import PropTypes from "prop-types";
import MDBox from 'components/MDBox';

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

function Loader({ open, color, ...options }) {
	return (
		<>
			<Backdrop sx={{ color: color, zIndex: 9999 }} open={open} {...options}>
				<CircularProgress color="inherit" />
			</Backdrop>
		</>
	)
}

// Setting default values for the props of Loaded
Loader.defaultProps = {
	color: "#fff",
	open: false,
};

// Typechecking props of the Loaded
Loader.propTypes = {
	color: PropTypes.string,
	open: PropTypes.bool.isRequired,
};


export default Loader