import React from "react"
import { Navigate, Link } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import Tooltip from "@mui/material/Tooltip"
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import DataTable from 'contents/Components/DataTable'
import Pagination from "contents/Components/Pagination";

import useAxios from "libs/useAxios"
import Config from 'config'
import secureStorage from "libs/secureStorage";
import { fnumber } from "libs/currency";

import moment from "moment";

class HistoryMember extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			merchant: null,
			status: null,
			product: null,

			statuses: [],
			products: [],
			merchants: [],

			rowsPerPage: 10,
			currentPage: 1,
			totalPages: 0,
			totalData: 0,
			tableHead: [
				{ Header: "kode", accessor: "kode", width: "10%" },
				{ Header: "nama jamaah", accessor: "name", width: "20%" },
				{ Header: "alamat", accessor: "address", width: "25%" },
				{ Header: "paket", accessor: "product", width: "20%" },
				{ Header: "tanggal berangkat", accessor: "departureDate", width: "20%" },
				{ Header: "status", accessor: "statusText", width: "10%" },
			],
			rows: [],
			keyword: "",
			isLoading: false,
		}
	}

	componentDidMount() {
		const user = secureStorage.getItem('user')

		this.loadData()
		user && user.roleId === 1 && this.loadMerchants()
		this.loadStatus()
		this.loadProduct()
	}

	loadMerchants = () => {
		useAxios()
			.post(`${Config.ApiUrl}/manage/merchant/dropdown`)
			.then(response => {
				const data = response.data

				this.setState({
					merchants: data.map(item => ({ id: item.id, label: item.name }))
				})
			})
			.catch(err => {
				console.log(err)
			})
	}

	loadProduct = (params) => {
		const merchantId = params && params.merchantId ? { merchantId: params.merchantId } : {}
		const payload = {
			...merchantId
		}

		useAxios()
			.post(`${Config.ApiUrl}/product/dropdown`, payload)
			.then(response => {
				const data = response.data

				this.setState({
					products: data.map(item => ({ id: item.id, label: item.name }))
				})
			})
			.catch(err => {
				console.log(err)
			})
	}

	loadStatus = () => {
		useAxios()
			.post(`${Config.ApiUrl}/product/getStatus`)
			.then(response => {
				const data = response.data
				this.setState({
					statuses: data.map(item => ({ id: item.id, label: item.name }))
				})
			})
			.catch(err => {
				console.log(err)
			})
	}

	loadData = (params) => {
		this.setState({ isLoading: true })
		const merchantId = params && params.merchantId ? { merchantId: params.merchantId } : {}
		const productId = params && params.productId ? { productId: params.productId } : {}
		const statusId = params && params.statusId ? { statusId: params.statusId } : {}

		const payload = {
			type: 'history',
			keyword: params && params.keyword ? params.keyword : this.state.keyword,
			currentPage: params && params.currentPage ? params.currentPage : 1,
			rowsPerPage: params && params.rowsPerPage ? params.rowsPerPage : this.state.rowsPerPage,
			...productId,
			...statusId,
			...merchantId
		}

		useAxios()
			.post(`${Config.ApiUrl}/user/member`, payload)
			.then(response => {
				const data = response.data
				console.log(data)

				const output = data.output.map(item => {
					const customer = item.Customer
					const product = customer.Product

					const member = item.Member
					const departureStatus = item.DepartureStatus

					return {
						// kode: <MDTypography variant="caption" fontWeight="regular">{member.kode}</MDTypography>,
						kode: (
							<MDTypography
								variant="caption"
								fontWeight="medium"
								sx={{
									cursor: "pointer"
								}}
								color="info"
								component={Link}
								to={{ pathname: `/adm/member/detail/${item.id}` }}
							>
								{member?.kode}
							</MDTypography>
						),
						name: <MDTypography variant="caption" fontWeight="regular">{member.name}</MDTypography>,
						address: (
							<p style={{ wordWrap: "break-word", width: "15em" }}>
								<MDTypography variant="caption" fontWeight="regular">{item.address}</MDTypography>
							</p>
						),
						product: (
							<MDBox
								style={{ wordWrap: 'break-word', width: '12em' }}
							>
								<MDBox display="flex" flexDirection="column">
									<MDTypography variant="caption" fontWeight="regular">Kode : {product.kode}</MDTypography>
									<MDTypography variant="caption" fontWeight="regular">Nama : {product.name}</MDTypography>
								</MDBox>
							</MDBox>
						),
						departureDate: <MDTypography variant="caption" fontWeight="regular">{moment(product.departureDate).format('YYYY-MM-DD')}</MDTypography>,
						statusText: <MDTypography variant="caption" fontWeight="regular">{departureStatus && departureStatus.name ? departureStatus.name : '-'}</MDTypography>
					}
				})
				this.setState({
					isLoading: false,
					totalPages: data.totalPages,
					totalData: data.totalData,
					rows: output
				})
			})
			.catch(err => {
				console.log(err)
				this.setState({ isLoading: false })
			})
	}

	render() {
		const user = secureStorage.getItem('user')

		return (
			<MDBox pb={3} my={3}>
				<Card>
					<MDBox p={2} lineHeight={1}>
						<MDTypography variant="h5" fontWeight="medium">
							History Jamaah
						</MDTypography>
					</MDBox>
					<MDBox px={2} width="100%" display="flex" justifyContent="flex-start">
						<Grid container spacing={3}>
							{/* Merchant */}
							{[1].includes(user.roleId) && (
								<Grid item xs={12} md={3} lg={3}>
									<Autocomplete
										value={this.state.merchant}
										options={this.state.merchants}
										onChange={(e, value) => {
											if (value) this.loadProduct({ merchantId: value?.id })
											else this.loadProduct()
											this.setState({ merchant: value }, () => {
												this.loadData({
													currentPage: 1,
													merchantId: value ? value.id : null,
													statusId: this.state.status ? this.state.status.id : null,
													productId: this.state.product ? this.state.product.id : null
												})
											})
										}}
										sx={{
											".MuiAutocomplete-input": {
												padding: "7.5px 5px 7.5px 8px !important"
											},
											".MuiOutlinedInput-root": {
												padding: "1.5px !important"
											}
										}}
										isOptionEqualToValue={(option, value) => option.id === value.id}
										renderInput={(params) => <MDInput sx={{ padding: "0px" }} fullWidth label="Pilih Biro Travel" {...params} />}
									/>
								</Grid>
							)}
							{/* Product */}
							<Grid item xs={12} md={3} lg={3}>
								<Autocomplete
									value={this.state.product}
									options={this.state.products}
									onChange={(e, value) => {
										this.setState({ product: value })
										this.loadData({
											currentPage: 1,
											productId: value ? value.id : null,
											statusId: this.state.status ? this.state.status.id : null,
											merchantId: this.state.merchant ? this.state.merchant.id : null
										})
									}}
									sx={{
										".MuiAutocomplete-input": {
											padding: "7.5px 5px 7.5px 8px !important"
										},
										".MuiOutlinedInput-root": {
											padding: "1.5px !important"
										}
									}}
									isOptionEqualToValue={(option, value) => option.id === value.id}
									renderInput={(params) => <MDInput sx={{ padding: "0px" }} fullWidth label="Pilih Paket" {...params} />}
								/>
							</Grid>
							{/* Status Departure */}
							<Grid item xs={12} md={3} lg={3}>
								<Autocomplete
									value={this.state.status}
									options={this.state.statuses}
									onChange={(e, value) => {
										this.setState({ status: value })
										this.loadData({
											currentPage: 1,
											statusId: value ? value.id : null,
											productId: this.state.product ? this.state.product.id : null,
											merchantId: this.state.merchant ? this.state.merchant.id : null
										})
									}}
									sx={{
										".MuiAutocomplete-input": {
											padding: "7.5px 5px 7.5px 8px !important"
										},
										".MuiOutlinedInput-root": {
											padding: "1.5px !important"
										}
									}}
									isOptionEqualToValue={(option, value) => option.id === value.id}
									renderInput={(params) => <MDInput sx={{ padding: "0px" }} fullWidth label="Pilih Status" {...params} />}
								/>
							</Grid>
							{/* Button */}
							<Grid item xs={12} md={3} lg={3}>
								<MDBox
									width="100%"
									display="flex"
									flexDirection="row"
									justifyContent="flex-start"
								>
									<Tooltip title="Reset Filter">
										<MDButton
											color="error"
											variant="gradient"
											onClick={() => {
												this.setState({
													currentPage: 1,
													productId: null,
													merchantId: null,
													statusId: null
												}, () => {
													this.loadData()
												})
											}}
											iconOnly
										>
											<Icon>refresh</Icon>
										</MDButton>
									</Tooltip>
								</MDBox>
							</Grid>
						</Grid>
					</MDBox>
					<MDBox p={2}>
						<DataTable
							isLoading={this.state.isLoading}
							tableHead={this.state.tableHead}
							tableData={this.state.rows}
						/>
						<Pagination
							totalPages={this.state.totalPages}
							totalData={this.state.totalData}
							currentPage={this.state.currentPage}
							rowsPerPage={[10, 25, 50, "All"]}
							totalButton={3}
							defaultRowsPerPage={this.state.rowsPerPage}
							onChangeRowsPerPage={(value) => {
								this.setState({ rowsPerPage: value, currentPage: 1 })
								this.loadData({
									rowsPerPage: value,
									currentPage: 1,
									statusId: this.state.status ? this.state.status.id : null,
									productId: this.state.product ? this.state.product.id : null,
									merchantId: this.state.merchant ? this.state.merchant.id : null
								})
							}}
							onChangePage={(currentPage) => {
								if (currentPage !== this.state.currentPage) {
									this.setState({ currentPage })
									this.loadData({
										currentPage,
										statusId: this.state.status ? this.state.status.id : null,
										productId: this.state.product ? this.state.product.id : null,
										merchantId: this.state.merchant ? this.state.merchant.id : null
									})
								}
							}}
						/>
					</MDBox>
				</Card>
			</MDBox>
		)
	}
}

export default HistoryMember