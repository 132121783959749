/* eslint-disable react/prop-types */
import React from "react";

// react-router-dom components
import { Navigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import MuiLink from "@mui/material/Link";
import Grid from "@mui/material/Grid";
// @mui icons
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import LOGO from "assets/img/logoACC.png";

import BasicLayout from "contents/Login/BasicLayout";

import axios from "axios";
import Config from "config";
import secureStorage from "libs/secureStorage";

import Notif from "contents/Components/Notif";
import ModalNotif from "contents/Components/ModalNotif";
import withRouter from 'contents/Components/withRouter';

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resetPass: null,

      password: "",
      repassword: "",

      confirmCode: "",
      isValid: true,

      error: {},
      success: {},

      redirect: null,
      submitDisabled: false,
    };

    this.notifRef = React.createRef();
    this.modalNotifRef = React.createRef();
  }

  componentDidMount() {
    const confirmCode = this.props.params?.confirmCode
    const resetPass = secureStorage.getItem("resetPass");

    if (this.state.redirect || !resetPass || !this.state.isValid) {
      this.setState({ redirect: '/' })
    }

    this.setState({ resetPass }, () => {
      this.isConfirmCodeValid(confirmCode)
    });
  }

  isConfirmCodeValid = (code) => {
    axios.post(`${Config.ApiUrl}/user/reset/isvalid`, { confirmCode: code })
      .then(response => {
        this.setState({ isValid: true, confirmCode: code })
      })
      .catch(err => {
        this.setState({ isValid: false })
      })
  }

  handleInput = (e) => {
    const id = e.target.id;
    let isError = { ...this.state.error };
    let isSuccess = { ...this.state.success };
    switch (id) {
      case "password":
        this.setState({
          password: e.target.value,
          success: { ...isSuccess, password: true },
          error: { ...isError, password: false }
        })
        break;
      case "repassword":
        this.setState({ repassword: e.target.value })
        if (e.target.value === this.state.password) {
          this.setState({
            success: { ...isSuccess, repassword: true },
            error: { ...isError, repassword: false }
          })
        } else {
          this.setState({
            success: { ...isSuccess, repassword: false },
            error: { ...isError, repassword: true }
          })
        }
        break;
    }
  };

  handleBlur = (e) => {
    let isSuccess = { ...this.state.success };
    let isError = { ...this.state.error };
    if (e.target.value) {
      this.handleInput(e);
    } else {
      this.setState({
        success: { ...isSuccess, [e.target.id]: false },
        error: { ...isError, [e.target.id]: true }
      })
    }
  };

  handleSubmit = () => {
    const password = this.state.password;
    const repassword = this.state.repassword;
    if (password == "" || repassword == "") {
      this.notifRef.current.setShow({
        show: true,
        message: "Password tidak boleh kosong",
        color: "warning",
      });
    } else {
      this.setState({ submitDisabled: true });
      const payload = {
        password,
        confirmCode: this.state.confirmCode
      };

      axios
        .post(`${Config.ApiUrl}/user/reset/change`, payload)
        .then((response) => {
          secureStorage.removeItem("resetPass");
          this.modalNotifRef.current.setShow({
            modalTitle: "Sukses",
            modalMessage: response.data,
            onClose: () => {
              this.setState({ redirect: "/login" });
            },
          });
        })
        .catch((err) => {
          console.log(err);
          this.setState({ submitDisabled: false });
          this.notifRef.current.setShow({
            show: true,
            message:
              err.response && err.response.data
                ? err.response.data
                : "Terjadi kesalahan pada system",
            color: "warning",
          });
        });
    }
  };

  render() {
    if (this.state.redirect) return <Navigate to={this.state.redirect} />;
    return (
      <BasicLayout image={bgImage}>
        <Notif ref={this.notifRef} />
        <ModalNotif ref={this.modalNotifRef} />

        <Card>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            mx={2}
            mt={-3}
            p={2}
            mb={1}
            textAlign="center"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Ubah Password
            </MDTypography>
          </MDBox>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" role="form">
              <MDBox mb={2}>
                <MDInput
                  type="password"
                  id="password"
                  value={this.state.password}
                  onChange={this.handleInput}
                  onBlur={this.handleBlur}
                  label="Password Baru"
                  error={this.state.error ? this.state.error.password : false}
                  success={this.state.success ? this.state.success.password : false}
                  fullWidth
                />
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="password"
                  id="repassword"
                  value={this.state.repassword}
                  onChange={this.handleInput}
                  onBlur={this.handleBlur}
                  label="Konfirmasi Password Baru"
                  error={this.state.error ? this.state.error.repassword : false}
                  success={this.state.success ? this.state.success.repassword : false}
                  fullWidth
                />
              </MDBox>
              <MDBox mt={4} mb={1}>
                <MDButton
                  variant="gradient"
                  color="info"
                  disabled={this.state.submitDisabled}
                  onClick={this.handleSubmit}
                  fullWidth
                >
                  Kirim
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      </BasicLayout>
    );
  }
}

export default withRouter(ResetPassword);
