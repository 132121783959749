import React from "react";
import PropTypes from "prop-types";
// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

import Copy from "@mui/icons-material/FileCopy";
import Tooltip from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDDatePicker from "components/MDDatePicker";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import ModalNotif from "contents/Components/ModalNotif";
import MiniFormCard from "contents/Components/FormCard/MiniFormCard";

import Config from "config";
import moment from "moment";
import _ from "lodash";
import * as EmailValidator from "email-validator";

import useAxios from "libs/useAxios";
import secureStorage from "libs/secureStorage";

class MerchantBasic extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			id: null,
			userId: null,
			merchantId: null,
			settingId: null,

			prefix: "",
			name: "",
			numberSK: "",
			director: "",
			email: "",
			phone: "",
			dateSK: "",
			address: "",
			remark: "",
			password: "",
			repassword: "",

			province: null,
			district: null,

			logo: null,
			filenameLogo: "",

			provinceOpen: false,
			districtOpen: false,

			error: {
				province: true,
				district: true,
				dateBirth: true,
			},
			success: [],

			provinces: [],
			districts: [],

			disabledSubmit: false,
			redirect: null
		};

		this.logoRef = React.createRef();
		this.modalNotifRef = React.createRef();
	}

	componentDidMount() {
		const user = secureStorage.getItem("user")

		this.setState(
			{
				userId: user.id,
				merchantId: user.Merchant?.id,
			},
			() => {
				this.loadMerchant(user.Merchant?.id);
				this.loadProvince();
			}
		);
	}

	loadMerchant = (id) => {
		useAxios()
			.post(`${Config.ApiUrl}/manage/merchant/get`, { id })
			.then((response) => {
				const data = response.data
				const province = data && data.Province ? data.Province : null
				const district = data && data.District ? data.District : null

				province && this.loadDistrict(province?.id) // load list district

				this.setState({
					id: data.id,
					merchantId: data && data.id ? data.id : this.state.merchantId,

					prefix: data.prefix,
					name: data.name,
					numberSK: data && data.numberSK ? data.numberSK : "",
					email: data && data.email ? data.email : "",
					phone: data && data.phone ? data.phone : "",
					dateSK: data && data.dateSK ? data.dateSK : "",
					director: data && data.director ? data.director : "",
					province: province ? { id: province.id, label: province.name } : null,
					district: district ? { id: district.id, label: district.name } : null,
					address: data && data.address ? data.address : "",
					remark: data && data.remark ? data.remark : "",

					success: {
						...this.state.success,
						prefix: data.prefix ? true : false,
						name: data.name ? true : false,
						numberSK: data.numberSK ? true : false,
						phone: data && data.phone ? true : false,
						email: data && data.email ? true : false,
						director: data && data.director ? true : false,
						dateSK: data && data.dateSK ? true : false,
						address: data && data.address ? true : false,
						province: province ? true : false,
						district: district ? true : false,
						password: true,
						repassword: true,
					}
				});
			})
			.catch((err) => {
				console.log(err);
				if (err.response) {
					this.modalNotifRef.current.setShow({
						modalTitle: "Gagal",
						modalMessage: err.response.data,
					});
				}
				// eslint-disable-next-line no-empty
				else {
					this.modalNotifRef.current.setShow({
						modalTitle: "Gagal",
						modalMessage: "Koneksi jaringan terputus",
					});
				}
			});
	};

	loadProvince = () => {
		useAxios()
			.post(`${Config.ApiUrl}/master/region/province/dropdown`, { countryId: 1 })
			.then((response) => {
				const data = response.data;
				const provinces = data.map((item) => ({
					id: item.id,
					label: item.name,
				}));
				this.setState({ provinces });
			})
			.catch((err) => {
				console.log(err);
			});
	};

	loadDistrict = (provinceId) => {
		useAxios()
			.post(`${Config.ApiUrl}/master/region/district/dropdown`, { provinceId })
			.then((response) => {
				const data = response.data;
				const districts = data.map((item) => ({ id: item.id, label: item.name }));
				this.setState({ districts });
			})
			.catch((err) => {
				console.log(err);
			});
	};

	handleChange = (e) => {
		const { id, value } = e.target;
		this.setState({
			[id]: value,
		});
	};

	handleBlur = (e) => {
		const user = this.state.user;
		const { id, value } = e.target;
		// eslint-disable-next-line no-empty
		if (value) {
			if (id === "email" && !EmailValidator.validate(value)) {
				this.setState({
					success: { ...this.state.success, [e.target.id]: false },
					error: { ...this.state.error, [e.target.id]: true },
				});
			} else {
				this.setState({
					success: { ...this.state.success, [e.target.id]: true },
					error: { ...this.state.error, [e.target.id]: false },
				});
			}
		} else {
			this.setState({
				success: { ...this.state.success, [e.target.id]: false },
				error: { ...this.state.error, [e.target.id]: true },
			});
		}
	};

	handleSubmit = () => {
		const success = this.state.success;

		if (
			success.prefix &&
			success.name &&
			success.numberSK &&
			success.director &&
			success.dateSK &&
			success.email &&
			success.phone &&
			success.address &&
			success.province
		) {
			this.sendData();
		} else {
			let input = "";
			!success.address && (input = "Alamat");
			!success.district && (input = "Kabupaten/Kota");
			!success.province && (input = "Provinsi");
			// !success.logo && (input = "Logo");
			!success.phone && (input = "Nomor Telpon");
			!success.email && (input = "Email");
			!success.dateSk && (input = "Tanggal SK");
			!success.director && (input = "Nama Direstur");
			!success.nomorSk && (input = "Nomor SK");
			!success.name && (input = "Nama Biro")
			!success.prefix && (input = "Kode Prfix")

			this.modalNotifRef.current.setShow({
				modalTitle: "Gagal",
				modalMessage: `Data ${input} masih kosong, Silahkan di cek kembali !`,
			});
		}
	};

	sendData = () => {
		this.setState({ disabledSubmit: true });
		const formData = new FormData();

		formData.append('id', this.state.id)
		formData.append('prefix', this.state.prefix)
		formData.append('name', this.state.name)
		formData.append('numberSK', this.state.numberSK)
		formData.append('director', this.state.director)
		formData.append('email', this.state.email)
		formData.append('phone', this.state.phone)
		formData.append('dateSK', this.state.dateSK)
		formData.append('address', this.state.address)
		formData.append('countryId', 1)
		formData.append('provinceId', this.state.province?.id)
		formData.append('districtId', this.state.district?.id)
		formData.append('password', this.state.password)
		formData.append('logo', this.state.logo)

		useAxios()
			.post(`${Config.ApiUrl}/manage/merchant/update`, formData)
			.then((response) => {
				this.setState({ disabledSubmit: false })
				this.modalNotifRef.current.setShow({
					modalTitle: "Sukses",
					modalMessage: response.data,
				});
			})
			.catch((err) => {
				console.log(err);
				this.setState({ disabledSubmit: false });
				if (err.response) {
					this.modalNotifRef.current.setShow({
						modalTitle: "Gagal",
						modalMessage: err.response.data,
					});
				}
				// eslint-disable-next-line no-empty
				else {
					this.modalNotifRef.current.setShow({
						modalTitle: "Gagal",
						modalMessage: "Koneksi jaringan terputus",
					});
				}
			});
	};

	render() {
		return (
			<>
				<ModalNotif ref={this.modalNotifRef} />
				<Card id="basic-info" sx={{ overflow: "visible" }}>
					<MDBox p={3}>
						<MDTypography variant="h5">Basic Info</MDTypography>
					</MDBox>
					<MDBox component="form" pb={3} px={3}>
						<Grid container spacing={3}>
							<Grid item xs={12} lg={6} md={6}>
								{/* Prefix Code */}
								<MDBox mb={2}>
									<MDInput
										disabled
										readOnly
										id="prefix"
										type="text"
										label="Prefix Kode"
										inputRef={this.inputRef}
										value={this.state.prefix}
										onChange={this.handleChange}
										onBlur={this.handleBlur}
										success={this.state.success ? this.state.success.prefix : false}
										error={this.state.error ? this.state.error.prefix : false}
										fullWidth
									/>
								</MDBox>
								{/* Name */}
								<MDBox mb={2}>
									<MDInput
										id="name"
										type="text"
										label="Nama Biro Travel"
										value={this.state.name}
										onChange={this.handleChange}
										onBlur={this.handleBlur}
										success={this.state.success ? this.state.success.name : false}
										error={this.state.error ? this.state.error.name : false}
										fullWidth
									/>
								</MDBox>
								{/* Nomor SK */}
								<MDBox mb={2}>
									<MDInput
										id="numberSK"
										type="text"
										label="Nomor SK PPIU / PIHK"
										value={this.state.numberSK}
										onChange={this.handleChange}
										onBlur={this.handleBlur}
										success={this.state.success ? this.state.success.numberSK : false}
										error={this.state.error ? this.state.error.numberSK : false}
										fullWidth
									/>
								</MDBox>
								{/* Director */}
								<MDBox mb={2}>
									<MDInput
										id="director"
										type="text"
										label="Direktur"
										inputRef={this.inputRef}
										value={this.state.director}
										onChange={this.handleChange}
										onBlur={this.handleBlur}
										success={this.state.success ? this.state.success.director : false}
										error={this.state.error ? this.state.error.director : false}
										fullWidth
									/>
								</MDBox>
								{/* Phone */}
								<MDBox mb={2}>
									<MDInput
										id="phone"
										type="text"
										label="Nomor Telpon"
										value={this.state.phone}
										onChange={this.handleChange}
										onBlur={this.handleBlur}
										success={this.state.success ? this.state.success.phone : false}
										error={this.state.error ? this.state.error.phone : false}
										fullWidth
									/>
								</MDBox>
								{/* Email */}
								<MDBox mb={2}>
									<MDInput
										id="email"
										type="text"
										label="Email"
										value={this.state.email}
										onChange={this.handleChange}
										onBlur={this.handleBlur}
										success={this.state.success ? this.state.success.email : false}
										error={this.state.error ? this.state.error.email : false}
										fullWidth
									/>
								</MDBox>
								{/* Date SK */}
								<MDBox mb={2}>
									<MDDatePicker
										input={{
											id: "dateSK",
											placeholder: "Tanggal SK PPIU / PIHK",
											fullWidth: true
										}}
										value={this.state.dateSK}
										onChange={(value) => {
											const date = moment(value[0]).format('YYYY-MM-DD')
											this.setState({
												dateSK: date,
												error: { ...this.state.error, dateSK: false },
												success: { ...this.state.success, dateSK: true }
											})
										}}
									/>
								</MDBox>
							</Grid>
							<Grid item xs={12} lg={6} md={6}>
								{/* Province */}
								<MDBox mb={2}>
									<Autocomplete
										fullWidth
										id="province"
										open={this.state.provinceOpen}
										onOpen={() => this.setState({ provinceOpen: true })}
										onClose={() => this.setState({ provinceOpen: false })}
										onFocus={() => this.setState({ provinceOpen: true })}
										value={this.state.province}
										options={this.state.provinces}
										onChange={(e, newValue) => {
											if (newValue) {
												this.setState({
													province: newValue,
													district: null,
													districts: [],
													error: { ...this.state.error, province: false, district: true },
													success: { ...this.state.success, province: true, district: false }
												})
												this.loadDistrict(newValue.id)
											}
										}}
										sx={{
											".MuiAutocomplete-input": {
												padding: "7.5px 5px 7.5px 8px !important"
											},
											".MuiOutlinedInput-root": {
												padding: "4px !important"
											}
										}}
										isOptionEqualToValue={(option, value) => option.id === value.id}
										renderInput={(params) => <MDInput label="Pilih Provinsi" {...params} />}
									/>
								</MDBox>
								{/* District */}
								<MDBox mb={2}>
									<Autocomplete
										fullWidth
										id="district"
										open={this.state.districtOpen}
										onOpen={() => this.setState({ districtOpen: true })}
										onClose={() => this.setState({ districtOpen: false })}
										onFocus={() => this.setState({ districtOpen: true })}
										value={this.state.district}
										options={this.state.districts}
										onChange={(e, value) => {
											if (value) {
												this.setState({
													district: value,
													error: { ...this.state.error, district: false },
													success: { ...this.state.success, district: true }
												})
											}
										}}
										sx={{
											".MuiAutocomplete-input": {
												padding: "7.5px 5px 7.5px 8px !important"
											},
											".MuiOutlinedInput-root": {
												padding: "4px !important"
											}
										}}
										isOptionEqualToValue={(option, value) => option.id === value.id}
										renderInput={(params) => <MDInput label="Pilih Kabupaten/Kota" {...params} />}
									/>
								</MDBox>
								{/* Logo */}
								<MDBox mb={2}>
									<input
										type="file"
										name="fileInput"
										ref={this.fileRef}
										onChange={(e) => {
											if (e.target.files.length === 1) {
												const logo = e.target.files[0];
												const filenameLogo = logo.name;
												const ext = filenameLogo.split(".")[1];
												this.setState({
													logo, filenameLogo
												})
											}
										}}
										hidden
									/>
									<MDInput
										fullWidth
										value={this.state.filenameLogo}
										label="Upload Logo"
										onClick={() => {
											this.fileRef.current.click()
										}}
										readOnly
									/>
								</MDBox>
								{/* Address */}
								<MDBox mb={2}>
									<MDInput
										id="address"
										type="text"
										rows={3}
										multiline
										label="Alamat"
										value={this.state.address}
										onChange={this.handleChange}
										onBlur={this.handleBlur}
										success={this.state.success ? this.state.success.address : false}
										error={this.state.error ? this.state.error.address : false}
										fullWidth
									/>
								</MDBox>
							</Grid>
						</Grid>
						<MDBox width="100%" display="flex" justifyContent="flex-end" flexWrap="wrap">
							<MDButton
								variant="gradient"
								color="dark"
								size="small"
								disabled={this.state.disabledSubmit}
								onClick={this.handleSubmit}
							>
								UPDATE
							</MDButton>
						</MDBox>
					</MDBox>
				</Card>
			</>
		);
	}
}

export default MerchantBasic;
