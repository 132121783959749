/* eslint-disable react/prop-types */
import React, { Fragment } from "react";
import { Document, Page, Text, Image, View, StyleSheet, Font } from "@react-pdf/renderer";

import logo from "assets/img/logoApp.png";

import RobotoMonoRegular from "assets/fonts/RobotoMono/RobotoMono-Regular.ttf";
import RobotMonoBold from "assets/fonts/RobotoMono/RobotoMono-Bold.ttf";
import RobotoMonoBoldItalic from "assets/fonts/RobotoMono/RobotoMono-BoldItalic.ttf";
import RobotoMonoItalic from "assets/fonts/RobotoMono/RobotoMono-Italic.ttf";

import PoaStyle from "helper/styles/poaStyles";
import moment from "moment";

// Font
Font.register({
  family: "RobotoMono",
  format: "truetype",
  fonts: [
    { src: RobotoMonoRegular },
    { src: RobotoMonoBoldItalic },
    { src: RobotoMonoItalic },
    { src: RobotMonoBold },
  ],
});
// Create styles
const styles = StyleSheet.create(PoaStyle);

class PdfPoa extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const data = this.props.data;
  }

  render() {
    const data = this.props.data;
    const item = data ? data.user : null;

    return (
      <Document>
        <Page size="Folio" style={styles.page}>
          {/* HAADER */}
          <View style={styles.headerContainer}>
            <Text stye={styles.headerTitle}>SURAT KUASA</Text>
          </View>

          <View style={styles.contentContainer}>
            <View style={styles.letterContainer}>
              {/* LEFT */}
              <View style={styles.letterLeft}>
                <View style={styles.headerLetterContent}>
                  <Text style={styles.headerContent}>Yang bertanda tangan dibawah ini :</Text>
                </View>
              </View>
              {/* RIGHT */}
            </View>

            {/* TABLE CONTENT */}
            <View style={styles.tableContainer}>
              <View style={styles.tableHeadContainer}>
                <Text style={styles.tableHeadNo}>NO</Text>
                <Text style={styles.tableHeadName}>NAMA</Text>
                <Text style={styles.tableHeadBirth}>TTL</Text>
                <Text style={styles.tableHeadAddress}>ALAMAT</Text>
              </View>
              <Fragment>
                <View style={styles.tableRow}>
                  <Text style={styles.tableRowNo}>1</Text>
                  <Text style={styles.tableRowName}>{item.name}</Text>
                  <Text style={styles.tableRowBirth}>
                    {item.placeBirth + ", " + moment(item.dateBirth).format("YYYY-MM-DD")}
                  </Text>
                  <Text style={styles.tableRowAddress}>{item.address}</Text>
                  {/* <Text style={styles.tableRowAmount}>{item.amount}</Text> */}
                </View>
              </Fragment>

              <View style={styles.letterLeft}>
                <View style={styles.headerLetterContent}>
                  <View style={{ ...styles.subContent, marginTop: 10 }}>
                    <Text style={styles.headerContent}>Memberi kuasa kepada :</Text>
                    <Text style={styles.headerContent}>
                      Nama &nbsp; &nbsp; &nbsp; : {data.name}
                    </Text>
                    <Text style={styles.headerContent}>Jabatan &nbsp; &nbsp;: {data.position}</Text>
                  </View>
                </View>
              </View>
            </View>

            <View style={styles.letterContainer}>
              <View style={styles.letterLeft}>
                <View style={styles.footerLetterContent}>
                  <Text style={styles.footerContent}>
                    Untuk melakukan pengajuan permohonan Rekomendasi Umroh di {data.direction}
                  </Text>
                  <Text style={{ ...styles.footerContent, marginTop: 15 }}>
                    Demikian Surat kuasa ini dibuat dan dapat digunakan sebagaimana mestinya.
                  </Text>
                </View>
              </View>
            </View>

            {/* FOOTER */}
            <View style={styles.footer}>
              <View style={styles.footerRowEnd}>
                <Text style={styles.footEndValue}>Yang diberi kuasa</Text>
                <Text style={{ ...styles.footEndValue, marginTop: "90px" }}>
                  ............................
                </Text>
              </View>

              <View style={styles.footerRowEnd}>
                <Text style={styles.footEndValue}>
                  {data.place}, {data.date ? moment(data.date).format("DD-MMM-YYYY") : ""}
                </Text>
                <Text style={styles.footEndValue}>Pembari Kuasa</Text>
                <Text style={{ ...styles.footEndValue, marginTop: "75px" }}>{item.name}</Text>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    );
  }
}

export default PdfPoa;
