/* eslint-disable react/prop-types */
import React from "react";
import { Link, Navigate } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import MDDatePicker from "components/MDDatePicker";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import ModalNotif from "contents/Components/ModalNotif";
import withRouter from "contents/Components/withRouter";

import MiniFormCard from "contents/Components/FormCard/MiniFormCard";
import ListJamaah from "contents/Administrasi/Certificate/components/ListJamaah";
import UserCertificate from "contents/Administrasi/Certificate/components/UserCertificate";
import FormCertificate from 'contents/Administrasi/Certificate/Form/components/Certificate';
import FormPoa from 'contents/Administrasi/Certificate/Form/components/Poa';

import Config from "config";
import _ from "lodash";
import moment from "moment";

import useAxios from "libs/useAxios";
import secureStorage from "libs/secureStorage";

class FormLetter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      title: "FORM SURAT PENGANTAR",
      action: "",
      id: null,

      type: "Keterangan",
      types: ["Keterangan", "Surat Kuasa"],
    };

  }

  componentDidMount() {
    const user = secureStorage.getItem("user");
    this.setState({
      user: user ? user : null,
    });

  }

  render() {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />;
    }

    return (
      <MDBox my={3} mx="auto" width="100%" height="100vh" pb={3}>
        <Grid container spacing={1} justifyContent="center" alignItems="flex-start" height="100%">
          <Grid item xs={12} sm={12} md={10} lg={12} xl={10} xxl={10}>
            <Card>
              <MDBox p={3} lineHeight={1} display="flex" justifyContent="center">
                <MDTypography variant="h5" fontWeight="medium">
                  {this.state.title}
                </MDTypography>
              </MDBox>
              <MDBox>
                <MDBox p={3}>
                  <Grid item xs={12} md={3} lg={3}>
                    <Autocomplete
                      value={this.state.type}
                      options={this.state.types}
                      onChange={(e, value) => {
                        if (value == "Keterangan") {
                          this.setState({ type: value, title: "FORM SURAT KETERANGAN" });
                        } else if (value == "Surat Kuasa") {
                          this.setState({ type: value, title: "FORM SURAT KUASA" });
                        }
                      }}
                      sx={{
                        ".MuiAutocomplete-input": {
                          padding: "7.5px 5px 7.5px 8px !important",
                        },
                        ".MuiOutlinedInput-root": {
                          padding: "1.5px !important",
                        },
                      }}
                      isOptionEqualToValue={(option, value) => option === value}
                      renderInput={(params) => (
                        <MDInput
                          sx={{ padding: "0px" }}
                          fullWidth
                          label="Pilih Type Surat"
                          {...params}
                        />
                      )}
                    />
                  </Grid>
                </MDBox>
              </MDBox>

              {this.state.type == "Keterangan" ? <FormCertificate /> : <FormPoa />}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    );
  }
}

export default withRouter(FormLetter);
