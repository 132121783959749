import secureStorage from 'libs/secureStorage'
import Config from 'config'
import axios from 'axios'


const logout = () => {
	secureStorage.removeItem('token')
	secureStorage.removeItem('user')
	window.location.href = "/login"
}
const useAxios = () => {
	const baseURL = Config.ApiUrl
	let authToken = secureStorage.getItem('token') ? secureStorage.getItem('token') : null
	// console.log({ authToken })

	const axiosInstance = axios.create({
		baseURL,
		headers: { Authorization: `Bearer ${authToken}` }
	})

	axiosInstance.interceptors.request.use(req => {
		if (!authToken) {
			authToken = secureStorage.getItem('token') ? secureStorage.getItem('token') : null
			// authToken = localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')) : null
			req.headers.Authorization = `Bearer ${authToken?.access}`
			// req.headers.post["Content-Type"] = "application/json;charset=utf-8";
		}

		return req
	})

	axiosInstance.interceptors.response.use(
		(response) => response,
		(error) => {
			if (error.response && error.response.data === "Forbidden Access") {
				logout()
			}
			return Promise.reject(error);
		}
	)

	return axiosInstance
}

export default useAxios