/* eslint-disable react/prop-types */
import React from 'react'
import { Link, Navigate } from 'react-router-dom';
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDBadge from "components/MDBadge";
import MDTypography from "components/MDTypography";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

// Material Dashboard 2 PRO React examples
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";

import withRouter from 'contents/Components/withRouter'
import Loader from 'contents/Components/Loader';
import ButtonBack from 'contents/Components/ButtonBack'
import ModalNotif from 'contents/Components/ModalNotif'

import useAxios from "libs/useAxios"
import Config from "config"
import secureStorage from 'libs/secureStorage';

class DetailAgent extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			user: null,
			id: null,
			isLoading: true,

			kode: "-",
			numberSK: "-",
			name: "-",
			leader: "-",
			email: "-",
			phone: "-",
			address: "-",
			datePermit: "",
			status: null,

			district: null,
			province: null
		}

		this.modalNotifRef = React.createRef()
	}

	componentDidMount() {
		const id = this.props.params?.id
		this.setState({ id })
		this.loadDetail(id)
	}

	loadDetail(id) {
		useAxios()
			.post(`${Config.ApiUrl}/manage/agent/get`, { id })
			.then(response => {
				const data = response.data
				console.log(data)

				this.setState({
					isLoading: false,
					kode: data.kode,
					numberSK: data.numberSK,
					name: data.name,
					leader: data.leader,
					address: data.address,
					datePermit: data.datePermit,
					email: data.email ? data.email : "-",
					phone: data.phone ? data.phone : "-",
					status: data.isActive,
					province: data.Province ? data.Province : null,
					district: data.District ? data.District : null,
				})
			})
			.catch(err => {
				console.log(err)
				this.modalNotifRef.current.setShow({
					modalTitle: 'Gagal',
					modalMessage: "Koneksi jaringan terputus",
				})
			})
	}

	render() {
		const user = secureStorage.getItem('user')
		const status = this.state.status

		return (
			<MDBox pb={3} my={3}>
				<ModalNotif ref={this.modalNotifRef} />
				<MDBox py={1} mb={2} display="flex">
					<MDBox mr={1}>
						<ButtonBack label={"KEMBALI"} />
					</MDBox>
					{user && [1].includes(user.roleId) && (
						<MDButton
							variant="gradient"
							color="info"
							component={Link}
							to={{ pathname: `/manage/agent/edit/${this.state.id}` }}
						>
							UBAH DATA
						</MDButton>
					)}
				</MDBox>
				<Card>
					<MDBox my={2} lineHeight={1} width="100%" display="flex" justifyContent="center">
						<MDTypography variant="h5" fontWeight="medium">
							Detail Agen
						</MDTypography>
					</MDBox>
					<MDBox p={2}>
						<Grid container spacing={3} item xs={12} md={12} lg={12} mb={5} pt={2}>
							<Table>
								<TableBody>
									{/* KODE && NOMOR SK */}
									<TableRow>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body" fontWeight="medium">
												Kode Agen
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body">
												{this.state.kode ? this.state.kode : "-"}
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body" fontWeight="medium">
												Nomor SK
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body">
												{this.state.numberSK ? this.state.numberSK : "-"}
											</MDTypography>
										</DataTableBodyCell>
									</TableRow>
									{/* NAME & EMAIL */}
									<TableRow>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body" fontWeight="medium">
												Nama KBIH
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body">
												{this.state.name ? this.state.name : "-"}
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body" fontWeight="medium">
												Email
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body">
												{this.state.email ? this.state.email : "-"}
											</MDTypography>
										</DataTableBodyCell>
									</TableRow>
									{/* LEADER && PHONE */}
									<TableRow>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body" fontWeight="medium">
												Pemimpin
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body">
												{this.state.leader ? this.state.leader : "-"}
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body" fontWeight="medium">
												Nomor Telpon
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body">
												{this.state.phone ? this.state.phone : "-"}
											</MDTypography>
										</DataTableBodyCell>
									</TableRow>
									{/* PROVINCE & ADDRESS */}
									<TableRow>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body" fontWeight="medium">
												Provinsi
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body">
												{this.state.province && this.state.province.name ? this.state.province.name.toUpperCase() : "-"}
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body" fontWeight="medium">
												Alamat
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body">
												<MDBox style={{ wordWrap: "break-word", width: "25em" }}>
													{this.state.address ? this.state.address : "-"}
												</MDBox>
											</MDTypography>
										</DataTableBodyCell>
									</TableRow>
									{/* DISTRICT && STATUS */}
									<TableRow>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body" fontWeight="medium">
												Kota/Kabupaten
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body">
												{this.state.district && this.state.district.name ? this.state.district.name.toUpperCase() : "-"}
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body" fontWeight="medium">
												Status
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body">
												{status !== null ? (
													<MDBadge
														variant="contained"
														badgeContent={status ? "Aktif" : "Tidak Aktif"}
														size="lg"
														color={status ? "success" : "warning"}
													/>
												) : ""}
											</MDTypography>
										</DataTableBodyCell>
									</TableRow>
									{/* DATE PERMIT */}
									<TableRow>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body" fontWeight="medium">
												Tanggal Izin
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body">
												{this.state.datePermit}
											</MDTypography>
										</DataTableBodyCell>
									</TableRow>
								</TableBody>
							</Table>
						</Grid>
					</MDBox>
				</Card>
			</MDBox>
		)
	}
}

export default withRouter(DetailAgent)