import { useMemo, useEffect, useState, useRef, createRef, Fragment } from "react";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
// react-table components
import { useTable, useGlobalFilter, useSortBy } from "react-table";

import { makeStyles } from "@mui/styles";
// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";

// Material Dashboard 2 PRO React contexts
import { useMaterialUIController } from "context";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DialogStatus from "contents/Administrasi/Departure/components/DialogStatus";

import useAxios from "libs/useAxios";
import Config from "config";
import moment from "moment";
import secureStorage from "libs/secureStorage";

const useStyles = makeStyles({
  stickyHeader: {
    position: "sticky",
    top: 0,
    zIndex: 2,
    backgroundColor: "#f9fafb",
  },
  columnHead: {
    color: "#000 !important",
    fontWeight: "bold",
    fontSize: "12px !important",
  },
});

function DataTable(props) {
  const classes = useStyles();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [open, setOpen] = useState(false);
  const [userProducts, setUserProducts] = useState([]);
  const [productCode, setProductCode] = useState(null)
  const [loadingChild, setLoadingChild] = useState(false)

  const dialogStatusRef = createRef();

  const { isLoading, isSorted, noEndBorder, tableHead, tableData, tableFoot } = props;

  const columnData = useMemo(() => tableHead, [tableHead]);
  const rowData = useMemo(() => tableData, [tableData]);
  const { getTableProps, getTableBodyProps, headerGroups, footerGroups, rows, prepareRow, page } =
    useTable(
      {
        columns: columnData,
        data: rowData,
        initialState: { pageIndex: 0 },
      },
      useGlobalFilter,
      useSortBy
    );

  useEffect(() => {
    productCode && loadUserProduct(productCode);
  }, [rows]);

  // A function that sets the sorted value for the table
  const setSortedValue = (column) => {
    let sortedValue;

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asce";
    } else if (isSorted) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };

  const handleExpandedClick = (index, code) => (e) => {
    setOpen(index === open ? "" : index);
    console.log(index === open ? "" : "open")
    console.log({ index, open })
    index === open ? "" : loadUserProduct(code);
  };

  const loadUserProduct = (code) => {
    // const productCode = code ? code : null;
    useAxios()
      .post(`${Config.ApiUrl}/product/userByProduct`, { productCode: code ? code : productCode })
      .then((response) => {
        const data = response.data;
        setUserProducts(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const user = secureStorage.getItem("user");

  return (
    <>
      <DialogStatus ref={dialogStatusRef} onAction={loadUserProduct.bind(this, productCode)} />
      <TableContainer sx={{ boxShadow: "none", maxHeight: 530 }}>
        {isLoading ? (
          <LoaderTable open={isLoading} />
        ) : (
          <Table {...getTableProps()}>
            <MDBox component="thead">
              {headerGroups.map((headerGroup, key) => (
                <TableRow
                  key={key}
                  className={classes.stickyHeader}
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {headerGroup.headers.map((column, idx) => (
                    <DataTableHeadCell
                      key={idx}
                      {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
                      className={darkMode ? classes.columnHead : null}
                      width={column.width ? column.width : "auto"}
                      align={column.align ? column.align : "left"}
                      sorted={setSortedValue(column)}
                    >
                      {column.render("Header")}
                    </DataTableHeadCell>
                  ))}
                </TableRow>
              ))}
            </MDBox>
            <TableBody {...getTableBodyProps()}>
              {rows.map((row, key) => {
                prepareRow(row);
                const kode = row.values.kode;
                return (
                  <Fragment key={key}>
                    <TableRow key={key} {...row.getRowProps()}>
                      {row.cells.map((cell, idx) => (
                        <DataTableBodyCell
                          key={idx}
                          noBorder={noEndBorder && rows.length - 1 === key}
                          align={cell.column.align ? cell.column.align : "left"}
                          {...cell.getCellProps()}
                        >
                          {cell.render("Cell")}
                          {cell.column.Header === "paket" && key !== open && (
                            <KeyboardArrowUpIcon
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                handleExpandedClick(key, kode)();
                                setProductCode(kode && parseInt(kode))
                              }}
                            />
                          )}
                          {cell.column.Header === "paket" && key === open && (
                            <KeyboardArrowDownIcon
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                console.log('[CLOSE]')
                                setOpen(false)
                                setUserProducts([])
                                setProductCode(null)
                              }}
                            />
                          )}
                        </DataTableBodyCell>
                      ))}
                    </TableRow>

                    {userProducts.map((item, idx) => (
                      <TableRow
                        component="tr"
                        key={idx}
                        style={{ display: open !== key ? "none" : "" }}
                      >
                        <DataTableBodyCell> </DataTableBodyCell>
                        <DataTableBodyCell> </DataTableBodyCell>
                        <DataTableBodyCell> {item.name}</DataTableBodyCell>
                        <DataTableBodyCell>
                          {" "}
                          {moment(item.product.departureDate).utc().add(7, "days").format("YYYY-MM-DD")}{" "}
                        </DataTableBodyCell>
                        <DataTableBodyCell> {item.product.UserProduct.remark}</DataTableBodyCell>
                        {/* Button Action */}
                        <DataTableBodyCell align="center">
                          {[2, 3].includes(user.roleId) ? (
                            <Tooltip title="Ubah Status">
                              <MDButton
                                variant="gradient"
                                color="info"
                                size="small"
                                onClick={() => {
                                  console.log("[EDIT STATUS MEMBER]")
                                  dialogStatusRef.current.setShow({
                                    productId:
                                      item.product && item.product.id ? item.product.id : null,
                                    productName:
                                      item.product && item.product.name ? item.product.name : "",
                                    memberId: item.id,
                                    memberName: item.name,
                                    departureDate:
                                      item.product && item.product.departureDate
                                        ? moment(item.product.departureDate)
                                          .utc()
                                          .add(7, "days")
                                          .format("YYYY-MM-DD")
                                        : "",
                                    statusName:
                                      item.product && item.product.UserProduct.remark
                                        ? item.product.UserProduct.remark
                                        : "",
                                    type: "member",
                                    show: true,
                                  });
                                }}
                                iconOnly
                              >
                                <Icon fontSize="medium">edit</Icon>
                              </MDButton>
                            </Tooltip>
                          ) : ("-")}
                        </DataTableBodyCell>
                      </TableRow>
                    ))}
                  </Fragment>
                );
              })}
            </TableBody>
            {tableFoot && (
              <MDBox component="tfoot">
                {footerGroups.map((footerGroup, key) => (
                  <TableRow
                    key={key}
                    className={classes.stickyHeader}
                    {...footerGroup.getFooterGroupProps()}
                  >
                    {footerGroup.headers.map((column, key) => (
                      <DataTableHeadCell
                        key={key}
                        {...column.getFooterProps()}
                        className={darkMode ? classes.columnHead : null}
                        width={column.width ? column.width : "auto"}
                        align={column.align ? column.align : "left"}
                      >
                        {column.render("Footer")}
                      </DataTableHeadCell>
                    ))}
                  </TableRow>
                ))}
              </MDBox>
            )}
          </Table>
        )}
      </TableContainer>
    </>
  );
}

// Setting default values for the props of DataTable
DataTable.defaultProps = {
  // canSearch: false,
  isSorted: true,
  noEndBorder: false,
};

DataTable.propTypes = {
  // onSearchPage: PropTypes.func,
  tableHead: PropTypes.array,
  tableData: PropTypes.array,
  tableFoot: PropTypes.bool,
  // canSearch: PropTypes.bool,
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default DataTable;

function LoaderTable({ color }) {
  return (
    <MDBox display="flex" justifyContent="center">
      <CircularProgress color="secondary" />
    </MDBox>
  );
}

LoaderTable.defaultProps = {
  color: "primary",
};

// Typechecking props of the Loader
LoaderTable.propTypes = {
  color: PropTypes.string,
};
