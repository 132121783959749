const bgColor = "#2c1061"
const borderColor = "#391380"

const styles = {
    page: {
        fontFamily: "RobotoMono",
        fontSize: 6,
        lineHeight: 1.5,
        flexDirection: "column",
    },
    headerContainer: {
        width: "100%",
        marginBottom: 2,
        display: "flex",
        flexDirection: "col",
        justifyContent: "center",
        alignItems: "center",
        paddingVertical: 20,
        paddingHorizontal: 40,
    },
    headerTitle: {
        fontSize: 20,
        fontWeight: "bold",
    },
    headerDate: {
        fontSize: 12,
    },
    contentContainer: {
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        paddingHorizontal: 15,
        paddingVertical: 0,
    },
    containerMiddle: {
        width: "100%",
        marginTop: 10,
        marginBottom: 10,
        // display: "flex",
        // flexDirection: "row",
        // justifyContent: "space-between",
        // alignItems: "center"
    },
    titleDetail: {
        fontSize: 12,
        fontWeight: "bold",
        merginLeft: 0,
        width: "54%",
        marginBottom: 5,
        marginTop: 5,
    },
    containerLabel: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start'
    },
    labelCaption: {
        textAlign: "left",
        fontSize: 10,
        // fontWeight: '500'
    },
    // Table
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 10,
        marginLeft: 3,
        marginRight: 3,
    },
    tableHeadContainer: {
        flexDirection: 'row',
        borderBottomColor: bgColor,
        backgroundColor: bgColor,
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        textAlign: 'center',
        fontWeight: "bold",
        flexGrow: 1,
        paddingHorizontal: 8
    },
    tableHeadCaption: {
        color: "#FFF",
        textAlign: "left",
        width: "20%",
        fontSize: 12,
        textTransform: "uppercase",
    },
    tableRow: {
        flexDirection: 'row',
        borderColor: borderColor,
        borderBottomWidth: 1,
        borderLeftWidth: 1,
        borderRightWidth: 1,
        alignItems: 'center',
        height: 24,
        paddingHorizontal: 8
    },
    tableRowCaption: {
        fontSize: 10,
        width: "20%",
        textAlign: "left",
        textTransform: "uppercase",
    },
}

export default styles