import React, { useState } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDDatePicker from "components/MDDatePicker";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import DataTable from "contents/Components/DataTable";
import Pagination from "contents/Components/Pagination";

import useAxios from "libs/useAxios";
import Config from "config";
import secureStorage from "libs/secureStorage";
import moment from "moment";
import { fnumber } from "libs/currency";

class AgentCommission extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      product: null,
      startDate: "",
      endDate: "",

      products: [],

      rowsPerPage: 10,
      currentPage: 1,
      totalPages: 0,
      totalData: 0,
      tableHead: [
        { Header: "No", accessor: "no", width: "15%" },
        { Header: "Komisi", accessor: "commission", width: "20%" },
        { Header: "Paket", accessor: "product", width: "20%" },
        { Header: "Nama Agen", accessor: "agent", width: "20%" },
        { Header: "Tanggal", accessor: "date", width: "20%" },
      ],
      rows: [],
      isLoading: true,
    };
  }

  componentDidMount() {
    const user = secureStorage.getItem('user')
    const startDate = moment().startOf("year").format("YYYY-MM-DD")
    const endDate = moment().endOf("year").format("YYYY-MM-DD")

    this.setState({
      startDate, endDate
    });
    this.loadProduct()
    this.loadData({ startDate, endDate });
  }

  loadProduct = () => {
    useAxios()
      .post(`${Config.ApiUrl}/product/dropdown`)
      .then(response => {
        const data = response.data

        this.setState({
          products: data.map(item => ({ id: item.id, label: item.name }))
        })
      })
      .catch(err => {
        console.log(err)
      })
  }


  loadData = (params) => {
    this.setState({ isLoading: true });
    const user = secureStorage.getItem("user")
    const productId = params && params.productId ? { productId: params.productId } : {}

    const payload = {
      currentPage: params && params.currentPage ? params.currentPage : this.state.currentPage,
      rowsPerPage: params && params.rowsPerPage ? params.rowsPerPage : this.state.rowsPerPage,
      startDate: params && params.startDate ? params.startDate : this.state.startDate,
      endDate: params && params.endDate ? params.endDate : this.state.endDate,
      ...productId
    };

    useAxios()
      .post(`${Config.ApiUrl}/finance/commission/agent`, payload)
      .then((response) => {
        const data = response.data
        let no = 0
        const output = data.output.map((item) => {
          no++
          const agent = item.User && item.User.Agent ? item.User.Agent : null

          return {
            no,
            commission: fnumber(item.amount),
            product: (
              <MDBox
                style={{ wordWrap: "break-word", width: "13em" }}
              >
                <MDBox
                  display="flex"
                  flexDirection="column"
                >
                  <MDTypography variant="caption" fontWeight="regular">
                    Kode: {item.Product && item.Product.kode ? item.Product.kode : '-'}
                  </MDTypography>
                  <MDTypography variant="caption" fontWeight="regular">
                    Name: {item.Product && item.Product.name ? item.Product.name : '-'}
                  </MDTypography>
                </MDBox>
              </MDBox>
            ),
            agent: (
              <MDBox
                style={{ wordWrap: "break-word", width: "13em" }}
              >
                <MDBox display="flex" flexDirection="column">
                  <MDTypography variant="caption" fontWeight="regular">
                    Kode: {agent && agent.kode ? agent.kode : "-"}
                  </MDTypography>
                  <MDTypography variant="caption" fontWeight="regular">
                    Nama: {agent && agent.name ? agent.name : "-"}
                  </MDTypography>
                </MDBox>
              </MDBox >
            ),
            date: <MDTypography variant="caption" fontWeight="regular">{item.createdAt}</MDTypography>
          };
        });

        this.setState({
          totalPages: data.totalPages,
          totalData: data.totalData,
          rows: output,
          isLoading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ isLoading: false });
      });
  };

  render() {
    const user = secureStorage.getItem("user");
    const title = [1, 2, 3].includes(user.roleId) ? 'Komisi Agen' : 'Komisi'
    return (
      <MDBox pb={3} my={3}>
        <Card>
          <MDBox p={2} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              {title}
            </MDTypography>
          </MDBox>
          <MDBox px={2} width="100%" display="flex" justifyContent="flex-start">
            <Grid container spacing={3}>
              {/* Product */}
              <Grid item xs={12} md={2} lg={3}>
                <Autocomplete
                  value={this.state.product}
                  options={this.state.products}
                  onChange={(e, value) => {
                    console.log({ value })
                    this.setState({ product: value })
                    this.loadData({ productId: value ? value.id : null })
                  }}
                  sx={{
                    ".MuiAutocomplete-input": {
                      padding: "7.5px 5px 7.5px 8px !important"
                    },
                    ".MuiOutlinedInput-root": {
                      padding: "1.5px !important"
                    }
                  }}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  renderInput={(params) => <MDInput sx={{ padding: "0px" }} fullWidth label="Pilih Paket" {...params} />}
                />
              </Grid>
              {/* Start Date */}
              <Grid
                item
                xs={12}
                md={[1].includes(user.roleId) ? 2 : 3}
                lg={[1].includes(user.roleId) ? 2 : 3}
              >
                <MDDatePicker
                  input={{
                    id: "startDate",
                    placeholder: "Tanggal Awal",
                    fullWidth: true,
                    sx: {
                      ".MuiInputBase-input": {
                        height: "1em !important",
                      },
                    },
                  }}
                  value={this.state.startDate}
                  onChange={(value) => {
                    const date = moment(value[0]).format("YYYY-MM-DD");
                    this.setState({ startDate: date });
                  }}
                />
              </Grid>
              {/* End Date */}
              <Grid
                item
                xs={12}
                md={[1].includes(user.roleId) ? 2 : 3}
                lg={[1].includes(user.roleId) ? 2 : 3}
              >
                <MDDatePicker
                  input={{
                    id: "endDate",
                    placeholder: "Tanggal Akhir",
                    fullWidth: true,
                    sx: {
                      ".MuiInputBase-input": {
                        height: "1em !important",
                      },
                    },
                  }}
                  value={this.state.endDate}
                  onChange={(value) => {
                    const date = moment(value[0]).format("YYYY-MM-DD");
                    this.setState({ endDate: date });
                  }}
                />
              </Grid>
              {/* Button Search */}
              <Grid item xs={12} md={1} lg={1}>
                <MDButton
                  color="info"
                  variant="gradient"
                  onClick={() => {
                    const startDate = this.state.startDate ? this.state.startDate : "";
                    const endDate = this.state.endDate ? this.state.endDate : "";
                    this.loadData({
                      currentPage: 1,
                      startDate,
                      endDate,
                      productId: this.state.product ? this.state.product.id : null,
                    })
                  }}
                  iconOnly
                >
                  <Icon>search</Icon>
                </MDButton>
              </Grid>
            </Grid>
          </MDBox>
          <MDBox p={2}>
            <DataTable
              isLoading={this.state.isLoading}
              tableHead={this.state.tableHead}
              tableData={this.state.rows}
            />
            <Pagination
              totalPages={this.state.totalPages}
              totalData={this.state.totalData}
              currentPage={this.state.currentPage}
              rowsPerPage={[10, 25, 50, "All"]}
              totalButton={3}
              defaultRowsPerPage={this.state.rowsPerPage}
              onChangeRowsPerPage={(value) => {
                this.setState({ rowsPerPage: value, currentPage: 1 });
                this.loadData({
                  rowsPerPage: value,
                  currentPage: 1,
                  productId: this.state.product ? this.state.product.id : null,
                });
              }}
              onChangePage={(currentPage) => {
                if (currentPage !== this.state.currentPage) {
                  this.setState({ currentPage });
                  this.loadData({
                    currentPage,
                    productId: this.state.product ? this.state.product.id : null,
                  });
                }
              }}
            />
          </MDBox>
        </Card>
      </MDBox>
    );
  }
}

export default AgentCommission;
