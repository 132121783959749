import React from "react";
import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import Grow from "@mui/material/Grow";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import MDBox from "components/MDBox";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

class Notification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      color: props.color,
      icon: props.icon,
      title: props.title,
      content: props.content,
      dateTime: props.dateTime,
      open: props.open,
      close: props.close,
    };
  }

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ open: false });
  };

  setShow(data) {
    this.setState({
      ...data,
      // show, color, message
    });
  }

  render() {
    return (
      <Stack spacing={2} sx={{ width: "100%" }}>
        <MDSnackbar
          autoHideDuration={3500}
          color={this.state.color}
          icon={this.state.icon}
          title={this.state.title}
          content={this.state.content}
          dateTime={this.state.dateTime}
          open={this.state.open}
          close={this.handleClose}
          onClose={() => this.setState({ open: false })}
        />
      </Stack>
    );
  }
}

Notification.defaultProps = {
  icon: "notification",
  title: "notifikasi",
  dateTime: "1 second",
  content: "notifikasi",
  open: false,
  close: true,
  color: "info",
};

Notification.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "success",
    "error",
    "warning",
    "info",
    "light",
    "dark",
  ]),
  icon: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
  dateTime: PropTypes.string,
  open: PropTypes.bool,
  close: PropTypes.bool,
};

export default Notification;
