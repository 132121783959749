/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from "prop-types";
import { Link, Navigate } from 'react-router-dom';
// @mui material components
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from 'components/MDInput';
import MDTypography from "components/MDTypography";
import MDDatePicker from 'components/MDDatePicker';

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import ModalNotif from 'contents/Components/ModalNotif';
import withRouter from 'contents/Components/withRouter';
import ButtonBack from 'contents/Components/ButtonBack';

import Config from 'config'
import moment from 'moment'
import _ from "lodash"
import * as EmailValidator from 'email-validator'

import useAxios from "libs/useAxios"
import secureStorage from 'libs/secureStorage';

class FormMember extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			userId: null,

			name: "",
			email: "",
			phone: "",
			placeBirth: "",
			dateBirth: "",
			address: "",
			nik: "",
			nokk: "",
			gender: "Male",

			statusPerson: null,
			province: null,
			district: null,

			genderOpen: false,
			statusPersonOpen: false,
			provinceOpen: false,
			districtOpen: false,

			provinces: [],
			districts: [],
			statusPersons: [
				{ id: 1, label: "BELUM KAWIN" },
				{ id: 2, label: "KAWIN" },
				{ id: 3, label: "CERAI HIDUP" },
				{ id: 4, label: "CERAI MATI" }
			],

			error: [],
			success: [],

			disabledSubmit: false,
			redirect: null
		}

		this.inputRef = React.createRef()
		this.modalNotifRef = React.createRef()
	}

	componentDidMount() {
		this.inputRef.current && this.inputRef.current.focus()
		const id = this.props.params?.id
		this.setState({ userId: id }, () => {
			this.loadDetail(id)
		})
	}

	loadDetail = (userId) => {
		useAxios()
			.post(`${Config.ApiUrl}/user/member/detail`, { userId })
			.then(response => {
				const data = response.data
				const user = data.User ? data.User : null
				const province = data.Province ? data.Province : null
				const district = data.District ? data.District : null
				const statusPerson = data.StatusPerson ? data.StatusPerson : null

				this.setState({
					disabledSubmit: false,

					name: data.name,
					gender: data.gender ? data.gender : "",
					placeBirth: data.placeBirth ? data.placeBirth : "",
					dateBirth: data.dateBirth,
					phone: data.phone ? data.phone : "",
					email: data.email ? data.email : "",
					nik: data.nik ? data.nik : "",
					nokk: data.nokk ? data.nokk : "",
					address: data.address ? data.address : "",

					province: province ? {
						id: province.id,
						label: province.name
					} : null,
					district: district ? {
						id: district.id,
						label: district.name
					} : null,
					statusPerson: statusPerson ? {
						id: statusPerson.id,
						label: statusPerson.name
					} : null,

					success: {
						...this.state.success,
						name: data.name ? true : false,
						gender: data.gender ? true : false,
						phone: data.phone ? true : false,
						email: data.email ? true : false,
						placeBirth: data.placeBirth ? true : false,
						dateBirth: data.dateBirth ? true : false,
						address: data.address ? true : false,
						nik: data.nik ? true : false,
						nokk: data.nokk ? true : false,
						statusPerson: statusPerson ? true : false,
						province: province ? true : false,
						district: district ? true : false,
					}
				})
			})
			.catch(err => {
				console.log(err)
				this.modalNotifRef.current.setShow({
					modalTitle: 'Gagal',
					modalMessage: err.response ? err.response.data : "Koneksi jaringan terputus",
					onClose: () => {
						this.setState({ redirect: '/adm/member' })
					}
				})
			})
	}

	loadProvince = () => {
		useAxios()
			.post(`${Config.ApiUrl}/master/region/province/dropdown`, { countryId: 1 })
			.then(response => {
				const data = response.data
				const provinces = data.map(item => ({
					id: item.id,
					label: item.name
				}))
				this.setState({ provinces })
			})
			.catch(err => {
				console.log(err)
			})
	}

	loadDistrict = (provinceId) => {
		useAxios()
			.post(`${Config.ApiUrl}/master/region/district/dropdown`, { provinceId })
			.then(response => {
				const data = response.data
				const districts = data.map(item => ({ id: item.id, label: item.name }))
				this.setState({ districts })
			})
			.catch(err => {
				console.log(err)
			})
	}

	handleChange = (e) => {
		const { id, value } = e.target;
		this.setState({
			[id]: value
		})
	}

	handleBlur = (e) => {
		const user = this.state.user
		const { id, value } = e.target
		// eslint-disable-next-line no-empty
		if (value) {
			if (id === "email" && !EmailValidator.validate(value)) {
				this.setState({
					success: { ...this.state.success, [e.target.id]: false },
					error: { ...this.state.error, [e.target.id]: true }
				})
			}
			else {
				this.setState({
					success: { ...this.state.success, [e.target.id]: true },
					error: { ...this.state.error, [e.target.id]: false }
				})
			}
		}
		else {
			this.setState({
				success: { ...this.state.success, [e.target.id]: false },
				error: { ...this.state.error, [e.target.id]: true }
			})
		}
	}

	handleSumit = () => {
		const success = this.state.success

		console.log({ success })
		if (
			success.name &&
			success.phone &&
			success.email &&
			success.gender &&
			success.statusPerson &&
			success.placeBirth &&
			success.nik &&
			success.nokk &&
			success.province &&
			success.district &&
			success.address
		) {
			this.sendData()
		}
		else {
			this.modalNotifRef.current.setShow({
				modalTitle: "Gagal",
				modalMessage: "Data form masih belum lengkap, Silahkan di cek kembali !",
			})
		}
	}

	sendData = () => {
		this.setState({ disabledSubmit: true })

		const payload = {
			userId: this.state.userId,
			name: this.state.name,
			phone: this.state.phone,
			email: this.state.email,
			gender: this.state.gender,
			placeBirth: this.state.placeBirth,
			dateBirth: this.state.dateBirth,
			statusPersonId: this.state.statusPerson?.id,
			nik: this.state.nik,
			nokk: this.state.nokk,
			provinceId: this.state.province?.id,
			districtId: this.state.district?.id,
			address: this.state.address,
		}

		useAxios()
			.post(`${Config.ApiUrl}/user/member/update`, payload)
			.then(response => {
				this.modalNotifRef.current.setShow({
					modalTitle: "Sukses",
					modalMessage: response.data,
					onClose: () => {
						this.setState({ redirect: '/adm/member', disabledSubmit: false })
					}
				})
			})
			.catch(err => {
				console.log(err)
				this.setState({ disabledSubmit: false })
				if (err.response) {
					this.modalNotifRef.current.setShow({
						modalTitle: "Gagal",
						modalMessage: err.response.data ? err.response.data : "Terjadi kesalahan pada system",
					})
				}
				// eslint-disable-next-line no-empty
				else {
					this.modalNotifRef.current.setShow({
						modalTitle: 'Gagal',
						modalMessage: "Koneksi jaringan terputus",
					})
				}
			})
	}

	render() {
		const user = secureStorage.getItem('user')

		if (this.state.redirect) {
			return <Navigate to={this.state.redirect} />
		}

		return (
			<MDBox pb={3} my={3}>
				<ModalNotif ref={this.modalNotifRef} />
				<Card>
					{/* Title */}
					<MDBox
						p={3}
						width="100%"
						display="flex"
						justifyContent="center"
					>
						<MDTypography variant="h5" textTransform="capitalize" fontWeight="medium">
							UBAH DATA MEMBER
						</MDTypography>
					</MDBox>

					{/* Form */}
					<MDBox p={3}>
						<MDBox component="form" role="form">
							<Grid container spacing={3}>
								{/* Left */}
								<Grid item xs={12} md={6} lg={6}>
									{/* Name */}
									<MDBox mb={2}>
										<MDInput
											id="name"
											type="text"
											label="Nama"
											inputRef={this.inputRef}
											value={this.state.name}
											onChange={this.handleChange}
											onBlur={this.handleBlur}
											success={this.state.success ? this.state.success.name : false}
											error={this.state.error ? this.state.error.name : false}
											fullWidth
										/>
									</MDBox>
									{/* Email */}
									<MDBox mb={2}>
										<MDInput
											id="email"
											type="text"
											label="Email"
											value={this.state.email}
											onChange={this.handleChange}
											onBlur={this.handleBlur}
											success={this.state.success ? this.state.success.email : false}
											error={this.state.error ? this.state.error.email : false}
											fullWidth
										/>
									</MDBox>
									{/* Phone */}
									<MDBox mb={2}>
										<MDInput
											id="phone"
											type="text"
											label="Nomor Telpon"
											value={this.state.phone}
											onChange={this.handleChange}
											onBlur={this.handleBlur}
											success={this.state.success ? this.state.success.phone : false}
											error={this.state.error ? this.state.error.phone : false}
											fullWidth
										/>
									</MDBox>
									{/* Gender */}
									<MDBox mb={2}>
										<Autocomplete
											disableClearable
											value={this.state.gender}
											open={this.state.genderOpen}
											onOpen={() => this.setState({ genderOpen: true })}
											onClose={() => this.setState({ genderOpen: false })}
											onFocus={() => this.setState({ genderOpen: true })}
											options={["Male", "Female"]}
											onChange={(e, value) => {
												if (value) {
													this.setState({
														gender: value,
														error: { ...this.state.error, gender: false },
														success: { ...this.state.success, gender: true }
													})
												}
											}}
											sx={{
												".MuiAutocomplete-input": {
													padding: "7.5px 5px 7.5px 8px !important"
												},
												".MuiOutlinedInput-root": {
													padding: "2px !important"
												}
											}}
											isOptionEqualToValue={(option, value) => option === value}
											renderInput={(params) => <MDInput label="Jenis Kelamin" {...params} />}
										/>
									</MDBox>
									{/* Status Person */}
									<MDBox mb={2}>
										<Autocomplete
											disableClearable
											open={this.state.statusPersonOpen}
											onOpen={() => this.setState({ statusPersonOpen: true })}
											onClose={() => this.setState({ statusPersonOpen: false })}
											onFocus={() => this.setState({ statusPersonOpen: true })}
											options={this.state.statusPersons}
											value={this.state.statusPerson}
											onChange={(e, value) => {
												if (value) {
													this.setState({
														statusPerson: value,
														error: { ...this.state.error, statusPerson: false },
														success: { ...this.state.success, statusPerson: true }
													})
												}
											}}
											sx={{
												".MuiAutocomplete-input": {
													padding: "7.5px 5px 7.5px 8px !important"
												},
												".MuiOutlinedInput-root": {
													padding: "4px !important"
												}
											}}
											isOptionEqualToValue={(option, value) => option.id === value.id}
											renderInput={(params) => <MDInput label="Status Pernikahan" {...params} />}
										/>
									</MDBox>
									{/* Place Birth */}
									<MDBox mb={2}>
										<MDInput
											id="placeBirth"
											type="text"
											label="Tempat Lahir"
											value={this.state.placeBirth}
											onChange={this.handleChange}
											onBlur={this.handleBlur}
											success={this.state.success ? this.state.success.placeBirth : false}
											error={this.state.error ? this.state.error.placeBirth : false}
											fullWidth
										/>
									</MDBox>
									{/* Date Birth */}
									<MDBox mb={2}>
										<MDDatePicker
											input={{
												id: "dateBirth",
												placeholder: "Tanggal Lahir",
												fullWidth: true
											}}
											value={this.state.dateBirth}
											onChange={(value) => {
												const date = moment(value[0]).format('YYYY-MM-DD')
												this.setState({
													dateBirth: date,
													error: { ...this.state.error, dateBirth: false },
													success: { ...this.state.success, dateBirth: true }
												})
											}}
										/>
									</MDBox>

								</Grid>
								{/* Right */}
								<Grid item xs={12} md={6} lg={6}>
									{/* NIK */}
									<MDBox mb={2}>
										<MDInput
											id="nik"
											type="text"
											label="Nomor KTP"
											value={this.state.nik}
											onChange={this.handleChange}
											onBlur={this.handleBlur}
											success={this.state.success ? this.state.success.nik : false}
											error={this.state.error ? this.state.error.nik : false}
											fullWidth
										/>
									</MDBox>
									{/* NOKK */}
									<MDBox mb={2}>
										<MDInput
											id="nokk"
											type="text"
											label="Nomor Kartu Keluarga"
											value={this.state.nokk}
											onChange={this.handleChange}
											onBlur={this.handleBlur}
											success={this.state.success ? this.state.success.nokk : false}
											error={this.state.error ? this.state.error.nokk : false}
											fullWidth
										/>
									</MDBox>
									{/* Province */}
									<MDBox mb={2}>
										<Autocomplete
											fullWidth
											id="province"
											open={this.state.provinceOpen}
											onOpen={() => this.setState({ provinceOpen: true })}
											onClose={() => this.setState({ provinceOpen: false })}
											onFocus={() => this.setState({ provinceOpen: true })}
											value={this.state.province}
											options={this.state.provinces}
											onChange={(e, newValue) => {
												if (newValue) {
													this.setState({
														province: newValue,
														district: null,
														error: { ...this.state.error, province: false },
														success: { ...this.state.success, province: true }
													})
													this.loadDistrict(newValue.id)
												}
											}}
											sx={{
												".MuiAutocomplete-input": {
													padding: "7.5px 5px 7.5px 8px !important"
												},
												".MuiOutlinedInput-root": {
													padding: "4px !important"
												}
											}}
											isOptionEqualToValue={(option, value) => option.id === value.id}
											renderInput={(params) => <MDInput label="Pilih Provinsi" {...params} />}
										/>
									</MDBox>
									{/* District */}
									<MDBox mb={2}>
										<Autocomplete
											fullWidth
											id="district"
											open={this.state.districtOpen}
											onOpen={() => this.setState({ districtOpen: true })}
											onClose={() => this.setState({ districtOpen: false })}
											onFocus={() => this.setState({ districtOpen: true })}
											value={this.state.district}
											options={this.state.districts}
											onChange={(e, value) => {
												if (value) {
													this.setState({
														district: value,
														error: { ...this.state.error, district: false },
														success: { ...this.state.success, district: true }
													})
												}
											}}
											sx={{
												".MuiAutocomplete-input": {
													padding: "7.5px 5px 7.5px 8px !important"
												},
												".MuiOutlinedInput-root": {
													padding: "4px !important"
												}
											}}
											isOptionEqualToValue={(option, value) => option.id === value.id}
											renderInput={(params) => <MDInput label="Pilih Kabupaten/Kota" {...params} />}
										/>
									</MDBox>
									{/* Address */}
									<MDBox mb={2}>
										<MDInput
											id="address"
											type="text"
											rows={3}
											multiline
											label="Alamat"
											value={this.state.address}
											onChange={this.handleChange}
											onBlur={this.handleBlur}
											success={this.state.success ? this.state.success.address : false}
											error={this.state.error ? this.state.error.address : false}
											fullWidth
										/>
									</MDBox>
								</Grid>
							</Grid>
						</MDBox>
					</MDBox>

					{/* Button Submit */}
					<MDBox p={3} display="flex" justifyContent="flex-end">
						<MDBox mr={1}>
							<ButtonBack label={"KEMBALI"} />
						</MDBox>
						<MDButton
							type="button"
							variant="gradient"
							color="info"
							disabled={this.state.disabledSubmit}
							onClick={this.handleSumit}
						>
							SIMPAN
						</MDButton>
					</MDBox>
				</Card>
			</MDBox>
		)
	}

}

export default withRouter(FormMember)