/* eslint-disable react/prop-types */
import React, { Fragment } from "react"
import { Document, Page, Text, Image, View, StyleSheet, Font } from "@react-pdf/renderer"

import logo from "assets/img/logoApp.png"

import RobotoMonoRegular from "assets/fonts/RobotoMono/RobotoMono-Regular.ttf"
import RobotMonoBold from "assets/fonts/RobotoMono/RobotoMono-Bold.ttf"
import RobotoMonoBoldItalic from "assets/fonts/RobotoMono/RobotoMono-BoldItalic.ttf"
import RobotoMonoItalic from "assets/fonts/RobotoMono/RobotoMono-Italic.ttf"

import PaymentStyle from "helper/styles/paymentListStyles"
import Config from "config";

Font.register({
	family: 'RobotoMono',
	format: 'truetype',
	fonts: [
		{ src: RobotoMonoRegular },
		{ src: RobotoMonoBoldItalic },
		{ src: RobotoMonoItalic },
		{ src: RobotMonoBold },
	]
})

const bgColor = "#9c88ff"
const borderColor = "#8c7ae6"
const styles = StyleSheet.create(PaymentStyle)

function PdfPaymentRecap({ data }) {
	const items = data.items ? data.items : []
	const banks = data ? data.banks : []

	return (
		<Document>
			<Page size="A4" style={styles.page}>
				{/* HEADER */}
				<View style={styles.headerContainer}>
					{/* Header Left */}
					<View style={styles.headerContent}>
						<Image style={styles.headerLogo} src={data.logo ? `${Config.ApiAsset}/merchant/${data.logo}` : logo} cache />
						<View style={styles.headerContentDesc}>
							<Text style={styles.company}>{data.company}</Text>
							<Text style={styles.headerCaption}>{data.address}</Text>
							<Text style={styles.headerCaption}>Email: {data.email}</Text>
							<Text style={styles.headerCaption}>Telpon: {data.phone}</Text>
						</View>
					</View>
				</View>

				<View style={styles.contentContainer}>
					{/* TITLE */}
					<View style={styles.titleContainer}>
						<Text style={styles.titleText}>{data.title}</Text>
					</View>

					{/* Bill Detail */}
					<View style={styles.billContainer}>
						<View style={styles.billLeft}>
							<View style={styles.billRow}>
								<Text style={{ ...styles.billCaption, width: "30%" }}>Kode Tagihan</Text>
								<Text style={{ width: "2%", fontSize: 5, textAlign: "left" }}>:</Text>
								<Text style={{ ...styles.billCaption, width: "50%" }}>{data.billCode}</Text>
							</View>
							<View style={styles.billRow}>
								<Text style={{ ...styles.billCaption, width: "30%" }}>Pelanggan</Text>
								<Text style={{ width: "2%", fontSize: 5, textAlign: "left" }}>:</Text>
								<Text style={{ ...styles.billCaption, width: "50%" }}>{data.customerName}</Text>
							</View>
							<View style={styles.billRow}>
								<Text style={{ ...styles.billCaption, width: "30%" }}>Nama Paket</Text>
								<Text style={{ width: "2%", fontSize: 5, textAlign: "left" }}>:</Text>
								<Text style={{ ...styles.billCaption, width: "50%" }}>{data.productName}</Text>
							</View>
						</View>
						<View style={styles.billRight}>
							<View style={styles.billRow}>
								<Text style={{ ...styles.billCaption, width: "40%" }}>Total Tagihan</Text>
								<Text style={{ width: "2%", fontSize: 5, textAlign: "left" }}>:</Text>
								<Text
									style={{
										...styles.billCaption,
										width: "50%",
										fontWeight: "bold",
										fontSize: 11,
										textAlign: "right"
									}}
								>
									{data.billAmount}
								</Text>
							</View>
							<View style={styles.billRow}>
								<Text style={{ ...styles.billCaption, width: "40%" }}>Tagihan Dibayar</Text>
								<Text style={{ width: "2%", fontSize: 5, textAlign: "left" }}>:</Text>
								<Text
									style={{
										...styles.billCaption,
										width: "50%",
										fontWeight: "bold",
										fontSize: 11,
										textAlign: "right"
									}}
								>
									{data.billPaidAmount}
								</Text>
							</View>
							<View style={styles.billRow}>
								<Text style={{ ...styles.billCaption, width: "40%" }}>Sisa Tagihan</Text>
								<Text style={{ width: "2%", fontSize: 5, textAlign: "left" }}>:</Text>
								<Text
									style={{
										...styles.billCaption,
										width: "50%",
										fontWeight: "bold",
										fontSize: 11,
										textAlign: "right"
									}}
								>
									{data.billCurrAmount}
								</Text>
							</View>
						</View>
					</View>

					{/* Table */}
					<View style={styles.tableContainer}>
						{/* Table Head */}
						<View style={styles.tableHeadContainer}>
							<Text style={styles.tableHeadCaption}>Kode</Text>
							<Text style={{ ...styles.tableHeadCaption, width: "30%" }}>Tanggal</Text>
							<Text style={{ ...styles.tableHeadCaption, width: "25%", textAlign: "center" }}>Status</Text>
							<Text style={{ ...styles.tableHeadCaption, width: "25%", textAlign: "right" }}>Nominal</Text>
							{/* <Text style={{ ...styles.tableHeadCaption, width: "25%", textAlign: "right", }}>Tagihan</Text> */}
						</View>
						{/* Table Body */}
						<Fragment>
							{items.map((item, key) => {
								const statusText = item.statusId === 1 ? "Pending" : item.statusId === 2 ? "Canceled" : "Approved"
								const textColor = item.statusId === 1 ? { color: styles.secondary.color } :
									item.statusId === 2 ? { color: styles.error.color } : { color: styles.success.color }

								return (
									<View key={key} style={styles.tableRow}>
										<Text style={styles.tableRowCaption}>{item.kode}</Text>
										<Text style={{ ...styles.tableRowCaption, textAlign: "left", width: "30%" }}>{item.createdAt}</Text>
										<Text style={{ ...styles.tableRowCaption, width: "25%", textAlign: "center", ...textColor }}>
											{statusText}
										</Text>
										<Text style={{ ...styles.tableRowCaption, width: "25%", textAlign: "right", fontWeight: "bold" }}>{item.amount}</Text>
										{/* <Text style={{ ...styles.tableRowCaption, width: "25%", textAlign: "right", fontWeight: "bold" }}>{item.currAmount}</Text> */}
									</View>
								)
							})}
						</Fragment>
						{/* Table Footer */}
					</View>

					{/* NOTE */}
					<View style={styles.noteContainer}>
						<Text style={styles.noteCaption}>
							Untuk Pembayaran bisa melalui bank berikut :
						</Text>

						{banks.map((item, key) => (
							<Text style={styles.noteItem} key={key}>
								- BANK {item.name}, NO.REK : {item.accountNumber}, {item.accountName}
							</Text>
						))}
					</View>
				</View>
			</Page>
		</Document >
	)
}

export default PdfPaymentRecap