import React, { useState } from "react";
import PropTypes from "prop-types";
import { Navigate, Link } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDAvatar from "components/MDAvatar";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import DataTable from 'contents/Components/DataTable'
import Pagination from "contents/Components/Pagination";
import ButtonAction from "contents/Administrasi/ListMember/components/ButtonAction"

import useAxios from "libs/useAxios"
import Config from 'config'
import secureStorage from "libs/secureStorage";
import { fnumber } from "libs/currency";

class ListJamaah extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			merchant: null,
			product: null,

			merchants: [],
			products: [],

			rowsPerPage: 10,
			currentPage: 1,
			totalPages: 0,
			totalData: 0,
			tableHead: [
				{ Header: "action", accessor: "action", width: "10%" },
				// { Header: "kode", accessor: "kode", width: "15%" },
				{ Header: "nama", accessor: "name", width: "25%" },
				// { Header: "no ktp", accessor: "nik", width: "20%" },
				{ Header: "email", accessor: "email", width: "20%" },
				{ Header: "phone", accessor: "phone", width: "20%" },
				{ Header: "pembayaran", accessor: "paymentType", width: "15%" },
				{ Header: "status", accessor: "status", width: "15%" },
			],
			rows: [],
			isLoading: true,
		}
	}

	componentDidMount() {
		this.initData()
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.view !== this.props.view) {
			this.initData()
		}
	}

	initData = () => {
		const user = secureStorage.getItem('user')

		user && user.roleId === 1 && this.loadMerchants()
		user && user.roleId !== 1 && this.loadProducts()
		this.setState({
			merchant: null,
			product: null,
			products: []
		}, () => {
			this.loadData()
		})
	}

	loadMerchants = () => {
		useAxios()
			.post(`${Config.ApiUrl}/manage/merchant/dropdown`)
			.then(response => {
				const data = response.data

				this.setState({
					merchant: null,
					merchants: data.map(item => ({ id: item.id, label: item.name }))
				})
			})
			.catch(err => {
				console.log(err)
			})
	}

	loadProducts = (params) => {
		const view = this.props.view

		const productCategoryId = view === "umroh" ? 2 : view === "haji" ? 1 : 3
		const merchantId = params && params.merchantId ? { merchantId: params.merchantId } : {}

		const payload = {
			productCategoryId,
			...merchantId
		}

		useAxios()
			.post(`${Config.ApiUrl}/product/dropdown`, payload)
			.then(response => {
				const data = response.data

				this.setState({
					product: null,
					products: data.map(item => ({ id: item.id, label: item.name })),
				})
			})
			.catch(err => {
				console.log(err)
			})
	}

	loadData = (params) => {
		this.setState({ isLoading: true })
		const user = secureStorage.getItem('user')
		const view = this.props.view

		const merchantId = params && params.merchantId ? { merchantId: params.merchantId } : {}
		const productId = params && params.productId ? { productId: params.productId } : {}
		const productCategoryId = view === "umroh" ? 2 : view === "haji" ? 1 : 3

		const payload = {
			currentPage: params && params.currentPage ? params.currentPage : 1,
			rowsPerPage: params && params.rowsPerPage ? params.rowsPerPage : this.state.rowsPerPage,
			productCategoryId,
			...productId,
			...merchantId
		}

		useAxios()
			.post(`${Config.ApiUrl}/user/member/bycategory`, payload)
			.then(response => {
				const data = response.data
				const output = data.output.map(item => {
					const member = item.Member
					const departureStatus = item.DepartureStatus

					const status = departureStatus && departureStatus.name ? departureStatus.name : ""
					return {
						action: (
							<ButtonAction
								id={item.id}
								refreshData={this.loadData}
							/>
						),
						name: (
							<MDBox style={{ wordWrap: "break-word", width: "13em" }}>
								<MDBox display="flex" flexDirection="column">
									<MDTypography variant="caption" fontWeight="regular">
										Kode: {member && member.kode ? member.kode : "-"}
									</MDTypography>
									<MDTypography variant="caption" fontWeight="regular">
										Nama: {member && member.name ? member.name : "-"}
									</MDTypography>
								</MDBox>
							</MDBox>
						),
						nik: (
							<MDTypography variant="caption" fontWeight="regular">
								{member && member.nik ? member.nik : "-"}
							</MDTypography>
						),
						email: (
							<MDTypography variant="caption" fontWeight="regular">
								{item.email}
							</MDTypography>
						),
						phone: (
							<MDTypography variant="caption" fontWeight="regular">
								{item.phone}
							</MDTypography>
						),
						paymentType: (
							<MDTypography variant="caption" fontWeight="regular">
								{member && member.paymentType ? member.paymentType : "-"}
							</MDTypography>
						),
						status: (
							<MDTypography variant="caption" fontWeight="regular">
								{status}
							</MDTypography>
						)
					}
				})

				this.setState({
					totalPages: data.totalPages,
					totalData: data.totalData,
					rows: output,
					isLoading: false
				})
			})
			.catch(err => {
				console.log(err)
				this.setState({ isLoading: false })
			})
	}

	render() {
		const user = secureStorage.getItem('user')
		const view = this.props.view
		const title = view === "umroh" ? "Daftar Jamaah Umroh" : view === "haji" ? "Daftar Jamaah Haji Plus" : "Daftar Jamaah Haji Furoda"

		return (
			<MDBox pb={3} my={3}>
				<Card>
					<MDBox p={2} lineHeight={1}>
						<MDTypography variant="h5" fontWeight="medium">
							{title}
						</MDTypography>
					</MDBox>

					{/* FIlter */}
					<MDBox px={2} width="100%" display="flex" justifyContent="flex-start">
						<Grid container spacing={3}>
							{/* Merchant */}
							{[1].includes(user.roleId) && (
								<Grid item xs={12} md={3} lg={3}>
									<Autocomplete
										value={this.state.merchant}
										options={this.state.merchants}
										onChange={(e, value) => {
											const params = value ? { merchantId: value.id } : {}
											this.loadProducts(params)

											this.setState({
												merchant: value,
												product: null,
											}, () => {
												this.loadData({
													currentPage: 1,
													merchantId: value ? value.id : null,
												})
											})
										}}
										sx={{
											".MuiAutocomplete-input": {
												padding: "7.5px 5px 7.5px 8px !important"
											},
											".MuiOutlinedInput-root": {
												padding: "1.5px !important"
											}
										}}
										isOptionEqualToValue={(option, value) => option.id === value.id}
										renderInput={(params) => <MDInput sx={{ padding: "0px" }} fullWidth label="Pilih Biro Travel" {...params} />}
									/>
								</Grid>
							)}
							{/* Product */}
							<Grid item xs={12} md={3} lg={3}>
								<Autocomplete
									value={this.state.product}
									options={this.state.products}
									onChange={(e, value) => {
										this.setState({ product: value }, () => {
											this.loadData({
												currentPage: 1,
												productId: value ? value.id : null,
												merchantId: this.state.merchant ? this.state.merchant.id : null
											})
										})
									}}
									sx={{
										".MuiAutocomplete-input": {
											padding: "7.5px 5px 7.5px 8px !important"
										},
										".MuiOutlinedInput-root": {
											padding: "1.5px !important"
										}
									}}
									isOptionEqualToValue={(option, value) => option.id === value.id}
									renderInput={(params) => <MDInput sx={{ padding: "0px" }} fullWidth label="Pilih Paket" {...params} />}
								/>
							</Grid>
							{/* Button Search */}
							<Grid item xs={12} md={3} lg={3}>
								<MDBox
									width="100%"
									display="flex"
									flexDirection="row"
									justifyContent="flex-start"
								>
									<MDButton
										color="info"
										variant="gradient"
										onClick={() => {
											this.loadData({
												currentPage: 1,
												productId: this.state.product ? this.state.product.id : null,
												merchantId: this.state.merchant ? this.state.merchant.id : null
											})
										}}
										iconOnly
									>
										<Icon>search</Icon>
									</MDButton>
									<MDBox ml={1}>
										<MDButton
											color="error"
											variant="gradient"
											onClick={() => {
												this.setState({
													currentPage: 1,
													product: null,
													merchant: null
												}, () => {
													this.loadData()
												})
											}}
											iconOnly
										>
											<Icon>refresh</Icon>
										</MDButton>
									</MDBox>
								</MDBox>
							</Grid>
						</Grid>
					</MDBox>

					<MDBox p={2}>
						<DataTable
							isLoading={this.state.isLoading}
							tableHead={this.state.tableHead}
							tableData={this.state.rows}
						/>
						<Pagination
							totalPages={this.state.totalPages}
							totalData={this.state.totalData}
							currentPage={this.state.currentPage}
							rowsPerPage={[10, 25, 50, "All"]}
							totalButton={3}
							defaultRowsPerPage={this.state.rowsPerPage}
							onChangeRowsPerPage={(value) => {
								this.setState({ rowsPerPage: value, currentPage: 1 })
								this.loadData({
									rowsPerPage: value,
									currentPage: 1,
									merchantId: this.state.merchant ? this.state.merchant.id : null
								})
							}}
							onChangePage={(currentPage) => {
								if (currentPage !== this.state.currentPage) {
									this.setState({ currentPage })
									this.loadData({
										currentPage,
										merchantId: this.state.merchant ? this.state.merchant.id : null
									})
								}
							}}
						/>
					</MDBox>
				</Card>
			</MDBox>
		)
	}
}

ListJamaah.propTypes = {
	view: PropTypes.oneOf(['umroh', 'haji', 'furoda'])
}

export default ListJamaah