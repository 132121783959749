/* eslint-disable react/prop-types */
import React from "react";
import { Link, Navigate } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import MDDatePicker from "components/MDDatePicker";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import ModalNotif from "contents/Components/ModalNotif";
import withRouter from "contents/Components/withRouter";

import MiniFormCard from "contents/Components/FormCard/MiniFormCard";
import ListPrincipal from "contents/Administrasi/Certificate/components/ListPrincipal";
import PrincipalCertificate from "contents/Administrasi/Certificate/components/PrincipalCertificate";

import Config from "config";
import _ from "lodash";
import moment from "moment";

import useAxios from "libs/useAxios";
import secureStorage from "libs/secureStorage";

class FormPoa extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      title: "FORM SURAT PENGANTAR",
      action: "",
      id: null,

      place: "",
      letterDate: "",
      direction: "",

      principal: [],
      tmpPrincipal: [],
      error: [],
      success: [],

      type: "Keterangan",
      types: ["Keterangan", "Surat Kuasa"],

      disabledSubmit: false,
      redirect: null,
    };

    this.inputRef = React.createRef();
    this.modalNotifRef = React.createRef();

    this.listPrincipalRef = React.createRef();
    this.principalCertificateRef = React.createRef();
  }

  componentDidMount() {
    // this.inputRef.current.focus();

    const user = secureStorage.getItem("user");
    this.setState({
      user: user ? user : null,
    });
    this.loadPath();
  }

  loadPath() {
    const pathname = window.location.pathname;
    const index = pathname.indexOf("edit");
    if (index === -1) {
      this.setState({
        title: "Tambah Surat Keterangan Baru",
        action: "add",
      });
    } else {
      const id = this.props.params?.id;
      this.loadDetail(id);
      this.setState({
        id,
        title: "Ubah Surat Keterangan",
        action: "edit",
        error: {
          ...this.state.error,
          name: false,
          remark: false,
          file: false,
        },
        success: {
          ...this.state.success,
          name: true,
          remark: true,
          file: true,
        },
      });
    }
  }

  handleChange = (e) => {
    const { id, value } = e.target;
    this.setState({
      [id]: value,
    });
  };

  handleBlur = (e) => {
    const { id, value } = e.target;

    if (value) {
      this.setState({
        success: { ...this.state.success, [e.target.id]: true },
        error: { ...this.state.error, [e.target.id]: false },
      });
    } else {
      this.setState({
        success: { ...this.state.success, [e.target.id]: false },
        error: { ...this.state.error, [e.target.id]: true },
      });
    }
  };

  onSavePrincipal = (params) => {
    const principal = [...this.state.principal, { ...params, action: "add" }];
    this.setState({ principal });
    this.listPrincipalRef.current.setData(principal);
  };

  onDeletePrincipal = (key) => {
    const principal = this.state.principal;
    let tmpPrincipal = this.state.tmpPrincipal;

    const tmpDel = principal[key];
    principal.splice(key, 1);

    if (tmpDel.action === "update")
      tmpPrincipal = [...tmpPrincipal, { ...tmpDel, action: "delete" }];

    this.setState({
      principal,
      tmpPrincipal,
    });
  };

  handleSubmit = () => {
    const success = this.state.success;
    if (
      success.place &&
      success.letterDate &&
      success.direction &&
      this.state.principal.length > 0
    ) {
      this.sendData();
    } else {
      this.modalNotifRef.current.setShow({
        modalTitle: "Gagal",
        modalMessage: "Data form masih belum lengkap, Silahkan di cek kembali !",
      });
    }
  };

  sendData = () => {
    this.setState({ disabledSubmit: true });

    const user = this.state.user
    const principals = this.state.principal

    const payload = {
      type: "SURAT KUASA",
      place: this.state.place,
      date: this.state.letterDate,
      direction: this.state.direction,
      principal: principals.length > 0 ? principals[0].userId : null,
      merchantId: user.Merchant?.id,
    };
    const url =
      this.state.action === "add" ? "/certificate/poa/create" : "/certificate/poa/update";
    useAxios()
      .post(`${Config.ApiUrl}${url}`, payload)
      .then((response) => {
        this.modalNotifRef.current.setShow({
          modalTitle: "Sukses",
          modalMessage: response.data,
          onClose: () => {
            this.setState({ redirect: "/adm/certificate" });
          },
        });
      })
      .catch((err) => {
        this.setState({ disabledSubmit: false });
        if (err.response) {
          this.modalNotifRef.current.setShow({
            modalTitle: "Gagal",
            modalMessage: err.response ? err.response.data : "Koneksi jaringan terputus",
          });
        } else {
          this.modalNotifRef.current.setShow({
            modalTitle: "Gagal",
            modalMessage: "Koneksi jaringan terputus",
          });
        }
      });
  };

  render() {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />;
    }

    return (
      <>
        <ModalNotif ref={this.modalNotifRef} />
        <PrincipalCertificate
          ref={this.principalCertificateRef}
          user={this.state.user}
          onAction={this.onSavePrincipal}
        />
        <MDBox p={3}>
          <MDBox component="form" role="form">
            <Grid container spacing={3} mb={2}>
              <Grid item xs={12} md={6} lg={6}>
                <MDBox mb={2}>
                  <MDInput
                    inputRef={this.inputRef}
                    id="place"
                    type="text"
                    label="Tempat"
                    value={this.state.place}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    onKeyDown={this.handleKeyDown}
                    success={this.state.success ? this.state.success.place : false}
                    error={this.state.error ? this.state.error.place : false}
                    fullWidth
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <MDBox mb={2}>
                  <MDDatePicker
                    input={{
                      id: "letterDate",
                      placeholder: "Tanggal",
                      fullWidth: true,
                    }}
                    value={this.state.letterDate}
                    onChange={(value) => {
                      const date = moment(value[0]).format("YYYY-MM-DD");
                      this.setState({
                        letterDate: date,
                        error: { ...this.state.error, letterDate: false },
                        success: { ...this.state.success, letterDate: true },
                      });
                    }}
                  />
                </MDBox>
              </Grid>
            </Grid>
            <Grid container spacing={3} mb={2}>
              <Grid item xs={12} md={6} lg={6}>
                <MDBox mb={2}>
                  <MDInput
                    id="direction"
                    type="text"
                    label="Tujuan"
                    value={this.state.direction}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    onKeyDown={this.handleKeyDown}
                    success={this.state.success ? this.state.success.direction : false}
                    error={this.state.error ? this.state.error.direction : false}
                    fullWidth
                  />
                </MDBox>
              </Grid>
            </Grid>
            {/* input content jamaah */}
            <Grid container spacing={3} mb={5}>
              <Grid item xs={12} md={12} lg={12}>
                <MDBox
                  mb={1}
                  width="100%"
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <MDTypography
                    component="label"
                    variant="button"
                    fontWeight="medium"
                    color="text"
                    textTransform="capitalize"
                  >
                    Principal
                  </MDTypography>
                  {this.state.principal.length < 1 ? (
                    <MDBox ml={1}>
                      <MDButton
                        variant="gradient"
                        color="info"
                        size="small"
                        iconOnly
                        onClick={() =>
                          this.principalCertificateRef.current.setShow({ show: true })
                        }
                      >
                        <Icon>add</Icon>
                      </MDButton>
                    </MDBox>
                  ) : null}
                </MDBox>
                <ListPrincipal
                  type="form"
                  rows={this.state.principal}
                  ref={this.listPrincipalRef}
                  onDelete={this.onDeletePrincipal}
                />
              </Grid>
            </Grid>
            {/* end input content jamaah */}

            <Grid container spacing={3} mb={2}>
              <Grid item xs={12} md={12} lg={12}>
                <MDBox pt={3} display="flex" justifyContent="space-between">
                  <MDButton
                    variant="gradient"
                    color="error"
                    component={Link}
                    to={{ pathname: "/adm/certificate" }}
                  >
                    KEMBALI
                  </MDButton>
                  <MDButton
                    type="button"
                    variant="gradient"
                    color="info"
                    disabled={this.state.disabledSubmit}
                    onClick={this.handleSubmit}
                  >
                    SUBMIT
                  </MDButton>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
      </>
    );
  }
}

export default withRouter(FormPoa);
