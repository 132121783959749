import React, { useState } from "react";
import PropTypes from "prop-types";
import { Navigate, Link } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

import DataTable from 'contents/Components/DataTable'
import Pagination from "contents/Components/Pagination";
import ButtonAction from "contents/Master/Banner/components/ButtonAction"

import useAxios from "libs/useAxios"
import moment from 'moment'
import Config from 'config'
import secureStorage from "libs/secureStorage";

class Banner extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			rowsPerPage: 10,
			currentPage: 1,
			totalPages: 0,
			totalData: 0,
			tableHead: [
				{ Header: "action", accessor: "action", width: "10%" },
				{ Header: "judul", accessor: "title", width: "20%" },
				{ Header: "gambar", accessor: "image", width: "15%" },
			],
			rows: [],
			keyword: "",
			isLoading: true,
		}
	}

	componentDidMount() {
		this.loadData()
	}

	loadData = (params) => {
		this.setState({ isLoading: true })
		const user = secureStorage.getItem('user')

		const paylaod = {
			keyword: params && params.keyword ? params.keyword : this.state.keyword,
			currentPage: params && params.currentPage ? params.currentPage : 1,
			rowsPerPage: params && params.rowsPerPage ? params.rowsPerPage : this.state.rowsPerPage,
		}

		useAxios()
			.post(`${Config.ApiUrl}/master/banner`, paylaod)
			.then(response => {
				const data = response.data
				const output = data.output.map(item => {
					return {
						title: (
							<p style={{ wordWrap: "break-word", width: "10em" }}>{item.title}</p>
						),
						image: item.image ? (
							<MDBox
								component="img"
								src={`${Config.ApiAsset}/banner/${item.image}`}
								alt="Gambar"
								borderRadius="lg"
								shadow="sm"
								width="100%"
								height="150px"
								position="relative"
								zIndex={10}
								mb={2}
							/>
						) : "-",
						action: user && [1].includes(user.roleId) ? (
							<ButtonAction id={item.id} refreshData={this.loadData} />
						) : '-'
					}
				})

				this.setState({
					totalPages: data.totalPages,
					totalData: data.totalData,
					rows: output,
					isLoading: false
				})
			})
			.catch(err => {
				console.log(err)
				this.setState({ isLoading: false })
			})
	}

	render() {
		const user = secureStorage.getItem('user')

		return (
			<MDBox pb={3} my={3}>
				<MDBox pb={2} mt={{ xs: 2, md: 0 }} display="flex" justifyContent={{ xs: "center", md: "flex-start" }}>
					{[1].includes(user.roleId) && (
						<MDButton
							size="medium"
							color="info"
							variant="gradient"
							component={Link}
							to={{ pathname: "/master/banner/add" }}
						>
							TAMBAH
						</MDButton>
					)}
				</MDBox>
				<Card>
					{/* Title */}
					<MDBox p={2} lineHeight={1}>
						<MDTypography variant="h5" fontWeight="medium">
							Daftar Banner
						</MDTypography >
					</MDBox >

					{/* Filter */}
					<MDBox px={2} width="100%" display="flex" justifyContent="flex-start">
						<Grid container spacing={3}>
							<Grid item xs={12} md={3} lg={3}>
								<MDInput
									label="Search..."
									size="small"
									fullWidth
									value={this.state.keyword}
									onKeyPress={(e) => {
										if (e.key === "Enter") {
											this.loadData({
												currentPage: 1,
												keyword: e.target.value,
											})
										}
									}}
									onChange={(e) => this.setState({ keyword: e.target.value })}
								/>
							</Grid>
						</Grid>
					</MDBox>

					{/* Content */}
					<MDBox p={2}>
						<DataTable
							isLoading={this.state.isLoading}
							tableHead={this.state.tableHead}
							tableData={this.state.rows}
						/>
						<Pagination
							totalPages={this.state.totalPages}
							totalData={this.state.totalData}
							currentPage={this.state.currentPage}
							rowsPerPage={[10, 25, 50, "All"]}
							totalButton={3}
							defaultRowsPerPage={this.state.rowsPerPage}
							onChangeRowsPerPage={(value) => {
								this.setState({ rowsPerPage: value, currentPage: 1 })
								this.loadData({
									rowsPerPage: value,
									currentPage: 1,
								})
							}}
							onChangePage={(currentPage) => {
								if (currentPage !== this.state.currentPage) {
									this.setState({ currentPage })
									this.loadData({
										currentPage,
									})
								}
							}}
						/>
					</MDBox>
				</Card>
			</MDBox>
		)
	}
}

export default Banner