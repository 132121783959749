import React, { useEffect, useState, useRef } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";

import Sidenav from "examples/Sidenav";

// Material Dashboard 2 PRO React contexts
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
  setDarkMode,
} from "context";

import { getMenu } from "routes";

// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import logoApp from "assets/img/logoApp.png"

import secureStorage from "libs/secureStorage";
import { requestForToken, onMessageListener } from "libs/firebase";
import Notification from 'contents/Components/Notification'

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar"

import Config from "config"
import useAxios from "libs/useAxios"

function Layout() {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, sidenavColor, transparentSidenav, whiteSidenav, darkMode } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [user, setUser] = useState(null)

  const location = useLocation()
  const [countNotif, setCountNotif] = useState(0)

  const [show, setShow] = useState(false);
  const toggleSnackbar = () => setShow(!show);

  const navigate = useNavigate();
  const notifRef = useRef()

  // set theme dark && transparent
  useEffect(() => {
    const user = secureStorage.getItem("user");

    if (!user) {
      navigate("/login");
    } else {
      // setTransparentSidenav(dispatch, false);
      // setWhiteSidenav(dispatch, true);
      // setDarkMode(dispatch, false);

      setWhiteSidenav(dispatch, false);
      setTransparentSidenav(dispatch, false);
      setUser(user)
      requestForToken()
      getNotif()
    }
  }, [])

  useEffect(() => {
    getNotif()
  }, [location])

  const getNotif = () => {
    useAxios()
      .post(`${Config.ApiUrl}/notif/count`)
      .then(response => {
        setCountNotif(response.data.count)
      })
      .catch(err => {
        console.log(err)
      })
  }

  // Message Listener
  onMessageListener()
    .then((payload) => {
      toggleSnackbar()
      getNotif() // update count notif
      notifRef.current.setShow({
        color: "info",
        icon: "notifications",
        title: payload?.notification?.title,
        content: payload?.notification?.body,
        dateTime: "Beberapa detik yang lalu ",
        open: true,
        close: { toggleSnackbar },
      })
    })
    .catch((err) => console.log('failed: ', err))

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  return (
    <>
      <Sidenav
        color={sidenavColor}
        brand={logoApp}
        brandName=""
        routes={user ? getMenu(user) : []}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
      />
      <Notification ref={notifRef} />
      <DashboardLayout>
        <DashboardNavbar countNotif={countNotif} />
        <Outlet />
      </DashboardLayout>
    </>
  );
}

export default Layout;
