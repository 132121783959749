import React, { useState } from "react";
import { Link } from "react-router-dom"
import PropTypes from "prop-types";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Tooltip from "@mui/material/Tooltip"
import Autocomplete from "@mui/material/Autocomplete"
import Icon from "@mui/material/Icon"

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput"
import MDButton from "components/MDButton"
import MDTypography from "components/MDTypography"
import MDDatePicker from "components/MDDatePicker"

import Table from "@mui/material/Table"
import TableRow from "@mui/material/TableRow"
import TableBody from "@mui/material/TableBody"
import TableContainer from "@mui/material/TableContainer"

import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell"
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell"

import DataTable from 'contents/Components/DataTable'
import Pagination from "contents/Components/Pagination";

import moment from "moment"
import useAxios from "libs/useAxios"
import Config from 'config'
import secureStorage from "libs/secureStorage";
import { fnumber } from "libs/currency"
import print from "helper/printer"

class OmsetPackage extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			merchant: null,
			product: null,
			startDate: "",
			endDate: "",

			merchants: [],
			products: [],

			totalQty: 0,
			totalFee: 0,
			totalBill: 0,
			totalPaid: 0,
			totalRemaider: 0,

			sales: [],
			isLoading: true,
			disabledSubmit: false,
			disabledPrint: false
		}
	}

	componentDidMount() {
		this.initData()
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.view !== this.props.view) {
			this.initData()
		}
	}

	initData = () => {
		const user = secureStorage.getItem('user')
		const startDate = moment().startOf("year").format("YYYY-MM-DD")
		const endDate = moment().endOf("year").format("YYYY-MM-DD")

		user && user.roleId === 1 && this.loadMerchants()
		this.setState({
			merchant: null,
			product: null,
			startDate,
			endDate,
			products: [],
		}, () => {
			this.loadData()
		})
	}

	loadMerchants = () => {
		useAxios()
			.post(`${Config.ApiUrl}/manage/merchant/dropdown`)
			.then(response => {
				const data = response.data

				this.setState({
					merchant: null,
					merchants: data.map(item => ({ id: item.id, label: item.name }))
				})
			})
			.catch(err => {
				console.log(err)
			})
	}

	loadProducts = (params) => {
		const view = this.props.view

		const productCategoryId = view === "umroh" ? 2 : view === "haji" ? 1 : 3
		const merchantId = params && params.merchantId ? { merchantId: params.merchantId } : { merchantId: this.state.merchant?.id }

		const payload = {
			productCategoryId,
			...merchantId
		}

		useAxios()
			.post(`${Config.ApiUrl}/product/dropdown`, payload)
			.then(response => {
				const data = response.data

				this.setState({
					product: null,
					products: data.map(item => ({ id: item.id, label: item.name })),
				})
			})
			.catch(err => {
				console.log(err)
			})
	}

	loadData = (params) => {
		this.setState({ isLoading: true, disabledSubmit: true })
		const user = secureStorage.getItem('user')
		const view = this.props.view

		const merchantId = params && params.merchantId ? { merchantId: params.merchantId } : {}
		const productId = params && params.productId ? { productId: params.productId } : {}
		const productCategoryId = view === "umroh" ? 2 : view === "haji" ? 1 : 3

		const payload = {
			currentPage: params && params.currentPage ? params.currentPage : 1,
			rowsPerPage: params && params.rowsPerPage ? params.rowsPerPage : this.state.rowsPerPage,
			startDate: params && params.startDate ? params.startDate : this.state.startDate,
			endDate: params && params.endDate ? params.endDate : this.state.endDate,
			productCategoryId,
			...productId,
			...merchantId
		}

		// console.log({ payload })
		useAxios()
			.post(`${Config.ApiUrl}/finance/omset`, payload)
			.then(response => {
				const data = response.data

				let totalQty = 0
				let totalFee = 0
				let totalBill = 0
				let totalPaid = 0
				let totalRemaider = 0

				data.forEach(item => {
					const product = item.Product
					const fee = item.fee ? parseInt(item.fee) : 0
					const qty = parseInt(item.qty)

					totalQty += qty
					totalFee += fee * qty
					totalBill += parseInt(item.amount)
					totalPaid += parseInt(item.paidAmount)
					totalRemaider += parseInt(item.amount) - parseInt(item.paidAmount)
				})

				this.setState({
					isLoading: false,
					disabledSubmit: false,
					sales: data,
					totalQty,
					totalFee,
					totalBill,
					totalPaid,
					totalRemaider
				})
			})
			.catch(err => {
				console.log(err)
				this.setState({ isLoading: false, disabledSubmit: false })
			})
	}

	handlePrint = () => {
		this.setState({ disabledPrint: true })
		const view = this.props.view
		const title = view === 'umroh' ? 'OMSET PAKET UMROH' : view === 'haji' ? 'OMSET PAKET HAJI PLUS' : 'OMSET PAKET HAJI FURODA'

		const header = {
			title,
			merchant: this.state.merchant,
			startDate: this.state.startDate !== "" ? this.state.startDate : moment().startOf("year").format("YY-MM-DD"),
			endDate: this.state.endDate !== "" ? this.state.endDate : moment().startOf("year").format("YY-MM-DD"),
		}

		const body = this.state.sales.map(item => {
			const product = item.Product
			const fee = item.fee ? parseInt(item.fee) : 0
			const qty = parseInt(item.qty)

			return {
				product: product && product.name ? product.name : '',
				qty,
				fee: fnumber(fee * qty),
				amount: fnumber(item.amount),
				remaiderAmount: fnumber(item.amount - item.paidAmount),
				paidAmount: fnumber(item.paidAmount)
			}
		})
		const footer = {
			totalQty: this.state.totalQty,
			totalFee: fnumber(this.state.totalFee),
			totalBill: fnumber(this.state.totalBill),
			totalPaid: fnumber(this.state.totalPaid),
			totalRemaider: fnumber(this.state.totalRemaider)
		}

		print({ ...header, body, ...footer }, 'PdfRecapPackage')
		this.setState({ disabledPrint: false })
	}

	render() {
		const user = secureStorage.getItem('user')
		const view = this.props.view
		const title = view === 'umroh' ? 'OMSET PAKET UMROH' : view === 'haji' ? 'OMSET PAKET HAJI PLUS' : 'OMSET PAKET HAJI FURODA'

		return (
			<MDBox pb={3} my={3}>
				<Card>
					{/* FILTER */}
					<MDBox p={5} width="100%" display="flex" justifyContent="flex-start">
						<Grid container spacing={3} justifyContent="flex-start">
							{/* Merchant */}
							<Grid item xs={12} md={3} lg={3}>
								<Autocomplete
									value={this.state.merchant}
									options={this.state.merchants}
									onChange={(e, value) => {
										const params = value ? { merchantId: value.id } : {}
										const startDate = this.state.startDate ? this.state.startDate : "";
										const endDate = this.state.endDate ? this.state.endDate : "";
										this.setState({ merchant: value }, () => {
											this.loadProducts(params)
											this.loadData({
												startDate,
												endDate,
												currentPage: 1,
												merchantId: value ? value.id : null,
											});
										});
									}}
									sx={{
										".MuiAutocomplete-input": {
											padding: "7.5px 5px 7.5px 8px !important",
										},
										".MuiOutlinedInput-root": {
											padding: "1.5px !important",
										},
									}}
									isOptionEqualToValue={(option, value) => option.id === value.id}
									renderInput={(params) => (
										<MDInput
											sx={{ padding: "0px" }}
											fullWidth
											label="Pilih Biro Travel"
											{...params}
										/>
									)}
								/>
							</Grid>
							{/* Product */}
							<Grid item xs={12} md={3} lg={3}>
								<Autocomplete
									value={this.state.product}
									options={this.state.products}
									onChange={(e, value) => {
										if (value) {
											const startDate = this.state.startDate ? this.state.startDate : ""
											const endDate = this.state.endDate ? this.state.endDate : ""
											this.setState({ product: value }, () => {
												this.loadData({
													startDate,
													endDate,
													currentPage: 1,
													productId: value ? value.id : null,
													merchantId: this.state.merchant ? this.state.merchant.id : null
												})
											})
										}
									}}
									sx={{
										".MuiAutocomplete-input": {
											padding: "7.5px 5px 7.5px 8px !important",
										},
										".MuiOutlinedInput-root": {
											padding: "1.5px !important",
										},
									}}
									isOptionEqualToValue={(option, value) => option.id === value.id}
									renderInput={(params) => (
										<MDInput
											{...params}
											fullWidth
											sx={{ padding: "0px" }}
											placeholder="Pilih Paket"
										/>
									)}
								/>
							</Grid>
							{/* Start Date */}
							<Grid item xs={12} md={2} lg={2}>
								<MDDatePicker
									input={{
										id: "startDate",
										placeholder: "Tanggal Awal",
										fullWidth: true,
										sx: {
											".MuiInputBase-input": {
												height: "1em !important",
											},
										},
									}}
									value={this.state.startDate}
									onChange={(value) => {
										const date = moment(value[0]).format("YYYY-MM-DD");
										this.setState({ startDate: date });
									}}
								/>
							</Grid>
							{/* End Date */}
							<Grid item xs={12} md={2} lg={2}>
								<MDDatePicker
									input={{
										id: "endDate",
										placeholder: "Tanggal Akhir",
										fullWidth: true,
										sx: {
											".MuiInputBase-input": {
												height: "1em !important",
											},
										},
									}}
									value={this.state.endDate}
									onChange={(value) => {
										const date = moment(value[0]).format("YYYY-MM-DD");
										this.setState({ endDate: date });
									}}
								/>
							</Grid>
							{/* Button Search */}
							<Grid item xs={12} md={2} lg={2}>
								<MDBox
									width="100%"
									display="flex"
									flexDirection="row"
									justifyContent="flex-start"
								>
									<Tooltip title="Submit Pencarian">
										<MDButton
											color="info"
											variant="gradient"
											disabled={this.state.disabledSubmit}
											onClick={() => {
												const startDate = this.state.startDate ? this.state.startDate : "";
												const endDate = this.state.endDate ? this.state.endDate : "";
												this.loadData({
													startDate,
													endDate,
													merchantId: this.state.merchant ? this.state.merchant.id : null,
													productId: this.state.product ? this.state.product.id : null
												});
											}}
											iconOnly
										>
											<Icon>search</Icon>
										</MDButton>
									</Tooltip>
									<MDBox ml={1}>
										<Tooltip title="Reset Pencarian">
											<MDButton
												color="error"
												variant="gradient"
												disabled={this.state.disabledSubmit}
												onClick={() => {
													const startDate = moment().startOf("year").format("YYYY-MM-DD")
													const endDate = moment().endOf("year").format("YYYY-MM-DD")
													this.setState({
														startDate,
														endDate,
														currentPage: 1,
														merchant: null,
														product: null,
														products: [],
													}, () => {
														this.loadData()
													})
												}}
												iconOnly
											>
												<Icon>refresh</Icon>
											</MDButton>
										</Tooltip>
									</MDBox>
									<MDBox ml={1}>
										<Tooltip title="Cetak Dokumen">
											<MDButton
												color="success"
												variant="gradient"
												disabled={this.state.disabledPrint}
												onClick={this.handlePrint}
												iconOnly
											>
												<Icon>print</Icon>
											</MDButton>
										</Tooltip>
									</MDBox>
								</MDBox>
							</Grid>
						</Grid>
					</MDBox>
					{/* END */}

					{/* CONTENT */}
					<MDBox py={5} px={2}>
						<Grid container justifyContent="center">
							<Grid item xs={12} md={12} lg={12} px={3}>
								<TableContainer sx={{ padding: "10px", maxHeight: 630 }}>
									{/* TITLE */}
									<MDBox
										p={2}
										display="flex"
										flexDirection="column"
										alignItems="center"
									>
										<MDTypography variant="h4" align="center" fontWeight="medium">
											{title}
										</MDTypography>
										<MDTypography variant="button" align="center" fontWeight="medium">
											Dari{" "}
											{this.state.startDate !== ""
												? this.state.startDate
												: moment().startOf("year").format("YY-MM-DD")}{" "}
											sampai{" "}
											{this.state.endDate !== ""
												? this.state.endDate
												: moment().endOf("year").format("YY-MM-DD")}
										</MDTypography>
									</MDBox>

									<Table>
										<TableBody>
											<TableRow>
												<DataTableBodyCell noBorder>
													<MDTypography variant="body" fontWeight="medium">
														Rincian Transaksi
													</MDTypography>
												</DataTableBodyCell>
											</TableRow>
										</TableBody>
									</Table>
									<Table>
										{/* TABLE HEAD */}
										<MDBox component="thead">
											<TableRow
												sx={{
													backgroundColor: "#f9fafb",
													position: "sticky",
													top: 0,
													zIndex: 2,
												}}
											>
												<DataTableBodyCell
													width="auto"
													align="left"
												>
													<MDTypography variant="caption" fontWeight="medium" textTransform="uppercase">
														Nama Paket
													</MDTypography>
												</DataTableBodyCell>
												<DataTableBodyCell
													width="auto"
													align="left"
												>
													<MDTypography variant="caption" fontWeight="medium" textTransform="uppercase">
														Jumlah Jamaah
													</MDTypography>
												</DataTableBodyCell>
												<DataTableBodyCell
													width="auto"
													align="right"
												>
													<MDTypography variant="caption" fontWeight="medium" textTransform="uppercase">
														Komisi
													</MDTypography>
												</DataTableBodyCell>
												<DataTableBodyCell
													width="auto"
													align="right"
												>
													<MDTypography variant="caption" fontWeight="medium" textTransform="uppercase">
														Tagihan
													</MDTypography>
												</DataTableBodyCell>
												<DataTableBodyCell
													width="auto"
													align="right"
												>
													<MDTypography variant="caption" fontWeight="medium" textTransform="uppercase">
														Sisa Tagihan
													</MDTypography>
												</DataTableBodyCell>
												<DataTableBodyCell
													width="auto"
													align="right"
												>
													<MDTypography variant="caption" fontWeight="medium" textTransform="uppercase">
														Tagihan Terbayar
													</MDTypography>
												</DataTableBodyCell>
											</TableRow>
										</MDBox>
										{/* TABLE BODY */}
										<TableBody>
											{this.state.sales.map((item, key) => {
												const product = item.Product
												const fee = item.fee ? parseInt(item.fee) : 0
												const qty = parseInt(item.qty)

												return (
													<TableRow key={key}>
														<DataTableBodyCell align="left">
															<MDTypography variant="body" >
																{product && product.name ? product.name : '-'}
															</MDTypography>
														</DataTableBodyCell>
														<DataTableBodyCell align="left">
															<MDTypography variant="body" >
																{item.qty} Jamaah
															</MDTypography>
														</DataTableBodyCell>
														<DataTableBodyCell align="right">
															<MDTypography variant="body" >
																{fnumber(fee * qty)}
															</MDTypography>
														</DataTableBodyCell>
														<DataTableBodyCell align="right">
															<MDTypography variant="body" >
																{fnumber(item.amount)}
															</MDTypography>
														</DataTableBodyCell>
														<DataTableBodyCell align="right">
															<MDTypography variant="body" >
																{fnumber(item.amount - item.paidAmount)}
															</MDTypography>
														</DataTableBodyCell>
														<DataTableBodyCell align="right">
															<MDTypography variant="body" >
																{fnumber(item.paidAmount)}
															</MDTypography>
														</DataTableBodyCell>
													</TableRow>
												)
											})}
										</TableBody>
										{/* TABLE FOOTER */}
										<MDBox component="tfoot">
											{/* Total Qty */}
											<TableRow
												sx={{
													position: "sticky",
													bottom: 0,
													zIndex: 2,
													backgroundColor: "#f9fafb"
												}}
											>
												<DataTableBodyCell> </DataTableBodyCell>
												<DataTableBodyCell> </DataTableBodyCell>
												<DataTableBodyCell> </DataTableBodyCell>
												<DataTableBodyCell> </DataTableBodyCell>
												<DataTableBodyCell width="auto" align="right">
													<MDTypography variant="caption" fontWeight="bold" textTransform="uppercase">
														Total Jamaah
													</MDTypography>
												</DataTableBodyCell>
												<DataTableBodyCell width="auto" align="right">
													<MDTypography variant="button" fontWeight="bold" textTransform="uppercase">
														{this.state.totalQty}
													</MDTypography>
												</DataTableBodyCell>
											</TableRow>
											{/* Total Fee */}
											<TableRow
												sx={{
													position: "sticky",
													bottom: 0,
													zIndex: 2,
													backgroundColor: "#f9fafb"
												}}
											>
												<DataTableBodyCell> </DataTableBodyCell>
												<DataTableBodyCell> </DataTableBodyCell>
												<DataTableBodyCell> </DataTableBodyCell>
												<DataTableBodyCell> </DataTableBodyCell>
												<DataTableBodyCell width="auto" align="right">
													<MDTypography variant="caption" fontWeight="bold" textTransform="uppercase">
														Total Komisi
													</MDTypography>
												</DataTableBodyCell>
												<DataTableBodyCell width="auto" align="right">
													<MDTypography variant="button" fontWeight="bold" textTransform="uppercase">
														{fnumber(this.state.totalFee)}
													</MDTypography>
												</DataTableBodyCell>
											</TableRow>
											{/* Total Bil */}
											<TableRow
												sx={{
													position: "sticky",
													bottom: 0,
													zIndex: 2,
													backgroundColor: "#f9fafb"
												}}
											>
												<DataTableBodyCell> </DataTableBodyCell>
												<DataTableBodyCell> </DataTableBodyCell>
												<DataTableBodyCell> </DataTableBodyCell>
												<DataTableBodyCell> </DataTableBodyCell>
												<DataTableBodyCell width="auto" align="right">
													<MDTypography variant="caption" fontWeight="bold" textTransform="uppercase">
														Total Tagihan
													</MDTypography>
												</DataTableBodyCell>
												<DataTableBodyCell width="auto" align="right">
													<MDTypography variant="button" fontWeight="bold" textTransform="uppercase">
														{fnumber(this.state.totalBill)}
													</MDTypography>
												</DataTableBodyCell>
											</TableRow>
											{/* Total Remaider Bill */}
											<TableRow
												sx={{
													position: "sticky",
													bottom: 0,
													zIndex: 2,
													backgroundColor: "#f9fafb"
												}}
											>
												<DataTableBodyCell> </DataTableBodyCell>
												<DataTableBodyCell> </DataTableBodyCell>
												<DataTableBodyCell> </DataTableBodyCell>
												<DataTableBodyCell> </DataTableBodyCell>
												<DataTableBodyCell width="auto" align="right">
													<MDTypography variant="caption" fontWeight="bold" textTransform="uppercase">
														Total Sisa Tagihan
													</MDTypography>
												</DataTableBodyCell>
												<DataTableBodyCell width="auto" align="right">
													<MDTypography variant="button" fontWeight="bold" textTransform="uppercase">
														{fnumber(this.state.totalRemaider)}
													</MDTypography>
												</DataTableBodyCell>
											</TableRow>
											{/* Total Paid Bill */}
											<TableRow
												sx={{
													position: "sticky",
													bottom: 0,
													zIndex: 2,
													backgroundColor: "#f9fafb"
												}}
											>
												<DataTableBodyCell> </DataTableBodyCell>
												<DataTableBodyCell> </DataTableBodyCell>
												<DataTableBodyCell> </DataTableBodyCell>
												<DataTableBodyCell> </DataTableBodyCell>
												<DataTableBodyCell width="auto" align="right">
													<MDTypography variant="caption" fontWeight="bold" textTransform="uppercase">
														Total Tagihan Terbayar
													</MDTypography>
												</DataTableBodyCell>
												<DataTableBodyCell width="auto" align="right">
													<MDTypography variant="button" fontWeight="bold" textTransform="uppercase">
														{fnumber(this.state.totalPaid)}
													</MDTypography>
												</DataTableBodyCell>
											</TableRow>
										</MDBox>
									</Table>
								</TableContainer>
							</Grid>
						</Grid>
					</MDBox>
					{/* END */}
				</Card>
			</MDBox>
		)
	}
}

OmsetPackage.propTypes = {
	view: PropTypes.oneOf(['umroh', 'haji', 'furoda']).isRequired
}

export default OmsetPackage