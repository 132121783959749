/* eslint-disable react/prop-types */
import React, { Fragment } from "react"
import { Document, Page, Text, Image, View, StyleSheet, Font } from "@react-pdf/renderer"

import logo from "assets/img/logoApp.png"

import RobotoMonoRegular from "assets/fonts/RobotoMono/RobotoMono-Regular.ttf"
import RobotMonoBold from "assets/fonts/RobotoMono/RobotoMono-Bold.ttf"
import RobotoMonoBoldItalic from "assets/fonts/RobotoMono/RobotoMono-BoldItalic.ttf"
import RobotoMonoItalic from "assets/fonts/RobotoMono/RobotoMono-Italic.ttf"

import InvoiceStyle from "helper/styles/repaymentStyle"

import secureStorage from "libs/secureStorage"

// Font
Font.register({
	family: 'RobotoMono',
	format: 'truetype',
	fonts: [
		{ src: RobotoMonoRegular },
		{ src: RobotoMonoBoldItalic },
		{ src: RobotoMonoItalic },
		{ src: RobotMonoBold },
	]
});
// Create styles
const styles = StyleSheet.create(InvoiceStyle)

class PdfRepayment extends React.Component {

	constructor(props) {
		super(props)
		this.state = {}
	}

	componentDidMount() {
		const data = this.props.data
	}

	render() {
		const user = secureStorage.getItem('user')

		const data = this.props.data
		const items = data ? data.items : []
		const banks = data ? data.banks : []
		const customer = data && data.customer ? data.customer : null

		return (
			<Document>
				<Page size="A4" style={styles.page}>
					{/* HAADER */}
					<View style={styles.headerContainer}>
						{/* Header Left */}
						<View style={styles.headerLeft}>
							<Image style={styles.headerLogo} src={data.logo ? data.logo : logo} cache />
							<View style={styles.headerLeftDesc}>
								<Text style={styles.company}>{data.company}</Text>
								<Text style={styles.headerCaption}>{data.address}</Text>
								<Text style={styles.headerCaption}>Email: {data.email}</Text>
								<Text style={styles.headerCaption}>Telpon: {data.phone}</Text>
							</View>
						</View>
						{/* Hader Right */}
						<View style={styles.headerRight}>
							<Text style={styles.headerRightTitle}>INVOICE</Text>
							<View style={styles.headerRightRow}>
								<Text style={{ ...styles.headerRightItem, textAlign: "left", width: "20%" }}>INVOICE</Text>
								<Text style={{ ...styles.headerRightItem, textAlign: "right", width: "30%" }}>{data.invoiceCode}</Text>
							</View>
							<View style={styles.headerRightRow}>
								<Text style={{ ...styles.headerRightItem, textAlign: "left", width: "20%" }}>TANGGAL</Text>
								<Text style={{ ...styles.headerRightItem, textAlign: "right", width: "30%" }}>{data.invoiceDate}</Text>
							</View>
						</View>
					</View>

					<View style={styles.contentContainer}>
						{/* INVOICE DETAIL */}
						<View style={styles.invoiceContainer}>
							{/* LEFT */}
							<View style={styles.invoiceLeft}>
								<Text style={styles.invoiceDetail}>Tagihan Kepada</Text>
								<Text style={styles.invoiceCaption}>{customer ? customer.name : ""}</Text>
								<Text style={styles.invoiceCaption}>{customer ? customer.address : ""}</Text>
								<Text style={styles.invoiceCaption}>{customer ? customer.email : ""}</Text>
								<Text style={styles.invoiceCaption}>{customer ? customer.phone : ""}</Text>
							</View>
							{/* RIGHT */}

						</View>

						{/* TABLE CONTENT */}
						<View style={styles.tableContainer}>
							<View style={styles.tableHeadContainer}>
								<Text style={styles.tableHeadDesc}>Item Description</Text>
								<Text style={styles.tableHeadAmount}>Tagihan</Text>
							</View>
							<Fragment>
								{items.map((item, key) => (
									<View style={styles.tableRow} key={key}>
										<Text style={styles.tableRowDesc}>{item.name}</Text>
										<Text style={styles.tableRowAmount}>{item.amount}</Text>
									</View>
								))}
							</Fragment>
						</View>
						{/* FOOTER */}
						<Fragment>
							<View style={styles.tableFooterRow}>
								<Text style={{ ...styles.tableFootCaption, fontSize: 12 }}>Dibayarkan</Text>
								<Text style={{ ...styles.tableFootValue, fontSize: 12 }}>{data.paidAmount}</Text>
							</View>
							<View style={styles.tableFooterRow}>
								<Text style={{ ...styles.tableFootCaption, fontSize: 12 }}>Sub Total</Text>
								<Text style={{ ...styles.tableFootValue, fontSize: 12 }}>{data.subtotal}</Text>
							</View>
							<View style={styles.tableFooterRow}>
								<Text style={{ ...styles.tableFootCaption, fontSize: 12 }}>Total</Text>
								<Text style={{ ...styles.tableFootValue, fontSize: 12 }}>{data.total}</Text>
							</View>
						</Fragment>

						{/* NOTE */}
						<View style={styles.noteContainer}>
							<Text style={styles.noteCaption}>
								Untuk Pembayaran bisa melalui bank berikut :
							</Text>

							{banks.map((item, key) => (
								<Text style={styles.noteItem} key={key}>
									- BANK {item.name}, NO.REK : {item.accountNumber}, {item.accountName}
								</Text>
							))}
						</View>

						{/* Note Commission */}
						{user && [4].includes(user.roleId) && data.commissionAgent && (
							<View style={styles.noteContainer}>
								<Text style={{ ...styles.noteCaption, fontWeight: 'normal' }}>
									komisi yang di dapatkan agen senilai {data.commissionAgent}
								</Text>
							</View>
						)}
					</View>
				</Page>
			</Document>
		)
	}
}

export default PdfRepayment