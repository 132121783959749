/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React from 'react'
import { Link, Navigate } from 'react-router-dom';
// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from 'components/MDInput';
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import ModalNotif from 'contents/Components/ModalNotif';
import withRouter from 'contents/Components/withRouter';
import MiniFormCard from 'contents/Components/FormCard/MiniFormCard';
import ButtonBack from 'contents/Components/ButtonBack';

import Config from 'config'
import _ from "lodash"

import useAxios from "libs/useAxios"
import secureStorage from 'libs/secureStorage';
import { fnumber } from "libs/currency"

class PaymentForm extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			id: null,
			userId: null,
			merchantId: null,

			bank: null,
			paymentType: null,

			accountNumber: "",
			accountName: "",
			remark: "",
			payAmount: "",
			paidAmount: 0,
			billAmount: "",

			bankOpen: false,
			paymentTypeOpen: false,

			file: null,
			filename: "",

			banks: [],
			paymentTypes: [{ id: 1, label: "Cash" }, { id: 2, label: "Kredit" }],
			success: [],
			error: [],

			redirect: null,
			disabledSubmit: false,
		}

		this.fileRef = React.createRef()
		this.inputRef = React.createRef()
		this.buttonBackRef = React.createRef()
		this.modalNotifRef = React.createRef()
	}

	componentDidMount() {
		const id = this.props.params?.id
		this.inputRef.current && this.inputRef.current.focus()
		this.loadBank()
		this.setState({ id }, () => {
			this.loadPayment(id)
		})
	}

	loadBank = () => {
		useAxios()
			.post(`${Config.ApiUrl}/master/bank/dropdown`)
			.then(response => {
				const data = response.data

				this.setState({
					banks: data.map(item => ({
						id: item.id,
						label: item.name,
						accountName: item.accountName,
						accountNumber: item.accountNumber
					}))
				})
			})
			.catch(err => {
				console.log(err)
			})
	}

	// get bill amount by id or saleId
	loadPayment = (id) => {
		useAxios()
			.post(`${Config.ApiUrl}/finance/bill/get`, { id })
			.then(response => {
				const data = response.data
				const billAmount = parseInt(data.amount) - parseInt(data.paidAmount)

				// console.log({ data, billAmount })
				this.setState({
					billAmount,
					paidAmount: data.paidAmount,
					// billId: data.id,
					// saleId: data.saleId,
					userId: data.userId,
					merchantId: data.merchantId
				})
			})
			.catch(err => {
				console.log(err)
				this.modalNotifRef.current.setShow({
					modalTitle: 'Gagal',
					modalMessage: err.response ? err.response.data : "Koneksi jaringan terputus",
					onClose: () => {
						this.setState({ redirect: '/finance/adm-member' })
					}
				})
			})
	}

	handleChange = (e) => {
		const { id, value } = e.target;
		this.setState({
			[id]: value
		})
	}

	handleBlur = (e) => {
		const user = this.state.user
		const { id, value } = e.target
		// eslint-disable-next-line no-empty
		if (value) {
			this.setState({
				success: { ...this.state.success, [e.target.id]: true },
				error: { ...this.state.error, [e.target.id]: false }
			})
		}
		else {
			this.setState({
				success: { ...this.state.success, [e.target.id]: false },
				error: { ...this.state.error, [e.target.id]: true }
			})
		}
	}

	resetForm = () => {
		this.setState({
			payAmount: "",
			paidAmount: 0,
			bank: null,
			paymentType: null,
			accountNumber: "",
			accountName: "",
			remark: "",

			file: null,
			filename: "",

			success: { bank: false, paymentType: false, payAmount: false }
		})
	}

	handleSubmit = () => {
		const success = this.state.success

		if (
			success.bank &&
			success.paymentType &&
			success.payAmount &&
			success.remark
		) {
			this.sendData()
		}
		else {
			this.modalNotifRef.current.setShow({
				modalTitle: "Gagal",
				modalMessage: "Data form masih belum lengkap, Silahkan di cek kembali !",
			})
		}
	}

	sendData = () => {
		this.setState({ disabledSubmit: true })
		const formData = new FormData()

		const valStr = this.state.payAmount.replace(/\./g, '')
		let payAmount = isNaN(valStr) || valStr === '' ? 0 : parseInt(valStr)

		formData.append('payAmount', payAmount)
		formData.append('paidAmount', this.state.paidAmount)
		formData.append('billAmount', this.state.billAmount)
		formData.append('remark', this.state.remark)
		// formData.append('saleId', this.state.saleId)
		formData.append('billId', this.state.id) // from params url
		formData.append('userId', this.state.userId)
		formData.append('merchantId', this.state.merchantId)
		formData.append('bankId', this.state.bank ? this.state.bank?.id : null)
		formData.append('paymentTypeId', this.state.paymentType ? this.state.paymentType?.id : null)
		formData.append('image', this.state.file)

		useAxios()
			.post(`${Config.ApiUrl}/finance/payment/create`, formData)
			.then(response => {
				this.modalNotifRef.current.setShow({
					modalTitle: "Sukses",
					modalMessage: response.data,
					onClose: () => {
						this.resetForm()
						this.buttonBackRef.current.click()
					}
				})
			})
			.catch(err => {
				console.log(err)
				this.setState({ disabledSubmit: false })
				if (err.response) {
					this.modalNotifRef.current.setShow({
						modalTitle: "Gagal",
						modalMessage: err.response ? err.response.data : "Terjadi kesalahan pada system",
					})
				}
				// eslint-disable-next-line no-empty
				else {
					this.modalNotifRef.current.setShow({
						modalTitle: 'Gagal',
						modalMessage: "Koneksi jaringan terputus",
					})
				}
			})
	}

	render() {
		const user = secureStorage.getItem('user')

		return (
			<MiniFormCard>
				<ModalNotif ref={this.modalNotifRef} />
				<MDBox p={3} lineHeight={1} display="flex" justifyContent="center">
					<MDTypography variant="h5" fontWeight="medium">
						Form Pembayaran
					</MDTypography>
				</MDBox>

				<MDBox p={3}>
					<MDBox component="form" role="form">
						<Grid container spacing={3}>
							<Grid item xs={12} md={12} lg={12}>
								{/* Bank */}
								<MDBox mb={2}>
									<Autocomplete
										disableClearable
										fullWidth
										id="bank"
										open={this.state.bankOpen}
										onOpen={() => this.setState({ bankOpen: true })}
										onClose={() => this.setState({ bankOpen: false })}
										onFocus={() => this.setState({ bankOpen: true })}
										value={this.state.bank}
										options={this.state.banks}
										onChange={(e, value) => {
											if (value) {
												console.log({ value })
												// const find = _.find(this.state.banks, { id: value.id })
												this.setState({
													bank: value,
													accountName: value.accountName,
													accountNumber: value.accountNumber,
													error: { ...this.state.error, bank: false },
													success: { ...this.state.success, bank: true }
												})
											}
										}}
										sx={{
											".MuiAutocomplete-input": {
												padding: "7.5px 5px 7.5px 8px !important"
											},
											".MuiOutlinedInput-root": {
												padding: "4px !important"
											}
										}}
										isOptionEqualToValue={(option, value) => option.id === value.id}
										renderInput={(params) => <MDInput label="Pilih Bank" {...params} />}
									/>
								</MDBox>
								{/* Account Number */}
								<MDBox mb={2}>
									<MDInput
										id="accountNumber"
										label="Nomor Rekening"
										value={this.state.accountNumber}
										disabled
										fullWidth
									/>
								</MDBox>
								{/* Account Name */}
								<MDBox mb={2}>
									<MDInput
										id="accountName"
										label="Atas Nama"
										value={this.state.accountName}
										disabled
										fullWidth
									/>
								</MDBox>
								{/* Payment Type */}
								<MDBox mb={2}>
									<Autocomplete
										fullWidth
										disableClearable
										id="paymentType"
										open={this.state.paymentTypeOpen}
										onOpen={() => this.setState({ paymentTypeOpen: true })}
										onClose={() => this.setState({ paymentTypeOpen: false })}
										onFocus={() => this.setState({ paymentTypeOpen: true })}
										value={this.state.paymentType}
										options={this.state.paymentTypes}
										onChange={(e, value) => {
											if (value) {
												let payAmount = value.id === 1 ? { payAmount: fnumber(this.state.billAmount) } : { payAmount: "" }

												this.setState({
													...payAmount,
													paymentType: value,
													error: { ...this.state.error, paymentType: false },
													success: { ...this.state.success, paymentType: true }
												}, () => this.inputRef.current.focus())
											}
										}}
										sx={{
											".MuiAutocomplete-input": {
												padding: "7.5px 5px 7.5px 8px !important"
											},
											".MuiOutlinedInput-root": {
												padding: "4px !important"
											}
										}}
										isOptionEqualToValue={(option, value) => option.id === value.id}
										renderInput={(params) => <MDInput label="Pilih Jenis Pembayaran" {...params} />}
									/>
								</MDBox>
								{/* Payment Amount */}
								<MDBox mb={2}>
									<MDInput
										id="payAmount"
										inputRef={this.inputRef}
										label="Nominal Bayar"
										disabled={this.state.paymentType && this.state.paymentType.id == 1 ? true : false}
										value={this.state.payAmount}
										onChange={(e) => {
											const valStr = e.target.value.replace(/\./g, '')
											let value = isNaN(valStr) || valStr === '' ? 0 : parseInt(valStr)
											if (value < 0)
												value = 0
											this.setState({
												payAmount: fnumber(value)
											})
										}}
										onBlur={this.handleBlur}
										success={this.state.success ? this.state.success.payAmount : false}
										error={this.state.error ? this.state.error.payAmount : false}
										fullWidth
									/>
								</MDBox>
								{/* Bill Amount */}
								<MDBox mb={2}>
									<MDInput
										id="billAmount"
										label="Jumlah Tagihan"
										value={fnumber(this.state.billAmount)}
										disabled
										fullWidth
									/>
								</MDBox>
								{/* Upload Image */}
								<MDBox mb={2}>
									<input
										type="file"
										name="fileInput"
										ref={this.fileRef}
										onChange={(e) => {
											if (e.target.files.length === 1) {
												const file = e.target.files[0];
												const filename = file.name;
												const ext = filename.split(".")[1];
												this.setState({ file, filename })
											}
										}}
										hidden
									/>
									<MDInput
										fullWidth
										value={this.state.filename}
										label="Upload bukti Transfer"
										onClick={() => {
											this.fileRef.current.click()
										}}
										readOnly
									/>
									<small style={{ color: "red", fontSize: "12px" }}>Maksimal ukuran 2MB</small>
								</MDBox>
								{/* Remark */}
								<MDBox mb={2}>
									<MDInput
										rows={3}
										multiline={true}
										id="remark"
										type="text"
										label="Keterangan"
										value={this.state.remark}
										onChange={this.handleChange}
										onBlur={this.handleBlur}
										success={this.state.success ? this.state.success.remark : false}
										error={this.state.error ? this.state.error.remark : false}
										fullWidth
									/>
								</MDBox>

								{/* Button Submit */}
								<MDBox pt={3} display="flex" justifyContent="space-between">
									<ButtonBack label="KEMBALI" ref={this.buttonBackRef} />
									<MDButton
										type="button"
										variant="gradient"
										color="info"
										disabled={this.state.disabledSubmit}
										onClick={this.handleSubmit}
									>
										SUBMIT
									</MDButton>
								</MDBox>
							</Grid>
						</Grid>
					</MDBox>
				</MDBox>
			</MiniFormCard>
		)
	}
}

export default withRouter(PaymentForm)