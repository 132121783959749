/* eslint-disable react/prop-types */
import React from "react";
import { Link, Navigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

// Material Dashboard 2 PRO React examples
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import withRouter from "contents/Components/withRouter";
import Loader from "contents/Components/Loader";
import Confirm from "contents/Components/Confirm";
import ModalNotif from "contents/Components/ModalNotif";
import ButtonBack from "contents/Components/ButtonBack";

import Config from "config";
import _ from "lodash";
import useAxios from "libs/useAxios";
import secureStorage from "libs/secureStorage";
import { fnumber } from "libs/currency";
import print from "helper/printer";

// Images
import productImage from "assets/images/products/product-11.jpg";

class DetailPayment extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			id: null,
			user: null,
			isLoading: true,

			bank: null,
			paymentType: null,
			status: null,
			payment: "",

			redirect: null,
			disabledBtnPrint: false,
			disabledBtnApprove: false,
			disabledBtnCancel: false,
		};

		this.confirmRef = React.createRef();
		this.modalNotifRef = React.createRef();
	}

	componentDidMount() {
		const id = this.props.params?.id

		this.setState({ id }, () => this.loadDetail(id))
	}

	loadDetail = (id) => {
		useAxios()
			.post(`${Config.ApiUrl}/finance/payment/get`, { id })
			.then((response) => {
				const data = response.data;
				const status = data.PaymentStatus ? data.PaymentStatus : null;
				const paymentType = data.paymentType ? data.paymentType : null;
				const bank = data.Bank ? data.Bank : null;

				this.setState({
					isLoading: false,
					payment: data,
					bank,
					status,
					paymentType,
				});
			})
			.catch((err) => {
				console.log(err);
				this.setState({ isLoading: false });
				this.modalNotifRef.current.setShow({
					modalTitle: "Gagal",
					modalMessage: err.response ? err.response.data : "Koneksi jaringan terputus",
					onClose: () => {
						this.setState({ redirect: "/finance/payment" });
					},
				});
			});
	};

	handleStatus = (statusId) => {
		this.confirmRef.current.setShow({
			title: "Konfirmasi",
			message: "Apakah anda yakin ?",
			onAction: () => {
				this.submitStatus(statusId);
			},
		});
	};

	submitStatus = (statusId) => {
		const disabledBtn = statusId === 2 ? { disabledBtnCancel: true } : { disabledBtnApprove: true };
		const enabledBtn =
			statusId === 2 ? { disabledBtnCancel: false } : { disabledBtnApprove: false };
		this.setState({ ...disabledBtn });

		useAxios()
			.post(`${Config.ApiUrl}/finance/payment/changestatus`, { id: this.state.id, statusId })
			.then((response) => {
				const data = response.data;
				console.log(data);

				this.modalNotifRef.current.setShow({
					modalTitle: "Sukses",
					modalMessage: response.data,
					onClose: () => {
						this.setState({
							...enabledBtn,
							redirect: "/finance/payment",
						});
					},
				});
			})
			.catch((err) => {
				console.log(err);
				this.setState({ ...enabledBtn });
				if (err.response) {
					this.modalNotifRef.current.setShow({
						modalTitle: "Gagal",
						modalMessage: err.response ? err.response.data : "Server Internal Error",
					});
				}
				// eslint-disable-next-line no-empty
				else {
					this.modalNotifRef.current.setShow({
						modalTitle: "Gagal",
						modalMessage: "Koneksi jaringan terputus",
					});
				}
			});
	};

	handlePrint = () => {
		this.setState({ disabledBtnPrint: true });
		useAxios()
			.post(`${Config.ApiUrl}/finance/print/paymentReceipt`, { id: this.state.id })
			.then((response) => {
				const data = response.data;
				const merchant = data.Merchant ? data.Merchant : null;

				this.setState({ disabledBtnPrint: false });
				const payload = {
					title: "KWITANSI PEMBAYARAN",
					company: merchant && merchant.name ? merchant.name : "",
					email: merchant && merchant.email ? merchant.email : "", //"birotravel01@mantrix.com",
					phone: merchant && merchant.phone ? merchant.phone : "", //"+62852567829",
					address: merchant && merchant.address ? merchant.address : "", //"Jalan Samsudin 502, Kudus",
					kode: data.kode,
					date: data.createdAt,
					customerName: data.User ? data.User.name : "",
					amount: fnumber(data.amount),
					remark: data.remark,
				};
				print(payload, "PdfPaymentReceipt");
			})
			.catch((err) => {
				console.log(err);
				this.modalNotifRef.current.setShow({
					modalTitle: "Gagal",
					modalMessage: err.response ? err.response.data : "Koneksi jaringan terputus",
					onClose: () => {
						this.setState({ disabledBtnPrint: false });
					},
				});
			});
	};

	render() {
		const user = secureStorage.getItem("user");
		if (this.state.redirect) {
			return <Navigate to={this.state.redirect} />;
		}

		return (
			<>
				<MDBox pt={3} pb={2} display="flex">
					<Confirm ref={this.confirmRef} />
					<ModalNotif ref={this.modalNotifRef} />
					<MDBox mr={1}>
						<ButtonBack label={"KEMBALI"} />
					</MDBox>
					{this.state.status && this.state.status.id && this.state.status.id === 3 && (
						<MDButton
							variant="gradient"
							color="info"
							disabled={this.state.disabledBtnPrint}
							onClick={this.handlePrint}
						>
							Cetak Kwitansi
						</MDButton>
					)}
				</MDBox>
				<Card sx={{ overflow: "visible" }}>
					<MDBox p={3}>
						<Grid container spacing={3} mb={3}>
							<Grid item xs={12} md={12} lg={12}>
								<MDBox width="100%" display="flex" justifyContent="center">
									<MDTypography variant="h5" textTransform="capitalize" fontWeight="medium">
										DETAIL PEMBAYARAN
									</MDTypography>
								</MDBox>
							</Grid>
						</Grid>
						<Grid container spacing={3}>
							<Grid item xs={12} md={6} lg={6}>
								<Table>
									<TableBody>
										{/* Kode */}
										<TableRow>
											<DataTableBodyCell noBorder>
												<MDTypography variant="body" fontWeight="medium">
													Kode Mutasi
												</MDTypography>
											</DataTableBodyCell>
											<DataTableBodyCell noBorder>
												<MDTypography variant="body">
													{this.state.payment && this.state.payment.kode
														? this.state.payment.kode
														: "-"}
												</MDTypography>
											</DataTableBodyCell>
										</TableRow>
										{/* Amount */}
										<TableRow>
											<DataTableBodyCell noBorder>
												<MDTypography variant="body" fontWeight="medium">
													Nominal
												</MDTypography>
											</DataTableBodyCell>
											<DataTableBodyCell noBorder>
												<MDTypography variant="body">
													{this.state.payment && this.state.payment.amount
														? fnumber(this.state.payment.amount)
														: "-"}
												</MDTypography>
											</DataTableBodyCell>
										</TableRow>
										{/* CurrAmount */}
										<TableRow>
											<DataTableBodyCell noBorder>
												<MDTypography variant="body" fontWeight="medium">
													Tagihan Saat ini
												</MDTypography>
											</DataTableBodyCell>
											<DataTableBodyCell noBorder>
												<MDTypography variant="body">
													{this.state.payment && this.state.payment.currAmount
														? fnumber(this.state.payment.currAmount)
														: "-"}
												</MDTypography>
											</DataTableBodyCell>
										</TableRow>
										{/* CreatedAt */}
										<TableRow>
											<DataTableBodyCell noBorder>
												<MDTypography variant="body" fontWeight="medium">
													Tanggal
												</MDTypography>
											</DataTableBodyCell>
											<DataTableBodyCell noBorder>
												<MDTypography variant="body">
													{this.state.payment && this.state.payment.createdAt
														? this.state.payment.createdAt
														: "-"}
												</MDTypography>
											</DataTableBodyCell>
										</TableRow>
										{/* Bank */}
										<TableRow>
											<DataTableBodyCell noBorder>
												<MDTypography variant="body" fontWeight="medium">
													Bank
												</MDTypography>
											</DataTableBodyCell>
											<DataTableBodyCell noBorder>
												<MDTypography variant="body">
													{this.state.bank
														? `${this.state.bank.name} - ${this.state.bank.accountNumber}`
														: "-"}
												</MDTypography>
											</DataTableBodyCell>
										</TableRow>
										{/* Status */}
										<TableRow>
											<DataTableBodyCell noBorder>
												<MDTypography variant="body" fontWeight="medium">
													Status
												</MDTypography>
											</DataTableBodyCell>
											<DataTableBodyCell noBorder>
												<MDTypography variant="body">
													{this.state.status && this.state.status.name
														? this.state.status.name
														: "-"}
												</MDTypography>
											</DataTableBodyCell>
										</TableRow>
										{/* Remark */}
										<TableRow>
											<DataTableBodyCell noBorder>
												<MDTypography variant="body" fontWeight="medium">
													Keterangan
												</MDTypography>
											</DataTableBodyCell>
											<DataTableBodyCell noBorder>
												<MDTypography variant="body">
													{this.state.payment && this.state.payment.remark
														? this.state.payment.remark
														: "-"}
												</MDTypography>
											</DataTableBodyCell>
										</TableRow>
									</TableBody>
								</Table>
							</Grid>
							<Grid item xs={12} md={6} lg={6}>
								<MDBox
									position="relative"
									borderRadius="lg"
									mx={2}
									className="card-header"
									sx={{ transition: "transform 300ms cubic-bezier(0.34, 1.61, 0.7, 1)" }}
								>
									{this.state.payment.image && (
										<MDBox
											component="img"
											src={`${Config.ApiAsset}/mutation/${this.state.payment.image}`}
											alt="Bukti Transfer"
											borderRadius="lg"
											shadow="sm"
											width="100%"
											height="100%"
											position="relative"
											zIndex={10}
											mb={2}
										/>
									)}
								</MDBox>
							</Grid>
						</Grid>
						{this.state.status &&
							this.state.status.id &&
							this.state.status.id === 1 &&
							user &&
							[2, 3].includes(user.roleId) && (
								<Grid container spacing={3}>
									<Grid item xs={12} lg={12} md={12}>
										<MDBox py={1} display="flex" px={3}>
											<MDBox mr={1}>
												<MDButton
													color="error"
													variant="gradient"
													disabled={this.state.disabledBtnCancel}
													onClick={() => this.handleStatus(2)}
												>
													Tolak Pembayaran
												</MDButton>
											</MDBox>
											<MDBox mr={1}>
												<MDButton
													variant="gradient"
													color="info"
													disabled={this.state.disabledBtnApprove}
													onClick={() => this.handleStatus(3)}
												>
													Setujui Pembayaran
												</MDButton>
											</MDBox>
										</MDBox>
									</Grid>
								</Grid>
							)}
					</MDBox>
				</Card>
			</>
		);
	}
}

export default withRouter(DetailPayment);
