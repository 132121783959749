/* eslint-disable react/prop-types */
import React from 'react'
import { Link, Navigate } from 'react-router-dom';
// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import MDEditor from "components/MDEditor";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import ModalNotif from "contents/Components/ModalNotif";
import withRouter from "contents/Components/withRouter";

import MiniFormCard from "contents/Components/FormCard/MiniFormCard";

import Config from "config";
import _ from "lodash";

import useAxios from "libs/useAxios";
import secureStorage from "libs/secureStorage";

class FormBanner extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			id: null,
			title: '',

			name: '',
			filename: '',
			image: null,

			error: [],
			success: [],

			disabledSubmit: false,
			redirect: null,
		}

		this.inputRef = React.createRef();
		this.fileRef = React.createRef();
		this.modalNotifRef = React.createRef();
	}

	componentDidMount() {
		this.inputRef && this.inputRef.current.focus();

		this.loadPath();
	}

	loadPath = () => {
		const pathname = window.location.pathname;
		const index = pathname.indexOf("edit");
		if (index === -1) {
			this.setState({
				title: "Tambah Banner Baru",
				action: "add",
			});
		} else {
			const id = this.props.params?.id;
			this.loadDetail(id);
			this.setState({
				id,
				title: "Ubah Banner",
				action: "edit",
				error: { ...this.state.error, name: false },
				success: { ...this.state.success, name: true },
			});
		}
	}

	handleChange = (e) => {
		const { id, value } = e.target
		this.setState({
			[id]: value,
		})
	}

	handleBlur = (e) => {
		const user = this.state.user;
		const { id, value } = e.target;
		if (value) {
			this.setState({
				success: { ...this.state.success, [e.target.id]: true },
				error: { ...this.state.error, [e.target.id]: false },
			});
		} else {
			this.setState({
				success: { ...this.state.success, [e.target.id]: false },
				error: { ...this.state.error, [e.target.id]: true },
			});
		}
	}

	loadDetail = (id) => {
		useAxios()
			.post(`${Config.ApiUrl}/master/banner/get`, { id })
			.then(response => {
				const data = response.data

				this.setState({
					name: data.title ?? "",

					success: {
						...this.state.success,
						name: data.title ? true : false,
					}
				})
			})
			.catch(err => {
				console.log(err)
				this.modalNotifRef.current.setShow({
					modalTitle: 'Gagal',
					modalMessage: err.response ? err.response.data : "Koneksi jaringan terputus",
					onClose: () => {
						this.setState({ redirect: "/master/banner" })
					}
				})
			})
	}

	handleSubmit = () => {
		const success = this.state.success

		if (success.name) {
			this.sendData()
		}
		else {
			this.modalNotifRef.current.setShow({
				modalTitle: 'Peringatan',
				modalMessage: "Data form masih belum lengkap, Silahkan di cek kembali !",
			})
		}
	}

	sendData = () => {
		this.setState({
			disabledSubmit: true
		})

		const formData = new FormData()
		formData.append("title", this.state.name)
		formData.append("image", this.state.image)
		formData.append("id", this.state.id)

		const url = this.state.action === "add" ? "/master/banner/create" : "/master/banner/update"
		useAxios()
			.post(`${Config.ApiUrl}${url}`, formData)
			.then(response => {
				this.modalNotifRef.current.setShow({
					modalTitle: "Sukses",
					modalMessage: response.data,
					onClose: () => {
						this.setState({
							redirect: "/master/banner",
							disabledSubmit: false,
						})
					},
				})
			})
			.catch(err => {
				this.setState({ disabledSubmit: false });
				if (err.response) {
					this.modalNotifRef.current.setShow({
						modalTitle: "Gagal",
						modalMessage: err.response ? err.response.data : "Terjadi kesalahan pada system",
					})
				}
				// eslint-disable-next-line no-empty
				else {
					this.modalNotifRef.current.setShow({
						modalTitle: "Gagal",
						modalMessage: "Koneksi jaringan terputus",
					})
				}
			})
	}

	render() {
		if (this.state.redirect) {
			return <Navigate to={this.state.redirect} />
		}

		return (
			<MiniFormCard>
				<ModalNotif ref={this.modalNotifRef} />
				<MDBox display="flex" p={3} lineHeight={1} justifyContent="center">
					<MDTypography variant="h5" fontWeight="medium">
						{this.state.title}
					</MDTypography>
				</MDBox>

				<MDBox p={3}>
					<MDBox component="form">
						<Grid container spacing={3}>
							<Grid item xs={12} md={12} lg={12}>
								{/* Title */}
								<MDBox mb={2}>
									<MDInput
										id="name"
										type="text"
										inputRef={this.inputRef}
										label="Judul"
										value={this.state.name}
										onChange={this.handleChange}
										onBlur={this.handleBlur}
										success={this.state.success ? this.state.success.name : false}
										error={this.state.error ? this.state.error.name : false}
										fullWidth
									/>
								</MDBox>
								{/* Image */}
								<MDBox mb={2}>
									<input
										type="file"
										name="fileInput"
										ref={this.fileRef}
										onChange={(e) => {
											if (e.target.files.length === 1) {
												const file = e.target.files[0];
												const filename = file.name;
												const ext = filename.split(".")[1];
												this.setState({
													image: file,
													filename,
												});
											}
										}}
										hidden
									/>
									<MDInput
										fullWidth
										value={this.state.filename}
										label="Upload Foto"
										onClick={() => {
											this.fileRef.current.click();
										}}
										readOnly
									/>
									<MDTypography color="error" variant="span" fontWeight="bold" fontSize="12px">
										* ukuran gambar minimal 480 x 268
									</MDTypography>
								</MDBox>
								{/* Button */}
								<MDBox pt={3} display="flex" justifyContent="space-between">
									<MDButton
										variant="gradient"
										color="error"
										component={Link}
										to={{ pathname: "/master/banner" }}
									>
										KEMBALI
									</MDButton>
									<MDButton
										type="button"
										variant="gradient"
										color="info"
										disabled={this.state.disabledSubmit}
										onClick={this.handleSubmit}
									>
										SUBMIT
									</MDButton>
								</MDBox>
							</Grid>
						</Grid>
					</MDBox>
				</MDBox>
			</MiniFormCard>
		)
	}
}

export default withRouter(FormBanner)