/* eslint-disable react/prop-types */
import React from 'react'
import { Link, Navigate } from 'react-router-dom';
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDBadge from "components/MDBadge";
import MDTypography from "components/MDTypography";

import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

// Material Dashboard 2 PRO React examples
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import withRouter from 'contents/Components/withRouter'
import Loader from 'contents/Components/Loader';
import ButtonBack from 'contents/Components/ButtonBack'
import ModalNotif from 'contents/Components/ModalNotif'

import useAxios from "libs/useAxios"
import Config from "config"
import secureStorage from 'libs/secureStorage';

class DetailMerchant extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			user: null,
			id: null,
			isLoading: true,

			kode: "-",
			name: "-",
			email: "-",
			phone: "-",
			address: "-",
			merchant: null,
			status: null,

			listUsers: [],
			isLoadingUser: true,

			district: null,
			province: null
		}

		this.modalNotifRef = React.createRef()
	}

	componentDidMount() {
		const id = this.props.params?.id
		this.setState({ id })
		this.loadDetail(id)
		this.getListUser(id)
	}

	loadDetail = (id) => {
		useAxios()
			.post(`${Config.ApiUrl}/manage/merchant/get`, { id })
			.then(response => {
				const data = response.data
				const province = data.Province
				const district = data.District

				delete data.Province
				delete data.District
				this.setState({
					isLoading: false,
					merchant: data,
					province: province,
					district: district,
				})
			})
			.catch(err => {
				console.log(err)
				this.modalNotifRef.current.setShow({
					modalTitle: 'Gagal',
					modalMessage: "Koneksi jaringan terputus",
				})
			})
	}

	getListUser = (id) => {
		this.setState({ isLoadingUser: true })

		const payload = {
			currentPage: 'All',
			rowsPerPage: 'All',
			roleId: [2, 3],
			merchantId: id
		}
		useAxios()
			.post(`${Config.ApiUrl}/user`, payload)
			.then(response => {
				const data = response.data

				const output = data.output.map(item => {

					return {
						name: item.name,
						email: item.email,
						phone: item.phone,
						status: item.isActive,
						role: item.Role && item.Role.name ? item.Role.name : '-'
					}
				})

				this.setState({
					isLoadingUser: false,
					listUsers: output
				})
			})
			.catch(err => {
				console.log(err)
				this.setState({ isLoadingUser: false })
			})
	}

	render() {
		const user = secureStorage.getItem('user')
		const status = this.state.status

		return (
			<MDBox pb={3} my={3}>
				<ModalNotif ref={this.modalNotifRef} />
				<MDBox py={1} mb={2} display="flex">
					<MDBox mr={1}>
						<ButtonBack label={"KEMBALI"} />
					</MDBox>
					{user && [1].includes(user.roleId) && (
						<MDButton
							variant="gradient"
							color="info"
							component={Link}
							to={{ pathname: `/manage/merchant/edit/${this.state.id}` }}
						>
							UBAH DATA
						</MDButton>
					)}
				</MDBox>

				{/* Detail */}
				<Card>
					<MDBox my={2} lineHeight={1} width="100%" display="flex" justifyContent="center">
						<MDTypography variant="h5" fontWeight="medium">
							Detail Biro Travel
						</MDTypography>
					</MDBox>
					<MDBox p={2}>
						<Grid container spacing={3} item xs={12} md={12} lg={12} mb={5} pt={2}>
							<Table>
								<TableBody>
									{/* KODE && NAME */}
									<TableRow>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body" fontWeight="medium">
												Kode Biro
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body">
												{this.state.merchant ? this.state.merchant.kode : "-"}
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body" fontWeight="medium">
												Nama
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body">
												{this.state.merchant ? this.state.merchant.name : "-"}
											</MDTypography>
										</DataTableBodyCell>
									</TableRow>
									{/* BRAND & ASITA */}
									<TableRow>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body" fontWeight="medium">
												Name Merek
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body">
												{this.state.merchant ? this.state.merchant.brand : "-"}
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body" fontWeight="medium">
												ASITA
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body">
												{this.state.merchant ? this.state.merchant.asita : "-"}
											</MDTypography>
										</DataTableBodyCell>
									</TableRow>
									{/* NOTARIS && NPWP */}
									<TableRow>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body" fontWeight="medium">
												Notaris
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body">
												{this.state.merchant ? this.state.merchant.notary : "-"}
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body" fontWeight="medium">
												NPWP
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body">
												{this.state.merchant ? this.state.merchant.npwp : "-"}
											</MDTypography>
										</DataTableBodyCell>
									</TableRow>
									{/* TDP && SIUP */}
									<TableRow>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body" fontWeight="medium">
												TDP/ Company Registration
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body">
												{this.state.merchant ? this.state.merchant.companyRegistration : "-"}
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body" fontWeight="medium">
												SIUP/ Licence of Business Trade
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body">
												{this.state.merchant ? this.state.merchant.siup : "-"}
											</MDTypography>
										</DataTableBodyCell>
									</TableRow>
									{/* TDP && SIUP */}
									<TableRow>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body" fontWeight="medium">
												Izin Haji/ Licence for Hajj
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body">
												{this.state.merchant ? this.state.merchant.permitHaji : "-"}
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body" fontWeight="medium">
												Izin Umrah/ Licence for Umra
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body">
												{this.state.merchant ? this.state.merchant.permitUmroh : "-"}
											</MDTypography>
										</DataTableBodyCell>
									</TableRow>
									{/* TDP && SIUP */}
									<TableRow>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body" fontWeight="medium">
												IATA/ lATA
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body">
												{this.state.merchant ? this.state.merchant.iata : "-"}
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body" fontWeight="medium">
												Anggota HIMPUH
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body">
												{this.state.merchant ? this.state.merchant.memberHimpuh : "-"}
											</MDTypography>
										</DataTableBodyCell>
									</TableRow>
									{/* EMAIL && Notarial Number */}
									<TableRow>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body" fontWeight="medium">
												Email
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body">
												{this.state.merchant ? this.state.merchant.email : "-"}
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body" fontWeight="medium">
												Akta Pendirian/ Notarial Number
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body">
												{this.state.merchant ? this.state.merchant.notarialNumber : "-"}
											</MDTypography>
										</DataTableBodyCell>
									</TableRow>

									{/*  TELP & ADDRESS */}
									<TableRow>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body" fontWeight="medium">
												Telpon
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body">
												{this.state.merchant ? this.state.merchant.phone : "-"}
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body" fontWeight="medium">
												Alamat
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body">
												<MDBox style={{ wordWrap: "break-word", width: "20em" }}>
													{this.state.merchant ? this.state.merchant.address : "-"}
												</MDBox>
											</MDTypography>
										</DataTableBodyCell>
									</TableRow>
									{/* PROVINCE & DISTRICT */}
									<TableRow>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body" fontWeight="medium">
												Provinsi
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body">
												{this.state.province && this.state.province.name ? this.state.province.name.toUpperCase() : "-"}
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body" fontWeight="medium">
												Kota/Kabupaten
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body">
												{this.state.district && this.state.district.name ? this.state.district.name.toUpperCase() : "-"}
											</MDTypography>
										</DataTableBodyCell>
									</TableRow>
									{/* WHATSAPP & Status */}
									<TableRow>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body" fontWeight="medium">
												Whatsapp
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body">
												{this.state.merchant ? this.state.merchant.whatsapp : "-"}
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body" fontWeight="medium">
												Status
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body">
												{this.state.merchant !== null ? (
													<MDBadge
														variant="contained"
														badgeContent={this.state.merchant.isActive ? "Aktif" : "Tidak Aktif"}
														size="lg"
														color={this.state.merchant.isActive ? "success" : "warning"}
													/>
												) : ""}
											</MDTypography>
										</DataTableBodyCell>
									</TableRow>
								</TableBody>
							</Table>
						</Grid>
					</MDBox>
				</Card>
				{/* List User */}
				<Card sx={{ mt: 2 }}>
					<MDBox my={2} lineHeight={1} width="100%" display="flex" justifyContent="center">
						<MDTypography variant="h5" fontWeight="medium">
							Daftar User
						</MDTypography>
					</MDBox>
					<MDBox p={2}>
						<TableContainer sx={{ boxShadow: "none" }}>
							<Table>
								<MDBox component="thead">
									<TableRow sx={{ backgroundColor: "#f9fafb" }}>
										<DataTableBodyCell
											width="auto"
											align="left"
										>
											<MDTypography variant="caption" fontWeight="medium" textTransform="uppercase">
												Nama
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell
											width="auto"
											align="left"
										>
											<MDTypography variant="caption" fontWeight="medium" textTransform="uppercase">
												Email
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell
											width="auto"
											align="left"
										>
											<MDTypography variant="caption" fontWeight="medium" textTransform="uppercase">
												Phone
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell
											width="auto"
											align="center"
										>
											<MDTypography variant="caption" fontWeight="medium" textTransform="uppercase">
												Akses
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell
											width="auto"
											align="center"
										>
											<MDTypography variant="caption" fontWeight="medium" textTransform="uppercase">
												Status
											</MDTypography>
										</DataTableBodyCell>
									</TableRow>
								</MDBox>
								<TableBody>
									{this.state.listUsers.map((item, key) => (
										<TableRow key={key}>
											{/* Name */}
											<DataTableBodyCell>
												<MDTypography variant="body">
													{item.name ? item.name : "-"}
												</MDTypography>
											</DataTableBodyCell>
											{/* Email */}
											<DataTableBodyCell>
												<MDTypography variant="body">
													{item.email ? item.email : "-"}
												</MDTypography>
											</DataTableBodyCell>
											{/* Phone */}
											<DataTableBodyCell>
												<MDTypography variant="body">
													{item.phone ? item.phone : "-"}
												</MDTypography>
											</DataTableBodyCell>
											{/* Role */}
											<DataTableBodyCell align="center">
												<MDTypography variant="body">
													{item.role ? item.role : "-"}
												</MDTypography>
											</DataTableBodyCell>
											{/* Status */}
											<DataTableBodyCell align="center">
												<MDTypography variant="body">
													{item.status !== null ? (
														<MDBadge
															variant="contained"
															badgeContent={item.status ? "Aktif" : "Tidak Aktif"}
															size="lg"
															color={item.status ? "success" : "warning"}
														/>
													) : ""}
												</MDTypography>
											</DataTableBodyCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</MDBox>
				</Card>
			</MDBox>
		)
	}
}

export default withRouter(DetailMerchant)