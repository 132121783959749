/* eslint-disable react/jsx-key */
/* eslint-disable no-undef */
import React from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card"

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDBadge from "components/MDBadge";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import StatisticsCard from "contents/Dashboard/components/StatisticsCard"
import CTable from "contents/Components/CTable";
import Loader from "contents/Components/Loader";

import Config from "config"
import useAxios from "libs/useAxios";
import { fnumber } from "libs/currency";
import secureStorage from "libs/secureStorage";

import DashboardOwner from "contents/Dashboard/components/DashboardOwner"
import DashboardBiro from "contents/Dashboard/components/DashboardBiro"

class DashboardAgent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			totalCustomer: 0,
			totalSales: 0,
			totalCommission: 0,
			totalBill: 0,

			historyPayments: [],

			isLoading: false,
			redirect: null
		};
	}

	componentDidMount() {
		const user = secureStorage.getItem('user')
		Promise.all([
			this.loadCustomer(),
			this.loadCountSales(),
			this.loadCountSaleFee(),
			this.loadCountBill(),
			this.loadLastPayment(),
		]).then(results => {
			this.setState({
				totalCustomer: results[0],
				totalSales: results[1],
				totalCommission: results[2].commission,
				totalBill: results[3],

				historyPayments: results[4],
			})
		}).catch(err => console.log(err))
	}

	// load customer by biro
	loadCustomer = () => {
		return useAxios()
			.post(`${Config.ApiUrl}/dashboard/stat/agentOrCustomer`, { roleId: 5 })
			.then(response => {
				const data = response.data
				return data.count
			})
			.catch(err => {
				console.log(err)
				return 0
			})
	}

	// Load transaction package 
	loadCountSales = () => {
		return useAxios()
			.post(`${Config.ApiUrl}/dashboard/stat/sales`)
			.then(response => {
				const data = response.data
				return data.count
			})
			.catch(err => {
				console.log(err)
				return 0
			})
	}

	// Load fee & admin from commission
	loadCountSaleFee = () => {
		return useAxios()
			.post(`${Config.ApiUrl}/dashboard/stat/saleFees`)
			.then(response => {
				const data = response.data;
				return data
			})
			.catch(err => {
				console.log(err)
				return 0
			})
	}

	// Load bill customer from agen
	loadCountBill = () => {
		return useAxios()
			.post(`${Config.ApiUrl}/dashboard/stat/billByAgent`)
			.then(response => {
				const data = response.data
				return data.count
			})
			.catch(err => {
				console.log(err)
				return 0
			})
	}

	loadLastPayment = () => {
		return useAxios()
			.post(`${Config.ApiUrl}/dashboard/list/payment`)
			.then(response => {
				const data = response.data
				return data
			})
			.catch(err => {
				console.log(err)
				return []
			})
	}

	render() {
		return (
			<MDBox py={3}>
				<Grid container spacing={3} mb={5}>
					<Grid item xs={12} md={3} lg={3}>
						<StatisticsCard
							count={this.state.totalCustomer}
							color="info"
							icon="person"
							title={"Jamaah"}
							description={"Total jamaah yang terdaftar"}
						/>
					</Grid>
					<Grid item xs={12} md={3} lg={3}>
						<StatisticsCard
							count={this.state.totalSales}
							color="error"
							icon="shoppingcart"
							title={"Transaksi"}
							description={"Daftar transaksi tahun ini"}
						/>
					</Grid>
					<Grid item xs={12} md={3} lg={3}>
						<StatisticsCard
							count={fnumber(this.state.totalCommission)}
							color="success"
							icon="paid"
							title={`Komisi`}
							description={`Total Komisi tahun ini`}
						/>
					</Grid>
					<Grid item xs={12} md={3} lg={3}>
						<StatisticsCard
							count={fnumber(this.state.totalBill)}
							color="warning"
							icon="payment"
							title={`Tagihan Jamaah`}
							description={`Total tagihan jamaah tahun ini`}
						/>
					</Grid>
				</Grid>

				<Grid container spacing={3} mb={2}>
					<Grid item xs={12} md={12} lg={12}>
						<Card>
							<MDBox
								p={2}
								lineHeight={1}
								display="flex"
								flexDirection={{ md: "row", xs: "column" }}
								justifyContent={{ md: "space-between", xs: "center" }}
								alignItems={{ md: "center", xs: "flex-start" }}
							>
								<MDTypography variant="button" fontWeight="medium">
									History pembayaran terakhir
								</MDTypography>
							</MDBox>
							<MDBox pb={2} px={2}>
								<CTable
									tableHead={[
										{ name: "kode", width: "15%" },
										{ name: "tanggal", width: "15%" },
										{ name: "nama", width: "20%" },
										{ name: "nominal", width: "25%" },
										{ name: "status", width: "20%" },
									]}
									textAlignColumns={["center", "left", "left", "right", "center"]}
									tableData={this.state.historyPayments.map((item, idx) => [
										item.kode,
										item.createdAt,
										item.User ? item.User.name : "",
										fnumber(item.amount),
										<MDBadge
											size="lg"
											variant="contained"
											badgeContent={item.PaymentStatus && item.PaymentStatus ? item.PaymentStatus.name : ""}
											color={item.PaymentStatus.id === 1 ? "warning" : item.PaymentStatus.id === 2 ? "error" : item.PaymentStatus.id === 3 ? "success" : "error"}
										/>,
									])}
								/>
							</MDBox>
						</Card>
					</Grid>
				</Grid>

			</MDBox>
		)
	}
}

export default DashboardAgent