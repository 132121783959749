/* eslint-disable react/prop-types */
import React from "react";
import { Link, Navigate } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import MDDatePicker from "components/MDDatePicker";

import ModalNotif from "contents/Components/ModalNotif";
import withRouter from "contents/Components/withRouter";

import MiniFormCard from "contents/Components/FormCard/MiniFormCard";

import Config from "config";
import _ from "lodash";
import moment from "moment"

import useAxios from "libs/useAxios";
import secureStorage from "libs/secureStorage";

class FormDirectors extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			user: null,
			title: "",
			action: "",
			id: null,

			name: "",
			gender: "Male",
			placeBirth: "",
			dateBirth: "",
			phone: "",
			address: "",
			work: "",
			position: "",
			religion: null,
			province: null,
			district: null,

			provinceOpen: false,
			districtOpen: false,
			genderOpen: false,
			religionOpen: false,

			provinces: [],
			districts: [],
			religions: [],

			error: [],
			success: [],

			disabledSubmit: false,
			redirect: null,
		};

		this.inputRef = React.createRef();
		this.modalNotifRef = React.createRef();
	}

	componentDidMount() {
		this.inputRef.current.focus();

		this.loadProvince()
		this.loadReligion()
		this.loadPath()
	}

	loadPath() {
		const pathname = window.location.pathname;
		const index = pathname.indexOf("edit");
		if (index === -1) {
			this.setState({
				title: "Tambah Direksi Baru",
				action: "add",
			});
		} else {
			const id = this.props.params?.id;
			this.loadDetail(id);
			this.setState({
				id,
				title: "Ubah Data Direksi",
				action: "edit",
			});
		}
	}

	loadDetail(id) {
		useAxios()
			.post(`${Config.ApiUrl}/manage/directors/get`, { id })
			.then((response) => {
				const data = response.data

				const province = data && data.Province ? data.Province : null
				const district = data && data.District ? data.District : null
				const religion = data && data.Religion ? data.Religion : null
				province && this.loadDistrict(province?.id) // load list district

				this.setState({
					name: data.name ?? "",
					phone: data.phone ?? "",
					placeBirth: data.placeBirth ?? "",
					dateBirth: data.dateBirth ?? "",
					address: data.address ?? "",
					position: data.position ?? "",
					religion: religion ? { id: religion.id, label: religion.name } : null,
					province: province ? { id: province.id, label: province.name } : null,
					district: district ? { id: district.id, label: district.name } : null,
					success: {
						...this.state.success,
						name: data.name ? true : false,
						phone: data.phone ? true : false,
						gender: data.gender ? true : false,
						placeBirth: data.placeBirth ? true : false,
						dateBirth: data.dateBirth ? true : false,
						religion: religion ? true : false,
						position: data.position ? true : false,
						address: data.address ? true : false,
						province: province ? true : false,
						district: district ? true : false,
					},
				});
			})
			.catch((err) => {
				console.log(err);
				this.modalNotifRef.current.setShow({
					modalTitle: "Gagal",
					modalMessage: err.response ? err.response.data : "Koneksi jaringan terputus",
					onClose: () => {
						this.setState({ redirect: "/manage/directors" });
					},
				});
			});
	}

	loadReligion = () => {
		useAxios()
			.post(`${Config.ApiUrl}/dropdown/religion`)
			.then(response => {
				const data = response.data
				const religions = data.map(item => ({
					id: item.id,
					label: item.name
				}))
				this.setState({ religions })
			})
			.catch(err => {
				console.log(err)
			})
	}

	loadProvince = () => {
		useAxios()
			.post(`${Config.ApiUrl}/master/region/province/dropdown`, { countryId: 1 })
			.then(response => {
				const data = response.data
				const provinces = data.map(item => ({
					id: item.id,
					label: item.name
				}))
				this.setState({ provinces })
			})
			.catch(err => {
				console.log(err)
			})
	}

	loadDistrict = (provinceId) => {
		useAxios()
			.post(`${Config.ApiUrl}/master/region/district/dropdown`, { provinceId })
			.then(response => {
				const data = response.data
				const districts = data.map(item => ({ id: item.id, label: item.name }))
				this.setState({ districts })
			})
			.catch(err => {
				console.log(err)
			})
	}

	handleChange = (e) => {
		const { id, value } = e.target;
		this.setState({
			[id]: value,
		});
	};

	handleBlur = (e) => {
		const { id, value } = e.target;
		// eslint-disable-next-line no-empty
		if (value) {
			this.setState({
				success: { ...this.state.success, [e.target.id]: true },
				error: { ...this.state.error, [e.target.id]: false },
			});
		} else {
			this.setState({
				success: { ...this.state.success, [e.target.id]: false },
				error: { ...this.state.error, [e.target.id]: true },
			});
		}
	};

	handleSubmit = () => {
		let error = this.state.error;
		let success = this.state.success;

		// console.log({ success })
		if (
			success.name &&
			success.gender &&
			success.phone &&
			success.placeBirth &&
			success.dateBirth &&
			success.religion &&
			success.position &&
			success.province &&
			success.district
		) {
			this.sendData();
		} else {
			this.modalNotifRef.current.setShow({
				modalTitle: "Gagal",
				modalMessage: "Data form masih belum lengkap, Silahkan di cek kembali !",
			});
		}
	};

	sendData = () => {
		this.setState({ disabledSubmit: true });
		const user = this.state.user;

		const payload = {
			id: this.state.id,
			name: this.state.name,
			gender: this.state.gender,
			phone: this.state.phone,
			placeBirth: this.state.placeBirth,
			dateBirth: this.state.dateBirth,
			address: this.state.address,
			position: this.state.position,
			religionId: this.state.religion?.id,
			provinceId: this.state.province?.id,
			districtId: this.state.district?.id,
		};

		const url =
			this.state.action === "add" ? "/manage/directors/create" : "/manage/directors/update";
		useAxios()
			.post(`${Config.ApiUrl}${url}`, payload)
			.then((response) => {
				this.modalNotifRef.current.setShow({
					modalTitle: "Sukses",
					modalMessage: response.data,
					onClose: () => {
						this.setState({ redirect: "/manage/directors" });
					},
				});

				this.setState({
					disabledSubmit: false,
				});
			})
			.catch((err) => {
				this.setState({ disabledSubmit: false });
				if (err.response) {
					this.modalNotifRef.current.setShow({
						modalTitle: "Gagal",
						modalMessage: err.response ? err.response.data : "Koneksi jaringan terputus",
					});
				}
				// eslint-disable-next-line no-empty
				else {
					this.modalNotifRef.current.setShow({
						modalTitle: "Gagal",
						modalMessage: "Koneksi jaringan terputus",
					});
				}
			});
	}

	render() {
		if (this.state.redirect) {
			return <Navigate to={this.state.redirect} />;
		}

		return (
			<MiniFormCard>
				<ModalNotif ref={this.modalNotifRef} />
				<MDBox p={3} lineHeight={1}>
					<MDTypography variant="h5" fontWeight="medium">
						{this.state.title}
					</MDTypography>
				</MDBox>

				<MDBox p={3}>
					<MDBox component="form" role="form">
						<Grid container spacing={3}>
							<Grid item xs={12} md={12} lg={12}>
								{/* NAME */}
								<MDBox mb={2}>
									<MDInput
										inputRef={this.inputRef}
										id="name"
										type="text"
										label="Nama"
										value={this.state.name}
										onChange={this.handleChange}
										onBlur={this.handleBlur}
										success={this.state.success ? this.state.success.name : false}
										error={this.state.error ? this.state.error.name : false}
										fullWidth
									/>
								</MDBox>
								{/* GENDER */}
								<MDBox mb={2}>
									<Autocomplete
										disableClearable
										value={this.state.gender}
										open={this.state.genderOpen}
										onOpen={() => this.setState({ genderOpen: true })}
										onClose={() => this.setState({ genderOpen: false })}
										onFocus={() => this.setState({ genderOpen: true })}
										options={["Male", "Female"]}
										onChange={(e, value) => {
											if (value) {
												this.setState({
													gender: value,
													error: { ...this.state.error, gender: false },
													success: { ...this.state.success, gender: true }
												})
											}
										}}
										sx={{
											".MuiAutocomplete-input": {
												padding: "7.5px 5px 7.5px 8px !important"
											},
											".MuiOutlinedInput-root": {
												padding: "2px !important"
											}
										}}
										isOptionEqualToValue={(option, value) => option === value}
										renderInput={(params) => <MDInput label="Jenis Kelamin" {...params} />}
									/>
								</MDBox>
								{/* PHONE */}
								<MDBox mb={2}>
									<MDInput
										id="phone"
										type="text"
										label="Nomor Telpon"
										value={this.state.phone}
										onChange={this.handleChange}
										onBlur={this.handleBlur}
										success={this.state.success ? this.state.success.phone : false}
										error={this.state.error ? this.state.error.phone : false}
										fullWidth
									/>
								</MDBox>
								{/* PLACE BIRTH */}
								<MDBox mb={2}>
									<MDInput
										id="placeBirth"
										type="text"
										label="Tempat Lahir"
										value={this.state.placeBirth}
										onChange={this.handleChange}
										onBlur={this.handleBlur}
										success={this.state.success ? this.state.success.placeBirth : false}
										error={this.state.error ? this.state.error.placeBirth : false}
										fullWidth
									/>
								</MDBox>
								{/* Date Birth */}
								<MDBox mb={2}>
									<MDDatePicker
										input={{
											id: "dateBirth",
											placeholder: "Tanggal Lahir",
											fullWidth: true,
										}}
										value={this.state.dateBirth}
										onChange={(value) => {
											const date = moment(value[0]).format("YYYY-MM-DD");
											this.setState({
												dateBirth: date,
												error: { ...this.state.error, dateBirth: false },
												success: { ...this.state.success, dateBirth: true },
											});
										}}
									/>
								</MDBox>
								{/* Religion */}
								<MDBox mb={2}>
									<Autocomplete
										fullWidth
										id="religion"
										open={this.state.religionOpen}
										onOpen={() => this.setState({ religionOpen: true })}
										onClose={() => this.setState({ religionOpen: false })}
										onFocus={() => this.setState({ religionOpen: true })}
										value={this.state.religion}
										options={this.state.religions}
										onChange={(e, newValue) => {
											if (newValue) {
												this.setState({
													religion: newValue,
													error: { ...this.state.error, religion: false },
													success: { ...this.state.success, religion: true }
												})
											}
										}}
										sx={{
											".MuiAutocomplete-input": {
												padding: "7.5px 5px 7.5px 8px !important"
											},
											".MuiOutlinedInput-root": {
												padding: "4px !important"
											}
										}}
										isOptionEqualToValue={(option, value) => option.id === value.id}
										renderInput={(params) => <MDInput label="Pilih Agama" {...params} />}
									/>
								</MDBox>
								{/* Postition */}
								<MDBox mb={2}>
									<MDInput
										id="position"
										type="text"
										label="Jabatan"
										value={this.state.position}
										onChange={this.handleChange}
										onBlur={this.handleBlur}
										success={this.state.success ? this.state.success.position : false}
										error={this.state.error ? this.state.error.position : false}
										fullWidth
									/>
								</MDBox>
								{/* Province */}
								<MDBox mb={2}>
									<Autocomplete
										fullWidth
										id="province"
										open={this.state.provinceOpen}
										onOpen={() => this.setState({ provinceOpen: true })}
										onClose={() => this.setState({ provinceOpen: false })}
										onFocus={() => this.setState({ provinceOpen: true })}
										value={this.state.province}
										options={this.state.provinces}
										onChange={(e, newValue) => {
											if (newValue) {
												this.setState({
													province: newValue,
													district: null,
													districts: [],
													error: { ...this.state.error, province: false },
													success: { ...this.state.success, province: true }
												})
												this.loadDistrict(newValue.id)
											}
										}}
										sx={{
											".MuiAutocomplete-input": {
												padding: "7.5px 5px 7.5px 8px !important"
											},
											".MuiOutlinedInput-root": {
												padding: "4px !important"
											}
										}}
										isOptionEqualToValue={(option, value) => option.id === value.id}
										renderInput={(params) => <MDInput label="Pilih Provinsi" {...params} />}
									/>
								</MDBox>
								{/* District */}
								<MDBox mb={2}>
									<Autocomplete
										fullWidth
										id="district"
										open={this.state.districtOpen}
										onOpen={() => this.setState({ districtOpen: true })}
										onClose={() => this.setState({ districtOpen: false })}
										onFocus={() => this.setState({ districtOpen: true })}
										value={this.state.district}
										options={this.state.districts}
										onChange={(e, value) => {
											if (value) {
												this.setState({
													district: value,
													error: { ...this.state.error, district: false },
													success: { ...this.state.success, district: true }
												})
											}
										}}
										sx={{
											".MuiAutocomplete-input": {
												padding: "7.5px 5px 7.5px 8px !important"
											},
											".MuiOutlinedInput-root": {
												padding: "4px !important"
											}
										}}
										isOptionEqualToValue={(option, value) => option.id === value.id}
										renderInput={(params) => <MDInput label="Pilih Kabupaten/Kota" {...params} />}
									/>
								</MDBox>
								{/* Address */}
								<MDBox mb={2}>
									<MDInput
										id="address"
										type="text"
										multiline
										rows={3}
										label="Alamat"
										value={this.state.address}
										onChange={this.handleChange}
										onBlur={this.handleBlur}
										success={this.state.success ? this.state.success.address : false}
										error={this.state.error ? this.state.error.address : false}
										fullWidth
									/>
								</MDBox>

								<MDBox pt={3} display="flex" justifyContent="space-between">
									<MDButton
										variant="gradient"
										color="error"
										component={Link}
										to={{ pathname: "/manage/directors" }}
									>
										KEMBALI
									</MDButton>
									<MDButton
										type="button"
										variant="gradient"
										color="info"
										disabled={this.state.disabledSubmit}
										onClick={this.handleSubmit}
									>
										SUBMIT
									</MDButton>
								</MDBox>
							</Grid>
						</Grid>
					</MDBox>
				</MDBox>
			</MiniFormCard>
		);
	}
}

export default withRouter(FormDirectors);
