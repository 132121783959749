/* eslint-disable react/prop-types */
import React, { Fragment } from "react";
import { Document, Page, Text, Image, View, StyleSheet, Font } from "@react-pdf/renderer";

import logo from "assets/img/logoApp.png";

import RobotoMonoRegular from "assets/fonts/RobotoMono/RobotoMono-Regular.ttf";
import RobotMonoBold from "assets/fonts/RobotoMono/RobotoMono-Bold.ttf";
import RobotoMonoBoldItalic from "assets/fonts/RobotoMono/RobotoMono-BoldItalic.ttf";
import RobotoMonoItalic from "assets/fonts/RobotoMono/RobotoMono-Italic.ttf";

import FinancialStatementStyle from "helper/styles/financialStatementStyle";
import moment from "moment";
import { formatCurrency } from "libs/currency"
import secureStorage from "libs/secureStorage";

// Font
Font.register({
  family: "RobotoMono",
  format: "truetype",
  fonts: [
    { src: RobotoMonoRegular },
    { src: RobotoMonoBoldItalic },
    { src: RobotoMonoItalic },
    { src: RobotMonoBold },
  ],
});
// Create styles
const styles = StyleSheet.create(FinancialStatementStyle);

class PdfFinancialTravel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const data = this.props.data;
  }

  render() {
    const data = this.props.data;
    const user = secureStorage.getItem('user')

    return (
      <Document>
        <Page size="A4" style={styles.page}>
          {/* HAADER */}
          <View style={styles.headerContainer}>
            <Text style={styles.headerTitle}>LAPORAN KEUANGAN</Text>
            <Text style={styles.headerCompany}>{user.Merchant?.name.toUpperCase()}</Text>
            <Text style={styles.headerDate}>
              dari {data.startDate} sampai {data.endDate}
            </Text>
          </View>
          <View style={styles.contentContainer}>
            <Fragment>
              <View style={{ width: "76%", borderTopWidth: 1 }}></View>

              <View style={styles.tableRow}>
                <Text style={styles.tableTitle}>Pendapatan</Text>
                <Text style={{ width: "30%" }}></Text>
              </View>
              <View style={styles.tableRow}>
                <Text style={styles.tableCaption}>Transaksi Paket</Text>
                <Text style={styles.tableValue}>{formatCurrency(data.amountTrx)}</Text>
              </View>
              <View style={styles.tableRow}>
                <Text style={styles.tableCaption}>Refund Komisi Agen</Text>
                <Text style={styles.tableValue}>{formatCurrency(data.refundCommissionAgent)}</Text>
              </View>
              <View style={styles.tableRow}>
                <Text style={styles.tableCaption}>Refund Biaya Admin</Text>
                <Text style={styles.tableValue}>{formatCurrency(data.refundAdmin)}</Text>
              </View>
              <View style={styles.tableRow}>
                <Text style={{ ...styles.tableTitle, borderBottomWidth: 1 }}>Total Dana Masuk</Text>
                <Text style={styles.tableTotal}>{formatCurrency(data.incomeTotal)}</Text>
              </View>

              <View style={styles.tableRow}>
                <Text style={styles.tableTitle}>Pengeluaran</Text>
                <Text style={{ width: "30%" }}></Text>
              </View>
              <View style={styles.tableRow}>
                <Text style={styles.tableCaption}>Total Komisi Agen</Text>
                <Text style={styles.tableValue}>{formatCurrency(data.commissionAgent)}</Text>
              </View>
              <View style={styles.tableRow}>
                <Text style={styles.tableCaption}>Total Biaya Admin</Text>
                <Text style={styles.tableValue}>{formatCurrency(data.admin)}</Text>
              </View>
              <View style={styles.tableRow}>
                <Text style={styles.tableCaption}>Refund Biaya Order</Text>
                <Text style={styles.tableValue}>{formatCurrency(data.refundTrx)}</Text>
              </View>
              <View style={styles.tableRow}>
                <Text style={{ ...styles.tableTitle, borderBottomWidth: 1 }}>
                  Total Dana Keluar
                </Text>
                <Text style={styles.tableTotal}>{formatCurrency(data.outFundTotal)}</Text>
              </View>
              <View style={styles.tableRow}>
                <Text style={{ ...styles.tableTitle, borderBottomWidth: 1 }}>
                  Laba/(Rugi) Bersih
                </Text>
                <Text style={styles.tableTotal}>{formatCurrency(data.netIncome)}</Text>
              </View>
            </Fragment>
          </View>
        </Page>
      </Document>
    );
  }
}

export default PdfFinancialTravel;
