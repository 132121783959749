import React, { useState } from "react";
import PropTypes from "prop-types";
import { Navigate, Link } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDDatePicker from "components/MDDatePicker";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import GroupDataTable from 'contents/Administrasi/Departure/components/GroupDataTable'
import Pagination from "contents/Components/Pagination";
import DialogStatus from "contents/Administrasi/Departure/components/DialogStatus"

import useAxios from "libs/useAxios"
import Config from 'config'
import secureStorage from "libs/secureStorage";
import { fnumber } from "libs/currency";

import moment from "moment";

class Departure extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			category: null,
			product: null,
			startDate: "",
			endDate: "",

			products: [],
			categories: [],

			rowsPerPage: 10,
			currentPage: 1,
			totalPages: 0,
			totalData: 0,
			tableHead: [
				{ Header: "kode", accessor: "kode", width: "5%" },
				{ Header: "paket", accessor: "product", width: "20%" },
				{ Header: "jamaah", accessor: "jamaah", width: "15%" },
				{ Header: "tanggal berangkat", accessor: "departureDate", width: "20%" },
				{ Header: "status", accessor: "departureStatus", width: "15%" },
				{ Header: "action", accessor: "action", width: "15%", align: "center" },
			],
			rows: [],
			keyword: "",
			isLoading: true,
		}

		this.dialogStatusRef = React.createRef()
	}

	componentDidMount() {
		const user = secureStorage.getItem('user')

		this.loadData()
		this.loadProduct()
		this.loadCategory()
	}

	loadProduct = (params) => {
		const merchantId = params && params.merchantId ? { merchantId: params.merchantId } : {}
		const payload = {
			...merchantId
		}

		useAxios()
			.post(`${Config.ApiUrl}/product/dropdown`, payload)
			.then(response => {
				const data = response.data

				this.setState({
					products: data.map(item => ({ id: item.id, label: item.name }))
				})
			})
			.catch(err => {
				console.log(err)
			})
	}

	loadCategory() {
		useAxios()
			.post(`${Config.ApiUrl}/product/category/dropdown`)
			.then(response => {
				const data = response.data;
				const categories = data.map(item => {
					return {
						id: item.id,
						label: item.name
					}
				});

				this.setState({ categories })
			})
	}

	loadData = (params) => {
		const user = secureStorage.getItem("user");
		this.setState({ isLoading: true })
		const productCategoryId = params && params.productCategoryId ? { productCategoryId: params.productCategoryId } : {}
		const productId = params && params.productId ? { productId: params.productId } : {}

		const payload = {
			keyword: params && params.keyword ? params.keyword : this.state.keyword,
			currentPage: params && params.currentPage ? params.currentPage : 1,
			rowsPerPage: params && params.rowsPerPage ? params.rowsPerPage : this.state.rowsPerPage,
			startDate: params && params.startDate ? params.startDate : this.state.startDate,
			endDate: params && params.endDate ? params.endDate : this.state.endDate,
			...productCategoryId,
			...productId
		};

		useAxios()
			.post(`${Config.ApiUrl}/product`, payload)
			.then(response => {
				const data = response.data
				const output = data.output.map((item, idx) => {
					const departureStatus = item && item.DepartureStatus ? item.DepartureStatus : null
					let departureDate = item && item.departureDate ? item.departureDate : null
					departureDate = departureDate ? moment(departureDate).utc().add(7, 'days').format('YYYY-MM-DD') : ""

					return {
						kode: item.kode,
						product: item.name,
						departureDate,
						departureStatus: departureStatus && departureStatus.name ? departureStatus.name : "",
						action: user && [2, 3].includes(user.roleId) ? (
							<Tooltip title="Ubah Status">
								<MDButton
									variant="gradient"
									color="success"
									size="small"
									onClick={() => {
										console.log('[EDIT STATUS PAKET]')
										this.dialogStatusRef.current.setShow({
											productId: item && item.id ? item.id : null,
											productName: item && item.name ? item.name : "",
											departureDate: departureDate,
											statusName: departureStatus ? departureStatus.name : null,
											type: "product",
											show: true
										})
									}}
									iconOnly
								>
									<Icon fontSize="medium">edit</Icon>
								</MDButton>
							</Tooltip>
						) : ("-")
					}
				})

				this.setState({
					totalPages: data.totalPages,
					totalData: data.totalData,
					rows: output,
					isLoading: false
				})
			})
			.catch(err => {
				console.log(err)
				this.setState({ isLoading: false })
			})
	}

	render() {
		const user = secureStorage.getItem('user')

		return (
			<MDBox pb={3} my={3}>
				<DialogStatus ref={this.dialogStatusRef} onAction={this.loadData.bind(this)} />
				<Card>
					<MDBox p={2} lineHeight={1}>
						<MDTypography variant="h5" fontWeight="medium">
							Keberangkatan
						</MDTypography>
					</MDBox>
					<MDBox px={2} width="100%" display="flex" justifyContent="flex-start">
						<Grid container spacing={3}>
							{/* Product */}
							<Grid item xs={12} md={3} lg={3}>
								<Autocomplete
									value={this.state.product}
									options={this.state.products}
									onChange={(e, value) => {
										this.setState({ product: value })
										this.loadData({
											currentPage: 1,
											startDate: this.state.startDate ? this.state.startDate : "",
											endDate: this.state.endDate ? this.state.endDate : "",
											productId: value ? value.id : null,
											productCategoryId: this.state.category ? this.state.category.id : null,
										})
									}}
									sx={{
										".MuiAutocomplete-input": {
											padding: "7.5px 5px 7.5px 8px !important"
										},
										".MuiOutlinedInput-root": {
											padding: "1.5px !important"
										}
									}}
									isOptionEqualToValue={(option, value) => option.id === value.id}
									renderInput={(params) => <MDInput sx={{ padding: "0px" }} fullWidth label="Pilih Paket" {...params} />}
								/>
							</Grid>
							{/* Category */}
							<Grid item xs={12} md={3} lg={3}>
								<Autocomplete
									value={this.state.category}
									options={this.state.categories}
									onChange={(e, value) => {
										this.setState({ category: value })
										this.loadData({
											currentPage: 1,
											productCategoryId: value ? value.id : null,
											startDate: this.state.startDate ? this.state.startDate : "",
											endDate: this.state.endDate ? this.state.endDate : "",
											productId: this.state.product ? this.state.product.id : null,
										})
									}}
									sx={{
										".MuiAutocomplete-input": {
											padding: "7.5px 5px 7.5px 8px !important"
										},
										".MuiOutlinedInput-root": {
											padding: "1.5px !important"
										}
									}}
									isOptionEqualToValue={(option, value) => option.id === value.id}
									renderInput={(params) => <MDInput sx={{ padding: "0px" }} fullWidth label="Pilih Kategori" {...params} />}
								/>
							</Grid>
							{/* Start Date */}
							<Grid item xs={12} md={2} lg={2}>
								<MDDatePicker
									input={{
										id: "startDate",
										placeholder: "Tanggal Awal",
										fullWidth: true,
										sx: {
											".MuiInputBase-input": {
												height: "1em !important"
											}
										}
									}}
									value={this.state.startDate}
									onChange={(value) => {
										const date = moment(value[0]).format('YYYY-MM-DD')
										this.setState({ startDate: date })
									}}
								/>
							</Grid>
							{/* End Date */}
							<Grid item xs={12} md={2} lg={2}>
								<MDDatePicker
									input={{
										id: "endDate",
										placeholder: "Tanggal Akhir",
										fullWidth: true,
										sx: {
											".MuiInputBase-input": {
												height: "1em !important"
											}
										}
									}}
									value={this.state.endDate}
									onChange={(value) => {
										const date = moment(value[0]).format('YYYY-MM-DD')
										this.setState({ endDate: date })
									}}
								/>
							</Grid>
							{/* Button Search */}
							<Grid item xs={12} md={1} lg={1}>
								<MDBox
									width="100%"
									display="flex"
									flexDirection="row"
									justifyContent="flex-start"
								>
									<MDButton
										color="info"
										variant="gradient"
										onClick={() => {
											const startDate = this.state.startDate ? this.state.startDate : ""
											const endDate = this.state.endDate ? this.state.endDate : ""
											this.loadData({
												currentPage: 1,
												startDate,
												endDate,
												productId: this.state.product ? this.state.product.id : null,
												productCategoryId: this.state.category ? this.state.category.id : null,
											})
										}}
										iconOnly
									>
										<Icon>search</Icon>
									</MDButton>
									<MDBox ml={1}>
										<MDButton
											color="error"
											variant="gradient"
											onClick={() => {
												this.setState({
													currentPage: 1,
													startDate: "",
													endDate: "",
													product: null,
													productCategory: null
												}, () => {
													this.loadData()
												})
											}}
											iconOnly
										>
											<Icon>refresh</Icon>
										</MDButton>
									</MDBox>
								</MDBox>
							</Grid>
						</Grid>
					</MDBox>
					<MDBox p={2}>
						<GroupDataTable
							isLoading={this.state.isLoading}
							tableHead={this.state.tableHead}
							tableData={this.state.rows}
						/>
						<Pagination
							totalPages={this.state.totalPages}
							totalData={this.state.totalData}
							currentPage={this.state.currentPage}
							rowsPerPage={[10, 25, 50, "All"]}
							totalButton={3}
							defaultRowsPerPage={this.state.rowsPerPage}
							onChangeRowsPerPage={(value) => {
								this.setState({ rowsPerPage: value, currentPage: 1 })
								this.loadData({
									rowsPerPage: value,
									currentPage: 1,
									startDate: this.state.startDate ? this.state.startDate : "",
									endDate: this.state.endDate ? this.state.endDate : "",
									productId: this.state.product ? this.state.product.id : null,
									productCategoryId: this.state.category ? this.state.category.id : null,
								})
							}}
							onChangePage={(currentPage) => {
								if (currentPage !== this.state.currentPage) {
									this.setState({ currentPage })
									this.loadData({
										currentPage,
										startDate: this.state.startDate ? this.state.startDate : "",
										endDate: this.state.endDate ? this.state.endDate : "",
										productId: this.state.product ? this.state.product.id : null,
										productCategoryId: this.state.category ? this.state.category.id : null,
									})
								}
							}}
						/>
					</MDBox>
				</Card>
			</MDBox>
		)
	}
}

export default Departure