/* eslint-disable react/prop-types */
import React from 'react'
import { Link, Navigate } from 'react-router-dom';
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from 'components/MDInput';
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import ModalNotif from 'contents/Components/ModalNotif';
import withRouter from 'contents/Components/withRouter';

import MiniFormCard from 'contents/Components/FormCard/MiniFormCard';

import Config from 'config'
import _ from "lodash"

import useAxios from "libs/useAxios"
import secureStorage from 'libs/secureStorage';

class FormCountry extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			user: null,
			title: "",
			action: "",
			id: null,

			kode: "",
			name: "",

			error: [],
			success: [],

			disabledSubmit: false,
			redirect: null
		}

		this.inputRef = React.createRef()
		this.modalNotifRef = React.createRef()
	}

	componentDidMount() {
		this.inputRef.current.focus()
		const user = secureStorage.getItem('user')

		this.setState({
			user: user ? user : null
		})
		this.loadPath()
	}

	loadPath() {
		const pathname = window.location.pathname
		const index = pathname.indexOf('edit')
		if (index === -1) {
			this.setState({
				title: "Tambah Nagara Baru",
				action: "add"
			})
		}
		else {
			const id = this.props.params?.id
			this.loadDetail(id)
			this.setState({
				id,
				title: "Ubah Negara",
				action: "edit",
				error: {
					...this.state.error,
					kode: false,
					name: false
				},
				success: {
					...this.state.success,
					kode: true,
					name: true
				}
			})
		}
	}

	loadDetail(id) {
		useAxios()
			.post(`${Config.ApiUrl}/master/region/country/detail`, { id })
			.then(response => {
				const data = response.data
				this.setState({
					kode: data.kode,
					name: data.name,
				})
			})
			.catch(err => {
				console.log(err)
				this.modalNotifRef.current.setShow({
					modalTitle: 'Gagal',
					modalMessage: err.response ? err.response.data : "Koneksi jaringan terputus",
					onClose: () => {
						this.setState({ redirect: '/master/region/country' })
					}
				})
			})
	}

	handleChange = (e) => {
		const { id, value } = e.target;
		this.setState({
			[id]: value
		})
	}

	handleBlur = (e) => {
		const user = this.state.user
		const { id, value } = e.target
		// eslint-disable-next-line no-empty
		if (value) {
			this.setState({
				success: { ...this.state.success, [e.target.id]: true },
				error: { ...this.state.error, [e.target.id]: false }
			})
		}
		else {
			this.setState({
				success: { ...this.state.success, [e.target.id]: false },
				error: { ...this.state.error, [e.target.id]: true }
			})
		}
	}

	handleKeyDown = (e) => {
		// if (e.key === "Enter") this.handleSubmit()
	}

	handleSubmit = () => {
		let error = this.state.error
		let success = this.state.success

		// console.log({ success, error })
		if (
			success.name &&
			success.kode
		) {
			this.sendData()
		}
		else {
			this.modalNotifRef.current.setShow({
				modalTitle: "Gagal",
				modalMessage: "Data form masih belum lengkap, Silahkan di cek kembali !",
			})
		}
	}

	sendData = () => {
		const payload = {
			kode: this.state.kode,
			name: this.state.name,
			id: this.state.id
		}

		const url = this.state.action === "add" ? "/master/region/country/create" : "/master/region/country/update"
		useAxios()
			.post(`${Config.ApiUrl}${url}`, payload)
			.then(response => {
				this.modalNotifRef.current.setShow({
					modalTitle: "Sukses",
					modalMessage: response.data,
					onClose: () => {
						this.setState({ redirect: '/master/region/country' })
					}
				})

				this.setState({
					disabledSubmit: false
				})
			})
			.catch(err => {
				this.setState({ disabledSubmit: false })
				if (err.response) {
					this.modalNotifRef.current.setShow({
						modalTitle: "Gagal",
						modalMessage: err.response.data,
					})
				}
				// eslint-disable-next-line no-empty
				else {
					this.modalNotifRef.current.setShow({
						modalTitle: 'Gagal',
						modalMessage: "Koneksi jaringan terputus",
					})
				}
			})
	}

	render() {
		if (this.state.redirect) {
			return <Navigate to={this.state.redirect} />
		}

		return (
			<DashboardLayout>
				<DashboardNavbar />
				<ModalNotif ref={this.modalNotifRef} />
				<MiniFormCard>
					<MDBox p={3} lineHeight={1}>
						<MDTypography variant="h5" fontWeight="medium">
							{this.state.title}
						</MDTypography>
					</MDBox>

					<MDBox p={3}>
						<MDBox component="form" role="form">
							<Grid container spacing={3}>
								<Grid item xs={12} md={12} lg={12}>
									<MDBox mb={2}>
										<MDInput
											id="kode"
											type="text"
											inputRef={this.inputRef}
											label="Kode Negara"
											value={this.state.kode}
											onChange={this.handleChange}
											onBlur={this.handleBlur}
											onKeyDown={this.handleKeyDown}
											success={this.state.success ? this.state.success.kode : false}
											error={this.state.error ? this.state.error.kode : false}
											fullWidth
										/>
									</MDBox>
									<MDBox mb={2}>
										<MDInput
											id="name"
											type="text"
											label="Nama Negara"
											value={this.state.name}
											onChange={this.handleChange}
											onBlur={this.handleBlur}
											onKeyDown={this.handleKeyDown}
											success={this.state.success ? this.state.success.name : false}
											error={this.state.error ? this.state.error.name : false}
											fullWidth
										/>
									</MDBox>

									<MDBox pt={3} display="flex" justifyContent="space-between">
										<MDButton
											variant="gradient"
											color="error"
											component={Link}
											to={{ pathname: "/master/region/country" }}
										>
											KEMBALI
										</MDButton>
										<MDButton
											type="button"
											variant="gradient"
											color="info"
											disabled={this.state.disabledSubmit}
											onKeyDown={this.handleKeyDown}
											onClick={this.handleSubmit}
										>
											SUBMIT
										</MDButton>
									</MDBox>
								</Grid>
							</Grid>
						</MDBox>
					</MDBox>
				</MiniFormCard>
			</DashboardLayout>
		)
	}
}

export default withRouter(FormCountry)