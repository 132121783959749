const RekomendationStyle = {
  page: {
    fontFamily: "RobotoMono",
    fontSize: 6,
    // paddingTop: 25,
    // paddingLeft: 20,
    // paddingRight: 20,
    lineHeight: 1.5,
    flexDirection: "column",
  },
  headerContainer: {
    width: "100%",
    marginBottom: 2,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 5,
    paddingVertical: 20,
    paddingHorizontal: 30,
  },
  contentContainer: {
    marginHorizontal: 30,
  },
  letterContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    marginTop: 10,
  },
  headerLogo: {
		width: 100,
		height: 80,
		marginRight: 10,
	},
  letterLeft: {
    paddingHorizontal: 10,
    width: "100%",
  },
  headerLetterContent: {
    marginVertical: 10,
  },
  headerContent: {
    fontSize: 12,
    textAlign: "left",
  },
  regards: {
    fontSize: 12,
    textAlign: "left",
    // fontWeight:"400",
  },
  subContent: {
    marginVertical: 5,
    marginHorizontal: 40,
  },
  footerLetterContent: {
    marginVertical: 10,
  },
  footerContent: {
    fontSize: 12,
    textAlign: "left",
  },
  companyName: {
    fontWeigh: "bold",
  },
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 24,
  },
  tableHeadContainer: {
    flexDirection: "row",
    borderBottomColor: "#0000",
    borderBottomWidth: 1,
    borderWidth: 1,
    borderColor: "#0000",
    alignItems: "center",
    height: 24,
    textAlign: "center",
    fontWeight: "bold",
    flexGrow: 1,
    textTransform: "uppercase",
  },
  tableHeadNo: {
    width: "10%",
    fontSize: 12,
  },
  tableHeadName: {
    width: "25%",
    fontSize: 12,
  },
  tableHeadBirth: {
    width: "30%",
    fontSize: 12,
  },
  tableHeadAddress: {
    width: "35%",
    fontSize: 12,
  },

  tableRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    alignItems: "center",
    height: 24,
  },

  tableRowNo: {
    width: "10%",
    textAlign: "center",
    paddingLeft: 8,
    fontSize: 10,
  },
  tableRowName: {
    width: "25%",
    textAlign: "center",
    paddingLeft: 8,
    fontSize: 10,
  },
  tableRowBirth: {
    width: "30%",
    textAlign: "center",
    paddingLeft: 8,
    fontSize: 10,
  },
  tableRowAddress: {
    width: "35%",
    textAlign: "center",
    paddingLeft: 8,
    fontSize: 10,
  },
  footerRow: {
    marginVertical:30,
		alignItems: 'flex-end',
  },
  footCaption: {
    width: "40%",
    fontSize: 10,
    textAlign: "center",
  },
  footValue: {
    width: "40%",
    fontSize: 10,
    textAlign: "left",
  },
};

export default RekomendationStyle;
