import { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import useAxios from "libs/useAxios";
import Config from "config"
import secureStorage from "libs/secureStorage";

import Confirm from "contents/Components/Confirm"
import ModalNotif from "contents/Components/ModalNotif";

function ButtonAction({ id, statusId, refreshData }) {
	const confirmRef = useRef()
	const modalNotifRef = useRef()

	const navigate = useNavigate()
	const [menu, setMenu] = useState(null);
	const [user, setUser] = useState(null)

	useEffect(() => {
		setUser(secureStorage.getItem('user'))
	}, [])

	const openMenu = (event) => setMenu(event.currentTarget)
	const closeMenu = () => setMenu(null)

	const handleStatus = (isStatus) => {
		closeMenu()
		confirmRef.current.setShow({
			title: 'Konfirmasi',
			message: "Apakah anda yakin ?",
			onAction: () => {
				submitStatus(isStatus)
			}
		})
	}

	const submitStatus = (isStatus) => {
		useAxios()
			.post(`${Config.ApiUrl}/trx/status/set`, {
				statusId: isStatus ? 4 : 3,
				saleId: id
			})
			.then(response => {
				modalNotifRef.current.setShow({
					modalTitle: "Sukses",
					modalMessage: response.data,
					onClose: () => {
						refreshData()
					}
				})
			})
			.catch(err => {
				modalNotifRef.current.setShow({
					modalTitle: 'Gagal',
					modalMessage: err.response ? err.response.data : "Koneksi jaringan terputus",
				})
			})
	}


	const renderMenu = (
		<Menu
			anchorEl={menu}
			anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
			transformOrigin={{ vertical: "top", horizontal: "left" }}
			open={Boolean(menu)}
			onClose={closeMenu}
			keepMounted
		>
			{user && [2, 3].includes(user.roleId) && [1].includes(statusId) && (
				<MenuItem onClick={() => handleStatus(true)}>Setujui</MenuItem>
			)}
			{user && [2, 3].includes(user.roleId) && [1].includes(statusId) && (
				<MenuItem onClick={() => handleStatus(false)}>Tolak</MenuItem>
			)}
		</Menu>
	);

	return (
		<MDBox display="flex">
			<Confirm ref={confirmRef} />
			<ModalNotif ref={modalNotifRef} />

			<MDButton
				variant="contained"
				color="info"
				size="small"
				onClick={openMenu}
			>
				actions&nbsp;
				<Icon>keyboard_arrow_down</Icon>
			</MDButton>
			{renderMenu}
		</MDBox>
	)
}

ButtonAction.propTypes = {
	id: PropTypes.number.isRequired,
	statusId: PropTypes.number.isRequired,
	refreshData: PropTypes.func.isRequired
}

export default ButtonAction