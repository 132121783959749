/* eslint-disable no-undef */
const Printer = async (payload, view) => {
    const { getUrl } = require('libs/pdf')
    const url = await getUrl(payload, view)
    const iframe = document.createElement('iframe')
    iframe.style.display = 'none'
    iframe.src = url
    document.body.appendChild(iframe)
    iframe.contentWindow.print()
}

export default Printer