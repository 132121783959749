/* eslint-disable react/prop-types */
import React, { Fragment } from "react";
import { Document, Page, Text, Image, View, StyleSheet, Font } from "@react-pdf/renderer";

import logo from "assets/img/logoApp.png";

import RobotoMonoRegular from "assets/fonts/RobotoMono/RobotoMono-Regular.ttf";
import RobotMonoBold from "assets/fonts/RobotoMono/RobotoMono-Bold.ttf";
import RobotoMonoBoldItalic from "assets/fonts/RobotoMono/RobotoMono-BoldItalic.ttf";
import RobotoMonoItalic from "assets/fonts/RobotoMono/RobotoMono-Italic.ttf";

import CertificateStyle from "helper/styles/certificateStyles";
import moment from "moment";

import Config from "config";
// Font
Font.register({
  family: "RobotoMono",
  format: "truetype",
  fonts: [
    { src: RobotoMonoRegular },
    { src: RobotoMonoBoldItalic },
    { src: RobotoMonoItalic },
    { src: RobotMonoBold },
  ],
});
// Create styles
const styles = StyleSheet.create(CertificateStyle);

class PdfRekomendation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const data = this.props.data;
  }

  render() {
    const data = this.props.data;
    const items = data ? data.jamaah : [];

    return (
      <Document>
        <Page size="Folio" style={styles.page}>
          {/* HAADER */}
          <View style={styles.headerContainer}>
            <Image style={styles.headerLogo} src={data.merchant.logo ? `${Config.ApiAsset}/merchant/${data.merchant.logo}` : logo} cache />
          </View>

          <View style={styles.contentContainer}>
            <View style={styles.letterContainer}>
              {/* LEFT */}
              <View style={styles.letterLeft}>
                <View style={styles.headerLetterContent}>
                  <Text style={styles.headerContent}>
                    Nomor &nbsp; &nbsp; &nbsp;: {data ? data.nomor : ""}
                  </Text>
                  <Text style={styles.headerContent}>
                    Tanggal &nbsp; &nbsp;: {data ? moment(data.date).utc().add(7, 'hours').format("DD-MMM-YYYY") : ""}
                  </Text>
                  <Text style={styles.headerContent}>
                    Hal &nbsp; &nbsp; &nbsp; &nbsp;: {data ? data.subject : ""}
                  </Text>
                </View>
                <View style={styles.headerLetterContent}>
                  <Text style={styles.headerContent}>Kepada Yth.</Text>
                  <Text style={styles.headerContent}>{data ? data.direction : ""}</Text>
                  <Text style={styles.headerContent}>di Tempat</Text>
                </View>
                <View style={styles.headerLetterContent}>
                  <Text style={styles.regards}>Assalamu’alaikum Wr. Wb.</Text>
                  <Text style={styles.headerContent}>Saya yang bertanda tangan dibawah ini :</Text>
                  <View style={styles.subContent}>
                    <Text style={styles.headerContent}>
                      Nama &nbsp; &nbsp; &nbsp; : {data.name}
                    </Text>
                    <Text style={styles.headerContent}>Jabatan &nbsp; &nbsp;: {data.position}</Text>
                  </View>
                </View>
                <View style={styles.headerLetterContent}>
                  <Text style={styles.headerContent}>
                    Bersama ini saya mengajukan Permohonan Surat Keterangan untuk jamaah kami
                    dengan data sebagai berikut :
                  </Text>
                </View>
              </View>
              {/* RIGHT */}
            </View>

            {/* TABLE CONTENT */}
            <View style={styles.tableContainer}>
              <View style={styles.tableHeadContainer}>
                <Text style={styles.tableHeadNo}>NO</Text>
                <Text style={styles.tableHeadName}>NAMA</Text>
                <Text style={styles.tableHeadBirth}>TEMPAT/TGL LAHIR</Text>
                <Text style={styles.tableHeadAddress}>ALAMAT</Text>
              </View>
              <Fragment>
                {items.map((item, key) => (
                  <View style={styles.tableRow} key={key}>
                    <Text style={styles.tableRowNo}>{key + 1}</Text>
                    <Text style={styles.tableRowName}>{item.name}</Text>
                    <Text style={styles.tableRowBirth}>
                      {item.placeBirth + ", " + moment(item.dateBirth).utc().add(7, 'hours').format("YYYY-MM-DD")}
                    </Text>
                    <Text style={styles.tableRowAddress}>{item.address}</Text>
                    {/* <Text style={styles.tableRowAmount}>{item.amount}</Text> */}
                  </View>
                ))}
              </Fragment>
            </View>

            <View style={styles.letterContainer}>
              <View style={styles.letterLeft}>
                <View style={styles.footerLetterContent}>
                  <Text style={styles.footerContent}>
                    Benar yang bersangkutan telah mendaftar dan berniat melaksanakan ibadah haji / umroh
                    melalui
                    <View style={styles.companyName}> {data.merchant.name} </View>
                    (ijin PPIU Kemenag No. 19 Tahun 2017) dan akan berangkat pada tanggal {moment(data.departureDate).utc().add(7, 'hours').format("DD-MM-YYYY")}
                  </Text>
                </View>

                <View style={styles.letterContainer}>
                  <View style={styles.footerLetterContent}>
                    <Text style={styles.footerContent}>
                      Demikian Surat Keterangan ini kami sampaikan, Besar harapan kami
                      Bapak/Ibu dapat memenuhi permohonan ini, atas bantuan dan kerjasamanya kami
                      ucapkan terima kasih.
                    </Text>
                    <Text style={styles.regards}>Wassalamu’alaikum Wr. Wb.</Text>
                  </View>
                </View>
              </View>
            </View>

            {/* FOOTER */}
            <View style={styles.footerRow}>
              <Text style={styles.footValue}>
                {data.merchant.name}
              </Text>
              <Text style={{ ...styles.footValue, marginTop:'60px' }}>{data.name}</Text>
              <Text style={styles.footValue}>{data.position}</Text>
            </View>
          </View>
        </Page>
      </Document>
    );
  }
}

export default PdfRekomendation;
