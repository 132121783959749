/* eslint-disable react/prop-types */
import React, { Fragment } from "react"
import { Document, Page, Text, Image, View, StyleSheet, Font } from "@react-pdf/renderer"

import logo from "assets/img/logoApp.png"

import RobotoMonoRegular from "assets/fonts/RobotoMono/RobotoMono-Regular.ttf"
import RobotMonoBold from "assets/fonts/RobotoMono/RobotoMono-Bold.ttf"
import RobotoMonoBoldItalic from "assets/fonts/RobotoMono/RobotoMono-BoldItalic.ttf"
import RobotoMonoItalic from "assets/fonts/RobotoMono/RobotoMono-Italic.ttf"

import InvoiceStyle from "helper/styles/dpStyle"
import Config from "config";

// Font
Font.register({
	family: 'RobotoMono',
	format: 'truetype',
	fonts: [
		{ src: RobotoMonoRegular },
		{ src: RobotoMonoBoldItalic },
		{ src: RobotoMonoItalic },
		{ src: RobotMonoBold },
	]
});
// Create styles
const styles = StyleSheet.create(InvoiceStyle)

class PdfInvoices extends React.Component {

	constructor(props) {
		super(props)
		this.state = {}
	}

	componentDidMount() {
		const data = this.props.data
	}

	render() {
		const data = this.props.data
		const item = data ? data.item : {}
		const banks = data ? data.banks : []
		const customer = data && data.customer ? data.customer : null

		return (
			<Document>
				<Page size="A4" style={styles.page}>
					{/* HAADER */}
					<View style={styles.headerContainer}>
						{/* Header Left */}
						<View style={styles.headerLeft}>
							<Image style={styles.headerLogo} src={data.logo ? `${Config.ApiAsset}/merchant/${data.logo}` : logo} cache />
							<View style={styles.headerLeftDesc}>
								<Text style={styles.company}>{data.company}</Text>
								<Text style={{ ...styles.headerCaption }}>{data.address}</Text>
								<Text style={styles.headerCaption}>Email: {data.email}</Text>
								<Text style={styles.headerCaption}>Telpon: {data.phone}</Text>
							</View>
						</View>
						{/* Hader Right */}
						<View style={styles.headerRight}>
							<Text style={styles.headerRightTitle}>UANG MUKA</Text>
							<View style={styles.headerRightRow}>
								<Text style={{ ...styles.headerRightItem, textAlign: "left", width: "20%" }}>INVOICE</Text>
								<Text style={{ ...styles.headerRightItem, textAlign: "right", width: "30%" }}>{data.invoiceCode}</Text>
							</View>
							<View style={styles.headerRightRow}>
								<Text style={{ ...styles.headerRightItem, textAlign: "left", width: "20%" }}>TANGGAL</Text>
								<Text style={{ ...styles.headerRightItem, textAlign: "right", width: "30%" }}>{data.invoiceDate}</Text>
							</View>
						</View>
					</View>

					<View style={styles.contentContainer}>
						{/* INVOICE DETAIL */}
						<View style={styles.invoiceContainer}>
							{/* LEFT */}
							<View style={styles.invoiceLeft}>
								<Text style={styles.invoiceDetail}>Tagihan Kepada</Text>
								<Text style={styles.invoiceCaption}>{customer ? customer.name : ""}</Text>
								<Text style={styles.invoiceCaption}>{customer ? customer.address : ""}</Text>
								<Text style={styles.invoiceCaption}>{customer ? customer.email : ""}</Text>
								<Text style={styles.invoiceCaption}>{customer ? customer.phone : ""}</Text>
							</View>
							{/* RIGHT */}

						</View>

						{/* TABLE CONTENT */}
						<View style={styles.tableContainer}>
							<View style={styles.tableHeadContainer}>
								<Text style={styles.tableHeadDesc}>Item Description</Text>
								<Text style={styles.tableHeadAmount}>Tagihan DP</Text>
							</View>
							<Fragment>
								<View style={styles.tableRow}>
									<Text style={styles.tableRowDesc}>{item.name}</Text>
									<Text style={styles.tableRowAmount}>{item.downPayment}</Text>
								</View>
							</Fragment>
						</View>
						{/* FOOTER */}
						<Fragment>
							<View style={styles.tableFooterRow}>
								<Text style={styles.tableFootCaption}>Total</Text>
								<Text style={{ ...styles.tableFootValue, fontSize: 12 }}>{item.downPayment}</Text>
							</View>
						</Fragment>

						{/* NOTE */}
						<View style={styles.noteContainer}>
							<Text style={styles.noteCaption}>
								Untuk Pembayaran bisa melalui bank berikut :
							</Text>

							{banks.map((item, key) => (
								<Text style={styles.noteItem} key={key}>
									- BANK {item.name}, NO.REK : {item.accountNumber}, {item.accountName}
								</Text>
							))}
						</View>
					</View>
				</Page>
			</Document>
		)
	}
}

export default PdfInvoices