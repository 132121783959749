/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react"
// @mui material components
import Grid from "@mui/material/Grid"
import AppBar from "@mui/material/AppBar"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox"

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout"
import DashboardNavbar from "examples/Navbars/DashboardNavbar"
import MiniFormCard from "contents/Components/FormCard/MiniFormCard"

import ModalNotif from "contents/Components/ModalNotif"
import withRouter from "contents/Components/withRouter"

import secureStorage from "libs/secureStorage"

import FormAgent from "contents/Manage/Agent/components/Form"
import FormMerchant from "contents/Manage/Merchant/components/Form"

function LayoutRegister() {
	const [tabsOrientation, setTabsOrientation] = useState("horizontal")
	const [activeTab, setActiveTab] = useState(0)
	const [user, setUser] = useState(null)

	useEffect(() => {
		setUser(secureStorage.getItem('user'))
	}, [])

	const handleSetTabValue = (event, newValue) => setActiveTab(newValue)

	return (
		<>
			<MDBox my={3} >
				<Grid container spacing={3}>
					<Grid item xs={12} sm={12} lg={12}>
						<AppBar position="static">
							<Tabs orientation={tabsOrientation} value={activeTab} onChange={handleSetTabValue}>
								<Tab label="Biro Travel" />
								<Tab label="Agen" />
							</Tabs>
						</AppBar>
					</Grid>
				</Grid>
			</MDBox>

			{activeTab === 0 ? <FormMerchant /> : <FormAgent />}
		</>
	)
}

export default LayoutRegister