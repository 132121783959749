import { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

import useAxios from "libs/useAxios";
import Config from "config"
import secureStorage from "libs/secureStorage";

import Confirm from "contents/Components/Confirm";
import ModalNotif from "contents/Components/ModalNotif"

function ButtonAction({ id, refreshData }) {
	const confirmRef = useRef()
	const modalNotifRef = useRef()

	const [menu, setMenu] = useState(null);
	const [user, setUser] = useState(null)

	useEffect(() => {
		setUser(secureStorage.getItem('user'))
	}, [])

	const openMenu = (event) => setMenu(event.currentTarget);
	const closeMenu = () => setMenu(null);

	const markMessage = () => {
		closeMenu()
		useAxios()
			.post(`${Config.ApiUrl}/notif/read`, { id })
			.then(response => {
				refreshData()
			})
			.catch(err => {
				if (err.response) {
					modalNotifRef.current.setShow({
						modalTitle: "Gagal",
						modalMessage: err.response ? err.response.data : "Server Internal Error",
					});
				} else {
					modalNotifRef.current.setShow({
						modalTitle: "Gagal",
						modalMessage: "Koneksi jaringan terputus",
					});
				}
			})
	}

	const renderMenu = (
		<Menu
			anchorEl={menu}
			anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
			transformOrigin={{ vertical: "top", horizontal: "left" }}
			open={Boolean(menu)}
			onClose={closeMenu}
			keepMounted
		>
			{/* <MenuItem onClick={showMessage}>Tampilkan</MenuItem> */}
			<MenuItem onClick={markMessage}>Tandai sudah dibaca</MenuItem>
		</Menu>
	);

	return (
		<MDBox display="flex">
			<Confirm ref={confirmRef} />
			<ModalNotif ref={modalNotifRef} />

			<MDButton
				variant="contained"
				color="info"
				size="small"
				onClick={openMenu}
			>
				actions&nbsp;
				<Icon>keyboard_arrow_down</Icon>
			</MDButton>
			{renderMenu}
		</MDBox>
	)
}

ButtonAction.propTypes = {
	id: PropTypes.number.isRequired,
	refreshData: PropTypes.func.isRequired
}

export default ButtonAction
