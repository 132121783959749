/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from "prop-types";
import { Link, Navigate } from 'react-router-dom';
// @mui material components
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from 'components/MDInput';
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import ModalNotif from 'contents/Components/ModalNotif';
import withRouter from 'contents/Components/withRouter';
import MiniFormCard from 'contents/Components/FormCard/MiniFormCard';
import ButtonBack from 'contents/Components/ButtonBack';

import Config from 'config'
import moment from 'moment'
import _ from "lodash"
import * as EmailValidator from 'email-validator'

import useAxios from "libs/useAxios"
import secureStorage from 'libs/secureStorage';

class FormUser extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			user: null,
			title: "",
			action: "",
			id: null,

			name: "",
			email: "",
			password: "",
			repassword: "",
			phone: "",
			address: "",
			merchant: null,
			role: null,

			merchantOpen: false,
			roleOpen: false,

			roles: [],
			merchants: [],
			error: [],
			success: [],

			disabledSubmit: false,
			redirect: null
		}

		this.inputRef = React.createRef()
		this.modalNotifRef = React.createRef()
	}

	componentDidMount() {
		this.inputRef && this.inputRef.current.focus()
		const user = secureStorage.getItem('user')
		const roles = user.roleId === 1 ? [{ id: 2, label: "OWNER" }, { id: 3, label: "OPERATOR" }] : []
		this.setState({
			roles,
			user: user ? user : null
		}, () => {
			this.loadPath()
			user && user.roleId === 1 && this.loadMerchants()
		})
	}

	loadPath() {
		const pathname = window.location.pathname
		const index = pathname.indexOf('edit')
		if (index === -1) {
			this.setState({
				title: "Tambah User Baru",
				action: "add"
			})
		}
		else {
			const id = this.props.params?.id
			this.loadDetail(id)
			this.setState({
				title: "Ubah Data User",
				action: "edit",
				id
			})
		}
	}

	loadDetail = (id) => {
		const user = secureStorage.getItem('user')
		useAxios()
			.post(`${Config.ApiUrl}/user/detail`, { id })
			.then(response => {
				const data = response.data
				console.log(data)

				const isValidRole = user.roleId === 1 ? {
					role: data.Role ? true : false
				} : {}
				const isValidMerchant = user.roleId === 1 ? {
					merchant: data.Merchant ? true : false
				} : {}

				this.setState({
					name: data.name ?? "",
					email: data.email ? data.email : "",
					phone: data.phone ? data.phone : "",
					address: data.address ?? "",
					role: data.Role ? {
						id: data.Role?.id,
						label: data.Role?.name,
					} : null,
					merchant: data.Merchant ? {
						id: data.Merchant?.id,
						label: data.Merchant?.name,
					} : null,
					success: {
						...this.state.success,
						name: data.name ? true : false,
						email: data.email ? true : false,
						phone: data.phone ? true : false,
						address: data.address ? true : false,
						password: true,
						repassword: true,
						...isValidRole,
						...isValidMerchant
					}
				})
			})
			.catch(err => {
				console.log(err)
				this.modalNotifRef.current.setShow({
					modalTitle: 'Gagal',
					modalMessage: err.response ? err.response.data : "Koneksi jaringan terputus",
					onClose: () => {
						this.setState({ redirect: `/manage/user` })
					}
				})
			})
	}

	loadMerchants = () => {
		useAxios()
			.post(`${Config.ApiUrl}/manage/merchant/dropdown`)
			.then(response => {
				const data = response.data

				this.setState({
					merchants: data.map(item => ({ id: item.id, label: item.name }))
				})
			})
			.catch(err => {
				console.log(err)
			})
	}

	handleChange = (e) => {
		const { id, value } = e.target;
		this.setState({
			[id]: value
		})
	}

	handleBlur = (e) => {
		const user = this.state.user
		const { id, value } = e.target
		// eslint-disable-next-line no-empty
		if (value) {
			if (id === "email" && !EmailValidator.validate(value)) {
				this.setState({
					success: { ...this.state.success, [e.target.id]: false },
					error: { ...this.state.error, [e.target.id]: true }
				})
			}
			else {
				this.setState({
					success: { ...this.state.success, [e.target.id]: true },
					error: { ...this.state.error, [e.target.id]: false }
				})
			}
		}
		else {
			this.setState({
				success: { ...this.state.success, [e.target.id]: false },
				error: { ...this.state.error, [e.target.id]: true }
			})
		}
	}

	handleSumit = () => {
		const user = this.state.user
		let success = this.state.success

		if (this.state.action === "add" && this.state.password !== this.state.repassword) {
			success.repassword = false
		}

		const validMerchant = [2, 3, 4].includes(user.roleId) ? { merchant: true } : {}
		const validRole = [2, 3, 4].includes(user.roleId) ? { role: true } : {}

		success = {
			...success,
			...validRole,
			...validMerchant
		}
		console.log({ success })
		this.setState({
			success: { ...success }
		})

		if (
			success.name &&
			success.email &&
			success.phone &&
			success.address &&
			success.role &&
			success.merchant &&
			success.password &&
			success.repassword
		) {
			this.sendData()
		}
		else {
			this.modalNotifRef.current.setShow({
				modalTitle: "Gagal",
				modalMessage: "Data form masih belum lengkap, Silahkan di cek kembali !",
			})
		}
	}

	sendData = () => {
		console.log('[SEND DATA]')
		this.setState({
			disabledSubmit: true
		})

		const user = this.state.user
		const payload = {
			name: this.state.name,
			password: this.state.password,
			email: this.state.email,
			phone: this.state.phone,
			address: this.state.address,
			roleId: user.roleId === 2 ? 3 : this.state.role?.id,
			merchantId: user.roleId === 1 ? this.state.merchant?.id : user?.Merchant?.id,
			id: this.state.id
		}

		const url = this.state.action === "add" ? "/user/create" : "/user/update"
		useAxios()
			.post(`${Config.ApiUrl}${url}`, payload)
			.then(response => {
				this.modalNotifRef.current.setShow({
					modalTitle: "Sukses",
					modalMessage: response.data,
					onClose: () => {
						this.setState({
							redirect: `/manage/user`,
							disabledSubmit: false
						})
					}
				})
			})
			.catch(err => {
				this.setState({ disabledSubmit: false })
				if (err.response) {
					this.modalNotifRef.current.setShow({
						modalTitle: "Gagal",
						modalMessage: err.response.data ? err.response.data : "Terjadi kesalahan pada system",
					})
				}
				// eslint-disable-next-line no-empty
				else {
					this.modalNotifRef.current.setShow({
						modalTitle: 'Gagal',
						modalMessage: "Koneksi jaringan terputus",
					})
				}
			})
	}

	render() {
		const user = secureStorage.getItem('user')

		if (this.state.redirect) {
			return <Navigate to={this.state.redirect} />
		}

		return (
			<MiniFormCard>
				<ModalNotif ref={this.modalNotifRef} />
				<MDBox p={3} lineHeight={1} textAlign='center'>
					<MDTypography variant="h5" fontWeight="medium">
						{this.state.title}
					</MDTypography>
				</MDBox>

				<MDBox p={3}>
					<MDBox component="form" role="form">
						<Grid container spacing={3}>
							<Grid item xs={12} lg={12} md={12}>
								{/* Name */}
								<MDBox mb={2}>
									<MDInput
										id="name"
										type="text"
										label="Nama"
										inputRef={this.inputRef}
										value={this.state.name}
										onChange={this.handleChange}
										onBlur={this.handleBlur}
										success={this.state.success ? this.state.success.name : false}
										error={this.state.error ? this.state.error.name : false}
										fullWidth
									/>
								</MDBox>
								{/* Email */}
								<MDBox mb={2}>
									<MDInput
										id="email"
										type="text"
										label="Email"
										value={this.state.email}
										onChange={this.handleChange}
										onBlur={this.handleBlur}
										success={this.state.success ? this.state.success.email : false}
										error={this.state.error ? this.state.error.email : false}
										fullWidth
									/>
								</MDBox>
								{/* Phone */}
								<MDBox mb={2}>
									<MDInput
										id="phone"
										type="text"
										label="Nomor Telpon"
										value={this.state.phone}
										onChange={this.handleChange}
										onBlur={this.handleBlur}
										success={this.state.success ? this.state.success.phone : false}
										error={this.state.error ? this.state.error.phone : false}
										fullWidth
									/>
								</MDBox>
								{/* Address */}
								<MDBox mb={2}>
									<MDInput
										id="address"
										type="text"
										rows={3}
										multiline
										label="Alamat"
										value={this.state.address}
										onChange={this.handleChange}
										onBlur={this.handleBlur}
										success={this.state.success ? this.state.success.address : false}
										error={this.state.error ? this.state.error.address : false}
										fullWidth
									/>
								</MDBox>
								{/* Password & Re-Password */}
								{this.state.action === 'add' && (
									<>
										<MDBox mb={2}>
											<MDInput
												id="password"
												type="password"
												label="Password"
												value={this.state.password}
												onChange={this.handleChange}
												onBlur={this.handleBlur}
												success={this.state.success ? this.state.success.password : false}
												error={this.state.error ? this.state.error.password : false}
												fullWidth
											/>
										</MDBox>
										<MDBox mb={2}>
											<MDInput
												id="repassword"
												type="password"
												label="Konfirmasi Password"
												value={this.state.repassword}
												onChange={this.handleChange}
												onBlur={this.handleBlur}
												success={this.state.success ? this.state.success.repassword : false}
												error={this.state.error ? this.state.error.repassword : false}
												fullWidth
											/>
										</MDBox>
									</>
								)}
								{/* ROLE & MERCHANT */}
								{user && user.roleId === 1 && (
									<>
										<MDBox mb={2}>
											<Autocomplete
												fullWidth
												id="role"
												open={this.state.roleOpen}
												onOpen={() => this.setState({ roleOpen: true })}
												onClose={() => this.setState({ roleOpen: false })}
												value={this.state.role}
												options={this.state.roles}
												onFocus={() => this.setState({ roleOpen: true })}
												onChange={(e, value) => {
													if (value) {
														this.setState({
															role: value,
															error: { ...this.state.error, role: false },
															success: { ...this.state.success, role: true }
														})
													}
												}}
												sx={{
													".MuiAutocomplete-input": {
														padding: "7.5px 5px 7.5px 8px !important"
													},
													".MuiOutlinedInput-root": {
														padding: "4px !important"
													}
												}}
												isOptionEqualToValue={(option, value) => option.id === value.id}
												renderInput={(params) => <MDInput label="Pilih Group" {...params} />}
											/>
										</MDBox>
										<MDBox mb={2}>
											<Autocomplete
												fullWidth
												id="merchant"
												open={this.state.merchantOpen}
												onOpen={() => this.setState({ merchantOpen: true })}
												onClose={() => this.setState({ merchantOpen: false })}
												value={this.state.merchant}
												options={this.state.merchants}
												onFocus={() => this.setState({ merchantOpen: true })}
												onChange={(e, value) => {
													if (value) {
														this.setState({
															merchant: value,
															error: { ...this.state.error, merchant: false },
															success: { ...this.state.success, merchant: true }
														})
													}
												}}
												sx={{
													".MuiAutocomplete-input": {
														padding: "7.5px 5px 7.5px 8px !important"
													},
													".MuiOutlinedInput-root": {
														padding: "4px !important"
													}
												}}
												isOptionEqualToValue={(option, value) => option.id === value.id}
												renderInput={(params) => <MDInput label="Pilih Biro Travel" {...params} />}
											/>
										</MDBox>
									</>
								)}

								<MDBox pt={3} display="flex" justifyContent="space-between">
									<MDBox mr={1}>
										<ButtonBack label={"KEMBALI"} />
									</MDBox>
									<MDButton
										type="button"
										variant="gradient"
										color="info"
										disabled={this.state.disabledSubmit}
										onClick={this.handleSumit}
									>
										SIMPAN
									</MDButton>
								</MDBox>
							</Grid>
						</Grid>
					</MDBox>
				</MDBox>
			</MiniFormCard>
		)
	}
}

export default withRouter(FormUser)