/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon"
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDBadge from "components/MDBadge";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import DataTable from "contents/Components/DataTable";
import Pagination from "contents/Components/Pagination";
import ButtonAction from "contents/Finance/Bill/components/ButtonAction";

import useAxios from "libs/useAxios";
import Config from "config";
import secureStorage from "libs/secureStorage";
import { fnumber } from "libs/currency";
import moment, { fn } from "moment";

class BillMember extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			// title: props.title,
			status: null,
			product: null,

			statuses: [],
			products: [],

			rowsPerPage: 10,
			currentPage: 1,
			totalPages: 0,
			totalData: 0,
			tableHead: [
				{ Header: "action", accessor: "action", width: "10%" },
				{ Header: "kode", accessor: "kode", width: "10%" },
				// { Header: "nominal", accessor: "amount", width: "20%" },
				{ Header: "tanggal", accessor: "createdAt", width: "20%" },
				{ Header: "pelanggan", accessor: "customer", width: "20%" },
				{ Header: "paket", accessor: "product", width: "20%" },
				{ Header: "harga paket", accessor: "amount", width: "20%" },
				{ Header: "uang muka", accessor: "downPayment", width: "20%" },
				{ Header: "dibayar", accessor: "paidAmount", width: "20%" },
				{ Header: "tagihan", accessor: "bill", width: "20%" },
				{ Header: "status", accessor: "status", width: "10%" },
			],
			rows: [],
			keyword: "",
			isLoading: true,
		};
	}

	componentDidMount() {
		const user = secureStorage.getItem("user");

		this.loadStatus()
		user && [2, 3, 4].includes(user.roleId) && this.loadProduct()
		this.loadData()
	}

	loadStatus = () => {
		useAxios()
			.post(`${Config.ApiUrl}/finance/bill/status`)
			.then((response) => {
				const data = response.data;

				this.setState({
					statuses: data.map((item) => ({ id: item.id, label: item.name })),
				});
			})
			.catch((err) => {
				console.log(err);
			});
	}

	loadProduct = (params) => {
		const merchantId = params && params.merchantId ? { merchantId: params.merchantId } : {};
		const payload = {
			...merchantId,
		};

		useAxios()
			.post(`${Config.ApiUrl}/product/dropdown`, payload)
			.then((response) => {
				const data = response.data;
				this.setState({
					products: data.map((item) => ({ id: item.id, label: item.name })),
				});
			})
			.catch((err) => {
				console.log(err);
			});
	}

	loadData = (params) => {
		const user = secureStorage.getItem("user")
		this.setState({ isLoading: true })

		const statusId = params && params.statusId ? { statusId: params.statusId } : {}
		const productId = params && params.productId ? { productId: params.productId } : {}

		const payload = {
			keyword: params && params.keyword ? params.keyword : this.state.keyword,
			currentPage: params && params.currentPage ? params.currentPage : 1,
			rowsPerPage: params && params.rowsPerPage ? params.rowsPerPage : this.state.rowsPerPage,
			...statusId,
			...productId
		};

		useAxios()
			.post(`${Config.ApiUrl}/finance/bill`, payload)
			.then((response) => {
				const data = response.data;

				const output = data.output.map((item, key) => {
					const sale = item.TrSale
					const customer = sale.Customer ? sale.Customer : null
					const member = customer && customer.Member ? customer.Member : null
					const product = sale.Product ? sale.Product : null
					const productPrice = sale.ProductPrice ? sale.ProductPrice : null
					const billStatus = item.BillStatus ? item.BillStatus : null

					return {
						kode: (
							<MDTypography
								variant="caption"
								fontWeight="medium"
								sx={{
									cursor: "pointer",
								}}
								color="info"
								component={Link}
								to={{ pathname: `/finance/bill/detail/${item.id}` }}
							>
								{item.kode}
							</MDTypography>
						),
						createdAt: item.createdAt,
						customer: (
							<MDBox style={{ wordWrap: "break-word", width: "13em" }}>
								<MDBox display="flex" flexDirection="column">
									<MDTypography variant="caption" fontWeight="regular">
										Kode: {member && member.kode ? member.kode : "-"}
									</MDTypography>
									<MDTypography variant="caption" fontWeight="regular">
										Nama: {member && member.name ? member.name : "-"}
									</MDTypography>
								</MDBox>
							</MDBox>
						),
						product: (
							<MDBox style={{ wordWrap: "break-word", width: "18em" }}>
								<MDBox display="flex" flexDirection="column">
									<MDTypography variant="caption" fontWeight="regular">
										Kode: {product && product.kode ? product.kode : "-"}
									</MDTypography>
									<MDTypography variant="caption" fontWeight="regular">
										Nama: {product && product.name ? product.name : "-"}
									</MDTypography>
								</MDBox>
							</MDBox>
						),
						amount: item.amount ? fnumber(item.amount) : "-",
						downPayment: productPrice && productPrice.downPayment ? fnumber(productPrice.downPayment) : 0,
						paidAmount: fnumber(item.paidAmount),
						bill: fnumber(item.amount - item.paidAmount),
						status: billStatus ? (
							<MDBadge
								variant="contained"
								badgeContent={billStatus.name}
								size="lg"
								color={
									billStatus.id === 1
										? "secondary"
										: billStatus.id === 2
											? "error"
											: billStatus.id === 3
												? "warning"
												: billStatus.id === 4
													? "info"
													: "success"
								}
							/>
						) : (
							"-"
						),
						action: (
							<ButtonAction
								id={item.id}
								statusId={billStatus?.id}
								refreshData={this.loadData}
							/>
						),
					};
				});

				this.setState({
					totalPages: data.totalPages,
					totalData: data.totalData,
					rows: output,
					isLoading: false,
				})
			})
			.catch((err) => {
				console.log(err)
				this.setState({ isLoading: false })
			});
	};

	render() {
		const user = secureStorage.getItem("user")

		return (
			<MDBox pb={3} my={3}>
				<Card>
					<MDBox p={2} lineHeight={1}>
						<MDTypography variant="h5" fontWeight="medium">
							Daftar Tagihan Jamaah
						</MDTypography>
					</MDBox>

					<MDBox px={2} width="100%" display="flex" justifyContent="flex-start">
						<Grid container spacing={3}>
							<Grid item xs={12} md={3} lg={3}>
								<MDInput
									label="Search..."
									size="small"
									fullWidth
									value={this.state.keyword}
									onKeyPress={(e) => {
										if (e.key === "Enter") {
											this.loadData({
												keyword: e.target.value,
												currentPage: 1,
												productId: this.state.product ? this.state.product.id : null,
												merchantId: this.state.merchant ? this.state.merchant.id : null,
											});
										}
									}}
									onChange={(e) => this.setState({ keyword: e.target.value })}
								/>
							</Grid>
							{/* Product */}
							{[2, 3, 4].includes(user.roleId) && (
								<Grid item xs={12} md={3} lg={3}>
									<Autocomplete
										value={this.state.product}
										options={this.state.products}
										onChange={(e, value) => {
											this.setState({ product: value });
											this.loadData({
												currentPage: 1,
												productId: value ? value.id : null,
												statusId: this.state.status ? this.state.status.id : null
											});
										}}
										sx={{
											".MuiAutocomplete-input": {
												padding: "7.5px 5px 7.5px 8px !important",
											},
											".MuiOutlinedInput-root": {
												padding: "1.5px !important",
											},
										}}
										isOptionEqualToValue={(option, value) => option.id === value.id}
										renderInput={(params) => (
											<MDInput
												sx={{ padding: "0px" }}
												fullWidth
												label="Pilih Paket"
												{...params}
											/>
										)}
									/>
								</Grid>
							)}
							{/* Bill Status */}
							<Grid item xs={12} md={3} lg={3}>
								<Autocomplete
									value={this.state.status}
									options={this.state.statuses}
									onChange={(e, value) => {
										this.setState({ status: value });
										this.loadData({
											currentPage: 1,
											statusId: value ? value.id : null,
											productId: this.state.product ? this.state.product.id : null
										});
									}}
									sx={{
										".MuiAutocomplete-input": {
											padding: "7.5px 5px 7.5px 8px !important",
										},
										".MuiOutlinedInput-root": {
											padding: "1.5px !important",
										},
									}}
									isOptionEqualToValue={(option, value) => option.id === value.id}
									renderInput={(params) => (
										<MDInput
											sx={{ padding: "0px" }}
											fullWidth
											label="Pilih Status"
											{...params}
										/>
									)}
								/>
							</Grid>
							{/* Button Search */}
							<Grid item xs={12} md={3} lg={3}>
								<MDBox
									width="100%"
									display="flex"
									flexDirection="row"
									justifyContent="flex-start"
								>
									<MDButton
										color="info"
										variant="gradient"
										onClick={() => {
											const startDate = this.state.startDate ? this.state.startDate : ""
											const endDate = this.state.endDate ? this.state.endDate : ""
											this.loadData({
												currentPage: 1,
												productId: this.state.product ? this.state.product.id : null,
												statusId: this.state.status ? this.state.status.id : null
											})
										}}
										iconOnly
									>
										<Icon>search</Icon>
									</MDButton>
									<MDBox ml={1}>
										<MDButton
											color="error"
											variant="gradient"
											onClick={() => {
												this.setState({
													currentPage: 1,
													statusId: null,
													productId: null
												}, () => {
													this.loadData()
												})
											}}
											iconOnly
										>
											<Icon>refresh</Icon>
										</MDButton>
									</MDBox>
								</MDBox>
							</Grid>
						</Grid>
					</MDBox>

					<MDBox p={2}>
						<DataTable
							isLoading={this.state.isLoading}
							tableHead={this.state.tableHead}
							tableData={this.state.rows}
						/>
						<Pagination
							totalPages={this.state.totalPages}
							totalData={this.state.totalData}
							currentPage={this.state.currentPage}
							rowsPerPage={[10, 25, 50, "All"]}
							totalButton={3}
							defaultRowsPerPage={this.state.rowsPerPage}
							onChangeRowsPerPage={(value) => {
								this.setState({ rowsPerPage: value, currentPage: 1 });
								this.loadData({
									rowsPerPage: value,
									currentPage: 1,
									statusId: this.state.status ? this.state.status.id : null,
									merchantId: this.state.merchant ? this.state.merchant.id : null,
								});
							}}
							onChangePage={(currentPage) => {
								if (currentPage !== this.state.currentPage) {
									this.setState({ currentPage });
									this.loadData({
										currentPage,
										statusId: this.state.status ? this.state.status.id : null,
										merchantId: this.state.merchant ? this.state.merchant.id : null,
									});
								}
							}}
						/>
					</MDBox>
				</Card>
			</MDBox>
		);
	}
}

export default BillMember
