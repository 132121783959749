import React from "react"
import PropTypes from "prop-types"
// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

import Copy from "@mui/icons-material/FileCopy"
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener'

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDDatePicker from "components/MDDatePicker";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import ModalNotif from 'contents/Components/ModalNotif';
import MiniFormCard from "contents/Components/FormCard/MiniFormCard";

import Config from 'config'
import moment from 'moment'
import _ from "lodash"
import * as EmailValidator from 'email-validator'

import useAxios from "libs/useAxios"
import secureStorage from "libs/secureStorage";

class BasicInfo extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			userId: null,

			name: "",
			email: "",
			phone: "",
			address: "",

			filename: "",
			file: null,

			error: [],
			success: [],

			disabledSubmit: false
		}

		this.input = React.createRef()
		this.fileRef = React.createRef()
		this.modalNotifRef = React.createRef()
	}

	componentDidMount() {
		const user = secureStorage.getItem('user')
		this.setState({ userId: user.id }, () => {
			this.loadData(user.id)
		})
	}

	loadData = (id) => {
		useAxios()
			.post(`${Config.ApiUrl}/user/detail`, { id })
			.then(response => {
				const data = response.data

				this.setData(data)
			})
			.catch(err => {
				console.log(err)
				this.setState({ isLoading: false })
			})
	}

	setData = (user) => {
		const merchant = user.Merchant ? user.Merchant : ""

		this.setState({
			name: user && user.name ? user.name : "",
			email: user && user.email ? user.email : "",
			phone: user && user.phone ? user.phone : "",
			address: user && user.address ? user.address : "",

			success: {
				...this.state.success,
				name: true,
				email: user.email ? true : false,
				phone: user.phone ? true : false,
				address: user.address ? true : false,
			}
		})
	}

	handleChange = (e) => {
		const { id, value } = e.target;
		this.setState({
			[id]: value
		})
	}

	handleBlur = (e) => {
		const user = this.state.user
		const { id, value } = e.target
		// eslint-disable-next-line no-empty
		if (value) {
			if (id === "email" && !EmailValidator.validate(value)) {
				this.setState({
					success: { ...this.state.success, [e.target.id]: false },
					error: { ...this.state.error, [e.target.id]: true }
				})
			}
			else {
				this.setState({
					success: { ...this.state.success, [e.target.id]: true },
					error: { ...this.state.error, [e.target.id]: false }
				})
			}
		}
		else {
			this.setState({
				success: { ...this.state.success, [e.target.id]: false },
				error: { ...this.state.error, [e.target.id]: true }
			})
		}
	}

	handleSubmit = () => {
		const success = this.state.success

		if (
			success.name &&
			success.phone &&
			success.email
			// success.address
		) {
			this.sendData()
		}
		else {
			this.modalNotifRef.current.setShow({
				modalTitle: "Gagal",
				modalMessage: "Data form masih belum lengkap, Silahkan di cek kembali !",
			})
		}
	}

	sendData = () => {
		this.setState({ disabledSubmit: true })
		// const formData = new FormData();

		// formData.append("name", this.state.name)
		// formData.append("phone", this.state.phone)
		// formData.append("email", this.state.email)
		// formData.append("id", this.state.userId)
		const payload = {
			name: this.state.name,
			phone: this.state.phone,
			email: this.state.email,
			id: this.state.userId
		}

		useAxios()
			.post(`${Config.ApiUrl}/user/update`, payload)
			.then(response => {
				this.setState({ disabledSubmit: false })
				this.modalNotifRef.current.setShow({
					modalTitle: "Sukses",
					modalMessage: response.data,
				})
			})
			.catch(err => {
				this.setState({ disabledSubmit: false })
				if (err.response) {
					this.modalNotifRef.current.setShow({
						modalTitle: "Gagal",
						modalMessage: err.response.data,
					})
				}
				// eslint-disable-next-line no-empty
				else {
					this.modalNotifRef.current.setShow({
						modalTitle: 'Gagal',
						modalMessage: "Koneksi jaringan terputus",
					})
				}
			})
	}

	render() {
		const user = secureStorage.getItem('user')

		return (
			<>
				<ModalNotif ref={this.modalNotifRef} />
				<MiniFormCard>
					<MDBox p={3}>
						<MDTypography variant="h5">Basic Info</MDTypography>
					</MDBox>

					<MDBox component="form" pb={3} px={3}>
						<Grid container spacing={3}>
							<Grid item xs={12} md={12} lg={12}>
								{/* Name */}
								<MDBox mb={2}>
									<MDInput
										id="name"
										type="text"
										label="Nama"
										value={this.state.name}
										onChange={this.handleChange}
										onBlur={this.handleBlur}
										success={this.state.success ? this.state.success.name : false}
										error={this.state.error ? this.state.error.name : false}
										fullWidth
									/>
								</MDBox>
								{/* Email */}
								<MDBox mb={2}>
									<MDInput
										id="email"
										type="text"
										label="Email"
										value={this.state.email}
										onChange={this.handleChange}
										onBlur={this.handleBlur}
										success={this.state.success ? this.state.success.email : false}
										error={this.state.error ? this.state.error.email : false}
										fullWidth
									/>
								</MDBox>
								{/* Phone */}
								<MDBox mb={2}>
									<MDInput
										id="phone"
										type="text"
										label="Nomor Telpon"
										value={this.state.phone}
										onChange={this.handleChange}
										onBlur={this.handleBlur}
										success={this.state.success ? this.state.success.phone : false}
										error={this.state.error ? this.state.error.phone : false}
										fullWidth
									/>
								</MDBox>
							</Grid>
						</Grid>

						<MDBox width="100%" display="flex" justifyContent="flex-end" flexWrap="wrap">
							<MDButton
								variant="gradient"
								color="dark"
								size="small"
								disabled={this.state.disabledSubmit}
								onClick={this.handleSubmit}
							>
								UPDATE
							</MDButton>
						</MDBox>
					</MDBox>
				</MiniFormCard>
			</>
		)
	}
}

export default BasicInfo