/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from "prop-types";
import { Link, Navigate } from 'react-router-dom';
// @mui material components
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from 'components/MDInput';
import MDTypography from "components/MDTypography";
import MDDatePicker from 'components/MDDatePicker';

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout"
import DashboardNavbar from "examples/Navbars/DashboardNavbar"

import ModalNotif from 'contents/Components/ModalNotif'
import withRouter from 'contents/Components/withRouter'
import ButtonBack from 'contents/Components/ButtonBack'
import MiniFormCard from 'contents/Components/FormCard/MiniFormCard';

import Config from 'config'
import moment from 'moment'
import _ from "lodash"
import * as EmailValidator from 'email-validator'

import useAxios from "libs/useAxios"
import secureStorage from 'libs/secureStorage';

class FormAgent extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			title: "",
			action: "",
			id: null,

			prefix: "",
			numberSK: "",
			name: "",
			leader: "",
			email: "",
			phone: "",
			password: "",
			repassword: "",
			address: "",
			datePermit: "",
			province: null,
			district: null,

			provinceOpen: false,
			districtOpen: false,

			provinces: [],
			districts: [],

			error: [],
			success: [],

			isPreffix: false,
			disabledSubmit: false,
			redirect: null
		}

		this.inputRef = React.createRef()
		this.modalNotifRef = React.createRef()
	}

	componentDidMount() {

		this.inputRef && this.inputRef.current.focus()
		this.loadProvince()

		this.loadPath()
	}

	loadPath() {
		const viewId = this.props.viewId
		const pathname = window.location.pathname
		const index = pathname.indexOf('edit')
		if (index === -1) {
			this.setState({
				title: viewId === 1 ? "Tambah Agen Baru" : "Pendaftaran Agen",
				action: "add",
				isPreffix: false,
			})
		}
		else {
			const id = this.props.params?.id
			this.loadDetail(id)
			this.setState({
				id,
				title: "Ubah Agen",
				action: "edit",
				isPreffix: true,
			})
		}
	}

	loadDetail = (id) => {
		useAxios()
			.post(`${Config.ApiUrl}/manage/agent/get`, { id })
			.then(response => {
				const data = response.data

				const province = data && data.Province ? data.Province : null
				const district = data && data.District ? data.District : null
				province && this.loadDistrict(province?.id) // load list district

				this.setState({
					prefix: data.prefix,
					numberSK: data.numberSK ?? "",
					name: data.name ?? "",
					email: data.email ? data.email : "",
					phone: data.phone ? data.phone : "",
					address: data.address ?? "",
					leader: data.leader ?? "",
					datePermit: data && data.datePermit ? data.datePermit : "",
					province: province ? { id: province.id, label: province.name } : null,
					district: district ? { id: district.id, label: district.name } : null,
					success: {
						...this.state.success,
						prefix: data.prefix ? true : false,
						numberSK: data.numberSK ? true : false,
						name: data.name ? true : false,
						leader: data.leader ? true : false,
						email: data.email ? true : false,
						phone: data.phone ? true : false,
						province: province ? true : false,
						district: district ? true : false,
						address: data.address ? true : false,
						datePermit: data && data.datePermit ? true : false,
						password: true,
						repassword: true,
					}
				})
			})
			.catch(err => {
				console.log(err)
				this.modalNotifRef.current.setShow({
					modalTitle: 'Gagal',
					modalMessage: err.response ? err.response.data : "Koneksi jaringan terputus",
					onClose: () => {
						this.setState({ redirect: '/manage/agent' })
					}
				})
			})
	}

	loadProvince = () => {
		useAxios()
			.post(`${Config.ApiUrl}/master/region/province/dropdown`, { countryId: 1 })
			.then(response => {
				const data = response.data
				const provinces = data.map(item => ({
					id: item.id,
					label: item.name
				}))
				this.setState({ provinces })
			})
			.catch(err => {
				console.log(err)
			})
	}

	loadDistrict = (provinceId) => {
		useAxios()
			.post(`${Config.ApiUrl}/master/region/district/dropdown`, { provinceId })
			.then(response => {
				const data = response.data
				const districts = data.map(item => ({ id: item.id, label: item.name }))
				this.setState({ districts })
			})
			.catch(err => {
				console.log(err)
			})
	}

	handleChange = (e) => {
		const { id, value } = e.target;
		this.setState({
			[id]: value
		})
	}

	handleBlur = (e) => {
		const user = this.state.user
		const { id, value } = e.target
		// eslint-disable-next-line no-empty
		if (value) {
			if (id === "email" && !EmailValidator.validate(value)) {
				this.setState({
					success: { ...this.state.success, [e.target.id]: false },
					error: { ...this.state.error, [e.target.id]: true }
				})
			}
			else {
				this.setState({
					success: { ...this.state.success, [e.target.id]: true },
					error: { ...this.state.error, [e.target.id]: false }
				})
			}
		}
		else {
			this.setState({
				success: { ...this.state.success, [e.target.id]: false },
				error: { ...this.state.error, [e.target.id]: true }
			})
		}
	}

	handleSubmit = () => {
		let success = this.state.success

		if (this.state.action === "add" && this.state.password !== this.state.repassword) {
			success.repassword = false
		}

		console.log({ success })
		this.setState({ success: { ...success } })
		if (
			success.prefix &&
			success.numberSK &&
			success.name &&
			success.leader &&
			success.email &&
			success.phone &&
			success.address &&
			success.datePermit &&
			success.province &&
			success.district &&
			success.password &&
			success.repassword
		) {
			this.sendData()
		}
		else {
			this.modalNotifRef.current.setShow({
				modalTitle: "Gagal",
				modalMessage: "Data form masih belum lengkap, Silahkan di cek kembali !",
			})
		}
	}

	sendData = () => {
		const viewId = this.props.viewId
		this.setState({ disabledSubmit: true })

		const payload = {
			id: this.state.id,
			prefix: this.state.prefix,
			numberSK: this.state.numberSK,
			name: this.state.name,
			leader: this.state.leader,
			email: this.state.email,
			phone: this.state.phone,
			address: this.state.address,
			datePermit: this.state.datePermit,
			provinceId: this.state.province?.id,
			districtId: this.state.district?.id,
			password: this.state.password
		}

		const url = this.state.action === "add" ? `/manage/agent/${viewId === 1 ? 'create' : 'register'}` : "/manage/agent/update"
		useAxios()
			.post(`${Config.ApiUrl}${url}`, payload)
			.then(response => {
				this.modalNotifRef.current.setShow({
					modalTitle: "Sukses",
					modalMessage: response.data,
					onClose: () => {
						this.setState({
							redirect: viewId === 1 ? '/manage/agent' : '/login',
							disabledSubmit: false
						})
					}
				})
			})
			.catch(err => {
				console.log(err)
				this.setState({ disabledSubmit: false })
				if (err.response) {
					this.modalNotifRef.current.setShow({
						modalTitle: "Gagal",
						modalMessage: err.response ? err.response.data : "Koneksi jaringan terputus",
					})
				}
				// eslint-disable-next-line no-empty
				else {
					this.modalNotifRef.current.setShow({
						modalTitle: 'Gagal',
						modalMessage: "Koneksi jaringan terputus",
					})
				}
			})
	}

	render() {
		const viewId = this.props.viewId
		if (this.state.redirect) {
			return <Navigate to={this.state.redirect} />
		}

		return (
			<MDBox pb={3} my={3}>
				<ModalNotif ref={this.modalNotifRef} />
				<Card>
					<MDBox
						p={3}
						display="flex"
						lineHeight={1}
						width="100%"
						justifyContent="center"
					>
						<MDTypography variant="h5" fontWeight="medium">
							{this.state.title}
						</MDTypography>
					</MDBox>

					<MDBox p={3}>
						<MDBox component="form" role="form">
							<Grid container spacing={3}>
								<Grid item xs={12} lg={6} md={6}>
									{/* Prefix Code */}
									<MDBox mb={2}>
										<MDInput
											disabled={this.state.isPreffix}
											readOnly={this.state.isPreffix}
											id="prefix"
											type="text"
											label="Prefix Kode"
											value={this.state.prefix}
											onChange={this.handleChange}
											onBlur={this.handleBlur}
											success={this.state.success ? this.state.success.prefix : false}
											error={this.state.error ? this.state.error.prefix : false}
											fullWidth
										/>
										<MDTypography color="error" variant="span" fontWeight="bold" fontSize="12px">
											* digunakan untuk awalan pembuatan kode agen
										</MDTypography>
									</MDBox>
									{/* Nomor SK */}
									<MDBox mb={2}>
										<MDInput
											id="numberSK"
											type="text"
											inputRef={this.inputRef}
											label="Nomor Izin / SK Lembaga"
											value={this.state.numberSK}
											onChange={this.handleChange}
											onBlur={this.handleBlur}
											success={this.state.success ? this.state.success.numberSK : false}
											error={this.state.error ? this.state.error.numberSK : false}
											fullWidth
										/>
									</MDBox>
									{/* Name */}
									<MDBox mb={2}>
										<MDInput
											id="name"
											type="text"
											label="Nama Lembaga"
											value={this.state.name}
											onChange={this.handleChange}
											onBlur={this.handleBlur}
											success={this.state.success ? this.state.success.name : false}
											error={this.state.error ? this.state.error.name : false}
											fullWidth
										/>
									</MDBox>
									{/* Leader */}
									<MDBox mb={2}>
										<MDInput
											id="leader"
											type="text"
											label="Pemimpin"
											value={this.state.leader}
											onChange={this.handleChange}
											onBlur={this.handleBlur}
											success={this.state.success ? this.state.success.leader : false}
											error={this.state.error ? this.state.error.leader : false}
											fullWidth
										/>
									</MDBox>
									{/* Date Permit */}
									<MDBox mb={2}>
										<MDDatePicker
											input={{
												id: "datePermit",
												placeholder: "Tanggal Izin / SK Lembaga",
												fullWidth: true
											}}
											value={this.state.datePermit}
											onChange={(value) => {
												const date = moment(value[0]).format('YYYY-MM-DD')
												this.setState({
													datePermit: date,
													error: { ...this.state.error, datePermit: false },
													success: { ...this.state.success, datePermit: true }
												})
											}}
										/>
									</MDBox>
									{/* Email */}
									<MDBox mb={2}>
										<MDInput
											id="email"
											type="text"
											label="Email"
											value={this.state.email}
											onChange={this.handleChange}
											onBlur={this.handleBlur}
											success={this.state.success ? this.state.success.email : false}
											error={this.state.error ? this.state.error.email : false}
											fullWidth
										/>
									</MDBox>
									{/* Phone */}
									<MDBox mb={2}>
										<MDInput
											id="phone"
											type="text"
											label="Nomor Telpon"
											value={this.state.phone}
											onChange={this.handleChange}
											onBlur={this.handleBlur}
											success={this.state.success ? this.state.success.phone : false}
											error={this.state.error ? this.state.error.phone : false}
											fullWidth
										/>
									</MDBox>
								</Grid>
								<Grid item xs={12} lg={6} md={6}>
									{/* Province */}
									<MDBox mb={2}>
										<Autocomplete
											fullWidth
											id="province"
											open={this.state.provinceOpen}
											onOpen={() => this.setState({ provinceOpen: true })}
											onClose={() => this.setState({ provinceOpen: false })}
											onFocus={() => this.setState({ provinceOpen: true })}
											value={this.state.province}
											options={this.state.provinces}
											onChange={(e, newValue) => {
												if (newValue) {
													this.setState({
														province: newValue,
														district: null,
														districts: [],
														error: { ...this.state.error, province: false },
														success: { ...this.state.success, province: true }
													})
													this.loadDistrict(newValue.id)
												}
											}}
											sx={{
												".MuiAutocomplete-input": {
													padding: "7.5px 5px 7.5px 8px !important"
												},
												".MuiOutlinedInput-root": {
													padding: "4px !important"
												}
											}}
											isOptionEqualToValue={(option, value) => option.id === value.id}
											renderInput={(params) => <MDInput label="Pilih Provinsi" {...params} />}
										/>
									</MDBox>
									{/* District */}
									<MDBox mb={2}>
										<Autocomplete
											fullWidth
											id="district"
											open={this.state.districtOpen}
											onOpen={() => this.setState({ districtOpen: true })}
											onClose={() => this.setState({ districtOpen: false })}
											onFocus={() => this.setState({ districtOpen: true })}
											value={this.state.district}
											options={this.state.districts}
											onChange={(e, value) => {
												if (value) {
													this.setState({
														district: value,
														error: { ...this.state.error, district: false },
														success: { ...this.state.success, district: true }
													})
												}
											}}
											sx={{
												".MuiAutocomplete-input": {
													padding: "7.5px 5px 7.5px 8px !important"
												},
												".MuiOutlinedInput-root": {
													padding: "4px !important"
												}
											}}
											isOptionEqualToValue={(option, value) => option.id === value.id}
											renderInput={(params) => <MDInput label="Pilih Kabupaten/Kota" {...params} />}
										/>
									</MDBox>
									{/* Address */}
									<MDBox mb={2}>
										<MDInput
											id="address"
											type="text"
											rows={3}
											multiline
											label="Alamat"
											value={this.state.address}
											onChange={this.handleChange}
											onBlur={this.handleBlur}
											success={this.state.success ? this.state.success.address : false}
											error={this.state.error ? this.state.error.address : false}
											fullWidth
										/>
									</MDBox>
									{/* Password & Re-Password */}
									{this.state.action === 'add' && (
										<>
											<MDBox mb={2}>
												<MDInput
													id="password"
													type="password"
													label="Password"
													value={this.state.password}
													onChange={this.handleChange}
													onBlur={this.handleBlur}
													success={this.state.success ? this.state.success.password : false}
													error={this.state.error ? this.state.error.password : false}
													fullWidth
												/>
											</MDBox>
											<MDBox mb={2}>
												<MDInput
													id="repassword"
													type="password"
													label="Konfirmasi Password"
													value={this.state.repassword}
													onChange={this.handleChange}
													onBlur={this.handleBlur}
													success={this.state.success ? this.state.success.repassword : false}
													error={this.state.error ? this.state.error.repassword : false}
													fullWidth
												/>
											</MDBox>
										</>
									)}
								</Grid>
							</Grid>
						</MDBox>
					</MDBox>

					<MDBox p={3} display="flex" justifyContent="flex-end">
						<MDBox mr={1}>
							<ButtonBack label={"KEMBALI"} />
						</MDBox>
						<MDButton
							type="button"
							variant="gradient"
							color="info"
							disabled={this.state.disabledSubmit}
							onClick={this.handleSubmit}
						>
							SIMPAN
						</MDButton>
					</MDBox>
				</Card>
			</MDBox>
		)
	}
}

FormAgent.defaultProps = {
	viewId: 1 // default form add / edit
}

FormAgent.propTypes = {
	viewId: PropTypes.oneOf([1, 2]).isRequired
}

export default withRouter(FormAgent)