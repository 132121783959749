/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from "prop-types";
import { Link, Navigate } from 'react-router-dom';
// @mui material components
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from 'components/MDInput';
import MDTypography from "components/MDTypography";
import MDDatePicker from 'components/MDDatePicker';

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import ModalNotif from 'contents/Components/ModalNotif'
import withRouter from 'contents/Components/withRouter'
import ButtonBack from 'contents/Components/ButtonBack'

import Config from 'config'
import moment from 'moment'
import _ from "lodash"
import * as EmailValidator from 'email-validator'

import useAxios from "libs/useAxios"
import secureStorage from 'libs/secureStorage';

class FormMerchant extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			user: null,
			title: "",
			action: "",
			id: null,

			prefix: "",
			name: "",
			brand: "",
			notary: "",
			notarialNumber: "",
			companyRegistration: "",
			asita: "",
			iata: "",
			siup: "",
			npwp: "",
			memberHimpuh: "",
			permitHaji: "",
			permitUmroh: "",
			email: "",
			phone: "",
			whatsapp: "",
			address: "",

			password: "",
			repassword: "",

			province: null,
			district: null,

			logo: null,
			filenameLogo: "",

			provinceOpen: false,
			districtOpen: false,

			error: {
				province: true,
				district: true,
				dateBirth: true,
			},
			success: [],

			provinces: [],
			districts: [],

			isPreffix: false,
			disabledSubmit: false,
			redirect: null
		}

		this.inputRef = React.createRef()
		this.fileRef = React.createRef()
		this.fileReceiptRef = React.createRef()
		this.modalNotifRef = React.createRef()
	}

	componentDidMount() {
		this.inputRef.current && this.inputRef.current.focus()
		const user = secureStorage.getItem('user')

		this.setState({ user }, () => {
			this.loadProvince()
			this.loadPath()
		})
	}

	loadPath = () => {
		const viewId = this.props.viewId
		const pathname = window.location.pathname
		const index = pathname.indexOf('edit')
		if (index === -1) {
			this.setState({
				title: viewId === 1 ? "Tambah Biro Travel Baru" : "Pendaftaran Biro Travel",
				action: "add",
				isPreffix: false
			})
		}
		else {
			const id = this.props.params?.id
			this.loadDetail(id)
			this.setState({
				id,
				isPreffix: true,
				title: "Ubah Data Biro Travel",
				action: "edit"
			})
		}
	}

	loadDetail = (id) => {
		useAxios()
			.post(`${Config.ApiUrl}/manage/merchant/get`, { id })
			.then(response => {
				const data = response.data
				const province = data && data.Province ? data.Province : null
				const district = data && data.District ? data.District : null

				province && this.loadDistrict(province?.id) // load list district
				this.setState({
					prefix: data.prefix,
					name: data.name,
					notary: data && data.notary ? data.notary : "",
					notarialNumber: data && data.notarialNumber ? data.notarialNumber : "",
					npwp: data && data.npwp ? data.npwp : "",
					companyRegistration: data && data.companyRegistration ? data.companyRegistration : "",
					siup: data && data.siup ? data.siup : "",
					asita: data && data.asita ? data.asita : "",
					iata: data && data.iata ? data.iata : "",
					memberHimpuh: data && data.memberHimpuh ? data.memberHimpuh : "",
					permitHaji: data && data.permitHaji ? data.permitHaji : "",
					permitUmroh: data && data.permitUmroh ? data.permitUmroh : "",
					brand: data && data.brand ? data.brand : "",
					email: data && data.email ? data.email : "",
					phone: data && data.phone ? data.phone : "",
					address: data && data.address ? data.address : "",
					whatsapp: data && data.whatsapp ? data.whatsapp : "",
					province: province ? { id: province.id, label: province.name } : null,
					district: district ? { id: district.id, label: district.name } : null,

					success: {
						...this.state.success,
						prefix: data.prefix ? true : false,
						name: data.name ? true : false,
						brand: data.brand ? true : false,
						notary: data.notary ? true : false,
						notarialNumber: data.notarialNumber ? true : false,
						npwp: data.npwp ? true : false,
						companyRegistration: data.companyRegistration ? true : false,
						siup: data.siup ? true : false,
						asita: data.asita ? true : false,
						iata: data.iata ? true : false,
						memberHimpuh: data.memberHimpuh ? true : false,
						permitHaji: data.permitHaji ? true : false,
						permitUmroh: data.permitUmroh ? true : false,
						phone: data && data.phone ? true : false,
						email: data && data.email ? true : false,
						whatsapp: data && data.whatsapp ? true : false,
						address: data && data.address ? true : false,

						province: province ? true : false,
						district: district ? true : false,
						password: true,
						repassword: true,
					}
				})
			})
			.catch(err => {
				console.log(err)
				this.modalNotifRef.current.setShow({
					modalTitle: 'Gagal',
					modalMessage: err.response ? err.response.data : "Koneksi jaringan terputus",
					onClose: () => {
						this.setState({ redirect: '/manage/merchant' })
					}
				})
			})
	}

	loadProvince = () => {
		useAxios()
			.post(`${Config.ApiUrl}/master/region/province/dropdown`, { countryId: 1 })
			.then(response => {
				const data = response.data
				const provinces = data.map(item => ({
					id: item.id,
					label: item.name
				}))
				this.setState({ provinces })
			})
			.catch(err => {
				console.log(err)
			})
	}

	loadDistrict = (provinceId) => {
		useAxios()
			.post(`${Config.ApiUrl}/master/region/district/dropdown`, { provinceId })
			.then(response => {
				const data = response.data
				const districts = data.map(item => ({ id: item.id, label: item.name }))
				this.setState({ districts })
			})
			.catch(err => {
				console.log(err)
			})
	}

	handleChange = (e) => {
		const { id, value } = e.target;
		this.setState({
			[id]: value
		})
	}

	handleBlur = (e) => {
		const user = this.state.user
		const { id, value } = e.target
		// eslint-disable-next-line no-empty
		if (value) {
			if (id === "email" && !EmailValidator.validate(value)) {
				this.setState({
					success: { ...this.state.success, [e.target.id]: false },
					error: { ...this.state.error, [e.target.id]: true }
				})
			}
			else {
				this.setState({
					success: { ...this.state.success, [e.target.id]: true },
					error: { ...this.state.error, [e.target.id]: false }
				})
			}
		}
		else {
			this.setState({
				success: { ...this.state.success, [e.target.id]: false },
				error: { ...this.state.error, [e.target.id]: true }
			})
		}
	}

	handleSubmit = () => {
		const user = this.state.user
		const success = this.state.success

		if (this.state.action === "add" && this.state.password !== this.state.repassword) {
			success.repassword = false
		}

		if (
			success.prefix &&
			success.name &&
			success.brand &&
			success.notary &&
			success.notarialNumber &&
			success.npwp &&
			success.companyRegistration &&
			success.siup &&
			success.memberHimpuh &&
			success.permitHaji &&
			success.permitUmroh &&
			success.asita &&
			success.iata &&
			success.email &&
			success.phone &&
			success.whatsapp &&
			success.address &&
			success.province &&
			success.district &&
			// success.logo &&
			success.password &&
			success.repassword
		) {
			this.sendData()
		}
		else {
			let input = "";
			!success.address && (input = "Alamat");
			// !success.logo && (input = "Logo");
			!success.district && (input = "Kabupaten");
			!success.province && (input = "Provinsi");
			!success.iata && (input = "IATA/ lATA");
			!success.asita && (input = "ASITA/ ASITA");
			!success.permitHaji && (input = "Izin Umrah/ Licence for Umra");
			!success.permitUmroh && (input = "Izin Haji/ Licence for Hajj");
			!success.notarialNumber && (input = "Akta Pendirian/ Notarial Number");
			!success.email && (input = "Email");
			!success.phone && (input = "Nomor Telpon");
			!success.whatsapp && (input = "Whatsapp");
			!success.memberHimpuh && (input = "Anggota HIMPUH");
			!success.siup && (input = "SIUP");
			!success.companyRegistration && (input = "TDP/ Company Registration");
			!success.npwp && (input = "NPWP");
			!success.notary && (input = "Notaris/ Notary");
			!success.brand && (input = "Nama Merek");
			!success.name && (input = "Nama Biro");

			this.modalNotifRef.current.setShow({
				modalTitle: "Gagal",
				modalMessage: `Data ${input} masih kosong, Silahkan di cek kembali !`,
			});
		}
	}

	sendData = () => {
		const viewId = this.props.viewId
		this.setState({ disabledSubmit: true })
		const formData = new FormData()

		formData.append('prefix', this.state.prefix)
		formData.append('name', this.state.name)
		formData.append('brand', this.state.brand)
		formData.append('notary', this.state.notary)
		formData.append('notarialNumber', this.state.notarialNumber)
		formData.append('npwp', this.state.npwp)
		formData.append('companyRegistration', this.state.companyRegistration)
		formData.append('siup', this.state.siup)
		formData.append('permitHaji', this.state.permitHaji)
		formData.append('permitUmroh', this.state.permitUmroh)
		formData.append('asita', this.state.asita)
		formData.append('iata', this.state.iata)
		formData.append('email', this.state.email)
		formData.append('phone', this.state.phone)
		formData.append('whatsapp', this.state.whatsapp)
		formData.append('address', this.state.address)
		// formData.append('countryId', 1)
		formData.append('provinceId', this.state.province?.id)
		formData.append('districtId', this.state.district?.id)
		formData.append('password', this.state.password)
		formData.append('logo', this.state.logo)
		if (this.state.action === "edit")
			formData.append('id', this.state.id)

		const url = this.state.action === "add" ? `/manage/merchant/${viewId === 1 ? 'create' : 'register'}` : "/manage/merchant/update"
		useAxios()
			.post(`${Config.ApiUrl}${url} `, formData)
			.then(response => {
				this.modalNotifRef.current.setShow({
					modalTitle: "Sukses",
					modalMessage: response.data,
					onClose: () => {
						this.setState({
							redirect: viewId === 1 ? '/manage/merchant' : '/login',
							disabledSubmit: false
						})
					}
				})
			})
			.catch(err => {
				console.log(err)
				this.setState({ disabledSubmit: false })
				if (err.response) {
					this.modalNotifRef.current.setShow({
						modalTitle: "Gagal",
						modalMessage: err.response.data,
					})
				}
				// eslint-disable-next-line no-empty
				else {
					this.modalNotifRef.current.setShow({
						modalTitle: 'Gagal',
						modalMessage: "Koneksi jaringan terputus",
					})
				}
			})
	}

	render() {
		const viewId = this.props.viewId
		if (this.state.redirect) {
			return <Navigate to={this.state.redirect} />
		}

		return (
			<>
				<ModalNotif ref={this.modalNotifRef} />

				<MDBox pb={3} my={3}>
					<Card>
						<MDBox
							p={3}
							display="flex"
							lineHeight={1}
							width="100%"
							justifyContent="center"
						>
							<MDTypography variant="h5" fontWeight="medium">
								{this.state.title}
							</MDTypography>
						</MDBox>

						<MDBox p={3}>
							<MDBox component="form" role="form">
								<Grid container spacing={3}>
									<Grid item xs={12} lg={6} md={6}>
										{/* Prefix Code */}
										<MDBox mb={2}>
											<MDInput
												id="prefix"
												type="text"
												label="Prefix Kode"
												inputRef={this.inputRef}
												value={this.state.prefix}
												onChange={this.handleChange}
												onBlur={this.handleBlur}
												success={this.state.success ? this.state.success.prefix : false}
												error={this.state.error ? this.state.error.prefix : false}
												fullWidth
											/>
											<MDTypography color="error" variant="span" fontWeight="bold" fontSize="12px">
												* digunakan untuk awalan pembuatan kode biro
											</MDTypography>
										</MDBox>
										{/* Name */}
										<MDBox mb={2}>
											<MDInput
												id="name"
												type="text"
												label="Nama Biro Travel"
												value={this.state.name}
												onChange={this.handleChange}
												onBlur={this.handleBlur}
												success={this.state.success ? this.state.success.name : false}
												error={this.state.error ? this.state.error.name : false}
												fullWidth
											/>
										</MDBox>
										{/* Name Brand */}
										<MDBox mb={2}>
											<MDInput
												id="brand"
												type="text"
												label="Nama Merek"
												value={this.state.brand}
												onChange={this.handleChange}
												onBlur={this.handleBlur}
												success={this.state.success ? this.state.success.brand : false}
												error={this.state.error ? this.state.error.brand : false}
												fullWidth
											/>
										</MDBox>
										{/* Notaris */}
										<MDBox mb={2}>
											<MDInput
												id="notary"
												type="text"
												label="Notaris/ Notary"
												value={this.state.notary}
												onChange={this.handleChange}
												onBlur={this.handleBlur}
												success={this.state.success ? this.state.success.notary : false}
												error={this.state.error ? this.state.error.notary : false}
												fullWidth
											/>
										</MDBox>
										{/* NPWP */}
										<MDBox mb={2}>
											<MDInput
												id="npwp"
												type="text"
												label="NPWP/ Tax Number"
												value={this.state.npwp}
												onChange={this.handleChange}
												onBlur={this.handleBlur}
												success={this.state.success ? this.state.success.npwp : false}
												error={this.state.error ? this.state.error.npwp : false}
												fullWidth
											/>
										</MDBox>
										{/* TDP / companyRegistration*/}
										<MDBox mb={2}>
											<MDInput
												id="companyRegistration"
												type="text"
												label="TDP/ Company Registration"
												value={this.state.companyRegistration}
												onChange={this.handleChange}
												onBlur={this.handleBlur}
												success={this.state.success ? this.state.success.companyRegistration : false}
												error={this.state.error ? this.state.error.companyRegistration : false}
												fullWidth
											/>
										</MDBox>
										{/* SIUP */}
										<MDBox mb={2}>
											<MDInput
												id="siup"
												type="text"
												label="SIUP/ Licence of Business Trade"
												value={this.state.siup}
												onChange={this.handleChange}
												onBlur={this.handleBlur}
												success={this.state.success ? this.state.success.siup : false}
												error={this.state.error ? this.state.error.siup : false}
												fullWidth
											/>
										</MDBox>
										{/* Anggota HIMPUH */}
										<MDBox mb={2}>
											<MDInput
												id="memberHimpuh"
												type="text"
												label="Anggota HIMPUH"
												value={this.state.memberHimpuh}
												onChange={this.handleChange}
												onBlur={this.handleBlur}
												success={this.state.success ? this.state.success.memberHimpuh : false}
												error={this.state.error ? this.state.error.memberHimpuh : false}
												fullWidth
											/>
										</MDBox>
										{/* Phone */}
										<MDBox mb={2}>
											<MDInput
												id="phone"
												type="text"
												label="Nomor Telpon"
												value={this.state.phone}
												onChange={this.handleChange}
												onBlur={this.handleBlur}
												success={this.state.success ? this.state.success.phone : false}
												error={this.state.error ? this.state.error.phone : false}
												fullWidth
											/>
										</MDBox>
										{/* Whatsapp */}
										<MDBox mb={2}>
											<MDInput
												id="whatsapp"
												type="text"
												label="Whatsapp"
												value={this.state.whatsapp}
												onChange={this.handleChange}
												onBlur={this.handleBlur}
												success={this.state.success ? this.state.success.whatsapp : false}
												error={this.state.error ? this.state.error.whatsapp : false}
												fullWidth
											/>
										</MDBox>
										{/* Email */}
										<MDBox mb={2}>
											<MDInput
												id="email"
												type="text"
												label="Email"
												value={this.state.email}
												onChange={this.handleChange}
												onBlur={this.handleBlur}
												success={this.state.success ? this.state.success.email : false}
												error={this.state.error ? this.state.error.email : false}
												fullWidth
											/>
										</MDBox>
									</Grid>
									<Grid item xs={12} md={6} lg={6}>
										{/* Akta Pendirian/ Notarial Number*/}
										<MDBox mb={2}>
											<MDInput
												id="notarialNumber"
												type="text"
												label="Akta Pendirian/ Notarial Number"
												value={this.state.notarialNumber}
												onChange={this.handleChange}
												onBlur={this.handleBlur}
												success={this.state.success ? this.state.success.notarialNumber : false}
												error={this.state.error ? this.state.error.notarialNumber : false}
												fullWidth
											/>
										</MDBox>
										{/* Izin Haji */}
										<MDBox mb={2}>
											<MDInput
												id="permitHaji"
												type="text"
												label="Izin Haji/ Licence for Hajj"
												value={this.state.permitHaji}
												onChange={this.handleChange}
												onBlur={this.handleBlur}
												success={this.state.success ? this.state.success.permitHaji : false}
												error={this.state.error ? this.state.error.permitHaji : false}
												fullWidth
											/>
										</MDBox>
										{/* Izin Umrah */}
										<MDBox mb={2}>
											<MDInput
												id="permitUmroh"
												type="text"
												label="Izin Umrah/ Licence for Umra"
												value={this.state.permitUmroh}
												onChange={this.handleChange}
												onBlur={this.handleBlur}
												success={this.state.success ? this.state.success.permitUmroh : false}
												error={this.state.error ? this.state.error.permitUmroh : false}
												fullWidth
											/>
										</MDBox>
										{/* ASITA/ ASITA */}
										<MDBox mb={2}>
											<MDInput
												id="asita"
												type="text"
												label="ASITA/ ASITA"
												value={this.state.asita}
												onChange={this.handleChange}
												onBlur={this.handleBlur}
												success={this.state.success ? this.state.success.asita : false}
												error={this.state.error ? this.state.error.asita : false}
												fullWidth
											/>
										</MDBox>
										{/* IATA/ lATA */}
										<MDBox mb={2}>
											<MDInput
												id="iata"
												type="text"
												label="IATA/ lATA"
												value={this.state.iata}
												onChange={this.handleChange}
												onBlur={this.handleBlur}
												success={this.state.success ? this.state.success.iata : false}
												error={this.state.error ? this.state.error.iata : false}
												fullWidth
											/>
										</MDBox>
										{/* Province */}
										<MDBox mb={2}>
											<Autocomplete
												fullWidth
												id="province"
												open={this.state.provinceOpen}
												onOpen={() => this.setState({ provinceOpen: true })}
												onClose={() => this.setState({ provinceOpen: false })}
												onFocus={() => this.setState({ provinceOpen: true })}
												value={this.state.province}
												options={this.state.provinces}
												onChange={(e, newValue) => {
													if (newValue) {
														this.setState({
															province: newValue,
															district: null,
															districts: [],
															error: { ...this.state.error, province: false, district: true },
															success: { ...this.state.success, province: true, district: false }
														})
														this.loadDistrict(newValue.id)
													}
												}}
												sx={{
													".MuiAutocomplete-input": {
														padding: "7.5px 5px 7.5px 8px !important"
													},
													".MuiOutlinedInput-root": {
														padding: "4px !important"
													}
												}}
												isOptionEqualToValue={(option, value) => option.id === value.id}
												renderInput={(params) => <MDInput label="Pilih Provinsi" {...params} />}
											/>
										</MDBox>
										{/* District */}
										<MDBox mb={2}>
											<Autocomplete
												fullWidth
												id="district"
												open={this.state.districtOpen}
												onOpen={() => this.setState({ districtOpen: true })}
												onClose={() => this.setState({ districtOpen: false })}
												onFocus={() => this.setState({ districtOpen: true })}
												value={this.state.district}
												options={this.state.districts}
												onChange={(e, value) => {
													if (value) {
														this.setState({
															district: value,
															error: { ...this.state.error, district: false },
															success: { ...this.state.success, district: true }
														})
													}
												}}
												sx={{
													".MuiAutocomplete-input": {
														padding: "7.5px 5px 7.5px 8px !important"
													},
													".MuiOutlinedInput-root": {
														padding: "4px !important"
													}
												}}
												isOptionEqualToValue={(option, value) => option.id === value.id}
												renderInput={(params) => <MDInput label="Pilih Kabupaten/Kota" {...params} />}
											/>
										</MDBox>
										{/* Logo */}
										<MDBox mb={2}>
											<input
												type="file"
												name="fileInput"
												ref={this.fileRef}
												onChange={(e) => {
													if (e.target.files.length === 1) {
														const logo = e.target.files[0];
														const filenameLogo = logo.name;
														const ext = filenameLogo.split(".")[1];
														this.setState({
															logo, filenameLogo,
															error: { ...this.state.error, logo: false },
															success: { ...this.state.success, logo: true }
														})
													}
												}}
												hidden
											/>
											<MDInput
												fullWidth
												value={this.state.filenameLogo}
												label="Upload Logo"
												onClick={() => {
													this.fileRef.current.click()
												}}
												readOnly
											/>
										</MDBox>
										{/* Address */}
										<MDBox mb={2}>
											<MDInput
												id="address"
												type="text"
												rows={3}
												multiline
												label="Alamat"
												value={this.state.address}
												onChange={this.handleChange}
												onBlur={this.handleBlur}
												success={this.state.success ? this.state.success.address : false}
												error={this.state.error ? this.state.error.address : false}
												fullWidth
											/>
										</MDBox>
										{/* Password & Re-Password */}
										{this.state.action === 'add' && (
											<>
												<MDBox mb={2}>
													<MDInput
														id="password"
														type="password"
														label="Password"
														value={this.state.password}
														onChange={this.handleChange}
														onBlur={this.handleBlur}
														success={this.state.success ? this.state.success.password : false}
														error={this.state.error ? this.state.error.password : false}
														fullWidth
													/>
												</MDBox>
												<MDBox mb={2}>
													<MDInput
														id="repassword"
														type="password"
														label="Konfirmasi Password"
														value={this.state.repassword}
														onChange={this.handleChange}
														onBlur={this.handleBlur}
														success={this.state.success ? this.state.success.repassword : false}
														error={this.state.error ? this.state.error.repassword : false}
														fullWidth
													/>
												</MDBox>
											</>
										)}
									</Grid>
								</Grid>
							</MDBox>
						</MDBox>

						<MDBox p={3} display="flex" justifyContent="flex-end">
							<MDBox mr={1}>
								<ButtonBack label={"KEMBALI"} />
							</MDBox>
							<MDButton
								type="button"
								variant="gradient"
								color="info"
								disabled={this.state.disabledSubmit}
								onClick={this.handleSubmit}
							>
								SIMPAN
							</MDButton>
						</MDBox>
					</Card>
				</MDBox>
			</>
		)
	}
}

FormMerchant.defaultProps = {
	viewId: 1 // default form add / edit
}

FormMerchant.propTypes = {
	viewId: PropTypes.oneOf([1, 2]).isRequired
}

export default withRouter(FormMerchant)