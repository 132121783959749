import { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

import useAxios from "libs/useAxios";
import Config from "config";
import secureStorage from "libs/secureStorage";

import Confirm from "contents/Components/Confirm";
import ModalNotif from "contents/Components/ModalNotif";
import print from "helper/printer";

function ButtonAction({ id }) {
  const confirmRef = useRef();
  const modalNotifRef = useRef();
  const [user, setUser] = useState(null);

  const navigate = useNavigate();
  const [menu, setMenu] = useState(null);

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);

  useEffect(() => {
    setUser(secureStorage.getItem('user'))
  }, [])

  const handleEdit = () => {
    closeMenu();
    navigate(`/master/tour-package/edit/${id}`);
  };

  const handleDetail = () => {
    closeMenu();
    navigate(`/adm/product/detail/${id}`);
  }

  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      {user && [2, 3].includes(user.roleId) && (<MenuItem onClick={handleEdit}>Edit</MenuItem>)}
      {user && [2, 3, 4].includes(user.roleId) && (<MenuItem onClick={handleDetail}>Detail</MenuItem>)}
    </Menu>
  );

  return (
    <MDBox display="flex">
      <Confirm ref={confirmRef} />
      <ModalNotif ref={modalNotifRef} />

      <MDButton variant="contained" color="info" size="small" onClick={openMenu}>
        actions&nbsp; <Icon>keyboard_arrow_down</Icon>
      </MDButton>
      {renderMenu}
    </MDBox>
  );
}

ButtonAction.propTypes = {
  id: PropTypes.number
};

export default ButtonAction;
