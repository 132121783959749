import { useState, useEffect } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import DetailTransaction from "contents/Finance/Transaction/components/Detail";
import DetailMember from "contents/Administrasi/ListMember/components/Detail";
import DetailBill from "contents/Finance/Bill/components/DetailBill";
import Payment from "contents/Finance/Payment"
import DocumentUser from "contents/Administrasi/Document"

function TabLayout() {
	const [tabsOrientation, setTabsOrientation] = useState("horizontal");
	const [activeTab, setActiveTab] = useState(0);

	const handleSetTabValue = (event, newValue) => setActiveTab(newValue);

	return (
		<>
			<MDBox my={3}>
				<Grid container spacing={3}>
					<Grid item xs={12} sm={12} lg={12}>
						<AppBar position="static">
							<Tabs orientation={tabsOrientation} value={activeTab} onChange={handleSetTabValue}>
								<Tab label="Detail Pesanan" />
								<Tab label="Detail Tagihan" />
								<Tab label="Data Pelanggan" />
								<Tab label="History Pembayaran" />
								<Tab label="Dokumen Kelengkapan" />
							</Tabs>
						</AppBar>
					</Grid>
				</Grid>
			</MDBox>
			{activeTab === 0 ? (
				<DetailTransaction view="bill" />
			) : activeTab === 1 ? (
				<DetailBill />
			) : activeTab === 2 ? (
				<DetailMember view={"bill"} />
			) : activeTab === 3 ? (
				<Payment view={"bill"} />
			) : (
				<DocumentUser view={"bill"} />
			)}
		</>
	);
}

export default TabLayout;
