/* eslint-disable react/prop-types */
import React from "react";
import { Link, Navigate } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import MDDatePicker from "components/MDDatePicker";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import ModalNotif from "contents/Components/ModalNotif";
import withRouter from "contents/Components/withRouter";

import MiniFormCard from "contents/Components/FormCard/MiniFormCard";

import Config from "config";
import _ from "lodash";

import useAxios from "libs/useAxios";
import secureStorage from "libs/secureStorage";

class FormInventory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      title: "",
      action: "",
      id: null,

      name: "",
      category: "",
      categories: [
        "Perlengkapan Haji dan Umroh",
        "Makanan dan Minuman",
        "Oleh-Oleh",
        "Pakaian",
        "Surat Surat",
      ],
      type: "",
      types: ["Bahan Baku / Bahan Mentah", "Bahan Setengah Jadi", "Barang Jadi"],
      remark: "",

      error: [],
      success: [],

      disabledSubmit: false,
      redirect: null,
    };

    this.inputRef = React.createRef();
    this.modalNotifRef = React.createRef();
  }

  componentDidMount() {
    this.inputRef.current.focus();

    const user = secureStorage.getItem("user");
    this.setState({
      user: user ? user : null,
    });
    this.loadPath();
  }

  loadPath() {
    const pathname = window.location.pathname;
    const index = pathname.indexOf("edit");
    if (index === -1) {
      this.setState({
        title: "Tambah Barang Baru",
        action: "add",
      });
    } else {
      const id = this.props.params?.id;
      this.loadDetail(id);
      this.setState({
        id,
        title: "Ubah Data Barang",
        action: "edit",
        error: {
          ...this.state.error,
          name: false,
          category: false,
          type: false,
          remark: false,
        },
        success: {
          ...this.state.success,
          name: true,
          category: true,
          type: true,
          remark: true,
        },
      });
    }
  }

  loadDetail(id) {
    useAxios()
      .post(`${Config.ApiUrl}/inventory/input/detail`, { id })
      .then((response) => {
        const data = response.data;
        this.setState({
          name: data.name,
          category: data.category,
          type: data.type,
          remark: data.remark,
        });
      })
      .catch((err) => {
        console.log(err);
        this.modalNotifRef.current.setShow({
          modalTitle: "Gagal",
          modalMessage: err.response ? err.response.data : "Koneksi jaringan terputus",
          onClose: () => {
            this.setState({ redirect: "/inventory/input" });
          },
        });
      });
  }

  handleChange = (e) => {
    const { id, value } = e.target;
    this.setState({
      [id]: value,
    });
  };

  handleBlur = (e) => {
    const { id, value } = e.target;
    // eslint-disable-next-line no-empty
    if (value) {
      this.setState({
        success: { ...this.state.success, [e.target.id]: true },
        error: { ...this.state.error, [e.target.id]: false },
      });
    } else {
      this.setState({
        success: { ...this.state.success, [e.target.id]: false },
        error: { ...this.state.error, [e.target.id]: true },
      });
    }
  }

  handleKeyDown = (e) => {
    // if (e.key === "Enter") this.handleSubmit()
  };

  handleSubmit = () => {
    let error = this.state.error;
    let success = this.state.success;

    if ((success.name, success.category, success.type)) {
      this.sendData();
    } else {
      this.modalNotifRef.current.setShow({
        modalTitle: "Gagal",
        modalMessage: "Data form masih belum lengkap, Silahkan di cek kembali !",
      });
    }
  }

  sendData = () => {
    this.setState({
      disabledSubmit: true
    })

    const user = this.state.user;
    const payload = {
      name: this.state.name,
      category: this.state.category,
      type: this.state.type,
      remark: this.state.remark,
      merchantId: user.Merchant?.id,
      id: this.state.id,
    };

    const url = this.state.action === "add" ? "/inventory/input/create" : "/inventory/input/update";
    useAxios()
      .post(`${Config.ApiUrl}${url}`, payload)
      .then((response) => {
        this.modalNotifRef.current.setShow({
          modalTitle: "Sukses",
          modalMessage: response.data,
          onClose: () => {
            this.setState({ redirect: "/inventory/input" });
          },
        });

        this.setState({
          disabledSubmit: false,
        });
      })
      .catch((err) => {
        this.setState({ disabledSubmit: false });
        if (err.response) {
          this.modalNotifRef.current.setShow({
            modalTitle: "Gagal",
            modalMessage: err.response ? err.response.data : "Koneksi jaringan terputus",
          });
        }
        // eslint-disable-next-line no-empty
        else {
          this.modalNotifRef.current.setShow({
            modalTitle: "Gagal",
            modalMessage: "Koneksi jaringan terputus",
          });
        }
      });
  };

  render() {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />;
    }

    return (
      <MiniFormCard>
        <ModalNotif ref={this.modalNotifRef} />
        <MDBox p={3} lineHeight={1}>
          <MDTypography variant="h5" fontWeight="medium">
            {this.state.title}
          </MDTypography>
        </MDBox>

        <MDBox p={3}>
          <MDBox component="form" role="form">
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12}>
                <MDBox mb={2}>
                  <MDInput
                    inputRef={this.inputRef}
                    id="name"
                    type="text"
                    label="Nama Barang"
                    value={this.state.name}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    onKeyDown={this.handleKeyDown}
                    success={this.state.success ? this.state.success.name : false}
                    error={this.state.error ? this.state.error.name : false}
                    fullWidth
                  />
                </MDBox>
                <MDBox mb={2}>
                  <Autocomplete
                    value={this.state.category}
                    options={this.state.categories}
                    onChange={(e, value) => {
                      if (value) {
                        this.setState({
                          category: value,
                          error: { ...this.state.error, category: false },
                          success: { ...this.state.success, category: true },
                        });
                      }
                    }}
                    sx={{
                      ".MuiAutocomplete-input": {
                        padding: "7.5px 5px 7.5px 8px !important",
                      },
                      ".MuiOutlinedInput-root": {
                        padding: "1.5px !important",
                      },
                    }}
                    isOptionEqualToValue={(option, value) => option === value}
                    renderInput={(params) => (
                      <MDInput label="Pilih Kategory Barang" {...params} />
                    )}
                  />
                </MDBox>
                <MDBox mb={2}>
                  <Autocomplete
                    value={this.state.type}
                    options={this.state.types}
                    onChange={(e, value) => {
                      if (value) {
                        this.setState({
                          type: value,
                          error: { ...this.state.error, type: false },
                          success: { ...this.state.success, type: true },
                        });
                      }
                    }}
                    sx={{
                      ".MuiAutocomplete-input": {
                        padding: "7.5px 5px 7.5px 8px !important",
                      },
                      ".MuiOutlinedInput-root": {
                        padding: "1.5px !important",
                      },
                    }}
                    isOptionEqualToValue={(option, value) => option === value}
                    renderInput={(params) => <MDInput label="Pilih Type Barang" {...params} />}
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    id="remark"
                    type="text"
                    multiline
                    rows={3}
                    label="Catatan"
                    value={this.state.remark}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    onKeyDown={this.handleKeyDown}
                    success={this.state.success ? this.state.success.remark : false}
                    error={this.state.error ? this.state.error.remark : false}
                    fullWidth
                  />
                </MDBox>

                <MDBox pt={3} display="flex" justifyContent="space-between">
                  <MDButton
                    variant="gradient"
                    color="error"
                    component={Link}
                    to={{ pathname: "/inventory/input" }}
                  >
                    KEMBALI
                  </MDButton>
                  <MDButton
                    type="button"
                    variant="gradient"
                    color="info"
                    disabled={this.state.disabledSubmit}
                    onKeyDown={this.handleKeyDown}
                    onClick={this.handleSubmit}
                  >
                    SUBMIT
                  </MDButton>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
      </MiniFormCard>
    );
  }
}

export default withRouter(FormInventory);
