/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { Link, Navigate } from "react-router-dom";
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";

// @mui icons
import { Close } from "@mui/icons-material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

import DataTable from "contents/Components/DataTable";
import Pagination from "contents/Components/Pagination";

import ModalNotif from "contents/Components/ModalNotif";
import DialogForm from "contents/Components/DialogForm";

import Config from "config";
import _, { find } from "lodash";

import useAxios from "libs/useAxios";
import secureStorage from "libs/secureStorage";

class DialogStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      statusId: null,
      memberId: null,

      status: "Belum Diterima",
      statuses: ["Belum Diterima", "Sudah Diterima"],
      payload: [],

      disabledSubmit: false,

      keyword: "",
      rowsPerPage: 10,
      currentPage: 1,
      totalPages: 0,
      totalData: 0,
      tableHead: [
        { Header: "no", accessor: "no", with: "5%" },
        { Header: "Nama Barang", accessor: "name", with: "20%" },
        { Header: "Status Penerimaan", accessor: "status", with: "35%" },
      ],
      rows: [],
    };

    this.modalNotifRef = React.createRef();
    this.dialogFormRef = React.createRef();
  }

  componentDidMount() {}

  handleChange = (e) => {
    const { id, value } = e.target;
    this.setState({
      [id]: value,
    });
  };

  setShow = (data) => {
    this.setState(
      {
        memberId: data.memberId,
      },
      () => {
        this.dialogFormRef.current.setShow({ ...data, title: "Ubah Pendataan Barang" });
        this.loadData();
      }
    );
  };

  resetForm = () => {
    this.setState({
      payload: [],
    });
  };

  handleSubmit = () => {
    this.setState({ disabledSubmit: true });
    const payload = {
      userId: this.state.memberId,
      payload: [...new Map(this.state.payload.map((item) => [item["id"], item])).values()],
    };

    useAxios()
      .post(`${Config.ApiUrl}/inventory/data/update`, payload)
      .then((response) => {
        this.modalNotifRef.current.setShow({
          modalTitle: "Sukses",
          modalMessage: response.data,
          onClose: () => {
            this.resetForm();
            this.setState({
              redirect: "/inventory/data",
              disabledSubmit: false,
            });
          },
        });
        this.props.onAction();
        this.dialogFormRef.current.setShow({ show: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ disabledSubmit: false });
        if (err.response) {
          this.modalNotifRef.current.setShow({
            modalTitle: "Gagal",
            modalMessage: err.response ? err.response.data : "Server Internal Error",
          });
        }
        // eslint-disable-next-line no-empty
        else {
          this.modalNotifRef.current.setShow({
            modalTitle: "Gagal",
            modalMessage: "Koneksi jaringan terputus",
          });
        }
      });
  };

  loadData = (params) => {
    this.setState({ isLoading: true });

    const user = secureStorage.getItem("user");
    const merchantId =
      params && params.merchantId
        ? { merchantId: params.merchantId }
        : user.Merchant?.id ?? {};

    const payload = {
      keyword: params && params.keyword ? params.keyword : this.state.keyword,
      currentPage: params && params.currentPage ? params.currentPage : 1,
      rowsPerPage: params && params.rowsPerPage ? params.rowsPerPage : this.state.rowsPerPage,
      ...merchantId,
    };

    const status = (params) => {
      return (
        <MDBox mb={2}>
          <Autocomplete
            defaultValue={
              params.user && params.user.includes(this.state.memberId)
                ? "Sudah Diterima"
                : "Belum Diterima"
            }
            options={this.state.statuses}
            onChange={(e, value) => {
              if (value) {
                let obj = {
                  id: params.id,
                  value,
                };

                this.setState({
                  payload: [...this.state.payload, obj],
                });
              }
            }}
            sx={{
              ".MuiAutocomplete-input": {
                width: "100% !important",
                padding: "7.5px 5px 7.5px 8px !important",
              },
              ".MuiOutlinedInput-root": {
                width: "100% !important",
                padding: "1.5px !important",
              },
            }}
            isOptionEqualToValue={(option, value) => option === value}
            renderInput={(params) => (
              <MDInput label="Pilih Status" size="large" {...params} />
            )}
          />
        </MDBox>
      );
    };

    useAxios()
      .post(`${Config.ApiUrl}/inventory/input/list`, payload)
      .then((response) => {
        const data = response.data;
        let no = 0;
        const output = data.output.map((item) => {
          no++;
          return {
            no,
            name: item.name,
            status: status(item),
          };
        });
        this.setState({
          totalPages: data.totalPages,
          totalData: data.totalData,
          rows: output,
          isLoading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ isLoading: false });
      });
  };

  render() {
    return (
      <DialogForm ref={this.dialogFormRef} maxWidth="md">
        <ModalNotif ref={this.modalNotifRef} />
        <Grid container item xs={12} lg={12} sx={{ mx: "auto", mt: 1 }}>
          <MDBox width="100%" component="form">
            <MDBox p={2}>
              <DataTable
                isLoading={this.state.isLoading}
                tableHead={this.state.tableHead}
                tableData={this.state.rows}
              />
              <Pagination
                totalPages={this.state.totalPages}
                totalData={this.state.totalData}
                currentPage={this.state.currentPage}
                rowsPerPage={[10, 25, 50, "All"]}
                totalButton={3}
                defaultRowsPerPage={this.state.rowsPerPage}
                onChangeRowsPerPage={(value) => {
                  this.setState({ rowsPerPage: value, currentPage: 1 });
                  this.loadData({
                    rowsPerPage: value,
                    currentPage: 1,
                    productId: this.state.product ? this.state.product.id : null,
                    merchantId: this.state.merchant ? this.state.merchant.id : null,
                  });
                }}
                onChangePage={(currentPage) => {
                  if (currentPage !== this.state.currentPage) {
                    this.setState({ currentPage });
                    this.loadData({
                      currentPage,
                      productId: this.state.product ? this.state.product.id : null,
                      merchantId: this.state.merchant ? this.state.merchant.id : null,
                    });
                  }
                }}
              />
            </MDBox>
          </MDBox>

          <MDBox
            py={3}
            width="100%"
            display="flex"
            justifyContent={{ md: "flex-end", xs: "center" }}
          >
            <MDBox mr={1}>
              <MDButton
                variant="gradient"
                color="error"
                onClick={() => {
                  this.resetForm();
                  this.dialogFormRef.current.setShow({ show: false });
                }}
              >
                Tutup
              </MDButton>
            </MDBox>
            <MDButton
              variant="gradient"
              color="info"
              disabled={this.state.disabledSubmit}
              onClick={this.handleSubmit}
            >
              Submit
            </MDButton>
          </MDBox>
        </Grid>
      </DialogForm>
    );
  }
}

DialogStatus.propTypes = {
  onAction: PropTypes.func.isRequired,
};

export default DialogStatus;
