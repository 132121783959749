import React, { useState } from "react";
import PropTypes from "prop-types";
import { Navigate, Link } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import DataTable from "contents/Components/DataTable";
import Pagination from "contents/Components/Pagination";
// import ButtonAction from "contents/Master/TourLeader/components/ButtonAction"
// import ButtonAction from "contents/Member/User/components/ButtonAction";
import DialogStatus from "contents/Inventory/DataInventory/DialogStatus";

import useAxios from "libs/useAxios";
import Config from "config";
import secureStorage from "libs/secureStorage";
import { fnumber } from "libs/currency";

class DataInventory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      merchant: null,
      product: null,

      merchants: [],
      products: [],

      rowsPerPage: 10,
      currentPage: 1,
      totalPages: 0,
      totalData: 0,
      tableHead: [],
      rows: [],
      keyword: "",
      isLoading: true,
    };

    this.dialogStatusRef = React.createRef();
  }

  componentDidMount() {
    const user = secureStorage.getItem("user");

    this.loadInventory();
    this.loadData();
    user && user.roleId === 1 && this.loadMerchants();
    user && user.roleId !== 1 && this.loadProduct();
  }

  loadMerchants = () => {
    useAxios()
      .post(`${Config.ApiUrl}/manage/merchant/dropdown`)
      .then((response) => {
        const data = response.data;

        this.setState({
          merchants: data.map((item) => ({ id: item.id, label: item.name })),
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  loadProduct = (params) => {
    const merchantId = params && params.merchantId ? { merchantId: params.merchantId } : {};
    const payload = {
      ...merchantId,
    };

    useAxios()
      .post(`${Config.ApiUrl}/product/dropdown`, payload)
      .then((response) => {
        const data = response.data;

        this.setState({
          products: data.map((item) => ({ id: item.id, label: item.name })),
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  loadInventory = (params) => {
    this.setState({ isLoading: true });

    const user = secureStorage.getItem("user");
    const merchantId = params && params.merchantId ? { merchantId: params.merchantId } : {}

    const payload = {
      keyword: params && params.keyword ? params.keyword : this.state.keyword,
      currentPage: params && params.currentPage ? params.currentPage : 1,
      rowsPerPage: params && params.rowsPerPage ? params.rowsPerPage : this.state.rowsPerPage,
      ...merchantId,
    };

    useAxios()
      .post(`${Config.ApiUrl}/inventory/input/list`, payload)
      .then((response) => {
        const data = response.data;
        let no = 0;
        const output = data.output.map((item) => {
          no++;
          return {
            Header: item.name,
            accessor: item.name,
            id: item.id,
            userId: item.userId,
          };
        });
        this.setState({
          tableHead: [
            { Header: "no", accessor: "no", with: "10%" },
            { Header: "Nama", accessor: "name", with: "15%" },
            ...output,
            { Header: "Action", accessor: "action", width: "10%" },
          ],
          isLoading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ isLoading: false });
      });
  };

  loadData = (params) => {
    this.setState({ isLoading: true });
    const user = secureStorage.getItem("user");

    const merchantId = params && params.merchantId ? { merchantId: params.merchantId } : {};
    const productId = params && params.productId ? { productId: params.productId } : {};

    const payload = {
      keyword: params && params.keyword ? params.keyword : this.state.keyword,
      currentPage: params && params.currentPage ? params.currentPage : 1,
      rowsPerPage: params && params.rowsPerPage ? params.rowsPerPage : this.state.rowsPerPage,
      ...productId,
      ...merchantId,
    };

    const iconCheck = (
      <MDButton variant="gradient" color="success" size="small" iconOnly>
        <Icon fontSize="medium">check</Icon>
      </MDButton>
    );

    const iconCross = (
      <MDButton variant="gradient" color="error" size="small" iconOnly>
        <Icon fontSize="medium">close</Icon>
      </MDButton>
    );
    useAxios()
      .post(`${Config.ApiUrl}/inventory/data/list`, payload)
      .then((response) => {
        const data = response.data;
        let fields = this.state.tableHead;
        fields = fields.slice(2, fields.length - 1);
        let no = 0;
        const output = data.output.map((item) => {
          no++;
          let checkInventory = {};
          for (var field of fields) {
            checkInventory[field.accessor] = item.inventory.includes(field.id)
              ? iconCheck
              : iconCross;
          }
          return {
            no,
            name: item.name,
            ...checkInventory,
            action:
              user && [2, 3, 4].includes(user.roleId) ? (
                <Tooltip title="Pendataan Barang">
                  <MDButton
                    variant="gradient"
                    color="info"
                    size="small"
                    onClick={() => {
                      this.dialogStatusRef.current.setShow({
                        memberId: item.id,
                        memberName: item.name,
                        memberAddress: item.address,
                        show: true,
                      });
                    }}
                    iconOnly
                  >
                    <Icon fontSize="medium">edit</Icon>
                  </MDButton>
                </Tooltip>
              ) : (
                "-"
              ),
          };
        });

        this.setState({
          totalPages: data.totalPages,
          totalData: data.totalData,
          rows: output,
          isLoading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ isLoading: false });
      });
  };

  render() {
    const user = secureStorage.getItem("user");

    return (
      <MDBox pb={3} my={3}>
        <DialogStatus ref={this.dialogStatusRef} onAction={this.loadData} />
        <Card>
          <MDBox p={2} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              Pendataan Barang
            </MDTypography>
          </MDBox>
          <MDBox px={2} width="100%" display="flex" justifyContent="flex-start">
            <Grid container spacing={3}>
              <Grid item xs={12} md={3} lg={3}>
                <MDInput
                  label="Search..."
                  size="small"
                  fullWidth
                  value={this.state.keyword}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      this.loadData({
                        currentPage: 1,
                        keyword: e.target.value,
                        productId: this.state.product ? this.state.product.id : null,
                        merchantId: this.state.merchant ? this.state.merchant.id : null,
                      });
                    }
                  }}
                  onChange={(e) => this.setState({ keyword: e.target.value })}
                />
              </Grid>
              {/* Merchant */}
              {[1].includes(user.roleId) && (
                <Grid item xs={12} md={3} lg={3}>
                  <Autocomplete
                    value={this.state.merchant}
                    options={this.state.merchants}
                    onChange={(e, value) => {
                      const params = value ? { merchantId: value.id } : {}
                      this.loadProduct(params)
                      this.setState({ merchant: value, product: null }, () => {
                        this.loadData({
                          currentPage: 1,
                          merchantId: value ? value.id : null,
                        });
                      });
                    }}
                    sx={{
                      ".MuiAutocomplete-input": {
                        padding: "7.5px 5px 7.5px 8px !important",
                      },
                      ".MuiOutlinedInput-root": {
                        padding: "1.5px !important",
                      },
                    }}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    renderInput={(params) => (
                      <MDInput
                        sx={{ padding: "0px" }}
                        fullWidth
                        label="Pilih Biro Travel"
                        {...params}
                      />
                    )}
                  />
                </Grid>
              )}
              {/* Product */}
              <Grid item xs={12} md={3} lg={3}>
                <Autocomplete
                  value={this.state.product}
                  options={this.state.products}
                  onChange={(e, value) => {
                    this.setState({ product: value });
                    this.loadData({
                      currentPage: 1,
                      productId: value ? value.id : null,
                      merchantId: this.state.merchant ? this.state.merchant.id : null,
                    });
                  }}
                  sx={{
                    ".MuiAutocomplete-input": {
                      padding: "7.5px 5px 7.5px 8px !important",
                    },
                    ".MuiOutlinedInput-root": {
                      padding: "1.5px !important",
                    },
                  }}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  renderInput={(params) => (
                    <MDInput
                      sx={{ padding: "0px" }}
                      fullWidth
                      label="Pilih Paket"
                      {...params}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </MDBox>
          <MDBox p={2}>
            <DataTable
              isLoading={this.state.isLoading}
              tableHead={this.state.tableHead}
              tableData={this.state.rows}
            />
            <Pagination
              totalPages={this.state.totalPages}
              totalData={this.state.totalData}
              currentPage={this.state.currentPage}
              rowsPerPage={[10, 25, 50, "All"]}
              totalButton={3}
              defaultRowsPerPage={this.state.rowsPerPage}
              onChangeRowsPerPage={(value) => {
                this.setState({ rowsPerPage: value, currentPage: 1 });
                this.loadData({
                  rowsPerPage: value,
                  currentPage: 1,
                  productId: this.state.product ? this.state.product.id : null,
                  merchantId: this.state.merchant ? this.state.merchant.id : null,
                });
              }}
              onChangePage={(currentPage) => {
                if (currentPage !== this.state.currentPage) {
                  this.setState({ currentPage });
                  this.loadData({
                    currentPage,
                    productId: this.state.product ? this.state.product.id : null,
                    merchantId: this.state.merchant ? this.state.merchant.id : null,
                  });
                }
              }}
            />
          </MDBox>
        </Card>
      </MDBox>
    );
  }
}

export default DataInventory;
