import React, { useState } from "react";
import { Link } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid"
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDDatePicker from "components/MDDatePicker";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import DataTable from 'contents/Components/DataTable'
import Pagination from "contents/Components/Pagination";
import ButtonAction from "contents/Finance/Transaction/components/ButtonAction"

import useAxios from "libs/useAxios"
import Config from 'config'
import secureStorage from "libs/secureStorage";
import { fnumber } from "libs/currency"

import moment from "moment";

class Transaction extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			status: null,
			startDate: "",
			endDate: "",

			statuses: [],

			rowsPerPage: 10,
			currentPage: 1,
			totalPages: 0,
			totalData: 0,
			tableHead: [
				{ Header: "action", accessor: "action", width: "15%" },
				{ Header: "kode", accessor: "kode", width: "15%" },
				{ Header: "jamaah", accessor: "customer", width: "30%" },
				{ Header: "produk", accessor: "product", width: "30%" },
				{ Header: "harga", accessor: "amount", width: "30%" },
				{ Header: "komisi", accessor: "fee", width: "30%" },
				// { Header: "tagihan", accessor: "remaiderAmount", width: "30%" },
				{ Header: "status", accessor: "status", width: "20%" },
			],
			rows: [],
			keyword: "",
			isLoading: true,
		}
	}

	componentDidMount() {
		this.loadStatus()
		this.loadData()
	}

	loadStatus = () => {
		useAxios()
			.post(`${Config.ApiUrl}/trx/status`)
			.then(response => {
				const data = response.data
				const output = data.map(item => ({ id: item.id, label: item.name }))

				this.setState({
					statuses: output
				})
			})
	}

	loadData = (params) => {
		this.setState({ isLoading: true })
		const user = secureStorage.getItem('user')

		const statusId = params && params.statusId ? { statusId: params.statusId } : {}
		const payload = {
			currentPage: params && params.currentPage ? params.currentPage : 1,
			rowsPerPage: params && params.rowsPerPage ? params.rowsPerPage : this.state.rowsPerPage,
			startDate: params && params.startDate ? params.startDate : this.state.startDate,
			endDate: params && params.endDate ? params.endDate : this.state.endDate,
			...statusId,
		}

		useAxios()
			.post(`${Config.ApiUrl}/trx`, payload)
			.then(response => {
				const data = response.data
				const output = data.output.map(item => {
					const amount = parseInt(item.amount)
					const paidAmount = item.Bill ? parseInt(item.Bill.paidAmount) : 0
					const TrStatus = item.TrStatus
					const product = item.Product
					const productPrice = item.ProductPrice
					const customer = item.Customer
					const member = customer && customer.Member ? customer.Member : null

					return {
						action: [2, 3].includes(user.roleId) && [1].includes(TrStatus.id) && (
							<ButtonAction
								id={item.id}
								statusId={TrStatus && TrStatus.id ? TrStatus.id : null}
								refreshData={this.loadData}
							/>
						),
						kode: (
							<MDTypography
								variant="caption"
								fontWeight="medium"
								sx={{
									cursor: "pointer"
								}}
								color="info"
								component={Link}
								to={{ pathname: `/finance/trx/detail/${item.id}` }}
							>
								{item.kode}
							</MDTypography>
						),
						customer: (
							<MDBox style={{ wordWrap: "break-word", width: "13em" }}>
								<MDBox display="flex" flexDirection="column">
									<MDTypography variant="caption" fontWeight="regular">
										Kode: {member && member.kode ? member.kode : "-"}
									</MDTypography>
									<MDTypography variant="caption" fontWeight="regular">
										Nama: {member && member.name ? member.name : "-"}
									</MDTypography>
								</MDBox>
							</MDBox>
						),
						product: (
							<MDBox style={{ wordWrap: "break-word", width: "18em" }}>
								<MDBox display="flex" flexDirection="column">
									<MDTypography variant="caption" fontWeight="regular">
										Kode: {product && product.kode ? product.kode : "-"}
									</MDTypography>
									<MDTypography variant="caption" fontWeight="regular">
										Nama: {product && product.name ? product.name : "-"}
									</MDTypography>
								</MDBox>
							</MDBox>
						),
						amount: fnumber(amount),
						fee: productPrice ? fnumber(productPrice.fee) : 0,
						// remaiderAmount: fnumber(amount - paidAmount),
						status: TrStatus ? <MDBadge
							variant="contained"
							badgeContent={TrStatus.name}
							size="lg"
							color={TrStatus.id === 1 ? "warning" : TrStatus.id === 2 ? "secondary" : TrStatus.id === 3 ? "error" : "success"}
						/> : "-",
					}
				})
				this.setState({
					isLoading: false,
					totalPages: data.totalPages,
					totalData: data.totalData,
					rows: output,
				})
			})
			.catch(err => {
				console.log(err)
				this.setState({ isLoading: false })
			})
	}

	render() {
		const user = secureStorage.getItem('user')

		return (
			<MDBox py={3} my={3}>
				<Card>
					<MDBox p={2} lineHeight={1}>
						<MDTypography variant="h5" fontWeight="medium">
							Daftar Transaksi
						</MDTypography>
					</MDBox>

					{/* Filter */}
					<MDBox
						px={3}
						display="flex"
						justifyContent="flex-start"
						width="100%"
					>
						<Grid container spacing={3}>
							{/* Filter Status */}
							<Grid item xs={12} md={3} lg={3}>
								<Autocomplete
									value={this.state.status}
									options={this.state.statuses}
									onChange={(e, value) => {
										this.setState({ status: value })
										this.loadData({
											currentPage: 1,
											statusId: value ? value.id : null,
											startDate: this.state.startDate ? this.state.startDate : "",
											endDate: this.state.endDate ? this.state.endDate : "",
										})
									}}
									sx={{
										".MuiAutocomplete-input": {
											padding: "7.5px 5px 7.5px 8px !important"
										},
										".MuiOutlinedInput-root": {
											padding: "1.5px !important"
										}
									}}
									isOptionEqualToValue={(option, value) => option.id === value.id}
									renderInput={(params) => <MDInput sx={{ padding: "0px" }} fullWidth label="Pilih Status" {...params} />}
								/>
							</Grid>
							{/* Start Date */}
							<Grid item xs={12} md={3} lg={3}>
								<MDDatePicker
									input={{
										id: "startDate",
										placeholder: "Tanggal Awal",
										fullWidth: true,
										sx: {
											".MuiInputBase-input": {
												height: "1em !important"
											}
										}
									}}
									value={this.state.startDate}
									onChange={(value) => {
										const date = moment(value[0]).format('YYYY-MM-DD')
										this.setState({ startDate: date })
									}}
								/>
							</Grid>
							{/* End Date */}
							<Grid item xs={12} md={3} lg={3}>
								<MDDatePicker
									input={{
										id: "endDate",
										placeholder: "Tanggal Akhir",
										fullWidth: true,
										sx: {
											".MuiInputBase-input": {
												height: "1em !important"
											}
										}
									}}
									value={this.state.endDate}
									onChange={(value) => {
										const date = moment(value[0]).format('YYYY-MM-DD')
										this.setState({ endDate: date })
									}}
								/>
							</Grid>
							{/* Button Search */}
							<Grid item xs={12} md={3} lg={3}>
								<MDBox
									width="100%"
									display="flex"
									flexDirection="row"
									justifyContent="flex-start"
								>
									<MDButton
										color="info"
										variant="gradient"
										onClick={() => {
											const startDate = this.state.startDate ? this.state.startDate : ""
											const endDate = this.state.endDate ? this.state.endDate : ""
											this.loadData({
												currentPage: 1,
												startDate,
												endDate,
												statusId: this.state.status ? this.state.status.id : null,
											})
										}}
										iconOnly
									>
										<Icon>search</Icon>
									</MDButton>
									<MDBox ml={1}>
										<MDButton
											color="error"
											variant="gradient"
											onClick={() => {
												this.setState({
													startDate: "",
													endDate: "",
													currentPage: 1,
													status: null
												}, () => {
													this.loadData()
												})
											}}
											iconOnly
										>
											<Icon>refresh</Icon>
										</MDButton>
									</MDBox>
								</MDBox>
							</Grid>
						</Grid>
					</MDBox>

					<MDBox p={2}>
						<DataTable
							isLoading={this.state.isLoading}
							tableHead={this.state.tableHead}
							tableData={this.state.rows}
						/>
						<Pagination
							totalPages={this.state.totalPages}
							totalData={this.state.totalData}
							currentPage={this.state.currentPage}
							rowsPerPage={[10, 25, 50, "All"]}
							totalButton={3}
							defaultRowsPerPage={this.state.rowsPerPage}
							onChangeRowsPerPage={(value) => {
								this.setState({ rowsPerPage: value, currentPage: 1 });
								this.loadData({
									rowsPerPage: value,
									currentPage: 1,
									statusId: this.state.status ? this.state.status.id : null,
									startDate: this.state.startDate ? this.state.startDate : "",
									endDate: this.state.endDate ? this.state.endDate : "",
								});
							}}
							onChangePage={(currentPage) => {
								if (currentPage !== this.state.currentPage) {
									this.setState({ currentPage });
									this.loadData({
										currentPage,
										statusId: this.state.status ? this.state.status.id : null,
										startDate: this.state.startDate ? this.state.startDate : "",
										endDate: this.state.endDate ? this.state.endDate : "",
									});
								}
							}}
						/>
					</MDBox>
				</Card>
			</MDBox>
		)
	}
}

export default Transaction