/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { Link, Navigate } from "react-router-dom";
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";

// @mui icons
import { Close } from "@mui/icons-material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

import ModalNotif from "contents/Components/ModalNotif";
import DialogForm from "contents/Components/DialogForm";

import Config from "config";
import _, { find } from "lodash";

import useAxios from "libs/useAxios";
import secureStorage from "libs/secureStorage";

class DialogStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // nalai awal 
      statusId: null,
      statusName: "",

      productId: null,
      memberId: null,
      productName: "",
      departureDate: "",
      memberName: "",
      status: null,
      type: "",
      remark: "",

      isRemark: false,

      statuses: [],

      disabledSubmit: false,
    };

    this.modalNotifRef = React.createRef();
    this.dialogFormRef = React.createRef();
  }

  componentDidMount() { }

  loadStatus = () => {
    this.setState({ disabledSubmit: true })
    useAxios()
      .post(`${Config.ApiUrl}/product/getStatus`)
      .then((response) => {
        const data = response.data;
        const statuses = data.map((item) => ({ id: item.id, label: item.name }))
        // .filter(item => this.state.statusId <= item.id)
        const status = _.find(statuses, { label: this.state.statusName })

        console.log({ statuses, status })
        this.setState({
          disabledSubmit: false,
          status,
          statusId: status?.id,
          statusName: status?.label,
          statuses: [5, 6].includes(status.id) ? [status] : statuses,
        })
      })
      .catch((err) => {
        console.log(err);
        this.setState({ disabledSubmit: false });
      })
  };

  setShow = (data) => {
    const { statusName, productId, memberId, productName, departureDate, memberName, type } = data

    this.setState({
      productId,
      productName,
      departureDate,
      memberId,
      memberName,
      statusName,
      type,
    }, () => {
      this.loadStatus()
      this.dialogFormRef.current.setShow({ ...data, title: "Ubah Status Keberangkatan" });
    })
  };

  handleSubmit = () => {
    // console.log(`selected ID: ${this.state.status.id}, statusId : ${this.state.statusId}`)
    if (this.state.status?.id <= this.state.statusId) {
      this.modalNotifRef.current.setShow({
        modalTitle: "Peringatan",
        modalMessage: "Status keberangkatan tidak bisa di ubah, silahkan cek kembali",
      })
    } else {
      this.setState({ disabledSubmit: true });
      const remark = this.state.isRemark ? { remark: this.state.remark } : { remark: this.state.status?.label }
      const payload = {
        statusId: this.state.status ? this.state.status?.id : null,
        statusName: this.state.status ? this.state.status.label : "",
        productId: this.state.productId,
        userId: this.state.memberId,
        type: this.state.type,
        ...remark,
      }

      let url = [5, 6].includes(payload.statusId) ? "/trx/cancel" : "/product/changestatus"
      console.log({ url, payload })
      useAxios()
        .post(`${Config.ApiUrl}${url}`, payload)
        .then((response) => {
          this.modalNotifRef.current.setShow({
            modalTitle: "Sukses",
            modalMessage: response.data,
            onClose: () => {
              this.props.onAction();
              this.dialogFormRef.current.setShow({ show: false });
            },
          })
        })
        .catch((err) => {
          console.log(err);
          this.setState({ disabledSubmit: false });
          if (err.response) {
            this.modalNotifRef.current.setShow({
              modalTitle: "Gagal",
              modalMessage: err.response ? err.response.data : "Terjadi kesalahan pada system",
            });
          }
          // eslint-disable-next-line no-empty
          else {
            this.modalNotifRef.current.setShow({
              modalTitle: "Gagal",
              modalMessage: "Koneksi jaringan terputus",
            });
          }
        })
    }
  };

  render() {
    return (
      <DialogForm ref={this.dialogFormRef} maxWidth="xs">
        <ModalNotif ref={this.modalNotifRef} />
        <Grid container item xs={12} lg={12} sx={{ mx: "auto", mt: 1 }}>
          <MDBox width="100%" component="form">
            {/* Name Member */}
            {this.state.type == "member" && (
              <MDBox mb={2}>
                <MDInput
                  type="text"
                  label="Nama"
                  value={this.state.memberName}
                  disabled
                  fullWidth
                />
              </MDBox>
            )}
            {/* Product Name */}
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Paket"
                value={this.state.productName}
                disabled
                fullWidth
              />
            </MDBox>
            {/* Departure Date */}
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Tanggal Berangkat"
                value={this.state.departureDate}
                disabled
                fullWidth
              />
            </MDBox>
            {/* Departure Status */}
            <MDBox mb={2}>
              <Autocomplete
                disableClearable
                value={this.state.status}
                options={this.state.statuses}
                onChange={(e, newValue) => {
                  if (newValue) {
                    const isRemark = [5, 6].includes(newValue.id)
                      ? { isRemark: true }
                      : { isRemark: false };
                    this.setState({ status: newValue, ...isRemark });
                  }
                }}
                sx={{
                  input: {
                    padding: "7.5px 5px 7.5px 8px !important",
                  },
                  "& .MuiOutlinedInput-root": {
                    padding: "4px !important",
                  },
                }}
                // isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => <MDInput {...params} label="Status Keberangkatan" />}
              />
            </MDBox>
            {this.state.isRemark && (
              <MDBox mb={2}>
                <MDInput
                  rows={3}
                  multiline
                  type="text"
                  label="Keterangan"
                  value={this.state.remark}
                  onChange={(e) => this.setState({ remark: e.target.value })}
                  fullWidth
                />
              </MDBox>
            )}
          </MDBox>

          <MDBox
            py={3}
            width="100%"
            display="flex"
            justifyContent={{ md: "flex-end", xs: "center" }}
          >
            <MDBox mr={1}>
              <MDButton
                variant="gradient"
                color="error"
                onClick={() => this.dialogFormRef.current.setShow({ show: false })}
              >
                Tutup
              </MDButton>
            </MDBox>
            <MDButton
              variant="gradient"
              color="info"
              disabled={this.state.disabledSubmit}
              onClick={this.handleSubmit}
            >
              Submit
            </MDButton>
          </MDBox>
        </Grid>
      </DialogForm>
    );
  }
}

DialogStatus.propTypes = {
  onAction: PropTypes.func.isRequired,
};

export default DialogStatus;
