/* eslint-disable react/prop-types */
import React from 'react'
import { Link, Navigate } from 'react-router-dom';
// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from 'components/MDInput';
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import ModalNotif from 'contents/Components/ModalNotif';
import withRouter from 'contents/Components/withRouter';

import MiniFormCard from 'contents/Components/FormCard/MiniFormCard';

import Config from 'config'
import _ from "lodash"

import useAxios from "libs/useAxios"
import secureStorage from 'libs/secureStorage';

class FormTourLeader extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			id: null,
			user: null,
			title: "",
			action: "",

			name: "",
			address: "",
			phone: "",
			gender: "",
			status: null,

			statuses: [
				{ id: 1, label: "Aktif" },
				{ id: 2, label: "Tidak Aktif" }
			],

			genderOpen: false,
			statusOpen: false,

			photo: null,
			filename: "",

			error: [],
			success: [],

			disabledSubmit: false,
			redirect: null
		}

		this.inputRef = React.createRef()
		this.fileRef = React.createRef()
		this.modalNotifRef = React.createRef()
	}

	componentDidMount() {
		this.inputRef && this.inputRef.current.focus()
		const user = secureStorage.getItem('user')

		this.setState({
			user: user ? user : null
		})
		this.loadPath()
	}

	loadPath = () => {
		const pathname = window.location.pathname
		const index = pathname.indexOf('edit')
		if (index === -1) {
			this.setState({
				title: "Tambah Tour Leader Baru",
				action: "add"
			})
		}
		else {
			const id = this.props.params?.id
			this.loadDetail(id)
			this.setState({
				id,
				title: "Ubah Tour Leader",
				action: "edit",
				error: { ...this.state.error, name: false },
				success: { ...this.state.success, name: true }
			})
		}
	}

	loadDetail = (id) => {
		useAxios()
			.post(`${Config.ApiUrl}/master/tourleader/get`, { id })
			.then(response => {
				const data = response.data
				this.setState({
					name: data.name,
					phone: data.phone,
					address: data.address,
					gender: data.gender ? data.gender : "",
					status: data.isActive ? { id: 1, label: 'Aktif' } : { id: 2, label: 'Tidak Aktif' },

					success: {
						...this.state.success,
						name: data.name ? true : false,
						gender: data.gender ? true : false,
						phone: data.phone ? true : false,
						address: data.address ? true : false
					}
				})
			})
			.catch(err => {
				console.log(err)
				this.modalNotifRef.current.setShow({
					modalTitle: 'Gagal',
					modalMessage: err.response ? err.response.data : "Koneksi jaringan terputus",
					onClose: () => {
						this.setState({ redirect: '/master/tour-leader' })
					}
				})
			})
	}

	handleChange = (e) => {
		const { id, value } = e.target;
		this.setState({
			[id]: value
		})
	}

	handleBlur = (e) => {
		const user = this.state.user
		const { id, value } = e.target
		if (value) {
			this.setState({
				success: { ...this.state.success, [e.target.id]: true },
				error: { ...this.state.error, [e.target.id]: false }
			})
		}
		else {
			this.setState({
				success: { ...this.state.success, [e.target.id]: false },
				error: { ...this.state.error, [e.target.id]: true }
			})
		}
	}

	handleKeyDown = (e) => {
		// if (e.key === "Enter") this.handleSubmit()
	}

	handleSubmit = () => {
		const success = this.state.success

		console.log({ success })
		if (
			success.name &&
			success.gender &&
			success.phone &&
			success.address
		) {
			this.sendData()
		}
		else {
			this.modalNotifRef.current.setShow({
				modalTitle: "Gagal",
				modalMessage: "Data form masih belum lengkap, Silahkan di cek kembali !",
			})
		}
	}

	sendData = () => {
		this.setState({
			disabledSubmit: true
		})

		const user = this.state.user
		const status = this.state.status
		const formData = new FormData();

		formData.append('name', this.state.name)
		formData.append('gender', this.state.gender)
		formData.append('phone', this.state.phone)
		formData.append('address', this.state.address)
		formData.append('merchantId', user?.Merchant?.id)
		formData.append("isActive", status && status.id === 1 ? true : false)
		formData.append('photo', this.state.photo)
		formData.append('id', this.state.id)

		const url = this.state.action === "add" ? "/master/tourleader/create" : "/master/tourleader/update"
		useAxios()
			.post(`${Config.ApiUrl}${url}`, formData)
			.then(response => {
				this.modalNotifRef.current.setShow({
					modalTitle: "Sukses",
					modalMessage: response.data,
					onClose: () => {
						this.setState({
							redirect: '/master/tour-leader',
							disabledSubmit: false
						})
					}
				})
			})
			.catch(err => {
				this.setState({ disabledSubmit: false })
				if (err.response) {
					this.modalNotifRef.current.setShow({
						modalTitle: "Gagal",
						modalMessage: err.response ? err.response.data : "Terjadi kesalahan pada system",
					})
				}
				// eslint-disable-next-line no-empty
				else {
					this.modalNotifRef.current.setShow({
						modalTitle: 'Gagal',
						modalMessage: "Koneksi jaringan terputus",
					})
				}
			})
	}

	render() {
		if (this.state.redirect) {
			return <Navigate to={this.state.redirect} />
		}

		return (
			<MiniFormCard>
				<ModalNotif ref={this.modalNotifRef} />
				<MDBox display="flex" p={3} lineHeight={1} justifyContent="center">
					<MDTypography variant="h5" fontWeight="medium">
						{this.state.title}
					</MDTypography>
				</MDBox>

				<MDBox p={3}>
					<MDBox component="form" role="form">
						<Grid container spacing={3}>
							<Grid item xs={12} md={12} lg={12}>
								{/* NAME */}
								<MDBox mb={2}>
									<MDInput
										id="name"
										type="text"
										inputRef={this.inputRef}
										label="Nama"
										value={this.state.name}
										onChange={this.handleChange}
										onBlur={this.handleBlur}
										onKeyDown={this.handleKeyDown}
										success={this.state.success ? this.state.success.name : false}
										error={this.state.error ? this.state.error.name : false}
										fullWidth
									/>
								</MDBox>
								{/* PHONE */}
								<MDBox mb={2}>
									<MDInput
										id="phone"
										type="text"
										label="Nomor Telpon"
										value={this.state.phone}
										onChange={this.handleChange}
										onBlur={this.handleBlur}
										onKeyDown={this.handleKeyDown}
										success={this.state.success ? this.state.success.phone : false}
										error={this.state.error ? this.state.error.phone : false}
										fullWidth
									/>
								</MDBox>
								{/* GENDER */}
								<MDBox mb={2}>
									<Autocomplete
										disableClearable
										value={this.state.gender}
										open={this.state.genderOpen}
										onOpen={() => this.setState({ genderOpen: true })}
										onClose={() => this.setState({ genderOpen: false })}
										onFocus={() => this.setState({ genderOpen: true })}
										options={["Male", "Female"]}
										onChange={(e, value) => {
											if (value) {
												this.setState({
													gender: value,
													error: { ...this.state.error, gender: false },
													success: { ...this.state.success, gender: true }
												})
											}
										}}
										sx={{
											".MuiAutocomplete-input": {
												padding: "7.5px 5px 7.5px 8px !important"
											},
											".MuiOutlinedInput-root": {
												padding: "2px !important"
											}
										}}
										isOptionEqualToValue={(option, value) => option === value}
										renderInput={(params) => <MDInput label="Jenis Kelamin" {...params} />}
									/>
								</MDBox>
								{/* UPLOAD PHOTO */}
								<MDBox mb={2}>
									<input
										type="file"
										name="fileInput"
										ref={this.fileRef}
										onChange={(e) => {
											if (e.target.files.length === 1) {
												const file = e.target.files[0];
												const filename = file.name;
												const ext = filename.split(".")[1];
												this.setState({
													photo: file,
													filename
												})
											}
										}}
										hidden
									/>
									<MDInput
										fullWidth
										value={this.state.filename}
										label="Upload Foto"
										onClick={() => {
											this.fileRef.current.click()
										}}
										readOnly
									/>
								</MDBox>
								{/* ADDRESS */}
								<MDBox mb={2}>
									<MDInput
										id="address"
										type="text"
										multiline
										rows={3}
										label="Alamat"
										value={this.state.address}
										onChange={this.handleChange}
										onBlur={this.handleBlur}
										onKeyDown={this.handleKeyDown}
										success={this.state.success ? this.state.success.address : false}
										error={this.state.error ? this.state.error.address : false}
										fullWidth
									/>
								</MDBox>
								{/* STATUS */}
								<MDBox mb={2}>
									<Autocomplete
										disableClearable
										open={this.state.statusOpen}
										onOpen={() => this.setState({ statusOpen: true })}
										onClose={() => this.setState({ statusOpen: false })}
										onFocus={() => this.setState({ statusOpen: true })}
										options={this.state.statuses}
										value={this.state.status}
										onChange={(e, value) => {
											console.log({ value })
											if (value) {
												this.setState({
													status: value,
													error: { ...this.state.error, status: false },
													success: { ...this.state.success, status: true }
												})
											}
										}}
										sx={{
											".MuiAutocomplete-input": {
												padding: "7.5px 5px 7.5px 8px !important"
											},
											".MuiOutlinedInput-root": {
												padding: "4px !important"
											}
										}}
										isOptionEqualToValue={(option, value) => option.id === value.id}
										renderInput={(params) => <MDInput label="Status" {...params} />}
									/>
								</MDBox>

								<MDBox pt={3} display="flex" justifyContent="space-between">
									<MDButton
										variant="gradient"
										color="error"
										component={Link}
										to={{ pathname: "/master/tour-leader" }}
									>
										KEMBALI
									</MDButton>
									<MDButton
										type="button"
										variant="gradient"
										color="info"
										onKeyDown={this.handleKeyDown}
										disabled={this.state.disabledSubmit}
										onClick={this.handleSubmit}
									>
										SUBMIT
									</MDButton>
								</MDBox>
							</Grid>
						</Grid>
					</MDBox>
				</MDBox>
			</MiniFormCard>
		)
	}
}

export default withRouter(FormTourLeader)