import React, { useState } from "react";
import PropTypes from "prop-types";
import { Navigate, Link } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDAvatar from "components/MDAvatar";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import DataTable from 'contents/Components/DataTable'
import Pagination from "contents/Components/Pagination";
import ButtonAction from "contents/Administrasi/ListProduct/components/ButtonAction"

import useAxios from "libs/useAxios"
import Config from 'config'
import secureStorage from "libs/secureStorage";
import { fnumber } from "libs/currency";

class ListProduct extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			merchant: null,
			category: null,
			status: null,

			merchants: [],
			categories: [],
			statuses: [
				{ id: 1, label: 'BELUM BERANGKAT' },
				{ id: 2, label: 'BERANGKAT KE TANAH SUCI' },
				{ id: 3, label: 'TIBA DI TANAH SUCI' },
				{ id: 4, label: 'SUDAH PULANG' },
				{ id: 5, label: 'BATAL TERBANG' }
			],

			rowsPerPage: 10,
			currentPage: 1,
			totalPages: 0,
			totalData: 0,
			tableHead: [
				{ Header: "action", accessor: "action", width: "10%" },
				{ Header: "kode", accessor: "kode", width: "15%" },
				{ Header: "nama", accessor: "name", width: "25%" },
				{ Header: "jenis", accessor: "category", width: "20%" },
				{ Header: "jumlah perserta", accessor: "count_member", width: "15%" },
				{ Header: "sisa kuota", accessor: "quota", width: "20%" },
				{ Header: "status", accessor: "status", width: "20%" },
			],
			rows: [],
			keyword: "",
			isLoading: true,
		}
	}

	componentDidMount() {
		const user = secureStorage.getItem('user')

		this.loadCategory()
		this.loadData()
		user && user.roleId === 1 && this.loadMerchants()
	}

	loadMerchants = () => {
		useAxios()
			.post(`${Config.ApiUrl}/manage/merchant/dropdown`)
			.then(response => {
				const data = response.data

				this.setState({
					merchants: data.map(item => ({ id: item.id, label: item.name }))
				})
			})
			.catch(err => {
				console.log(err)
			})
	}

	loadCategory() {
		useAxios()
			.post(`${Config.ApiUrl}/product/category/dropdown`)
			.then(response => {
				const data = response.data;
				const categories = data.map(item => {
					return {
						id: item.id,
						label: item.name
					}
				});

				this.setState({ categories })
			})
	}

	loadData = (params) => {
		this.setState({ isLoading: true })
		const user = secureStorage.getItem('user')

		const statusId = params && params.statusId ? { statusId: params.statusId } : {}
		const merchantId = params && params.merchantId ? { merchantId: params.merchantId } : {}
		const productCategoryId = params && params.productCategoryId ? { productCategoryId: params.productCategoryId } : {}

		const payload = {
			keyword: params && params.keyword ? params.keyword : this.state.keyword,
			currentPage: params && params.currentPage ? params.currentPage : 1,
			rowsPerPage: params && params.rowsPerPage ? params.rowsPerPage : this.state.rowsPerPage,
			...productCategoryId,
			...merchantId,
			...statusId
		}

		useAxios()
			.post(`${Config.ApiUrl}/product`, payload)
			.then(response => {
				const data = response.data

				const output = data.output.map(item => {

					return {
						kode: (
							<MDTypography
								variant="caption"
								fontWeight="medium"
								sx={{
									cursor: "pointer"
								}}
								color="info"
								component={Link}
								to={{ pathname: `/adm/product/detail/${item.id}` }}
							>
								{item.kode}
							</MDTypography>
						),
						name: item.name,
						category: item.category ? item.category : "-",
						count_member: item.used,
						quota: item.seat - item.used,
						status: item.status,
						action: user && [2, 3].includes(user.roleId) ? (
							<ButtonAction id={item.id} />
						) : "-"
					}
				})

				this.setState({
					totalPages: data.totalPages,
					totalData: data.totalData,
					rows: output,
					isLoading: false
				})
			})
			.catch(err => {
				console.log(err)
				this.setState({ isLoading: false })
			})
	}

	render() {
		const user = secureStorage.getItem('user')

		return (
			<>
				<MDBox pb={3} my={3}>
					<Card>
						<MDBox p={2} lineHeight={1}>
							<MDTypography variant="h5" fontWeight="medium">
								Daftar Paket
							</MDTypography>
						</MDBox>
						<MDBox px={2} width="100%" display="flex" justifyContent="flex-start">
							<Grid container spacing={3}>
								<Grid item xs={12} md={3} lg={3}>
									<MDInput
										label="Search..."
										size="small"
										fullWidth
										value={this.state.keyword}
										onKeyPress={(e) => {
											if (e.key === "Enter") {
												this.loadData({
													currentPage: 1,
													keyword: e.target.value,
													productCategoryId: this.state.category ? this.state.category.id : null,
													merchantId: this.state.merchant ? this.state.merchant.id : null,
													statusId: this.state.status ? this.state.status.id : null,
												})
											}
										}}
										onChange={(e) => this.setState({ keyword: e.target.value })}
									/>
								</Grid>
								{/* Merchant */}
								{[1].includes(user.roleId) && (
									<Grid item xs={12} md={3} lg={3}>
										<Autocomplete
											value={this.state.merchant}
											options={this.state.merchants}
											onChange={(e, value) => {
												this.setState({ merchant: value }, () => {
													this.loadData({
														currentPage: 1,
														merchantId: value ? value.id : null,
														productCategoryId: this.state.category ? this.state.category.id : null,
													})
												})
											}}
											sx={{
												".MuiAutocomplete-input": {
													padding: "7.5px 5px 7.5px 8px !important"
												},
												".MuiOutlinedInput-root": {
													padding: "1.5px !important"
												}
											}}
											isOptionEqualToValue={(option, value) => option.id === value.id}
											renderInput={(params) => <MDInput sx={{ padding: "0px" }} fullWidth label="Pilih Biro Travel" {...params} />}
										/>
									</Grid>
								)}
								{/* Product Category */}
								<Grid item xs={12} md={3} lg={3}>
									<Autocomplete
										value={this.state.category}
										options={this.state.categories}
										onChange={(e, value) => {
											this.setState({ category: value })
											this.loadData({
												currentPage: 1,
												productCategoryId: value ? value.id : null,
												statusId: this.state.status ? this.state.status.id : null,
												merchantId: this.state.merchant ? this.state.merchant.id : null
											})
										}}
										sx={{
											".MuiAutocomplete-input": {
												padding: "7.5px 5px 7.5px 8px !important"
											},
											".MuiOutlinedInput-root": {
												padding: "1.5px !important"
											}
										}}
										isOptionEqualToValue={(option, value) => option.id === value.id}
										renderInput={(params) => <MDInput sx={{ padding: "0px" }} fullWidth label="Pilih Kategori" {...params} />}
									/>
								</Grid>
								{/* Status Departure */}
								<Grid item xs={12} md={3} lg={3}>
									<Autocomplete
										value={this.state.status}
										options={this.state.statuses}
										onChange={(e, value) => {
											this.setState({ status: value })
											this.loadData({
												currentPage: 1,
												statusId: value ? value.id : null,
												merchantId: this.state.merchant ? this.state.merchant.id : null,
												productCategoryId: this.state.category ? this.state.category.id : null,
											})
										}}
										sx={{
											".MuiAutocomplete-input": {
												padding: "7.5px 5px 7.5px 8px !important"
											},
											".MuiOutlinedInput-root": {
												padding: "1.5px !important"
											}
										}}
										isOptionEqualToValue={(option, value) => option.id === value.id}
										renderInput={(params) => <MDInput sx={{ padding: "0px" }} fullWidth label="Pilih Status" {...params} />}
									/>
								</Grid>
							</Grid>
						</MDBox>
						<MDBox p={2}>
							<DataTable
								isLoading={this.state.isLoading}
								tableHead={this.state.tableHead}
								tableData={this.state.rows}
							/>
							<Pagination
								totalPages={this.state.totalPages}
								totalData={this.state.totalData}
								currentPage={this.state.currentPage}
								rowsPerPage={[10, 25, 50, "All"]}
								totalButton={3}
								defaultRowsPerPage={this.state.rowsPerPage}
								onChangeRowsPerPage={(value) => {
									this.setState({ rowsPerPage: value, currentPage: 1 })
									this.loadData({
										rowsPerPage: value,
										currentPage: 1,
										statusId: this.state.status ? this.state.status.id : null,
										productCategoryId: this.state.category ? this.state.category.id : null,
										merchantId: this.state.merchant ? this.state.merchant.id : null
									})
								}}
								onChangePage={(currentPage) => {
									if (currentPage !== this.state.currentPage) {
										this.setState({ currentPage })
										this.loadData({
											currentPage,
											statusId: this.state.status ? this.state.status.id : null,
											productCategoryId: this.state.category ? this.state.category.id : null,
											merchantId: this.state.merchant ? this.state.merchant.id : null
										})
									}
								}}
							/>
						</MDBox>
					</Card>
				</MDBox>
			</>
		)
	}
}

export default ListProduct