/* eslint-disable react/prop-types */
import React, { Fragment } from "react"
import { Document, Page, Text, Image, View, StyleSheet, Font } from "@react-pdf/renderer"

import logo from "assets/img/logoApp.png"

import RobotoMonoRegular from "assets/fonts/RobotoMono/RobotoMono-Regular.ttf"
import RobotMonoBold from "assets/fonts/RobotoMono/RobotoMono-Bold.ttf"
import RobotoMonoBoldItalic from "assets/fonts/RobotoMono/RobotoMono-BoldItalic.ttf"
import RobotoMonoItalic from "assets/fonts/RobotoMono/RobotoMono-Italic.ttf"

import PaymentReceiptStyle from "helper/styles/paymentReceiptStyles"
import Config from "config";

// Font
Font.register({
	family: 'RobotoMono',
	format: 'truetype',
	fonts: [
		{ src: RobotoMonoRegular },
		{ src: RobotoMonoBoldItalic },
		{ src: RobotoMonoItalic },
		{ src: RobotMonoBold },
	]
});
// Create styles
const styles = StyleSheet.create(PaymentReceiptStyle);

class PdfPaymentReceipt extends React.Component {

	constructor(props) {
		super(props)
		this.state = {}
	}

	render() {
		const data = this.props.data
		return (
			<Document>
				<Page size="ID1" orientation="landscape" style={styles.page}>
					{/* HEADER */}
					<View style={styles.headerContainer}>
						{/* HEADER LEFT */}
						<View style={styles.headerLeft}>
							<Image style={styles.headerLogo} src={data.logo ? `${Config.ApiAsset}/merchant/${data.logo}` : logo} cache />
							<View style={styles.headerLeftDesc}>
								<Text style={styles.company}>{data.company}</Text>
								<Text style={styles.headerLeftLabel}>{data.address}</Text>
								<Text style={styles.headerLeftLabel}>Email: {data.email}</Text>
								<Text style={styles.headerLeftLabel}>Telpon: {data.phone}</Text>
							</View>
						</View>
						{/* HEADER RIGHT */}
						<View style={styles.headerRight}>
							<View style={styles.headerRightContainer}>
								<Text style={styles.headerNumber}>Nomor</Text>
								<Text style={{ width: "5%" }}>:</Text>
								<Text style={styles.headerRightLabel}>{data.kode}</Text>
							</View>
						</View>
					</View>
					{/* TITLE */}
					<View style={styles.titleContainer}>
						<Text style={styles.titleText}>{data.title}</Text>
					</View>

					<View style={styles.tableContainer}>
						{/* TABLE CONTENT */}
						<Text style={styles.tableTitle}>Sudah di terima dari</Text>
						<View style={styles.tableRow}>
							<Text style={styles.tableCellLabel}>Nama</Text>
							<Text style={styles.tableCellDivider}>:</Text>
							<Text style={{ ...styles.tableCellValue, fontWeight: "bold" }}>{data.customerName}</Text>
						</View>
						<View style={styles.tableRow}>
							<Text style={styles.tableCellLabel}>Jumlah</Text>
							<Text style={styles.tableCellDivider}>:</Text>
							<Text style={{ ...styles.tableCellValue, fontWeight: "bold" }}>{data.amount}</Text>
						</View>
						<View style={styles.tableRow}>
							<Text style={styles.tableCellLabel}>Keterangan</Text>
							<Text style={styles.tableCellDivider}>:</Text>
							<Text style={styles.tableCellValue}>{data.remark}</Text>
						</View>

						{/* FOOTER */}
						<View style={styles.tableFooter}>
							<View style={{ ...styles.tableFooterBox, width: "50%" }}>
								<View style={styles.tableFooterLeft}>
									<Text style={styles.labelAmount}>Nominal</Text>
									<Text style={styles.amount}>{data.amount}</Text>
								</View>
							</View>
							<View style={styles.tableFooterBox}>
								<Text style={styles.tableFooterLabel}>{data.date}</Text>
								<View style={styles.tableFooterItem}>
									<Text>(</Text>
									<Text>)</Text>
								</View>
							</View>
						</View>
					</View>
				</Page>
			</Document>
		)
	}
}

export default PdfPaymentReceipt