/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import ModalNotif from "contents/Components/ModalNotif";
import MiniFormCard from "contents/Components/FormCard/MiniFormCard";

import Config from "config";

import useAxios from "libs/useAxios";
import secureStorage from "libs/secureStorage";

class SocialMedia extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: null,

      tagline: "",
      phone: "",
      email: "",
      address: "",
      excerpt: "",
      message: "",
      facebook: "",
      twitter: "",
      instagram: "",

      error: [],
      success: [],

      disabledSubmit: false,
    };

    this.modalNotifRef = React.createRef();
    this.inputRef = React.createRef();
  }

  componentDidMount() {
    this.inputRef.current && this.inputRef.current.focus();
    const user = secureStorage.getItem("user");
    this.setState({ userId: user.id });
    this.loadData(user.id);
  }

  loadData = (userId) => {
    useAxios()
      .post(`${Config.ApiUrl}/user/profile`, { userId })
      .then((response) => {
        const data = response.data;

        this.setState({
          tagline: data && data.tagline ? data.tagline : "",
          phone: data && data.phone ? data.phone : "",
          email: data && data.email ? data.email : "",
          address: data && data.address ? data.address : "",
          excerpt: data && data.excerpt ? data.excerpt : "",
          message: data && data.message ? data.message : "",
          facebook: data && data.facebook ? data.facebook : "",
          twitter: data && data.twitter ? data.twitter : "",
          instagram: data && data.instagram ? data.instagram : ""
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleChange = (e) => {
    const { id, value } = e.target;
    this.setState({
      [id]: value,
    });
  };

  handleBlur = (e) => {
    const user = this.state.user;
    const { id, value } = e.target;
    // eslint-disable-next-line no-empty
    if (value) {
      this.setState({
        success: { ...this.state.success, [e.target.id]: true },
        error: { ...this.state.error, [e.target.id]: false },
      });
    } else {
      this.setState({
        success: { ...this.state.success, [e.target.id]: false },
        error: { ...this.state.error, [e.target.id]: true },
      });
    }
  };

  handleSumit = () => {
    if (
      this.state.tagline === "" ||
      this.state.phone === "" ||
      this.state.email === "" ||
      this.state.address === "" ||
      this.state.excerpt === "" ||
      this.state.message === "" ||
      this.state.facebook === "" ||
      this.state.twitter === "" ||
      this.state.instagram === ""
    ) {
      this.modalNotifRef.current.setShow({
        modalTitle: "Gagal",
        modalMessage: "Data form masih belum lengkap, Silahkan di cek kembali!",
      });
    } else {
      this.setState({ disabledSubmit: true });
      const user = secureStorage.getItem("user");

      const payload = {
        tagline: this.state.tagline,
        phone: this.state.phone,
        email: this.state.email,
        address: this.state.address,
        excerpt: this.state.excerpt,
        message: this.state.message,
        facebook: this.state.facebook,
        twitter: this.state.twitter,
        instagram: this.state.instagram,
        userId: user && user.id,
      };

      useAxios()
        .post(`${Config.ApiUrl}/user/profile/set`, payload)
        .then((response) => {
          this.setState({ disabledSubmit: false });
          this.modalNotifRef.current.setShow({
            modalTitle: "Sukses",
            modalMessage: response.data,
          });
        })
        .catch((err) => {
          console.log(err);
          this.setState({ disabledSubmit: false });
          if (err.response) {
            this.modalNotifRef.current.setShow({
              modalTitle: "Gagal",
              modalMessage: err.response.data,
            });
          }
          // eslint-disable-next-line no-empty
          else {
            this.modalNotifRef.current.setShow({
              modalTitle: "Gagal",
              modalMessage: "Koneksi jaringan terputus",
            });
          }
        });
    }
  };

  render() {
    return (
      <>
        <ModalNotif ref={this.modalNotifRef} />
        <MiniFormCard>
          <MDBox p={3}>
            <MDTypography variant="h5">Data Profile</MDTypography>
          </MDBox>
          <MDBox component="form" pb={3} px={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12}>
                <MDBox mb={2}>
                  <MDInput
                    id="tagline"
                    type="text"
                    label="Tagline"
                    inputRef={this.inputRef}
                    value={this.state.tagline}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    fullWidth
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    id="phone"
                    type="text"
                    label="Nomor Telpon"
                    value={this.state.phone}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    fullWidth
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    id="email"
                    type="text"
                    label="Email"
                    value={this.state.email}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    fullWidth
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    id="address"
                    type="text"
                    label="Alamat"
                    value={this.state.address}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    fullWidth
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    id="excerpt"
                    type="text"
                    label="Kutipan"
                    value={this.state.excerpt}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    fullWidth
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    id="message"
                    type="text"
                    label="Pesan"
                    multiline
                    rows={5}
                    value={this.state.message}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    fullWidth
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    id="facebook"
                    type="text"
                    label="Link Facebook"
                    value={this.state.facebook}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    fullWidth
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    id="twitter"
                    type="text"
                    label="Link Twitter"
                    value={this.state.twitter}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    fullWidth
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    id="instagram"
                    type="text"
                    label="Link Istagram"
                    value={this.state.instagram}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    fullWidth
                  />
                </MDBox>
              </Grid>
            </Grid>

            <MDBox width="100%" display="flex" justifyContent="flex-end" flexWrap="wrap">
              <MDButton
                variant="gradient"
                color="dark"
                size="small"
                disabled={this.state.disabledSubmit}
                onClick={this.handleSumit}
              >
                UPDATE
              </MDButton>
            </MDBox>
          </MDBox>
        </MiniFormCard>
      </>
    );
  }
}

export default SocialMedia;
