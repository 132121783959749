/* eslint-disable react/prop-types */
import React from "react"
import { Link, Navigate, useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

// Material Dashboard 2 PRO React examples
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";

import withRouter from 'contents/Components/withRouter';
import Loader from "contents/Components/Loader"
import ModalNotif from "contents/Components/ModalNotif";
import ButtonBack from "contents/Components/ButtonBack";

import ListHotel from "contents/Components/ListHotel"
import ListPrice from "contents/Components/ListPrice"
import ListAirline from "contents/Components/ListAirline";
import ListItem from "contents/Components/ListItem"

import Config from 'config'
import _ from "lodash"
import moment from "moment"
import useAxios from "libs/useAxios"
import secureStorage from 'libs/secureStorage';
import { fnumber, formatCurrency } from "libs/currency";

class TourPackageDetail extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			id: null,
			isLoading: true,

			kode: "",
			name: "",
			seat: 0,
			used: 0,
			createdAt: "",
			days: "",
			departureDate: "",
			arrivalDate: "",
			description: "",

			product: null,
			productCategory: null,
			departureStatus: null,
			tourLeader: null,
			// Airport
			airportDeparture: null,
			airportReturn: null,

			productPrices: [],
			// productHotels: [],
			// productAirlines: [],
			// productAddons: [],
			// productDiscounts: [],

			redirect: null
		}

		this.buttonBackRef = React.createRef()
		this.modalNotifRef = React.createRef()
	}

	componentDidMount() {
		const id = this.props.params?.id
		this.setState({ id }, () => this.loadData(id))
	}

	loadData = (id) => {
		useAxios()
			.post(`${Config.ApiUrl}/product/get`, { id })
			.then(response => {
				const data = response.data
				const airportDeparture = data.AirportDeparture ? data.AirportDeparture : null
				const airportReturn = data.AirportReturn ? data.AirportReturn : null
				const tourleader = data.TourLeader ? data.TourLeader : null
				const productCategory = data.ProductCategory ? data.ProductCategory : null
				const departureStatus = data && data.DepartureStatus ? data.DepartureStatus : null
				const productHotels = data.ProductHotels.map(item => {
					return {
						id: item.id,
						duration: item.duration,
						name: item.name,
						location: item.location,
						rating: item.rating
					}
				})
				const productPrices = data.ProductPrices.map(item => ({
					amount: parseInt(item.amount),
					downPayment: parseInt(item.downPayment),
					roomType: item.RoomType ? item.RoomType.name : ""
				}))
				// const productAirlines = data.ProductAirlines.map((item, i) => ({
				// 	no: i + 1,
				// 	name: item.name,
				// 	direction: item.direction,
				// 	flightNumber: item.flightNumber
				// }))
				// const productAddons = data.Addons.map(item => ({
				// 	amount: item.ProductAddon && item.ProductAddon.amount ? item.ProductAddon.amount : "",
				// 	remark: item.ProductAddon?.remark,
				// 	row: {
				// 		id: item.id,
				// 		name: item.name
				// 	}
				// }))
				// const productDiscounts = data.Discounts.map(item => ({
				// 	amount: item.ProductDiscount && item.ProductDiscount.amount ? item.ProductDiscount.amount : "",
				// 	remark: item.ProductDiscount?.remark,
				// 	row: {
				// 		id: item.id,
				// 		name: item.name
				// 	}
				// }))

				this.setState({
					isLoading: false,
					kode: data.kode ? data.kode : "-",
					name: data.name ? data.name : "-",
					seat: data.seat ? data.seat : 0,
					used: data.used ? data.used : 0,
					createdAt: data.createdAt ? data.createdAt : "-",
					days: data.days ? data.days : "-",
					departureDate: data.departureDate ? data.departureDate : "-",
					arrivalDate: data.arrivalDate ? data.arrivalDate : "-",
					description: data.description ? data.description : "-",
					tourleader,
					productCategory,
					departureStatus,
					airportDeparture,
					airportReturn,
					productHotels,
					productPrices,
					// productAirlines,
					// productAddons,
					// productDiscounts
				})
			})
			.catch(err => {
				console.log(err)
				this.setState({ isLoading: false })
				this.modalNotifRef.current.setShow({
					modalTitle: 'Gagal',
					modalMessage: err.response ? err.response.data : "Koneksi jaringan terputus",
					onClose: () => {
						this.buttonBackRef.current.click()
					}
				})
			})
	}

	render() {
		const airportDeparture = this.state.airportDeparture
		const airportReturn = this.state.airportReturn
		if (this.state.redirect) {
			return <Navigate to={this.state.redirect} />
		}

		return (
			<>
				<ModalNotif ref={this.modalNotifRef} />
				<MDBox pt={3} pb={2} display="flex">
					<MDBox mr={1}>
						<ButtonBack label={"KEMBALI"} ref={this.buttonBackRef} />
					</MDBox>
				</MDBox>
				<Card sx={{ overflow: "visible" }}>
					{this.state.isLoading ? <Loader open={true} /> : (
						<MDBox p={3}>
							<Grid container spacing={3} mb={3}>
								<Grid item xs={12} md={12} lg={12}>
									<MDBox width="100%" display="flex" justifyContent="center">
										<MDTypography variant="h5" textTransform="capitalize" fontWeight="medium">
											DETAIL PAKET
										</MDTypography>
									</MDBox>
								</Grid>
							</Grid>

							<Grid container spacing={3}>
								<Grid container spacing={3} item xs={12} md={12} lg={12} mt={2}>
									<Table>
										<TableBody>
											{/* Kode && Name */}
											<TableRow>
												<DataTableBodyCell noBorder>
													<MDTypography variant="body" fontWeight="medium">
														Kode
													</MDTypography>
												</DataTableBodyCell>
												<DataTableBodyCell noBorder>
													<MDTypography variant="body">
														{this.state.kode}
													</MDTypography>
												</DataTableBodyCell>

												<DataTableBodyCell noBorder>
													<MDTypography variant="body" fontWeight="medium">
														Nama
													</MDTypography>
												</DataTableBodyCell>
												<DataTableBodyCell noBorder>
													<MDTypography variant="body">
														{this.state.name}
													</MDTypography>
												</DataTableBodyCell>
											</TableRow>

											{/* Seat && Used */}
											<TableRow>
												<DataTableBodyCell noBorder>
													<MDTypography variant="body" fontWeight="medium">
														Kursi Tersedia
													</MDTypography>
												</DataTableBodyCell>
												<DataTableBodyCell noBorder>
													<MDTypography variant="body">
														{this.state.seat - this.state.used}
													</MDTypography>
												</DataTableBodyCell>

												<DataTableBodyCell noBorder>
													<MDTypography variant="body" fontWeight="medium">
														Jumlah Jamaah
													</MDTypography>
												</DataTableBodyCell>
												<DataTableBodyCell noBorder>
													<MDTypography variant="body">
														{this.state.used}
													</MDTypography>
												</DataTableBodyCell>
											</TableRow>

											{/*  DepartureDate && ArrivalDate */}
											<TableRow>
												<DataTableBodyCell noBorder>
													<MDTypography variant="body" fontWeight="medium">
														Tanggal Berangkat
													</MDTypography>
												</DataTableBodyCell>
												<DataTableBodyCell noBorder>
													<MDTypography variant="body">
														{this.state.departureDate}
													</MDTypography>
												</DataTableBodyCell>

												<DataTableBodyCell noBorder>
													<MDTypography variant="body" fontWeight="medium">
														Tanggal Pulang
													</MDTypography>
												</DataTableBodyCell>
												<DataTableBodyCell noBorder>
													<MDTypography variant="body">
														{this.state.arrivalDate}
													</MDTypography>
												</DataTableBodyCell>
											</TableRow>

											{/*  Days && ProductCategory */}
											<TableRow>
												<DataTableBodyCell noBorder>
													<MDTypography variant="body" fontWeight="medium">
														Jumlah Hari
													</MDTypography>
												</DataTableBodyCell>
												<DataTableBodyCell noBorder>
													<MDTypography variant="body">
														{this.state.days} Hari
													</MDTypography>
												</DataTableBodyCell>

												<DataTableBodyCell noBorder>
													<MDTypography variant="body" fontWeight="medium">
														Jenis Paket
													</MDTypography>
												</DataTableBodyCell>
												<DataTableBodyCell noBorder>
													<MDTypography variant="body">
														{this.state.productCategory && this.state.productCategory.name ? this.state.productCategory.name : "-"}
													</MDTypography>
												</DataTableBodyCell>
											</TableRow>

											{/* Airport Departure & Return */}
											<TableRow>
												<DataTableBodyCell noBorder>
													<MDTypography variant="body" fontWeight="medium">
														Bandara Berangkat
													</MDTypography>
												</DataTableBodyCell>
												<DataTableBodyCell noBorder>
													<MDBox
														width="100%"
														display="flex"
														flexDirection="column"
														alignItems="flex-start"
														justifyContent="center"
														style={{ wordWrap: "break-word", width: "20em" }}>
														<MDTypography variant="body">
															Maskapai &nbsp;
															<MDTypography variant="body" fontWeight="medium">
																{airportDeparture && airportDeparture.airline ? airportDeparture.airline : ''}
															</MDTypography>
														</MDTypography>
														<MDTypography variant="body">
															Dari &nbsp;
															<MDTypography variant="body" fontWeight="medium">
																{airportDeparture && airportDeparture.from ? airportDeparture.from : ''}
															</MDTypography>
														</MDTypography>
														<MDTypography variant="body">
															Ke &nbsp;
															<MDTypography variant="body" fontWeight="medium">
																{airportDeparture && airportDeparture.to ? airportDeparture.to : ''}
															</MDTypography>
														</MDTypography>
													</MDBox>
												</DataTableBodyCell>

												<DataTableBodyCell noBorder>
													<MDTypography variant="body" fontWeight="medium">
														Bandara Pulang
													</MDTypography>
												</DataTableBodyCell>
												<DataTableBodyCell noBorder>
													<MDBox
														width="100%"
														display="flex"
														flexDirection="column"
														alignItems="flex-start"
														justifyContent="center"
														style={{ wordWrap: "break-word", width: "20em" }}>
														<MDTypography variant="body">
															Maskapai &nbsp;
															<MDTypography variant="body" fontWeight="medium">
																{airportReturn && airportReturn.airline ? airportReturn.airline : ''}
															</MDTypography>
														</MDTypography>
														<MDTypography variant="body">
															Dari &nbsp;
															<MDTypography variant="body" fontWeight="medium">
																{airportReturn && airportReturn.from ? airportReturn.from : ''}
															</MDTypography>
														</MDTypography>
														<MDTypography variant="body">
															Ke &nbsp;
															<MDTypography variant="body" fontWeight="medium">
																{airportReturn && airportReturn.to ? airportReturn.to : ''}
															</MDTypography>
														</MDTypography>
													</MDBox>
												</DataTableBodyCell>
											</TableRow>

											{/* Status Product */}
											<TableRow>
												<DataTableBodyCell noBorder>
													<MDTypography variant="body" fontWeight="medium">
														Status
													</MDTypography>
												</DataTableBodyCell>
												<DataTableBodyCell noBorder>
													<MDTypography variant="body">
														{this.state.departureStatus && this.state.departureStatus.name ? this.state.departureStatus.name : '-'}
													</MDTypography>
												</DataTableBodyCell>
											</TableRow>

											{/* List Hotel */}
											<TableRow>
												<DataTableBodyCell noBorder>
													<MDTypography variant="body" fontWeight="medium">
														Hotel
													</MDTypography>
												</DataTableBodyCell>
												<TableCell colSpan={3} sx={{ borderBottom: "none" }}>
													<ListHotel type="view" rows={this.state.productHotels} />
												</TableCell>
											</TableRow>

											{/* List Maskapai
											<TableRow>
												<DataTableBodyCell noBorder>
													<MDTypography variant="body" fontWeight="medium">
														Maskapai
													</MDTypography>
												</DataTableBodyCell>
												<TableCell colSpan={3} sx={{ borderBottom: "none" }}>
													<ListAirline rows={this.state.productAirlines} />
												</TableCell>
											</TableRow> */}

											{/* List Price */}
											<TableRow>
												<DataTableBodyCell noBorder>
													<MDTypography variant="body" fontWeight="medium">
														Daftar Harga
													</MDTypography>
												</DataTableBodyCell>
												<TableCell colSpan={3} sx={{ borderBottom: "none" }}>
													<ListPrice rows={this.state.productPrices} />
												</TableCell>
											</TableRow>

											{/* Product Addon
											<TableRow>
												<DataTableBodyCell noBorder>
													<MDTypography variant="body" fontWeight="medium">
														Biaya Tambahan
													</MDTypography>
												</DataTableBodyCell>
												<TableCell colSpan={3} sx={{ borderBottom: "none" }}>
													<ListItem type={"view"} rows={this.state.productAddons} />
												</TableCell>
											</TableRow> */}

											{/* Product Discount
											<TableRow>
												<DataTableBodyCell noBorder>
													<MDTypography variant="body" fontWeight="medium">
														Diskon
													</MDTypography>
												</DataTableBodyCell>
												<TableCell colSpan={3} sx={{ borderBottom: "none" }}>
													<ListItem type={"view"} rows={this.state.productDiscounts} />
												</TableCell>
											</TableRow> */}

										</TableBody>
									</Table>
								</Grid>
							</Grid>
						</MDBox>
					)}
				</Card>
			</>
		)
	}
}

export default withRouter(TourPackageDetail)