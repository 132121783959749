/* eslint-disable react/prop-types */
import React from "react"
import PropTypes from "prop-types"
// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDDatePicker from "components/MDDatePicker";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import ModalNotif from 'contents/Components/ModalNotif';
import MiniFormCard from "contents/Components/FormCard/MiniFormCard";

import Config from 'config'
import moment from 'moment'

import useAxios from "libs/useAxios"
import secureStorage from "libs/secureStorage";

class CardIdentity extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			userId: null,

			name: "",
			number: "",
			effectiveDate: "",
			expiredDate: "",
			passportPlace: "",
			passportDate: "",

			action: null,
			disabledSubmit: false
		}

		this.inputRef = React.createRef()
		this.modalNotifRef = React.createRef()
	}

	componentDidMount() {
		this.inputRef.current && this.inputRef.current.focus()
		const user = secureStorage.getItem('user')
		this.setState({
			userId: user.id
		})
		this.loadData(user.id)
	}

	componentDidUpdate(prevProp, prevState) {
		if (prevProp.view !== this.props.view) {
			this.loadData(this.state.userId)
		}
	}

	loadData = (userId) => {
		const view = this.props.view
		const url = view === "passport" ? "/user/passport/get" : "/user/visa"
		useAxios()
			.post(`${Config.ApiUrl}${url}`, { userId })
			.then(response => {
				const data = response.data

				this.setState({
					name: data && data.name ? data.name : "",
					action: data ? "update" : "create",
					number: data && data.number ? data.number : "",
					effectiveDate: data && data.effectiveDate ? data.effectiveDate : "",
					expiredDate: data && data.expiredDate ? data.expiredDate : "",
					passportPlace: data && data.passportPlace ? data.passportPlace : "",
					passportDate: data && data.passportDate ? data.passportDate : ""
				})
			})
			.catch(err => {
				console.log(err)
			})
	}

	handleSumit = () => {
		const view = this.props.view

		if (
			this.state.number === "" ||
			this.state.effectiveDate === "" ||
			this.state.expiredDate === ""
		) {
			this.modalNotifRef.current.setShow({
				modalTitle: "Gagal",
				modalMessage: "Data form masih belum lengkap, Silahkan di cek kembali !",
			})
		}
		else {
			this.setState({ disabledSubmit: true })
			const payload = {
				name: this.state.name,
				number: this.state.number,
				effectiveDate: this.state.effectiveDate,
				expiredDate: this.state.expiredDate,
				action: this.state.action,
				userId: this.state.userId,
				passportDate: this.state.passportDate,
				passportPlace: this.state.passportPlace,
			}

			const url = view === "passport" ? "/user/changepassport" : `/user/visa/${this.state.action}`
			useAxios()
				.post(`${Config.ApiUrl}${url}`, payload)
				.then(response => {
					this.setState({ disabledSubmit: false })
					this.modalNotifRef.current.setShow({
						modalTitle: "Sukses",
						modalMessage: response.data,
					})
				})
				.catch(err => {
					console.log(err)
					this.setState({ disabledSubmit: false })
					if (err.response) {
						this.modalNotifRef.current.setShow({
							modalTitle: "Gagal",
							modalMessage: err.response.data,
						})
					}
					// eslint-disable-next-line no-empty
					else {
						this.modalNotifRef.current.setShow({
							modalTitle: 'Gagal',
							modalMessage: "Koneksi jaringan terputus",
						})
					}
				})
		}
	}

	render() {
		const view = this.props.view
		return (
			<>
				<ModalNotif ref={this.modalNotifRef} />
				<MiniFormCard>
					<MDBox p={3}>
						<MDTypography variant="h5">{this.props.title}</MDTypography>
					</MDBox>
					<MDBox component="form" pb={3} px={3}>
						<Grid container spacing={3}>
							<Grid item xs={12} md={12} lg={12}>
								<MDBox mb={2}>
									<MDInput
										type="text"
										inputRef={this.inputRef}
										id={view === "passport" ? "namePassport" : "nameVisa"}
										label="Nama"
										value={this.state.name}
										onChange={(e) => this.setState({ name: e.target.value })}
										onBlur={this.handleBlur}
										fullWidth
									/>
								</MDBox>
								<MDBox mb={2}>
									<MDInput
										type="text"
										id={view === "passport" ? "numberPassport" : "numberVisa"}
										label={`Nomor ${view === "passport" ? "Passport" : "Visa"}`}
										value={this.state.number}
										onChange={(e) => this.setState({ number: e.target.value })}
										onBlur={this.handleBlur}
										fullWidth
									/>
								</MDBox>
								{ view == "passport" && (
									<>
									{/* Place Passport */}
                  <MDBox mb={2}>
                    <MDInput
                      id="passportPlace"
                      type="text"
                      label="Tempat Terbit"
                      value={this.state.passportPlace}
                      onChange={(e) => this.setState({ passportPlace: e.target.value })}
                      onBlur={this.handleBlur}
                      fullWidth
                    />
                  </MDBox>
                  {/* Passport Date */}
                  <MDBox mb={2}>
                    <MDDatePicker
                      input={{
                        id: "passportDate",
                        placeholder: "Tanggal Pembuatan Paspor",
                        fullWidth: true,
                      }}
                      value={this.state.passportDate}
                      onChange={(value) => {
                        const date = moment(value[0]).format("YYYY-MM-DD");
                        this.setState({
                          passportDate: date,
                        });
                      }}
                    />
                  </MDBox>
									</>
								)}
								<MDBox mb={2}>
									<MDDatePicker
										input={{
											placeholder: `Tanggal Terbit ${view === "passport" ? "Passpor" : "Visa"}`,
											fullWidth: true
										}}
										value={this.state.effectiveDate}
										onChange={(value) => {
											const date = moment(value[0]).format('YYYY-MM-DD')
											this.setState({ effectiveDate: date })
										}}
									/>
								</MDBox>
								<MDBox mb={2}>
									<MDDatePicker
										input={{
											placeholder: `Tanggal Kadaluarsa ${view === "passport" ? "Paspor" : "Visa"}`,
											fullWidth: true
										}}
										value={this.state.expiredDate}
										onChange={(value) => {
											const date = moment(value[0]).format('YYYY-MM-DD')
											this.setState({ expiredDate: date })
										}}
									/>
								</MDBox>
							</Grid>
						</Grid>
						<MDBox width="100%" display="flex" justifyContent="flex-end" flexWrap="wrap">
							<MDButton
								variant="gradient"
								color="dark"
								size="small"
								disabled={this.state.disabledSubmit}
								onClick={this.handleSumit}
							>
								UPDATE
							</MDButton>
						</MDBox>
					</MDBox>
				</MiniFormCard>
			</>
		)
	}
}

CardIdentity.propTypes = {
	title: PropTypes.string.isRequired,
	data: PropTypes.object,
	view: PropTypes.oneOf(['passport', 'visa']).isRequired
}

export default CardIdentity