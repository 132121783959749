/* eslint-disable react/prop-types */
import React from 'react'
import { Link, Navigate } from 'react-router-dom';
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDBadge from "components/MDBadge";
import MDTypography from "components/MDTypography";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

// Material Dashboard 2 PRO React examples
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import withRouter from 'contents/Components/withRouter';
import Loader from 'contents/Components/Loader';
import ModalNotif from 'contents/Components/ModalNotif';

import useAxios from "libs/useAxios"
import Config from "config"
import secureStorage from 'libs/secureStorage';

class DetailUser extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			user: null,
			id: null,
			isLoading: true,

			kode: "-",
			name: "-",
			email: "-",
			phone: "-",
			address: "-",
			status: null,

			role: "-",
			merchant: "-",
			discrict: null,
			province: "-",
			country: null,
		}

		this.modalNotifRef = React.createRef()
	}

	componentDidMount() {
		const id = this.props.params?.id
		this.setState({ id })
		this.loadDetail(id)
	}

	loadDetail = (id) => {
		useAxios()
			.post(`${Config.ApiUrl}/user/detail`, { id })
			.then(response => {
				const data = response.data
				console.log(data)

				this.setState({
					isLoading: false,
					kode: data.kode,
					name: data.name,
					address: data.address,
					email: data.email ? data.email : "-",
					phone: data.phone ? data.phone : "-",
					status: data.isActive,
					province: data.Province ? data.Province : null,
					role: data.Role && data.Role.name ? data.Role.name : "",
					merchant: data.Merchant && data.Merchant.name ? data.Merchant.name : "",
				})
			})
			.catch(err => {
				console.log(err)
				this.modalNotifRef.current.setShow({
					modalTitle: 'Gagal',
					modalMessage: "Koneksi jaringan terputus",
				})
			})
	}

	render() {
		const user = secureStorage.getItem('user')
		const status = this.state.status

		return (
			<MDBox pb={3} my={3}>
				<ModalNotif ref={this.modalNotifRef} />
				<MDBox py={1} mb={2} display="flex">
					<MDBox mr={1}>
						<MDButton
							variant="gradient"
							color="error"
							component={Link}
							to={{ pathname: "/manage/user" }}
						>
							KEMBALI
						</MDButton>
					</MDBox>
					{user && [1, 2].includes(user.roleId) && (
						<MDButton
							variant="gradient"
							color="info"
							component={Link}
							to={{ pathname: `/manage/user/edit/${this.state.id}` }}
						>
							UBAH DATA
						</MDButton>
					)}
				</MDBox>

				<Card>
					<MDBox p={2}>
						<Grid container spacing={3} item xs={12} md={12} lg={12} mb={5} pt={2}>
							<Table>
								<TableBody>
									{/* KODE && EMAIL */}
									<TableRow>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body" fontWeight="medium">
												Kode User
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body">
												{this.state.kode ? this.state.kode : "-"}
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body" fontWeight="medium">
												Email
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body">
												{this.state.email ? this.state.email : "-"}
											</MDTypography>
										</DataTableBodyCell>
									</TableRow>
									{/* NAME & PHONE */}
									<TableRow>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body" fontWeight="medium">
												Nama
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body">
												{this.state.name ? this.state.name : "-"}
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body" fontWeight="medium">
												Nomor Telpon
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body">
												{this.state.phone ? this.state.phone : "-"}
											</MDTypography>
										</DataTableBodyCell>
									</TableRow>
									{/* PROVINCE & ADDRESS */}
									<TableRow>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body" fontWeight="medium">
												Provinsi
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body">
												{this.state.province && this.state.province.name ? this.state.province.name.toUpperCase() : "-"}
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body" fontWeight="medium">
												Alamat
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body">
												{this.state.address ? this.state.address : "-"}
											</MDTypography>
										</DataTableBodyCell>
									</TableRow>
									{/* BIRO & ROLE */}
									<TableRow>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body" fontWeight="medium">
												Biro Travel
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body">
												{this.state.merchant}
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body" fontWeight="medium">
												Group User
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body">
												{this.state.role}
											</MDTypography>
										</DataTableBodyCell>
									</TableRow>
									{/* STATUS */}
									<TableRow>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body" fontWeight="medium">
												Status
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body">
												{status !== null ? (
													<MDBadge
														variant="contained"
														badgeContent={status ? "Aktif" : "Tidak Aktif"}
														size="lg"
														color={status ? "success" : "warning"}
													/>
												) : ""}
											</MDTypography>
										</DataTableBodyCell>
									</TableRow>
								</TableBody>
							</Table>
						</Grid>
					</MDBox>
				</Card>
			</MDBox>
		)
	}
}

export default withRouter(DetailUser)