/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid"
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon"
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDBadge from "components/MDBadge";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import DataTable from 'contents/Components/DataTable'
import Pagination from "contents/Components/Pagination";
import ButtonAction from "contents/Finance/Payment/components/ButtonAction"

import ButtonBack from 'contents/Components/ButtonBack';

import withRouter from "contents/Components/withRouter";

import useAxios from "libs/useAxios"
import Config from 'config'
import secureStorage from "libs/secureStorage";
import { fnumber } from "libs/currency"

class Payment extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			title: props.title,
			user: null,
			trStatus: null,
			billId: null, // jika dipanggil di component detail bill
			merchant: null,
			status: null,
			bank: null,
			// paymentType: null,
			product: null,

			products: [],
			merchants: [],
			statuses: [],
			// paymentTypes: [{ id: 1, label: 'CASH' }, { id: 2, label: 'KREDIT' }],
			banks: [],

			rowsPerPage: 10,
			currentPage: 1,
			totalPages: 0,
			totalData: 0,
			tableHead: [
				{ Header: "action", accessor: "action", width: "10%" },
				{ Header: "kode", accessor: "kode", width: "15%" },
				{ Header: "nominal", accessor: "amount", width: "20%" },
				{ Header: "pelanggan", accessor: "customer", width: "20%" },
				{ Header: "bank", accessor: "bank", width: "20%" },
				{ Header: "keterangan", accessor: "remark", width: "20%" },
				{ Header: "tanggal masuk", accessor: "createdAt", width: "20%" },
				{ Header: "status", accessor: "status", width: "10%" },
			],
			rows: [],
			keyword: "",
			isLoading: true,
		}
	}

	componentDidMount() {
		const billId = this.props.params?.id
		const user = secureStorage.getItem('user')
		const view = this.props.view

		this.setState({
			billId, user,
		}, () => {
			billId && this.checkStatus(billId)
			user && [4].includes(user.roleId) && this.loadMerchants()
			user && [2, 3, 4].includes(user.roleId) && this.loadBank()
			view === 'payment' && this.loadProduct()
			this.loadStatus()
			this.loadData()
		})
	}

	loadProduct = () => {
		useAxios()
			.post(`${Config.ApiUrl}/product/dropdown`)
			.then(response => {
				const data = response.data

				this.setState({
					products: data.map(item => ({ id: item.id, label: item.name }))
				})
			})
			.catch(err => {
				console.log(err)
			})
	}

	loadStatus = () => {
		useAxios()
			.post(`${Config.ApiUrl}/finance/payment/statuslist`)
			.then(response => {
				const data = response.data

				this.setState({
					statuses: data.map(item => ({ id: item.id, label: item.name }))
				})
			}).catch(err => {
				console.log(err)
			})
	}

	loadMerchants = () => {
		useAxios()
			.post(`${Config.ApiUrl}/manage/merchant/dropdown`)
			.then(response => {
				const data = response.data

				this.setState({
					merchants: data.map(item => ({ id: item.id, label: item.name }))
				})
			})
			.catch(err => {
				console.log(err)
			})
	}

	loadBank = (params) => {
		const merchantId = params && params.merchantId ? { merchantId: params.merchantId } : {}
		const payload = {
			...merchantId
		}

		useAxios()
			.post(`${Config.ApiUrl}/master/bank/dropdown`, payload)
			.then(response => {
				const data = response.data
				this.setState({
					banks: data.map(item => ({ id: item.id, label: item.name }))
				})
			})
			.catch(err => {
				console.log(err)
			})
	}

	checkStatus = (billId) => {
		useAxios()
			.post(`${Config.ApiUrl}/finance/bill/get`, { id: billId })
			.then(response => {
				const data = response.data
				// console.log('[BILL] -> ', data)
				this.setState({
					trStatus: data ? data.statusId : null
				})
			})
			.catch(err => {
				console.log(err)
			})
	}

	loadData = (params) => {
		const user = secureStorage.getItem('user')
		this.setState({ isLoading: true })

		const statusId = params && params.statusId ? { statusId: params.statusId } : {}
		const bankId = params && params.bankId ? { bankId: params.bankId } : {}
		const merchantId = params && params.merchantId ? { merchantId: params.merchantId } : {}
		const productId = params && params.productId ? { productId: params.productId } : {}
		const billId = this.state.billId ? { billId: this.state.billId } : {}

		const payload = {
			keyword: params && params.keyword ? params.keyword : this.state.keyword,
			currentPage: params && params.currentPage ? params.currentPage : 1,
			rowsPerPage: params && params.rowsPerPage ? params.rowsPerPage : this.state.rowsPerPage,
			...merchantId,
			...billId,
			...statusId,
			...bankId,
			...productId,
		}

		useAxios()
			.post(`${Config.ApiUrl}/finance/payment`, payload)
			.then(response => {
				const data = response.data
				// console.log(data)

				const output = data.output.map(item => {
					const paymentStatus = item.PaymentStatus ? item.PaymentStatus : null
					const member = item.Member

					return {
						kode: (
							<MDTypography
								variant="caption"
								fontWeight="medium"
								sx={{
									cursor: "pointer"
								}}
								color="info"
								component={Link}
								to={{ pathname: `/finance/payment/detail/${item.id}` }}
							>
								{item.kode}
							</MDTypography>
						),
						amount: fnumber(item.amount),
						customer: (
							<MDBox
								style={{ wordWrap: "break-word", width: "13em" }}
							>
								<MDBox display="flex" flexDirection="column">
									<MDTypography variant="caption" fontWeight="regular">
										Kode: {member && member.kode ? member.kode : "-"}
									</MDTypography>
									<MDTypography variant="caption" fontWeight="regular">
										Nama: {member && member.name ? member.name : "-"}
									</MDTypography>
								</MDBox>
							</MDBox >
						),
						bank: (
							<MDTypography variant="caption" fontWeight="regular">
								{item.Bank && item.Bank ? item.Bank.name : "-"}
							</MDTypography>
						),
						createdAt: item.createdAt,
						remark: (
							<MDBox
								style={{ wordWrap: "break-word", width: "13em" }}
							>
								<MDTypography variant="caption" fontWeight="regular">
									{item.remark}
								</MDTypography>
							</MDBox>
						),
						status: paymentStatus ? <MDBadge
							variant="contained"
							badgeContent={paymentStatus.name}
							size="lg"
							color={paymentStatus.id === 1 ? "warning" : paymentStatus.id === 2 ? "error" : "success"}
						/> : "-",
						action: (
							<ButtonAction
								id={item.id}
								image={item.image}
								statusId={paymentStatus?.id}
								refreshData={this.loadData}
							/>
						)
					}
				})

				this.setState({
					totalPages: data.totalPages,
					totalData: data.totalData,
					rows: output,
					isLoading: false
				})
			}).catch(err => {
				console.log(err)
				this.setState({ isLoading: false })
			})
	}

	render() {
		const user = secureStorage.getItem('user')
		const view = this.props.view

		return (
			<MDBox pb={3} my={3}>
				{view === "bill" && [2, 3, 4, 5].includes(user.roleId) && (
					<MDBox py={1} mb={2} display="flex">
						<MDBox mr={1}>
							<ButtonBack label={"KEMBALI"} />
						</MDBox>
						{this.state.trStatus && [1, 3].includes(this.state.trStatus) && [2, 3, 4].includes(user.roleId) && (
							<MDButton
								variant="gradient"
								color="info"
								component={Link}
								to={{ pathname: `/finance/payment/form/${this.state.billId}` }}
							>
								SETOR PEMBAYARAN
							</MDButton>
						)}
					</MDBox>
				)}

				<Card>
					<MDBox p={2} lineHeight={1}>
						<MDTypography variant="h5" fontWeight="medium">
							{this.state.title}
						</MDTypography>
					</MDBox>

					<MDBox px={2} width="100%" display="flex" justifyContent="flex-start">
						<Grid container spacing={3}>
							{/* Merchant */}
							{[4].includes(user.roleId) && (
								<Grid item xs={12} md={3} lg={3}>
									<Autocomplete
										value={this.state.merchant}
										options={this.state.merchants}
										onChange={(e, value) => {
											this.setState({
												merchant: value,
												bank: null,
												banks: []
											}, () => {
												this.loadBank({
													merchantId: value?.id
												})
												this.loadData({
													currentPage: 1,
													merchantId: value ? value.id : null,
													statusId: this.state.status ? this.state.status?.id : null,
													bankId: this.state.bank ? this.state.bank.id : null,
													productId: this.state.product ? this.state.product.id : null
												})
											})
										}}
										sx={{
											".MuiAutocomplete-input": {
												padding: "7.5px 5px 7.5px 8px !important"
											},
											".MuiOutlinedInput-root": {
												padding: "1.5px !important"
											}
										}}
										isOptionEqualToValue={(option, value) => option.id === value.id}
										renderInput={(params) => <MDInput sx={{ padding: "0px" }} fullWidth label="Pilih Biro Travel" {...params} />}
									/>
								</Grid>
							)}
							{/* Filter By Product */}
							{view !== 'bill' && (
								<Grid item xs={12} md={3} lg={3}>
									<Autocomplete
										value={this.state.product}
										options={this.state.products}
										onChange={(e, value) => {
											this.setState({ product: value })
											this.loadData({
												currentPage: 1,
												productId: value ? value.id : null,
												bankId: this.state.bank ? this.state.bank.id : null,
												statusId: this.state.status ? this.state.status.id : null,
												merchantId: this.state.merchant ? this.state.merchant.id : null
											})
										}}
										sx={{
											".MuiAutocomplete-input": {
												padding: "7.5px 5px 7.5px 8px !important"
											},
											".MuiOutlinedInput-root": {
												padding: "1.5px !important"
											}
										}}
										isOptionEqualToValue={(option, value) => option.id === value.id}
										renderInput={(params) => <MDInput sx={{ padding: "0px" }} fullWidth label="Pilih Paket" {...params} />}
									/>
								</Grid>
							)}
							{/* Filter By Bank */}
							<Grid item xs={12} md={2} lg={2}>
								<Autocomplete
									value={this.state.bank}
									options={this.state.banks}
									onChange={(e, value) => {
										this.setState({ bank: value })
										this.loadData({
											currentPage: 1,
											bankId: value ? value.id : null,
											statusId: this.state.status ? this.state.status.id : null,
											paymentTypeId: this.state.paymentType ? this.state.paymentType.id : null,
											merchantId: this.state.merchant ? this.state.merchant.id : null,
											productId: this.state.product ? this.state.product.id : null
										})
									}}
									sx={{
										".MuiAutocomplete-input": {
											padding: "7.5px 5px 7.5px 8px !important"
										},
										".MuiOutlinedInput-root": {
											padding: "1.5px !important"
										}
									}}
									isOptionEqualToValue={(option, value) => option.id === value.id}
									renderInput={(params) => <MDInput sx={{ padding: "0px" }} fullWidth label="Pilih Bank" {...params} />}
								/>
							</Grid>
							{/* Filter By Status */}
							<Grid item xs={12} md={2} lg={2}>
								<Autocomplete
									value={this.state.status}
									options={this.state.statuses}
									onChange={(e, value) => {
										this.setState({ status: value })
										this.loadData({
											currentPage: 1,
											statusId: value ? value.id : null,
											bankId: this.state.bank ? this.state.bank.id : null,
											merchantId: this.state.merchant ? this.state.merchant.id : null,
											productId: this.state.product ? this.state.product.id : null
										})
									}}
									sx={{
										".MuiAutocomplete-input": {
											padding: "7.5px 5px 7.5px 8px !important"
										},
										".MuiOutlinedInput-root": {
											padding: "1.5px !important"
										}
									}}
									isOptionEqualToValue={(option, value) => option.id === value.id}
									renderInput={(params) => <MDInput sx={{ padding: "0px" }} fullWidth label="Pilih Status" {...params} />}
								/>
							</Grid>
							{/* Button Search */}
							<Grid item xs={12} md={2} lg={2}>
								<MDBox
									width="100%"
									display="flex"
									flexDirection="row"
									justifyContent="flex-start"
								>
									<MDButton
										color="info"
										variant="gradient"
										onClick={() => {
											this.loadData({
												currentPage: 1,
												statusId: this.state.status ? this.state.status.id : null,
												bankId: this.state.bank ? this.state.bank.id : null,
												merchantId: this.state.merchant ? this.state.merchant.id : null,
												productId: this.state.product ? this.state.product.id : null
											})
										}}
										iconOnly
									>
										<Icon>search</Icon>
									</MDButton>
									<MDBox ml={1}>
										<MDButton
											color="error"
											variant="gradient"
											onClick={() => {
												this.setState({
													currentPage: 1,
													bankId: null,
													statusId: null,
													productId: null,
													merchantId: null
												}, () => {
													this.loadData()
												})
											}}
											iconOnly
										>
											<Icon>refresh</Icon>
										</MDButton>
									</MDBox>
								</MDBox>
							</Grid>
						</Grid>
					</MDBox>

					<MDBox p={2}>
						<DataTable
							isLoading={this.state.isLoading}
							tableHead={this.state.tableHead}
							tableData={this.state.rows}
						/>
						<Pagination
							totalPages={this.state.totalPages}
							totalData={this.state.totalData}
							currentPage={this.state.currentPage}
							rowsPerPage={[10, 25, 50, "All"]}
							totalButton={3}
							defaultRowsPerPage={this.state.rowsPerPage}
							onChangeRowsPerPage={(value) => {
								this.setState({ rowsPerPage: value, currentPage: 1 });
								this.loadData({
									rowsPerPage: value,
									currentPage: 1,
									bankId: this.state.bank ? this.state.bank.id : null,
									statusId: this.state.status ? this.state.status.id : null,
									merchantId: this.state.merchant ? this.state.merchant.id : null
								});
							}}
							onChangePage={(currentPage) => {
								if (currentPage !== this.state.currentPage) {
									this.setState({ currentPage });
									this.loadData({
										currentPage,
										bankId: this.state.bank ? this.state.bank.id : null,
										statusId: this.state.status ? this.state.status.id : null,
										merchantId: this.state.merchant ? this.state.merchant.id : null
									});
								}
							}}
						/>
					</MDBox>
				</Card>
			</MDBox>
		)
	}
}

Payment.defaultProps = {
	title: "Daftar Pembayaran",
	view: "payment"
}

Payment.propTypes = {
	title: PropTypes.string,
	view: PropTypes.oneOf(['payment', 'bill']),
}

export default withRouter(Payment)