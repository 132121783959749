import React from "react"
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import Icon from "@mui/material/Icon"

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

// Material Dashboard 2 PRO React examples
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";

import { fnumber } from "libs/currency"

function ListPrice({ rows }) {
	return (
		<TableContainer sx={{ boxShadow: "none" }}>
			<Table>
				<MDBox component="thead">
					<TableRow sx={{ backgroundColor: "#f9fafb" }}>
						<DataTableBodyCell
							width="auto"
							align="left"
						>
							<MDTypography variant="caption" fontWeight="medium" textTransform="uppercase">
								NO
							</MDTypography>
						</DataTableBodyCell>
						<DataTableBodyCell
							width="auto"
							align="left"
						>
							<MDTypography variant="caption" fontWeight="medium" textTransform="uppercase">
								Jenis Kamar
							</MDTypography>
						</DataTableBodyCell>
						<DataTableBodyCell
							width="auto"
							align="right"
						>
							<MDTypography variant="caption" fontWeight="medium" textTransform="uppercase">
								Komisi
							</MDTypography>
						</DataTableBodyCell>
						<DataTableBodyCell
							width="auto"
							align="right"
						>
							<MDTypography variant="caption" fontWeight="medium" textTransform="uppercase">
								Uang Muka
							</MDTypography>
						</DataTableBodyCell>
						<DataTableBodyCell
							width="auto"
							align="right"
						>
							<MDTypography variant="caption" fontWeight="medium" textTransform="uppercase">
								Harga
							</MDTypography>
						</DataTableBodyCell>
					</TableRow>
				</MDBox>
				<TableBody>
					{rows.map((item, key) => (
						<TableRow key={key}>
							<DataTableBodyCell>
								<MDTypography variant="body">
									{key + 1}
								</MDTypography>
							</DataTableBodyCell>
							<DataTableBodyCell>
								<MDTypography variant="body">
									{item.roomType}
								</MDTypography>
							</DataTableBodyCell>
							<DataTableBodyCell align="right">
								<MDTypography variant="body" fontWeight="medium">
									{item.fee ? fnumber(item.fee) : 0}
								</MDTypography>
							</DataTableBodyCell>
							<DataTableBodyCell align="right">
								<MDTypography variant="body" fontWeight="medium">
									{fnumber(item.downPayment)}
								</MDTypography>
							</DataTableBodyCell>
							<DataTableBodyCell align="right">
								<MDTypography variant="body" fontWeight="medium">
									{fnumber(item.amount)}
								</MDTypography>
							</DataTableBodyCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	)
}

ListPrice.propTypes = {
	rows: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default ListPrice