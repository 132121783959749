/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

// Material Dashboard 2 PRO React examples
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";

import withRouter from "contents/Components/withRouter";
import Loader from "contents/Components/Loader";
import ModalNotif from "contents/Components/ModalNotif";

import ListHotel from "contents/Components/ListHotel"
import ButtonBack from 'contents/Components/ButtonBack'

import Config from "config";
import _ from "lodash";
import moment from "moment";
import useAxios from "libs/useAxios";
import secureStorage from "libs/secureStorage";
import { fnumber } from "libs/currency";

class DetailTransaction extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			id: null,
			isLoading: true,

			sale: null,
			trStatus: null,
			member: null,
			agent: null,
			product: null,
			productCategory: null,
			airportDeparture: null,
			airportReturn: null,
			productHotels: [],
			// productAirlines: [],
			// productAddons: [],
			// productDiscounts: [],
			productPrice: null,
			totalAmount: 0
		}

		this.modalNotifRef = React.createRef()
		this.confirmRef = React.createRef()
		this.dialogStatusRef = React.createRef()
		this.buttonBackRef = React.createRef()
	}

	componentDidMount() {
		const id = this.props.params?.id

		this.setState({ id }, () => {
			this.loadDetail(id)
		})
	}

	loadDetail = (id) => {
		const view = this.props.view

		const payload = view === 'sale' ? { saleId: id } : { billId: id }
		useAxios()
			.post(`${Config.ApiUrl}/trx/detail`, payload)
			.then(response => {
				const data = response.data
				const trStatus = data.TrStatus ? data.TrStatus : null
				// Price Product
				const productPrice = data.ProductPrice
				// Member & Agent
				const customer = data.Customer
				const parent = data.Parent
				const member = customer ? customer.Member : null
				const agent = parent ? parent.Agent : null
				// Product
				const product = data.Product
				const productCategory = product && product.ProductCategory ? product.ProductCategory : null
				const airportDeparture = product.AirportDeparture ? product.AirportDeparture : null
				const airportReturn = product.AirportReturn ? product.AirportReturn : null
				const productHotels = product.ProductHotels.map(item => ({
					id: item.id,
					duration: item.duration,
					name: item.name,
					location: item.location,
					rating: item.rating
				}))
				// const productAirlines = product.ProductAirlines.map((item, i) => ({
				// 	no: i + 1,
				// 	name: item.name,
				// 	direction: item.direction,
				// 	flightNumber: item.flightNumber
				// }))
				// const productAddons = product.Addons.map(item => ({
				// 	amount: item.ProductAddon && item.ProductAddon.amount ? item.ProductAddon.amount : "",
				// 	remark: item.ProductAddon?.remark,
				// 	row: {
				// 		id: item.id,
				// 		name: item.name
				// 	}
				// }))
				// const productDiscounts = product.Discounts.map(item => ({
				// 	amount: item.ProductDiscount && item.ProductDiscount.amount ? item.ProductDiscount.amount : "",
				// 	remark: item.ProductDiscount?.remark,
				// 	row: {
				// 		id: item.id,
				// 		name: item.name
				// 	}
				// }))

				this.setState({
					isLoading: false,
					// TRX
					sale: {
						id: data.id,
						kode: data.kode,
						createdAt: data.createdAt
					},
					trStatus,
					productPrice,
					totalAmount: data.amount,
					// Member & Agent
					member: member,
					agent: agent,
					// Product
					product: {
						id: product ? product.id : "",
						kode: product ? product.kode : "",
						name: product ? product.name : "",
						days: product ? product.days : "",
						departureDate: product ? moment(product.departureDate).utc().add(7, 'hours').format('YYYY-MM-DD') : " ",
						arrivalDate: product ? moment(product.arrivalDate).utc().add(7, 'hours').format('YYYY-MM-DD') : " ",
					},
					productCategory,
					airportDeparture,
					airportReturn,
					productHotels,
					// productAirlines,
					// productAddons,
					// productDiscounts
				})
			})
			.catch(err => {
				console.log(err)
				this.setState({ isLoading: false })
				this.modalNotifRef.current.setShow({
					modalTitle: "Gagal",
					modalMessage: err.response ? err.response.data : "Koneksi jaringan terputus",
					onClose: () => {
						this.buttonBackRef.current.click()
					}
				})
			})
	}

	render() {
		const user = secureStorage.getItem('user')
		const view = this.props.view
		const airportDeparture = this.state.airportDeparture
		const airportReturn = this.state.airportReturn

		if (this.state.redirect) {
			return <Navigate to={this.state.redirect} />;
		}

		return (
			<>
				<MDBox py={1} mb={2} display="flex">
					<ModalNotif ref={this.modalNotifRef} />
					<MDBox mr={1}>
						<ButtonBack label={"KEMBALI"} ref={this.buttonBackRef} />
					</MDBox>
				</MDBox>
				{this.state.isLoading ? (<Loader open={true} />) : (
					<Card>
						<MDBox p={2}>
							<Grid container spacing={3} item xs={12} md={12} lg={12} mb={5} pt={2}>
								<Table>
									<TableBody>
										{/* KODE & DATE */}
										<TableRow>
											<DataTableBodyCell noBorder>
												<MDTypography variant="body" fontWeight="medium">
													Kode Transaksi
												</MDTypography>
											</DataTableBodyCell>
											<DataTableBodyCell noBorder>
												<MDTypography variant="body">
													{this.state.sale ? this.state.sale.kode : "-"}
												</MDTypography>
											</DataTableBodyCell>
											<DataTableBodyCell noBorder>
												<MDTypography variant="body" fontWeight="medium">
													Tanggal Transaksi
												</MDTypography>
											</DataTableBodyCell>
											<DataTableBodyCell noBorder>
												<MDTypography variant="body">
													{this.state.sale ? this.state.sale.createdAt : "-"}
												</MDTypography>
											</DataTableBodyCell>
										</TableRow>
										{/* MEMBER & AGENT */}
										<TableRow>
											<DataTableBodyCell noBorder>
												<MDTypography variant="body" fontWeight="medium">
													Pelanggan
												</MDTypography>
											</DataTableBodyCell>
											<DataTableBodyCell noBorder>
												<MDTypography variant="body">
													{this.state.member
														? `${this.state.member.kode} - ${this.state.member.name}`
														: "-"}
												</MDTypography>
											</DataTableBodyCell>
											<DataTableBodyCell noBorder>
												<MDTypography variant="body" fontWeight="medium">
													Agen
												</MDTypography>
											</DataTableBodyCell>
											<DataTableBodyCell noBorder>
												<MDTypography variant="body">
													{this.state.agent
														? `${this.state.agent.kode} - ${this.state.agent.name}`
														: "-"}
												</MDTypography>
											</DataTableBodyCell>
										</TableRow>
										{/* PRODUCT & PRODUCT CATEGORY */}
										<TableRow>
											<DataTableBodyCell noBorder>
												<MDTypography variant="body" fontWeight="medium">
													Paket
												</MDTypography>
											</DataTableBodyCell>
											<DataTableBodyCell noBorder>
												<MDTypography variant="body">
													{this.state.product
														? `${this.state.product.kode} - ${this.state.product.name}`
														: "-"}
												</MDTypography>
											</DataTableBodyCell>
											<DataTableBodyCell noBorder>
												<MDTypography variant="body" fontWeight="medium">
													Kategori Paket
												</MDTypography>
											</DataTableBodyCell>
											<DataTableBodyCell noBorder>
												<MDTypography variant="body">
													{this.state.productCategory ? `${this.state.productCategory.name} ` : "-"}
												</MDTypography>
											</DataTableBodyCell>
										</TableRow>
										{/* DepartureDate & ArrivalDate */}
										<TableRow>
											<DataTableBodyCell noBorder>
												<MDTypography variant="body" fontWeight="medium">
													Tanggal Berangkat
												</MDTypography>
											</DataTableBodyCell>
											<DataTableBodyCell noBorder>
												<MDTypography variant="body">{this.state.product?.departureDate}</MDTypography>
											</DataTableBodyCell>
											<DataTableBodyCell noBorder>
												<MDTypography variant="body" fontWeight="medium">
													Tanggal Pulang
												</MDTypography>
											</DataTableBodyCell>
											<DataTableBodyCell noBorder>
												<MDTypography variant="body">{this.state.product?.arrivalDate}</MDTypography>
											</DataTableBodyCell>
										</TableRow>
										{/* Airport Departure & Return */}
										<TableRow>
											<DataTableBodyCell noBorder>
												<MDTypography variant="body" fontWeight="medium">
													Bandara Berangkat
												</MDTypography>
											</DataTableBodyCell>
											<DataTableBodyCell noBorder>
												<MDBox
													width="100%"
													display="flex"
													flexDirection="column"
													alignItems="flex-start"
													justifyContent="center"
													style={{ wordWrap: "break-word", width: "20em" }}>
													<MDTypography variant="body">
														Maskapai &nbsp;
														<MDTypography variant="body" fontWeight="medium">
															{airportDeparture && airportDeparture.airline ? airportDeparture.airline : '-'}
														</MDTypography>
													</MDTypography>
													<MDTypography variant="body">
														Dari &nbsp;
														<MDTypography variant="body" fontWeight="medium">
															{airportDeparture && airportDeparture.from ? airportDeparture.from : '-'}
														</MDTypography>
													</MDTypography>
													<MDTypography variant="body">
														Ke &nbsp;
														<MDTypography variant="body" fontWeight="medium">
															{airportDeparture && airportDeparture.to ? airportDeparture.to : '-'}
														</MDTypography>
													</MDTypography>
												</MDBox>
											</DataTableBodyCell>

											<DataTableBodyCell noBorder>
												<MDTypography variant="body" fontWeight="medium">
													Bandara Pulang
												</MDTypography>
											</DataTableBodyCell>
											<DataTableBodyCell noBorder>
												<MDBox
													width="100%"
													display="flex"
													flexDirection="column"
													alignItems="flex-start"
													justifyContent="center"
													style={{ wordWrap: "break-word", width: "20em" }}>
													<MDTypography variant="body">
														Maskapai &nbsp;
														<MDTypography variant="body" fontWeight="medium">
															{airportReturn && airportReturn.airline ? airportReturn.airline : '-'}
														</MDTypography>
													</MDTypography>
													<MDTypography variant="body">
														Dari &nbsp;
														<MDTypography variant="body" fontWeight="medium">
															{airportReturn && airportReturn.from ? airportReturn.from : '-'}
														</MDTypography>
													</MDTypography>
													<MDTypography variant="body">
														Ke &nbsp;
														<MDTypography variant="body" fontWeight="medium">
															{airportReturn && airportReturn.to ? airportReturn.to : '-'}
														</MDTypography>
													</MDTypography>
												</MDBox>
											</DataTableBodyCell>
										</TableRow>
										{/* Status Transaction && Fee Agent */}
										<TableRow>
											<DataTableBodyCell noBorder>
												<MDTypography variant="body" fontWeight="medium">
													Komisi Agen
												</MDTypography>
											</DataTableBodyCell>
											<DataTableBodyCell noBorder>
												<MDTypography variant="body">
													{this.state.productPrice && this.state.productPrice.fee ? fnumber(this.state.productPrice.fee) : 0}
												</MDTypography>
											</DataTableBodyCell>
											<DataTableBodyCell noBorder>
												<MDTypography variant="body" fontWeight="medium">
													Status Transaksi
												</MDTypography>
											</DataTableBodyCell>
											<DataTableBodyCell noBorder>
												<MDTypography variant="body">
													<MDBadge
														size="lg"
														variant="contained"
														badgeContent={this.state.trStatus?.name}
														color={
															this.state.trStatus.id === 1 ? 'secondary' :
																this.state.trStatus.id === 2 ? 'warning' :
																	this.state.trStatus.id === 3 ? 'error' : 'success'
														}
													/>
												</MDTypography>
											</DataTableBodyCell>
										</TableRow>
										{/* List Hotel */}
										<TableRow>
											<DataTableBodyCell noBorder>
												<MDTypography variant="body" fontWeight="medium">
													Hotel
												</MDTypography>
											</DataTableBodyCell>
											<TableCell colSpan={3} sx={{ borderBottom: "none" }}>
												<ListHotel type="view" rows={this.state.productHotels} />
											</TableCell>
										</TableRow>
										{/* List Maskapai */}
										{/* <TableRow>
											<DataTableBodyCell noBorder>
												<MDTypography variant="body" fontWeight="medium">
													Maskapai
												</MDTypography>
											</DataTableBodyCell>
											<TableCell colSpan={3} sx={{ borderBottom: "none" }}>
												<ListAirline rows={this.state.productAirlines} />
											</TableCell>
										</TableRow> */}
										{/* Product Addon */}
										{/* <TableRow>
											<DataTableBodyCell noBorder>
												<MDTypography variant="body" fontWeight="medium">
													Biaya Tambahan
												</MDTypography>
											</DataTableBodyCell>
											<TableCell colSpan={3} sx={{ borderBottom: "none" }}>
												<ListItem type={"view"} rows={this.state.productAddons} />
											</TableCell>
										</TableRow> */}
										{/* Product Discount */}
										{/* <TableRow>
											<DataTableBodyCell noBorder>
												<MDTypography variant="body" fontWeight="medium">
													Diskon
												</MDTypography>
											</DataTableBodyCell>
											<TableCell colSpan={3} sx={{ borderBottom: "none" }}>
												<ListItem type={"view"} rows={this.state.productDiscounts} />
											</TableCell>
										</TableRow> */}
										{/* Product Price */}
										{/* <TableRow>
											<DataTableBodyCell noBorder>
												<MDTypography variant="body" fontWeight="medium">
													Harga Paket
												</MDTypography>
											</DataTableBodyCell>
											<TableCell
												colSpan={3}
												size="small"
												align="right"
												sx={{ borderBottom: "none" }}
											>
												<MDTypography variant="button" fontWeight="medium">
													{this.state.productPrice && this.state.productPrice.amount ? fnumber(this.state.productPrice.amount) : 0}
												</MDTypography>
											</TableCell>
										</TableRow> */}
										{/* Total Amount */}
										<TableRow>
											<DataTableBodyCell noBorder>
												<MDTypography variant="body" fontWeight="medium">
													{/* Total Harga */}
													Harga Paket
												</MDTypography>
											</DataTableBodyCell>
											<TableCell
												colSpan={3}
												size="small"
												align="right"
												sx={{ borderBottom: "none" }}
											>
												<MDTypography variant="button" fontWeight="medium">
													{fnumber(this.state.totalAmount)}
												</MDTypography>
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</Grid>
						</MDBox>
					</Card>
				)}
			</>
		)
	}

}

DetailTransaction.defaultProps = {
	view: "sale"
}

DetailTransaction.propTypes = {
	title: PropTypes.string,
	view: PropTypes.oneOf(['sale', 'bill']) // jika value bill maka component di panggil di tabview component lain
}

export default withRouter(DetailTransaction)