/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from "prop-types"
import { Link, Navigate } from 'react-router-dom';
// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from 'components/MDInput';
import MDTypography from "components/MDTypography";
import MDDatePicker from 'components/MDDatePicker';
import MDEditor from 'components/MDEditor';

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import ModalNotif from 'contents/Components/ModalNotif';
import withRouter from 'contents/Components/withRouter';
import ProductHotel from "contents/Components/ProductHotel";
import ListHotel from "contents/Components/ListHotel"
import DialogItem from 'contents/Components/DialogItem'
import ListItem from 'contents/Components/ListItem'

import ButtonBack from 'contents/Components/ButtonBack';

import Config from 'config'
import moment from 'moment'
import _ from "lodash"

import useAxios from "libs/useAxios"
import secureStorage from 'libs/secureStorage';
import { fnumber } from "libs/currency"

class TourPackage extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			action: "",
			id: null,

			name: "",
			productCategory: null,
			// Jumlah Hari
			days: "",
			departureDate: "",
			arrivalDate: "",
			// Airport
			airportDeparture: null,
			airportReturn: null,
			// Hotel
			dataHotels: [], // { action: "add", duration: "", location: "", name:"" }
			tmpHotels: [],
			// Room Price
			roomPrices: [
				{ action: "add", price: "", downPayment: "", fee: "", roomType: null }
			],
			tmpRoomPrice: [],

			seat: "",
			tourLeader: null,
			image: null,
			imageFilename: "",

			description: "",

			roomTypes: [],
			tourLeaders: [],
			productCategories: [],

			tourLeaderOpen: false,
			productCateogryOpen: false,

			error: [],
			success: [],

			disabledSubmit: false,
			redirect: null
		}

		this.inputRef = React.createRef()
		this.modalNotifRef = React.createRef()
		this.listHotelRef = React.createRef()
		this.productHotelRef = React.createRef()
		this.dialogAddonRef = React.createRef()
		this.dialogDiscountRef = React.createRef()
		this.listDiscountRef = React.createRef()
		this.listAddonRef = React.createRef()
		this.fileImageRef = React.createRef()
	}

	componentWillUnmount() {
		this.setState({ isMounted: false })
	}

	componentDidMount() {
		this.inputRef.current && this.inputRef.current.focus()
		const user = secureStorage.getItem('user')

		this.loadTourLeader()
		this.loadRoomTypes()
		this.loadProductCategory()
		this.loadPath()
	}

	loadPath() {
		const pathname = window.location.pathname;
		const index = pathname.indexOf('edit');
		if (index === -1) {
			this.setState({
				title: "Buat Paket Tour Baru",
				action: "add"
			});
		} else {
			const id = this.props.params?.id;
			this.loadDetail(id);
			this.setState({
				id,
				title: "Edit Paket Tour",
				action: "edit"
			})
		}
	}

	loadDetail(id) {
		useAxios()
			.post(`${Config.ApiUrl}/product/get`, { id })
			.then(response => {
				const data = response.data

				const tourleader = data.TourLeader ? data.TourLeader : null
				const productCategory = data.ProductCategory ? data.ProductCategory : null
				const productPrices = data.ProductPrices ? data.ProductPrices : []
				const hotels = data.ProductHotels ? data.ProductHotels : []

				const dataHotels = hotels.map(item => {
					return {
						action: "update",
						id: item.id,
						duration: item.duration ? item.duration : "",
						name: item.name ? item.name : "",
						location: item.location ? item.location : "",
						rating: item.rating ? item.rating : ""
					}
				})

				this.setState({
					id: data.id,
					name: data.name,
					seat: data.seat,
					description: data.description,
					days: data.days,
					arrivalDate: data.arrivalDate,
					departureDate: data.departureDate,
					tourLeader: { id: tourleader?.id, label: `${tourleader?.kode} -  ${tourleader?.name}` },
					productCategory: { id: productCategory?.id, label: productCategory?.name },
					airportDeparture: data.AirportDeparture ? data.AirportDeparture : null,
					airportReturn: data.AirportReturn ? data.AirportReturn : null,
					// Data Hotel
					dataHotels,
					// Price Room
					roomPrices: productPrices.map(item => {
						const productPrice = {
							action: "update",
							id: item.id,
							fee: item.fee ? fnumber(item.fee) : "",
							price: item.amount ? fnumber(item.amount) : "",
							downPayment: item.downPayment ? fnumber(item.downPayment) : "",
							roomType: {
								id: item.RoomType?.id,
								label: item.RoomType?.name
							}
						}
						return productPrice
					}),

					success: {
						...this.state.success,
						name: data.name ? true : false,
						seat: data.seat ? true : false,
						image: data.image ? true : false,
						description: data.description ? true : false,
						days: data.days ? true : false,
						arrivalDate: data.arrivalDate ? true : false,
						departureDate: data.departureDate ? true : false,
						tourLeader: tourleader ? true : false,
						productCategory: productCategory ? true : false,
						// Airport Departure
						airportDepartureAirline: data.AirportDeparture && data.AirportDeparture.airline ? true : false,
						airportDepartureFrom: data.AirportDeparture && data.AirportDeparture.from ? true : false,
						airportDepartureTo: data.AirportDeparture && data.AirportDeparture.to ? true : false,
						// Airport Return
						airportReturnAirline: data.AirportReturn && data.AirportReturn.airline ? true : false,
						airportReturnFrom: data.AirportReturn && data.AirportReturn.from ? true : false,
						airportReturnTo: data.AirportDeparture && data.AirportDeparture.to ? true : false,
					}
				})
			})
			.catch(err => {
				console.log(err)
			})
	}

	loadProductCategory() {
		useAxios()
			.post(`${Config.ApiUrl}/product/category/dropdown`)
			.then(response => {
				const data = response.data;
				const productCategories = data.map(item => {
					return {
						id: item.id,
						label: item.name
					}
				});

				this.setState({ productCategories })
			})
	}

	loadTourLeader() {
		useAxios()
			.post(`${Config.ApiUrl}/master/tourleader/dropdown`)
			.then(response => {
				const data = response.data
				const tourLeaders = data.map(item => ({
					id: item.id,
					label: `${item.kode} - ${item.name}`
				}))

				this.setState({ tourLeaders })
			})
			.catch(err => {
				console.log(err)
			})
	}

	loadRoomTypes = () => {
		useAxios()
			.post(`${Config.ApiUrl}/master/room/dropdown`)
			.then(response => {
				const data = response.data
				this.setState({
					roomTypes: data.map(item => ({ id: item.id, label: item.name }))
				})
			})
			.catch(err => {
				console.log(err)
			})
	}

	handleConvert = (e) => {
		const id = e.target.id
		const valStr = e.target.value.replace(/\./g, '')
		let value = isNaN(valStr) || valStr === '' ? 0 : parseInt(valStr)
		if (value < 0)
			value = 0
		this.setState({
			[id]: fnumber(value)
		})
	}

	handleChange = (e) => {
		const { id, value } = e.target;
		this.setState({
			[id]: value
		})
	}

	handleBlur = (e) => {
		const { id, value } = e.target
		// eslint-disable-next-line no-empty
		if (value) {
			this.setState({
				success: { ...this.state.success, [e.target.id]: true },
				error: { ...this.state.error, [e.target.id]: false }
			})
		}
		else {
			this.setState({
				success: { ...this.state.success, [e.target.id]: false },
				error: { ...this.state.error, [e.target.id]: true }
			})
		}
	}

	// Handle Hotel
	onSaveHotel = (params) => {
		console.log({ params })
		const dataHotels = [...this.state.dataHotels, { ...params, action: "add" }]
		this.setState({ dataHotels })
		this.listHotelRef.current.setData(dataHotels)
	}

	onDeleteHotel = (key) => {
		const dataHotels = this.state.dataHotels
		let tmpHotels = this.state.tmpHotels

		const tmpDel = dataHotels[key]
		dataHotels.splice(key, 1)

		if (tmpDel.action === "update")
			tmpHotels = [...tmpHotels, { ...tmpDel, action: 'delete' }]

		this.setState({
			dataHotels, tmpHotels
		})
	}
	// End Hotel

	// Handle Price
	onAddPrice = () => {
		const newData = { action: 'add', roomType: null, price: "", downPayment: "", fee: "" }
		this.setState({
			roomPrices: [...this.state.roomPrices, newData]
		})
	}

	onDelPrice = (key) => {
		const roomPrices = this.state.roomPrices
		let tmpRoomPrice = this.state.tmpRoomPrice

		const tmpDel = roomPrices[key]
		roomPrices.splice(key, 1)

		if (tmpDel.action === "update")
			tmpRoomPrice = [...tmpRoomPrice, { ...tmpDel, action: 'delete' }]

		this.setState({
			roomPrices, tmpRoomPrice
		})
	}

	renderPrice = () => {
		return this.state.roomPrices.map((item, key) => (
			<Grid key={key} container spacing={3} mb={{ xs: 2, md: 1 }}>
				{/* Room Type */}
				<Grid item xs={2} md={2}>
					<Autocomplete
						disableClearable
						value={item.roomType}
						options={this.state.roomTypes}
						onChange={(e, value) => {
							if (value) {
								const roomPrices = this.state.roomPrices
								roomPrices[key]['roomType'] = value
								this.setState({
									roomPrices,
									error: { ...this.state.error, roomPrices: false },
									success: { ...this.state.success, roomPrices: true },
								})
							}
						}}
						sx={{
							".MuiAutocomplete-input": {
								padding: "7.5px 5px 7.5px 8px !important"
							},
							".MuiOutlinedInput-root": {
								padding: "4px !important"
							}
						}}
						isOptionEqualToValue={(option, value) => option.id === value.id}
						renderInput={(params) => <MDInput label="Jenis Kamar" {...params} />}
					/>
				</Grid>
				{/* Price Room */}
				<Grid item xs={3} md={3}>
					<MDInput
						id="price"
						type="text"
						label="Harga Paket"
						value={item.price}
						onChange={(e) => {
							const valStr = e.target.value.replace(/\./g, '')
							let value = isNaN(valStr) || valStr === '' ? 0 : parseInt(valStr)
							if (value < 0)
								value = 0
							const roomPrices = this.state.roomPrices
							roomPrices[key]['price'] = fnumber(value)
							this.setState({ roomPrices })
						}}
						fullWidth
					/>
				</Grid>
				{/* Down Payment */}
				<Grid item xs={3} md={3}>
					<MDInput
						id="downPayment"
						type="text"
						label="Uang Muka"
						value={item.downPayment}
						onChange={(e) => {
							const valStr = e.target.value.replace(/\./g, '')
							let value = isNaN(valStr) || valStr === '' ? 0 : parseInt(valStr)
							if (value < 0)
								value = 0
							const roomPrices = this.state.roomPrices
							roomPrices[key]['downPayment'] = fnumber(value)
							this.setState({ roomPrices })
						}}
						fullWidth
					/>
				</Grid>
				{/* Fee */}
				<Grid item xs={3} md={3}>
					<MDInput
						id="fee"
						type="text"
						label="Komisi"
						value={item.fee}
						onChange={(e) => {
							const valStr = e.target.value.replace(/\./g, '')
							let value = isNaN(valStr) || valStr === '' ? 0 : parseInt(valStr)
							if (value < 0)
								value = 0
							const roomPrices = this.state.roomPrices
							roomPrices[key]['fee'] = fnumber(value)
							this.setState({ roomPrices })
						}}
						fullWidth
					/>
				</Grid>
				{/* Button */}
				<Grid item xs={1} md={1}>
					<MDButton
						variant="gradient"
						color="error"
						onClick={() => this.onDelPrice(key)}
						iconOnly
					>
						<Icon>delete</Icon>
					</MDButton>
				</Grid>
			</Grid>
		))
	}
	// End Price

	// Reset Form
	resetForm = () => {
		this.setState({
			disabledSubmit: false,
			name: "",
			seat: "",
			description: "",
			days: "",
			departureDate: "",
			arrivalDate: "",
			// Airport
			airportDeparture: null,
			airportReturn: null,

			image: "",
			tourLeader: null,
			productCategory: null,

			roomPrices: [{ action: "add", price: "", downPayment: "", fee: "", roomType: null }],
			dataHotels: [],

			success: {
				...this.state.success,
				name: false,
				seat: false,
				productCategory: false,
				departureDate: false,
				arrivalDate: false,
				days: false,
				tourLeader: false,
				// Airport Departure
				airportDepartureAirline: false,
				airportDepartureFrom: false,
				airportDepartureTo: false,
				// Airport Return
				airportReturnAirline: false,
				airportReturnFrom: false,
				airportReturnTo: false,
			}
		}, () => {
			this.listAddonRef.current.setData([])
			this.listDiscountRef.current.setData([])
			this.listHotelRef.current.setData([])
		})
	}

	// Submit Proses
	handleSubmit = () => {
		const success = this.state.success

		console.log({ success })
		if (
			success.name &&
			success.seat &&
			// success.image &&
			success.productCategory &&
			success.tourLeader &&
			success.days &&
			success.departureDate &&
			success.arrivalDate &&
			// Airport Departure
			success.airportDepartureAirline &&
			success.airportDepartureFrom &&
			success.airportDepartureTo &&
			// Airport Return
			success.airportReturnAirline &&
			success.airportReturnFrom &&
			success.airportReturnTo &&

			success.description &&
			this.state.roomPrices.length > 0 &&
			this.state.dataHotels.length > 0
		) {
			this.sendData()
		}
		else {
			let input = ""
			// !success.description && (input = "Deskripsi Paket")
			!success.seat && (input = "Kursi Tersedia")
			this.state.roomPrices.length < 1 && (input = "Harga Paket")
			this.state.roomPrices[0]?.price == "" && (input = "Harga Paket")
			this.state.roomPrices[0]?.downPayment == "" && (input = "Harga Paket")
			this.state.dataHotels.length < 1 && (input = "Hotel")
			!success.tourLeader && (input = "Tour Leader")
			!success.airportReturnTo && (input = "Bandara Kepulangan")
			!success.airportReturnFrom && (input = "Bandara Kepulangan")
			!success.airportReturnAirline && (input = "Maskapai Kepulangan")
			!success.airportDepartureTo && (input = "Bandara Keberangkatan")
			!success.airportDepartureFrom && (input = "Bandara Keberangkatan")
			!success.airportDepartureAirline && (input = "Maskapai Keberangkatan")
			// !success.image && (input = "Gambar Paket")
			!success.arrivalDate && (input = "Tanggal Pulang")
			!success.departureDate && (input = "Tanggal Keberangkatan")
			!success.days && (input = "Jumlah Hari Tour")
			!success.productCategory && (input = "Kategori Paket Tour")
			!success.name && (input = "Nama Paket Tour")

			this.modalNotifRef.current.setShow({
				modalTitle: "Gagal",
				modalMessage: `Data "${input}" masih kosong, Silahkan di cek kembali !`,
			})
		}
	}

	// Button SendData
	sendData = () => {
		this.setState({ disabledSubmit: true })
		const { action } = this.props
		const user = secureStorage.getItem('user')

		const formData = new FormData()
		formData.append('name', this.state.name)
		formData.append('seat', this.state.seat)
		formData.append('description', this.state.description)
		formData.append('days', this.state.days)
		formData.append('departureDate', this.state.departureDate)
		formData.append('arrivalDate', this.state.arrivalDate)
		formData.append('airportDeparture', JSON.stringify(this.state.airportDeparture))
		formData.append('airportReturn', JSON.stringify(this.state.airportReturn))
		formData.append('tourleaderId', this.state.tourLeader ? this.state.tourLeader?.id : null)
		formData.append('productCategoryId', this.state.productCategory ? this.state.productCategory.id : null)
		formData.append('merchantId', user.Merchant?.id)
		formData.append('id', this.state.id)
		formData.append('image', this.state.image)

		formData.append('roomPrices', JSON.stringify([...this.state.roomPrices, ...this.state.tmpRoomPrice]))
		// formData.append('productAirlines', JSON.stringify([...this.state.productAirlines, ...this.state.tmpProductAirlines]))
		formData.append('hotels', JSON.stringify([...this.state.dataHotels, ...this.state.tmpHotels]))
		// formData.append('discounts', JSON.stringify([...this.state.tmpDiscount, ...this.state.discounts]))
		// formData.append('addons', JSON.stringify([...this.state.tmpAddon, ...this.state.addons]))

		const url = action === "add" ? "/product/create" : "/product/update"
		console.log({ formData, url })
		useAxios()
			.post(`${Config.ApiUrl}${url}`, formData)
			.then(response => {
				this.modalNotifRef.current.setShow({
					modalTitle: "Sukses",
					modalMessage: response.data,
					onClose: () => {
						this.setState({ redirect: "/adm/product" })
					}
				})
			})
			.catch(err => {
				this.setState({ disabledSubmit: false })
				if (err.response) {
					this.modalNotifRef.current.setShow({
						modalTitle: "Gagal",
						modalMessage: err.response ? err.response.data : "Server Internal Error",
					})
				}
				// eslint-disable-next-line no-empty
				else {
					this.modalNotifRef.current.setShow({
						modalTitle: 'Gagal',
						modalMessage: err.message
					})
				}
			})
	}

	render() {
		const user = secureStorage.getItem('user')
		const { title } = this.props
		if (this.state.redirect) {
			return <Navigate to={this.state.redirect} />
		}

		return (
			<>
				<ModalNotif ref={this.modalNotifRef} />
				<ProductHotel
					ref={this.productHotelRef}
					user={user}
					onAction={this.onSaveHotel}
				/>

				<MDBox my={3} mx="auto" width="100%" height="100vh" pb={3}>
					<Grid container spacing={1} justifyContent="center" alignItems="flex-start" height="100%">
						<Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
							<Card>
								{/* TITLE */}
								<MDBox p={3} lineHeight={1} display="flex" justifyContent="center">
									<MDTypography variant="h5" fontWeight="medium">
										{title}
									</MDTypography>
								</MDBox>
								{/* FORM */}
								<MDBox p={3}>
									<MDBox component="form" role="form">
										{/* Product Name && Product Category */}
										<Grid container spacing={3} mb={1}>
											{/* Product Name */}
											<Grid item xs={12} md={6} lg={6}>
												<MDInput
													id="name"
													type="text"
													inputRef={this.inputRef}
													label="Nama Paket"
													value={this.state.name}
													onChange={this.handleChange}
													onBlur={this.handleBlur}
													success={this.state.success ? this.state.success.name : false}
													error={this.state.error ? this.state.error.name : false}
													fullWidth
												/>
											</Grid>
											{/* Product Category */}
											<Grid item xs={12} md={6} lg={6}>
												<Autocomplete
													disableClearable
													value={this.state.productCategory}
													open={this.state.productCateogryOpen}
													onOpen={() => this.setState({ productCateogryOpen: true })}
													onClose={() => this.setState({ productCateogryOpen: false })}
													onFocus={() => this.setState({ productCateogryOpen: true })}
													options={this.state.productCategories}
													onChange={(e, value) => {
														if (value) {
															this.setState({
																productCategory: value,
																error: { ...this.state.error, productCategory: false },
																success: { ...this.state.success, productCategory: true }
															})
														}
														else {
															this.setState({
																productType: null,
																productTypes: [],
																error: { ...this.state.error, productType: true },
																success: { ...this.state.success, productType: false },
															})
														}
													}}
													sx={{
														".MuiAutocomplete-input": {
															padding: "7.5px 5px 7.5px 8px !important"
														},
														".MuiOutlinedInput-root": {
															padding: "4px !important"
														}
													}}
													isOptionEqualToValue={(option, value) => option.id === value.id}
													renderInput={(params) => <MDInput label="Kategori Paket" {...params} />}
												/>
											</Grid>
										</Grid>

										{/* Jumlah Hari Tour */}
										<Grid container spacing={3}>
											<Grid item xs={12} md={12} lg={12}>
												<MDBox mb={1} width="100%" display="flex" justifyContent="flex-start" alignItems="center">
													<MDTypography
														component="label"
														variant="button"
														fontWeight="medium"
														color="text"
														textTransform="capitalize"
													>
														Jumlah Hari Tour
													</MDTypography>
												</MDBox>

												<Grid container spacing={3} mb={1}>
													{/* Days */}
													<Grid item xs={12} md={4} lg={4}>
														<MDInput
															id="days"
															type="text"
															label="Hari"
															value={this.state.days}
															onChange={this.handleChange}
															onBlur={this.handleBlur}
															success={this.state.success ? this.state.success.days : false}
															error={this.state.error ? this.state.error.days : false}
															fullWidth
														/>
													</Grid>
													{/* Departure Date */}
													<Grid item xs={12} md={4} lg={4}>
														<MDDatePicker
															input={{
																id: "departureDate",
																placeholder: "Tanggal Berangkat",
																fullWidth: true
															}}
															value={this.state.departureDate}
															onChange={(value) => {
																const date = moment(value[0]).format('YYYY-MM-DD')
																this.setState({
																	departureDate: date,
																	error: { ...this.state.error, departureDate: false },
																	success: { ...this.state.success, departureDate: true }
																})
															}}
														/>
													</Grid>
													{/* arrivalDate */}
													<Grid item xs={12} md={4} lg={4}>
														<MDDatePicker
															input={{
																id: "arrivalDate",
																placeholder: "Tanggal Pulang",
																fullWidth: true
															}}
															value={this.state.arrivalDate}
															onChange={(value) => {
																const date = moment(value[0]).format('YYYY-MM-DD')
																this.setState({
																	arrivalDate: date,
																	error: { ...this.state.error, arrivalDate: false },
																	success: { ...this.state.success, arrivalDate: true }
																})
															}}
														/>
													</Grid>
												</Grid>
											</Grid>
										</Grid>

										{/* Airport Departure */}
										<Grid container spacing={3}>
											<Grid item xs={12} md={12} lg={12}>
												<MDBox mb={1} width="100%" display="flex" justifyContent="flex-start" alignItems="center">
													<MDTypography
														component="label"
														variant="button"
														fontWeight="medium"
														color="text"
														textTransform="capitalize"
													>
														Bandara Keberangkatan
													</MDTypography>
												</MDBox>

												<Grid container spacing={3} mb={1}>
													{/* Airline */}
													<Grid item xs={12} md={4} lg={4}>
														<MDInput
															id="airportDepartureAirline"
															type="text"
															label="Maskapai"
															value={this.state.airportDeparture && this.state.airportDeparture.airline ? this.state.airportDeparture.airline : ""}
															onBlur={this.handleBlur}
															onChange={(e) => {
																const value = e.target.value
																let airportDeparture = { ...this.state.airportDeparture, airline: value }
																this.setState({ airportDeparture })
															}}
															success={this.state.success ? this.state.success.airportDepartureAirline : false}
															error={this.state.error ? this.state.error.airportDepartureAirline : false}
															fullWidth
														/>
													</Grid>
													{/* DepartureFrom */}
													<Grid item xs={12} md={4} lg={4}>
														<MDInput
															id="airportDepartureFrom"
															type="text"
															label="Dari"
															value={this.state.airportDeparture && this.state.airportDeparture.from ? this.state.airportDeparture.from : ""}
															onChange={(e) => {
																const value = e.target.value
																let airportDeparture = { ...this.state.airportDeparture, from: value }
																this.setState({ airportDeparture })
															}}
															onBlur={this.handleBlur}
															success={this.state.success ? this.state.success.airportDepartureFrom : false}
															error={this.state.error ? this.state.error.airportDepartureFrom : false}
															fullWidth
														/>
													</Grid>
													{/* DepartureTo */}
													<Grid item xs={12} md={4} lg={4}>
														<MDInput
															id="airportDepartureTo"
															type="text"
															label="Ke"
															value={this.state.airportDeparture && this.state.airportDeparture.to ? this.state.airportDeparture.to : ""}
															onChange={(e) => {
																const value = e.target.value
																let airportDeparture = { ...this.state.airportDeparture, to: value }
																this.setState({ airportDeparture })
															}}
															onBlur={this.handleBlur}
															success={this.state.success ? this.state.success.airportDepartureTo : false}
															error={this.state.error ? this.state.error.airportDepartureTo : false}
															fullWidth
														/>
													</Grid>
												</Grid>
											</Grid>
										</Grid>

										{/* Airport Homecoming */}
										<Grid container spacing={3}>
											<Grid item xs={12} md={12} lg={12}>
												<MDBox mb={1} width="100%" display="flex" justifyContent="flex-start" alignItems="center">
													<MDTypography
														component="label"
														variant="button"
														fontWeight="medium"
														color="text"
														textTransform="capitalize"
													>
														Bandara Kepulangan
													</MDTypography>
												</MDBox>
												<Grid container spacing={3} mb={1}>
													{/* Airline */}
													<Grid item xs={12} md={4} lg={4}>
														<MDInput
															id="airportReturnAirline"
															type="text"
															label="Maskapai"
															value={this.state.airportReturn && this.state.airportReturn.airline ? this.state.airportReturn.airline : ""}
															onBlur={this.handleBlur}
															onChange={(e) => {
																const value = e.target.value
																let airportReturn = { ...this.state.airportReturn, airline: value }
																this.setState({ airportReturn })
															}}
															success={this.state.success ? this.state.success.airportReturnAirline : false}
															error={this.state.error ? this.state.error.airportReturnAirline : false}
															fullWidth
														/>
													</Grid>
													{/* Departure From */}
													<Grid item xs={12} md={4} lg={4}>
														<MDInput
															id="airportReturnFrom"
															type="text"
															label="Dari"
															value={this.state.airportReturn && this.state.airportReturn.from ? this.state.airportReturn.from : ""}
															onChange={(e) => {
																const value = e.target.value
																let airportReturn = { ...this.state.airportReturn, from: value }
																this.setState({ airportReturn })
															}}
															onBlur={this.handleBlur}
															success={this.state.success ? this.state.success.airportReturnFrom : false}
															error={this.state.error ? this.state.error.airportReturnFrom : false}
															fullWidth
														/>
													</Grid>
													{/* Departure To */}
													<Grid item xs={12} md={4} lg={4}>
														<MDInput
															id="airportReturnTo"
															type="text"
															label="Ke"
															value={this.state.airportReturn && this.state.airportReturn.to ? this.state.airportReturn.to : ""}
															onChange={(e) => {
																const value = e.target.value
																let airportReturn = { ...this.state.airportReturn, to: value }
																this.setState({ airportReturn })
															}}
															onBlur={this.handleBlur}
															success={this.state.success ? this.state.success.airportReturnTo : false}
															error={this.state.error ? this.state.error.airportReturnTo : false}
															fullWidth
														/>
													</Grid>
												</Grid>
											</Grid>
										</Grid>

										{/* List Hotel */}
										<Grid container spacing={3} mb={2}>
											<Grid item xs={12} md={12} lg={12}>
												<MDBox mb={1} width="100%" display="flex" justifyContent="flex-start" alignItems="center">
													<MDBox mr={1}>
														<MDButton
															variant="gradient"
															color="info"
															size="small"
															iconOnly
															onClick={() => this.productHotelRef.current.setShow({ show: true })}
														>
															<Icon>add</Icon>
														</MDButton>
													</MDBox>
													<MDTypography
														component="label"
														variant="button"
														fontWeight="medium"
														color="text"
														textTransform="capitalize"
													>
														Hotel
													</MDTypography>
												</MDBox>
												<ListHotel
													type="form"
													rows={this.state.dataHotels}
													ref={this.listHotelRef}
													onDelete={this.onDeleteHotel}
												/>
											</Grid>
										</Grid>

										{/* Price */}
										<Grid container spcaing={3}>
											<Grid item xs={12} md={12} lg={12} mb={2}>
												{/* Button Action */}
												<MDBox mb={1} width="100%" display="flex" justifyContent="flex-start" alignItems="center">
													<MDBox mr={1}>
														<MDButton
															onClick={this.onAddPrice}
															variant="gradient"
															color="info"
															size="small"
															iconOnly
														>
															<Icon>add</Icon>
														</MDButton>
													</MDBox>
													<MDTypography
														component="label"
														variant="button"
														fontWeight="medium"
														color="text"
														textTransform="capitalize"
													>
														Harga Paket Tour
													</MDTypography>
												</MDBox>
												{this.renderPrice()}
											</Grid>
										</Grid>

										{/* Tour Leader , Seat & Logo*/}
										<Grid container spacing={3} mb={2}>
											{/* TourLeader */}
											<Grid item xs={12} md={4} lg={4}>
												<Autocomplete
													disableClearable
													value={this.state.tourLeader}
													open={this.state.tourLeaderOpen}
													onOpen={() => this.setState({ tourLeaderOpen: true })}
													onClose={() => this.setState({ tourLeaderOpen: false })}
													onFocus={() => this.setState({ tourLeaderOpen: true })}
													options={this.state.tourLeaders}
													onChange={(e, value) => {
														if (value) {
															this.setState({
																tourLeader: value,
																error: { ...this.state.error, tourLeader: false },
																success: { ...this.state.success, tourLeader: true }
															})
														}
													}}
													sx={{
														".MuiAutocomplete-input": {
															padding: "7.5px 5px 7.5px 8px !important"
														},
														".MuiOutlinedInput-root": {
															padding: "4px !important"
														}
													}}
													isOptionEqualToValue={(option, value) => option.id === value.id}
													renderInput={(params) => <MDInput label="Tour Leader" {...params} />}
												/>
											</Grid>
											{/* Qouta Seat */}
											<Grid item xs={12} md={4} lg={4}>
												<MDInput
													id="seat"
													type="text"
													label="Jumlah Kursi / Porsi"
													value={this.state.seat}
													onChange={this.handleChange}
													onBlur={this.handleBlur}
													success={this.state.success ? this.state.success.seat : false}
													error={this.state.error ? this.state.error.seat : false}
													fullWidth
												/>
											</Grid>
											{/* Upload Brosur */}
											<Grid item xs={12} lg={4} md={4}>
												<MDBox mb={2}>
													<input
														type="file"
														name="image"
														ref={this.fileImageRef}
														onChange={(e) => {
															if (e.target.files.length === 1) {
																const file = e.target.files[0];
																const filename = file.name;
																const ext = filename.split(".")[1];
																this.setState({
																	image: file,
																	imageFilename: filename,
																	error: { ...this.state.error, image: false },
																	success: { ...this.state.success, image: true },
																});
															}
														}}
														hidden
													/>
													<MDInput
														fullWidth
														value={this.state.imageFilename}
														label="Pilih Gambar"
														onClick={() => {
															this.fileImageRef.current.click();
														}}
														readOnly
													/>
												</MDBox>
											</Grid>
										</Grid>

										{/* Description */}
										<Grid container spacing={3} mb={2}>
											<Grid item xs={12} md={12} lg={12}>
												<MDEditor
													id="description"
													label="Deskripsi"
													value={this.state.description}
													onChange={(content, delta, source, editorue) => {
														if (content) {
															this.setState({
																description: content,
																error: { ...this.state.error, description: false },
																success: { ...this.state.success, description: true },
															});
														}
													}}
													onBlur={(previousRange, source, editor) => {
														const index = previousRange.index
														if (index > 0) {
															this.setState({
																error: { ...this.state.error, description: false },
																success: { ...this.state.success, description: true }
															})
														}
														else {
															this.setState({
																error: { ...this.state.error, description: true },
																success: { ...this.state.success, description: false },
															})
														}
													}}
													formats={[
														'header',
														'bold', 'italic', 'underline', 'strike', 'blockquote',
														'list', 'bullet', 'indent',
														'link', 'image'
													]}
													modules={{
														toolbar: [
															[{ 'header': [1, 2, false] }],
															['bold', 'italic', 'underline', 'strike', 'blockquote'],
															[{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
															['link', 'image'],
															['clean']
														],
													}}
												/>
											</Grid>
										</Grid>

										{/* BUTTON ACTION */}
										{[2, 3].includes(user.roleId) && (
											<Grid container spacing={3} mb={2}>
												<Grid item xs={12} md={12} lg={12}>
													<MDBox pt={3} display="flex" justifyContent="space-between">
														{this.props.action == "edit" ? (
															<ButtonBack label={"KEMBALI"} />
														) : (
															<MDButton variant="gradient" color="error" onClick={this.resetForm}>
																RESET
															</MDButton>
														)}

														<MDButton
															type="button"
															variant="gradient"
															color="info"
															disabled={this.state.disabledSubmit}
															onClick={this.handleSubmit}
														>
															SUBMIT
														</MDButton>
													</MDBox>
												</Grid>
											</Grid>
										)}
									</MDBox>
								</MDBox>
							</Card>
						</Grid>
					</Grid>
				</MDBox>

			</>
		)
	}
}

TourPackage.propTypes = {
	title: PropTypes.string.isRequired,
	action: PropTypes.oneOf(['add', 'edit']).isRequired
}

export default withRouter(TourPackage)