/* eslint-disable react/prop-types */
import React, { Fragment } from "react"
import { Document, Page, Text, Image, View, StyleSheet, Font } from "@react-pdf/renderer"

import logo from "assets/img/logoApp.png"

import RobotoMonoRegular from "assets/fonts/RobotoMono/RobotoMono-Regular.ttf"
import RobotMonoBold from "assets/fonts/RobotoMono/RobotoMono-Bold.ttf"
import RobotoMonoBoldItalic from "assets/fonts/RobotoMono/RobotoMono-BoldItalic.ttf"
import RobotoMonoItalic from "assets/fonts/RobotoMono/RobotoMono-Italic.ttf"

import InvoiceStyle from "helper/styles/repaymentStyle"

import secureStorage from "libs/secureStorage"

// Font
Font.register({
	family: 'RobotoMono',
	format: 'truetype',
	fonts: [
		{ src: RobotoMonoRegular },
		{ src: RobotoMonoBoldItalic },
		{ src: RobotoMonoItalic },
		{ src: RobotMonoBold },
	]
});
// Create styles
const styles = StyleSheet.create(InvoiceStyle)

class PdfRegistrasi extends React.Component {
	constructor(props) {
		super(props)
	}

	componentDidMount() { }

	render() {
		const data = this.props.data
		const user = secureStorage.getItem('user')

		return (
			<Document>
				<Page size="A4" style={styles.page}>

				</Page>
			</Document>
		)
	}
}

export default PdfRegistrasi