const RekomendationStyle = {
  page: {
    fontFamily: "RobotoMono",
    fontSize: 6,
    lineHeight: 1.5,
    flexDirection: "column",
  },
  headerContainer: {
    width: "100%",
    marginBottom: 2,
    display: "flex",
    flexDirection: "col",
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 30,
    paddingHorizontal: 40,
  },
  headerTitle: {
    fontSize: 20,
    fontWeight: "bold",
  },
  headerCompany: {
    fontSize: 16,
    fontWeight: "bold",
  },
  headerDate: {
    fontSize: 12,
  },
  contentContainer: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 15,
    // paddingVertical: 20,
  },
  tableRow: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "90%",
  },
  tableTitle: {
    fontSize: 12,
    fontWeight: "bold",
    merginLeft: 0,
    width: "54%",
    marginBottom: 5,
    marginTop: 5,
  },
  tableCaption: {
    fontSize: 12,
    marginLeft: 20,
    width: "50%",
    marginBottom: 3,
    borderBottomWidth: 0.2,
  },
  tableValue: {
    width: "30%",
    fontSize: 12,
    paddingRight: 8,
    textAlign: "left",
    marginBottom: 3,
    borderBottomWidth: 0.2,
  },
  tableTotal: {
    width: "30%",
    fontSize: 12,
    paddingRight: 8,
    textAlign: "left",
    borderBottomWidth: 1,
    fontWeight: "bold",
    marginBottom: 5,
    marginTop: 5,
  },
};

export default RekomendationStyle;
