/* eslint-disable react/prop-types */
import React from "react";
import { Link, Navigate } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import MDEditor from "components/MDEditor";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import ModalNotif from "contents/Components/ModalNotif";
import withRouter from "contents/Components/withRouter";

import MiniFormCard from "contents/Components/FormCard/MiniFormCard";

import Config from "config";
import _ from "lodash";

import useAxios from "libs/useAxios";
import secureStorage from "libs/secureStorage";

class FormPrayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      user: null,
      title: "",
      action: "",

      sortNumber: "",
      name: "",
      arabic: "",
      latin: "",
      translate: "",
      remark: "",

      error: [],
      success: [],

      disabledSubmit: false,
      redirect: null,
    };

    this.inputRef = React.createRef();
    this.fileRef = React.createRef();
    this.modalNotifRef = React.createRef();
  }

  componentDidMount() {
    this.inputRef && this.inputRef.current.focus();
    const user = secureStorage.getItem("user");

    this.setState({
      user: user ? user : null,
    });
    this.loadPath();
  }

  loadPath = () => {
    const pathname = window.location.pathname;
    const index = pathname.indexOf("edit");
    if (index === -1) {
      this.loadSortNumber();
      this.setState({
        title: "Tambah Do'a Manasik Baru",
        action: "add",
      });
    } else {
      const id = this.props.params?.id;
      this.loadDetail(id);
      this.setState({
        id,
        title: "Ubah Do'a Manasik",
        action: "edit",
        error: { ...this.state.error, name: false },
        success: { ...this.state.success, name: true },
      });
    }
  };

  loadSortNumber = () => {
    useAxios()
      .post(`${Config.ApiUrl}/master/prayer/sort`)
      .then((response) => {
        const sortNumber = response.data;
        this.setState({ sortNumber });
      });
  };

  loadDetail = (id) => {
    useAxios()
      .post(`${Config.ApiUrl}/master/prayer/get`, { id })
      .then((response) => {
        const data = response.data;
        this.setState({
          sortNumber: data.sortNumber,
          name: data.title,
          arabic: data.arabic,
          latin: data.latin,
          translate: data.translate,
          remark: data.remark,
          id: data.id,

          success: {
            ...this.state.success,
            sortNumber: data.sortNumber ? true : false,
            title: data.title ? true : false,
            arabic: data.arabic ? true : false,
            latin: data.latin ? true : false,
            translate: data.translate ? true : false,
            remark: data.remark ? true : false,
            id: data.id ? true : false,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        this.modalNotifRef.current.setShow({
          modalTitle: "Gagal",
          modalMessage: err.response ? err.response.data : "Koneksi jaringan terputus",
          onClose: () => {
            this.setState({ redirect: "/master/prayer" });
          },
        });
      });
  };

  handleChange = (e) => {
    const { id, value } = e.target;
    this.setState({
      [id]: value,
    });
  };

  handleBlur = (e) => {
    const user = this.state.user;
    const { id, value } = e.target;
    if (value) {
      this.setState({
        success: { ...this.state.success, [e.target.id]: true },
        error: { ...this.state.error, [e.target.id]: false },
      });
    } else {
      this.setState({
        success: { ...this.state.success, [e.target.id]: false },
        error: { ...this.state.error, [e.target.id]: true },
      });
    }
  };

  handleKeyDown = (e) => {
    // if (e.key === "Enter") this.handleSubmit()
  };

  handleSubmit = () => {
    const success = this.state.success;

    console.log({ success });
    if (success.name) {
      this.sendData();
    } else {
      this.modalNotifRef.current.setShow({
        modalTitle: "Gagal",
        modalMessage: "Data form masih belum lengkap, Silahkan di cek kembali !",
      });
    }
  };

  sendData = () => {
    this.setState({
      disabledSubmit: true,
    });

    const payload = {
      sortNumber: this.state.sortNumber && parseInt(this.state.sortNumber),
      title: this.state.name,
      arabic: this.state.arabic,
      latin: this.state.latin,
      translate: this.state.translate,
      remark: this.state.remark,
      id: this.state.id,
    };

    console.log("payload", payload)
    // console.log(formData);
    const url = this.state.action === "add" ? "/master/prayer/create" : "/master/prayer/update";
    useAxios()
      .post(`${Config.ApiUrl}${url}`, payload)
      .then((response) => {
        this.modalNotifRef.current.setShow({
          modalTitle: "Sukses",
          modalMessage: response.data,
          onClose: () => {
            this.setState({
              redirect: "/master/prayer",
              disabledSubmit: false,
            });
          },
        });
      })
      .catch((err) => {
        this.setState({ disabledSubmit: false });
        if (err.response) {
          this.modalNotifRef.current.setShow({
            modalTitle: "Gagal",
            modalMessage: err.response ? err.response.data : "Terjadi kesalahan pada system",
          });
        }
        // eslint-disable-next-line no-empty
        else {
          this.modalNotifRef.current.setShow({
            modalTitle: "Gagal",
            modalMessage: "Koneksi jaringan terputus",
          });
        }
      });
  };

  render() {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />;
    }

    return (
      <MiniFormCard>
        <ModalNotif ref={this.modalNotifRef} />
        <MDBox display="flex" p={3} lineHeight={1} justifyContent="center">
          <MDTypography variant="h5" fontWeight="medium">
            {this.state.title}
          </MDTypography>
        </MDBox>

        <MDBox p={3}>
          <MDBox component="form" role="form">
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12}>
                {/* SORT NUMBer */}
                <MDBox mb={2}>
                  <MDInput
                    id="sortNumber"
                    type="text"
                    inputRef={this.inputRef}
                    label="Nomor Urut"
                    value={this.state.sortNumber}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    onKeyDown={this.handleKeyDown}
                    success={this.state.success ? this.state.success.sortNumber : false}
                    error={this.state.error ? this.state.error.sortNumber : false}
                    fullWidth
                  />
                </MDBox>
                {/* Title */}
                <MDBox mb={2}>
                  <MDInput
                    id="name"
                    type="text"
                    label="Judul"
                    value={this.state.name}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    onKeyDown={this.handleKeyDown}
                    success={this.state.success ? this.state.success.name : false}
                    error={this.state.error ? this.state.error.name : false}
                    fullWidth
                  />
                </MDBox>
                {/* ARABIC */}
                <MDBox mb={2}>
                  <MDInput
                    id="arabic"
                    type="text"
                    label="Do'a (Bahasa Arab)"
                    multiline
                    rows={5}
                    value={this.state.arabic}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    onKeyDown={this.handleKeyDown}
                    success={this.state.success ? this.state.success.arabic : false}
                    error={this.state.error ? this.state.error.arabic : false}
                    fullWidth
                  />
                </MDBox>
                {/* LATIN */}
                <MDBox mb={2}>
                  <MDInput
                    id="latin"
                    type="text"
                    label="Do'a (Latin)"
                    multiline
                    rows={5}
                    value={this.state.latin}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    onKeyDown={this.handleKeyDown}
                    success={this.state.success ? this.state.success.latin : false}
                    error={this.state.error ? this.state.error.latin : false}
                    fullWidth
                  />
                </MDBox>
                {/* TRANSLATE */}
                <MDBox mb={2}>
                  <MDInput
                    id="translate"
                    type="text"
                    label="Terjemahan"
                    multiline
                    rows={5}
                    value={this.state.translate}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    onKeyDown={this.handleKeyDown}
                    success={this.state.success ? this.state.success.translate : false}
                    error={this.state.error ? this.state.error.translate : false}
                    fullWidth
                  />
                </MDBox>
                <MDBox pt={3} display="flex" justifyContent="space-between">
                  <MDButton
                    variant="gradient"
                    color="error"
                    component={Link}
                    to={{ pathname: "/master/prayer" }}
                  >
                    KEMBALI
                  </MDButton>
                  <MDButton
                    type="button"
                    variant="gradient"
                    color="info"
                    onKeyDown={this.handleKeyDown}
                    disabled={this.state.disabledSubmit}
                    onClick={this.handleSubmit}
                  >
                    SUBMIT
                  </MDButton>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
      </MiniFormCard>
    );
  }
}

export default withRouter(FormPrayer);
