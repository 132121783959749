/* eslint-disable react/prop-types */
import React, { Fragment } from "react";
import { Document, Page, Text, Image, View, StyleSheet, Font } from "@react-pdf/renderer";

import logo from "assets/img/logoApp.png";

import RobotoMonoRegular from "assets/fonts/RobotoMono/RobotoMono-Regular.ttf";
import RobotMonoBold from "assets/fonts/RobotoMono/RobotoMono-Bold.ttf";
import RobotoMonoBoldItalic from "assets/fonts/RobotoMono/RobotoMono-BoldItalic.ttf";
import RobotoMonoItalic from "assets/fonts/RobotoMono/RobotoMono-Italic.ttf";

import FinancialStatementStyle from "helper/styles/financialStatementStyle";
import moment from "moment";
import { formatCurrency } from "libs/currency"

// Font
Font.register({
	family: "RobotoMono",
	format: "truetype",
	fonts: [
		{ src: RobotoMonoRegular },
		{ src: RobotoMonoBoldItalic },
		{ src: RobotoMonoItalic },
		{ src: RobotMonoBold },
	],
});

// Create styles
const styles = StyleSheet.create(FinancialStatementStyle);

class PdfFinancialOwner extends React.Component {

	constructor(props) {
		super(props)
		this.state = {}
	}

	componentDidMount() { }

	render() {
		const data = this.props.data;
		console.log({ data })
		return (
			<Document>
				<Page size="A4" style={styles.page}>
					{/* HAADER */}
					<View style={styles.headerContainer}>
						<Text style={styles.headerTitle}>Laporan Keuangan</Text>
						<Text style={styles.headerCompany}>MABRUR</Text>
						<Text style={styles.headerDate}>
							Dari {data.startDate} sampai {data.endDate}
						</Text>
					</View>

					<View style={{ ...styles.contentContainer, paddingVertical: 0 }}>
						<Fragment>
							<View style={{ width: "76%", borderTopWidth: 1 }}></View>
							<View style={styles.tableRow}>
								<Text style={styles.tableTitle}>Rincian</Text>
								<Text style={{ width: "30%" }}></Text>
							</View>
							<View style={styles.tableRow}>
								<Text style={styles.tableCaption}>Dana Masuk</Text>
								<Text style={styles.tableValue}>{formatCurrency(data.incomeFund)}</Text>
							</View>
							<View style={styles.tableRow}>
								<Text style={styles.tableCaption}>Dana Keluar</Text>
								<Text style={styles.tableValue}>{formatCurrency(data.outFund)}</Text>
							</View>
							<View style={styles.tableRow}>
								<Text style={styles.tableCaption}>Dana Keluar Manual</Text>
								<Text style={styles.tableValue}>{formatCurrency(data.manualOutFund)}</Text>
							</View>

							<View style={styles.tableRow}>
								<Text style={{ ...styles.tableTitle, borderBottomWidth: 1 }}>Total Transaksi</Text>
								<Text style={styles.tableTotal}>{data.trxCount}</Text>
							</View>
							<View style={styles.tableRow}>
								<Text style={{ ...styles.tableTitle, borderBottomWidth: 1 }}>Total Pendapatan</Text>
								<Text style={styles.tableTotal}>{formatCurrency(data.totalAmount)}</Text>
							</View>
						</Fragment>
					</View>
				</Page>
			</Document >
		)
	}
}

export default PdfFinancialOwner