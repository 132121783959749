import React from "react";
import PropTypes from "prop-types";
// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

import Copy from "@mui/icons-material/FileCopy";
import Tooltip from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDDatePicker from "components/MDDatePicker";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import ModalNotif from "contents/Components/ModalNotif";
import MiniFormCard from "contents/Components/FormCard/MiniFormCard";

import Config from "config";
import moment from "moment";
import _ from "lodash";
import * as EmailValidator from "email-validator";

import useAxios from "libs/useAxios";
import secureStorage from "libs/secureStorage";

class AgenBasicInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      action: "",
      id: null,

      prefix: "",
      numberSK: "",
      name: "",
      leader: "",
      email: "",
      phone: "",
      password: "",
      repassword: "",
      address: "",
      datePermit: "",
      province: null,
      district: null,

      provinceOpen: false,
      districtOpen: false,

      provinces: [],
      districts: [],

      error: [],
      success: [],

      disabledSubmit: false,
      redirect: null,
    };

    this.inputRef = React.createRef();
    this.modalNotifRef = React.createRef();
  }

  componentDidMount() {
    const user = secureStorage.getItem("user");
    this.inputRef && this.inputRef.current.focus();
    this.setState({ userId: user.id }, () => {
      this.loadData(user.id);
    });
    this.loadProvince();
  }

  loadData = (userId) => {
    useAxios()
      .post(`${Config.ApiUrl}/manage/agent/get`, { userId })
      .then((response) => {
        const data = response.data

        const province = data && data.Province ? data.Province : null
        const district = data && data.District ? data.District : null
        province && this.loadDistrict(province?.id) // load list district

        this.setState({
          id: data.id,
          prefix: data.prefix,
          numberSK: data.numberSK ?? "",
          name: data.name ?? "",
          email: data.email ? data.email : "",
          phone: data.phone ? data.phone : "",
          address: data.address ?? "",
          leader: data.leader ?? "",
          datePermit: data && data.datePermit ? data.datePermit : "",
          province: province ? { id: province.id, label: province.name } : null,
          district: district ? { id: district.id, label: district.name } : null,
          success: {
            ...this.state.success,
            prefix: data.prefix ? true : false,
            numberSK: data.numberSK ? true : false,
            name: data.name ? true : false,
            leader: data.leader ? true : false,
            email: data.email ? true : false,
            phone: data.phone ? true : false,
            province: province ? true : false,
            district: district ? true : false,
            address: data.address ? true : false,
            datePermit: data && data.datePermit ? true : false,
            password: true,
            repassword: true,
          }
        })
        // this.setData(data)
      })
      .catch((err) => {
        console.log(err);
        this.setState({ isLoading: false });
      });
  };

  loadProvince = () => {
    useAxios()
      .post(`${Config.ApiUrl}/master/region/province/dropdown`, { countryId: 1 })
      .then((response) => {
        const data = response.data;
        const provinces = data.map((item) => ({
          id: item.id,
          label: item.name,
        }));
        this.setState({ provinces });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  loadDistrict = (provinceId) => {
    useAxios()
      .post(`${Config.ApiUrl}/master/region/district/dropdown`, { provinceId })
      .then(response => {
        const data = response.data
        const districts = data.map(item => ({ id: item.id, label: item.name }))
        this.setState({ districts })
      })
      .catch(err => {
        console.log(err)
      })
  }

  handleChange = (e) => {
    const { id, value } = e.target;
    this.setState({
      [id]: value,
    });
  };

  handleBlur = (e) => {
    const user = this.state.user;
    const { id, value } = e.target;
    // eslint-disable-next-line no-empty
    if (value) {
      if (id === "email" && !EmailValidator.validate(value)) {
        this.setState({
          success: { ...this.state.success, [e.target.id]: false },
          error: { ...this.state.error, [e.target.id]: true },
        });
      } else {
        this.setState({
          success: { ...this.state.success, [e.target.id]: true },
          error: { ...this.state.error, [e.target.id]: false },
        });
      }
    } else {
      this.setState({
        success: { ...this.state.success, [e.target.id]: false },
        error: { ...this.state.error, [e.target.id]: true },
      });
    }
  };

  handleSumit = () => {
    const success = this.state.success;

    this.setState({ success: { ...success } });
    if (
      success.prefix &&
      success.numberSK &&
      success.name &&
      success.leader &&
      success.email &&
      success.phone &&
      success.address &&
      success.datePermit &&
      success.province &&
      success.district
    ) {
      this.sendData();
    } else {
      this.modalNotifRef.current.setShow({
        modalTitle: "Gagal",
        modalMessage: "Data form masih belum lengkap, Silahkan di cek kembali !",
      });
    }
  };

  sendData = () => {
    this.setState({ disabledSubmit: true });
    const formData = new FormData();

    const payload = {
      id: this.state.id,
      prefix: this.state.prefix,
      numberSK: this.state.numberSK,
      name: this.state.name,
      leader: this.state.leader,
      email: this.state.email,
      phone: this.state.phone,
      address: this.state.address,
      datePermit: this.state.datePermit,
      provinceId: this.state.province?.id,
      districtId: this.state.district?.id
    };

    useAxios()
      .post(`${Config.ApiUrl}/manage/agent/update`, payload)
      .then((response) => {
        this.setState({ disabledSubmit: false });
        this.modalNotifRef.current.setShow({
          modalTitle: "Sukses",
          modalMessage: response.data,
        });
      })
      .catch((err) => {
        this.setState({ disabledSubmit: false });
        if (err.response) {
          this.modalNotifRef.current.setShow({
            modalTitle: "Gagal",
            modalMessage: err.response.data,
          });
        }
        // eslint-disable-next-line no-empty
        else {
          this.modalNotifRef.current.setShow({
            modalTitle: "Gagal",
            modalMessage: "Koneksi jaringan terputus",
          });
        }
      });
  };

  render() {
    const user = secureStorage.getItem("user");

    return (
      <>
        <ModalNotif ref={this.modalNotifRef} />
        <MiniFormCard id="basic-info" sx={{ overflow: "visible" }}>
          <MDBox p={3}>
            <MDTypography variant="h5">Basic Info</MDTypography>
          </MDBox>
          <MDBox component="form" pb={3} px={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12}>
                {/* Prefix Code */}
                <MDBox mb={2}>
                  <MDInput
                    disabled
                    readOnly
                    id="prefix"
                    type="text"
                    label="Prefix Kode"
                    inputRef={this.inputRef}
                    value={this.state.prefix}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    success={this.state.success ? this.state.success.prefix : false}
                    error={this.state.error ? this.state.error.prefix : false}
                    fullWidth
                  />
                </MDBox>
                {/* Nomor SK */}
                <MDBox mb={2}>
                  <MDInput
                    id="numberSK"
                    type="text"
                    label="Nomor SK"
                    value={this.state.numberSK}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    success={this.state.success ? this.state.success.numberSK : false}
                    error={this.state.error ? this.state.error.numberSK : false}
                    fullWidth
                  />
                </MDBox>
                {/* Name */}
                <MDBox mb={2}>
                  <MDInput
                    id="name"
                    type="text"
                    label="Nama Lembaga"
                    value={this.state.name}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    success={this.state.success ? this.state.success.name : false}
                    error={this.state.error ? this.state.error.name : false}
                    fullWidth
                  />
                </MDBox>
                {/* Leader */}
                <MDBox mb={2}>
                  <MDInput
                    id="leader"
                    type="text"
                    label="Pemimpin"
                    value={this.state.leader}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    success={this.state.success ? this.state.success.leader : false}
                    error={this.state.error ? this.state.error.leader : false}
                    fullWidth
                  />
                </MDBox>
                {/* Date Permit */}
                <MDBox mb={2}>
                  <MDDatePicker
                    input={{
                      id: "datePermit",
                      placeholder: "Tanggal Izin / SK Lembaga",
                      fullWidth: true
                    }}
                    value={this.state.datePermit}
                    onChange={(value) => {
                      const date = moment(value[0]).format('YYYY-MM-DD')
                      this.setState({
                        datePermit: date,
                        error: { ...this.state.error, datePermit: false },
                        success: { ...this.state.success, datePermit: true }
                      })
                    }}
                  />
                </MDBox>
                {/* Email */}
                <MDBox mb={2}>
                  <MDInput
                    id="email"
                    type="text"
                    label="Email"
                    value={this.state.email}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    success={this.state.success ? this.state.success.email : false}
                    error={this.state.error ? this.state.error.email : false}
                    fullWidth
                  />
                </MDBox>
                {/* Phone */}
                <MDBox mb={2}>
                  <MDInput
                    id="phone"
                    type="text"
                    label="Nomor Telpon"
                    value={this.state.phone}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    success={this.state.success ? this.state.success.phone : false}
                    error={this.state.error ? this.state.error.phone : false}
                    fullWidth
                  />
                </MDBox>
                {/* Address */}
                <MDBox mb={2}>
                  <MDInput
                    id="address"
                    type="text"
                    rows={3}
                    multiline
                    label="Alamat"
                    value={this.state.address}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    success={this.state.success ? this.state.success.address : false}
                    error={this.state.error ? this.state.error.address : false}
                    fullWidth
                  />
                </MDBox>
                {/* Province */}
                <MDBox mb={2}>
                  <Autocomplete
                    fullWidth
                    id="province"
                    open={this.state.provinceOpen}
                    onOpen={() => this.setState({ provinceOpen: true })}
                    onClose={() => this.setState({ provinceOpen: false })}
                    onFocus={() => this.setState({ provinceOpen: true })}
                    value={this.state.province}
                    options={this.state.provinces}
                    onChange={(e, newValue) => {
                      if (newValue) {
                        this.setState({
                          province: newValue,
                          district: null,
                          districts: [],
                          error: { ...this.state.error, province: false },
                          success: { ...this.state.success, province: true }
                        })
                        this.loadDistrict(newValue.id)
                      }
                    }}
                    sx={{
                      ".MuiAutocomplete-input": {
                        padding: "7.5px 5px 7.5px 8px !important"
                      },
                      ".MuiOutlinedInput-root": {
                        padding: "4px !important"
                      }
                    }}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    renderInput={(params) => <MDInput label="Pilih Provinsi" {...params} />}
                  />
                </MDBox>
                {/* District */}
                <MDBox mb={2}>
                  <Autocomplete
                    fullWidth
                    id="district"
                    open={this.state.districtOpen}
                    onOpen={() => this.setState({ districtOpen: true })}
                    onClose={() => this.setState({ districtOpen: false })}
                    onFocus={() => this.setState({ districtOpen: true })}
                    value={this.state.district}
                    options={this.state.districts}
                    onChange={(e, value) => {
                      if (value) {
                        this.setState({
                          district: value,
                          error: { ...this.state.error, district: false },
                          success: { ...this.state.success, district: true }
                        })
                      }
                    }}
                    sx={{
                      ".MuiAutocomplete-input": {
                        padding: "7.5px 5px 7.5px 8px !important"
                      },
                      ".MuiOutlinedInput-root": {
                        padding: "4px !important"
                      }
                    }}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    renderInput={(params) => <MDInput label="Pilih Kabupaten/Kota" {...params} />}
                  />
                </MDBox>
              </Grid>
            </Grid>

            <MDBox width="100%" display="flex" justifyContent="flex-end" flexWrap="wrap">
              <MDButton
                variant="gradient"
                color="dark"
                size="small"
                disabled={this.state.disabledSubmit}
                onClick={this.handleSumit}
              >
                UPDATE
              </MDButton>
            </MDBox>
          </MDBox>
        </MiniFormCard>
      </>
    );
  }
}

export default AgenBasicInfo;
