const bgColor = "#2c1061"
const borderColor = "#391380"

const PaymentStyle = {
	page: {
		fontFamily: 'RobotoMono',
		fontSize: 6,
		lineHeight: 1.5,
		flexDirection: "column"
	},
	headerContainer: {
		width: "100%",
		marginBottom: 2,
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		fontSize: 5,
		paddingVertical: 20,
		paddingHorizontal: 30,
		backgroundColor: bgColor
	},
	headerContent: {
		width: "100%",
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "center"
	},
	headerLogo: {
		width: 100,
		height: 80,
		marginRight: 10,
	},
	company: {
		fontSize: 14,
		fontWeight: "bold",
		color: "#FFF"
	},
	headerCaption: {
		fontSize: 11,
		color: "#FFF"
	},
	contentContainer: {
		marginHorizontal: 30
	},
	titleContainer: {
		width: "100%",
		display: "flex",
		justifyContent: "center",
		flexDirection: 'column',
		marginTop: 10,
		marginBottom: 5
	},
	titleText: {
		fontSize: 18,
		fontWeight: "bold",
		textAlign: "center",
		textTransform: 'uppercase',
		paddingBottom: 2,
		textDecoration: "underline",
	},
	periodText: {
		fontSize: 12,
		fontWeight: "medium",
		textAlign: "center",
		textTransform: "uppercase",
		marginTop: 3,
	},
	// Bill Content
	billContainer: {
		width: "100%",
		marginTop: 20,
		marginBottom: 10,
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center"
	},
	billLeft: {
		width: "60%",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-start"
	},
	billRight: {
		width: "40%",
	},
	billRow: {
		width: "100%",
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "center"
	},
	billCaption: {
		textAlign: "left",
		fontSize: 9,
		paddingLeft: 5
	},
	// Table
	tableContainer: {
		flexDirection: 'row',
		flexWrap: 'wrap',
		marginTop: 10,
	},
	tableHeadContainer: {
		flexDirection: 'row',
		borderBottomColor: bgColor,
		backgroundColor: bgColor,
		borderBottomWidth: 1,
		alignItems: 'center',
		height: 24,
		textAlign: 'center',
		fontWeight: "bold",
		flexGrow: 1,
		paddingHorizontal: 8
	},
	tableHeadCaption: {
		color: "#FFF",
		textAlign: "left",
		width: "20%",
		fontSize: 12,
		textTransform: "uppercase",
	},
	tableRow: {
		flexDirection: 'row',
		borderColor: borderColor,
		borderBottomWidth: 1,
		borderLeftWidth: 1,
		borderRightWidth: 1,
		alignItems: 'center',
		height: 24,
		paddingHorizontal: 8
	},
	tableRowCaption: {
		fontSize: 10,
		width: "20%",
		textAlign: "left",
		textTransform: "uppercase",
	},
	// NOTE
	noteContainer: {
		marginTop: 20,
		flexDirection: "column",
		justifyContent: "flex-start",
		lineHeight: 1.5
	},
	noteCaption: {
		fontSize: 10,
		fontWeight: "bold",
		textAlign: "left",
		marginBottom: 10
	},
	noteItem: {
		fontSize: 9,
		// fontWeight: "bold",
		textAlign: "left",
	},

	// Status
	statusView: {
		padding: 3,
	},
	secondary: {
		backgroundColor: "#d7d9e1",
		color: "#6c757d",
	},
	error: {
		backgroundColor: "#fcd3d0",
		color: "#f61200",
	},
	success: {
		backgroundColor: "#bce2be",
		color: "#339537",
	}
}

export default PaymentStyle