/* eslint-disable react/prop-types */
import React, { useEffect } from "react";

// react-router components
import { Routes, Route, Navigate } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import SnackBar from "@mui/material/Snackbar"
import Alert from "@mui/material/Alert"
import Button from "@mui/material/Button"

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

// Material Dashboard 2 PRO React themes
import theme from "assets/theme";
import themeDark from "assets/theme-dark";

// Material Dashboard 2 PRO React routes
import { routes } from "routes";
import Layout from "contents";
import Login from "contents/Login";
import Register from "contents/Register";
// Reset Password
import Verifycation from "contents/Login/ForgetPassword/Verifycation"
import ResetPassword from "contents/Login/ForgetPassword/ResetPassword";

import * as serviceWorker from "serviceWorker"

class App extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      history: '',
      newVersionAvailable: false,
      waitingWorker: {},
      open: false
    }
  }

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }

  UNSAFE_componentWillMount() {
    serviceWorker.register({ onUpdate: this.onServiceWorkerUpdate })
  }

  onServiceWorkerUpdate = (waitingWorker) => {
    console.log('[UPDATE VERSI]')
    this.setState({
      waitingWorker,
      newVersionAvailable: true,
      open: true
    })
  }

  updateServiceWorker = () => {
    const { waitingWorker } = this.state;
    waitingWorker && waitingWorker.postMessage && waitingWorker.postMessage('forceUpdate')
    this.handleClose()
  }

  refreshAction = (
    <React.Fragment>
      <Button color="inherit" size="small" onClick={this.updateServiceWorker}>
        UPDATE
      </Button>
      <IconButton
        aria-label="close"
        color="inherit"
        sx={{ p: 0.5 }}
        onClick={this.handleClose}
      >
        <CloseIcon />
      </IconButton>
    </React.Fragment>
  )

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ open: false })
  };

  getRoutes = (allRoutes) => {
    const index = []
    const theRoutes = allRoutes.map((route) => {
      if (route.index) index.push(<Route index element={route.component} key={route.key} />);
      if (route.collapse) {
        return (
          <Route exact path={route.route} element={route.component} key={route.key}>
            {this.getRoutes(route.collapse)}
          </Route>
        );
      } else if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }
      return null;
    });

    return theRoutes.concat(index);
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {/* SNACKBAR NOTIF */}
        <SnackBar
          open={this.state.open}
          autoHideDuration={6000}
        >
          <Alert
            onClose={this.handleClose}
            severity="info"
            sx={{ width: '100%' }}
            action={this.refreshAction}
          >
            Versi terbaru telah tersedia
          </Alert>
        </SnackBar>
        {/* END */}

        <Routes>
          <Route exact path="/login" element={<Login />} key="login" />
          <Route exact path="/register" element={<Register />} key="register-agent" />
          <Route exact path="/verifycation" element={<Verifycation />} />
          <Route exact path="/reset/:confirmCode" element={<ResetPassword />} />
          {/* <Route exact path="/register/:invitationId" element={<Register />} key="register" /> */}
          <Route path="/" element={<Layout key="layout" />}>
            {this.getRoutes(routes)}
          </Route>
          <Route path="*" element={<Navigate to="/dashboard" />} />
        </Routes>
      </ThemeProvider>
    )
  }
}

export default App