/* eslint-disable react/prop-types */
import React from "react";
import { Link, Navigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDBadge from "components/MDBadge";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

// Material Dashboard 2 PRO React examples
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import withRouter from "contents/Components/withRouter";
import Loader from "contents/Components/Loader";
import Confirm from "contents/Components/Confirm";
import ModalNotif from "contents/Components/ModalNotif";

import ButtonBack from 'contents/Components/ButtonBack';

import _ from "lodash";
import moment from "moment";

import Config from "config";
import useAxios from "libs/useAxios";
import secureStorage from "libs/secureStorage";
import { fnumber } from "libs/currency";
import print from "helper/printer";

class DetailBill extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			user: null,
			billId: null,
			isLoading: true,

			bill: null,
			billStatus: { id: "", name: "" },
			currentBill: 0,
			currAmount: 0,
			customer: null,
			product: null,
			productPrice: null,
			payments: [],

			redirect: null,
			disabledBtnPrint: false,
			disabledBtnDp: false,
			disabledBtnPaymentPrint: false,
		};

		this.modalNotifRef = React.createRef()
		this.buttonBackRef = React.createRef()
	}

	componentDidMount() {
		const billId = this.props.params?.id
		const user = secureStorage.getItem("user")
		this.setState({ user, billId }, () => {
			this.loadDetail(billId)
		})
	}

	printDp = () => {
		this.setState({ disabledBtnDp: true });
		useAxios()
			.post(`${Config.ApiUrl}/finance/print`, { id: this.state.billId, type: 'dp' })
			.then((response) => {
				this.setState({ disabledBtnDp: false })
				const data = response.data
				const merchant = data.Merchant ? data.Merchant : null

				const sale = data.TrSale
				const product = sale.Product ? sale.Product : null;
				const productPrice = sale.ProductPrice ? sale.ProductPrice : null;

				const item = {
					name: product ? product.name : "",
					amount: productPrice && productPrice.amount ? fnumber(productPrice.amount) : 0,
					downPayment:
						productPrice && productPrice.downPayment ? fnumber(productPrice.downPayment) : 0,
				};

				const customer = data.User ? data.User : null
				const payload = {
					title: "Invoice Uang Muka",
					company: merchant && merchant.name ? merchant.name : "",
					email: merchant && merchant.email ? merchant.email : "",
					phone: merchant && merchant.phone ? merchant.phone : "",
					address: merchant && merchant.address ? merchant.address : "",
					invoiceDate: data.createdAt ? data.createdAt : "",
					invoiceCode: data.kode ? data.kode : "",
					item,
					banks: data.Banks ? data.Banks : [],
					customer,
				};

				print(payload, "PdfDp");
			})
			.catch((err) => {
				console.log(err);
				this.modalNotifRef.current.setShow({
					modalTitle: "Gagal",
					modalMessage: err.response ? err.response.data : "Koneksi jaringan terputus",
					onClose: () => {
						this.setState({ disabledBtnDp: false });
					},
				});
			});
	};

	// Invoice / repayment
	printInvoice = (show) => {
		this.setState({ disabledBtnPrint: true });
		useAxios()
			.post(`${Config.ApiUrl}/finance/print`, { id: this.state.billId, type: 'invoice' })
			.then((response) => {
				this.setState({ disabledBtnPrint: false });
				const data = response.data
				const merchant = data.Merchant ? data.Merchant : null
				// Sale Fee
				const saleFee = data && data.SaleFee ? data.SaleFee : null

				// Customer
				const customer = data.User ? data.User : null
				// TrSale
				const sale = data.TrSale
				const product = sale.Product ? sale.Product : null;
				const productPrice = sale.ProductPrice ? sale.ProductPrice : null;

				const total = parseInt(data.amount) - parseInt(data.paidAmount);

				const commissionAgent = show ? saleFee && saleFee.amount ? fnumber(saleFee.amount) : 0 : null
				const items = [
					{
						name: product ? product.name : "",
						amount: productPrice && productPrice.amount ? fnumber(productPrice.amount) : 0,
					}
				];

				const payload = {
					title: "Invoice",
					company: merchant && merchant.name ? merchant.name : "",
					email: merchant && merchant.email ? merchant.email : "",
					phone: merchant && merchant.phone ? merchant.phone : "",
					address: merchant && merchant.address ? merchant.address : "",
					invoiceDate: data.createdAt ? data.createdAt : "",
					invoiceCode: data.kode ? data.kode : "",
					items,
					banks: data.Banks ? data.Banks : [],
					paidAmount: fnumber(data.paidAmount ? data.paidAmount : 0),
					subtotal: fnumber(data.amount),
					total: fnumber(total),
					customer,
					commissionAgent
				};

				print(payload, "PdfInvoices");
			})
			.catch((err) => {
				console.log(err);
				this.modalNotifRef.current.setShow({
					modalTitle: "Gagal",
					modalMessage: err.response ? err.response.data : "Koneksi jaringan terputus",
					onClose: () => {
						this.setState({ disabledBtnPrint: false });
					},
				});
			});
	}

	printPayment = () => {
		this.setState({ disabledBtnPaymentPrint: true });
		useAxios()
			.post(`${Config.ApiUrl}/finance/print/payment`, { id: this.state.billId })
			.then((response) => {
				this.setState({ disabledBtnPaymentPrint: false })
				const data = response.data

				const product = data.Product ? data.Product : null
				const customer = data.User ? data.User : null
				const merchant = data.Merchant ? data.Merchant : null
				const billCurrAmount = parseInt(data.amount) - parseInt(data.paidAmount)

				const payload = {
					title: "Daftar Pembayaran Transaksi",
					// Merchant
					company: merchant && merchant.name ? merchant.name : "",
					email: merchant && merchant.email ? merchant.email : "",
					phone: merchant && merchant.phone ? merchant.phone : "",
					address: merchant && merchant.address ? merchant.address : "",
					// Bill
					billCode: data.kode ? data.kode : "",
					billDate: data.createdAt ? data.createdAt : "",
					billAmount: fnumber(data.amount),
					billPaidAmount: fnumber(data.paidAmount ? data.paidAmount : 0),
					billCurrAmount: fnumber(billCurrAmount),
					// Prodct
					productName: product && product.name ? product.name : "",
					// member
					customerName: customer && customer.name ? customer.name : "",
					// Payment
					items: data.Payments ? data.Payments : [],
					// Bank
					banks: data.Banks ? data.Banks : [],
				};

				print(payload, "PdfPaymentList")
			})
			.catch((err) => {
				console.log(err)
				this.setState({ disabledBtnPaymentPrint: false })
				this.modalNotifRef.current.setShow({
					modalTitle: "Gagal",
					modalMessage: err.response ? err.response.data : "Koneksi jaringan terputus",
					onClose: () => {
						this.setState({ disabledBtnPrint: false });
					},
				});
			});
	}

	sendAccPayment = () => {
		useAxios()
			.post(`${Config.ApiUrl}/payment/bill/updateStatus`, { billId: this.state.bill?.id })
			.then((response) => {
				this.modalNotifRef.current.setShow({
					modalTitle: "Sukses",
					modalMessage: response.data,
					onClose: () => {
						console.log("close");
						this.loadDetail(this.state.billId);
					},
				});
			})
			.catch((err) => {
				console.log(err);
				this.setState({ disabledSubmit: false });
				if (err.response) {
					this.modalNotifRef.current.setShow({
						modalTitle: "Gagal",
						modalMessage: err.response ? err.response.data : "Terjadi kesalahan pada system",
					});
				}
				// eslint-disable-next-line no-empty
				else {
					this.modalNotifRef.current.setShow({
						modalTitle: "Gagal",
						modalMessage: "Koneksi jaringan terputus",
					});
				}
			});
	};

	loadDetail = (billId) => {
		useAxios()
			.post(`${Config.ApiUrl}/finance/bill/detail`, { id: billId })
			.then((response) => {
				const data = response.data
				const billStatus = data.BillStatus ? data.BillStatus : null
				const currAmount = data && data.amount && data.paidAmount ? parseInt(data.amount) - parseInt(data.paidAmount) : 0
				// TrSale
				const sale = data.TrSale
				const product = sale && sale.Product ? sale.Product : null
				const productPrice = sale && sale.ProductPrice ? sale.ProductPrice : null

				const payments = data.Payments ? data.Payments : [];
				// User
				const user = data.User
				const customer = user && user.Member ? user.Member : null

				this.setState({
					isLoading: false,
					bill: {
						id: data.id,
						kode: data.kode,
						amount: data.amount,
						paidAmount: data.paidAmount,
						createdAt: data.createdAt,
					},
					billStatus,
					currAmount,
					product: {
						id: product.id,
						kode: product.kode,
						name: product.name
					},
					productPrice,
					customer,
					payments,
				});
			})
			.catch((err) => {
				console.log(err);
				this.setState({ isLoading: false });
				this.modalNotifRef.current.setShow({
					modalTitle: "Gagal",
					modalMessage: err.response ? err.response.data : "Koneksi jaringan terputus",
					onClose: () => {
					},
				})
			})
	};

	renderList = () => {
		let currentBill = 0;
		return (
			<Table>
				<MDBox component="thead">
					<TableRow sx={{ backgroundColor: "#f9fafb" }}>
						<DataTableBodyCell width="auto" align="left">
							<MDTypography variant="caption" fontWeight="medium" textTransform="uppercase">
								NO
							</MDTypography>
						</DataTableBodyCell>
						<DataTableBodyCell width="auto" align="left">
							<MDTypography variant="caption" fontWeight="medium" textTransform="uppercase">
								TANGGAL
							</MDTypography>
						</DataTableBodyCell>
						<DataTableBodyCell width="auto" align="right">
							<MDTypography variant="caption" fontWeight="medium" textTransform="uppercase">
								NOMINAL
							</MDTypography>
						</DataTableBodyCell>
						<DataTableBodyCell width="auto" align="right">
							<MDTypography variant="caption" fontWeight="medium" textTransform="uppercase">
								TAGIHAN SAAT INI
							</MDTypography>
						</DataTableBodyCell>
						<DataTableBodyCell width="auto" align="center">
							<MDTypography variant="caption" fontWeight="medium" textTransform="uppercase">
								STATUS
							</MDTypography>
						</DataTableBodyCell>
					</TableRow>
				</MDBox>
				<TableBody>
					{!this.state.isLoading &&
						this.state.payments.map((item, key) => {
							item.PaymentStatus.id == 3 && (currentBill = currentBill + parseInt(item.amount));
							return (
								<TableRow key={key}>
									<DataTableBodyCell align="left">
										<MDTypography variant="body">{key + 1}</MDTypography>
									</DataTableBodyCell>
									<DataTableBodyCell align="left">
										<MDTypography variant="body">
											{item.createdAt
												? moment(item.createdAt)
													.utc()
													.add(7, "hours")
													.format("DD MMMM YYYY HH:mm:ss")
												: "-"}
										</MDTypography>
									</DataTableBodyCell>
									<DataTableBodyCell align="right">
										<MDTypography variant="body">
											{item.amount ? fnumber(item.amount) : 0}
										</MDTypography>
									</DataTableBodyCell>
									<DataTableBodyCell align="right">
										<MDTypography variant="body">
											{item.PaymentStatus.id == 3 &&
												item.amount &&
												this.state.bill.amount &&
												fnumber(this.state.bill.amount - currentBill)}
										</MDTypography>
									</DataTableBodyCell>
									<DataTableBodyCell align="center">
										<MDTypography variant="body">
											<MDBadge
												variant="contained"
												badgeContent={item.PaymentStatus ? item.PaymentStatus.name : "-"}
												size="lg"
												color={
													item.PaymentStatus.id === 1
														? "secondary"
														: item.PaymentStatus.id === 2
															? "error"
															: "success"
												}
											/>
										</MDTypography>
									</DataTableBodyCell>
								</TableRow>
							);
						})}
				</TableBody>
			</Table>
		);
	};

	render() {
		const user = secureStorage.getItem("user");
		if (this.state.redirect) {
			return <Navigate to={this.state.redirect} />;
		}

		return (
			<>
				<ModalNotif ref={this.modalNotifRef} />
				<MDBox py={1} mb={2} display="flex">
					<MDBox mr={1}>
						<ButtonBack label={"KEMBALI"} ref={this.buttonBackRef} />
					</MDBox>
					{/* Print DownPayment */}
					{user && [2, 3, 4].includes(user.roleId) && this.state.billStatus.id !== 2 && (
						<MDBox mr={1}>
							<MDButton
								variant="gradient"
								color="info"
								disabled={this.state.disabledBtnDp}
								onClick={this.printDp}
							>
								Cetak Uang Muka
							</MDButton>
						</MDBox>
					)}
					{/* Button for Biro Travel */}
					{user && [2, 3, 4].includes(user.roleId) && [1, 3, 4, 5].includes(this.state.billStatus.id) && (
						<MDBox mr={1}>
							<MDButton
								variant="gradient"
								color="info"
								disabled={this.state.disabledBtnPrint}
								onClick={() => this.printInvoice(false)}
							>
								Cetak Invoice
							</MDButton>
						</MDBox>
					)}
					{/* Button for Agent */}
					{user && [4].includes(user.roleId) && [1, 3, 4, 5].includes(this.state.billStatus.id) && (
						<MDBox mr={1}>
							<MDButton
								variant="gradient"
								color="info"
								disabled={this.state.disabledBtnPrint}
								onClick={() => this.printInvoice(true)}
							>
								Cetak Invoice Khusus
							</MDButton>
						</MDBox>
					)}
					{/* Button Print Rekap Payment */}
					{user && [2, 3, 4].includes(user.roleId) && this.state.billStatus.id !== 2 && (
						<MDBox mr={1}>
							<MDButton
								variant="gradient"
								color="info"
								disabled={this.state.disabledBtnPaymentPrint}
								onClick={this.printPayment}
							>
								Cetak Daftar Pembayaran
							</MDButton>
						</MDBox>
					)}
					{/* Button Bukti Pendaftaran */}
					{user && [2, 3, 4].includes(user.roleId) && [4, 5].includes(this.state.billStatus.id) && (
						<MDBox mr={1}>
							<MDButton
								variant="gradient"
								color="info"
							>
								Cetak Bukti Pendaftaran
							</MDButton>
						</MDBox>
					)}
					{/* Button bill paid */}
					{user && [2, 3, 4].includes(user.roleId) && [4].includes(this.state.billStatus.id) && (
						<MDBox mr={1}>
							<MDButton
								variant="gradient"
								color="success"
								onClick={this.sendAccPayment}
							>
								Pembayaran Complete
							</MDButton>
						</MDBox>
					)}
				</MDBox>
				{this.state.isLoading ? (
					<Loader open={true} />
				) : (
					<Card>
						<MDBox p={2}>
							<Grid container spacing={1} mb={3}>
								<Grid item xs={12} md={12} lg={12}>
									<MDBox width="100%" display="flex" justifyContent="center">
										<MDTypography variant="h5" textTransform="capitalize" fontWeight="medium">
											DETAIL TAGIHAN
										</MDTypography>
									</MDBox>
								</Grid>
							</Grid>

							<Grid container spacing={1} mb={2}>
								{/* Top Left */}
								<Grid item xs={12} md={6} lg={6}>
									<Table>
										<TableBody>
											{/* Kode Bill*/}
											<TableRow>
												<DataTableBodyCell noBorder>
													<MDTypography variant="body" fontWeight="medium">
														Kode Tagihan
													</MDTypography>
												</DataTableBodyCell>
												<DataTableBodyCell noBorder>
													<MDTypography variant="body">
														{this.state.bill && this.state.bill.kode ? this.state.bill.kode : "-"}
													</MDTypography>
												</DataTableBodyCell>
											</TableRow>
											{/* Product */}
											<TableRow>
												<DataTableBodyCell noBorder>
													<MDTypography variant="body" fontWeight="medium">
														Paket
													</MDTypography>
												</DataTableBodyCell>
												<DataTableBodyCell noBorder>
													<MDTypography variant="body">
														<MDBox style={{ wordWrap: "break-word", width: "20em" }}>
															{
																this.state.product
																	? `${this.state.product.kode} - ${this.state.product.name}`
																	: "-"
															}
														</MDBox>
													</MDTypography>
												</DataTableBodyCell>
											</TableRow>
											{/* Product Price  */}
											{/* {this.state.addons !== 0 || this.state.discounts !== 0 ? ( */}
											<TableRow>
												<DataTableBodyCell noBorder>
													<MDTypography variant="body" fontWeight="medium">
														Harga Paket
													</MDTypography>
												</DataTableBodyCell>
												<DataTableBodyCell noBorder>
													<MDTypography variant="body">
														{this.state.productPrice && this.state.productPrice.amount
															? fnumber(this.state.productPrice.amount)
															: "-"}
													</MDTypography>
												</DataTableBodyCell>
											</TableRow>
											{/* ) : (
                        <></>
                      )} */}

											{/* Bill Amount */}
											<TableRow>
												<DataTableBodyCell noBorder>
													<MDTypography variant="body" fontWeight="medium">
														Tagihan
													</MDTypography>
												</DataTableBodyCell>
												<DataTableBodyCell noBorder>
													<MDTypography variant="body">
														{this.state.bill && this.state.bill.amount
															? fnumber(this.state.bill.amount)
															: "-"}
													</MDTypography>
												</DataTableBodyCell>
											</TableRow>
											{/* Bill PaidAmount */}
											<TableRow>
												<DataTableBodyCell noBorder>
													<MDTypography variant="body" fontWeight="medium">
														Tagihan Terbayar
													</MDTypography>
												</DataTableBodyCell>
												<DataTableBodyCell noBorder>
													<MDTypography variant="body">
														{this.state.bill && this.state.bill.amount
															? fnumber(this.state.bill.paidAmount)
															: "-"}
													</MDTypography>
												</DataTableBodyCell>
											</TableRow>
											{/* Bill PaidAmount */}
											<TableRow>
												<DataTableBodyCell noBorder>
													<MDTypography variant="body" fontWeight="medium">
														Sisa Tagihan
													</MDTypography>
												</DataTableBodyCell>
												<DataTableBodyCell noBorder>
													<MDTypography variant="body">
														{this.state.currAmount && this.state.currAmount
															? fnumber(this.state.currAmount)
															: "-"}
													</MDTypography>
												</DataTableBodyCell>
											</TableRow>
											{/* Bill Date */}
											<TableRow>
												<DataTableBodyCell noBorder>
													<MDTypography variant="body" fontWeight="medium">
														Tanggal Transaksi
													</MDTypography>
												</DataTableBodyCell>
												<DataTableBodyCell noBorder>
													<MDTypography variant="body">
														{this.state.bill && this.state.bill.createdAt
															? this.state.bill.createdAt
															: "-"}
													</MDTypography>
												</DataTableBodyCell>
											</TableRow>
											{/* Status */}
											<TableRow>
												<DataTableBodyCell noBorder>
													<MDTypography variant="body" fontWeight="medium">
														Status Tagihan
													</MDTypography>
												</DataTableBodyCell>
												<DataTableBodyCell noBorder>
													<MDTypography variant="body">
														{this.state.billStatus && this.state.billStatus.name && (
															<MDBadge
																variant="contained"
																badgeContent={this.state.billStatus.name}
																size="lg"
																color={
																	this.state.billStatus.id === 1
																		? "secondary"
																		: this.state.billStatus.id === 2
																			? "error"
																			: this.state.billStatus.id === 3
																				? "warning"
																				: this.state.billStatus.id === 4
																					? "info"
																					: "success"
																}
															/>
														)}
													</MDTypography>
												</DataTableBodyCell>
											</TableRow>
										</TableBody>
									</Table>
								</Grid>
								{/* Top Right */}
								<Grid item xs={12} md={6} lg={6}>
									<Table>
										<TableBody>
											{/* Kode Customer */}
											<TableRow>
												<DataTableBodyCell noBorder>
													<MDTypography variant="body" fontWeight="medium">
														Kode Pelanggan
													</MDTypography>
												</DataTableBodyCell>
												<DataTableBodyCell noBorder>
													<MDTypography variant="body">
														{this.state.customer && this.state.customer.kode
															? this.state.customer.kode
															: "-"}
													</MDTypography>
												</DataTableBodyCell>
											</TableRow>
											{/* Name Customer */}
											<TableRow>
												<DataTableBodyCell noBorder>
													<MDTypography variant="body" fontWeight="medium">
														Nama Pelanggan
													</MDTypography>
												</DataTableBodyCell>
												<DataTableBodyCell noBorder>
													<MDTypography variant="body">
														{this.state.customer && this.state.customer.name
															? this.state.customer.name
															: "-"}
													</MDTypography>
												</DataTableBodyCell>
											</TableRow>
											{/* Email Customer */}
											<TableRow>
												<DataTableBodyCell noBorder>
													<MDTypography variant="body" fontWeight="medium">
														Email Pelanggan
													</MDTypography>
												</DataTableBodyCell>
												<DataTableBodyCell noBorder>
													<MDTypography variant="body">
														{this.state.customer && this.state.customer.email
															? this.state.customer.email
															: "-"}
													</MDTypography>
												</DataTableBodyCell>
											</TableRow>
											{/* Phone customer */}
											<TableRow>
												<DataTableBodyCell noBorder>
													<MDTypography variant="body" fontWeight="medium">
														Nomor Telpon
													</MDTypography>
												</DataTableBodyCell>
												<DataTableBodyCell noBorder>
													<MDTypography variant="body">
														{this.state.customer && this.state.customer.phone
															? this.state.customer.phone
															: "-"}
													</MDTypography>
												</DataTableBodyCell>
											</TableRow>
											{/* Address */}
											<TableRow>
												<DataTableBodyCell noBorder>
													<MDTypography variant="body" fontWeight="medium">
														Alamat
													</MDTypography>
												</DataTableBodyCell>
												<DataTableBodyCell noBorder>
													<MDTypography variant="body">
														<MDBox style={{ wordWrap: "break-word", width: "20em" }}>
															{
																this.state.customer && this.state.customer.address
																	? this.state.customer.address
																	: "-"
															}
														</MDBox>
													</MDTypography>
												</DataTableBodyCell>
											</TableRow>
										</TableBody>
									</Table>
								</Grid>
							</Grid>
							<Grid container spacing={1} mb={2}>
								<Grid item xs={12} md={12} lg={12}>
									<Table>
										<TableBody>
											{/* History Payment */}
											<TableRow>
												<DataTableBodyCell noBorder>
													<MDTypography variant="body" fontWeight="medium">
														Pembayaran
													</MDTypography>
												</DataTableBodyCell>
												<TableCell colSpan={3} sx={{ borderBottom: "none" }}>
													{this.renderList()}
												</TableCell>
											</TableRow>
										</TableBody>
									</Table>
								</Grid>
							</Grid>
						</MDBox>
					</Card>
				)}
			</>
		);
	}
}

export default withRouter(DetailBill)
