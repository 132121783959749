/* eslint-disable react/prop-types */
import React from "react"
import PropTypes from "prop-types"
// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import ModalNotif from 'contents/Components/ModalNotif';
import MiniFormCard from "contents/Components/FormCard/MiniFormCard";

import Config from 'config'

import useAxios from "libs/useAxios"
import secureStorage from "libs/secureStorage";

class ChangePassword extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			userId: null,

			currpass: "",
			newpass: "",
			repass: "",

			error: [],
			success: [],

			disabledSubmit: false
		}

		this.modalNotifRef = React.createRef()
	}

	componentDidMount() {
		const user = secureStorage.getItem('user')
		this.setState({ userId: user.id })
	}

	handleChange = (e) => {
		const { id, value } = e.target;
		this.setState({
			[id]: value
		})
	}

	handleBlur = (e) => {
		const user = this.state.user
		const { id, value } = e.target
		// eslint-disable-next-line no-empty
		if (value) {
			this.setState({
				success: { ...this.state.success, [e.target.id]: true },
				error: { ...this.state.error, [e.target.id]: false }
			})
		}
		else {
			this.setState({
				success: { ...this.state.success, [e.target.id]: false },
				error: { ...this.state.error, [e.target.id]: true }
			})
		}
	}

	handleSumit = () => {
		if (this.state.newpass !== this.state.repass) {
			this.setState({
				error: { ...this.state.error, repass: true },
				success: { ...this.state.success, repass: false }
			})
			this.modalNotifRef.current.setShow({
				modalTitle: "Peringatan",
				modalMessage: "Konfirmasi password harus sama dengan password baru"
			})
		}
		else {
			this.setState({ disabledSubmit: true })
			const payload = {
				currentPassword: this.state.currpass,
				newPassword: this.state.newpass,
				userId: this.state.userId
			}

			useAxios()
				.post(`${Config.ApiUrl}/user/changepass`, payload)
				.then(response => {
					this.setState({ disabledSubmit: false })
					this.modalNotifRef.current.setShow({
						modalTitle: "Sukses",
						modalMessage: response.data,
						onClose: () => {
							this.setState({
								currpass: "",
								newpass: "",
								repass: ""
							})
						}
					})
				})
				.catch(err => {
					console.log(err)
					this.setState({ disabledSubmit: false })
					if (err.response) {
						this.modalNotifRef.current.setShow({
							modalTitle: "Gagal",
							modalMessage: err.response.data,
						})
					}
					// eslint-disable-next-line no-empty
					else {
						this.modalNotifRef.current.setShow({
							modalTitle: 'Gagal',
							modalMessage: "Koneksi jaringan terputus",
						})
					}
				})
		}
	}

	render() {
		return (
			<>
				<ModalNotif ref={this.modalNotifRef} />
				<MiniFormCard>
					<MDBox p={3}>
						<MDTypography variant="h5">Ubah Password</MDTypography>
					</MDBox>
					<MDBox component="form" pb={3} px={3}>
						<Grid container spacing={3}>
							<Grid item xs={12} md={12} lg={12}>
								<MDBox mb={2}>
									<MDInput
										id="currpass"
										type="password"
										label="Password saat ini"
										value={this.state.currpass}
										onChange={this.handleChange}
										onBlur={this.handleBlur}
										fullWidth
									/>
								</MDBox>
								<MDBox mb={2}>
									<MDInput
										id="newpass"
										type="password"
										label="Password baru"
										value={this.state.newpass}
										onChange={this.handleChange}
										onBlur={this.handleBlur}
										fullWidth
									/>
								</MDBox>
								<MDBox mb={2}>
									<MDInput
										id="repass"
										type="password"
										label="Konfirmasi Password"
										value={this.state.repass}
										onChange={this.handleChange}
										onBlur={this.handleBlur}
										success={this.state.success ? this.state.success.repass : false}
										error={this.state.error ? this.state.error.repass : false}
										fullWidth
									/>
								</MDBox>
							</Grid>
						</Grid>

						<MDBox width="100%" display="flex" justifyContent="flex-end" flexWrap="wrap">
							<MDButton
								variant="gradient"
								color="dark"
								size="small"
								disabled={this.state.disabledSubmit}
								onClick={() => {
									if (this.state.currpass === "" || this.state.newpass === "" || this.state.repass === "") {
										this.modalNotifRef.current.setShow({
											modalTitle: "Gagal",
											modalMessage: "Data form masih belum lengkap, Silahkan di cek kembali !",
										})
									}
									else {
										this.handleSumit()
									}
								}}
							>
								UPDATE
							</MDButton>
						</MDBox>
					</MDBox>
				</MiniFormCard>
			</>
		)
	}
}

export default ChangePassword