/* eslint-disable react/prop-types */
import React from "react";
import { Link, Navigate } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import MDDatePicker from "components/MDDatePicker";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import ModalNotif from "contents/Components/ModalNotif";
import withRouter from "contents/Components/withRouter";

import MiniFormCard from "contents/Components/FormCard/MiniFormCard";

import Config from "config";
import _ from "lodash"

import useAxios from "libs/useAxios"
import secureStorage from "libs/secureStorage"
import { fnumber } from "libs/currency"

class FormOutFund extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			amount: "",
			description: "",
			remark: "",

			error: [],
			success: [],

			disabledSubmit: false,
			redirect: null,
		}

		this.inputRef = React.createRef()
		this.modalNotifRef = React.createRef()
	}

	componentDidMount() {

	}

	handleChange = (e) => {
		const { id, value } = e.target;
		this.setState({
			[id]: value,
		});
	}

	handleBlur = (e) => {
		const { id, value } = e.target;
		// eslint-disable-next-line no-empty
		if (value) {
			this.setState({
				success: { ...this.state.success, [e.target.id]: true },
				error: { ...this.state.error, [e.target.id]: false },
			});
		} else {
			this.setState({
				success: { ...this.state.success, [e.target.id]: false },
				error: { ...this.state.error, [e.target.id]: true },
			});
		}
	}

	handleSubmit = () => {
		let error = this.state.error;
		let success = this.state.success;

		if (
			success.amount && success.description
		) {
			this.sendData()
		} else {
			this.modalNotifRef.current.setShow({
				modalTitle: "Gagal",
				modalMessage: "Data form masih belum lengkap, Silahkan di cek kembali !",
			})
		}
	}

	sendData = () => {
		this.setState({
			disabledSubmit: true
		})

		const payload = {
			// type: "Dana Pengeluaran Manual",
			amount: this.state.amount,
			description: this.state.description
		}

		useAxios()
			.post(`${Config.ApiUrl}/finance/mutation/add`, payload)
			.then(response => {
				const data = response.data
				console.log({ data })

				this.modalNotifRef.current.setShow({
					modalTitle: "Sukses",
					modalMessage: response.data,
					onClose: () => {
						this.setState({ redirect: "/finance/out-fund" });
					},
				})

				this.setState({
					disabledSubmit: false,
				})
			})
			.catch(err => {
				console.log(err)
				this.setState({
					disabledSubmit: false,
				})

				if (err.response) {
					this.modalNotifRef.current.setShow({
						modalTitle: "Gagal",
						modalMessage: err.response ? err.response.data : "Koneksi jaringan terputus",
					});
				}
				// eslint-disable-next-line no-empty
				else {
					this.modalNotifRef.current.setShow({
						modalTitle: "Gagal",
						modalMessage: "Koneksi jaringan terputus",
					});
				}
			})
	}

	render() {
		if (this.state.redirect) {
			return <Navigate to={this.state.redirect} />;
		}

		return (
			<MiniFormCard>
				<ModalNotif ref={this.modalNotifRef} />
				<MDBox p={3} lineHeight={1}>
					<MDTypography variant="h5" fontWeight="medium">
						Form Pengeluaran
					</MDTypography>
				</MDBox>

				<MDBox p={3}>
					<MDBox component="form" role="form">
						<Grid container spacing={3}>
							<Grid item xs={12} md={12} lg={12}>
								<MDBox mb={2}>
									<MDInput
										inputRef={this.inputRef}
										id="amount"
										type="text"
										label="Nominal"
										value={this.state.amount}
										onChange={(e) => {
											const valStr = e.target.value.replace(/\./g, '')
											let value = isNaN(valStr) || valStr === '' ? 0 : parseInt(valStr)
											if (value < 0) value = 0

											this.setState({ amount: fnumber(value) })
										}}
										onBlur={this.handleBlur}
										success={this.state.success ? this.state.success.amount : false}
										error={this.state.error ? this.state.error.amount : false}
										fullWidth
									/>
								</MDBox>
								<MDBox mb={2}>
									<MDInput
										id="description"
										type="text"
										multiline
										rows={3}
										label="Catatan"
										value={this.state.description}
										onChange={this.handleChange}
										onBlur={this.handleBlur}
										success={this.state.success ? this.state.success.description : false}
										error={this.state.error ? this.state.error.description : false}
										fullWidth
									/>
								</MDBox>

								<MDBox pt={3} display="flex" justifyContent="space-between">
									<MDButton
										variant="gradient"
										color="error"
										component={Link}
										to={{ pathname: "/finance/out-fund" }}
									>
										KEMBALI
									</MDButton>
									<MDButton
										type="button"
										variant="gradient"
										color="info"
										disabled={this.state.disabledSubmit}
										onKeyDown={this.handleKeyDown}
										onClick={this.handleSubmit}
									>
										SUBMIT
									</MDButton>
								</MDBox>

							</Grid>
						</Grid>
					</MDBox>
				</MDBox>
			</MiniFormCard>
		)
	}
}

export default FormOutFund