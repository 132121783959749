/* eslint-disable react/prop-types */
import React from 'react'
import { Link, Navigate } from 'react-router-dom';
// @mui material components
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from 'components/MDInput';
import MDTypography from "components/MDTypography";
import MDDatePicker from 'components/MDDatePicker';

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import ModalNotif from 'contents/Components/ModalNotif';
import withRouter from 'contents/Components/withRouter';
import ButtonBack from 'contents/Components/ButtonBack';

import MiniFormCard from 'contents/Components/FormCard/MiniFormCard';

import Config from 'config'
import moment from 'moment'
import _ from "lodash"
import * as EmailValidator from 'email-validator'

import useAxios from "libs/useAxios"
import secureStorage from 'libs/secureStorage';

class FormPassport extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			disabledSubmit: false,
			isLoading: false,
			userId: null,
			passportId: null,

			error: [],
			success: [],

			name: "",
			number: "",
			effectiveDate: "",
			expiredDate: "",

			passportPlace: "",
			passportDate: "",
		}

		this.loadData = this.loadData.bind(this)

		this.inputRef = React.createRef()
		this.modalNotifRef = React.createRef()
	}

	componentDidMount() {
		this.inputRef.current.focus()
		const id = this.props.params?.id
		this.setState({ userId: id }, () => {
			this.loadData(id)
		})
	}

	loadData(userId) {
		useAxios()
			.post(`${Config.ApiUrl}/user/passport`, { userId })
			.then(response => {
				const data = response.data

				this.setState({
					name: data ? data.name : "",
					number: data ? data.number : "",
					effectiveDate: data ? moment(data.effectiveDate).format("YYYY-MM-DD") : "",
					passportDate: data ? moment(data.passportDate).format("YYYY-MM-DD") : "",
					passportPlace: data ? data.passportPlace : "",
					expiredDate: data ? moment(data.expiredDate).format("YYYY-MM-DD") : "",
					passportId: data ? data.id : null,

					success: {
						...this.state.success,
						name: data.name ? true : false,
						number: data.number ? true : false,
						passportPlace: data.passportPlace ? true : false,
						passportDate: data.passportDate ? true : false,
						effectiveDate: data.effectiveDate ? true : false,
						expiredDate: data.expiredDate ? true : false
					}
				})
			})
			.catch(err => {
				console.log(err)
				this.setState({ isLoading: false })
				this.modalNotifRef.current.setShow({
					modalTitle: 'Gagal',
					modalMessage: err.response ? err.response.data : "Koneksi jaringan terputus",
					onClose: () => {
						this.setState({ redirect: '/adm/member' })
					}
				})
			})
	}

	handleChange = (e) => {
		const { id, value } = e.target;
		this.setState({
			[id]: value
		})
	}

	handleBlur = (e) => {
		const user = this.state.user
		const { id, value } = e.target
		if (value) {
			this.setState({
				success: { ...this.state.success, [e.target.id]: true },
				error: { ...this.state.error, [e.target.id]: false }
			})
		}
		else {
			this.setState({
				success: { ...this.state.success, [e.target.id]: false },
				error: { ...this.state.error, [e.target.id]: true }
			})
		}
	}

	handleSubmit = () => {
		let error = this.state.error

		if (
			!error.number &&
			!error.passportDate &&
			!error.passportPlace &&
			!error.effectiveDate &&
			!error.expiredDate
		) {
			this.sendData()
		}
		else {
			this.modalNotifRef.current.setShow({
				modalTitle: "Gagal",
				modalMessage: "Data form masih belum lengkap, Silahkan di cek kembali !",
			})
		}
	}

	sendData = () => {
		this.setState({
			disabledSubmit: true
		})

		const payload = {
			name: this.state.name,
			number: this.state.number,
			passportDate: this.state.passportDate,
			passportPlace: this.state.passportPlace,
			effectiveDate: this.state.effectiveDate,
			expiredDate: this.state.expiredDate,
			userId: this.state.userId,
			id: this.state.passportId,
		}

		useAxios()
			.post(`${Config.ApiUrl}/user/passport/set`, payload)
			.then(response => {
				this.modalNotifRef.current.setShow({
					modalTitle: "Sukses",
					modalMessage: response.data,
					onClose: () => {
						this.setState({ redirect: '/adm/member' })
					}
				})

				this.setState({
					disabledSubmit: false
				})
			})
			.catch(err => {
				this.setState({ disabledSubmit: false })
				if (err.response) {
					this.modalNotifRef.current.setShow({
						modalTitle: "Gagal",
						modalMessage: err.response.data,
					})
				}
				// eslint-disable-next-line no-empty
				else {
					this.modalNotifRef.current.setShow({
						modalTitle: 'Gagal',
						modalMessage: "Koneksi jaringan terputus",
					})
				}
			})
	}

	render() {
		if (this.state.redirect) {
			return <Navigate to={this.state.redirect} />
		}

		return (
			<MiniFormCard>
				<ModalNotif ref={this.modalNotifRef} />
				<MDBox display="flex" p={3} lineHeight={1} justifyContent="center">
					<MDTypography variant="h5" fontWeight="medium">
						Form Paspor
					</MDTypography>
				</MDBox>

				<MDBox p={3}>
					<MDBox component="form" role="form">
						<Grid container spacing={3}>
							<Grid item xs={12} md={12} lg={12}>
								{/* Name */}
								<MDBox mb={2}>
									<MDInput
										id="name"
										type="text"
										label="Nama"
										value={this.state.name}
										inputRef={this.inputRef}
										onChange={this.handleChange}
										onBlur={this.handleBlur}
										success={this.state.success ? this.state.success.name : false}
										error={this.state.error ? this.state.error.name : false}
										fullWidth
									/>
								</MDBox>
								{/* No Passport */}
								<MDBox mb={2}>
									<MDInput
										id="number"
										type="text"
										label="No Passport"
										value={this.state.number}
										inputRef={this.inputRef}
										onChange={this.handleChange}
										onBlur={this.handleBlur}
										success={this.state.success ? this.state.success.number : false}
										error={this.state.error ? this.state.error.number : false}
										fullWidth
									/>
								</MDBox>
								{/* Place Passport */}
								<MDBox mb={2}>
									<MDInput
										id="passportPlace"
										type="text"
										label="Tempat Terbit"
										value={this.state.passportPlace}
										onChange={this.handleChange}
										onBlur={this.handleBlur}
										success={this.state.success ? this.state.success.passportPlace : false}
										error={this.state.error ? this.state.error.passportPlace : false}
										fullWidth
									/>
								</MDBox>
								{/* Passport Date */}
								<MDBox mb={2}>
									<MDDatePicker
										input={{
											id: "passportDate",
											label: "Tanggal Pembuatan Paspor",
											fullWidth: true,
										}}
										value={this.state.passportDate}
										onChange={(value) => {
											const date = moment(value[0]).format("YYYY-MM-DD");
											this.setState({
												passportDate: date,
												success: { ...this.state.success, passportDate: true },
												error: { ...this.state.error, passportDate: false },
											});
										}}
									/>
								</MDBox>
								{/* Effective Date */}
								<MDBox mb={2}>
									<MDDatePicker
										input={{
											id: "effectiveDate",
											placeholder: "Tanggal Terbit Paspor",
											fullWidth: true,
										}}
										value={this.state.effectiveDate}
										onChange={(value) => {
											const date = moment(value[0]).format("YYYY-MM-DD");
											this.setState({
												effectiveDate: date,
												success: { ...this.state.success, effectiveDate: true },
												error: { ...this.state.error, effectiveDate: false },
											});
										}}
									/>
								</MDBox>
								{/* Expired Date */}
								<MDBox mb={2}>
									<MDDatePicker
										input={{
											id: "expiredDate",
											placeholder: "Tanggal Kadaluarsa Paspor",
											fullWidth: true,
										}}
										value={this.state.expiredDate}
										onChange={(value) => {
											const date = moment(value[0]).format("YYYY-MM-DD");
											this.setState({
												expiredDate: date,
												success: { ...this.state.success, expiredDate: true },
												error: { ...this.state.error, expiredDate: false },
											});
										}}
									/>
								</MDBox>

								<MDBox pt={3} display="flex" justifyContent="space-between">
									<ButtonBack label={"KEMBALI"} />
									<MDButton
										type="button"
										variant="gradient"
										color="info"
										disabled={this.state.disabledSubmit}
										onClick={this.handleSubmit}
									>
										UPDATE
									</MDButton>
								</MDBox>
							</Grid>
						</Grid>
					</MDBox>
				</MDBox>
			</MiniFormCard>
		)
	}
}

export default withRouter(FormPassport)