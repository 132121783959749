/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from "prop-types"
import { Link, Navigate } from 'react-router-dom';
// @mui material components
import Container from '@mui/material/Container';
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";

// @mui icons
import { Close } from '@mui/icons-material';

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from 'components/MDInput';
import MDTypography from "components/MDTypography";

import ModalNotif from 'contents/Components/ModalNotif';
import DialogForm from "contents/Components/DialogForm"

import Config from 'config'
import _ from "lodash"

import useAxios from "libs/useAxios"
import secureStorage from 'libs/secureStorage';
import { fnumber } from 'libs/currency';

class DialogItem extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			title: props.item === "discount" ? "Tambah Diskon" : "Tambah Biaya Tambahan",

			merchant: null,
			row: null,
			amount: "",
			remark: "",

			rows: [],

			error: [],
			success: [],

			disabledSubmit: false,
		}

		this.inputRef = React.createRef()
		this.modalNotifRef = React.createRef()
		this.dialogFormRef = React.createRef()
	}

	componentDidMount() {
		this.inputRef.current && this.inputRef.current.focus()
		const user = secureStorage.getItem('user')
		this.loadItem(user?.Merchant)
	}

	setShow = (data) => {
		this.dialogFormRef.current.setShow({ ...data, title: this.state.title })
	}

	loadItem = (merchant) => {
		const url = this.props.item === "discount" ? "/master/discount/dropdown" : "/master/addon/dropdown"
		useAxios()
			.post(`${Config.ApiUrl}${url}`, { merchantId: merchant?.id })
			.then(response => {
				const data = response.data

				// console.log(data)
				this.setState({
					rows: data.map(item => ({ id: item.id, label: item.name }))
				})
			})
			.catch(err => {
				console.log(err)
			})
	}

	onChange = (e, newValue) => {
		if (newValue) {
			this.setState({
				row: newValue
			})
		}
	}

	handleSave = () => {
		if (
			this.state.row &&
			this.state.amount
		) {
			const amount = parseInt(this.state.amount.replace(/\./g, ''))
			this.props.onAction({
				row: this.state.row ? { id: this.state.row.id, name: this.state.row.label } : null,
				amount,
				remark: this.state.remark
			})
			this.setState({
				row: null,
				remark: "",
				amount: ""
			}, () => {
				this.dialogFormRef.current.setShow({ show: false })
			})
		}
		else {
			this.modalNotifRef.current.setShow({
				modalTitle: "Gagal",
				modalMessage: "Data form masih belum lengkap, Silahkan di cek kembali !",
			})
		}
	}

	render() {
		const item = this.props.item
		return (
			<DialogForm ref={this.dialogFormRef} maxWidth="sm">
				<ModalNotif ref={this.modalNotifRef} />
				<Grid container item xs={12} lg={12} sx={{ mx: "auto" }}>
					<MDBox width="100%" component="form" mt={2}>
						<MDBox mb={2}>
							<Autocomplete
								disableClearable
								value={this.state.row}
								options={this.state.rows}
								onChange={this.onChange}
								sx={{
									input: {
										padding: "7.5px 5px 7.5px 8px !important"
									},
									"& .MuiOutlinedInput-root": {
										padding: "4px !important"
									}
								}}
								isOptionEqualToValue={(option, value) => option.id === value.id}
								renderInput={(params) => <MDInput {...params} label={item === "discount" ? "Pilih Diskon" : "Pilih Biaya Tambahan"} />}
							/>
						</MDBox>
						<MDBox mb={2}>
							<MDInput
								id="amount"
								type="text"
								inputRef={this.inputRef}
								label={`Nominal ${item === "discount" ? "Diskon" : "Biaya Tambahan"}`}
								value={this.state.amount}
								onChange={(e) => {
									const valStr = e.target.value.replace(/\./g, '')
									let value = isNaN(valStr) || valStr === '' ? 0 : parseInt(valStr)
									if (value < 0)
										value = 0
									this.setState({ amount: fnumber(value) })
								}}
								fullWidth
							/>
						</MDBox>
						<MDBox mb={2}>
							<MDInput
								rows={3}
								multiline
								id="remark"
								type="text"
								label="Keterangan"
								value={this.state.remark}
								onChange={(e) => this.setState({ remark: e.target.value })}
								fullWidth
							/>
						</MDBox>
					</MDBox>

					<MDBox py={3} width="100%" display="flex" justifyContent={{ md: "flex-end", xs: "center" }}>
						<MDBox mr={1}>
							<MDButton
								variant="gradient"
								color="error"
								onClick={() => this.dialogFormRef.current.setShow({ show: false })}
							>
								Tutup
							</MDButton>
						</MDBox>
						<MDButton
							variant="gradient"
							color="info"
							disabled={this.state.disabledSubmit}
							onClick={this.handleSave}
						>
							Submit
						</MDButton>
					</MDBox>
				</Grid>
			</DialogForm>
		)
	}
}

DialogItem.propTypes = {
	onAction: PropTypes.func.isRequired,
	item: PropTypes.oneOf(['discount', 'addon']).isRequired
}

export default DialogItem