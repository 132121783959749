import React from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon"
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import DataTable from 'contents/Components/DataTable'
import Pagination from "contents/Components/Pagination";
import ButtonAction from "contents/Administrasi/Notification/components/ButtonAction"
// import ModalForm from "contents/Administrasi/Notification/components/ModalForm"

import useAxios from "libs/useAxios"
import Config from 'config'
import secureStorage from "libs/secureStorage";

class Notification extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			merchant: null,
			type: null,

			types: [{ id: 1, label: 'APP' }, { id: 2, label: 'WA' }],
			merchants: [],

			rowsPerPage: 10,
			currentPage: 1,
			totalPages: 0,
			totalData: 0,
			tableHead: [
				{ Header: "action", accessor: "action", width: "10%" },
				{ Header: "judul", accessor: "title", width: "20%" },
				{ Header: "pesan", accessor: "message", width: "20%" },
				{ Header: "jenis", accessor: "type", width: "15%" },
				{ Header: "tanggal", accessor: "createdAt", width: "20%" },
			],
			rows: [],
			keyword: "",
			isLoading: true,
		}

	}

	componentDidMount() {
		const user = secureStorage.getItem('user')
		this.loadData()
		user && user.roleId === 1 && this.loadMerchants()
	}

	loadMerchants = () => {
		useAxios()
			.post(`${Config.ApiUrl}/manage/merchant/dropdown`)
			.then(response => {
				const data = response.data

				this.setState({
					merchants: data.map(item => ({ id: item.id, label: item.name }))
				})
			})
			.catch(err => {
				console.log(err)
			})
	}

	loadData = (params) => {
		const user = secureStorage.getItem('user')
		this.setState({ isLoading: true })
		const merchantId = params && params.merchantId ? { merchantId: params.merchantId } : {}
		const typeName = params && params.typeName ? { typeName: params.typeName } : {}

		const payload = {
			keyword: params && params.keyword ? params.keyword : this.state.keyword,
			currentPage: params && params.currentPage ? params.currentPage : 1,
			rowsPerPage: params && params.rowsPerPage ? params.rowsPerPage : this.state.rowsPerPage,
			attributes: ['id', 'title', 'type', 'message', 'createdAt', 'isRead'],
			...typeName,
			...merchantId
		}

		useAxios()
			.post(`${Config.ApiUrl}/notif`, payload)
			.then(response => {
				const data = response.data

				const output = data.output.map((item, key) => {
					const fontWeight = item.isRead ? "regular" : "bold"
					return {
						action: <ButtonAction
							id={item.id}
							statusId={item.status}
							refreshData={this.loadData}
						/>,
						title: (
							<p style={{ wordWrap: "break-word", width: "15em" }}>
								<MDTypography variant="caption" fontWeight={fontWeight}>
									{item.title}
								</MDTypography>
							</p>
						),
						message: (
							<p style={{ wordWrap: "break-word", width: "25em" }}>
								<MDTypography variant="caption" fontWeight={fontWeight}>{item.message}</MDTypography>
							</p>
						),
						type: (
							<MDTypography variant="caption" fontWeight={fontWeight}>
								{item.type}
							</MDTypography>
						),
						createdAt: (
							<MDTypography variant="caption" fontWeight={fontWeight}>
								{item.createdAt}
							</MDTypography>
						)
					}
				})
				this.setState({
					isLoading: false,
					totalPages: data.totalPages,
					totalData: data.totalData,
					rows: output
				})
			})
			.catch(err => {
				console.log(err)
				this.setState({ isLoading: false })
			})
	}

	render() {
		const user = secureStorage.getItem('user')

		return (
			<MDBox pb={3} my={3}>
				<Card>
					<MDBox p={2} lineHeight={1}>
						<MDTypography variant="h5" fontWeight="medium">
							Daftar Notifikasi
						</MDTypography>
					</MDBox>
					{/* Filter */}
					<MDBox px={2} width="100%" display="flex" justifyContent="flex-start">
						<Grid container spacing={3}>
							{/* Merchant */}
							{[1].includes(user.roleId) && (
								<Grid item xs={12} md={3} lg={3}>
									<Autocomplete
										value={this.state.merchant}
										options={this.state.merchants}
										onChange={(e, value) => {
											if (value) this.loadProduct({ merchantId: value?.id })
											else this.loadProduct()
											this.setState({ merchant: value }, () => {
												this.loadData({
													currentPage: 1,
													merchantId: value ? value.id : null,
													typeName: this.state.type ? this.state.type.label : null
												})
											})
										}}
										sx={{
											".MuiAutocomplete-input": {
												padding: "7.5px 5px 7.5px 8px !important"
											},
											".MuiOutlinedInput-root": {
												padding: "1.5px !important"
											}
										}}
										isOptionEqualToValue={(option, value) => option.id === value.id}
										renderInput={(params) => <MDInput sx={{ padding: "0px" }} fullWidth label="Pilih Biro Travel" {...params} />}
									/>
								</Grid>
							)}
							{/* Type Notif */}
							<Grid item xs={12} md={3} lg={3}>
								<Autocomplete
									value={this.state.type}
									options={this.state.types}
									onChange={(e, value) => {
										this.setState({ type: value }, () => {
											this.loadData({
												currentPage: 1,
												typeName: value ? value.label : null,
												merchantId: this.state.merchant ? this.state.merchant.id : null,
											})
										})
									}}
									sx={{
										".MuiAutocomplete-input": {
											padding: "7.5px 5px 7.5px 8px !important"
										},
										".MuiOutlinedInput-root": {
											padding: "1.5px !important"
										}
									}}
									isOptionEqualToValue={(option, value) => option.id === value.id}
									renderInput={(params) => <MDInput sx={{ padding: "0px" }} fullWidth label="Pilih Jenis" {...params} />}
								/>
							</Grid>
							{/* Button Search */}
							<Grid item xs={12} md={3} lg={3}>
								<MDBox
									width="100%"
									display="flex"
									flexDirection="row"
									justifyContent="flex-start"
								>
									<MDButton
										color="info"
										variant="gradient"
										onClick={() => {
											const startDate = this.state.startDate ? this.state.startDate : ""
											const endDate = this.state.endDate ? this.state.endDate : ""
											this.loadData({
												currentPage: 1,
												typeName: this.state.type ? this.state.type.label : null,
												merchantId: this.state.merchant ? this.state.merchant.id : null,
											})
										}}
										iconOnly
									>
										<Icon>search</Icon>
									</MDButton>
									<MDBox ml={1}>
										<MDButton
											color="error"
											variant="gradient"
											onClick={() => {
												this.setState({
													currentPage: 1,
													typeName: null,
													merchant: null
												}, () => {
													this.loadData()
												})
											}}
											iconOnly
										>
											<Icon>refresh</Icon>
										</MDButton>
									</MDBox>
								</MDBox>
							</Grid>
						</Grid>
					</MDBox>
					{/* Content */}
					<MDBox p={2}>
						<DataTable
							isLoading={this.state.isLoading}
							tableHead={this.state.tableHead}
							tableData={this.state.rows}
						/>
						<Pagination
							totalPages={this.state.totalPages}
							totalData={this.state.totalData}
							currentPage={this.state.currentPage}
							rowsPerPage={[10, 25, 50, "All"]}
							totalButton={3}
							defaultRowsPerPage={this.state.rowsPerPage}
							onChangeRowsPerPage={(value) => {
								this.setState({ rowsPerPage: value, currentPage: 1 })
								this.loadData({
									rowsPerPage: value,
									currentPage: 1,
									merchantId: this.state.merchant ? this.state.merchant.id : null,
									typeName: this.state.type ? this.state.type.label : null
								})
							}}
							onChangePage={(currentPage) => {
								if (currentPage !== this.state.currentPage) {
									this.setState({ currentPage })
									this.loadData({
										currentPage,
										merchantId: this.state.merchant ? this.state.merchant.id : null,
										typeName: this.state.type ? this.state.type.label : null
									})
								}
							}}
						/>
					</MDBox>
				</Card>
			</MDBox>
		)
	}
}

export default Notification