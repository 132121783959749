/* eslint-disable react/prop-types */
import React from 'react'
import { Link, Navigate } from 'react-router-dom';
// @mui material components
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from 'components/MDInput';
import MDTypography from "components/MDTypography";
import MDDatePicker from 'components/MDDatePicker';

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import ModalNotif from 'contents/Components/ModalNotif';
import withRouter from 'contents/Components/withRouter';

import MiniFormCard from 'contents/Components/FormCard/MiniFormCard';

import Config from 'config'
import _ from "lodash"

import useAxios from "libs/useAxios"
import secureStorage from 'libs/secureStorage';

class FormProvince extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			user: null,
			title: "",
			action: "",
			id: null,

			name: "",
			country: null,

			countries: [],

			countryOpen: false,

			error: [],
			success: [],

			disabledSubmit: false,
			redirect: null
		}

		this.inputRef = React.createRef()
		this.modalNotifRef = React.createRef()
	}

	componentDidMount() {
		this.inputRef.current.focus()
		const user = secureStorage.getItem('user')

		this.setState({
			user: user ? user : null
		})
		this.loadPath()
		this.loadCountry() // load list country
	}

	loadPath() {
		const pathname = window.location.pathname
		const index = pathname.indexOf('edit')
		if (index === -1) {
			this.setState({
				title: "Tambah Provinsi Baru",
				action: "add"
			})
		}
		else {
			const id = this.props.params?.id
			this.loadDetail(id)
			this.setState({
				id,
				title: "Ubah Provinsi",
				action: "edit"
			})
		}
	}

	loadDetail(id) {
		useAxios()
			.post(`${Config.ApiUrl}/master/region/province/detail`, { id })
			.then(response => {
				const data = response.data
				this.setState({
					name: data.name,
					country: data.Country ? {
						id: data.Country.id,
						label: `${data.Country.name} - ${data.Country.kode}`
					} : null,
					success: {
						...this.state.success,
						name: true,
						country: data.Country ? true : false
					}
				})
			})
			.catch(err => {
				console.log(err)
				this.modalNotifRef.current.setShow({
					modalTitle: 'Gagal',
					modalMessage: err.response ? err.response.data : "Koneksi jaringan terputus",
					onClose: () => {
						this.setState({ redirect: '/master/region/province' })
					}
				})
			})
	}

	loadCountry() {
		useAxios()
			.post(`${Config.ApiUrl}/master/region/country/dropdown`)
			.then(response => {
				const data = response.data
				const countries = data.map(item => ({ id: item.id, label: `${item.name} - ${item.kode}` }))
				this.setState({ countries })
			})
			.catch(err => {
				console.log(err)
			})
	}

	handleChange = (e) => {
		const { id, value } = e.target;
		this.setState({
			[id]: value
		})
	}

	handleBlur = (e) => {
		const user = this.state.user
		const { id, value } = e.target
		// eslint-disable-next-line no-empty
		if (value) {
			this.setState({
				success: { ...this.state.success, [e.target.id]: true },
				error: { ...this.state.error, [e.target.id]: false }
			})
		}
		else {
			this.setState({
				success: { ...this.state.success, [e.target.id]: false },
				error: { ...this.state.error, [e.target.id]: true }
			})
		}
	}

	handleKeyDown = (e) => {
		// if (e.key === "Enter") this.handleSubmit()
	}

	handleSubmit = () => {
		const user = this.state.user
		let success = this.state.success
		let error = this.state.error

		if (
			success.name &&
			success.country
		) {
			this.sendData()
		}
		else {
			this.modalNotifRef.current.setShow({
				modalTitle: "Gagal",
				modalMessage: "Data form masih belum lengkap, Silahkan di cek kembali !",
			})
		}
	}

	sendData = () => {
		const country = this.state.country
		const payload = {
			name: this.state.name,
			countryId: country ? country.id : null,
			id: this.state.id
		}

		const url = this.state.action === "add" ? "/master/region/province/create" : "/master/region/province/update"
		useAxios()
			.post(`${Config.ApiUrl}${url}`, payload)
			.then(response => {
				this.modalNotifRef.current.setShow({
					modalTitle: "Sukses",
					modalMessage: response.data,
					onClose: () => {
						this.setState({ redirect: '/master/region/province' })
					}
				})

				this.setState({
					disabledSubmit: false
				})
			})
			.catch(err => {
				this.setState({ disabledSubmit: false })
				if (err.response) {
					this.modalNotifRef.current.setShow({
						modalTitle: "Gagal",
						modalMessage: err.response.data,
					})
				}
				// eslint-disable-next-line no-empty
				else {
					this.modalNotifRef.current.setShow({
						modalTitle: 'Gagal',
						modalMessage: "Koneksi jaringan terputus",
					})
				}
			})
	}

	render() {
		if (this.state.redirect) {
			return <Navigate to={this.state.redirect} />
		}

		return (
			<MiniFormCard>
				<ModalNotif ref={this.modalNotifRef} />
				<MDBox p={3} lineHeight={1}>
					<MDTypography variant="h5" fontWeight="medium">
						{this.state.title}
					</MDTypography>
				</MDBox>

				<MDBox p={3}>
					<MDBox component="form" role="form">
						<Grid container spacing={3}>
							<Grid item xs={12} md={12} lg={12}>
								<MDBox mb={2}>
									<MDInput
										id="name"
										type="text"
										label="Nama Provinsi"
										value={this.state.name}
										onChange={this.handleChange}
										onBlur={this.handleBlur}
										onKeyDown={this.handleKeyDown}
										inputRef={this.inputRef}
										success={this.state.success ? this.state.success.name : false}
										error={this.state.error ? this.state.error.name : false}
										fullWidth
									/>
								</MDBox>
								<MDBox mb={2}>
									<Autocomplete
										disableClearable
										value={this.state.country}
										open={this.state.countryOpen}
										onOpen={() => this.setState({ countryOpen: true })}
										onClose={() => this.setState({ countryOpen: false })}
										onFocus={() => this.setState({ countryOpen: true })}
										options={this.state.countries}
										onChange={(e, value) => {
											if (value) {
												this.setState({
													country: value,
													error: { ...this.state.error, country: false },
													success: { ...this.state.success, country: true }
												})
											}
										}}
										sx={{
											".MuiAutocomplete-input": {
												padding: "7.5px 5px 7.5px 8px !important"
											},
											".MuiOutlinedInput-root": {
												padding: "4px !important"
											}
										}}
										isOptionEqualToValue={(option, value) => option === value}
										renderInput={(params) => <MDInput label="Pilih Negara" {...params} />}
									/>
								</MDBox>

								<MDBox pt={3} display="flex" justifyContent="space-between">
									<MDButton
										variant="gradient"
										color="error"
										component={Link}
										to={{ pathname: "/master/region/province" }}
									>
										KEMBALI
									</MDButton>
									<MDButton
										type="button"
										variant="gradient"
										color="info"
										disabled={this.state.disabledSubmit}
										onKeyDown={this.handleKeyDown}
										onClick={this.handleSubmit}
									>
										SUBMIT
									</MDButton>
								</MDBox>
							</Grid>
						</Grid>
					</MDBox>
				</MDBox>
			</MiniFormCard>
		)
	}
}

export default withRouter(FormProvince)