/* eslint-disable react/jsx-key */
/* eslint-disable no-undef */
import React from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card"

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDBadge from "components/MDBadge";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import StatisticsCard from "contents/Dashboard/components/StatisticsCard"
import CTable from "contents/Components/CTable";
import Loader from "contents/Components/Loader";

import Config from "config"
import useAxios from "libs/useAxios";
import { fnumber } from "libs/currency";
import secureStorage from "libs/secureStorage";

import DashboardOwner from "contents/Dashboard/components/DashboardOwner"
import DashboardBiro from "contents/Dashboard/components/DashboardBiro"
import DashboardAgent from "./components/DashboardAgent";

class Dashboard extends React.Component {
	constructor(props) {
		super(props);
		this.state = {

			isLoading: false,
			redirect: null
		};
	}

	componentDidMount() {

	}

	render() {
		const user = secureStorage.getItem('user')
		if (user && user.roleId === 1) return <DashboardOwner />
		if (user && [2, 3].includes(user.roleId)) return <DashboardBiro />
		else return <DashboardAgent />

	}
}

export default Dashboard;
