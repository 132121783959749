import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function StatisticsCard({ color, title, count, paidAmount, description, icon }) {
	return (
		<Card>
			<MDBox display="flex" justifyContent="space-between" pt={1} px={2}>
				<MDBox
					variant="gradient"
					bgColor={color}
					color={color === "light" ? "dark" : "white"}
					coloredShadow={color}
					borderRadius="xl"
					display="flex"
					justifyContent="center"
					alignItems="center"
					width="4rem"
					height="4rem"
					mt={-3}
				>
					<Icon fontSize="medium" color="inherit">
						{icon}
					</Icon>
				</MDBox>
				<MDBox textAlign="right" lineHeight={1.25}>
					<MDTypography variant="button" fontWeight="light" color="text">
						{title}
					</MDTypography>
					<MDTypography variant="h4">{count}</MDTypography>
					{/* {paidAmount && <MDTypography variant="h6">{paidAmount}</MDTypography>} */}
				</MDBox>
			</MDBox>
			<Divider />
			<MDBox pb={2} px={2}>
				<MDTypography component="p" variant="button" color="text" display="flex">
					&nbsp;{description}
				</MDTypography>
			</MDBox>
		</Card>
	)
}

StatisticsCard.defaultProp = {
	color: "info"
}

StatisticsCard.propTypes = {
	color: PropTypes.oneOf([
		"primary",
		"secondary",
		"info",
		"success",
		"warning",
		"error",
		"light",
		"dark",
	]),
	title: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	paidAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	icon: PropTypes.node,
}

export default StatisticsCard