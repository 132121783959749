import React, { useState } from "react";
import PropTypes from "prop-types";
import { Navigate, Link } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDAvatar from "components/MDAvatar";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import DataTable from "contents/Components/DataTable";
import Pagination from "contents/Components/Pagination";

import useAxios from "libs/useAxios";
import Config from "config";
import secureStorage from "libs/secureStorage";
import { fnumber } from "libs/currency";

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rowsPerPage: 10,
      currentPage: 1,
      totalPages: 0,
      totalData: 0,
      tableHead: [
        { Header: "nama", accessor: "name", width: "25%" },
        { Header: "email", accessor: "email", width: "20%" },
        { Header: "telpon", accessor: "phone", width: "15%" },
        { Header: "pesan", accessor: "message", width: "35%" },
      ],
      rows: [],
      keyword: "",
      isLoading: true,
    };
  }

  componentDidMount() {
    const user = secureStorage.getItem("user");
    user && user.roleId === 1 && this.loadData();
  }

  loadData = (params) => {
    this.setState({ isLoading: true });
    const payload = {
      keyword: params && params.keyword ? params.keyword : this.state.keyword,
      currentPage: params && params.currentPage ? params.currentPage : 1,
      rowsPerPage: params && params.rowsPerPage ? params.rowsPerPage : this.state.rowsPerPage,
    };
    useAxios()
      .post(`${Config.ApiUrl}/contact`, payload)
      .then((response) => {
        const data = response.data;

        const output = data.output.map((item) => {
          const message = <p style={{ wordWrap: "break-word", width: "40em" }}>{item.message}</p>;
          return {
            name: (
              <MDTypography variant="caption" fontWeight="regular">
                {item.name}
              </MDTypography>
            ),
            email: (
              <MDTypography variant="caption" fontWeight="regular">
                {item.email}
              </MDTypography>
            ),
            phone: (
              <MDTypography variant="caption" fontWeight="regular">
                {item.phone}
              </MDTypography>
            ),
            message: (
              <p style={{ wordWrap: "break-word", width: "40em" }}>
                <MDTypography variant="caption" fontWeight="regular">
                  {item.message}
                </MDTypography>
              </p>
            )
          };
        });

        this.setState({
          totalPages: data.totalPages,
          totalData: data.totalData,
          rows: output,
          isLoading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ isLoading: false });
      });
  };

  render() {
    return (
      <MDBox pb={3} my={3}>
        <Card>
          <MDBox p={2} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              Daftar Kontak
            </MDTypography>
          </MDBox>
          <MDBox px={2} width="100%" display="flex" justifyContent="flex-start">
            <Grid container spacing={3}>
              <Grid item xs={12} md={3} lg={3}>
                <MDInput
                  label="Search..."
                  size="small"
                  fullWidth
                  value={this.state.keyword}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      this.loadData({
                        currentPage: 1,
                        keyword: e.target.value,
                      });
                    }
                  }}
                  onChange={(e) => this.setState({ keyword: e.target.value })}
                />
              </Grid>
            </Grid>
          </MDBox>
          <MDBox p={2}>
            <DataTable
              isLoading={this.state.isLoading}
              tableHead={this.state.tableHead}
              tableData={this.state.rows}
            />
            <Pagination
              totalPages={this.state.totalPages}
              totalData={this.state.totalData}
              currentPage={this.state.currentPage}
              rowsPerPage={[10, 25, 50, "All"]}
              totalButton={3}
              defaultRowsPerPage={this.state.rowsPerPage}
              onChangeRowsPerPage={(value) => {
                this.setState({ rowsPerPage: value, currentPage: 1 });
                this.loadData({
                  rowsPerPage: value,
                  currentPage: 1,
                });
              }}
              onChangePage={(currentPage) => {
                if (currentPage !== this.state.currentPage) {
                  this.setState({ currentPage });
                  this.loadData({
                    currentPage,
                  });
                }
              }}
            />
          </MDBox>
        </Card>
      </MDBox>
    );
  }
}

export default Contact;
