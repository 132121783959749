/* eslint-disable no-undef */

const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

export function register(config) {
    if (window.location.href.indexOf('/apidoc') !== -1) {
        unregister()
        window.location.reload()
    }
    else {
        if ('serviceWorker' in navigator) {
            const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
            if (publicUrl.origin !== window.location.origin) {
                // Our service worker won't work if PUBLIC_URL is on a different origin
                // from what our page is served on. This might happen if a CDN is used to
                // serve assets; see https://github.com/facebook/create-react-app/issues/2374
                return;
            }

            window.addEventListener('load', () => {
                const swUrl = `https://${publicUrl.hostname}/sw.js`
                // console.log({ swUrl, isLocalhost })
                if (isLocalhost) {
                    checkValidServiceWorker(`http://${publicUrl.hostname}:${publicUrl.port}/sw.js`, config);
                    navigator.serviceWorker.ready.then(() => {
                        console.log(
                            'This web app is being served cache-first by a service ' +
                            'worker. To learn more, visit https://bit.ly/CRA-PWA'
                        );
                    });
                }
                else {
                    registerValidSW(swUrl, config);
                    navigator.serviceWorker.ready.then(() => {
                        console.log(
                            'This web app is being served cache-first by a service ' +
                            'worker. '
                        );
                    })
                }
            })
        }
    }
}

function registerValidSW(swUrl, config) {
    navigator.serviceWorker.addEventListener('message', (event) => {
        console.log({ event })
        if (!event.data) {
            return;
        }

        switch (event.data) {
            case 'reloadWindow':
                window.location.reload();
                break;
            default:
                // NOOP
                break;
        }
    });

    navigator.serviceWorker
        .register(swUrl)
        .then(registration => {
            if (!navigator.serviceWorker.controller) {
                // The window client isn't currently controlled so it's a new service
                // worker that will activate immediately
                return;
            }
            registration.addEventListener('updatefound', () => {
                const installingWorker = registration.installing;
                if (installingWorker == null) {
                    return;
                }
                installingWorker.addEventListener('statechange', () => {
                    if (installingWorker.state === 'installed') {
                        if (navigator.serviceWorker.controller) {
                            // At this point, the updated precached content has been fetched,
                            // but the previous service worker will still serve the older
                            // content until all client tabs are closed.
                            // console.log(
                            //   'New content is available and will be used when all ' +
                            //     'tabs for this page are closed. See https://bit.ly/CRA-PWA.'
                            // );

                            // Execute callback
                            if (config && config.onUpdate) {
                                registration.waiting.postMessage('skipWaiting')
                                config.onUpdate(registration.waiting);
                            }
                        } else {
                            // At this point, everything has been precached.
                            // It's the perfect time to display a
                            // "Content is cached for offline use." message.
                            console.log('Content is cached for offline use.');

                            // Execute callback
                            if (config && config.onSuccess) {
                                config.onSuccess(registration);
                            }
                        }
                    }
                })
            })
        })
        .catch(error => {
            console.error('Error during service worker registration:', error);
        });
}

function checkValidServiceWorker(swUrl, config) {
    fetch(swUrl)
        .then(response => {
            const contentType = response.headers.get('content-type')
            if (
                response.status === 404 ||
                (contentType != null && contentType.indexOf('javascript') === -1)
            ) {
                // No service worker found. Probably a different app. Reload the page.
                navigator.serviceWorker.ready.then(registration => {
                    registration.unregister().then(() => {
                        window.location.reload();
                    });
                });
            }
            else {
                // Service worker found. Proceed as normal.
                console.log("Service worker found. Proceed as normal.")
                registerValidSW(swUrl, config);
            }
        })
        .catch(() => {
            console.log(
                'No internet connection found. App is running in offline mode.'
            );
        })
}

export function unregister() {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready.then(registration => {
            registration.unregister();
        });
    }
}