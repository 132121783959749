/* eslint-disable react/prop-types */
import React from "react"
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom"

import MDButton from "components/MDButton"

const ButtonBack = React.forwardRef((props, ref) => {
	const navigate = useNavigate()
	return (
		<MDButton
			ref={ref}
			variant="gradient"
			color={"error"}
			{...props}
			onClick={() => navigate(-1)}
		>
			{props.label}
		</MDButton>
	)
})

export default ButtonBack