import React, { useState } from "react";
import { Link } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import DataTable from 'contents/Components/DataTable'
import Pagination from "contents/Components/Pagination";
import ButtonAction from "contents/Administrasi/ListMember/components/ButtonAction"

import useAxios from "libs/useAxios"
import Config from 'config'
import secureStorage from "libs/secureStorage";

class ListMember extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			merchant: null,
			product: null,

			merchants: [],
			products: [],

			rowsPerPage: 10,
			currentPage: 1,
			totalPages: 0,
			totalData: 0,
			tableHead: [
				{ Header: "action", accessor: "action", width: "10%" },
				{ Header: "kode", accessor: "kode", width: "15%" },
				{ Header: "no ktp", accessor: "nik", width: "20%" },
				{ Header: "nama", accessor: "name", width: "20%" },
				{ Header: "phone", accessor: "phone", width: "20%" },
				{ Header: "pembayaran", accessor: "paymentType", width: "15%" },
				{ Header: "author", accessor: "author", width: "20%" },
			],
			rows: [],
			keyword: "",
			isLoading: true,
		}
	}

	componentDidMount() {
		const user = secureStorage.getItem('user')

		this.loadData()
		user && user.roleId === 1 && this.loadMerchants()
		// this.loadProduct()
	}

	loadMerchants = () => {
		useAxios()
			.post(`${Config.ApiUrl}/manage/merchant/dropdown`)
			.then(response => {
				const data = response.data

				this.setState({
					merchants: data.map(item => ({ id: item.id, label: item.name }))
				})
			})
			.catch(err => {
				console.log(err)
			})
	}

	loadProduct = (params) => {
		const merchantId = params && params.merchantId ? { merchantId: params.merchantId } : {}
		const payload = {
			...merchantId
		}

		useAxios()
			.post(`${Config.ApiUrl}/product/dropdown`, payload)
			.then(response => {
				const data = response.data

				this.setState({
					products: data.map(item => ({ id: item.id, label: item.name }))
				})
			})
			.catch(err => {
				console.log(err)
			})
	}

	loadData = (params) => {
		this.setState({ isLoading: true })
		const merchantId = params && params.merchantId ? { merchantId: params.merchantId } : {}
		const productId = params && params.productId ? { productId: params.productId } : {}

		const payload = {
			type: 'list',
			keyword: params && params.keyword ? params.keyword : this.state.keyword,
			currentPage: params && params.currentPage ? params.currentPage : 1,
			rowsPerPage: params && params.rowsPerPage ? params.rowsPerPage : this.state.rowsPerPage,
			...productId,
			...merchantId
		};

		useAxios()
			.post(`${Config.ApiUrl}/user/member`, payload)
			.then(response => {
				const data = response.data

				const output = data.output.map(item => {
					const member = item.Member ? item.Member : null

					return {
						kode: (
							<MDTypography
								variant="caption"
								fontWeight="medium"
								sx={{
									cursor: "pointer"
								}}
								color="info"
								component={Link}
								to={{ pathname: `/adm/member/detail/${item.id}` }}
							>
								{member?.kode}
							</MDTypography>
						),
						name: item.name,
						nik: member.nik,
						paymentType: member.paymentType,
						author: item.ParentUser && item.ParentUser.name ? item.ParentUser.name : '-',
						phone: item.phone,
						action: (
							<ButtonAction
								id={item.id}
								refreshData={this.loadData}
							/>
						)
					}
				})

				this.setState({
					totalPages: data.totalPages,
					totalData: data.totalData,
					rows: output,
					isLoading: false
				})
			})
			.catch(err => {
				console.log(err)
				this.setState({ isLoading: false })
			})
	}

	render() {
		const user = secureStorage.getItem('user')

		return (
			<MDBox pb={3} my={3}>
				<Card>
					<MDBox p={2} lineHeight={1}>
						<MDTypography variant="h5" fontWeight="medium">
							Daftar Jamaah
						</MDTypography>
					</MDBox>
					<MDBox px={2} width="100%" display="flex" justifyContent="flex-start">
						<Grid container spacing={3}>
							<Grid item xs={12} md={3} lg={3}>
								<MDInput
									label="Search..."
									size="small"
									fullWidth
									value={this.state.keyword}
									onKeyPress={(e) => {
										if (e.key === "Enter") {
											this.loadData({
												currentPage: 1,
												keyword: e.target.value,
												productCategoryId: this.state.category ? this.state.category.id : null,
												productId: this.state.product ? this.state.product.id : null,
												merchantId: this.state.merchant ? this.state.merchant.id : null
											})
										}
									}}
									onChange={(e) => this.setState({ keyword: e.target.value })}
								/>
							</Grid>
							{/* Merchant */}
							{[1].includes(user.roleId) && (
								<Grid item xs={12} md={3} lg={3}>
									<Autocomplete
										value={this.state.merchant}
										options={this.state.merchants}
										onChange={(e, value) => {
											if (value) this.loadProduct({ merchantId: value?.id })
											else this.loadProduct()
											this.setState({ merchant: value }, () => {
												this.loadData({
													currentPage: 1,
													merchantId: value ? value.id : null,
													productCategoryId: this.state.category ? this.state.category.id : null,
												})
											})
										}}
										sx={{
											".MuiAutocomplete-input": {
												padding: "7.5px 5px 7.5px 8px !important"
											},
											".MuiOutlinedInput-root": {
												padding: "1.5px !important"
											}
										}}
										isOptionEqualToValue={(option, value) => option.id === value.id}
										renderInput={(params) => <MDInput sx={{ padding: "0px" }} fullWidth label="Pilih Biro Travel" {...params} />}
									/>
								</Grid>
							)}
						</Grid>
					</MDBox>
					<MDBox p={2}>
						<DataTable
							isLoading={this.state.isLoading}
							tableHead={this.state.tableHead}
							tableData={this.state.rows}
						/>
						<Pagination
							totalPages={this.state.totalPages}
							totalData={this.state.totalData}
							currentPage={this.state.currentPage}
							rowsPerPage={[10, 25, 50, "All"]}
							totalButton={3}
							defaultRowsPerPage={this.state.rowsPerPage}
							onChangeRowsPerPage={(value) => {
								this.setState({ rowsPerPage: value, currentPage: 1 })
								this.loadData({
									rowsPerPage: value,
									currentPage: 1,
									productId: this.state.product ? this.state.product.id : null,
									productCategoryId: this.state.category ? this.state.category.id : null,
									merchantId: this.state.merchant ? this.state.merchant.id : null
								})
							}}
							onChangePage={(currentPage) => {
								if (currentPage !== this.state.currentPage) {
									this.setState({ currentPage })
									this.loadData({
										currentPage,
										productId: this.state.product ? this.state.product.id : null,
										productCategoryId: this.state.category ? this.state.category.id : null,
										merchantId: this.state.merchant ? this.state.merchant.id : null
									})
								}
							}}
						/>
					</MDBox>
				</Card>
			</MDBox>
		)
	}
}

export default ListMember