/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { Link, Navigate } from "react-router-dom";
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Paper from "@mui/material/Paper";
import Autocomplete from "@mui/material/Autocomplete";

// @mui icons
import { Close } from "@mui/icons-material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

import ModalNotif from "contents/Components/ModalNotif";
import DialogForm from "contents/Components/DialogForm";

import Config from "config";
import _ from "lodash";
import moment from "moment";

import useAxios from "libs/useAxios";
import secureStorage from "libs/secureStorage";

class UserCertificate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: props.user,

      member: null,
      members: [],

      error: [],
      success: [],

      disabledSubmit: false,

      merchants: [],
    };

    this.inputRef = React.createRef();
    this.modalNotifRef = React.createRef();
    this.dialogFormRef = React.createRef();
  }

  componentDidMount() {
    const user = secureStorage.getItem('user')
    this.loadMember(user);
  }

  setShow = (data) => {
    this.dialogFormRef.current.setShow({ ...data, title: "Tambah Jamaah" });
  };

  loadMember = (user) => {
    const merchantId = user && user.Merchant ? { merchantId: user.Merchant?.id } : {};
    const payload = {
      ...merchantId,
      attributes: ['id', 'kode', 'name', 'address', 'placeBirth', 'dateBirth', 'userId']
    }

    useAxios()
      .post(`${Config.ApiUrl}/user/member/dropdown`, payload)
      .then((response) => {
        const data = response.data;
        const members = data.map((item) => ({
          id: item.id,
          label:
            item.name +
            ", " +
            item.placeBirth +
            ", " +
            moment(item.dateBirth).format("DD-MM-YYYY"),
          name: item.name,
          placeBirth: item.placeBirth,
          dateBirth: item.dateBirth,
          address: item.address,
          userId: item.userId
        }));
        this.setState({ members });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleSave = () => {
    const member = this.state.member;
    if (member) {
      this.props.onAction({
        member: member ? member : null,
      })
      this.setState({ member: null, }, () => {
        this.dialogFormRef.current.setShow({ show: false })
      })

    } else {
      this.modalNotifRef.current.setShow({
        modalTitle: "Gagal",
        modalMessage: "Data form masih belum lengkap, Silahkan di cek kembali !",
      });
    }
  };

  render() {
    return (
      <DialogForm ref={this.dialogFormRef} maxWidth="sm">
        <ModalNotif ref={this.modalNotifRef} />

        <Grid container item xs={12} lg={12} sx={{ mx: "auto" }}>
          <MDBox width="100%" component="form" mt={2}>
            <MDBox mb={2}>
              <Autocomplete
                disableClearable
                value={this.state.member}
                options={this.state.members}
                onChange={(e, value) => {
                  if (value) {
                    this.setState({ member: value });
                  }
                }}
                sx={{
                  input: {
                    padding: "7.5px 5px 7.5px 8px !important",
                  },
                  "& .MuiOutlinedInput-root": {
                    padding: "5px !important",
                  },
                }}
                isOptionEqualToValue={(option, value) => option === value}
                renderInput={(params) => (
                  <MDInput inputRef={this.inputRef} {...params} label="Pilih jama'ah" />
                )}
              />
            </MDBox>
          </MDBox>

          <MDBox
            py={3}
            width="100%"
            display="flex"
            justifyContent={{ md: "flex-end", xs: "center" }}
          >
            <MDBox mr={1}>
              <MDButton
                variant="gradient"
                color="error"
                onClick={() => this.dialogFormRef.current.setShow({ show: false })}
              >
                Tutup
              </MDButton>
            </MDBox>
            <MDButton
              variant="gradient"
              color="info"
              disabled={this.state.disabledSubmit}
              onClick={this.handleSave}
            >
              Submit
            </MDButton>
          </MDBox>
        </Grid>
      </DialogForm>
    );
  }
}

UserCertificate.propTypes = {
  user: PropTypes.object,
  onAction: PropTypes.func.isRequired,
};

export default UserCertificate;
