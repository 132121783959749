const ReceiptStyle = {
	page: {
		fontFamily: 'RobotoMono',
		fontSize: 6,
		paddingTop: 10,
		paddingLeft: 10,
		paddingRight: 10,
		lineHeight: 1.5,
		flexDirection: "column"
	},
	headerContainer: {
		marginBottom: 2,
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "flex-end",
		fontSize: 5,
		borderBottomColor: "#000",
		borderBottomWidth: 0.6,
		paddingBottom: 2
	},
	headerLeft: {
		display: "flex",
		// width: "60%",
		// flexDirection: "column",
		// alignItems: "flex-start"
		width: "100%",
		flexDirection: "row",
		justifyContent: "flex-start",
	},
	headerLogo: {
		width: 30,
		height: 28,
		marginRight: 3
		// marginLeft: 'auto',
		// marginRight: 'auto'
	},
	headerLeftDesc: {
		display: "flex",
		width: "60%",
		flexDirection: "column",
		alignItems: "flex-start",
		justifyContent: "flex-end"
	},
	company: {
		fontSize: 6,
		fontWeight: "bold",
	},
	headerLeftLabel: {
		fontSize: 4
	},
	headerRight: {
		width: "40%"
	},
	headerRightContainer: {
		width: "100%",
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-end",
		alignItems: "flex-end"
	},
	headerNumber: {
		width: "20%",
		fontWeight: "bold",
		marginRight: 1,
	},
	headerRightLabel: {
		width: "50%",
		textAlign: "right"
	},
	titleContainer: {
		display: "flex",
		justifyContent: "center",
		flexDirection: 'row',
		marginTop: 2,
		marginBottom: 2
	},
	titleText: {
		color: "#000",
		fontWeight: "bold",
		fontSize: 7,
		textAlign: 'center',
		textTransform: 'uppercase',
	},
	tableContainer: {
		width: "100%",
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
		flexWrap: 'wrap',
		marginTop: 10
	},
	tableTitle: {
		width: "100%",
		fontWeight: "bold",
		fontSize: 5,
	},
	tableRow: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
		paddingTop: 1,
		paddingBottom: 1,
		fontSize: 5
	},
	tableCellLabel: {
		width: "25%",
		textAlign: 'left',
	},
	tableCellDivider: {
		width: "5%"
	},
	tableCellValue: {
		width: "70%",
		textAlign: 'left',
		borderBottomColor: "#000",
		borderBottomWidth: 0.6,
		borderBottomStyle: "dotted"
	},
	tableFooter: {
		width: "100%",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		// alignItems: "center",
		marginTop: 5
	},
	tableFooterBox: {
		width: "30%",
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-end"
	},
	tableFooterLabel: {
		textAlign: "left",
		borderBottomColor: "#000",
		borderBottomStyle: "dashed",
		borderBottomWidth: 0.6
	},
	tableFooterItem: {
		width: "100%",
		marginTop: 15,
		borderBottomColor: "#000",
		borderBottomStyle: "dashed",
		borderBottomWidth: 0.6,
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center"
	},
	tableFooterLeft: {
		width: "100%",
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		borderColor: "#000",
		borderWidth: 0.6,
		padding: 2
	},
	labelAmount: {
		fontSize: 7,
		fontWeight: "bold",
		textTransform: 'uppercase',
	},
	amount: {
		fontSize: 7,
		fontWeight: "bold",
		marginLeft: 3,
	}
}

export default ReceiptStyle