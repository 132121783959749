import React from "react";
import { Navigate, Link } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import DataTable from "contents/Components/DataTable";
import Pagination from "contents/Components/Pagination";
import ButtonAction from "contents/Administrasi/Certificate/ButtonAction";

import useAxios from "libs/useAxios";
import Config from "config";
import secureStorage from "libs/secureStorage";
import { fnumber } from "libs/currency";
import moment from "moment";

class Certificate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      merchant: null,

      merchants: [],

      type: "Keterangan",
      types: ["Keterangan", "Kuasa"],

      rowsPerPage: 10,
      currentPage: 1,
      totalPages: 0,
      totalData: 0,
      tableHead: [
        { Header: "Action", accessor: "action", width: "10%" },
        { Header: "no", accessor: "no", width: "15%" },
        { Header: "Type", accessor: "type", width: "15%" },
        { Header: "Information", accessor: "information", width: "40%" },
      ],
      rows: [],
      keyword: "",
      isLoading: true,
    };

    this.loadDataCertificate = this.loadDataCertificate.bind(this);
    this.loadDataPoa = this.loadDataPoa.bind(this);
  }

  componentDidMount() {
    const user = secureStorage.getItem("user");
    this.setState({ user }, () => {
      this.loadDataCertificate();
      user && user.roleId === 1 && this.loadMerchants();
    });
  }

  onChangeType = (value, payload) => {
    if (value == "Keterangan") {
      this.loadDataCertificate(payload);
    } else if (value == "Kuasa") {
      this.loadDataPoa(payload);
    }
  };

  loadMerchants = () => {
    useAxios()
      .post(`${Config.ApiUrl}/manage/merchant/dropdown`)
      .then((response) => {
        const data = response.data;
        this.setState({
          merchants: data.map((item) => ({ id: item.id, label: item.name })),
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  loadDataCertificate(params) {
    this.setState({ isLoading: true });
    const user = secureStorage.getItem("user");
    const merchantId = params && params.merchantId ? { merchantId: params.merchantId } : {};

    const payload = {
      keyword: params && params.keyword ? params.keyword : this.state.keyword,
      currentPage: params && params.currentPage ? params.currentPage : 1,
      rowsPerPage: params && params.rowsPerPage ? params.rowsPerPage : this.state.rowsPerPage,
      ...merchantId,
    };

    useAxios()
      .post(`${Config.ApiUrl}/certificate/list`, payload)
      .then((response) => {
        const data = response.data;
        let no = 0;
        const output = data.output.map((item) => {
          no++;
          return {
            no,
            type: item.type,
            information:
              item.nomor + ", " + moment(item.date).format("DD-MMM-YYYY") + ", " + item.subject,
            action:
              user && [1, 2, 3].includes(user.roleId) ? (
                <ButtonAction
                  id={item.id}
                  refreshData={this.loadDataCertificate}
                  type="Keterangan"
                ></ButtonAction>
              ) : (
                "-"
              ),
          };
        });

        this.setState({
          totalPages: data.totalPages,
          totalData: data.totalData,
          rows: output,
          isLoading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ isLoading: false });
      });
  }

  loadDataPoa(params) {
    this.setState({ isLoading: true });
    const user = secureStorage.getItem("user");
    const merchantId =
      params && params.merchantId
        ? { merchantId: params.merchantId }
        : user.Merchant?.id ?? {};

    const payload = {
      keyword: params && params.keyword ? params.keyword : this.state.keyword,
      currentPage: params && params.currentPage ? params.currentPage : 1,
      rowsPerPage: params && params.rowsPerPage ? params.rowsPerPage : this.state.rowsPerPage,
      ...merchantId,
    };

    useAxios()
      .post(`${Config.ApiUrl}/certificate/poa/list`, payload)
      .then((response) => {
        const data = response.data;
        let no = 0;
        const output = data.output.map((item) => {
          no++;
          return {
            no,
            type: item.type,
            information:
              item.place + ", " + moment(item.date).format("DD-MMM-YYYY") + ", " + item.direction,
            action:
              user && [1, 2, 3].includes(user.roleId) ? (
                <ButtonAction
                  id={item.id}
                  refreshData={this.loadDataPoa}
                  type="Surat Kuasa"
                ></ButtonAction>
              ) : (
                "-"
              ),
          };
        });

        this.setState({
          totalPages: data.totalPages,
          totalData: data.totalData,
          rows: output,
          isLoading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ isLoading: false });
      });
  }

  render() {
    const user = secureStorage.getItem("user");

    return (
      <MDBox pb={3} my={3}>
        <MDBox
          pb={2}
          mt={{ xs: 2, md: 0 }}
          display="flex"
          justifyContent={{ xs: "center", md: "flex-start" }}
        >
          {[2, 3].includes(user.roleId) && (
            <MDButton
              size="medium"
              color="info"
              variant="gradient"
              component={Link}
              to={{ pathname: "/adm/certificate/add" }}
            >
              TAMBAH
            </MDButton>
          )}
        </MDBox>
        <Card>
          <MDBox p={2} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              Daftar Surat {this.state.type}
            </MDTypography>
          </MDBox>

          <MDBox px={2} width="100%" display="flex" justifyContent="flex-start">
            <Grid container spacing={3}>
              <Grid item xs={12} md={3} lg={3}>
                <MDInput
                  label="Search..."
                  size="small"
                  fullWidth
                  value={this.state.keyword}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      this.onChangeType(this.state.type, {
                        currentPage: 1,
                        keyword: e.target.value,
                        merchantId: this.state.merchant ? this.state.merchant.id : null,
                      });
                    }
                  }}
                  onChange={(e) => this.setState({ keyword: e.target.value })}
                />
              </Grid>
              {/* Merchant */}
              {[1].includes(user.roleId) && (
                <Grid item xs={12} md={3} lg={3}>
                  <Autocomplete
                    value={this.state.merchant}
                    options={this.state.merchants}
                    onChange={(e, value) => {
                      this.setState({ merchant: value }, () => {
                        this.loadDataCertificate({
                          currentPage: 1,
                          merchantId: value ? value.id : null,
                        });
                      });
                    }}
                    sx={{
                      ".MuiAutocomplete-input": {
                        padding: "7.5px 5px 7.5px 8px !important",
                      },
                      ".MuiOutlinedInput-root": {
                        padding: "1.5px !important",
                      },
                    }}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    renderInput={(params) => (
                      <MDInput
                        sx={{ padding: "0px" }}
                        fullWidth
                        label="Pilih Biro Travel"
                        {...params}
                      />
                    )}
                  />
                </Grid>
              )}
              <Grid item xs={12} md={3} lg={3}>
                <Autocomplete
                  value={this.state.type}
                  options={this.state.types}
                  onChange={(e, value) => {
                    this.setState({ type: value });
                    this.onChangeType(value, {
                      merchantId: this.state.merchant?.id
                        ? this.state.merchant.id
                        : user.Merchant?.id,
                    });
                  }}
                  sx={{
                    ".MuiAutocomplete-input": {
                      padding: "7.5px 5px 7.5px 8px !important",
                    },
                    ".MuiOutlinedInput-root": {
                      padding: "1.5px !important",
                    },
                  }}
                  isOptionEqualToValue={(option, value) => option === value}
                  renderInput={(params) => (
                    <MDInput
                      sx={{ padding: "0px" }}
                      fullWidth
                      label="Pilih Type Surat"
                      {...params}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </MDBox>
          <MDBox p={2}>
            <DataTable
              isLoading={this.state.isLoading}
              tableHead={this.state.tableHead}
              tableData={this.state.rows}
            />
            <Pagination
              totalPages={this.state.totalPages}
              totalData={this.state.totalData}
              currentPage={this.state.currentPage}
              rowsPerPage={[10, 25, 50, "All"]}
              totalButton={3}
              defaultRowsPerPage={this.state.rowsPerPage}
              onChangeRowsPerPage={(value) => {
                this.setState({ rowsPerPage: value, currentPage: 1 });
                this.loadDataCertificate({
                  rowsPerPage: value,
                  currentPage: 1,
                  merchantId: this.state.merchant ? this.state.merchant.id : null,
                });
              }}
              onChangePage={(currentPage) => {
                if (currentPage !== this.state.currentPage) {
                  this.setState({ currentPage });
                  this.loadDataCertificate({
                    currentPage,
                    merchantId: this.state.merchant ? this.state.merchant.id : null,
                  });
                }
              }}
            />
          </MDBox>
        </Card>
      </MDBox>
    );
  }
}

export default Certificate;
