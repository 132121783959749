/* eslint-disable react/prop-types */
import React from "react";
import { Link, Navigate } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import ModalNotif from "contents/Components/ModalNotif";
import withRouter from "contents/Components/withRouter";

import MiniFormCard from "contents/Components/FormCard/MiniFormCard";

import Config from "config";
import _ from "lodash";

import useAxios from "libs/useAxios";
import secureStorage from "libs/secureStorage";

class FormBranchOffice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      title: "",
      action: "",
      id: null,

      name: "",
      address: "",
      phone: "",
      owner: "",

      province: null,
      district: null,
      provinces: [],
      districts: [],

      provinceOpen: false,
      districtOpen: false,

      error: {
        province: true,
        district: true,
      },
      success: [],

      disabledSubmit: false,
      redirect: null,
    };

    this.inputRef = React.createRef();
    this.modalNotifRef = React.createRef();
  }

  componentDidMount() {
    this.inputRef.current.focus()

    this.loadProvince()
    this.loadPath()
  }

  loadPath() {
    const pathname = window.location.pathname;
    const index = pathname.indexOf("edit");
    if (index === -1) {
      this.setState({
        title: "Tambah Kantor Cabang Baru",
        action: "add",
      });
    } else {
      const id = this.props.params?.id;
      this.loadDetail(id);
      this.setState({
        id,
        title: "Ubah Data Kantor Cabang",
        action: "edit",
      });
    }
  }

  loadDetail(id) {
    useAxios()
      .post(`${Config.ApiUrl}/manage/branchoffice/get`, { id })
      .then((response) => {
        const data = response.data;

        const province = data && data.Province ? data.Province : null
        const district = data && data.District ? data.District : null

        this.setState({
          name: data.name ?? "-",
          address: data.address ?? "-",
          phone: data.phone ?? "-",
          owner: data.owner ?? "-",
          province: province ? { id: province.id, label: province.name } : null,
          district: district ? { id: district.id, label: district.name } : null,
          success: {
            ...this.state.success,
            name: data.name ? true : false,
            phone: data.phone ? true : false,
            owner: data.owner ? true : false,
            address: data.address ? true : false,
            province: province ? true : false,
            district: district ? true : false,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        this.modalNotifRef.current.setShow({
          modalTitle: "Gagal",
          modalMessage: err.response ? err.response.data : "Koneksi jaringan terputus",
          onClose: () => {
            this.setState({ redirect: "/manage/branchoffice" });
          },
        });
      });
  }

  loadProvince = () => {
    useAxios()
      .post(`${Config.ApiUrl}/master/region/province/dropdown`, { countryId: 1 })
      .then((response) => {
        const data = response.data;
        const provinces = data.map((item) => ({
          id: item.id,
          label: item.name,
        }));
        this.setState({ provinces });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  loadDistrict = (provinceId) => {
    useAxios()
      .post(`${Config.ApiUrl}/master/region/district/dropdown`, { provinceId })
      .then((response) => {
        const data = response.data;

        const districts = data.map((item) => ({ id: item.id, label: item.name }));
        this.setState({ districts });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleChange = (e) => {
    const { id, value } = e.target;
    this.setState({
      [id]: value,
    });
  };

  handleBlur = (e) => {
    const { id, value } = e.target;
    // eslint-disable-next-line no-empty
    if (value) {
      this.setState({
        success: { ...this.state.success, [e.target.id]: true },
        error: { ...this.state.error, [e.target.id]: false },
      });
    } else {
      this.setState({
        success: { ...this.state.success, [e.target.id]: false },
        error: { ...this.state.error, [e.target.id]: true },
      });
    }
  };

  handleSubmit = () => {
    let error = this.state.error;
    let success = this.state.success;

    if (
      success.name &&
      success.phone &&
      success.owner &&
      success.address &&
      success.province,
      success.district,
      success.address
    ) {
      this.sendData();
    } else {
      this.modalNotifRef.current.setShow({
        modalTitle: "Gagal",
        modalMessage: "Data form masih belum lengkap, Silahkan di cek kembali !",
      });
    }
  };

  sendData = () => {
    this.setState({ disabledSubmit: true })
    const user = this.state.user

    const payload = {
      id: this.state.id,
      name: this.state.name,
      phone: this.state.phone,
      owner: this.state.owner,
      address: this.state.address,
      provinceId: this.state.province?.id,
      districtId: this.state.district?.id,
    }

    const url = this.state.action === "add"
      ? "/manage/branchoffice/create"
      : "/manage/branchoffice/update"

    useAxios()
      .post(`${Config.ApiUrl}${url}`, payload)
      .then((response) => {
        this.modalNotifRef.current.setShow({
          modalTitle: "Sukses",
          modalMessage: response.data,
          onClose: () => {
            this.setState({ redirect: "/manage/branchoffice" })
          },
        })

        this.setState({
          disabledSubmit: false,
        });
      })
      .catch((err) => {
        this.setState({ disabledSubmit: false });
        if (err.response) {
          this.modalNotifRef.current.setShow({
            modalTitle: "Gagal",
            modalMessage: err.response ? err.response.data : "Koneksi jaringan terputus",
          });
        }
        // eslint-disable-next-line no-empty
        else {
          this.modalNotifRef.current.setShow({
            modalTitle: "Gagal",
            modalMessage: "Koneksi jaringan terputus",
          });
        }
      });
  };

  render() {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />;
    }

    return (
      <MiniFormCard>
        <ModalNotif ref={this.modalNotifRef} />
        <MDBox p={3} lineHeight={1}>
          <MDTypography variant="h5" fontWeight="medium">
            {this.state.title}
          </MDTypography>
        </MDBox>

        <MDBox p={3}>
          <MDBox component="form" role="form">
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12}>
                {/* Name */}
                <MDBox mb={2}>
                  <MDInput
                    inputRef={this.inputRef}
                    id="name"
                    type="text"
                    label="Nama Kantor"
                    value={this.state.name}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    success={this.state.success ? this.state.success.name : false}
                    error={this.state.error ? this.state.error.name : false}
                    fullWidth
                  />
                </MDBox>
                {/* Phone */}
                <MDBox mb={2}>
                  <MDInput
                    id="phone"
                    type="text"
                    label="Nomor Telpon"
                    value={this.state.phone}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    success={this.state.success ? this.state.success.phone : false}
                    error={this.state.error ? this.state.error.phone : false}
                    fullWidth
                  />
                </MDBox>
                {/* Onwer */}
                <MDBox mb={2}>
                  <MDInput
                    id="owner"
                    type="text"
                    label="Penanggung Jawab"
                    value={this.state.owner}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    success={this.state.success ? this.state.success.owner : false}
                    error={this.state.error ? this.state.error.owner : false}
                    fullWidth
                  />
                </MDBox>
                {/* Address */}
                <MDBox mb={2}>
                  <MDInput
                    id="address"
                    type="text"
                    rows={3}
                    multiline
                    label="Alamat"
                    value={this.state.address}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    success={this.state.success ? this.state.success.address : false}
                    error={this.state.error ? this.state.error.address : false}
                    fullWidth
                  />
                </MDBox>
                {/* Province */}
                <MDBox mb={2}>
                  <Autocomplete
                    fullWidth
                    id="province"
                    open={this.state.provinceOpen}
                    onOpen={() => this.setState({ provinceOpen: true })}
                    onClose={() => this.setState({ provinceOpen: false })}
                    onFocus={() => this.setState({ provinceOpen: true })}
                    value={this.state.province}
                    options={this.state.provinces}
                    onChange={(e, newValue) => {
                      if (newValue) {
                        this.setState({
                          province: newValue,
                          district: null,
                          error: { ...this.state.error, province: false },
                          success: { ...this.state.success, province: true },
                        });
                        this.loadDistrict(newValue.id);
                      }
                    }}
                    sx={{
                      ".MuiAutocomplete-input": {
                        padding: "7.5px 5px 7.5px 8px !important",
                      },
                      ".MuiOutlinedInput-root": {
                        padding: "4px !important",
                      },
                    }}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    renderInput={(params) => <MDInput label="Pilih Provinsi" {...params} />}
                  />
                </MDBox>
                {/* District */}
                <MDBox mb={2}>
                  <Autocomplete
                    fullWidth
                    id="district"
                    open={this.state.districtOpen}
                    onOpen={() => this.setState({ districtOpen: true })}
                    onClose={() => this.setState({ districtOpen: false })}
                    onFocus={() => this.setState({ districtOpen: true })}
                    value={this.state.district}
                    options={this.state.districts}
                    onChange={(e, value) => {
                      if (value) {
                        this.setState({
                          district: value,
                          error: { ...this.state.error, district: false },
                          success: { ...this.state.success, district: true },
                        });
                      }
                    }}
                    sx={{
                      ".MuiAutocomplete-input": {
                        padding: "7.5px 5px 7.5px 8px !important",
                      },
                      ".MuiOutlinedInput-root": {
                        padding: "4px !important",
                      },
                    }}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    renderInput={(params) => <MDInput label="Pilih Kabupaten/Kota" {...params} />}
                  />
                </MDBox>

                <MDBox pt={3} display="flex" justifyContent="space-between">
                  <MDButton
                    variant="gradient"
                    color="error"
                    component={Link}
                    to={{ pathname: "/manage/branchoffice" }}
                  >
                    KEMBALI
                  </MDButton>
                  <MDButton
                    type="button"
                    variant="gradient"
                    color="info"
                    disabled={this.state.disabledSubmit}
                    onClick={this.handleSubmit}
                  >
                    SUBMIT
                  </MDButton>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
      </MiniFormCard>
    );
  }
}

export default withRouter(FormBranchOffice);
