/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from "prop-types"
import { Link, Navigate } from 'react-router-dom';
// @mui material components
import Container from '@mui/material/Container';
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Paper from "@mui/material/Paper";
import Autocomplete from "@mui/material/Autocomplete";

// @mui icons
import { Close } from '@mui/icons-material';

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from 'components/MDInput';
import MDTypography from "components/MDTypography";

import ModalNotif from 'contents/Components/ModalNotif';
import DialogForm from "contents/Components/DialogForm"

import Config from 'config'
import _ from "lodash"

import useAxios from "libs/useAxios"
import secureStorage from 'libs/secureStorage';

class ProductHotel extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			user: props.user,

			name: "",
			location: "",
			rating: "",
			duration: "",

			error: [],
			success: [],

			disabledSubmit: false,
		}

		this.inputRef = React.createRef()
		this.modalNotifRef = React.createRef()
		this.dialogFormRef = React.createRef()
	}

	componentDidMount() {
		this.inputRef.current && this.inputRef.current.focus()
	}

	setShow = (data) => {
		this.dialogFormRef.current.setShow({ ...data, title: 'Tambah Hotel' })
	}

	handleSave = () => {

		if (this.state.name && this.state.location) {
			this.props.onAction({
				name: this.state.name,
				location: this.state.location,
				rating: this.state.rating,
				duration: this.state.duration
			})
			this.setState({
				duration: "",
				location: "",
				name: "",
				rating: "",
			}, () => {
				this.dialogFormRef.current.setShow({ show: false })
			})
		}
		else {
			this.modalNotifRef.current.setShow({
				modalTitle: "Gagal",
				modalMessage: "Data form masih belum lengkap, Silahkan di cek kembali !",
			})
		}
	}

	render() {
		return (
			<DialogForm ref={this.dialogFormRef} maxWidth="sm">
				<ModalNotif ref={this.modalNotifRef} />

				<Grid container item xs={12} lg={12} sx={{ mx: "auto" }}>
					<MDBox width="100%" component="form" mt={2}>
						<MDBox mb={2}>
							<MDInput
								id="name"
								type="text"
								inputRef={this.inputRef}
								label="Nama Hotel"
								value={this.state.name}
								onChange={(e) => this.setState({ name: e.target.value })}
								fullWidth
							/>
						</MDBox>
						<MDBox mb={2}>
							<MDInput
								id="location"
								type="text"
								label="Lokasi Hotel"
								value={this.state.location}
								onChange={(e) => this.setState({ location: e.target.value })}
								fullWidth
							/>
						</MDBox>
						<MDBox mb={2}>
							<MDInput
								id="rating"
								type="text"
								label="Rating Hotel"
								placeholder="sample : bintang 1"
								value={this.state.rating}
								onChange={(e) => this.setState({ rating: e.target.value })}
								fullWidth
							/>
						</MDBox>
						<MDBox mb={2}>
							<MDBox display="flex" alignItems="center">
								<MDInput
									id="duration"
									type="number"
									label="Durasi"
									value={this.state.duration}
									onChange={(e) => this.setState({ duration: e.target.value })}
									fullWidth
								/>
								<MDBox
									color="dark"
									bgColor="secondary"
									variant="contained"
									borderRadius="md"
									shadow="lg"
									opacity={1}
									px={2}
									py={1}
									ml={1}
								>
									<MDTypography
										variant="caption"
										fontWeight="medium"
										verticalAlign="middle"
										color="white"
									>
										Hari
									</MDTypography>
								</MDBox>
							</MDBox>
						</MDBox>
					</MDBox>

					<MDBox py={3} width="100%" display="flex" justifyContent={{ md: "flex-end", xs: "center" }}>
						<MDBox mr={1}>
							<MDButton
								variant="gradient"
								color="error"
								onClick={() => this.dialogFormRef.current.setShow({ show: false })}
							>
								Tutup
							</MDButton>
						</MDBox>
						<MDButton
							variant="gradient"
							color="info"
							disabled={this.state.disabledSubmit}
							onClick={this.handleSave}
						>
							Submit
						</MDButton>
					</MDBox>
				</Grid>
			</DialogForm>
		)
	}
}

ProductHotel.propTypes = {
	user: PropTypes.object,
	onAction: PropTypes.func.isRequired
}

export default ProductHotel