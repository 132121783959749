import React from "react"
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import Icon from "@mui/material/Icon"

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

import Confirm from "contents/Components/Confirm"
import moment from "moment";

// Material Dashboard 2 PRO React examples
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";

class ListPrincipal extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			rows: props.rows ? props.rows : []
		}

		this.confirmRef = React.createRef()
	}

	setData = (rows) => {
		// console.log({ rows })
		this.setState({ rows })
	}

	render() {
		return (
			<>
				<Confirm ref={this.confirmRef} />
				<TableContainer sx={{ boxShadow: "none" }}>
					<Table>
						<MDBox component="thead">
							<TableRow sx={{ backgroundColor: "#f9fafb" }}>
								<DataTableBodyCell
									width="auto"
									align="left"
								>
									<MDTypography variant="caption" fontWeight="medium" textTransform="uppercase">
										NO
									</MDTypography>
								</DataTableBodyCell>
								<DataTableBodyCell
									width="auto"
									align="left"
								>
									<MDTypography variant="caption" fontWeight="medium" textTransform="uppercase">
										Nama
									</MDTypography>
								</DataTableBodyCell>
								<DataTableBodyCell
									width="auto"
									align="left"
								>
									<MDTypography variant="caption" fontWeight="medium" textTransform="uppercase">
										Tempat / Tgl Lahir
									</MDTypography>
								</DataTableBodyCell>
								<DataTableBodyCell
									width="auto"
									align="center"
								>
									<MDTypography variant="caption" fontWeight="medium" textTransform="uppercase">
										Alamat
									</MDTypography>
								</DataTableBodyCell>
								<DataTableBodyCell
									width="auto"
									align="center"
								>
									<MDTypography variant="caption" fontWeight="medium" textTransform="uppercase">
										Action
									</MDTypography>
								</DataTableBodyCell>
							</TableRow>
						</MDBox>
						<TableBody>
							{this.state.rows.map((item, key) => (
								<TableRow key={key}>
									<DataTableBodyCell>
										<MDTypography variant="body">
											{key + 1}
										</MDTypography>
									</DataTableBodyCell>
									<DataTableBodyCell>
										<MDTypography variant="body">
											{item.principal ? item.principal.name : "-"}
										</MDTypography>
									</DataTableBodyCell>
									<DataTableBodyCell>
										<MDTypography variant="body">
											{item.principal ? item.principal.placeBirth+', '+moment(item.dateBirth).format("DD-MM-YYYY") : "-"}
										</MDTypography>
									</DataTableBodyCell>
									<DataTableBodyCell align="center">
										<MDTypography variant="body" verticalAlign="middle">
											{item.principal ? item.principal.address : '-'}
										</MDTypography>
									</DataTableBodyCell>
									<DataTableBodyCell align="center">
										{this.props.type === "form" && (
											<MDButton
												variant="gradient"
												color="error"
												size="small"
												onClick={() => {
													this.confirmRef.current.setShow({
														title: 'Konfirmasi',
														message: "Apakah anda yang ini menghapus data ini ?",
														onAction: () => {
															this.props.onDelete(key)
														}
													})
												}}
												iconOnly
											>
												<Icon>delete</Icon>
											</MDButton>
										)}
									</DataTableBodyCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</>
		)
	}
}

ListPrincipal.propTypes = {
	type: PropTypes.oneOf(["view", "form"]).isRequired,
	onDelete: PropTypes.func,
	rows: PropTypes.arrayOf(PropTypes.object)
}

export default ListPrincipal