import React from "react";
import { Navigate, Link } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

import DataTable from "contents/Components/DataTable";
import Pagination from "contents/Components/Pagination";
import ButtonAction from "contents/Inventory/ButtonAction";

import useAxios from "libs/useAxios";
import Config from "config";
import secureStorage from "libs/secureStorage";

class Directors extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			merchant: null,
			merchants: [],
			user: null,
			type: "",
			types: ["Dewan Direksi Internal", "Dewan Direksi Eksternal"],

			rowsPerPage: 10,
			currentPage: 1,
			totalPages: 0,
			totalData: 0,
			tableHead: [
				{ Header: "Action", accessor: "action", width: "15%" },
				{ Header: "Nama", accessor: "name", with: "25%" },
				{ Header: "telp", accessor: "phone", width: "15%" },
				{ Header: "jenis kelamin", accessor: "gender", width: "15%" },
				{ Header: "Alamat", accessor: "address", width: "30%" },
				{ Header: "Jabatan", accessor: "position", with: "23%" },
			],
			rows: [],
			keyword: "",
			isLoading: true,
		};
	}

	componentDidMount() {
		const user = secureStorage.getItem("user");
		this.setState({ user }, () => {
			this.loadData();
			user && user.roleId === 1 && this.loadMerchants();
		});
	}

	loadMerchants = () => {
		useAxios()
			.post(`${Config.ApiUrl}/manage/merchant/dropdown`)
			.then((response) => {
				const data = response.data;
				this.setState({
					merchants: data.map((item) => ({ id: item.id, label: item.name })),
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};

	loadData = (params) => {
		this.setState({ isLoading: true });

		const user = secureStorage.getItem("user");
		const type = params && params.type ? { type: params.type } : {};
		const merchantId =
			params && params.merchantId
				? { merchantId: params.merchantId }
				: { merchantId: user.Merchant?.id } ?? {};

		const payload = {
			keyword: params && params.keyword ? params.keyword : this.state.keyword,
			currentPage: params && params.currentPage ? params.currentPage : 1,
			rowsPerPage: params && params.rowsPerPage ? params.rowsPerPage : this.state.rowsPerPage,
			...type,
			...merchantId,
		};

		useAxios()
			.post(`${Config.ApiUrl}/manage/directors`, payload)
			.then((response) => {
				const data = response.data;
				const output = data.output.map((item) => {
					const province = item && item.Province
					const district = item && item.District

					let state = district && district.name ? `, ${district.name.toUpperCase()}, ` : ''
					state += province && province.name ? province.name.toUpperCase() : ''

					return {
						name: (
							<MDTypography variant="caption" fontWeight="regular">
								{item.name ?? "-"}
							</MDTypography>
						),
						gender: (
							<MDTypography variant="caption" fontWeight="regular">
								{item.gender ?? "-"}
							</MDTypography>
						),
						phone: (
							<MDTypography variant="caption" fontWeight="regular">
								{item.phone ?? "-"}
							</MDTypography>
						),
						address: (
							<MDBox style={{ wordWrap: "break-word", width: "20em" }}>
								<MDBox display="flex" flexDirection="column">
									<MDTypography variant="caption" fontWeight="regular">{item.address ?? "-"}</MDTypography>
									<MDTypography mt={1} variant="caption" fontWeight="regular">Provinsi : {province ? province.name : ''}</MDTypography>
									<MDTypography variant="caption" fontWeight="regular">Kabupaten / Kota : {district ? district.name : ''}</MDTypography>
								</MDBox>
							</MDBox>
						),
						position: (
							<MDTypography variant="caption" fontWeight="regular">
								{item.position ?? "-"}
							</MDTypography>
						),
						action:
							user && [2, 3].includes(user.roleId) ? (
								<ButtonAction
									id={item.id}
									isDetail={true}
									urlKey={"/manage/directors"}
									refreshData={this.loadData}
								></ButtonAction>
							) : (
								"-"
							),
					};
				});

				this.setState({
					totalPages: data.totalPages,
					totalData: data.totalData,
					rows: output,
					isLoading: false,
				});
			})
			.catch((err) => {
				console.log(err);
				this.setState({ isLoading: false });
			});
	};

	render() {
		const user = secureStorage.getItem("user");
		return (
			<MDBox pb={3} my={3}>
				<MDBox
					pb={2}
					mt={{ xs: 2, md: 0 }}
					display="flex"
					justifyContent={{ xs: "center", md: "flex-start" }}
				>
					{[2, 3].includes(user.roleId) && (
						<MDButton
							size="medium"
							color="info"
							variant="gradient"
							component={Link}
							to={{ pathname: "/manage/directors/add" }}
						>
							TAMBAH
						</MDButton>
					)}
				</MDBox>
				<Card>
					<MDBox p={2} lineHeight={1}>
						<MDTypography variant="h5" fontWeight="medium">
							Daftar Direksi
						</MDTypography>
					</MDBox>

					<MDBox px={2} width="100%" display="flex" justifyContent="flex-start">
						<Grid container spacing={3}>
							<Grid item xs={12} md={3} lg={3}>
								<MDInput
									label="Search..."
									size="small"
									fullWidth
									value={this.state.keyword}
									onKeyPress={(e) => {
										if (e.key === "Enter") {
											this.loadData({
												currentPage: 1,
												keyword: e.target.value,
												merchantId: this.state.merchant ? this.state.merchant.id : null,
											});
										}
									}}
									onChange={(e) => this.setState({ keyword: e.target.value })}
								/>
							</Grid>
							{/* Merchant */}
							{[1].includes(user.roleId) && (
								<Grid item xs={12} md={3} lg={3}>
									<Autocomplete
										value={this.state.merchant}
										options={this.state.merchants}
										onChange={(e, value) => {
											this.setState({ merchant: value }, () => {
												this.loadData({
													currentPage: 1,
													merchantId: value ? value.id : null,
													keyword: this.state.keyword ? this.state.keyword : null,
												});
											});
										}}
										sx={{
											".MuiAutocomplete-input": {
												padding: "7.5px 5px 7.5px 8px !important",
											},
											".MuiOutlinedInput-root": {
												padding: "1.5px !important",
											},
										}}
										isOptionEqualToValue={(option, value) => option.id === value.id}
										renderInput={(params) => (
											<MDInput
												sx={{ padding: "0px" }}
												fullWidth
												label="Pilih Biro Travel"
												{...params}
											/>
										)}
									/>
								</Grid>
							)}
						</Grid>
					</MDBox>
					<MDBox p={2}>
						<DataTable
							isLoading={this.state.isLoading}
							tableHead={this.state.tableHead}
							tableData={this.state.rows}
						/>
						<Pagination
							totalPages={this.state.totalPages}
							totalData={this.state.totalData}
							currentPage={this.state.currentPage}
							rowsPerPage={[10, 25, 50, "All"]}
							totalButton={3}
							defaultRowsPerPage={this.state.rowsPerPage}
							onChangeRowsPerPage={(value) => {
								this.setState({ rowsPerPage: value, currentPage: 1 });
								this.loadData({
									rowsPerPage: value,
									currentPage: 1,
									keyword: this.state.keyword ? this.state.keyword : null,
									merchantId: this.state.merchant ? this.state.merchant.id : null,
								});
							}}
							onChangePage={(currentPage) => {
								if (currentPage !== this.state.currentPage) {
									this.setState({ currentPage });
									this.loadData({
										currentPage,
										keyword: this.state.keyword ? this.state.keyword : null,
										merchantId: this.state.merchant ? this.state.merchant.id : null,
									});
								}
							}}
						/>
					</MDBox>
				</Card>
			</MDBox>
		);
	}
}

export default Directors;
