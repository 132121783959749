/* eslint-disable react/jsx-key */
/* eslint-disable no-undef */
import React from "react";
import { Link, Navigate } from 'react-router-dom';
// @mui material components
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card"

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Material Dashboard 2 PRO React examples
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";

// Anaytics dashboard components
import SalesByCountry from "layouts/dashboards/analytics/components/SalesByCountry";

// Data
import reportsBarChartData from "layouts/dashboards/analytics/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboards/analytics/data/reportsLineChartData";
import gradientLineChartData from "layouts/pages/charts/data/gradientLineChartData"

import ChartSales from "contents/Dashboard/components/ChartSales"
import StatisticsCard from "contents/Dashboard/components/StatisticsCard"
import CTable from "contents/Components/CTable";
import Loader from "contents/Components/Loader";
import PieCharts from "contents/Dashboard/components/PieChart";

import DataTable from 'contents/Components/DataTable'
import Pagination from "contents/Components/Pagination";

import Config from "config"
import useAxios from "libs/useAxios";
import { fnumber } from "libs/currency";
import secureStorage from "libs/secureStorage";

import moment from "moment"
import _, { result } from "lodash"

class DashboardBiro extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			totalAgent: 0,
			totalCustomer: 0,
			totalSales: 0,
			totalCommissionAgent: 0,
			totalIncomeFund: 0,
			totalOutFund: 0,
			totalAdmin: 0,

			year: null,
			datasetSales: {
				labels: [],
				datasets: []
			},
			datasetFinance: {
				labels: [],
				datasets: [],
			},

			historyPayments: [],
			listAgents: []
		}
	}

	componentDidMount() {
		Promise.all([
			this.loadCountAgent(),
			this.loadCustomer(),
			this.loadCountSales(),
			this.loadCountSaleFee(),
			this.loadMutation(),
			this.loadChart(),
			this.loadLastPayment(),
			this.loadLastAgent()
		]).then(results => {
			this.setState({
				totalAgent: results[0],
				totalCustomer: results[1],
				totalSales: results[2],
				totalCommissionAgent: results[3].commission,
				totalAdmin: results[3].revenue,
				totalIncomeFund: results[4]?.incomeFund,
				totalOutFund: results[4]?.outFund,

				datasetSales: results[5] ? results[5] : { labels: [], datasets: [] },
				historyPayments: results[6],
				listAgents: results[7]
			})
		}).catch(err => {
			console.log(err)
		})
	}

	// Load total agent
	loadCountAgent = () => {
		return useAxios()
			.post(`${Config.ApiUrl}/dashboard/stat/agentOrCustomer`, { roleId: 4 })
			.then(response => {
				const data = response.data
				return data.count
			})
			.catch(err => {
				console.log(err)
				return 0
			})
	}

	// load customer by biro
	loadCustomer = () => {
		return useAxios()
			.post(`${Config.ApiUrl}/dashboard/stat/agentOrCustomer`, { roleId: 5 })
			.then(response => {
				const data = response.data
				return data.count
			})
			.catch(err => {
				console.log(err)
				return 0
			})
	}

	// Load transaction package 
	loadCountSales = () => {
		return useAxios()
			.post(`${Config.ApiUrl}/dashboard/stat/sales`)
			.then(response => {
				const data = response.data
				return data.count
			})
			.catch(err => {
				console.log(err)
				return 0
			})
	}

	// Load SaleFee from commission
	loadCountSaleFee = () => {
		return useAxios()
			.post(`${Config.ApiUrl}/dashboard/stat/saleFees`)
			.then(response => {
				const data = response.data;
				return data
			})
			.catch(err => {
				console.log(err)
				return 0
			})
	}

	// load mutation in/out
	loadMutation = () => {
		return useAxios()
			.post(`${Config.ApiUrl}/dashboard/stat/mutation`)
			.then(response => {
				const data = response.data
				return data
			})
			.catch(err => {
				console.log(err)
				return null
			})
	}

	loadChart = () => {
		return useAxios()
			.post(`${Config.ApiUrl}/dashboard/stat/chart`)
			.then(response => {
				const data = response.data
				return data
			})
			.catch(err => {
				console.log(err)
				return null
			})
	}

	loadLastPayment = () => {
		return useAxios()
			.post(`${Config.ApiUrl}/dashboard/list/payment`)
			.then(response => {
				const data = response.data
				return data
			})
			.catch(err => {
				console.log(err)
				return []
			})
	}

	loadLastAgent = () => {
		return useAxios()
			.post(`${Config.ApiUrl}/dashboard/list/agent`)
			.then(response => {
				const data = response.data
				return data
			})
			.catch(err => {
				console.log(err)
				return []
			})
	}

	render() {
		const user = secureStorage.getItem('user')

		return (
			<MDBox py={3}>
				{/* Grid Box */}
				<Grid container spacing={3} mb={5}>
					{/* total agen */}
					<Grid item xs={12} md={3} lg={3}>
						<StatisticsCard
							count={this.state.totalAgent}
							color="dark"
							icon="group"
							title={"Agen"}
							description={"Total agen yang terdaftar"}
						/>
					</Grid>
					{/* total jamaah */}
					<Grid item xs={12} md={3} lg={3}>
						<StatisticsCard
							count={this.state.totalCustomer}
							color="info"
							icon="person"
							title={"Jamaah"}
							description={"Total jamaah yang terdaftar"}
						/>
					</Grid>
					{/* total transaksi */}
					<Grid item xs={12} md={3} lg={3}>
						<StatisticsCard
							count={this.state.totalSales}
							color="error"
							icon="shoppingcart"
							title={"Transaksi"}
							description={"Daftar transaksi tahun ini"}
						/>
					</Grid>
					{/* commision agent */}
					<Grid item xs={12} md={3} lg={3}>
						<StatisticsCard
							count={fnumber(this.state.totalCommissionAgent)}
							color="success"
							icon="paid"
							title={`Komisi Agen`}
							description={`Total Komisi Agen tahun ini`}
						/>
					</Grid>
					{/* total income in biro */}
					<Grid item xs={12} md={4} lg={4}>
						<StatisticsCard
							count={fnumber(this.state.totalIncomeFund)}
							color="success"
							icon="paid"
							title={"Dana Masuk"}
							description={"Total mutasi dana masuk tahun ini"}
						/>
					</Grid>
					{/* total outfund in biro */}
					<Grid item xs={12} md={4} lg={4}>
						<StatisticsCard
							count={fnumber(this.state.totalOutFund)}
							color="error"
							icon="southeast"
							title={"Dana Keluar"}
							description={"Total mutasi dana keluar tahun ini"}
						/>
					</Grid>
					<Grid item xs={12} md={4} lg={4}>
						<StatisticsCard
							count={fnumber(this.state.totalAdmin)}
							color="secondary"
							icon="sell"
							title={"Admin"}
							description={"Total Biaya Admin tahun ini"}
						/>
					</Grid>
				</Grid>

				{/* Chart */}
				<Grid container spacing={3} mb={2}>
					<Grid item xs={12} md={12} lg={8}>
						<MDBox mb={3}>
							<ChartSales
								title={`Statistik Transaksi Tahun ${moment().format('YYYY')}`}
								height={"22.19rem"}
								chart={this.state.datasetSales} />
						</MDBox>
					</Grid>
					<Grid item xs={12} md={12} lg={4}>
						<MDBox mb={2}>
							<PieCharts
								title="Statistik Keuangan Tahunan"
								height="325px"
								description={"Laporan Dana Masuk dan Dana Keluar tahun ini"}
								chart={{
									labels: ["Dana Masuk", "Dana Keluar", "Komisi Agen", "Admin Mabrur"],
									datasets: {
										label: "Statistik Keuangan",
										backgroundColors: ["success", "error", "warning", "info"],
										data: [
											this.state.totalIncomeFund,
											this.state.totalOutFund,
											this.state.totalCommissionAgent,
											this.state.totalAdmin
										],
									},
								}} />
						</MDBox>
					</Grid>
				</Grid>

				{/* DataTable */}
				<Grid container spacing={3} mb={2}>
					<Grid item xs={12} md={12} lg={8}>
						<Card>
							<MDBox
								p={2}
								lineHeight={1}
								display="flex"
								flexDirection={{ md: "row", xs: "column" }}
								justifyContent={{ md: "space-between", xs: "center" }}
								alignItems={{ md: "center", xs: "flex-start" }}
							>
								<MDTypography variant="button" fontWeight="medium">
									History pembayaran terakhir
								</MDTypography>
							</MDBox>
							<MDBox pb={2} px={2}>
								<CTable
									tableHead={[
										{ name: "kode", width: "15%" },
										{ name: "tanggal", width: "15%" },
										{ name: "nama", width: "20%" },
										{ name: "nominal", width: "25%" },
										{ name: "status", width: "20%" },
									]}
									textAlignColumns={["center", "left", "left", "right", "center"]}
									tableData={this.state.historyPayments.map((item, idx) => [
										item.kode,
										item.createdAt,
										item.User ? item.User.name : "",
										fnumber(item.amount),
										<MDBadge
											size="lg"
											variant="contained"
											badgeContent={item.PaymentStatus && item.PaymentStatus ? item.PaymentStatus.name : ""}
											color={item.PaymentStatus.id === 1 ? "warning" : item.PaymentStatus.id === 2 ? "error" : item.PaymentStatus.id === 3 ? "success" : "error"}
										/>,
									])}
								/>
							</MDBox>
						</Card>
					</Grid>
					<Grid item xs={12} md={12} lg={4}>
						<Card>
							<MDBox
								p={2}
								lineHeight={1}
								display="flex"
								flexDirection={{ md: "row", xs: "column" }}
								justifyContent={{ md: "space-between", xs: "center" }}
								alignItems={{ md: "center", xs: "flex-start" }}
							>
								<MDTypography variant="button" fontWeight="medium">
									Daftar history agen yang mengambil paket
								</MDTypography>
							</MDBox>
							<MDBox pb={2} px={2}>
								<CTable
									tableHead={[
										{ name: "kode", width: "15%" },
										{ name: "nama", width: "20%" },
									]}
									textAlignColumns={["center", "left", "left", "left"]}
									tableData={this.state.listAgents.map((item, idx) => [
										item.Agent && item.Agent.kode ? item.Agent.kode : '-',
										item.Agent && item.Agent.name ? item.Agent.name : '-',
									])}
								/>
							</MDBox>
						</Card>
					</Grid>
				</Grid>
			</MDBox>
		)
	}
}

export default DashboardBiro