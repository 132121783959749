/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from "prop-types";
import { Link, Navigate } from 'react-router-dom';
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

// Material Dashboard 2 PRO React examples
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import withRouter from 'contents/Components/withRouter';
import ModalNotif from 'contents/Components/ModalNotif';
import ButtonBack from 'contents/Components/ButtonBack';

import useAxios from "libs/useAxios"
import Config from "config"
import secureStorage from 'libs/secureStorage';
import { TableContainer } from '@mui/material';
import moment from 'moment';

class DetailMember extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			userId: null,
			id: props.params?.id,

			member: null,
			discrict: null,
			province: null,
			country: null,
			passport: null,
			visa: null,
			statusPerson: null,
			ParentUser: null,
			departureStatus: null
		}

		this.modalNotifRef = React.createRef()
	}

	componentDidMount() {
		const view = this.props.view
		const id = this.props.params?.id

		this.setState({ id }, () => {
			const params = view === "member" ? { userId: id } : { billId: id }
			this.loadDetail(params)
		})
	}

	loadDetail = (params) => {
		const view = this.props.view
		const userId = params && params.userId ? { userId: params.userId } : {}
		const billId = params && params.billId ? { billId: params.billId } : {}

		const payload = {
			...userId,
			...billId, // jika component di panggil di menu billing
		}

		useAxios()
			.post(`${Config.ApiUrl}/user/member/detail`, payload)
			.then(response => {
				const data = response.data
				const dataUser = data.User ? data.User : null
				// console.log(data)

				this.setState({
					userId: dataUser.id,
					member: data,
					district: data.District ? data.District : null,
					province: data.Province ? data.Province : null,
					passport: dataUser.Passport ? dataUser.Passport : null,
					visa: dataUser.Visa ? dataUser.Visa : null,
					statusPerson: data.StatusPerson ? data.StatusPerson : null,
					ParentUser: dataUser.ParentUser ? dataUser.ParentUser : null,
					departureStatus: dataUser.DepartureStatus ? dataUser.DepartureStatus : null
				})
			})
			.catch(err => {
				console.log(err)
				this.modalNotifRef.current.setShow({
					modalTitle: 'Gagal',
					modalMessage: "Koneksi jaringan terputus",
				})
			})
	}

	render() {
		const user = secureStorage.getItem('user')

		return (
			<>
				<ModalNotif ref={this.modalNotifRef} />
				<MDBox pb={3} my={3}>
					{/* Button */}
					<MDBox py={1} mb={2} display="flex">
						<MDBox mr={1}>
							<ButtonBack label={"KEMBALI"} />
						</MDBox>
						{user && [2, 3].includes(user.roleId) && (
							<>
								<MDBox mr={1}>
									<MDButton
										variant="gradient"
										color="info"
										component={Link}
										to={{ pathname: `/adm/member/edit/${this.state.userId}` }}
									>
										UBAH DATA
									</MDButton>
								</MDBox>
								<MDBox mr={1}>
									<MDButton
										variant="gradient"
										color="info"
										component={Link}
										to={{ pathname: `/adm/member/visa/${this.state.userId}` }}
									>
										SET NOMOR VISA
									</MDButton>
								</MDBox>
								<MDBox mr={1}>
									<MDButton
										variant="gradient"
										color="info"
										component={Link}
										to={{ pathname: `/adm/member/passport/${this.state.userId}` }}
									>
										UPDATE PASSPORT
									</MDButton>
								</MDBox>
							</>
						)}
					</MDBox>

					<Card sx={{ overflow: "visible" }}>
						<MDBox p={2}>
							<Grid container spacing={3} mb={3}>
								<Grid item xs={12} md={12} lg={12}>
									<MDBox width="100%" display="flex" justifyContent="center">
										<MDTypography variant="h5" textTransform="capitalize" fontWeight="medium">
											DETAIL JAMAAH
										</MDTypography>
									</MDBox>
								</Grid>
							</Grid>

							<Grid container spacing={3} item xs={12} md={12} lg={12} mb={5} pt={2}>
								<Table>
									<TableBody>
										{/* KODE && CreatedAt */}
										<TableRow>
											<DataTableBodyCell noBorder>
												<MDTypography variant="body" fontWeight="medium">
													Kode Pelanggan
												</MDTypography>
											</DataTableBodyCell>
											<DataTableBodyCell noBorder>
												<MDTypography variant="body">
													{this.state.member && this.state.member.kode ? this.state.member.kode : "-"}
												</MDTypography>
											</DataTableBodyCell>
											<DataTableBodyCell noBorder>
												<MDTypography variant="body" fontWeight="medium">
													Tanggal Registrasi
												</MDTypography>
											</DataTableBodyCell>
											<DataTableBodyCell noBorder>
												<MDTypography variant="body">
													{this.state.member && this.state.member.createdAt ? this.state.member.createdAt : "-"}
												</MDTypography>
											</DataTableBodyCell>
										</TableRow>
										{/* NAME & GENDER */}
										<TableRow>
											<DataTableBodyCell noBorder>
												<MDTypography variant="body" fontWeight="medium">
													Nama Pelanggan
												</MDTypography>
											</DataTableBodyCell>
											<DataTableBodyCell noBorder>
												<MDTypography variant="body">
													{this.state.member && this.state.member.name ? this.state.member.name : "-"}
												</MDTypography>
											</DataTableBodyCell>
											<DataTableBodyCell noBorder>
												<MDTypography variant="body" fontWeight="medium">
													Jenis Kelamin
												</MDTypography>
											</DataTableBodyCell>
											<DataTableBodyCell noBorder>
												<MDTypography variant="body">
													{this.state.member && this.state.member.gender ? this.state.member.gender === "Male" ? "Laki-laki" : "Perempuan" : "-"}
												</MDTypography>
											</DataTableBodyCell>
										</TableRow>
										{/* PHONE & EMAIL */}
										<TableRow>
											<DataTableBodyCell noBorder>
												<MDTypography variant="body" fontWeight="medium">
													Email
												</MDTypography>
											</DataTableBodyCell>
											<DataTableBodyCell noBorder>
												<MDTypography variant="body">
													{this.state.member && this.state.member.email ? this.state.member.email : "-"}
												</MDTypography>
											</DataTableBodyCell>
											<DataTableBodyCell noBorder>
												<MDTypography variant="body" fontWeight="medium">
													Nomor Telpon
												</MDTypography>
											</DataTableBodyCell>
											<DataTableBodyCell noBorder>
												<MDTypography variant="body">
													{this.state.member && this.state.member.phone ? this.state.member.phone : "-"}
												</MDTypography>
											</DataTableBodyCell>
										</TableRow>
										{/* Birth Date & Place Date */}
										<TableRow>
											<DataTableBodyCell noBorder>
												<MDTypography variant="body" fontWeight="medium">
													Tempat Lahir
												</MDTypography>
											</DataTableBodyCell>
											<DataTableBodyCell noBorder>
												<MDTypography variant="body">
													{this.state.member && this.state.member.placeBirth ? this.state.member.placeBirth : "-"}
												</MDTypography>
											</DataTableBodyCell>
											<DataTableBodyCell noBorder>
												<MDTypography variant="body" fontWeight="medium">
													Tanggal Lahir
												</MDTypography>
											</DataTableBodyCell>
											<DataTableBodyCell noBorder>
												<MDTypography variant="body">
													{this.state.member && this.state.member.dateBirth ? this.state.member.dateBirth : "-"}
												</MDTypography>
											</DataTableBodyCell>
										</TableRow>
										{/* NIK && KK */}
										<TableRow>
											<DataTableBodyCell noBorder>
												<MDTypography variant="body" fontWeight="medium">
													Nomor KTP
												</MDTypography>
											</DataTableBodyCell>
											<DataTableBodyCell noBorder>
												<MDTypography variant="body">
													{this.state.member && this.state.member.nik ? this.state.member.nik : "-"}
												</MDTypography>
											</DataTableBodyCell>
											<DataTableBodyCell noBorder>
												<MDTypography variant="body" fontWeight="medium">
													Nomor KK
												</MDTypography>
											</DataTableBodyCell>
											<DataTableBodyCell noBorder>
												<MDTypography variant="body">
													{this.state.member && this.state.member.nokk ? this.state.member.nokk : "-"}
												</MDTypography>
											</DataTableBodyCell>
										</TableRow>
										{/* Disctric & Province */}
										<TableRow>
											<DataTableBodyCell noBorder>
												<MDTypography variant="body" fontWeight="medium">
													Kabupaten
												</MDTypography>
											</DataTableBodyCell>
											<DataTableBodyCell noBorder>
												<MDTypography variant="body">
													{this.state.district && this.state.district.name ? this.state.district.name : "-"}
												</MDTypography>
											</DataTableBodyCell>
											<DataTableBodyCell noBorder>
												<MDTypography variant="body" fontWeight="medium">
													Provinsi
												</MDTypography>
											</DataTableBodyCell>
											<DataTableBodyCell noBorder>
												<MDTypography variant="body">
													{this.state.province && this.state.province.name ? this.state.province.name.toUpperCase() : "-"}
												</MDTypography>
											</DataTableBodyCell>
										</TableRow>
										{/* Address & Author */}
										<TableRow>
											<DataTableBodyCell noBorder>
												<MDTypography variant="body" fontWeight="medium">
													Alamat
												</MDTypography>
											</DataTableBodyCell>
											<DataTableBodyCell noBorder>
												<MDTypography variant="body">
													<MDBox style={{ wordWrap: "break-word", width: "20em" }}>
														{this.state.member && this.state.member.address ? this.state.member.address : "-"}
													</MDBox>
												</MDTypography>
											</DataTableBodyCell>
											<DataTableBodyCell noBorder>
												<MDTypography variant="body" fontWeight="medium">
													Author
												</MDTypography>
											</DataTableBodyCell>
											<DataTableBodyCell noBorder>
												<MDTypography variant="body">
													{this.state.ParentUser && this.state.ParentUser.name ? this.state.ParentUser.name : "-"}
												</MDTypography>
											</DataTableBodyCell>
										</TableRow>
										{/* Departure Status */}
										<TableRow>
											<DataTableBodyCell noBorder>
												<MDTypography variant="body" fontWeight="medium">
													Status Keberangkatan
												</MDTypography>
											</DataTableBodyCell>
											<DataTableBodyCell noBorder>
												<MDTypography variant="body">
													{this.state.departureStatus && this.state.departureStatus.name ? this.state.departureStatus.name : '-'}
												</MDTypography>
											</DataTableBodyCell>
										</TableRow>
										{/* Passport */}
										<TableRow>
											<DataTableBodyCell noBorder>
												<MDTypography variant="body" fontWeight="medium">
													Paspor
												</MDTypography>
											</DataTableBodyCell>
											<TableCell colSpan={3} sx={{ borderBottom: "none" }}>
												{this.renderItem(this.state.passport, 'passport')}
											</TableCell>
										</TableRow>
										{/* Visa */}
										<TableRow>
											<DataTableBodyCell noBorder>
												<MDTypography variant="body" fontWeight="medium">
													Visa
												</MDTypography>
											</DataTableBodyCell>
											<TableCell colSpan={3} sx={{ borderBottom: "none" }}>
												{this.renderItem(this.state.visa, 'visa')}
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</Grid>
						</MDBox>
					</Card>
				</MDBox>
			</>
		)
	}

	renderItem = (rows, type) => {
		return (
			<TableContainer sx={{ boxShadow: "none" }}>
				<Table>
					<MDBox component="thead">
						<TableRow sx={{ backgroundColor: "#f9fafb" }}>
							<DataTableBodyCell
								width="auto"
								align="left"
							>
								<MDTypography variant="caption" fontWeight="medium" textTransform="uppercase">
									Nomor
								</MDTypography>
							</DataTableBodyCell>
							{type === "passport" && (
								<>
									<DataTableBodyCell
										width="auto"
										align="left"
									>
										<MDTypography variant="caption" fontWeight="medium" textTransform="uppercase">
											Tempat Tebit
										</MDTypography>
									</DataTableBodyCell>
									<DataTableBodyCell
										width="auto"
										align="left"
									>
										<MDTypography variant="caption" fontWeight="medium" textTransform="uppercase">
											Tanggal Pembuatan
										</MDTypography>
									</DataTableBodyCell>
								</>
							)}
							<DataTableBodyCell
								width="auto"
								align="left"
							>
								<MDTypography variant="caption" fontWeight="medium" textTransform="uppercase">
									Tanggal Terbit
								</MDTypography>
							</DataTableBodyCell>
							<DataTableBodyCell
								width="auto"
								align="left"
							>
								<MDTypography variant="caption" fontWeight="medium" textTransform="uppercase">
									Tanggal Kadaluarsa
								</MDTypography>
							</DataTableBodyCell>
						</TableRow>
					</MDBox>
					<TableBody>
						<TableRow>
							<DataTableBodyCell>
								<MDTypography variant="body">
									{rows && rows.number ? rows.number : "-"}
								</MDTypography>
							</DataTableBodyCell>
							{type === "passport" && (
								<>
									<DataTableBodyCell>
										<MDTypography variant="body">
											{rows && rows.passportPlace ? rows.passportPlace : "-"}
										</MDTypography>
									</DataTableBodyCell>
									<DataTableBodyCell>
										<MDTypography variant="body">
											{rows && rows.passportDate ? moment(rows.passportDate).format("YYYY-MM-DD") : "-"}
										</MDTypography>
									</DataTableBodyCell>
								</>
							)}
							<DataTableBodyCell>
								<MDTypography variant="body">
									{rows && rows.effectiveDate ? moment(rows.effectiveDate).format("YYYY-MM-DD") : "-"}
								</MDTypography>
							</DataTableBodyCell>
							<DataTableBodyCell>
								<MDTypography variant="body">
									{rows && rows.expiredDate ? moment(rows.expiredDate).format("YYYY-MM-DD") : "-"}
								</MDTypography>
							</DataTableBodyCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
		)
	}
}

DetailMember.defaultProps = {
	view: "member"
}

DetailMember.propTypes = {
	view: PropTypes.oneOf(['member', 'bill']).isRequired
}

export default withRouter(DetailMember)