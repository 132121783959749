import React, { useState } from "react";
import PropTypes from "prop-types";
import { Navigate, Link } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import DataTable from 'contents/Components/DataTable'
import Pagination from "contents/Components/Pagination";
import ButtonAction from "contents/Master/Region/District/components/ButtonAction"

import useAxios from "libs/useAxios"
import Config from 'config'
import secureStorage from "libs/secureStorage";

class District extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			merchant: null,
			country: null,
			province: null,

			countries: [],
			provinces: [],

			rowsPerPage: 10,
			currentPage: 1,
			totalPages: 0,
			totalData: 0,
			tableHead: [
				{ Header: "action", accessor: "action", width: "10%" },
				{ Header: "no", accessor: "no", width: "15%" },
				{ Header: "Nama", accessor: "name", width: "25%" },
				{ Header: "Provinsi", accessor: "province", width: "20%" },
				{ Header: "Negara", accessor: "country", width: "20%" },
			],
			rows: [],
			keyword: "",
			isLoading: true,
		}

		this.loadData = this.loadData.bind(this)
	}

	componentDidMount() {
		const user = secureStorage.getItem('user')
		this.setState({
			merchant: user?.Merchant,
		}, () => {
			this.loadData()
			this.loadCountry()
		})
	}

	loadCountry = () => {
		useAxios()
			.post(`${Config.ApiUrl}/master/country/district/dropdown`)
			.then(response => {
				const data = response.data
				this.setState({
					countries: data.map(item => ({ id: item.id, label: item.name }))
				})
			})
			.catch(err => {
				console.log(err)
			})
	}

	loadProvinces = (params) => {
		const countryId = params && params.countryId ? { countryId: params.countryId } : {}
		const payload = {
			...countryId
		}

		useAxios()
			.post(`${Config.ApiUrl}/master/region/province/dropdown`, payload)
			.then(response => {
				const data = response.data
				this.setState({
					provinces: data.map(item => ({ id: item.id, label: item.name }))
				})
			})
			.catch(err => {
				console.log(err)
			})
	}

	loadData(params) {
		this.setState({ isLoading: true })
		const user = secureStorage.getItem('user')
		const countryId = params && params.countryId ? { countryId: params.countryId } : {}
		const provinceId = params && params.provinceId ? { provinceId: params.provinceId } : {}

		const payload = {
			keyword: params && params.keyword ? params.keyword : this.state.keyword,
			currentPage: params && params.currentPage ? params.currentPage : 1,
			rowsPerPage: params && params.rowsPerPage ? params.rowsPerPage : this.state.rowsPerPage,
			...countryId,
			...provinceId
		};

		useAxios()
			.post(`${Config.ApiUrl}/master/region/district`, payload)
			.then(response => {
				const data = response.data
				let no = 0
				const output = data.output.map(item => {
					const country = item.Province && item.Province.Country ? item.Province.Country : null
					no++
					return {
						no,
						name: item.name,
						province: item.Province?.name,
						country: country?.name,
						action: [1].includes(user.roleId) ? (
							<ButtonAction id={item.id} refreshData={this.loadData} />
						) : '-'
					}
				})

				this.setState({
					totalPages: data.totalPages,
					totalData: data.totalData,
					rows: output,
					isLoading: false
				})
			})
			.catch(err => {
				console.log(err)
				this.setState({ isLoading: false })
			})
	}

	render() {
		const user = secureStorage.getItem('user')

		return (
			<MDBox pb={3} my={3}>
				<MDBox pb={2} mt={{ xs: 2, md: 0 }} display="flex" justifyContent={{ xs: "center", md: "flex-start" }}>
					{[1].includes(user.roleId) && (
						<MDButton
							size="medium"
							color="info"
							variant="gradient"
							component={Link}
							to={{ pathname: "/master/region/district/add" }}
						>
							TAMBAH
						</MDButton>
					)}
				</MDBox>
				<Card>
					<MDBox p={2} lineHeight={1}>
						<MDTypography variant="h5" fontWeight="medium">
							Daftar Kabupaten
						</MDTypography>
					</MDBox>

					<MDBox px={2} width="100%" display="flex" justifyContent="flex-start">
						<Grid container spacing={3}>
							<Grid item xs={12} md={3} lg={3}>
								<MDInput
									label="Search..."
									size="small"
									fullWidth
									value={this.state.keyword}
									onKeyPress={(e) => {
										if (e.key === "Enter") {
											this.loadData({
												keyword: e.target.value,
												currentPage: 1,
												countryId: this.state.country ? this.state.country.id : null,
												provinceId: this.state.province ? this.state.province.id : null
											})
										}
									}}
									onChange={(e) => this.setState({ keyword: e.target.value })}
								/>
							</Grid>
							<Grid item xs={12} md={3} lg={3}>
								<Autocomplete
									value={this.state.country}
									options={this.state.countries}
									onChange={(e, value) => {
										this.setState({ country: value, province: null, provinces: [] }, () => {
											this.loadProvinces({ countryId: value?.id })
											this.loadData({
												currentPage: 1,
												countryId: value ? value.id : null
											})
										})
									}}
									sx={{
										".MuiAutocomplete-input": {
											padding: "7.5px 5px 7.5px 8px !important"
										},
										".MuiOutlinedInput-root": {
											padding: "1.5px !important"
										}
									}}
									isOptionEqualToValue={(option, value) => option.id === value.id}
									renderInput={(params) => <MDInput sx={{ padding: "0px" }} fullWidth label="Pilih Nagara" {...params} />}
								/>
							</Grid>
							<Grid item xs={12} md={3} lg={3}>
								<Autocomplete
									value={this.state.province}
									options={this.state.provinces}
									onChange={(e, value) => {
										this.setState({ province: value }, () => {
											this.loadData({
												currentPage: 1,
												provinceId: value ? value.id : null,
												countryId: this.state.country ? this.state.country.id : null,
											})
										})
									}}
									sx={{
										".MuiAutocomplete-input": {
											padding: "7.5px 5px 7.5px 8px !important"
										},
										".MuiOutlinedInput-root": {
											padding: "1.5px !important"
										}
									}}
									isOptionEqualToValue={(option, value) => option.id === value.id}
									renderInput={(params) => <MDInput sx={{ padding: "0px" }} fullWidth label="Pilih Provinsi" {...params} />}
								/>
							</Grid>
						</Grid>
					</MDBox>

					<MDBox p={2}>
						<DataTable
							isLoading={this.state.isLoading}
							tableHead={this.state.tableHead}
							tableData={this.state.rows}
						/>
						<Pagination
							totalPages={this.state.totalPages}
							totalData={this.state.totalData}
							currentPage={this.state.currentPage}
							rowsPerPage={[10, 25, 50, "All"]}
							totalButton={3}
							defaultRowsPerPage={this.state.rowsPerPage}
							onChangeRowsPerPage={(value) => {
								this.setState({ rowsPerPage: value, currentPage: 1 });
								this.loadData({
									rowsPerPage: value,
									currentPage: 1,
									countryId: this.state.country ? this.state.country.id : null,
									provinceId: this.state.province ? this.state.province.id : null
								});
							}}
							onChangePage={(currentPage) => {
								if (currentPage !== this.state.currentPage) {
									this.setState({ currentPage });
									this.loadData({
										currentPage,
										countryId: this.state.country ? this.state.country.id : null,
										provinceId: this.state.province ? this.state.province.id : null
									});
								}
							}}
						/>
					</MDBox>

				</Card>
			</MDBox>
		)
	}
}

export default District