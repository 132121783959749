/* eslint-disable react/prop-types */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Navigate, Link } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import DataTable from 'contents/Components/DataTable'
import Pagination from "contents/Components/Pagination";
import ButtonAction from "contents/Administrasi/Document/components/ButtonAction"
import DialogStatus from "contents/Administrasi/Document/components/DialogStatus"
import ModalNotif from 'contents/Components/ModalNotif';
import ButtonBack from 'contents/Components/ButtonBack';

import useAxios from "libs/useAxios"
import Config from 'config'
import secureStorage from "libs/secureStorage";
import { fnumber } from "libs/currency";
import withRouter from "contents/Components/withRouter";

class Document extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			product: null,

			products: [],
			id: null,

			rowsPerPage: 10,
			currentPage: 1,
			totalPages: 0,
			totalData: 0,
			tableHead: [
				{ Header: "action", accessor: "action", width: "10%" },
				{ Header: "nama", accessor: "name", width: "15%" },
				{ Header: "alamat", accessor: "address", width: "15%" },
				{ Header: "ktp", accessor: "ktp", width: "15%" },
				{ Header: "kk", accessor: "kk", width: "15%" },
				{ Header: "passport", accessor: "passport", width: "15%" },
				{ Header: "foto 3x4 6lbr", accessor: "foto", width: "15%" },
				{ Header: "visa", accessor: "visa", width: "15%" },
				{ Header: "catatan", accessor: "remark", width: "20%" },
			],
			rows: [],
			keyword: "",
			isLoading: true,
		}

		this.dialogStatusRef = React.createRef()
		this.modalNotifRef = React.createRef()

	}

	componentDidMount() {
		const user = secureStorage.getItem('user')
		const id = this.props.params?.id

		this.loadProduct()
		this.setState({ id }, () => {
			this.loadData()
		})
	}

	loadProduct = () => {
		useAxios()
			.post(`${Config.ApiUrl}/product/dropdown`)
			.then(response => {
				const data = response.data

				this.setState({
					products: data.map(item => ({ id: item.id, label: item.name }))
				})
			})
			.catch(err => {
				console.log(err)
			})
	}

	loadData = (params) => {
		const view = this.props.view
		const user = secureStorage.getItem("user")
		this.setState({ isLoading: true })

		// jika component di panggil di component lain
		const billId = view === "bill" ? { billId: this.state.id } : {}

		const productId = params && params.productId ? { productId: params.productId } : {}
		const payload = {
			keyword: params && params.keyword ? params.keyword : this.state.keyword,
			currentPage: params && params.currentPage ? params.currentPage : 1,
			rowsPerPage: params && params.rowsPerPage ? params.rowsPerPage : this.state.rowsPerPage,
			...productId,
			...billId,
		}

		useAxios()
			.post(`${Config.ApiUrl}/user/doc/list`, payload)
			.then(response => {
				const data = response.data
				const iconCheck = (
					<MDButton
						variant="gradient"
						color="success"
						size="small"
						iconOnly
					>
						<Icon fontSize="medium">check</Icon>
					</MDButton>
				)

				const iconCross = (
					<MDButton
						variant="gradient"
						color="error"
						size="small"
						iconOnly
					>
						<Icon fontSize="medium">close</Icon>
					</MDButton>
				)
				const output = data.output.map(item => {

					const document = item.Document
					return {
						name: item.name,
						address: (
							<p style={{ wordWrap: "break-word", width: "20em" }}>
								<MDTypography variant="caption" fontWeight="regular">
									{item.address}
								</MDTypography>
							</p>
						),
						ktp: document?.ktp ? iconCheck : iconCross,
						kk: document?.kk ? iconCheck : iconCross,
						passport: document?.passport ? iconCheck : iconCross,
						foto: document?.photo ? iconCheck : iconCross,
						visa: document?.visa ? iconCheck : iconCross,
						remark: document?.remark ? document?.remark : "",
						action: (
							<ButtonAction
								item={item}
								isCompleted={item.isCompleted}
								refreshData={this.loadData}
							/>
						)
					}
				})

				this.setState({
					totalPages: data.totalPages,
					totalData: data.totalData,
					rows: output,
					isLoading: false
				})
			})
			.catch(err => {
				console.log(err)
				this.setState({ isLoading: false })
			})
	}

	render() {
		const user = secureStorage.getItem('user')

		return (
			<>
				<ModalNotif ref={this.modalNotifRef} />
				{this.props.view === "bill" && [2, 3, 4].includes(user.roleId) && (
					<MDBox py={1} mb={2} display="flex">
						<MDBox mr={1}>
							<ButtonBack label={"KEMBALI"} />
						</MDBox>
					</MDBox>
				)}
				<MDBox pb={3} my={3}>
					<Card>
						<MDBox p={2} lineHeight={1}>
							<MDTypography variant="h5" fontWeight="medium">
								Dokumen Kelengkapan Jamaah
							</MDTypography>
						</MDBox>
						<MDBox px={2} width="100%" display="flex" justifyContent="flex-start">
							<Grid container spacing={3}>
								<Grid item xs={12} md={3} lg={3}>
									<MDInput
										label="Search..."
										size="small"
										fullWidth
										value={this.state.keyword}
										onKeyPress={(e) => {
											if (e.key === "Enter") {
												this.loadData({
													currentPage: 1,
													keyword: e.target.value,
													productId: this.state.product ? this.state.product.id : null,
												})
											}
										}}
										onChange={(e) => this.setState({ keyword: e.target.value })}
									/>
								</Grid>
								{/* Product */}
								<Grid item xs={12} md={3} lg={3}>
									<Autocomplete
										value={this.state.product}
										options={this.state.products}
										onChange={(e, value) => {
											this.setState({ product: value })
											this.loadData({
												currentPage: 1,
												productId: value ? value.id : null
											})
										}}
										sx={{
											".MuiAutocomplete-input": {
												padding: "7.5px 5px 7.5px 8px !important"
											},
											".MuiOutlinedInput-root": {
												padding: "1.5px !important"
											}
										}}
										isOptionEqualToValue={(option, value) => option.id === value.id}
										renderInput={(params) => <MDInput sx={{ padding: "0px" }} fullWidth label="Pilih Paket" {...params} />}
									/>
								</Grid>
							</Grid>
						</MDBox>
						<MDBox p={2}>
							<DataTable
								isLoading={this.state.isLoading}
								tableHead={this.state.tableHead}
								tableData={this.state.rows}
							/>
							<Pagination
								totalPages={this.state.totalPages}
								totalData={this.state.totalData}
								currentPage={this.state.currentPage}
								rowsPerPage={[10, 25, 50, "All"]}
								totalButton={3}
								defaultRowsPerPage={this.state.rowsPerPage}
								onChangeRowsPerPage={(value) => {
									this.setState({ rowsPerPage: value, currentPage: 1 })
									this.loadData({
										rowsPerPage: value,
										currentPage: 1,
										productId: this.state.product ? this.state.product.id : null
									})
								}}
								onChangePage={(currentPage) => {
									if (currentPage !== this.state.currentPage) {
										this.setState({ currentPage })
										this.loadData({
											currentPage,
											productId: this.state.product ? this.state.product.id : null
										})
									}
								}}
							/>
						</MDBox>
					</Card>
				</MDBox>
			</>
		)
	}
}

Document.defaultProps = {
	title: "Dokumen Kelengkapan Jamaah",
	view: "document"
}

Document.propTypes = {
	title: PropTypes.string,
	view: PropTypes.oneOf(['document', 'bill'])
}


export default withRouter(Document)