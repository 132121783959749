/* eslint-disable react/jsx-key */
/* eslint-disable no-undef */
import React from "react";
import { Link, Navigate } from 'react-router-dom';
// @mui material components
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card"

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Material Dashboard 2 PRO React examples
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";

// Anaytics dashboard components
import SalesByCountry from "layouts/dashboards/analytics/components/SalesByCountry";

// Data
import reportsBarChartData from "layouts/dashboards/analytics/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboards/analytics/data/reportsLineChartData";
import gradientLineChartData from "layouts/pages/charts/data/gradientLineChartData"

import ChartSales from "contents/Dashboard/components/ChartSales"
import StatisticsCard from "contents/Dashboard/components/StatisticsCard"
import CTable from "contents/Components/CTable";
import Loader from "contents/Components/Loader";
import PieCharts from "contents/Dashboard/components/PieChart";

import DataTable from 'contents/Components/DataTable'
import Pagination from "contents/Components/Pagination";

import Config from "config"
import useAxios from "libs/useAxios";
import { fnumber } from "libs/currency";
import secureStorage from "libs/secureStorage";

import moment from "moment"
import _ from "lodash"

class DashboardOwner extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			totalTravel: 0,
			totalAgent: 0,
			totalSales: 0,
			totalAdmin: 0,

			year: null,
			datasetSales: {
				labels: [],
				datasets: []
			},

			isLoadingTravel: true,
			rowsPerPage: 10,
			currentPage: 1,
			totalPages: 0,
			totalData: 0,
			salesTravel: [],

			isLoadingAgent: true,
			rowsPerPage2: 10,
			currentPage2: 1,
			totalPages2: 0,
			totalData2: 0,
			commissions: [],
		}
	}

	componentDidMount() {
		Promise.all([
			this.loadCountTravel(),
			this.loadCountAgent(),
			this.loadCountSales(),
			this.loadCountSaleFee(),
			this.loadChartSales(),
			this.loadTrxByBiro(),
			this.loadCommissions()
		]).then(results => {
			this.setState({
				totalTravel: results[0],
				totalAgent: results[1],
				totalSales: results[2],
				totalAdmin: results[3].revenue,

				datasetSales: results[4] ? results[4] : { labels: [], datasets: [] },
			})
		}).catch(err => {
			console.log(err)
		})
	}

	// Load total biro travel
	loadCountTravel = () => {
		return useAxios()
			.post(`${Config.ApiUrl}/dashboard/stat/travel`)
			.then(response => {
				const data = response.data
				return data.count
			})
			.catch(err => {
				console.log(err)
				return 0
			})
	}

	// Load total agent
	loadCountAgent = () => {
		return useAxios()
			.post(`${Config.ApiUrl}/dashboard/stat/agentOrCustomer`, { roleId: 4 })
			.then(response => {
				const data = response.data
				return data.count
			})
			.catch(err => {
				console.log(err)
				return 0
			})
	}

	// Load transaction package 
	loadCountSales = () => {
		return useAxios()
			.post(`${Config.ApiUrl}/dashboard/stat/sales`)
			.then(response => {
				const data = response.data
				return data.count
			})
			.catch(err => {
				console.log(err)
				return 0
			})
	}

	// Load SaleFee 
	loadCountSaleFee = () => {
		return useAxios()
			.post(`${Config.ApiUrl}/dashboard/stat/saleFees`)
			.then(response => {
				const data = response.data;
				return data
			})
			.catch(err => {
				console.log(err)
				return 0
			})
	}

	// Load Chart
	loadChartSales = () => {
		return useAxios()
			.post(`${Config.ApiUrl}/dashboard/stat/chart`, { year: this.state.year })
			.then(response => {
				const data = response.data
				return data
			})
			.catch(err => {
				console.log(err)
				return null
			})
	}

	// Load total trx by biro
	loadTrxByBiro = (params) => {
		this.setState({ isLoadingTravel: true })
		const payload = {
			currentPage: params && params.currentPage ? params.currentPage : 1,
			rowsPerPage: params && params.rowsPerPage ? params.rowsPerPage : this.state.rowsPerPage,
		}

		return useAxios()
			.post(`${Config.ApiUrl}/dashboard/list/trxTravel`, payload)
			.then(response => {
				const data = response.data
				const output = data.output.map(item => {
					return {
						kode: item.kode,
						name: item.name,
						trx: item.trx
					}
				})

				this.setState({
					totalPages: data.totalPages,
					totalData: data.totalData,
					salesTravel: output,
					isLoadingTravel: false
				})
				return
			})
			.catch(err => {
				console.log(err)
				this.setState({
					isLoadingTravel: false,
				})
				return
			})
	}

	// load commision by agent
	loadCommissions = (params) => {
		this.setState({ isLoadingAgent: true })
		const payload = {
			currentPage: params && params.currentPage ? params.currentPage : 1,
			rowsPerPage: params && params.rowsPerPage ? params.rowsPerPage : this.state.rowsPerPage2,
		}

		return useAxios()
			.post(`${Config.ApiUrl}/dashboard/list/commisionAgent`, payload)
			.then(response => {
				const data = response.data
				const output = data.output.map(item => {
					return {
						code: item.code,
						name: item.name,
						nominal: fnumber(item.amount)
					}
				})

				this.setState({
					totalPages2: data.totalPages,
					totalData2: data.totalData,
					commissions: output,
					isLoadingAgent: false
				})
				return
			})
			.catch(err => {
				console.log(err)
				this.setState({
					isLoadingAgent: false,
				})
				return
			})
	}

	render() {
		return (
			<MDBox py={3}>
				<Grid container spacing={3} mb={5}>
					{/* Count Biro Travel */}
					<Grid item xs={12} md={3} lg={3}>
						<StatisticsCard
							count={this.state.totalTravel}
							color="info"
							icon="group"
							title={"Biro Travel"}
							description={"Jumlah biro travel"}
						/>
					</Grid>
					{/* Count Agent */}
					<Grid item xs={12} md={3} lg={3}>
						<StatisticsCard
							count={this.state.totalAgent}
							color="dark"
							icon="group"
							title={"Agen"}
							description={"Jumlah agen yang terdaftar"}
						/>
					</Grid>
					{/* Count all transaksction */}
					<Grid item xs={12} md={3} lg={3}>
						<StatisticsCard
							count={this.state.totalSales}
							color="error"
							icon="shoppingcart"
							title={"Transaksi"}
							description={"Jumlah semua transaksi"}
						/>
					</Grid>
					{/* Total commision  */}
					<Grid item xs={12} md={3} lg={3}>
						<StatisticsCard
							count={fnumber(this.state.totalAdmin)}
							color="success"
							icon="paid"
							title={`Komisi`}
							description={`Total semua komisi`}
						/>
					</Grid>
				</Grid>

				{/* Carts Statistik */}
				<Grid container spacing={3} mb={2}>
					{/* Month */}
					<Grid item xs={12} md={12} lg={12}>
						<MDBox mb={3}>
							<ChartSales
								title={'Statistik Transaksi Bulanan dan Tahunan'}
								height={"18.19rem"}
								chart={this.state.datasetSales} />
						</MDBox>
					</Grid>
				</Grid>

				<Grid container spacing={3} mb={2}>
					{/* Menampilkan Total Transaksi dari tiap Biro Travel */}
					<Grid item xs={12} md={12} lg={6}>
						<Card>
							<MDBox
								p={2}
								lineHeight={1}
								display="flex"
								flexDirection={{ md: "row", xs: "column" }}
								justifyContent={{ md: "space-between", xs: "center" }}
								alignItems={{ md: "center", xs: "flex-start" }}
							>
								<MDTypography variant="button" fontWeight="medium">
									Menampilkan Total Transaksi dari tiap Biro Travel
								</MDTypography>
							</MDBox>

							<MDBox p={2}>
								<DataTable
									isLoading={this.state.isLoadingTravel}
									tableHead={[
										{ Header: "no reg", accessor: "kode", width: "20%" },
										{ Header: "biro", accessor: "name", width: "30%" },
										{ Header: "transaksi", accessor: "trx", width: "30%" },
									]}
									tableData={this.state.salesTravel}
								/>
								<Pagination
									totalPages={this.state.totalPages}
									totalData={this.state.totalData}
									currentPage={this.state.currentPage}
									rowsPerPage={[10, 25, 50, "All"]}
									totalButton={3}
									defaultRowsPerPage={this.state.rowsPerPage}
									onChangeRowsPerPage={(value) => {
										this.setState({ rowsPerPage: value, currentPage2: 1 });
										this.loadTrxByBiro({
											rowsPerPage: value,
											currentPage: 1,
										})
									}}
									onChangePage={(currentPage) => {
										if (currentPage !== this.state.currentPage) {
											this.setState({ currentPage });
											this.loadTrxByBiro({
												currentPage,
											});
										}
									}}
								/>
							</MDBox>
						</Card>
					</Grid>
					{/* Menampilkan Total Komisi dari tiap Agen */}
					<Grid item xs={12} md={12} lg={6}>
						<Card>
							<MDBox
								p={2}
								lineHeight={1}
								display="flex"
								flexDirection={{ md: "row", xs: "column" }}
								justifyContent={{ md: "space-between", xs: "center" }}
								alignItems={{ md: "center", xs: "flex-start" }}
							>
								<MDTypography variant="button" fontWeight="medium">
									Menampilkan Total Komisi dari tiap Agen
								</MDTypography>
							</MDBox>
							<MDBox p={2}>
								<DataTable
									isLoading={this.state.isLoadingAgent}
									tableHead={[
										{ Header: "kode", accessor: "code", width: "20%" },
										{ Header: "nama", accessor: "name", width: "30%" },
										{ Header: "nominal", accessor: "nominal", width: "30%" },
									]}
									tableData={this.state.commissions}
								/>
								<Pagination
									totalPages={this.state.totalPages2}
									totalData={this.state.totalData2}
									currentPage={this.state.currentPage2}
									rowsPerPage={[10, 25, 50, "All"]}
									totalButton={3}
									defaultRowsPerPage={this.state.rowsPerPage2}
									onChangeRowsPerPage={(value) => {
										this.setState({ rowsPerPage2: value, currentPage2: 1 });
										this.loadCommissions({
											rowsPerPage: value,
											currentPage: 1,
										})
									}}
									onChangePage={(currentPage2) => {
										if (currentPage2 !== this.state.currentPage2) {
											this.setState({ currentPage2 });
											this.loadCommissions({
												currentPage: currentPage2,
											});
										}
									}}
								/>
							</MDBox>
						</Card>
					</Grid>
				</Grid>
			</MDBox>
		)
	}
}

export default DashboardOwner