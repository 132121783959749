import { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";

// @mui icons
import { Close } from "@mui/icons-material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import Confirm from "contents/Components/Confirm";
import ModalNotif from "contents/Components/ModalNotif";
import DialogForm from "contents/Components/DialogForm";

import useAxios from "libs/useAxios";
import Config from "config";
import secureStorage from "libs/secureStorage";
import { fnumber } from "libs/currency";
import print from "helper/printer";

function ButtonAction({ id, statusId, refreshData }) {
	const confirmRef = useRef();
	const modalNotifRef = useRef();
	const dialogFormRef = useRef();

	const navigate = useNavigate();
	const [menu, setMenu] = useState(null);
	const [user, setUser] = useState(null);

	useEffect(() => {
		setUser(secureStorage.getItem("user"));
	}, []);

	const openMenu = (event) => setMenu(event.currentTarget)
	const closeMenu = () => setMenu(null);

	const handleDetail = () => {
		closeMenu();
		navigate(`/finance/bill/detail/${id}`)
	}

	const handlePayment = () => {
		closeMenu();
		navigate(`/finance/payment/form/${id}`)
	};

	const handlePrintDp = () => {
		closeMenu();
		useAxios()
			.post(`${Config.ApiUrl}/finance/print`, { id, type: 'dp' })
			.then((response) => {
				const data = response.data;
				const merchant = data.Merchant ? data.Merchant : null

				const sale = data.TrSale
				const product = sale.Product ? sale.Product : null;
				const productPrice = sale.ProductPrice ? sale.ProductPrice : null;

				const item = {
					name: product ? product.name : "",
					amount: productPrice && productPrice.amount ? fnumber(productPrice.amount) : 0,
					downPayment:
						productPrice && productPrice.downPayment ? fnumber(productPrice.downPayment) : 0,
				};

				const customer = data.User ? data.User : null
				const payload = {
					title: "Invoice Uang Muka",
					company: merchant && merchant.name ? merchant.name : "",
					email: merchant && merchant.email ? merchant.email : "",
					phone: merchant && merchant.phone ? merchant.phone : "",
					address: merchant && merchant.address ? merchant.address : "",
					invoiceDate: data.createdAt ? data.createdAt : "",
					invoiceCode: data.kode ? data.kode : "",
					item,
					banks: data.Banks ? data.Banks : [],
					customer,
				};

				print(payload, "PdfDp");
			})
			.catch((err) => {
				console.log(err);
				modalNotifRef.current.setShow({
					modalTitle: "Gagal",
					modalMessage: err.response ? err.response.data : "Koneksi jaringan terputus",
				});
			});
	};

	const handlePrintInvoice = (show) => {
		closeMenu();
		useAxios()
			.post(`${Config.ApiUrl}/finance/print`, { id, type: 'invoice' })
			.then((response) => {
				const data = response.data;
				const merchant = data.Merchant ? data.Merchant : null
				// Sale Fee
				const saleFee = data && data.SaleFee ? data.SaleFee : null

				// Customer
				const customer = data.User ? data.User : null
				// TrSale
				const sale = data.TrSale
				const product = sale.Product ? sale.Product : null;
				const productPrice = sale.ProductPrice ? sale.ProductPrice : null;

				const total = parseInt(data.amount) - parseInt(data.paidAmount);

				const commissionAgent = show ? saleFee && saleFee.amount ? fnumber(saleFee.amount) : 0 : null
				const items = [
					{
						name: product ? product.name : "",
						amount: productPrice && productPrice.amount ? fnumber(productPrice.amount) : 0,
					}
				];
				const payload = {
					title: "Invoice",
					company: merchant && merchant.name ? merchant.name : "",
					email: merchant && merchant.email ? merchant.email : "",
					phone: merchant && merchant.phone ? merchant.phone : "",
					address: merchant && merchant.address ? merchant.address : "",
					invoiceDate: data.createdAt ? data.createdAt : "",
					invoiceCode: data.kode ? data.kode : "",
					items,
					banks: data.Banks ? data.Banks : [],
					paidAmount: fnumber(data.paidAmount ? data.paidAmount : 0),
					subtotal: fnumber(data.amount),
					total: fnumber(total),
					customer,
					commissionAgent
				};

				print(payload, "PdfInvoices");
			})
			.catch((err) => {
				console.log(err);
				modalNotifRef.current.setShow({
					modalTitle: "Gagal",
					modalMessage: err.response ? err.response.data : "Koneksi jaringan terputus",
				});
			});
	};

	const handlePrintPayment = () => {
		closeMenu()
		useAxios()
			.post(`${Config.ApiUrl}/finance/print/payment`, { id })
			.then(response => {
				const data = response.data

				const product = data.Product ? data.Product : null
				const customer = data.User ? data.User : null
				const merchant = data.Merchant ? data.Merchant : null
				const billCurrAmount = parseInt(data.amount) - parseInt(data.paidAmount)

				const payload = {
					title: "Daftar Pembayaran Transaksi",
					company: merchant && merchant.name ? merchant.name : "",
					email: merchant && merchant.email ? merchant.email : "",
					phone: merchant && merchant.phone ? merchant.phone : "",
					address: merchant && merchant.address ? merchant.address : "",
					billCode: data.kode ? data.kode : "",
					billDate: data.createdAt ? data.createdAt : "",
					billAmount: fnumber(data.amount),
					billPaidAmount: fnumber(data.paidAmount ? data.paidAmount : 0),
					billCurrAmount: fnumber(billCurrAmount),
					productName: product && product.name ? product.name : "",
					customerName: customer && customer.name ? customer.name : "",
					items: data.Payments ? data.Payments : [],
					banks: data.Banks ? data.Banks : [],
				}

				print(payload, 'PdfPaymentList')
			})
			.catch(err => {
				console.log(err)
				modalNotifRef.current.setShow({
					modalTitle: 'Gagal',
					modalMessage: err.response ? err.response.data : "Koneksi jaringan terputus",
				})
			})
	}

	const renderMenu = (
		<Menu
			anchorEl={menu}
			anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
			transformOrigin={{ vertical: "top", horizontal: "left" }}
			open={Boolean(menu)}
			onClose={closeMenu}
			keepMounted
		>
			<MenuItem onClick={handleDetail}>Detail</MenuItem>
			{[1, 3].includes(statusId) && user && [2, 3, 4, 5].includes(user.roleId) && (
				<MenuItem onClick={handlePayment}>Pembayaran</MenuItem>
			)}
			{user && [2, 3, 4].includes(user.roleId) && (
				<MenuItem onClick={handlePrintDp}>Cetak Uang Muka</MenuItem>
			)}

			{[1, 3, 4, 5].includes(statusId) && user && [2, 3, 4].includes(user.roleId) && (
				<MenuItem onClick={() => handlePrintInvoice(false)}>Cetak Invoice</MenuItem>
			)}
			{[1, 3, 4, 5].includes(statusId) && user && [4].includes(user.roleId) && (
				<MenuItem onClick={() => handlePrintInvoice(true)}>Cetak Invoice Khusus</MenuItem>
			)}

			{user && [2, 3, 4].includes(user.roleId) && (
				<MenuItem onClick={handlePrintPayment}>Cetak Daftar Pembayaran</MenuItem>
			)}
			{/* {user && [2, 3, 4].includes(user.roleId) && [4, 5].includes(statusId) && (
				<MenuItem>Cetak Bukti Pendaftaran</MenuItem>
			)} */}
		</Menu>
	);

	return (
		<MDBox display="flex">
			<Confirm ref={confirmRef} />
			<ModalNotif ref={modalNotifRef} />
			{/* Dialog View Image */}
			<DialogForm ref={dialogFormRef} maxWidth={"sm"}>
				<MDBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
					<MDTypography variant="h5" textTransform="uppercase">
						Bukti Transfer
					</MDTypography>
					<Close
						fontSize="medium"
						sx={{ cursor: "pointer" }}
						onClick={() => dialogFormRef.current.setShow({ show: false })}
					/>
				</MDBox>
			</DialogForm>

			<MDButton variant="contained" color="info" size="small" onClick={openMenu}>
				actions&nbsp;
				<Icon>keyboard_arrow_down</Icon>
			</MDButton>
			{renderMenu}
		</MDBox>
	);
}

ButtonAction.propTypes = {
	id: PropTypes.number.isRequired,
	statusId: PropTypes.number.isRequired,
	refreshData: PropTypes.func
};

export default ButtonAction;
