import { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";

// @mui icons
import { Close } from "@mui/icons-material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

import Config from "config";
import useAxios from "libs/useAxios";
import secureStorage from "libs/secureStorage";

import Confirm from "contents/Components/Confirm";
import ModalNotif from "contents/Components/ModalNotif";
import DialogForm from "contents/Components/DialogForm";
import DialogStatus from "contents/Administrasi/Document/components/DialogStatus";

function ButtonAction({ item, isCompleted, refreshData }) {
	const confirmRef = useRef();
	const modalNotifRef = useRef();
	const dialogStatusRef = useRef();
	const dialogFormRef = useRef();

	const navigate = useNavigate();
	const [menu, setMenu] = useState(null);
	const [user, setUser] = useState(null);
	const [documentComplete, setDocumentComplete] = useState(false);

	useEffect(() => {
		setUser(secureStorage.getItem("user"));
		item.Document?.ktp &&
			item.Document?.kk &&
			item.Document?.passport &&
			item.Document?.photo &&
			item.Document?.visa &&
			setDocumentComplete(true);
	}, []);

	const openMenu = (event) => setMenu(event.currentTarget);
	const closeMenu = () => setMenu(null);

	const handleEdit = () => {
		closeMenu();
		dialogStatusRef.current.setShow({
			memberId: item.id,
			memberName: item.name,
			memberAddress: item.address,
			show: true,
		});
	};

	const handleDocument = () => {
		closeMenu();
		dialogFormRef.current.setShow({ show: true });
	}

	const handleCompleted = () => {
		closeMenu()
		console.log('[DELETE]')
		confirmRef.current.setShow({
			title: 'Konfirmasi',
			message: "Apakah anda yakin dokumen jamaah ini sudah lengkap ?",
			onAction: () => {
				submitCompleted()
			}
		})
	}

	const submitCompleted = () => {
		useAxios()
			.post(`${Config.ApiUrl}/user/doc/isCompleted`, { userId: item.id })
			.then((response) => {
				modalNotifRef.current.setShow({
					modalTitle: "Sukses",
					modalMessage: response.data,
					onClose: () => {
						refreshData();
					},
				});
			})
			.catch((err) => {
				if (err.response) {
					modalNotifRef.current.setShow({
						modalTitle: "Gagal",
						modalMessage: err.response ? err.response.data : "Server Internal Error",
					});
				} else {
					modalNotifRef.current.setShow({
						modalTitle: "Gagal",
						modalMessage: "Koneksi jaringan terputus",
					});
				}
			});
	};

	const renderMenu = (
		<Menu
			anchorEl={menu}
			anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
			transformOrigin={{ vertical: "top", horizontal: "left" }}
			open={Boolean(menu)}
			onClose={closeMenu}
			keepMounted
		>
			<MenuItem onClick={handleDocument}>Lhat Dokumen</MenuItem>
			{user && [2, 3, 4, 5].includes(user.roleId) && <MenuItem onClick={handleEdit}>Edit</MenuItem>}
			{user && [2, 3].includes(user.roleId) && documentComplete && !isCompleted && (
				<MenuItem onClick={handleCompleted}>Set Complete</MenuItem>
			)}
		</Menu>
	);

	return (
		<MDBox display="flex">
			<Confirm ref={confirmRef} />
			<ModalNotif ref={modalNotifRef} />

			{/* Dialog View Image */}
			<DialogForm ref={dialogFormRef} maxWidth={"sm"}>
				<MDBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
					<MDTypography variant="h5" textTransform="uppercase">
						KTP
					</MDTypography>
					<Close
						fontSize="medium"
						sx={{ cursor: "pointer" }}
						onClick={() => dialogFormRef.current.setShow({ show: false })}
					/>
				</MDBox>
				<MDBox
					position="relative"
					borderRadius="lg"
					mx={2}
					className="card-header"
					sx={{ transition: "transform 300ms cubic-bezier(0.34, 1.61, 0.7, 1)" }}
				>
					{item.Document?.ktp && (
						<MDBox
							component="img"
							src={`${Config.ApiAsset}/user/document/${item.Document.ktp}`}
							alt="KTP"
							borderRadius="lg"
							shadow="sm"
							width="100%"
							height="250px"
							position="relative"
							zIndex={10}
							mb={2}
						/>
					)}

				</MDBox>

				{/* KK */}
				<MDBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
					<MDTypography variant="h5" textTransform="uppercase">
						Kartu Keluarga
					</MDTypography>
				</MDBox>
				<MDBox
					position="relative"
					borderRadius="lg"
					mx={2}
					className="card-header"
					sx={{ transition: "transform 300ms cubic-bezier(0.34, 1.61, 0.7, 1)" }}
				>
					{item.Document?.kk && (
						<MDBox
							component="img"
							src={`${Config.ApiAsset}/user/document/${item.Document?.kk}`}
							alt="Kartu Keluarga"
							borderRadius="lg"
							shadow="sm"
							width="100%"
							height="250px"
							position="relative"
							zIndex={10}
							mb={2}
						/>
					)}
				</MDBox>

				{/* Passport */}
				<MDBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
					<MDTypography variant="h5" textTransform="uppercase">
						Passport
					</MDTypography>
				</MDBox>
				<MDBox
					position="relative"
					borderRadius="lg"
					mx={2}
					className="card-header"
					sx={{ transition: "transform 300ms cubic-bezier(0.34, 1.61, 0.7, 1)" }}
				>
					{item.Document?.passport && (
						<MDBox
							component="img"
							src={`${Config.ApiAsset}/user/document/${item.Document?.passport}`}
							alt="Passport"
							borderRadius="lg"
							shadow="sm"
							width="100%"
							height="250px"
							position="relative"
							zIndex={10}
							mb={2}
						/>
					)}
				</MDBox>

				{/* Photo */}
				<MDBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
					<MDTypography variant="h5" textTransform="uppercase">
						Photo
					</MDTypography>
				</MDBox>
				<MDBox
					position="relative"
					borderRadius="lg"
					mx={2}
					className="card-header"
					sx={{ transition: "transform 300ms cubic-bezier(0.34, 1.61, 0.7, 1)" }}
				>
					{item.Document?.photo && (
						<MDBox
							component="img"
							src={`${Config.ApiAsset}/user/document/${item.Document?.photo}`}
							alt="Photo"
							borderRadius="lg"
							shadow="sm"
							width="100%"
							height="250px"
							position="relative"
							zIndex={10}
							mb={2}
						/>
					)}
				</MDBox>

				{/* Visa */}
				<MDBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
					<MDTypography variant="h5" textTransform="uppercase">
						Visa
					</MDTypography>
				</MDBox>
				<MDBox
					position="relative"
					borderRadius="lg"
					mx={2}
					className="card-header"
					sx={{ transition: "transform 300ms cubic-bezier(0.34, 1.61, 0.7, 1)" }}
				>
					{item.Document?.visa && (
						<MDBox
							component="img"
							src={`${Config.ApiAsset}/user/document/${item.Document?.visa}`}
							alt="Visa"
							borderRadius="lg"
							shadow="sm"
							width="100%"
							height="250px"
							position="relative"
							zIndex={10}
							mb={2}
						/>
					)}
				</MDBox>
			</DialogForm>

			<DialogStatus ref={dialogStatusRef} onAction={refreshData.bind(this)} />
			<MDButton variant="contained" color="info" size="small" onClick={openMenu}>
				actions&nbsp;
				<Icon>keyboard_arrow_down</Icon>
			</MDButton>
			{renderMenu}
		</MDBox>
	);
}

ButtonAction.propTypes = {
	item: PropTypes.object,
	isCompleted: PropTypes.bool.isRequired,
	refreshData: PropTypes.func,
};

export default ButtonAction;
