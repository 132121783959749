const bgColor = "#2c1061"
const borderColor = "#391380"
const RepaymentStyle = {
	page: {
		fontFamily: 'RobotoMono',
		fontSize: 6,
		// paddingTop: 25,
		// paddingLeft: 20,
		// paddingRight: 20,
		lineHeight: 1.5,
		flexDirection: "column"
	},
	headerContainer: {
		width: "100%",
		marginBottom: 2,
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		fontSize: 5,
		paddingVertical: 20,
		paddingHorizontal: 30,
		backgroundColor: bgColor
	},
	headerLeft: {
		width: "60%",
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "center"
	},
	headerLeftDesc: {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start",
		justifyContent: "flex-end"
	},
	headerLogo: {
		width: 100,
		height: 80,
		marginRight: 10,
	},
	company: {
		fontSize: 14,
		fontWeight: "bold",
		color: "#FFF"
	},
	headerCaption: {
		fontSize: 11,
		color: "#FFF"
	},
	headerRight: {
		width: "40%",
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-start",
		alignItems: "flex-end",
	},
	headerRightTitle: {
		fontSize: 20,
		fontWeight: "bold",
		textAlign: "center",
		color: "#FFF",
		// marginBottom: 2,
	},
	headerRightRow: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center"
	},
	headerRightItem: {
		fontSize: 10,
		fontWeight: "bold",
		color: "#FFF"
	},
	contentContainer: {
		marginHorizontal: 30
	},
	invoiceContainer: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		width: "100%",
		marginTop: 10
	},
	invoiceLeft: {
		width: "60%",
	},
	invoiceDetail: {
		fontSize: 10,
		textAlign: "left",
		fontWeight: "bold",
		marginBottom: 5
	},
	invoiceCaption: {
		textAlign: "left",
		fontSize: 10,
	},
	invoiceRight: {
		width: "40%",
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-end"
	},
	titleContainer: {
		width: "100%",
		display: "flex",
		justifyContent: "center",
		flexDirection: 'row',
		marginTop: 10,
		marginBottom: 5
	},
	titleText: {
		fontSize: 18,
		fontWeight: "bold",
		textAlign: "center",
		textTransform: 'uppercase',
		paddingBottom: 2,
		textDecoration: "underline"
	},
	tableContainer: {
		flexDirection: 'row',
		flexWrap: 'wrap',
		marginTop: 24,
		// borderWidth: 1,
		// borderColor: '#8c7ae6',
	},
	tableHeadContainer: {
		flexDirection: 'row',
		borderBottomColor: bgColor,
		backgroundColor: bgColor,
		borderBottomWidth: 1,
		// borderWidth: 1,
		// borderColor: '#8c7ae6',
		alignItems: 'center',
		height: 24,
		textAlign: 'center',
		fontWeight: "bold",
		flexGrow: 1,
		textTransform: "uppercase"
	},
	tableHeadDesc: {
		width: '70%',
		color: "#FFF",
		fontSize: 12
	},
	tableHeadAmount: {
		width: '30%',
		color: "#FFF",
		fontSize: 12,
	},
	tableRow: {
		flexDirection: 'row',
		borderColor: borderColor,
		borderBottomWidth: 1,
		borderLeftWidth: 1,
		borderRightWidth: 1,
		alignItems: 'center',
		height: 24,
		fontWeight: 'bold',
	},
	tableRowDesc: {
		width: '70%',
		textAlign: 'left',
		paddingLeft: 8,
		// color: "#FFF",
		fontSize: 10,
	},
	tableRowAmount: {
		width: '30%',
		textAlign: 'right',
		paddingRight: 8,
		// color: "#FFF",
		fontSize: 10,
	},
	tableFooterRow: {
		// marginHorizontal: 30,
		flexDirection: 'row',
		justifyContent: "flex-end",
		alignItems: 'center',
		height: 24,
		fontWeight: 'bold',
	},
	tableFootCaption: {
		fontSize: 10,
		textAlign: "right",
		width: "15%",
		borderColor: borderColor,
		borderBottomWidth: 1,
	},
	tableFootValue: {
		width: "20%",
		fontSize: 10,
		paddingRight: 8,
		textAlign: "right",
		borderColor: borderColor,
		borderBottomWidth: 1,
	},
	noteContainer: {
		marginTop: 20,
		flexDirection: "column",
		justifyContent: "flex-start",
		lineHeight: 1.5
	},
	noteCaption: {
		fontSize: 10,
		fontWeight: "bold",
		textAlign: "left",
		marginBottom: 10
	},
	noteItem: {
		fontSize: 9,
		// fontWeight: "bold",
		textAlign: "left",
	},
}

export default RepaymentStyle