import React, { useState } from "react";
import PropTypes from "prop-types";
import { Navigate, Link } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDAvatar from "components/MDAvatar";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import DataTable from 'contents/Components/DataTable'
import Pagination from "contents/Components/Pagination";
import ButtonAction from "contents/Master/Prayer/components/ButtonAction"

import useAxios from "libs/useAxios"
import moment from 'moment'
import Config from 'config'
import secureStorage from "libs/secureStorage";

class Prayer extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			merchant: null,

			merchants: [],

			rowsPerPage: 10,
			currentPage: 1,
			totalPages: 0,
			totalData: 0,
			tableHead: [
				{ Header: "action", accessor: "action", width: "10%" },
				{ Header: "no", accessor: "no", width: "15%" },
				{ Header: "nomor urut", accessor: "sortNumber", width: "15%" },
				{ Header: "judul", accessor: "title", width: "20%" },
				{ Header: "doa", accessor: "arabic", width: "30%" },
				{ Header: "latin", accessor: "latin", width: "30%" },
				{ Header: "terjemah", accessor: "translate", width: "30%" },
			],
			rows: [],
			keyword: "",
			isLoading: true,
		}
	}

	componentDidMount() {
		const user = secureStorage.getItem('user')
		this.setState({ user }, () => {
			user && user.roleId === 1 && this.loadData()
		})
	}


	loadData = (params) => {
		this.setState({ isLoading: true })
		const user = secureStorage.getItem('user')

		const paylaod = {
			keyword: params && params.keyword ? params.keyword : this.state.keyword,
			currentPage: params && params.currentPage ? params.currentPage : 1,
			rowsPerPage: params && params.rowsPerPage ? params.rowsPerPage : this.state.rowsPerPage,
		}

		useAxios()
			.post(`${Config.ApiUrl}/master/prayer`, paylaod)
			.then(response => {
				const data = response.data
				const output = data.output.map((item, key) => {
					const arabic = (
						<p style={{ wordWrap: "break-word", width: "25em" }}>{item.arabic}</p>
					)
					const latin = (
						<p style={{ wordWrap: "break-word", width: "25em" }}>{item.latin}</p>
					)
					const translate = (
						<p style={{ wordWrap: "break-word", width: "25em" }}>{item.translate}</p>
					)
					return {
						no: key + 1,
						sortNumber: item.sortNumber,
						title: item.title,
						arabic,
						latin,
						translate,
						action: user && [1].includes(user.roleId) ? (
							<ButtonAction id={item.id} refreshData={this.loadData} />
						) : '-'
					}
				})

				this.setState({
					totalPages: data.totalPages,
					totalData: data.totalData,
					rows: output,
					isLoading: false
				})
			})
			.catch(err => {
				console.log(err)
				this.setState({ isLoading: false })
			})
	}

	render() {
		const user = secureStorage.getItem('user')

		return (
			<MDBox pb={3} my={3}>
				<MDBox pb={2} mt={{ xs: 2, md: 0 }} display="flex" justifyContent={{ xs: "center", md: "flex-start" }}>
					{[1].includes(user.roleId) && (
						<MDButton
							size="medium"
							color="info"
							variant="gradient"
							component={Link}
							to={{ pathname: "/master/prayer/add" }}
						>
							TAMBAH
						</MDButton>
					)}
				</MDBox>
				<Card>
					<MDBox p={2} lineHeight={1}>
						<MDTypography variant="h5" fontWeight="medium">
							Daftar Doa Manasik
						</MDTypography >
					</MDBox >
					<MDBox px={2} width="100%" display="flex" justifyContent="flex-start">
						<Grid container spacing={3}>
							<Grid item xs={12} md={3} lg={3}>
								<MDInput
									label="Search..."
									size="small"
									fullWidth
									value={this.state.keyword}
									onKeyPress={(e) => {
										if (e.key === "Enter") {
											this.loadData({
												currentPage: 1,
												keyword: e.target.value,
											})
										}
									}}
									onChange={(e) => this.setState({ keyword: e.target.value })}
								/>
							</Grid>
						</Grid>
					</MDBox>

					<MDBox p={2}>
						<DataTable
							isLoading={this.state.isLoading}
							tableHead={this.state.tableHead}
							tableData={this.state.rows}
						/>
						<Pagination
							totalPages={this.state.totalPages}
							totalData={this.state.totalData}
							currentPage={this.state.currentPage}
							rowsPerPage={[10, 25, 50, "All"]}
							totalButton={3}
							defaultRowsPerPage={this.state.rowsPerPage}
							onChangeRowsPerPage={(value) => {
								this.setState({ rowsPerPage: value, currentPage: 1 })
								this.loadData({
									rowsPerPage: value,
									currentPage: 1,
								})
							}}
							onChangePage={(currentPage) => {
								if (currentPage !== this.state.currentPage) {
									this.setState({ currentPage })
									this.loadData({
										currentPage,
									})
								}
							}}
						/>
					</MDBox>
				</Card >
			</MDBox >
		)
	}
}

export default Prayer