/* eslint-disable no-undef */
module.exports = {
  ApiAsset: 'https://cdn.mabrur.tours/asset/images',
  ApiUrl: "https://api-mon.mabrur.tours/v2",
  // ApiUrl: "http://localhost:4114/v2",

  apiKey: "AIzaSyCwbrjMlcy2B4E4zBcPx-jGncL4TYs_FLs",
  authDomain: "mabrur-app.firebaseapp.com",
  projectId: "mabrur-app",
  storageBucket: "mabrur-app.appspot.com",
  messagingSenderId: "736469682277",
  appId: "1:736469682277:web:1ac135eb0e73f6ef489e8c",
  measurementId: "G-L886502SXB",
  vapidKey: "BGHEIbVcAyL6Itnq_D10EY_pyRT_X288prKmL8DkMTAwpHsFTFBUIHmvthYnbjuVFFXtFbsMecuWU21HfMh1dqc"
};
