import React, { useState } from "react";
import PropTypes from "prop-types";
import { Navigate, Link } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import DataTable from 'contents/Components/DataTable'
import Pagination from "contents/Components/Pagination";
import ButtonAction from "contents/Manage/User/components/ButtonAction"

import useAxios from "libs/useAxios"
import Config from 'config'
import secureStorage from "libs/secureStorage";

class User extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			user: null,

			role: null,
			status: null,
			merchant: null,

			merchants: [],
			roles: [],
			statuses: [{ id: 1, label: 'Aktif' }, { id: 2, label: 'Tidak Aktif' }],

			rowsPerPage: 10,
			currentPage: 1,
			totalPages: 0,
			totalData: 0,
			tableHead: [
				{ Header: "action", accessor: "action", width: "15%" },
				{ Header: "kode", accessor: "kode", width: "15%" },
				{ Header: "nama", accessor: "name", width: "25%" },
				{ Header: "email", accessor: "email", width: "20%" },
				{ Header: "phone", accessor: "phone", width: "20%" },
				{ Header: 'group', accessor: 'group', width: "20%" },
				{ Header: "status", accessor: "status", width: "15%" },
			],
			rows: [],
			keyword: "",
			isLoading: true,
		}
	}

	componentDidMount() {
		const user = secureStorage.getItem('user')
		this.setState({ user }, () => {
			this.loadData()
			user && user.roleId === 1 && this.loadMerchants()
			user && [1, 2].includes(user.roleId) && this.loadRoles()
		})
	}

	loadMerchants = () => {
		useAxios()
			.post(`${Config.ApiUrl}/manage/merchant/dropdown`)
			.then(response => {
				const data = response.data

				this.setState({
					merchants: data.map(item => ({ id: item.id, label: item.name }))
				})
			})
			.catch(err => {
				console.log(err)
			})
	}

	loadRoles = () => {
		useAxios()
			.post(`${Config.ApiUrl}/user/role`)
			.then(response => {
				const data = response.data
				this.setState({
					roles: data.map(item => ({ id: item.id, label: item.name }))
				})
			})
			.catch(err => {
				console.log(err)
			})
	}

	loadData = (params) => {
		const user = secureStorage.getItem('user')
		this.setState({ isLoading: true })

		const defaultRoleIds = user.roleId === 1 ? [2, 3] : user.roleId === 2 ? [3] : []

		const merchantId = params && params.merchantId ? { merchantId: params.merchantId } : {}
		const statusId = params && params.statusId ? { statusId: params.statusId } : {}
		const roleId = params && params.roleId ? { roleId: [params.roleId] } : { roleId: defaultRoleIds }

		const payload = {
			keyword: params && params.keyword ? params.keyword : this.state.keyword,
			currentPage: params && params.currentPage ? params.currentPage : 1,
			rowsPerPage: params && params.rowsPerPage ? params.rowsPerPage : this.state.rowsPerPage,
			...merchantId,
			...statusId,
			...roleId
		};

		useAxios()
			.post(`${Config.ApiUrl}/user`, payload)
			.then(response => {
				const data = response.data
				const output = data.output.map(item => {
					return {
						kode: (
							<MDTypography
								variant="caption"
								fontWeight="medium"
								sx={{
									cursor: "pointer"
								}}
								color="info"
								component={Link}
								to={{ pathname: `/manage/user/detail/${item.id}` }}
							>
								{item.kode}
							</MDTypography>
						),
						name: item.name,
						phone: item.phone,
						email: item.email,
						status: <MDBadge
							variant="contained"
							badgeContent={item.isActive ? "Aktif" : "Tidak Aktif"}
							size="lg"
							color={item.isActive ? "success" : "warning"}
						/>,
						group: <MDBadge
							variant="gradient"
							badgeContent={item.Role ? item.Role.name : "-"}
							size="lg"
							color={item.Role && item.Role.id === 2 ? "primary" : "light"}
						/>,
						action: (
							<ButtonAction
								id={item.id}
								view={"user"}
								refreshData={this.loadData}
							/>
						)
					}
				})

				this.setState({
					totalPages: data.totalPages,
					totalData: data.totalData,
					rows: output,
					isLoading: false
				})
			})
			.catch(err => {
				console.log(err)
				this.setState({ isLoading: false })
			})
	}

	render() {
		const user = secureStorage.getItem('user')

		return (
			<MDBox pb={3} my={3}>
				<MDBox pb={2} mt={{ xs: 2, md: 0 }} display="flex" justifyContent={{ xs: "center", md: "flex-start" }}>
					{[1, 2].includes(user.roleId) && (
						<MDButton
							color="info"
							variant="gradient"
							component={Link}
							to={{ pathname: "/manage/user/add" }}
						>
							TAMBAH
						</MDButton>
					)}
				</MDBox>
				<Card>
					<MDBox p={2} lineHeight={1}>
						<MDTypography variant="h5" fontWeight="medium">
							Daftar User
						</MDTypography>
					</MDBox>

					<MDBox px={2} width="100%" display="flex" justifyContent="flex-start">
						<Grid container spacing={3}>
							<Grid item xs={12} md={3} lg={3}>
								<MDInput
									label="Search..."
									size="small"
									fullWidth
									value={this.state.keyword}
									onKeyPress={(e) => {
										if (e.key === "Enter") {
											this.loadData({
												keyword: e.target.value,
												currentPage: 1,
												merchantId: this.state.merchant ? this.state.merchant?.id : null,
												statusId: this.state.status ? this.state.status?.id : null,
												roleId: this.state.role ? this.state.role.id : null
											})
										}
									}}
									onChange={(e) => this.setState({ keyword: e.target.value })}
								/>
							</Grid>
							{/* Filter Biro Travel */}
							{[1].includes(user.roleId) && (
								<Grid item xs={12} md={3} lg={3}>
									<Autocomplete
										value={this.state.merchant}
										options={this.state.merchants}
										onChange={(e, value) => {
											this.setState({ merchant: value })
											this.loadData({
												currentPage: 1,
												merchantId: value ? value.id : null,
												roleId: this.state.role ? this.state.role.id : null,
												statusId: this.state.status ? this.state.status?.id : null
											})
										}}
										sx={{
											".MuiAutocomplete-input": {
												padding: "7.5px 5px 7.5px 8px !important"
											},
											".MuiOutlinedInput-root": {
												padding: "1.5px !important"
											}
										}}
										isOptionEqualToValue={(option, value) => option.id === value.id}
										renderInput={(params) => <MDInput sx={{ padding: "0px" }} fullWidth label="Pilih Biro Travel" {...params} />}
									/>
								</Grid>
							)}
							{/* Filter Role */}
							{[1].includes(user.roleId) && (
								<Grid item xs={12} md={3} lg={3}>
									<Autocomplete
										value={this.state.role}
										options={this.state.roles}
										onChange={(e, value) => {
											this.setState({ role: value })
											this.loadData({
												currentPage: 1,
												roleId: value ? value.id : null,
												statusId: this.state.status ? this.state.status.id : null,
												merchantId: this.state.merchant ? this.state.merchant?.id : null
											})
										}}
										sx={{
											".MuiAutocomplete-input": {
												padding: "7.5px 5px 7.5px 8px !important"
											},
											".MuiOutlinedInput-root": {
												padding: "1.5px !important"
											}
										}}
										isOptionEqualToValue={(option, value) => option.id === value.id}
										renderInput={(params) => <MDInput sx={{ padding: "0px" }} fullWidth label="Pilih Group" {...params} />}
									/>
								</Grid>
							)}
							{/* Filter Status */}
							<Grid item xs={12} md={3} lg={3}>
								<Autocomplete
									value={this.state.status}
									options={this.state.statuses}
									onChange={(e, value) => {
										this.setState({ status: value })
										this.loadData({
											currentPage: 1,
											statusId: value ? value.id : null,
											roleId: this.state.role ? this.state.role.id : null,
											merchantId: this.state.merchant ? this.state.merchant?.id : null
										})
									}}
									sx={{
										".MuiAutocomplete-input": {
											padding: "7.5px 5px 7.5px 8px !important"
										},
										".MuiOutlinedInput-root": {
											padding: "1.5px !important"
										}
									}}
									isOptionEqualToValue={(option, value) => option.id === value.id}
									renderInput={(params) => <MDInput sx={{ padding: "0px" }} fullWidth label="Pilih Status" {...params} />}
								/>
							</Grid>
						</Grid>
					</MDBox>

					<MDBox p={2}>
						<DataTable
							isLoading={this.state.isLoading}
							tableHead={this.state.tableHead}
							tableData={this.state.rows}
						/>
						<Pagination
							totalPages={this.state.totalPages}
							totalData={this.state.totalData}
							currentPage={this.state.currentPage}
							rowsPerPage={[10, 25, 50, "All"]}
							totalButton={3}
							defaultRowsPerPage={this.state.rowsPerPage}
							onChangeRowsPerPage={(value) => {
								this.setState({ rowsPerPage: value, currentPage: 1 }, () => {
									this.loadData({
										rowsPerPage: value,
										currentPage: 1,
										roleId: this.state.role ? this.state.role?.id : null,
										merchantId: this.state.merchant ? this.state.merchant?.id : null
									});
								});
							}}
							onChangePage={(currentPage) => {
								if (currentPage !== this.state.currentPage) {
									this.setState({ currentPage }, () => {
										this.loadData({
											currentPage,
											roleId: this.state.role ? this.state.role?.id : null,
											merchantId: this.state.merchant ? this.state.merchant?.id : null
										});
									});
								}
							}}
						/>
					</MDBox>
				</Card>
			</MDBox>
		)
	}
}

export default User