import React from "react";

// react-router-dom components
import { Navigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import MuiLink from "@mui/material/Link";
import Grid from "@mui/material/Grid"
// @mui icons
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import LOGO from "assets/img/logoACC.png";

import BasicLayout from "./BasicLayout";

import axios from "axios";
import * as EmailValidator from "email-validator";

import Config from "config";
import secureStorage from "libs/secureStorage";

import Notif from 'contents/Components/Notif'
import DialogForm from "contents/Components/DialogForm";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      loggIn: false,

      resetPass: "",
      verify: false,

      isShow: false,
      color: 'success',

      showModal: false,

      submitDisabled: false,
      submitResetDisabled: true
    };

    this.notifRef = React.createRef()
    // this.handleChange = this.handleChange.bind(this)
    this.dialogFormRef = React.createRef()
  }

  componentDidMount() {
    const user = secureStorage.getItem("user");
    if (user) this.setState({ loggIn: true });
    else {
      this.setState({ loggIn: false, verify: false })
      secureStorage.removeItem("token");
      secureStorage.removeItem("user");
    }
  }

  toggleModal = () => {
    this.dialogFormRef.current.setShow({ show: true, title: "Lupa Password" })
  }

  handleForgetPass = () => {
    const phone = !isNaN(this.state.resetPass) && this.state.resetPass;
    const email = EmailValidator.validate(this.state.resetPass) && this.state.resetPass;

    console.log({ resetPass: this.state.resetPass, phone, email })
    if (phone || email) {
      this.setState({ submitResetDisabled: true })
      const payload = {
        phone,
        email
      }

      axios
        .post(`${Config.ApiUrl}/user/reset/sendotp`, payload)
        .then(response => {
          const data = response.data;
          console.log({ data })
          secureStorage.setItem('resetPass', data)
          this.setState({ submitResetDisabled: false, verify: true })
        }).catch(err => {
          console.log(err)
          this.setState({ submitResetDisabled: false })
          this.notifRef.current.setShow({
            show: true,
            message: err.response && err.response.data ? err.response.data : "Terjadi kesalahan pada system",
            color: "warning"
          })
        })
    } else {
      this.notifRef.current.setShow({
        show: true,
        message: "Data masih ada yang kosong, Mohon di lengkapi dahulu",
        color: "warning"
      });
    }
  }

  handleKeyDown = (e) => {
    if (e.key === "Enter") this.handleLogin()
  }

  handleChange = (e) => {
    const { id, value } = e.target;
    if (id === "username") this.setState({ username: value });
    else this.setState({ password: value });
  }

  handleLogin = () => {
    this.setState({ submitDisabled: true });
    if (this.state.username === "" || this.state.password === "") {
      this.setState({
        submitDisabled: false,
      });

      this.notifRef.current.setShow({
        show: true,
        message: "Data masih ada yang kosong, Mohon di lengkapi dahulu",
        color: "warning"
      })
    } else {
      const payload = {
        username: this.state.username,
        password: this.state.password,
      };

      axios
        .post(`${Config.ApiUrl}/user/login`, payload)
        .then((response) => {
          const data = response.data;
          secureStorage.setItem('user', data.user)
          secureStorage.setItem('token', data.token)
          axios.defaults.headers.Authorization = "Bearer " + data.token;
          this.setState({ loggIn: true, submitDisabled: false })
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            submitDisabled: false,
          }, () => {
            this.notifRef.current.setShow({
              show: true,
              message: err.response && err.response.data ? err.response.data : "Terjadi kesalahan pada system",
              color: 'warning'
            })
          })
        });
    }
  }

  render() {
    if (this.state.loggIn) return <Navigate to="/" />;
    if (this.state.verify) return <Navigate to="/verifycation" />
    return (
      <BasicLayout image={bgImage}>
        <DialogForm ref={this.dialogFormRef} maxWidth="xs">
          <Grid container item xs={12} lg={12} sx={{ mx: "auto" }}>
            <MDBox width="100%" component="form">
              <MDBox my={2}>
                <MDInput
                  fullWidth
                  type="text"
                  value={this.state.resetPass}
                  onChange={(e) => {
                    const val = e.target.value
                    if (!val) {
                      this.setState({
                        resetPass: val,
                        submitResetDisabled: true,
                      })
                    }
                    else if (isNaN(val) && !EmailValidator.validate(val)) {
                      this.setState({
                        resetPass: val,
                        submitResetDisabled: true
                      })
                    }
                    else if (!isNaN(val) && val.indexOf("08") !== 0) {
                      this.setState({
                        resetPass: val,
                        submitResetDisabled: true
                      })
                    }
                    else {
                      this.setState({
                        resetPass: val,
                        submitResetDisabled: false
                      })
                    }
                  }}
                  label="Masukan Email / Nomor Telpon Anda"
                />
              </MDBox>
            </MDBox>
            <MDBox py={3} width="100%" display="flex" justifyContent={{ md: "flex-end", xs: "center" }}>
              <MDBox mr={1}>
                <MDButton
                  variant="gradient"
                  color="error"
                  onClick={() => this.dialogFormRef.current.setShow({ show: false, title: "" })}
                >
                  TUTUP
                </MDButton>
              </MDBox>
              <MDButton
                variant="gradient"
                color="info"
                disabled={this.state.submitResetDisabled}
                onClick={this.handleForgetPass}
              >
                SUBMIT
              </MDButton>
            </MDBox>
          </Grid>
        </DialogForm>


        <Notif ref={this.notifRef} />
        <Card>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            mx={2}
            mt={-3}
            p={2}
            mb={1}
            textAlign="center"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              LOGIN
            </MDTypography>
          </MDBox>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" role="form">
              <MDBox mb={2}>
                <MDInput
                  id="username"
                  type="username"
                  label="Username"
                  value={this.state.username}
                  onChange={this.handleChange}
                  onKeyDown={this.handleKeyDown}
                  fullWidth
                />
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  id="passowrd"
                  type="password"
                  value={this.state.password}
                  onChange={this.handleChange}
                  onKeyDown={this.handleKeyDown}
                  label="Password"
                  fullWidth
                />
              </MDBox>
              <MDBox mt={4} mb={1}>
                <MDButton
                  variant="gradient"
                  color="info"
                  disabled={this.state.submitDisabled}
                  onClick={this.handleLogin}
                  fullWidth
                >
                  MASUK
                </MDButton>
              </MDBox>
              <MDBox mt={3} mb={1} textAlign="center">
                <MDTypography
                  onClick={this.toggleModal}
                  sx={{ cursor: "pointer" }}
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Lupa passsword ?
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      </BasicLayout>
    );
  }
}

export default Login;
