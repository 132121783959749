import React, { useState } from "react";
import { Link } from "react-router-dom"
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDDatePicker from "components/MDDatePicker";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import DataTable from "contents/Components/DataTable";
import Pagination from "contents/Components/Pagination";

import useAxios from "libs/useAxios";
import { fnumber } from "libs/currency"
import Config from "config";
import secureStorage from "libs/secureStorage";
import moment from "moment";

class OutFund extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      merchant: null,
      product: null,
      startDate: "",
      endDate: "",

      merchants: [],
      products: [],

      rowsPerPage: 10,
      currentPage: 1,
      totalPages: 0,
      totalData: 0,
      tableHead: [
        { Header: "No", accessor: "no", width: "10%" },
        { Header: "Type", accessor: "type", width: "20%" },
        { Header: "Tanggal", accessor: "date", width: "20%" },
        { Header: "Deskripsi", accessor: "description", width: "20%" },
        { Header: "Nominal", accessor: "amount", width: "20%" },
      ],
      rows: [],
      isLoading: true,
    };
  }

  componentDidMount() {
    const user = secureStorage.getItem("user");
    const startDate = moment().startOf("year").format("YYYY-MM-DD")
    const endDate = moment().endOf("year").format("YYYY-MM-DD")

    user.roleId === 1 && this.loadMerchants()
    user.roleId !== 1 && this.loadProduct()
    this.setState({
      startDate, endDate
    }, () => {
      this.loadData({ startDate, endDate })
    })

  }

  loadMerchants = () => {
    useAxios()
      .post(`${Config.ApiUrl}/manage/merchant/dropdown`)
      .then((response) => {
        const data = response.data;

        this.setState({
          merchants: data.map((item) => ({ id: item.id, label: item.name })),
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  loadProduct = (params) => {
    const merchantId = params && params.merchantId ? { merchantId: params.merchantId } : {}
    const payload = {
      ...merchantId
    }

    useAxios()
      .post(`${Config.ApiUrl}/product/dropdown`, payload)
      .then(response => {
        const data = response.data

        this.setState({
          products: data.map(item => ({ id: item.id, label: item.name }))
        })
      })
      .catch(err => {
        console.log(err)
      })
  }

  loadData = (params) => {
    this.setState({ isLoading: true });
    const user = secureStorage.getItem("user")

    const merchantId = params && params.merchantId ? { merchantId: params.merchantId } : {}
    const productId = params && params.productId ? { productId: params.productId } : {}

    const payload = {
      type: 'out',
      currentPage: params && params.currentPage ? params.currentPage : this.state.currentPage,
      rowsPerPage: params && params.rowsPerPage ? params.rowsPerPage : this.state.rowsPerPage,
      startDate: params && params.startDate ? params.startDate : this.state.startDate,
      endDate: params && params.endDate ? params.endDate : this.state.endDate,
      ...merchantId,
      ...productId,
    }

    useAxios()
      .post(`${Config.ApiUrl}/finance/mutation`, payload)
      .then((response) => {
        const data = response.data;
        let no = 0;
        const output = data.output.map((item) => {
          no++;
          return {
            no: <MDTypography variant="caption" fontWeight="regular">{no}</MDTypography>,
            date: (
              <MDTypography variant="caption" fontWeight="regular">
                {item.createdAt}
              </MDTypography>
            ),
            type: item.remark ? (
              <MDTypography variant="caption" fontWeight="regular">
                {item.remark}
              </MDTypography>
            ) : "-",
            amount: fnumber(item.amount),
            description: (
              <MDBox
                style={{ wordWrap: "break-word", width: "20em" }}
              >
                <MDTypography variant="caption" fontWeight="regular">
                  {item.description}
                </MDTypography>
              </MDBox>
            )
          };
        });

        this.setState({
          totalPages: data.totalPages,
          totalData: data.totalData,
          rows: output,
          isLoading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ isLoading: false });
      });
  }

  render() {
    const user = secureStorage.getItem("user");

    return (
      <MDBox pb={3} my={3}>
        <MDBox
          pb={2}
          mt={{ xs: 2, md: 0 }}
          display="flex"
          justifyContent={{ xs: "center", md: "flex-start" }}
        >
          <MDButton
            size="medium"
            color="info"
            variant="gradient"
            component={Link}
            to={{ pathname: "/finance/out-fund/add" }}
          >
            TAMBAH
          </MDButton>
        </MDBox>
        <Card>
          <MDBox p={2} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              Dana Keluar
            </MDTypography>
          </MDBox>
          <MDBox px={2} width="100%" display="flex" justifyContent="flex-start">
            <Grid container spacing={3}>
              {/* Merchant */}
              {[1].includes(user.roleId) && (
                <Grid item xs={12} md={3} lg={3}>
                  <Autocomplete
                    value={this.state.merchant}
                    options={this.state.merchants}
                    onChange={(e, value) => {
                      this.setState({ merchant: value, product: null }, () => {
                        const params = value ? { merchantId: value.id } : {}
                        this.loadProduct(params)

                        this.loadData({
                          currentPage: 1,
                          merchantId: value ? value.id : null,
                          startDate: this.state.startDate ? this.state.startDate : "",
                          endDate: this.state.endDate ? this.state.endDate : "",
                        });
                      });
                    }}
                    sx={{
                      ".MuiAutocomplete-input": {
                        padding: "7.5px 5px 7.5px 8px !important",
                      },
                      ".MuiOutlinedInput-root": {
                        padding: "1.5px !important",
                      },
                    }}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    renderInput={(params) => (
                      <MDInput
                        sx={{ padding: "0px" }}
                        fullWidth
                        label="Pilih Biro Travel"
                        {...params}
                      />
                    )}
                  />
                </Grid>
              )}
              {/* Product */}
              <Grid item xs={12} md={3} lg={3}>
                <Autocomplete
                  value={this.state.product}
                  options={this.state.products}
                  onChange={(e, value) => {
                    this.setState({ product: value })
                    this.loadData({
                      currentPage: 1,
                      startDate: this.state.startDate ? this.state.startDate : "",
                      endDate: this.state.endDate ? this.state.endDate : "",
                      productId: value ? value.id : null,
                      merchantId: this.state.merchant ? this.state.merchant.id : null,
                    })
                  }}
                  sx={{
                    ".MuiAutocomplete-input": {
                      padding: "7.5px 5px 7.5px 8px !important"
                    },
                    ".MuiOutlinedInput-root": {
                      padding: "1.5px !important"
                    }
                  }}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  renderInput={(params) => <MDInput sx={{ padding: "0px" }} fullWidth label="Pilih Paket" {...params} />}
                />
              </Grid>
              {/* Start Date */}
              <Grid
                item
                xs={12}
                md={[1].includes(user.roleId) ? 2 : 3}
                lg={[1].includes(user.roleId) ? 2 : 3}
              >
                <MDDatePicker
                  input={{
                    id: "startDate",
                    placeholder: "Tanggal Awal",
                    fullWidth: true,
                    sx: {
                      ".MuiInputBase-input": {
                        height: "1em !important",
                      },
                    },
                  }}
                  value={this.state.startDate}
                  onChange={(value) => {
                    const date = moment(value[0]).format("YYYY-MM-DD");
                    this.setState({ startDate: date });
                  }}
                />
              </Grid>
              {/* End Date */}
              <Grid
                item
                xs={12}
                md={[1].includes(user.roleId) ? 2 : 3}
                lg={[1].includes(user.roleId) ? 2 : 3}
              >
                <MDDatePicker
                  input={{
                    id: "endDate",
                    placeholder: "Tanggal Akhir",
                    fullWidth: true,
                    sx: {
                      ".MuiInputBase-input": {
                        height: "1em !important",
                      },
                    },
                  }}
                  value={this.state.endDate}
                  onChange={(value) => {
                    const date = moment(value[0]).format("YYYY-MM-DD");
                    this.setState({ endDate: date });
                  }}
                />
              </Grid>
              {/* Button Search */}
              <Grid item xs={12} md={2} lg={2}>
                <MDBox
                  width="100%"
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-start"
                >
                  <MDButton
                    color="info"
                    variant="gradient"
                    onClick={() => {
                      const startDate = this.state.startDate ? this.state.startDate : ""
                      const endDate = this.state.endDate ? this.state.endDate : ""
                      this.loadData({
                        currentPage: 1,
                        startDate,
                        endDate,
                        productId: this.state.product ? this.state.product.id : null,
                        merchantId: this.state.merchant ? this.state.merchant.id : null
                      })
                    }}
                    iconOnly
                  >
                    <Icon>search</Icon>
                  </MDButton>
                  <MDBox ml={1}>
                    <MDButton
                      color="error"
                      variant="gradient"
                      onClick={() => {
                        this.setState({
                          startDate: "",
                          endDate: "",
                          currentPage: 1,
                          product: null,
                          merchant: null
                        }, () => {
                          this.loadData()
                        })
                      }}
                      iconOnly
                    >
                      <Icon>refresh</Icon>
                    </MDButton>
                  </MDBox>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
          <MDBox p={2}>
            <DataTable
              isLoading={this.state.isLoading}
              tableHead={this.state.tableHead}
              tableData={this.state.rows}
            />
            <Pagination
              totalPages={this.state.totalPages}
              totalData={this.state.totalData}
              currentPage={this.state.currentPage}
              rowsPerPage={[10, 25, 50, "All"]}
              totalButton={3}
              defaultRowsPerPage={this.state.rowsPerPage}
              onChangeRowsPerPage={(value) => {
                this.setState({ rowsPerPage: value, currentPage: 1 });
                this.loadData({
                  rowsPerPage: value,
                  currentPage: 1,
                  productId: this.state.product ? this.state.product.id : null,
                  merchantId: this.state.merchant ? this.state.merchant.id : null,
                });
              }}
              onChangePage={(currentPage) => {
                if (currentPage !== this.state.currentPage) {
                  this.setState({ currentPage });
                  this.loadData({
                    currentPage,
                    productId: this.state.product ? this.state.product.id : null,
                    merchantId: this.state.merchant ? this.state.merchant.id : null,
                  });
                }
              }}
            />
          </MDBox>
        </Card>
      </MDBox>
    );
  }
}

export default OutFund;
