import React, { useState } from "react";
import { Link } from "react-router-dom"
import PropTypes from "prop-types";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox"
import MDButton from "components/MDButton"
import MDInput from "components/MDInput"
import MDTypography from "components/MDTypography"

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import DataTable from 'contents/Components/DataTable'
import Pagination from "contents/Components/Pagination";

import useAxios from "libs/useAxios"
import Config from 'config'
import secureStorage from "libs/secureStorage";
import { fnumber } from "libs/currency";

class PackageAgent extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			merchant: null,

			merchants: [],

			rowsPerPage: 10,
			currentPage: 1,
			totalPages: 0,
			totalData: 0,
			tableHead: [],
			rows: [],
			keyword: "",
			isLoading: true,
		}
	}

	componentDidMount() {
		this.initData()
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.view !== this.props.view) {
			this.initData()
		}
	}

	initData = () => {
		const user = secureStorage.getItem('user')

		this.loadData()
		user && [1, 4].includes(user.roleId) && this.loadMerchants()
	}

	loadMerchants = () => {
		useAxios()
			.post(`${Config.ApiUrl}/manage/merchant/dropdown`)
			.then(response => {
				const data = response.data

				this.setState({
					merchant: null,
					merchants: data.map(item => ({ id: item.id, label: item.name }))
				})
			})
			.catch(err => {
				console.log(err)
			})
	}

	loadData = (params) => {
		this.setState({ isLoading: true })
		const user = secureStorage.getItem('user')
		const view = this.props.view

		const merchantId = params && params.merchantId ? { merchantId: params.merchantId } : {}
		const productCategoryId = view === "umroh" ? 2 : view === "haji" ? 1 : 3

		const payload = {
			keyword: params && params.keyword ? params.keyword : this.state.keyword,
			currentPage: params && params.currentPage ? params.currentPage : 1,
			rowsPerPage: params && params.rowsPerPage ? params.rowsPerPage : this.state.rowsPerPage,
			productCategoryId,
			...merchantId
		}

		useAxios()
			.post(`${Config.ApiUrl}/product/productByCategory`, payload)
			.then(response => {
				const data = response.data
				const tfoot = data.tfoot

				const output = data.output.map(item => {
					const remainder = item.seat - item.used
					const merchant = item.Merchant

					return {
						kode: (
							<MDTypography
								variant="caption"
								fontWeight="medium"
								sx={{
									cursor: "pointer"
								}}
								color="info"
								component={Link}
								to={{ pathname: `/adm/product/detail/${item.id}` }}
							>
								{item.kode}
							</MDTypography>
						),
						name: item.name,
						seat: (
							<MDBox
								width="100%"
								display="flex"
								flexDirection="rows"
								justifyContent="flex-start"
								alignItems="flex-start"
							>
								<MDBox
									display="flex"
									flexDirection="column"
									justifyContent="flex-start"
								>
									<MDTypography variant="caption" fontWeight="regular">
										Total Seat&nbsp;: &nbsp;
										<MDTypography variant="caption" fontWeight="medium">
											{item.seat}
										</MDTypography>
									</MDTypography>
									<MDTypography variant="caption" fontWeight="regular">
										Total Jamaah&nbsp;: &nbsp;
										<MDTypography variant="caption" fontWeight="medium">
											{item.used}
										</MDTypography>
									</MDTypography>
									<MDTypography variant="caption" fontWeight="regular">
										Sisa Seat&nbsp;: &nbsp;
										<MDTypography variant="caption" fontWeight="medium">
											{remainder}
										</MDTypography>
									</MDTypography>
								</MDBox>
							</MDBox>
						),
						commision: fnumber(item.fee),
						price: fnumber(item.price),
						status: item.DepartureStatus && item.DepartureStatus.name ? item.DepartureStatus.name : "",
						action: remainder > 0 && (
							<MDBox width="100%" display="flex" alignItems="center">
								<MDButton
									type="button"
									variant="gradient"
									color="error"
									size="small"
									component={Link}
									to={{ pathname: `/adm/register-member?productCode=${item.kode}&merchantCode=${merchant?.kode}` }}
								>
									DAFTAR
								</MDButton>
							</MDBox>
						)
					}
				})

				const tableHead = [
					{ Header: "action", accessor: "action", width: "15%" },
					{ Header: "kode", accessor: "kode", width: "10%" },
					{ Header: "nama", accessor: "name", width: "25%" },
					{
						Header: "seat",
						accessor: "seat",
						width: "10%",
						Footer: <MDTypography variant="button" fontWeight="bold" color="dark">Total</MDTypography>
					},
					{
						Header: "komisi",
						accessor: "commision",
						width: "15%",
						Footer: (
							<MDTypography variant="button" fontWeight="bold" color="dark">
								{fnumber(tfoot.totalFee)}
							</MDTypography>
						)
					},
					{
						Header: "harga",
						accessor: "price",
						width: "15%",
						Footer: (
							<MDTypography variant="button" fontWeight="bold" color="dark">
								{fnumber(tfoot.totalPrice)}
							</MDTypography>
						)
					},
					{ Header: "status", accessor: "status", width: "15%" }
				]

				this.setState({
					totalPages: data.totalPages,
					totalData: data.totalData,
					rows: output,
					isLoading: false,
					tableHead
				})
			})
			.catch(err => {
				console.log(err)
				this.setState({ isLoading: false })
			})
	}

	render() {
		const user = secureStorage.getItem('user')
		const view = this.props.view

		const title = view === "umroh" ? "Daftar Paket Umroh" : view === "haji" ? "Daftar Paket Haji Plus" : "Daftar Paket Haji Furoda"

		return (
			<MDBox pb={3} my={3}>
				<Card>
					<MDBox p={2} lineHeight={1}>
						<MDTypography variant="h5" fontWeight="medium">
							{title}
						</MDTypography>
					</MDBox>

					<MDBox px={2} width="100%" display="flex" justifyContent="flex-start">
						<Grid container spacing={3}>
							<Grid item xs={12} md={3} lg={3}>
								<MDInput
									label="Search..."
									size="small"
									fullWidth
									value={this.state.keyword}
									onKeyPress={(e) => {
										if (e.key === "Enter") {
											this.loadData({
												currentPage: 1,
												keyword: e.target.value,
												merchantId: this.state.merchant ? this.state.merchant.id : null
											})
										}
									}}
									onChange={(e) => this.setState({ keyword: e.target.value })}
								/>
							</Grid>
							{/* Merchant */}
							{[1, 4].includes(user.roleId) && (
								<Grid item xs={12} md={3} lg={3}>
									<Autocomplete
										value={this.state.merchant}
										options={this.state.merchants}
										onChange={(e, value) => {
											this.setState({ merchant: value }, () => {
												this.loadData({
													currentPage: 1,
													merchantId: value ? value.id : null,
												})
											})
										}}
										sx={{
											".MuiAutocomplete-input": {
												padding: "7.5px 5px 7.5px 8px !important"
											},
											".MuiOutlinedInput-root": {
												padding: "1.5px !important"
											}
										}}
										isOptionEqualToValue={(option, value) => option.id === value.id}
										renderInput={(params) => <MDInput sx={{ padding: "0px" }} fullWidth label="Pilih Biro Travel" {...params} />}
									/>
								</Grid>
							)}
						</Grid>
					</MDBox>

					<MDBox p={2}>
						<DataTable
							isLoading={this.state.isLoading}
							tableHead={this.state.tableHead}
							tableData={this.state.rows}
							tableFoot={true}
						/>
						<Pagination
							totalPages={this.state.totalPages}
							totalData={this.state.totalData}
							currentPage={this.state.currentPage}
							rowsPerPage={[10, 25, 50, "All"]}
							totalButton={3}
							defaultRowsPerPage={this.state.rowsPerPage}
							onChangeRowsPerPage={(value) => {
								this.setState({ rowsPerPage: value, currentPage: 1 })
								this.loadData({
									rowsPerPage: value,
									currentPage: 1,
									merchantId: this.state.merchant ? this.state.merchant.id : null
								})
							}}
							onChangePage={(currentPage) => {
								if (currentPage !== this.state.currentPage) {
									this.setState({ currentPage })
									this.loadData({
										currentPage,
										merchantId: this.state.merchant ? this.state.merchant.id : null
									})
								}
							}}
						/>
					</MDBox>
				</Card>
			</MDBox>
		)
	}
}

PackageAgent.propTypes = {
	view: PropTypes.oneOf(['umroh', 'haji', 'furoda']).isRequired
}

export default PackageAgent