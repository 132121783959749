import React, { useState } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDDatePicker from "components/MDDatePicker";
import MDTypography from "components/MDTypography";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import TableRow from "@mui/material/TableRow";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { fnumber } from "libs/currency"
import useAxios from "libs/useAxios";
import Config from "config";
import secureStorage from "libs/secureStorage";
import moment from "moment"

import print from "helper/printer"

class ReportOwner extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			startDate: null,
			endDate: null,
			merchant: null,

			merchants: [],

			trxCount: 0,
			outFund: 0,
			incomeFund: 0,
			refundIncome: 0,
			manualOutFund: 0,
			totalAmount: 0,

			disabledSubmit: false,
		}
	}

	componentDidMount() {
		const startDate = moment().startOf("year").format("YYYY-MM-DD")
		const endDate = moment().endOf("year").format("YYYY-MM-DD")

		this.loadMerchants()
		this.setState({ startDate, endDate }, () => {
			this.loadData({ startDate, endDate })
		})
	}

	loadMerchants = () => {
		useAxios()
			.post(`${Config.ApiUrl}/manage/merchant/dropdown`)
			.then((response) => {
				const data = response.data

				this.setState({
					merchants: data.map((item) => ({ id: item.id, label: item.name })),
				})
			})
			.catch((err) => {
				console.log(err)
			})
	}

	handlePrint = () => {
		const payload = {
			startDate: this.state.startDate,
			endDate: this.state.endDate,
			merchant: this.state.merchant,

			trxCount: this.state.trxCount,
			incomeFund: this.state.incomeFund,
			outFund: this.state.outFund,
			manualOutFund: this.state.manualOutFund,
			totalAmount: this.state.totalAmount
		}

		print(payload, 'PdfFinancialOwner')
	}

	loadData = (params) => {
		this.setState({ disabledSubmit: true })
		const merchantId = params && params.merchantId ? { merchantId: params.merchantId } : {}

		const payload = {
			startDate: params && params.startDate ? params.startDate : this.state.startDate,
			endDate: params && params.endDate ? params.endDate : this.state.endDate,
			...merchantId
		}

		console.log({ payload })

		useAxios()
			.post(`${Config.ApiUrl}/finance/report/owner`, payload)
			.then(response => {
				const data = response.data
				// console.log({ data })

				this.setState({
					trxCount: data.trxCount,

					incomeFund: data.incomeFund,
					outFund: data.outFund,
					manualOutFund: data.manualOutFund,
					totalAmount: data.totalAmount,
					disabledSubmit: false
				})
			})
			.catch(err => {
				console.log(err)
				this.setState({ disabledSubmit: false })
			})
	}

	render() {
		return (
			<MDBox pb={3} my={3}>
				<Grid container spacing={1} justifyContent="center" alignItems="flex-start" height="100%">
					<Grid item xs={12} sm={12} md={12} lg={12} xl={10} xxl={10}>
						<Card>
							{/* Filter */}
							<MDBox p={5} width="100%" display="flex" justifyContent="flex-start">
								<Grid container spacing={1} justifyContent="flex-start">
									{/* Merchant */}
									<Grid item xs={12} md={3} lg={3}>
										<Autocomplete
											value={this.state.merchant}
											options={this.state.merchants}
											onChange={(e, value) => {
												this.setState({ merchant: value }, () => {
													this.loadData({
														currentPage: 1,
														merchantId: value ? value.id : null,
													});
												});
											}}
											sx={{
												".MuiAutocomplete-input": {
													padding: "7.5px 5px 7.5px 8px !important",
												},
												".MuiOutlinedInput-root": {
													padding: "1.5px !important",
												},
											}}
											isOptionEqualToValue={(option, value) => option.id === value.id}
											renderInput={(params) => (
												<MDInput
													sx={{ padding: "0px" }}
													fullWidth
													label="Pilih Biro Travel"
													{...params}
												/>
											)}
										/>
									</Grid>
									{/* Start Date */}
									<Grid item xs={12} md={2} lg={2}>
										<MDDatePicker
											input={{
												id: "startDate",
												placeholder: "Tanggal Awal",
												fullWidth: true,
												sx: {
													".MuiInputBase-input": {
														height: "1em !important",
													},
												},
											}}
											value={this.state.startDate}
											onChange={(value) => {
												const date = moment(value[0]).format("YYYY-MM-DD");
												this.setState({ startDate: date });
											}}
										/>
									</Grid>
									{/* End Date */}
									<Grid item xs={12} md={2} lg={2}>
										<MDDatePicker
											input={{
												id: "endDate",
												placeholder: "Tanggal Akhir",
												fullWidth: true,
												sx: {
													".MuiInputBase-input": {
														height: "1em !important",
													},
												},
											}}
											value={this.state.endDate}
											onChange={(value) => {
												const date = moment(value[0]).format("YYYY-MM-DD");
												this.setState({ endDate: date });
											}}
										/>
									</Grid>
									{/* Button Search */}
									<Grid item xs={12} md={2} lg={2}>
										<MDBox
											width="100%"
											display="flex"
											flexDirection="row"
											justifyContent="flex-start"
										>
											<MDButton
												color="info"
												variant="gradient"
												disabled={this.state.disabledSubmit}
												onClick={() => {
													const startDate = this.state.startDate ? this.state.startDate : "";
													const endDate = this.state.endDate ? this.state.endDate : "";
													this.loadData({
														startDate,
														endDate,
														merchantId: this.state.merchant ? this.state.merchant.id : null,
													});
												}}
												iconOnly
											>
												<Icon>search</Icon>
											</MDButton>
											<MDBox ml={1}>
												<MDButton
													color="error"
													variant="gradient"
													onClick={() => {
														const startDate = moment().startOf("year").format("YYYY-MM-DD")
														const endDate = moment().endOf("year").format("YYYY-MM-DD")
														this.setState({
															startDate,
															endDate,
															currentPage: 1,
															merchant: null
														}, () => {
															this.loadData()
														})
													}}
													iconOnly
												>
													<Icon>refresh</Icon>
												</MDButton>
											</MDBox>
										</MDBox>
									</Grid>
									{/* Button Print */}
									<Grid item xs={12} md={2} lg={2}>
										<MDButton
											color="info"
											variant="gradient"
											onClick={() => {
												this.handlePrint();
											}}
										>
											<Icon>print</Icon> Cetak
										</MDButton>
									</Grid>
								</Grid>
							</MDBox>

							{/* Content */}
							<MDBox p={5}>
								<Grid container justifyContent="center">
									<Grid item xs={12} md={12} lg={10} px={3}>
										<TableContainer sx={{ padding: "20px" }}>
											{/* TITLE */}
											<MDBox p={2}>
												<MDTypography variant="h4" align="center" fontWeight="medium">
													LAPORAN KEUANGAN
												</MDTypography>
												<MDTypography variant="h6" align="center">
													MABRUR
												</MDTypography>
												<MDTypography variant="h6" align="center" fontWeight="medium">
													Dari{" "}
													{this.state.startDate !== ""
														? this.state.startDate
														: moment().startOf("year").format("YY-MM-DD")}{" "}
													sampai{" "}
													{this.state.endDate !== ""
														? this.state.endDate
														: moment().endOf("year").format("YY-MM-DD")}
												</MDTypography>
											</MDBox>
											<Table>
												<TableBody>
													<TableRow>
														<DataTableBodyCell noBorder>
															<MDTypography variant="body" fontWeight="medium">
																Rincian
															</MDTypography>
														</DataTableBodyCell>
														{/* <DataTableBodyCell noBorder></DataTableBodyCell>

															<DataTableBodyCell noBorder></DataTableBodyCell>
															<DataTableBodyCell noBorder></DataTableBodyCell> */}
													</TableRow>

													<TableRow>
														<DataTableBodyCell noBorder>
															<MDTypography variant="body" p={10}>
																Dana Masuk
															</MDTypography>
														</DataTableBodyCell>

														<DataTableBodyCell noBorder>
															<MDTypography variant="body">
																{fnumber(this.state.incomeFund)}
															</MDTypography>
														</DataTableBodyCell>
													</TableRow>
													<TableRow>
														<DataTableBodyCell noBorder>
															<MDTypography variant="body" p={10}>
																Dana Keluar
															</MDTypography>
														</DataTableBodyCell>

														<DataTableBodyCell noBorder>
															<MDTypography variant="body">
																{fnumber(this.state.outFund)}
															</MDTypography>
														</DataTableBodyCell>
													</TableRow>
													<TableRow>
														<DataTableBodyCell>
															<MDTypography variant="body" p={10}>
																Dana Keluar Manual
															</MDTypography>
														</DataTableBodyCell>

														<DataTableBodyCell>
															<MDTypography variant="body">
																{fnumber(this.state.manualOutFund)}
															</MDTypography>
														</DataTableBodyCell>
													</TableRow>

													<TableRow>
														<DataTableBodyCell>
															<MDTypography variant="body" fontWeight="medium">
																Total Transaksi
															</MDTypography>
														</DataTableBodyCell>
														<DataTableBodyCell>
															<MDTypography variant="body" fontWeight="medium">
																{fnumber(this.state.trxCount)}
															</MDTypography>
														</DataTableBodyCell>
													</TableRow>
													<TableRow>
														<DataTableBodyCell>
															<MDTypography variant="body" fontWeight="medium">
																Total Pendapatan
															</MDTypography>
														</DataTableBodyCell>
														<DataTableBodyCell>
															<MDTypography variant="body" fontWeight="medium">
																{fnumber(this.state.totalAmount)}
															</MDTypography>
														</DataTableBodyCell>
													</TableRow>
												</TableBody>
											</Table>
										</TableContainer>
									</Grid>
								</Grid>
							</MDBox>
						</Card>
					</Grid>
				</Grid>
			</MDBox>
		)
	}
}

export default ReportOwner