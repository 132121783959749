import { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import Grid from "@mui/material/Grid"
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from '@mui/material/Divider'

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

import Config from "config"
import useAxios from "libs/useAxios";
import secureStorage from "libs/secureStorage";

import Confirm from "contents/Components/Confirm"
import ModalNotif from "contents/Components/ModalNotif";
import DialogForm from "contents/Components/DialogForm";

function ButtonAction({ id, userId, isActive, refreshData }) {
	const confirmRef = useRef()
	const modalNotifRef = useRef()
	const dialogFormRef = useRef()

	const navigate = useNavigate()
	const [menu, setMenu] = useState(null);
	const [user, setUser] = useState(null)

	const [password, setPassword] = useState("")
	const [repassword, setRepassword] = useState("")

	const [disabledSubmit, setDisabledSubmit] = useState(false)

	useEffect(() => {
		setUser(secureStorage.getItem('user'))
	}, [])

	const openMenu = (event) => setMenu(event.currentTarget);
	const closeMenu = () => setMenu(null);

	const handleDirect = (url) => {
		closeMenu()
		navigate(url)
	}

	const handleDelete = () => {
		closeMenu()
		console.log('[DELETE]')
		confirmRef.current.setShow({
			title: 'Konfirmasi',
			message: "Apakah anda yang ini menghapus data ini ?",
			onAction: () => {
				sumbitDel()
			}
		})
	}

	const sumbitDel = () => {
		useAxios()
			.post(`${Config.ApiUrl}/manage/agent/delete`, { id })
			.then(response => {
				modalNotifRef.current.setShow({
					modalTitle: "Sukses",
					modalMessage: response.data,
					onClose: () => {
						console.log('[REFRESH]')
						refreshData()
					}
				})
			})
			.catch(err => {
				modalNotifRef.current.setShow({
					modalTitle: 'Gagal',
					modalMessage: "Koneksi jaringan terputus",
				})
			})
	}

	const handleDialogPass = () => {
		dialogFormRef.current.setShow({ show: true, title: "Reset Password" })
	}

	const handleStatus = (status) => {
		closeMenu()
		confirmRef.current.setShow({
			title: 'Konfirmasi',
			message: `Apakah anda yakin ingin ${status ? 'mengaktifkan' : 'menmblokir'} akun ini ?`,
			onAction: () => {
				submitStatus(status)
			}
		})
	}

	const submitStatus = (status) => {
		useAxios()
			.post(`${Config.ApiUrl}/manage/agent/setstatus`, { id, userId, isActive: status })
			.then(response => {
				modalNotifRef.current.setShow({
					modalTitle: "Sukses",
					modalMessage: response.data,
					onClose: () => {
						refreshData()
					}
				})
			})
			.catch(err => {
				modalNotifRef.current.setShow({
					modalTitle: 'Gagal',
					modalMessage: "Koneksi jaringan terputus",
				})
			})
	}

	const handleResetPass = () => {
		if (repassword !== password) {
			modalNotifRef.current.setShow({
				modalTitle: 'Peringatan',
				modalMessage: "Konfirmasi password tidak sama",
			})
		}
		else {
			setDisabledSubmit(true)
			const payload = { password, userId }
			useAxios()
				.post(`${Config.ApiUrl}/user/reset/change`, payload)
				.then(response => {
					modalNotifRef.current.setShow({
						modalTitle: "Sukses",
						modalMessage: response.data,
						onClose: () => {
							setDisabledSubmit(false)
							setPassword("")
							setRepassword("")
							refreshData()
							dialogFormRef.current.setShow({ show: false, title: "" })
						}
					})
				})
				.catch(err => {
					console.log(err)
					setDisabledSubmit(false)
					modalNotifRef.current.setShow({
						modalTitle: "Gagal",
						modalMessage: err.response ? err.response.data : "Terjadi kesalahan pada system",
						onClose: () => {
							setDisabledSubmit(false)
							setPassword("")
							setRepassword("")
							dialogFormRef.current.setShow({ show: false, title: "" })
						}
					})
				})
		}
	}

	const renderMenu = (
		<Menu
			anchorEl={menu}
			anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
			transformOrigin={{ vertical: "top", horizontal: "left" }}
			open={Boolean(menu)}
			onClose={closeMenu}
			keepMounted
		>
			<MenuItem onClick={() => handleDirect(`/manage/agent/detail/${id}`)}>Detail</MenuItem>
			{user && [1].includes(user.roleId) && <MenuItem onClick={() => handleDirect(`/manage/agent/edit/${id}`)}>Edit</MenuItem>}
			{user && [1].includes(user.roleId) && (<MenuItem onClick={handleDelete}>Delete</MenuItem>)}
			<Divider />
			{user && [1].includes(user.roleId) && (
				<MenuItem onClick={() => handleStatus(!isActive)}>{!isActive ? 'Aktifkan' : 'Nonaktifkan'}</MenuItem>
			)}
			{user && [1].includes(user.roleId) && <MenuItem onClick={handleDialogPass}>Reset Password</MenuItem>}
		</Menu>
	);

	return (
		<MDBox display="flex">
			<Confirm ref={confirmRef} />
			<ModalNotif ref={modalNotifRef} />
			{/* Dialog Reset Password */}
			<DialogForm ref={dialogFormRef} maxWidth="xs">
				<Grid container item xs={12} lg={12} sx={{ mx: "auto" }} mt={3}>
					<MDBox width="100%" component="form">
						<MDBox mb={2}>
							<MDInput
								fullWidth
								type="password"
								value={password}
								onChange={(e) => setPassword(e.target.value)}
								label="Password"
							/>
						</MDBox>
						<MDBox mb={2}>
							<MDInput
								fullWidth
								type="password"
								value={repassword}
								onChange={(e) => setRepassword(e.target.value)}
								label="Komfirmasi Password"
							/>
						</MDBox>
					</MDBox>
					<MDBox py={3} width="100%" display="flex" justifyContent={{ md: "flex-end", xs: "center" }}>
						<MDBox mr={1}>
							<MDButton
								variant="gradient"
								color="error"
								onClick={() => dialogFormRef.current.setShow({ show: false, title: "" })}
							>
								Tutup
							</MDButton>
						</MDBox>
						<MDButton
							variant="gradient"
							color="info"
							disabled={disabledSubmit}
							onClick={handleResetPass}
						>
							Submit
						</MDButton>
					</MDBox>
				</Grid>
			</DialogForm>

			<MDButton
				variant="contained"
				color="info"
				size="small"
				onClick={openMenu}
			>
				actions&nbsp;
				<Icon>keyboard_arrow_down</Icon>
			</MDButton>
			{renderMenu}
		</MDBox>
	)
}

ButtonAction.propTypes = {
	id: PropTypes.number.isRequired,
	userId: PropTypes.number.isRequired,
	refreshData: PropTypes.func,
	isActive: PropTypes.bool
}

export default ButtonAction