/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { Link, Navigate } from "react-router-dom";
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";

// @mui icons
import { Close } from "@mui/icons-material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

import ModalNotif from "contents/Components/ModalNotif";
import DialogForm from "contents/Components/DialogForm";

import Config from "config";
import _, { find } from "lodash";

import useAxios from "libs/useAxios";
import secureStorage from "libs/secureStorage";

class DialogStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      statusId: null,
      memberId: null,
      memberName: "",
      memberAddress: "",
      status: null,
      ktpFilename: "",
      ktp: null,
      kkFilename: "",
      kk: null,
      passportFilename: "",
      passport: null,
      photoFilename: "",
      photo: null,
      visaFilename: "",
      visa: null,
      remark: "",

      isRemark: false,

      statuses: [],

      disabledSubmit: false,
    };

    this.modalNotifRef = React.createRef();
    this.dialogFormRef = React.createRef();
    this.inputRef = React.createRef();

    this.fileKTPRef = React.createRef();
    this.fileKKRef = React.createRef();
    this.filePassportRef = React.createRef();
    this.filePhotoRef = React.createRef();
    this.fileVisaRef = React.createRef();
  }

  componentDidMount() {}

  handleChange = (e) => {
    const { id, value } = e.target;
    this.setState({
      [id]: value,
    });
  };

  setShow = (data) => {
    const { statusId, memberId, departureDate, memberName, memberAddress } = data;

    delete data?.memberId;
    delete data?.statusId;
    delete data?.memberName;
    delete data?.memberAddress;
    this.setState(
      {
        departureDate,
        memberId,
        memberName,
        memberAddress,
        statusId,
      },
      () => {
        this.dialogFormRef.current.setShow({ ...data, title: "Ubah Dokument Kelangkapan" });
      }
    );
  };

  resetForm = () => {
    this.setState({
      ktpFilename: "",
      ktp: null,
      kkFilename: "",
      kk: null,
      passportFilename: "",
      passport: null,
      photoFilename: "",
      photo: null,
      visaFilename: "",
      visa: null,
      remark: "",
    });
  };

  handleSubmit = () => {
    this.setState({ disabledSubmit: true });
    const formData = new FormData();

    formData.append("ktp", this.state.ktp);
    formData.append("kk", this.state.kk);
    formData.append("passport", this.state.passport);
    formData.append("photo", this.state.photo);
    formData.append("visa", this.state.visa);
    formData.append("remark", this.state.remark);
    formData.append("userId", this.state.memberId);

    useAxios()
      .post(`${Config.ApiUrl}/user/doc`, formData)
      .then((response) => {
        this.modalNotifRef.current.setShow({
          modalTitle: "Sukses",
          modalMessage: response.data,
          onClose: () => {
            this.resetForm();
            this.setState({
              redirect: "/adm/document",
              disabledSubmit: false,
            });
            this.props.onAction();
          },
        });
        this.dialogFormRef.current.setShow({ show: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ disabledSubmit: false });
        if (err.response) {
          this.modalNotifRef.current.setShow({
            modalTitle: "Gagal",
            modalMessage: err.response ? err.response.data : "Server Internal Error",
          });
        }
        // eslint-disable-next-line no-empty
        else {
          this.modalNotifRef.current.setShow({
            modalTitle: "Gagal",
            modalMessage: "Koneksi jaringan terputus",
          });
        }
      });
  };

  render() {
    return (
      <DialogForm ref={this.dialogFormRef} maxWidth="xs">
        <ModalNotif ref={this.modalNotifRef} />
        <Grid container item xs={12} lg={12} sx={{ mx: "auto", mt: 1 }}>
          <MDBox width="100%" component="form">
            <MDBox mb={2}>
              <MDInput type="text" label="Nama" value={this.state.memberName} disabled fullWidth />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Alamat"
                value={this.state.memberAddress}
                disabled
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <input
                type="file"
                name="ktp"
                ref={this.fileKTPRef}
                onChange={(e) => {
                  if (e.target.files.length === 1) {
                    const file = e.target.files[0];
                    const filename = file.name;
                    const ext = filename.split(".")[1];
                    this.setState({
                      ktp: file,
                      ktpFilename: filename,
                    });
                  }
                }}
                hidden
              />
              <MDInput
                fullWidth
                value={this.state.ktpFilename}
                label="Upload KTP"
                onClick={() => {
                  this.fileKTPRef.current.click();
                }}
                readOnly
              />
            </MDBox>
            <MDBox mb={2}>
              <input
                type="file"
                name="kk"
                ref={this.fileKKRef}
                onChange={(e) => {
                  if (e.target.files.length === 1) {
                    const file = e.target.files[0];
                    const filename = file.name;
                    const ext = filename.split(".")[1];
                    this.setState({
                      kk: file,
                      kkFilename: filename,
                    });
                  }
                }}
                hidden
              />
              <MDInput
                fullWidth
                value={this.state.kkFilename}
                label="Upload KK"
                onClick={() => {
                  this.fileKKRef.current.click();
                }}
                readOnly
              />
            </MDBox>
            <MDBox mb={2}>
              <input
                type="file"
                name="passport"
                ref={this.filePassportRef}
                onChange={(e) => {
                  if (e.target.files.length === 1) {
                    const file = e.target.files[0];
                    const filename = file.name;
                    const ext = filename.split(".")[1];
                    this.setState({
                      passport: file,
                      passportFilename: filename,
                    });
                  }
                }}
                hidden
              />
              <MDInput
                fullWidth
                value={this.state.passportFilename}
                label="Upload Passport"
                onClick={() => {
                  this.filePassportRef.current.click();
                }}
                readOnly
              />
            </MDBox>
            <MDBox mb={2}>
              <input
                type="file"
                name="photo"
                ref={this.filePhotoRef}
                onChange={(e) => {
                  if (e.target.files.length === 1) {
                    const file = e.target.files[0];
                    const filename = file.name;
                    const ext = filename.split(".")[1];
                    this.setState({
                      photo: file,
                      photoFilename: filename,
                    });
                  }
                }}
                hidden
              />
              <MDInput
                fullWidth
                value={this.state.photoFilename}
                label="Upload Foto"
                onClick={() => {
                  this.filePhotoRef.current.click();
                }}
                readOnly
              />
            </MDBox>
            <MDBox mb={2}>
              <input
                type="file"
                name="visa"
                ref={this.fileVisaRef}
                onChange={(e) => {
                  if (e.target.files.length === 1) {
                    const file = e.target.files[0];
                    const filename = file.name;
                    const ext = filename.split(".")[1];
                    this.setState({
                      visa: file,
                      visaFilename: filename,
                    });
                  }
                }}
                hidden
              />
              <MDInput
                fullWidth
                value={this.state.visaFilename}
                label="Upload Visa"
                onClick={() => {
                  this.fileVisaRef.current.click();
                }}
                readOnly
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                rows={3}
                type="text"
                label="Catatan"
                id="remark"
                multiline
                value={this.state.remark}
                onChange={this.handleChange}
                fullWidth
              />
            </MDBox>
          </MDBox>

          <MDBox
            py={3}
            width="100%"
            display="flex"
            justifyContent={{ md: "flex-end", xs: "center" }}
          >
            <MDBox mr={1}>
              <MDButton
                variant="gradient"
                color="error"
                onClick={() => {
                  this.resetForm();
                  this.dialogFormRef.current.setShow({ show: false });
                }}
              >
                Tutup
              </MDButton>
            </MDBox>
            <MDButton
              variant="gradient"
              color="info"
              disabled={this.state.disabledSubmit}
              onClick={this.handleSubmit}
            >
              Submit
            </MDButton>
          </MDBox>
        </Grid>
      </DialogForm>
    );
  }
}

DialogStatus.propTypes = {
  onAction: PropTypes.func.isRequired,
};

export default DialogStatus;
