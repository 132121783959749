import { useState, useEffect } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import secureStorage from "libs/secureStorage";

import BasicInfo from "contents/Setting/Account/components/BasicInfo";
import ChangePassword from "contents/Setting/Account/components/ChangePassword"
import CardIdentity from "contents/Setting/Account/components/CardIdentity"
import SocialMedia from "contents/Setting/Account/components/SocialMedia"
import AgenBasicInfo from "contents/Setting/Account/components/BasicInfo/Agen"
import MerchantBasic from "contents/Setting/Account/components/BasicInfo/Merchant"

function TabLayout() {
	const [tabsOrientation, setTabsOrientation] = useState("horizontal");
	const [activeTab, setActiveTab] = useState(0);
	const [user, setUser] = useState(null)

	useEffect(() => {
		setUser(secureStorage.getItem('user'))
	}, [])

	const handleSetTabValue = (event, newValue) => setActiveTab(newValue);

	return (
		<>
			<MDBox my={3} >
				<Grid container spacing={3}>
					<Grid item xs={12} sm={12} lg={12}>
						<AppBar position="static">
							<Tabs orientation={tabsOrientation} value={activeTab} onChange={handleSetTabValue}>
								<Tab label="Basic Info" />
								<Tab label="Ganti Password" />
								{/* {user && [5].includes(user.roleId) && <Tab label="Paspor" />}
								{user && [5].includes(user.roleId) && <Tab label="Visa" />} */}
								{user && [1].includes(user.roleId) && <Tab label="Media Sosial" />}
							</Tabs>
						</AppBar>
					</Grid>
				</Grid>
			</MDBox>

			{activeTab === 0 && (user && [4].includes(user.roleId) ? <AgenBasicInfo /> : (user && [2].includes(user.roleId) ? <MerchantBasic /> : <BasicInfo />))}
			{activeTab === 1 && <ChangePassword />}
			{/* {activeTab === 2 && (user && [5].includes(user.roleId) ? <CardIdentity title={"Data Paspor"} view={"passport"} /> : <SocialMedia />)}
			{activeTab === 3 && user && [5].includes(user.roleId) && <CardIdentity title={"Data Visa"} view={"visa"} />} */}
			{activeTab === 4 && user && [1].includes(user.roleId) && <SocialMedia />}
		</>
	)
}

export default TabLayout