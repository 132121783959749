import React, { useState } from "react";
import PropTypes from "prop-types";
import { Navigate, Link } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import DataTable from 'contents/Components/DataTable'
import Pagination from "contents/Components/Pagination";
import ButtonAction from "contents/Manage/Merchant/components/ButtonAction"

import useAxios from "libs/useAxios"
import moment from 'moment'
import Config from 'config'
import secureStorage from "libs/secureStorage";
import MDBadge from "components/MDBadge";

class Merchant extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			user: null,
			status: null,
			province: null,

			provinces: [],
			statuses: [{ id: 1, label: 'Aktif' }, { id: 2, label: 'Tidak Aktif' }],

			rowsPerPage: 10,
			currentPage: 1,
			totalPages: 0,
			totalData: 0,
			tableHead: [
				{ Header: "action", accessor: "action", width: "10%" },
				{ Header: "nama", accessor: "name", width: "25%" },
				{ Header: "kontak & alamat ", accessor: "address", width: "30%" },
				{ Header: "status", accessor: "status", width: "5%" },
			],
			rows: [],
			keyword: "",
			isLoading: true,
		}
	}

	componentDidMount() {
		const user = secureStorage.getItem('user')
		this.setState({ user })
		this.loadData()
		this.loadProvince()
	}

	loadData = (params) => {
		const user = secureStorage.getItem('user')
		this.setState({ isLoading: true })

		const provinceId = params && params.provinceId ? { provinceId: params.provinceId } : {}
		const statusId = params && params.statusId ? { statusId: params.statusId } : {}
		const payload = {
			keyword: params && params.keyword ? params.keyword : this.state.keyword,
			currentPage: params && params.currentPage ? params.currentPage : 1,
			rowsPerPage: params && params.rowsPerPage ? params.rowsPerPage : this.state.rowsPerPage,
			...provinceId,
			...statusId,
		};

		useAxios()
			.post(`${Config.ApiUrl}/manage/merchant`, payload)
			.then(response => {
				const data = response.data

				const output = data.output.map(item => {
					const userId = item.Users && item.Users.length > 0 ? item.Users[0].id : null
					const adminNominal = item && item.AdminNominal
					const province = item && item.Province
					const district = item && item.District
					let state = district && district.name ? `, ${district.name.toUpperCase()}, ` : ''
					state += province && province.name ? province.name.toUpperCase() : ''

					return {
						name: (
							<MDBox style={{ wordWrap: "break-word", width: "18em" }}>
								<MDBox display="flex" flexDirection="column">
									<MDTypography variant="caption" fontWeight="regular">No. Registrasi : {item.kode}</MDTypography>
									<MDTypography variant="caption" fontWeight="regular">Nama : {item.name}</MDTypography>
									<MDTypography variant="caption" fontWeight="regular">Nama Brand : {item.brand}</MDTypography>
								</MDBox>
							</MDBox>
						),
						address: (
							<MDBox style={{ wordWrap: "break-word", width: "25em" }}>
								<MDBox display="flex" flexDirection="column">
									<MDTypography variant="caption" fontWeight="regular">{item.address}{state}</MDTypography>
									<MDTypography mt={1} variant="caption" fontWeight="regular">Telpon : {item.phone}</MDTypography>
									<MDTypography variant="caption" fontWeight="regular">Email : {item.email}</MDTypography>
								</MDBox>
							</MDBox>
						),
						status: <MDBadge
							variant="contained"
							badgeContent={item.isActive ? "Aktif" : "Tidak Aktif"}
							size="lg"
							color={item.isActive ? "success" : "warning"}
						/>,
						action: (
							<ButtonAction
								id={item.id}
								isActive={item.isActive}
								admin={adminNominal && adminNominal.nominal ? adminNominal.nominal : 0}
								userId={userId}
								refreshData={this.loadData}
							/>
						)
					}
				})

				this.setState({
					isLoading: false,
					totalPages: data.totalPages,
					totalData: data.totalData,
					rows: output
				})
			})
			.catch(err => {
				console.log(err)
				this.setState({ isLoading: false })
			})
	}

	loadProvince = () => {
		useAxios()
			.post(`${Config.ApiUrl}/master/region/province/dropdown`, { countryId: 1 })
			.then(response => {
				const data = response.data

				this.setState({
					provinces: data.map(item => ({ id: item.id, label: item.name }))
				})
			})
			.catch(err => {
				console.log(err)
			})
	}

	render() {
		return (
			<MDBox pb={3} my={3}>
				<Card>
					<MDBox p={2} lineHeight={1}>
						<MDTypography variant="h5" fontWeight="medium">
							Daftar Biro Travel
						</MDTypography>
					</MDBox>

					<MDBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
						<Grid container spacing={3}>
							{/* Form Search */}
							<Grid item xs={12} md={3} lg={3}>
								<MDInput
									label="Search..."
									size="small"
									fullWidth
									value={this.state.keyword}
									onKeyPress={(e) => {
										if (e.key === "Enter") {
											this.loadData({
												keyword: e.target.value,
												currentPage: 1,
												statusId: this.state.status ? this.state.status?.id : null,
												provinceId: this.state.province ? this.state.province?.id : null,
											})
										}
									}}
									onChange={(e) => this.setState({ keyword: e.target.value })}
								/>
							</Grid>
							{/* Filter Province */}
							<Grid item xs={12} md={3} lg={3}>
								<Autocomplete
									value={this.state.province}
									options={this.state.provinces}
									onChange={(e, value) => {
										this.setState({ province: value })
										this.loadData({
											currentPage: 1,
											provinceId: value ? value.id : null,
											statusId: this.state.status ? this.state.status?.id : null
										})

									}}
									sx={{
										".MuiAutocomplete-input": {
											padding: "7.5px 5px 7.5px 8px !important"
										},
										".MuiOutlinedInput-root": {
											padding: "1.5px !important"
										}
									}}
									isOptionEqualToValue={(option, value) => option.id === value.id}
									renderInput={(params) => <MDInput sx={{ padding: "0px" }} fullWidth label="Pilih Provinsi" {...params} />}
								/>
							</Grid>
							{/* Filter Status */}
							<Grid item xs={12} md={3} lg={3}>
								<Autocomplete
									value={this.state.status}
									options={this.state.statuses}
									onChange={(e, value) => {
										this.setState({ status: value })
										this.loadData({
											currentPage: 1,
											statusId: value ? value.id : null,
											provinceId: this.state.province ? this.state.province?.id : null
										})
									}}
									sx={{
										".MuiAutocomplete-input": {
											padding: "7.5px 5px 7.5px 8px !important"
										},
										".MuiOutlinedInput-root": {
											padding: "1.5px !important"
										}
									}}
									isOptionEqualToValue={(option, value) => option.id === value.id}
									renderInput={(params) => <MDInput sx={{ padding: "0px" }} fullWidth label="Pilih Status" {...params} />}
								/>
							</Grid>
						</Grid>
					</MDBox>

					<MDBox p={2}>
						<DataTable
							isLoading={this.state.isLoading}
							tableHead={this.state.tableHead}
							tableData={this.state.rows}
						/>
						<Pagination
							totalPages={this.state.totalPages}
							totalData={this.state.totalData}
							currentPage={this.state.currentPage}
							rowsPerPage={[10, 25, 50, "All"]}
							totalButton={3}
							defaultRowsPerPage={this.state.rowsPerPage}
							onChangeRowsPerPage={(value) => {
								this.setState({ rowsPerPage: value, currentPage: 1 });
								this.loadData({
									rowsPerPage: value,
									currentPage: 1,
									statusId: this.state.status ? this.state.status?.id : null,
									provinceId: this.state.province ? this.state.province?.id : null
								});
							}}
							onChangePage={(currentPage) => {
								if (currentPage !== this.state.currentPage) {
									this.setState({ currentPage });
									this.loadData({
										currentPage,
										statusId: this.state.status ? this.state.status?.id : null,
										provinceId: this.state.province ? this.state.province?.id : null
									});
								}
							}}
						/>
					</MDBox>
				</Card>
			</MDBox>
		)
	}
}

export default Merchant