/* eslint-disable react/prop-types */
import React from 'react'
import { Link, Navigate } from 'react-router-dom';
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDBadge from "components/MDBadge";
import MDTypography from "components/MDTypography";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

// Material Dashboard 2 PRO React examples
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";

import withRouter from 'contents/Components/withRouter'
import Loader from 'contents/Components/Loader';
import ButtonBack from 'contents/Components/ButtonBack'
import ModalNotif from 'contents/Components/ModalNotif'

import useAxios from "libs/useAxios"
import Config from "config"
import secureStorage from 'libs/secureStorage';

class DetailDirector extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			id: null,
			isLoading: true,

			name: "",
			gender: "",
			placeBirth: "",
			dateBirth: "",
			phone: "",
			address: "",
			work: "",
			position: "",
			religion: null,
			province: null,
			district: null,
			statusPerson: null,
		}
	}

	componentDidMount() {
		const id = this.props.params?.id
		this.setState({ id }, () => {
			this.loadData(id)
		})
	}

	loadData = (id) => {
		useAxios()
			.post(`${Config.ApiUrl}/manage/directors/get`, { id })
			.then(response => {
				const data = response.data

				const religion = data.Religion
				const province = data.Province
				const district = data.District
				const statusPerson = data.StatusPerson

				this.setState({
					isLoading: false,
					name: data.name,
					phone: data.phone,
					gender: data.gender,
					placeBirth: data.placeBirth ?? "-",
					dateBirth: data.dateBirth ?? "-",
					address: data.address ?? "-",
					position: data.position ?? "-",
					work: data.work ?? "-",
					religion,
					province,
					district,
					statusPerson
				})
			})
			.catch(err => {
				console.log(err)
				this.modalNotifRef.current.setShow({
					modalTitle: 'Gagal',
					modalMessage: "Koneksi jaringan terputus",
				})
			})
	}

	render() {
		const user = secureStorage.getItem('user')

		return (
			<MDBox pb={3} my={3}>
				<ModalNotif ref={this.modalNotifRef} />
				<MDBox py={1} mb={2} display="flex">
					<MDBox mr={1}>
						<ButtonBack label={"KEMBALI"} />
					</MDBox>
					{user && [2, 3].includes(user.roleId) && (
						<MDButton
							variant="gradient"
							color="info"
							component={Link}
							to={{ pathname: `/manage/directors/edit/${this.state.id}` }}
						>
							UBAH DATA
						</MDButton>
					)}
				</MDBox>

				<Card>
					<MDBox my={2} lineHeight={1} width="100%" display="flex" justifyContent="center">
						<MDTypography variant="h5" fontWeight="medium">
							Detail Direksi
						</MDTypography>
					</MDBox>
					<MDBox p={2}>
						<Grid container spacing={3} item xs={12} md={12} lg={12} mb={5} pt={2}>
							<Table>
								<TableBody>
									{/* NAME && PHONE */}
									<TableRow>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body" fontWeight="medium">
												Nama
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body">
												{this.state.name ? this.state.name : "-"}
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body" fontWeight="medium">
												Nomor Telpon
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body">
												{this.state.phone ? this.state.phone : "-"}
											</MDTypography>
										</DataTableBodyCell>
									</TableRow>
									{/* PLACE & DATE BIRTH */}
									<TableRow>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body" fontWeight="medium">
												Tempat Lahir
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body">
												{this.state.placeBirth ? this.state.placeBirth : "-"}
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body" fontWeight="medium">
												Tanggal Lahir
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body">
												{this.state.dateBirth ? this.state.dateBirth : "-"}
											</MDTypography>
										</DataTableBodyCell>
									</TableRow>
									{/* GENDER && POSITION */}
									<TableRow>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body" fontWeight="medium">
												Jenis Kelamin
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body">
												{this.state.gender ? this.state.gender : "-"}
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body" fontWeight="medium">
												Jabatan
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body">
												{this.state.position ? this.state.position : "-"}
											</MDTypography>
										</DataTableBodyCell>
									</TableRow>
									{/* STATUS PERSON && ADDRESS */}
									<TableRow>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body" fontWeight="medium">
												Status
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body">
												{this.state.statusPerson ? this.state.statusPerson?.name : "-"}
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body" fontWeight="medium">
												Alamat
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body">
												<MDBox style={{ wordWrap: "break-word", width: "25em" }}>
													{this.state.address ? this.state.address : "-"}
												</MDBox>
											</MDTypography>
										</DataTableBodyCell>
									</TableRow>
									{/*  PROVINCE && DISTRICT*/}
									<TableRow>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body" fontWeight="medium">
												Provinsi
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body">
												{this.state.province && this.state.province.name ? this.state.province.name.toUpperCase() : "-"}
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body" fontWeight="medium">
												Kota/Kabupaten
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body">
												{this.state.district && this.state.district.name ? this.state.district.name.toUpperCase() : "-"}
											</MDTypography>
										</DataTableBodyCell>
									</TableRow>
									{/* Religion */}
									<TableRow>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body" fontWeight="medium">
												Agama
											</MDTypography>
										</DataTableBodyCell>
										<DataTableBodyCell noBorder>
											<MDTypography variant="body">
												{this.state.religion && this.state.religion.name ? this.state.religion.name.toUpperCase() : "-"}
											</MDTypography>
										</DataTableBodyCell>
									</TableRow>
								</TableBody>
							</Table>
						</Grid>
					</MDBox>
				</Card>
			</MDBox>
		)
	}
}

export default withRouter(DetailDirector)