import { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";

// @mui icons
import { Close } from '@mui/icons-material';

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import Confirm from "contents/Components/Confirm"
import ModalNotif from "contents/Components/ModalNotif";
import DialogForm from "contents/Components/DialogForm";

// Images
import productImage from "assets/images/products/product-6-min.jpg";

import useAxios from "libs/useAxios";
import Config from "config"
import secureStorage from "libs/secureStorage";
import { fnumber } from "libs/currency"
import print from "helper/printer"

function ButtonAction({ id, statusId, image, refreshData }) {
	const confirmRef = useRef()
	const modalNotifRef = useRef()
	const dialogFormRef = useRef()

	const navigate = useNavigate()
	const [menu, setMenu] = useState(null);
	const [roleId, setRoleId] = useState(null)

	useEffect(() => {
		const user = secureStorage.getItem('user')
		setRoleId(user.roleId)
	}, [])

	const openMenu = (event) => setMenu(event.currentTarget);
	const closeMenu = () => setMenu(null);

	const handleDetail = () => {
		closeMenu()
		navigate(`/finance/payment/detail/${id}`)
	}

	const handleImg = () => {
		closeMenu()
		dialogFormRef.current.setShow({ show: true })
	}

	const handleStatus = (isStatus) => {
		closeMenu()
		confirmRef.current.setShow({
			title: 'Konfirmasi',
			message: "Apakah anda yakin ?",
			onAction: () => {
				submitStatus(isStatus)
			}
		})
	}

	const submitStatus = (isStatus) => {
		useAxios()
			.post(`${Config.ApiUrl}/finance/payment/changestatus`, { id, statusId: isStatus ? 3 : 2 })
			.then(response => {
				const data = response.data
				console.log(data)

				modalNotifRef.current.setShow({
					modalTitle: "Sukses",
					modalMessage: response.data,
					onClose: () => {
						refreshData()
					}
				})
			})
			.catch(err => {
				console.log(err)
				if (err.response) {
					modalNotifRef.current.setShow({
						modalTitle: "Gagal",
						modalMessage: err.response ? err.response.data : "Server Internal Error",
					})
				}
				// eslint-disable-next-line no-empty
				else {
					modalNotifRef.current.setShow({
						modalTitle: 'Gagal',
						modalMessage: "Koneksi jaringan terputus",
					})
				}
			})
	}

	const handlePrint = () => {
		closeMenu()
		useAxios()
			.post(`${Config.ApiUrl}/finance/print/paymentReceipt`, { id })
			.then(response => {
				const data = response.data
				const merchant = data.Merchant ? data.Merchant : null

				const payload = {
					title: "KWITANSI PEMBAYARAN",
					company: merchant && merchant.name ? merchant.name : "",
					email: merchant && merchant.email ? merchant.email : "", //"birotravel01@mantrix.com",
					phone: merchant && merchant.phone ? merchant.phone : "", //"+62852567829",
					address: merchant && merchant.address ? merchant.address : "", //"Jalan Samsudin 502, Kudus",
					kode: data.kode,
					date: data.createdAt,
					customerName: data.User ? data.User.name : "",
					amount: fnumber(data.amount),
					remark: data.remark
				}

				print(payload, 'PdfPaymentReceipt')
			})
			.catch(err => {
				console.log(err)
				modalNotifRef.current.setShow({
					modalTitle: 'Gagal',
					modalMessage: err.response ? err.response.data : "Koneksi jaringan terputus",
				})
			})
	}

	const renderMenu = (
		<Menu
			anchorEl={menu}
			anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
			transformOrigin={{ vertical: "top", horizontal: "left" }}
			open={Boolean(menu)}
			onClose={closeMenu}
			keepMounted
		>
			{statusId === 1 && [2, 3].includes(roleId) && (
				<MenuItem onClick={() => handleStatus(true)}>Terima</MenuItem>
			)}
			{statusId === 1 && [2, 3].includes(roleId) && (
				<MenuItem onClick={() => handleStatus(false)}>Tolak</MenuItem>
			)}
			<MenuItem onClick={handleDetail}>Detail</MenuItem>
			<Divider />
			{statusId === 3 && [2, 3].includes(roleId) && (
				<MenuItem onClick={handleImg}>Lihat Bukti</MenuItem>
			)}
			{statusId === 3 && (
				<MenuItem onClick={handlePrint}>Cetak Struk</MenuItem>
			)}
		</Menu>
	);

	return (
		<MDBox display="flex">
			<Confirm ref={confirmRef} />
			<ModalNotif ref={modalNotifRef} />
			{/* Dialog View Image */}
			<DialogForm ref={dialogFormRef} maxWidth={"sm"}>
				<MDBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
					<MDTypography
						variant="h5"
						textTransform="uppercase">
						Bukti Transfer
					</MDTypography>
					<Close
						fontSize="medium"
						sx={{ cursor: "pointer" }}
						onClick={() => dialogFormRef.current.setShow({ show: false })}
					/>
				</MDBox>
				<MDBox
					position="relative"
					borderRadius="lg"
					mx={2}
					className="card-header"
					sx={{ transition: "transform 300ms cubic-bezier(0.34, 1.61, 0.7, 1)" }}
				>
					{image && (
						<MDBox
							component="img"
							src={`${Config.ApiAsset}/mutation/${image}`}
							alt="Bukti Transfer"
							borderRadius="lg"
							shadow="sm"
							width="100%"
							height="250px"
							position="relative"
							zIndex={10}
							mb={2}
						/>
					)}
				</MDBox>
			</DialogForm>

			<MDButton
				variant="contained"
				color="info"
				size="small"
				onClick={openMenu}
			>
				actions&nbsp;
				<Icon>keyboard_arrow_down</Icon>
			</MDButton>
			{renderMenu}
		</MDBox>
	)
}

ButtonAction.propTypes = {
	id: PropTypes.number.isRequired,
	statusId: PropTypes.number.isRequired,
	refreshData: PropTypes.func,
	image: PropTypes.string
}

export default ButtonAction