import React, { useState } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDDatePicker from "components/MDDatePicker";
import MDTypography from "components/MDTypography";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";

import useAxios from "libs/useAxios";
import Config from "config";
import secureStorage from "libs/secureStorage";
import moment from "moment";

import print from "helper/printer";

import ReportOwner from "contents/Finance/FinancialStatement/ReportOwner"
import ReportTravel from "contents/Finance/FinancialStatement/ReportTravel"

class FinancialStatement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
  }

  render() {
    const user = secureStorage.getItem("user");
    if (user && user.roleId === 1) return <ReportOwner />
    else if (user && [2, 3].includes(user.roleId)) return <ReportTravel />

  }
}

export default FinancialStatement;
