import { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

import useAxios from "libs/useAxios";
import Config from "config";
import secureStorage from "libs/secureStorage";

import Confirm from "contents/Components/Confirm";
import ModalNotif from "contents/Components/ModalNotif";
import print from "helper/printer";

function ButtonAction({ id, refreshData, type }) {
  const confirmRef = useRef();
  const modalNotifRef = useRef();
	const [user, setUser] = useState(null);

  const navigate = useNavigate();
  const [menu, setMenu] = useState(null);

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);

  useEffect(() => {
		setUser(secureStorage.getItem('user'))
	}, [])

  const handleEdit = () => {
    closeMenu();
    navigate(`/adm/certificate/edit/${id}`);
  };

  const handleDelete = () => {
    closeMenu();
    console.log("[DELETE]");
    confirmRef.current.setShow({
      title: "Komfirmasi",
      message: "Apakah anda yakin inging menghapus data ini ?",
      onAction: () => {
        submitDel();
      },
    });
  };

  const submitDel = () => {
    let url;
    if (type == "Keterangan") {
      url = "/certificate/delete";
    } else if (type == "Surat Kuasa") {
      url = "/certificate/poa/delete";
    }
    useAxios()
      .post(`${Config.ApiUrl}${url}`, { id })
      .then((response) => {
        modalNotifRef.current.setShow({
          modalTitle: "Sukses",
          modalMessage: response.data,
          onClose: () => {
            console.log("[REFRESH]");
            refreshData();
          },
        });
      })
      .catch((err) => {
        modalNotifRef.current.setShow({
          modalTitle: "Gagal",
          moadlMessage: "Koneksi jaringan terputus",
        });
      });
  };

  const handlePrint = () => {
    closeMenu();
    let url;
    if (type == "Keterangan") {
      url = "/certificate/letter/print";
    } else if (type == "Surat Kuasa") {
      url = "/certificate/poa/print";
    }
    useAxios()
      .post(`${Config.ApiUrl}${url}`, { id })
      .then((response) => {
        const data = response.data;

        const nomor = data.nomor ? data.nomor : null;
        const date = data.date ? data.date : null;
        const subject = data.subject ? data.subject : null;
        const direction = data.direction ? data.direction : null;
        const name = data.name ? data.name : null;
        const position = data.position ? data.position : null;
        const departureDate = data.departureDate ? data.departureDate : null;
        const jamaah = data.userCertificate ? data.userCertificate : [];
        const user = data.User ? data.User : null;
        const place = data.place ? data.place : null;
        const merchant = data.Merchant ? data.Merchant : null;

        const payload = {
          nomor,
          date,
          direction,
          name,
          position,
          subject,
          departureDate,
          jamaah,
          user,
          place,
          merchant
        };
      
        if (type == "Keterangan") {
          print(payload, "PdfCertificate");
        } else if (type == "Surat Kuasa") {
          print(payload, "PdfPoa");
        }
      })
      .catch((err) => {
        console.log(err);
        modalNotifRef.current.setShow({
          modalTitle: "Gagal " + err.response.data,
          modalMessage: err.response ? err.response.data : "Koneksi jaringan terputus",
        });
      });
  };

  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      {/* <MenuItem onClick={handleEdit}>Edit</MenuItem> */}
      {user && [2, 3].includes(user.roleId) && (<MenuItem onClick={handleDelete}>Hapus</MenuItem>)}
      <MenuItem onClick={handlePrint}>Cetak Surat</MenuItem>
    </Menu>
  );

  return (
    <MDBox display="flex">
      <Confirm ref={confirmRef} />
      <ModalNotif ref={modalNotifRef} />

      <MDButton variant="contained" color="info" size="small" onClick={openMenu}>
        actions&nbsp; <Icon>keyboard_arrow_down</Icon>
      </MDButton>
      {renderMenu}
    </MDBox>
  );
}

ButtonAction.propTypes = {
  id: PropTypes.number,
  refreshData: PropTypes.func,
  type: PropTypes.string,
};

export default ButtonAction;
