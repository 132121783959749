// @mui icons
import Icon from "@mui/material/Icon";

import secureStorage from "libs/secureStorage";
import Container from "@mui/material/Container"

import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';

// ================================= DASHBOARD ==================================
import Dashboard from "contents/Dashboard";

// ====================================  MANAGEMENT =============================
// Merchant / Biro Travel
import Merchant from "contents/Manage/Merchant"
import FormMerchant from "contents/Manage/Merchant/components/Form"
import DetailMerchant from "contents/Manage/Merchant/components/Detail"
// Agent Mabrur
import Agen from "contents/Manage/Agent";
import FormAgen from "contents/Manage/Agent/components/Form"
import DetailAgen from "contents/Manage/Agent/components/Detail"
// -- User
import User from "contents/Manage/User"
import FormUser from "contents/Manage/User/components/Form";
import DetailUser from "contents/Manage/User/components/Detail"

// Director
import Directors from "contents/Manage/Directors";
import FormDirectors from "contents/Manage/Directors/Form"
import DetailDirector from "contents/Manage/Directors/Detail"
// Employee
import Employee from "contents/Manage/Employee";
import FormEmployee from "contents/Manage/Employee/Form"
import DetailEmployee from "contents/Manage/Employee/Detail"

// Branch Office
import BranchOffice from "contents/Manage/BranchOffice"
import FormBranchOffice from "contents/Manage/BranchOffice/Form"
import DetailBranchOffice from "contents/Manage/BranchOffice/Detail"

// Profile Office
import ProfileOffice from "contents/Manage/ProfileOffice";
import FormProfileOffice from "contents/Manage/ProfileOffice/components/Form";
// ==========================================================================

// ==================================== MASTER =============================
import Banner from "contents/Master/Banner"
import FormBanner from "contents/Master/Banner/components/Form"
// Register Biro Travel / Agen Mabrur
import LayoutRegister from "contents/Master/LayoutRegister"
// Tour Package
import TourPackage from "contents/Master/TourPackage"
// -- Account Bank 
import AccountBank from "contents/Master/AccountBank";
import FormAccountBank from "contents/Master/AccountBank/components/Form";
// Country
import Country from "contents/Master/Region/Country"
import FormCountry from "contents/Master/Region/Country/components/Form"
// Province
import Province from "contents/Master/Region/Province"
import FormProvince from "contents/Master/Region/Province/components/Form"
// District
import District from "contents/Master/Region/District"
import FormDistrict from "contents/Master/Region/District/components/Form"
// Tour Leader
import TourLeader from "contents/Master/TourLeader"
import FormTourLeader from "contents/Master/TourLeader/components/Form"
//  Article
import Article from "contents/Master/Article";
import FormArticle from "contents/Master/Article/components/Form"
// // DISCOUNT
// import Discount from "contents/Master/Discount"
// import FormDiscount from "contents/Master/Discount/components/Form"
// // Addon
// import Addon from "contents/Master/Addon"
// import FormAddon from "contents/Master/Addon/components/Form"
// Prayer
import Prayer from "contents/Master/Prayer";
import FormPrayer from "contents/Master/Prayer/components/Form"
// Room Type
import RoomType from "contents/Master/RoomType"
import FormRoom from "contents/Master/RoomType/components/Form"
// ==========================================================================

// ============================= Package By Agent ===========================
import PackageAgent from "contents/PackageAgent"
// ==========================================================================
// ============================= Administration =============================
// Owner / Admin
import OmsetPackage from "contents/Administrasi/Owner/Omset"
import OwnerJamaah from "contents/Administrasi/Owner/Jamaah"
// End

// Register Member
import RegisterMember from "contents/Administrasi/RegisterMember"
// Product
import ListProduct from "contents/Administrasi/ListProduct"
import ProductDetail from "contents/Administrasi/ListProduct/components/Detail"

// -- Member
import ListMember from "contents/Administrasi/ListMember"
import FormMember from "contents/Administrasi/ListMember/components/Form"
import DetailMember from "contents/Administrasi/ListMember/components/Detail"
import FormVisa from "contents/Administrasi/ListMember/components/FormVisa"
import FormPassport from "contents/Administrasi/ListMember/components/FormPassport"

import Document from "contents/Administrasi/Document"
import Departure from "contents/Administrasi/Departure"
import Certificate from "contents/Administrasi/Certificate";
import FormLetter from "contents/Administrasi/Certificate/Form";
import HistoryMember from "contents/Administrasi/HistoryMember";
import Notification from "contents/Administrasi/Notification";
// ==========================================================================

// ============================= FINANCE =============================
import Transaction from "contents/Finance/Transaction";
import DetailTransaction from "contents/Finance/Transaction/components/Detail"
import AgentCommission from "contents/Finance/AgentCommission";
import IncomingFund from "contents/Finance/IncomingFund"
import OutFund from "contents/Finance/OutFund"
import FormOutFund from "contents/Finance/OutFund/Form"
import FinancialStatement from "contents/Finance/FinancialStatement"
// Bill Member
import BillMember from "contents/Finance/Bill"
import TabBillDetail from "contents/Finance/Bill/components/TabLayout"
// Payment
import Payment from "contents/Finance/Payment"
import PaymentForm from "contents/Finance/Payment/components/Form"
import PaymentDetail from "contents/Finance/Payment/components/Detail"
// ==========================================================================

// ============================= INVENTORY =============================
// Input Inventory
import InputInventory from "contents/Inventory/InputInventory";
import FormInventory from "contents/Inventory/InputInventory/Form";
// Data Inventory
import DataInventory from "contents/Inventory/DataInventory";

// ================================ SETTING =================================
import Setting from "contents/Setting/Account"
import Contact from "contents/Setting/Contact"
// ==========================================================================

const logout = () => {
	secureStorage.removeItem("token");
	secureStorage.removeItem("user");
	window.location.href = "/login"
}

let routes = [
	// ======================================== DASHBOARD ========================================
	{
		type: "collapse",
		name: "Dashboard",
		index: true,
		key: "dashboard",
		route: "/dashboard",
		icon: <Icon fontSize="medium">home</Icon>,
		component: <Dashboard />,
		noCollapse: true,
	},
	// ============================================================================================

	// ======================================== MANAGEMENT ========================================
	{
		type: "collapse",
		name: "Management",
		key: "manage",
		route: "/manage",
		icon: <Icon fontSize="medium">group</Icon>,
		collapse: [
			// LIST MERCHANT
			{
				name: "Biro Travel",
				key: "merchant",
				route: "/manage/merchant",
				index: true,
				component: <Merchant />
			},
			{
				name: "Tambah Biro Travel",
				key: "add",
				route: "/manage/merchant/add",
				component: <FormMerchant />
			},
			{
				name: "Ubah Biro Travel",
				key: "edit",
				route: "/manage/merchant/edit/:id",
				component: <FormMerchant />
			},
			{
				name: "Detail Biro Travel",
				key: "detail",
				route: "/manage/merchant/detail/:id",
				component: <DetailMerchant />
			},
			// List Agen Mabrur
			{
				name: "Agen Mabrur",
				key: "agent",
				route: "/manage/agent",
				component: <Agen />
			},
			{
				name: "Ubah Agen",
				key: "edit",
				route: "/manage/agent/edit/:id",
				component: <FormAgen />
			},
			{
				name: "Detail Agen",
				key: "detail",
				route: "/manage/agent/detail/:id",
				component: <DetailAgen />
			},
			// LIST User/Operator
			{
				name: "User",
				key: "user",
				route: "/manage/user",
				component: <User />
			},
			{
				name: "Tambah User",
				key: "add",
				route: "/manage/user/add",
				component: <FormUser />
			},
			{
				name: "Ubah User",
				key: "edit",
				route: "/manage/user/edit/:id",
				component: <FormUser />
			},
			{
				name: "Detai User",
				key: "detail",
				route: "/manage/user/detail/:id",
				component: <DetailUser />
			},

			{
				name: "Direksi",
				key: "directors",
				route: "/manage/directors",
				component: <Directors />
			},
			{
				name: "Tambah Direksi",
				key: "add",
				route: "/manage/directors/add",
				component: <FormDirectors />
			},
			{
				name: "Edit Direksi",
				key: "edit",
				route: "/manage/directors/edit/:id",
				component: <FormDirectors />
			},
			{
				name: "Detail Direksi",
				key: "detail",
				route: "/manage/directors/detail/:id",
				component: <DetailDirector />
			},
			{
				name: "Karyawan",
				key: "employee",
				route: "/manage/employee",
				component: <Employee />
			},
			{
				name: "Tambah Karyawan",
				key: "add",
				route: "/manage/employee/add",
				component: <FormEmployee />
			},
			{
				name: "Edit Karyawan",
				key: "edit",
				route: "/manage/employee/edit/:id",
				component: <FormEmployee />
			},
			{
				name: "Detail Karyawan",
				key: "detail",
				route: "/manage/employee/detail/:id",
				component: <DetailEmployee />
			},
			{
				name: "Kantor Cabang",
				key: "branchoffice",
				route: "/manage/branchoffice",
				component: <BranchOffice />
			},
			{
				name: "Tambah Kantor Cabang",
				key: "add",
				route: "/manage/branchoffice/add",
				component: <FormBranchOffice />
			},
			{
				name: "Edit Kantor Cabang",
				key: "edit",
				route: "/manage/branchoffice/edit/:id",
				component: <FormBranchOffice />
			},
			{
				name: "Detail Kantor Cabang",
				key: "detail",
				route: "/manage/branchoffice/detail/:id",
				component: <DetailBranchOffice />
			},
			// Profile Office
			{
				name: "Profil Perusahaan",
				key: "profile-office",
				route: "/manage/profile-office",
				component: <ProfileOffice />
			},
			{
				name: "Ubah Profil Perusahaan",
				key: "edit",
				route: "/manage/profile-office/edit",
				component: <FormProfileOffice />
			}
		],
	},
	// ============================================================================================

	// ======================================== MASTER ============================================
	{
		type: "collapse",
		name: "Master",
		key: "master",
		route: "/master",
		icon: <Icon fontSize="medium">source</Icon>,
		collapse: [
			{
				name: "Banner",
				key: "banner",
				route: "/master/banner",
				component: <Banner />
			},
			{
				name: "Add",
				key: "add",
				route: "/master/banner/add",
				component: <FormBanner />
			},
			{
				name: "Edit",
				key: "edit",
				route: "/master/banner/edit/:id",
				component: <FormBanner />
			},
			// TABLAYOUT REGISTER
			{
				name: "Registrasi",
				key: "register",
				route: "/master/register",
				component: <LayoutRegister />
			},
			// CREATE OR UPDATE TOUR PACKAGE
			{
				name: "Paket Tour",
				key: "tour-package",
				route: "/master/tour-package",
				index: true,
				component: <TourPackage title="Buat Paket Tour Baru" action="add" />
			},
			{
				name: "Edit Paket Tour",
				key: "edit",
				route: "/master/tour-package/edit/:id",
				component: <TourPackage title="Edit Paket Tour" action="edit" />
			},

			// ACCOUNT BANK
			{
				name: "Akun Bank",
				key: "account-bank",
				route: "/master/account-bank",
				component: <AccountBank />,
			},
			{
				name: "Tambah Akun Bank Baru",
				key: "add",
				route: "/master/account-bank/add",
				component: <FormAccountBank />,
			},
			{
				name: "Ubah Akun Bank",
				key: "edit",
				route: "/master/account-bank/edit/:id",
				component: <FormAccountBank />,
			},
			// TOUR LEADER
			{
				name: "Tour Leader",
				key: "tour-leader",
				route: "/master/tour-leader",
				component: <TourLeader />
			},
			{
				name: "Tambah Tour Leader",
				key: "add",
				route: "/master/tour-leader/add",
				component: <FormTourLeader />
			},
			{
				name: "Ubah Tour Leader",
				key: "edit",
				route: "/master/tour-leader/edit/:id",
				component: <FormTourLeader />
			},
			// CATEGORY PRODUCT
			{
				name: "Jenis Paket",
				key: "product-category",
				route: "/master/product-category",
				// component: <ProductType />,
			},
			{
				name: "Tambah Jenis Paket",
				key: "add",
				route: "/master/product-category/add",
				// component: <FormProductType />
			},
			{
				name: "Ubah Jenis Paket",
				key: "edit",
				route: "/master/product-category/edit/:id",
				// component: <FormProductType />
			},
			// ARTICLE
			{
				name: "Artikel",
				key: "article",
				route: "/master/article",
				component: <Article />
			},
			{
				name: "Tambah Artikel",
				key: "add",
				route: "/master/article/add",
				component: <FormArticle />
			},
			{
				name: "Ubah Artikel",
				key: "edit",
				route: "/master/article/edit/:id",
				component: <FormArticle />
			},
			// // DISCOUNT
			// {
			// 	name: "Diskon",
			// 	key: "discount",
			// 	route: "/master/discount",
			// 	icon: <Icon fontSize="medium">Discount</Icon>,
			// 	component: <Discount />
			// },
			// {
			// 	name: "Tambah Diskon Baru",
			// 	key: "add",
			// 	route: "/master/discount/add",
			// 	component: <FormDiscount />
			// },
			// {
			// 	name: "Ubah Diskon",
			// 	key: "edit",
			// 	route: "/master/discount/edit/:id",
			// 	component: <FormDiscount />
			// },
			// // ADDON
			// {
			// 	name: "Biaya Tambahan",
			// 	key: "addon",
			// 	route: "/master/addon",
			// 	component: <Addon />
			// },
			// {
			// 	name: "Tambah Biaya Tambahan",
			// 	key: "add",
			// 	route: "/master/addon/add",
			// 	component: <FormAddon />
			// },
			// {
			// 	name: "Ubah Biaya Tambahan",
			// 	key: "edit",
			// 	route: "/master/addon/edit/:id",
			// 	component: <FormAddon />
			// },
			// PRAYER
			{
				name: "Doa Manasik",
				key: "prayer",
				route: "/master/prayer",
				component: <Prayer />
			},
			{
				name: "Tambah Doa Baru",
				key: "add",
				route: "/master/prayer/add",
				component: <FormPrayer />
			},
			{
				name: "Ubah Doa",
				key: "edit",
				route: "/master/prayer/edit/:id",
				component: <FormPrayer />
			},
			// REGION
			{
				name: "Wilayah",
				key: "region",
				route: "/master/region",
				collapse: [
					// Country
					{
						name: "Negara",
						key: "country",
						route: "/master/region/country",
						component: <Country />,
					},
					{
						name: "Tambah Negara",
						key: "add",
						route: "/master/region/country/add",
						component: <FormCountry />
					},
					{
						name: "Ubah Negara",
						key: "edit",
						route: "/master/region/country/edit/:id",
						component: <FormCountry />
					},
					// Province
					{
						name: "Provinsi",
						key: "province",
						route: "/master/region/province",
						component: <Province />,
					},
					{
						name: "Tambah Provinsi",
						key: "add",
						route: "/master/region/province/add",
						component: <FormProvince />
					},
					{
						name: "Ubah Provinsi",
						key: "edit",
						route: "/master/region/province/edit/:id",
						component: <FormProvince />
					},
					// District
					{
						name: "Kabupaten",
						key: "district",
						route: "/master/region/district",
						component: <District />
					},
					{
						name: "Tambah Kabupaten",
						key: "add",
						route: "/master/region/district/add",
						component: <FormDistrict />
					},
					{
						name: "Ubah Kabupaten",
						key: "edit",
						route: "/master/region/district/edit/:id",
						component: <FormDistrict />
					}
				],
			},
			// TYPE ROOM
			{
				name: "Jenis Kamar Hotel",
				key: "room",
				route: "/master/room",
				component: <RoomType />
			},
			{
				name: "Tambah Jenis Kamar",
				key: "add",
				route: "/master/room/add",
				component: <FormRoom />
			},
			{
				name: "Ubah Jenis Kamar",
				key: "edit",
				route: "/master/room/edit/:id",
				component: <FormRoom />
			},
		],
	},
	// ============================================================================================

	// ======================================== FINANCE ===========================================
	{
		type: "collapse",
		name: "Keuangan",
		key: "finance",
		route: "/finance",
		icon: <Icon fontSize="medium">payments</Icon>,
		collapse: [
			// Transaction Member
			{
				name: "Transaksi",
				key: "transaction",
				route: "/finance/trx",
				component: <Transaction />
			},
			{
				name: "Detail Transaksi",
				key: "detail",
				route: "/finance/trx/detail/:id",
				component: <DetailTransaction view="sale" />
			},
			// Bill Member
			{
				name: "Administrasi Jamaah",
				key: "bill",
				route: "/finance/bill",
				component: <BillMember />
			},
			{
				name: "Detail Tagihan",
				key: "detail",
				route: "/finance/bill/detail/:id",
				component: <TabBillDetail />
			},
			// Payment
			{
				name: "Daftar Pembayaran",
				key: "payment",
				route: "/finance/payment",
				component: <Payment />
			},
			{
				name: "Form Pembayaran",
				key: "payment",
				route: "/finance/payment/form/:id",
				component: <PaymentForm />
			},
			{
				name: "Detail Pembayaran",
				key: "detail",
				route: "/finance/payment/detail/:id",
				component: <PaymentDetail />
			},
			{
				name: "Dana Masuk",
				key: "incoming-fund",
				route: "/finance/incoming-fund",
				component: <IncomingFund />
			},
			{
				name: "Dana Keluar",
				key: "out-fund",
				route: "/finance/out-fund",
				component: <OutFund />
			},
			{
				name: "Form Pengeluaran",
				key: "form-add",
				route: "/finance/out-fund/add",
				component: <FormOutFund />
			},
			{
				name: "Laporan Keuangan",
				key: "financial-statement",
				route: "/finance/financial-statement",
				component: <FinancialStatement />
			},
			{
				name: "Komisi Agen",
				key: "agent-commission",
				route: "/finance/agent-commission",
				component: <AgentCommission />
			}
		]
	},
	// ============================================================================================

	// ======================================== INVENTORY =========================================
	{
		type: "collapse",
		name: "Inventaris",
		key: "inventory",
		route: "/inventory",
		icon: <Icon fontSize="medium">inventory</Icon>,
		collapse: [
			{
				name: "Input Barang",
				key: "input",
				route: "/inventory/input",
				component: <InputInventory />
			},
			{
				name: "Tambah Barang",
				key: "add",
				route: "/inventory/input/add",
				component: <FormInventory />
			},
			{
				name: "Edit Barang",
				key: "edit",
				route: "/inventory/input/edit/:id",
				component: <FormInventory />
			},
			{
				name: "Pendataan Barang",
				key: "data",
				route: "/inventory/data",
				component: <DataInventory />
			},
		]
	},
	// ============================================================================================
	{
		type: "collapse",
		name: "Daftar Agen",
		key: "agent",
		route: "/agent",
		icon: <Icon fontSize="medium">people</Icon>,
		component: <Agen />,
		noCollapse: true
	},

	// ======================================== PACKAGE TOUR ======================================
	{
		type: "collapse",
		name: "Paket Tour",
		key: "package",
		route: "/package",
		icon: <Icon fontSize="medium">list</Icon>,
		collapse: [
			{
				name: "Umroh",
				key: "umroh",
				route: "/package/umroh",
				component: <PackageAgent view="umroh" />
			},
			{
				name: "Haji Plus",
				key: "haji-plus",
				route: "/package/haji-plus",
				component: <PackageAgent view="haji" />
			},
			{
				name: "Haji Furoda",
				key: "haji-furoda",
				route: "/package/haji-furoda",
				component: <PackageAgent view="furoda" />
			}
		]
	},

	// ======================================== ADMINISTRASI ======================================
	{
		type: "collapse",
		name: "Administrasi",
		key: "adm",
		route: "/adm",
		icon: <SupervisorAccountIcon fontSize="medium" />,
		collapse: [
			// Owner
			{
				name: "Omset Umroh",
				key: "omset-umroh",
				route: "/adm/omset-umroh",
				component: <OmsetPackage view="umroh" />
			},
			{
				name: "Omset Haji Plus",
				key: "omset-haji-plus",
				route: "/adm/omset-haji-plus",
				component: <OmsetPackage view="haji" />
			},
			{
				name: "Omset Haji Furoda",
				key: "omset-haji-furoda",
				route: "/adm/omset-haji-furoda",
				component: <OmsetPackage view="furoda" />
			},
			{
				name: "Jamaah Umroh",
				key: "jamaah-umroh",
				route: "/adm/jamaah-umroh",
				component: <OwnerJamaah view="umroh" />
			},
			{
				name: "Jamaah Haji Plus",
				key: "jamaah-haji-plus",
				route: "/adm/jamaah-haji-plus",
				component: <OwnerJamaah view="haji" />
			},
			{
				name: "Jamaah Haji Furoda",
				key: "jamaah-haji-furoda",
				route: "/adm/jamaah-haji-furoda",
				component: <OwnerJamaah view="furoda" />
			},
			// End

			// Agent
			{
				name: "Pendaftaran Member",
				key: "register-member",
				route: "/adm/register-member",
				component: <RegisterMember />
			},
			// Biro
			{
				name: "Daftar Paket",
				key: "product",
				route: "/adm/product",
				component: <ListProduct />
			},
			{
				name: "Paket Detail",
				key: "detail",
				route: "/adm/product/detail/:id",
				component: <ProductDetail />
			},
			// List Member
			{
				name: "Daftar Jamaah",
				key: "member",
				route: "/adm/member",
				component: <ListMember />
			},
			{
				name: "Ubah Pelanggan",
				key: "edit",
				route: "/adm/member/edit/:id",
				component: <FormMember view={"customer"} />
			},
			{
				name: "Detail Pelanggan",
				key: "detail",
				route: "/adm/member/detail/:id",
				component: <DetailMember view={"member"} />
			},
			// VISA
			{
				name: "Form Visa",
				key: "visa",
				route: "/adm/member/visa/:id",
				component: <FormVisa />
			},
			// PASSPORT
			{
				name: "Form Passpor",
				key: "passpor",
				route: "/adm/member/passport/:id",
				component: <FormPassport />
			},
			// End
			{
				name: "Dokumen",
				key: "document",
				route: "/adm/document",
				component: <Document />
			},
			{
				name: "Keberangkatan",
				key: "departure",
				route: "/adm/departure",
				component: <Departure />
			},
			{
				name: "Rekomendasi",
				key: "certificate",
				route: "/adm/certificate",
				component: <Certificate />
			},
			{
				name: "Tambah Surat Keterangan",
				key: "add",
				route: "/adm/certificate/add",
				component: <FormLetter />
			},
			{
				name: "Ubah Surat Keterangan",
				key: "edit",
				route: "/adm/certificate/edit/:id",
				component: <FormLetter />
			},
			{
				name: "History Jamaah",
				key: "history-member",
				route: "/adm/history-member",
				component: <HistoryMember />
			},
			{
				name: "Notifikasi",
				key: "notification",
				route: "/adm/notification",
				component: <Notification />
			}
		]
	},
	// ============================================================================================

	// ======================================== SETTING ===========================================
	{
		type: "collapse",
		name: "Pengaturan",
		key: "setting",
		route: "/setting",
		icon: <Icon FormDiscount="medium">settingsApplications</Icon>,
		collapse: [
			{
				name: "Akun",
				key: "account",
				route: "/setting/account",
				component: <Setting />
			},
			{
				name: "Kontak",
				key: "contact",
				route: "/setting/contact",
				component: <Contact />
			}
		]
	}
	// ============================================================================================
];

const getMenu = (user) => {
	const roleId = user.roleId
	let menus = [
		{
			type: "collapse",
			name: "Dashboard",
			index: true,
			key: "dashboard",
			route: "/dashboard",
			icon: <Icon fontSize="medium">home</Icon>,
			component: <Dashboard />,
			noCollapse: true,
		}
	]

	// ======================================== MENU PACKAGE AGENT =====================================
	let menuPackageAgent = [4].includes(roleId) ? [
		{
			type: "collapse",
			name: "Paket Tour",
			key: "package",
			route: "/package",
			icon: <Icon fontSize="medium">list</Icon>,
			collapse: [
				{
					name: "Umroh",
					key: "umroh",
					route: "/package/umroh",
					component: <Container />
				},
				{
					name: "Haji Plus",
					key: "haji-plus",
					route: "/package/haji-plus",
					component: <Container />
				},
				{
					name: "Haji Furoda",
					key: "haji-furoda",
					route: "/package/haji-furoda",
					component: <Container />
				}
			]
		}
	] : []

	// ======================================== MENU MANAGEMENT ===================================
	const menuManageOwner = [1].includes(roleId) ? [
		{
			name: "Biro Travel",
			key: "merchant",
			route: "/manage/merchant",
			component: <Container />
		},
		{
			name: "Agen Mabrur",
			key: "agent",
			route: "/manage/agent",
			component: <Container />
		},
		{
			name: "Badal Haji",
			key: "badal-haji",
			route: "/manage/badal-haji",
			component: <Container />
		},
		{
			name: "Badal Umroh",
			key: "badal-umroh",
			route: "/manage/badal-umroh",
			component: <Container />
		}
	] : []
	// User / Operator
	const menuManageBiro = [2, 3].includes(roleId) ? [
		{
			name: "Profil Perusahaan",
			key: "profile-office",
			route: "/manage/profile-office",
			component: <Container />
		},
		{
			name: "Direksi",
			key: "directors",
			route: "/manage/directors",
			component: <Container />
		},
		{
			name: "Karyawan",
			key: "employee",
			route: "/manage/employee",
			component: <Container />
		},
		{
			name: "Kantor Cabang",
			key: "branchoffice",
			route: "/manage/branchoffice",
			component: <Container />
		},
		{
			name: "User / Operator",
			key: "user",
			route: "/manage/user",
			component: <Container />
		}
	] : []

	let menuManage = [1, 2, 3].includes(roleId) ? [{
		type: "collapse",
		name: "Management",
		key: "manage",
		route: "/manage",
		icon: <Icon fontSize="medium">list</Icon>,
		collapse: [
			// Biro/Operator
			...menuManageBiro,
			// Develop/Owner
			...menuManageOwner
		]
	}] : []
	// ============================================================================================

	// ======================================== MENU MASTER =======================================
	let menuRegLayout = [1].includes(roleId) ? [{
		name: 'Banner',
		key: 'banner',
		route: '/master/banner',
		component: <Container />
	}, {
		name: "Registrasi",
		key: "register",
		route: "/master/register",
		component: <Container />
	}] : []
	let menuMasterArticle = [1].includes(roleId) ? [{
		name: "Artikel Berita",
		key: "article",
		route: "/master/article",
		component: <Container />
	}] : []
	let masterPrayer = [1].includes(roleId) ? [{
		name: "Doa Manasik",
		key: "prayer",
		route: "/master/prayer",
		component: <Container />
	}] : []
	let masterRegion = [1].includes(roleId) ? [{
		name: "Wilayah",
		key: "region",
		collapse: [
			{
				name: "Negara",
				key: "country",
				route: "/master/region/country",
				component: <Container />,
			},
			{
				name: "Provinsi",
				key: "province",
				route: "/master/region/province",
				component: <Container />,
			},
			{
				name: "Kabupaten",
				key: "district",
				route: "/master/region/district",
				component: <Container />,
			}
		],
	}] : []

	let menuMasterTour = [2, 3].includes(roleId) ? [{
		name: "Paket Tour",
		key: "tour-package",
		route: "/master/tour-package",
		component: <Container />
	}] : []
	let menuTourLeader = [2, 3].includes(roleId) ? [{
		name: "Tour Leader",
		key: "tour-leader",
		route: "/master/tour-leader",
		component: <Container />
	}] : []
	let menuMasterBank = [2, 3].includes(roleId) ? [{
		name: "Account Bank",
		key: "account-bank",
		route: "/master/account-bank",
		component: <Container />,
	}] : []
	// let menuDiscountAddon = [2, 3].includes(roleId) ? [
	// 	{
	// 		name: "Biaya Tambahan",
	// 		key: "addon",
	// 		route: "/master/addon",
	// 		component: <Container />
	// 	}, {
	// 		name: "Diskon",
	// 		key: "discount",
	// 		route: "/master/discount",
	// 		component: <Container />
	// 	}
	// ] : []
	let menuMasterRoom = [1].includes(roleId) ? [{
		name: "Jenis Kamar Hotel",
		key: "room",
		route: "/master/room",
		component: <Container />
	}] : []

	let menuMaster = [1, 2, 3].includes(roleId) ? [{
		type: "collapse",
		name: "Master",
		key: "master",
		route: "/master",
		icon: <Icon fontSize="medium">source</Icon>,
		collapse: [
			...menuRegLayout,
			...menuMasterTour,
			...menuTourLeader,
			// ...menuDiscountAddon,
			...menuMasterBank,
			...menuMasterArticle,
			...masterPrayer,
			...menuMasterRoom,
			...masterRegion,
		]
	}] : []
	// ============================================================================================

	// ======================================== ADMINISTRATION =====================================
	let menuAdmOwner = [1].includes(roleId) ? [
		// Owner
		{
			name: "Omset Umroh",
			key: "omset-umroh",
			route: "/adm/omset-umroh",
			component: <Container />
		},
		{
			name: "Omset Haji Plus",
			key: "omset-haji-plus",
			route: "/adm/omset-haji-plus",
			component: <Container />
		},
		{
			name: "Omset Haji Furoda",
			key: "omset-haji-furoda",
			route: "/adm/omset-haji-furoda",
			component: <Container />
		},
		{
			name: "Jamaah Umroh",
			key: "jamaah-umroh",
			route: "/adm/jamaah-umroh",
			component: <Container />
		},
		{
			name: "Jamaah Haji Plus",
			key: "jamaah-haji-plus",
			route: "/adm/jamaah-haji-plus",
			component: <Container />
		},
		{
			name: "Jamaah Haji Furoda",
			key: "jamaah-haji-furoda",
			route: "/adm/jamaah-haji-furoda",
			component: <Container />
		},
		// End
	] : []

	let menuAdmPackage = [2, 3].includes(roleId) ? [{
		name: "Daftar Paket",
		key: "product",
		route: "/adm/product",
		component: <Container />
	}] : []
	let menuRegisterMember = [4].includes(roleId) ? [{
		name: "Form Pendaftaran",
		key: "register-member",
		route: "/adm/register-member",
		component: <Container />
	}] : []
	let menuBiroAgen = [2, 3, 4].includes(roleId) ? [
		...menuRegisterMember,
		...menuAdmPackage,
		{
			name: "Daftar Jamaah",
			key: "list-member",
			route: "/adm/member",
			component: <Container />
		},
		{
			name: "Dokumen",
			key: "document",
			route: "/adm/document",
			component: <Document />
		},
		{
			name: "Keberangkatan",
			key: "departure",
			route: "/adm/departure",
			component: <Container />
		},
		{
			name: "History Jamaah",
			key: "history-member",
			route: "/adm/history-member",
			component: <Container />
		},
		{
			name: "Rekomendasi",
			key: "certificate",
			route: "/adm/certificate",
			component: <Container />
		},
		{
			name: "Notifikasi",
			key: "notification",
			route: "/adm/notification",
			component: <Container />
		}
	] : []

	let menuAdministrasi = [1, 2, 3, 4].includes(roleId) ? [{
		type: "collapse",
		name: "Administrasi",
		key: "adm",
		route: "/adm",
		icon: <SupervisorAccountIcon fontSize="medium" />,
		collapse: [
			...menuAdmOwner,
			...menuBiroAgen,
		]
	}] : []
	// ===========================================================================================

	// ======================================== MENU FINANCE =====================================
	let menuFinanceTrx = [2, 3].includes(roleId) ? [{
		name: "Daftar Transaksi",
		key: "trasaction",
		route: "/finance/trx",
		component: <Container />
	}] : []
	let menuAdmJmhPay = [2, 3, 4].includes(roleId) ? [
		{
			name: "Daftar Transaksi",
			key: "trasaction",
			route: "/finance/trx",
			component: <Container />
		},
		{
			name: "Administrasi Jamaah",
			key: "bill",
			route: "/finance/bill",
			component: <Container />
		},
		{
			name: "Pembayaran",
			key: "payment",
			route: "/finance/payment",
			component: <Container />
		},
	] : []
	let menuFinanceCommission = [2, 3, 4].includes(roleId) ? [{
		name: "Komisi Agen",
		key: "agent-commission",
		route: "/finance/agent-commission",
		component: <Container />
	}] : []

	// Owner / Develop
	let menuFinanceOwner = [1, 2, 3].includes(roleId) ? [
		{
			name: "Dana Masuk",
			key: "incoming-fund",
			route: "/finance/incoming-fund",
			component: <Container />
		},
		{
			name: "Dana Keluar",
			key: "out-fund",
			route: "/finance/out-fund",
			component: <Container />
		},
		{
			name: "Laporan Keuangan",
			key: "financial-statement",
			route: "/finance/financial-statement",
			component: <Container />
		},
	] : []

	let menuFinance = [1, 2, 3, 4].includes(roleId) ? [{
		type: "collapse",
		name: "Keuangan",
		key: "finance",
		route: "/finance",
		icon: <Icon fontSize="medium">payments</Icon>,
		collapse: [
			// ...menuFinanceTrx,
			...menuAdmJmhPay,
			...menuFinanceOwner,
			...menuFinanceCommission,
		]
	}] : []
	// ============================================================================================

	// ======================================== MENU INVENTORY ====================================
	let menuInventory = [2, 3].includes(roleId) ? [{
		type: "collapse",
		name: "Inventaris",
		key: "inventory",
		route: "/inventory",
		icon: <Icon fontSize="medium">inventory</Icon>,
		collapse: [
			{
				name: "Input Barang",
				key: "input",
				route: "/inventory/input",
				component: <Container />
			},
			{
				name: "Pendataan Barang",
				key: "data",
				route: "/inventory/data",
				component: <Container />
			},
		]
	}] : []
	// ============================================================================================

	// // ======================================== MENU TRANSACTION ==================================
	// let menuTrx = [5].includes(roleId) ? [{
	// 	type: "collapse",
	// 	name: "Transaksi",
	// 	key: "trx",
	// 	route: "/trx",
	// 	icon: <Icon fontSize="medium">shoppingcart</Icon>,
	// 	collapse: [
	// 		{
	// 			name: "Paket",
	// 			key: "user-product",
	// 			route: '/trx/user-product',
	// 			component: <Container />
	// 		},
	// 		{
	// 			name: "Tagihan",
	// 			key: "user-bill",
	// 			route: "/trx/user-bill",
	// 			component: <Container />
	// 		},
	// 		{
	// 			name: "Pembayaran",
	// 			key: "user-payment",
	// 			route: "/trx/user-payment",
	// 			component: <Container />
	// 		}
	// 	]
	// }] : []
	// ============================================================================================

	// ======================================== AGEN ==============================================
	let menuAgen = [2, 3].includes(roleId) ? [{
		type: "collapse",
		name: "Daftar Agen",
		key: "agent",
		route: "/agent",
		icon: <Icon fontSize="medium">people</Icon>,
		component: <Container />,
		noCollapse: true
	}] : []
	// ============================================================================================

	// ======================================== MENU SETTING ======================================
	let menuContact = [1].includes(roleId) ? [{
		name: "Kontak",
		key: "contact",
		route: "/setting/contact",
		component: <Container />,
	}] : []
	let menuSetting = [{
		type: "collapse",
		name: "Pengaturan",
		key: "setting",
		route: "/setting",
		icon: <Icon fontSize="medium">settingsApplications</Icon>,
		collapse: [
			{
				name: "Akun",
				key: "account",
				route: "/setting/account",
				component: <Container />,
			},
			...menuContact
		]
	}]
	// ============================================================================================

	menus = [
		...menus,
		...menuManage,
		...menuMaster,
		...menuPackageAgent, // from agent
		...menuAdministrasi,
		...menuFinance,
		...menuInventory,
		// ...menuTrx,
		...menuAgen,
		...menuSetting,
		{
			type: "collapse",
			name: "Logout",
			key: "logout",
			href: logout,
			noCollapse: true,
			icon: <Icon fontSize="medium">logout</Icon>,
			component: <Container />,
		}
	]

	return menus
}

export { routes, getMenu }

