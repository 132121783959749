/* eslint-disable react/prop-types */
import React from "react";
import { Link, Navigate } from "react-router-dom";
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import MDDatePicker from "components/MDDatePicker";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import ModalNotif from "contents/Components/ModalNotif";
import withRouter from "contents/Components/withRouter";

import MiniFormCard from "contents/Components/FormCard/MiniFormCard";
import ListJamaah from "contents/Administrasi/Certificate/components/ListJamaah";
import UserCertificate from "contents/Administrasi/Certificate/components/UserCertificate";

import Config from "config";
import _ from "lodash";
import moment from "moment";

import useAxios from "libs/useAxios";
import secureStorage from "libs/secureStorage";

class FormCertificate extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			user: null,
			title: "FORM SURAT KETERANGAN",
			action: "",
			id: null,

			nomor: "",
			letterDate: "",
			departureDate: "",
			subject: "",
			direction: "",
			name: "",
			position: "",
			remark: "",
			dataJamaah: [],
			tmpJamaah: [],
			error: [],
			success: [],

			type: "Keterangan",
			types: ["Keterangan", "Surat Kuasa"],

			disabledSubmit: false,
			redirect: null,
		};

		this.inputRef = React.createRef();
		this.modalNotifRef = React.createRef();

		this.listJamaahRef = React.createRef();
		this.userCertificateRef = React.createRef();
	}

	componentDidMount() {
		this.inputRef.current.focus();

		const user = secureStorage.getItem("user");
		this.setState({
			user: user ? user : null,
		});
		this.loadPath();
	}

	loadPath() {
		const pathname = window.location.pathname;
		const index = pathname.indexOf("edit");
		if (index === -1) {
			this.setState({
				title: "Tambah Surat Rekomendasi Baru",
				action: "add",
			});
		} else {
			const id = this.props.params?.id;
			this.loadDetail(id);
			this.setState({
				id,
				title: "Ubah Surat Rekomendasi",
				action: "edit",
				error: {
					...this.state.error,
					name: false,
					remark: false,
					file: false,
				},
				success: {
					...this.state.success,
					name: true,
					remark: true,
					file: true,
				},
			});
		}
	}

	handleChange = (e) => {
		const { id, value } = e.target;
		this.setState({
			[id]: value,
		});
	};

	handleBlur = (e) => {
		const { id, value } = e.target;

		if (value) {
			this.setState({
				success: { ...this.state.success, [e.target.id]: true },
				error: { ...this.state.error, [e.target.id]: false },
			});
		} else {
			this.setState({
				success: { ...this.state.success, [e.target.id]: false },
				error: { ...this.state.error, [e.target.id]: true },
			});
		}
	};

	onSaveJamaah = (params) => {
		const dataJamaah = [...this.state.dataJamaah, { ...params, action: "add" }];
		this.setState({ dataJamaah })
		this.listJamaahRef.current.setData(dataJamaah)
	};

	onDeleteJamaah = (key) => {
		const dataJamaah = this.state.dataJamaah
		let tmpJamaah = this.state.tmpJamaah

		const tmpDel = dataJamaah[key];
		dataJamaah.splice(key, 1);

		if (tmpDel.action === "update") tmpJamaah = [...tmpJamaah, { ...tmpDel, action: "delete" }];

		this.setState({
			dataJamaah,
			tmpJamaah,
		});
	};

	handleSubmit = () => {
		const success = this.state.success;
		if (
			success.nomor &&
			success.letterDate &&
			success.subject &&
			success.direction &&
			success.name &&
			success.position &&
			success.departureDate &&
			this.state.dataJamaah.length > 0
		) {
			this.sendData();
		} else {
			this.modalNotifRef.current.setShow({
				modalTitle: "Gagal",
				modalMessage: "Data form masih belum lengkap, Silahkan di cek kembali !",
			});
		}
	};

	sendData = () => {
		this.setState({ disabledSubmit: true });

		const user = this.state.user;
		const payload = {
			type: "SURAT KETERANGAN",
			nomor: this.state.nomor,
			date: this.state.letterDate,
			departureDate: this.state.departureDate,
			subject: this.state.subject,
			direction: this.state.direction,
			name: this.state.name,
			position: this.state.position,
			jamaah: this.state.dataJamaah,
			merchantId: user.Merchant?.id,
		};
		const url = this.state.action === "add" ? "/certificate/create" : "/certificate/update";
		useAxios()
			.post(`${Config.ApiUrl}${url}`, payload)
			.then((response) => {
				this.modalNotifRef.current.setShow({
					modalTitle: "Sukses",
					modalMessage: response.data,
					onClose: () => {
						this.setState({ redirect: "/adm/certificate" });
					},
				});
			})
			.catch((err) => {
				this.setState({ disabledSubmit: false });
				if (err.response) {
					this.modalNotifRef.current.setShow({
						modalTitle: "Gagal",
						modalMessage: err.response ? err.response.data : "Koneksi jaringan terputus",
					});
				} else {
					this.modalNotifRef.current.setShow({
						modalTitle: "Gagal",
						modalMessage: "Koneksi jaringan terputus",
					});
				}
			});
	};

	render() {
		if (this.state.redirect) {
			return <Navigate to={this.state.redirect} />;
		}
		return (
			<>
				<ModalNotif ref={this.modalNotifRef} />
				<UserCertificate
					ref={this.userCertificateRef}
					user={this.state.user}
					onAction={this.onSaveJamaah}
				/>
				<MDBox p={3}>
					<MDBox component="form" role="form">
						<Grid container spacing={3} mb={2}>
							<Grid item xs={12} md={6} lg={6}>
								<MDBox mb={2}>
									<MDInput
										inputRef={this.inputRef}
										id="nomor"
										type="text"
										label="Nomor"
										value={this.state.nomor}
										onChange={this.handleChange}
										onBlur={this.handleBlur}
										onKeyDown={this.handleKeyDown}
										success={this.state.success ? this.state.success.nomor : false}
										error={this.state.error ? this.state.error.nomor : false}
										fullWidth
									/>
								</MDBox>
							</Grid>
							<Grid item xs={12} md={6} lg={6}>
								<MDBox mb={2}>
									<MDDatePicker
										input={{
											id: "letterDate",
											placeholder: "Tanggal Surat",
											fullWidth: true,
										}}
										value={this.state.letterDate}
										onChange={(value) => {
											const date = moment(value[0]).format("YYYY-MM-DD");
											this.setState({
												letterDate: date,
												error: { ...this.state.error, letterDate: false },
												success: { ...this.state.success, letterDate: true },
											});
										}}
									/>
								</MDBox>
							</Grid>
						</Grid>
						<Grid container spacing={3} mb={2}>
							<Grid item xs={12} md={6} lg={6}>
								<MDBox mb={2}>
									<MDInput
										id="subject"
										type="text"
										label="Hal Permohonan"
										value={this.state.subject}
										onChange={this.handleChange}
										onBlur={this.handleBlur}
										onKeyDown={this.handleKeyDown}
										success={this.state.success ? this.state.success.subject : false}
										error={this.state.error ? this.state.error.subject : false}
										fullWidth
									/>
								</MDBox>
							</Grid>
							<Grid item xs={12} md={6} lg={6}>
								<MDBox mb={2}>
									<MDInput
										id="direction"
										type="text"
										label="Tujuan"
										value={this.state.direction}
										onChange={this.handleChange}
										onBlur={this.handleBlur}
										onKeyDown={this.handleKeyDown}
										success={this.state.success ? this.state.success.direction : false}
										error={this.state.error ? this.state.error.direction : false}
										fullWidth
									/>
								</MDBox>
							</Grid>
						</Grid>
						<Grid container spacing={3} mb={2}>
							<Grid item xs={12} md={6} lg={6}>
								<MDBox mb={2}>
									<MDInput
										id="name"
										type="text"
										label="Nama"
										value={this.state.name}
										onChange={this.handleChange}
										onBlur={this.handleBlur}
										onKeyDown={this.handleKeyDown}
										success={this.state.success ? this.state.success.name : false}
										error={this.state.error ? this.state.error.name : false}
										fullWidth
									/>
								</MDBox>
							</Grid>
							<Grid item xs={12} md={6} lg={6}>
								<MDBox mb={2}>
									<MDInput
										id="position"
										type="text"
										label="Jabatan"
										value={this.state.position}
										onChange={this.handleChange}
										onBlur={this.handleBlur}
										onKeyDown={this.handleKeyDown}
										success={this.state.success ? this.state.success.position : false}
										error={this.state.error ? this.state.error.position : false}
										fullWidth
									/>
								</MDBox>
							</Grid>
						</Grid>
						<Grid container spacing={3} mb={2}>
							<Grid item xs={12} md={6} lg={6}>
								<MDBox mb={2}>
									<MDDatePicker
										input={{
											id: "depatrueDate",
											placeholder: "Tanggal Keberangkatan Jamaah",
											fullWidth: true,
										}}
										value={this.state.departureDate}
										onChange={(value) => {
											const date = moment(value[0]).format("YYYY-MM-DD");
											this.setState({
												departureDate: date,
												error: { ...this.state.error, departureDate: false },
												success: { ...this.state.success, departureDate: true },
											});
										}}
									/>
								</MDBox>
							</Grid>
						</Grid>
						{/* input content jamaah */}
						<Grid container spacing={3} mb={5}>
							<Grid item xs={12} md={12} lg={12}>
								<MDBox
									mb={1}
									width="100%"
									display="flex"
									justifyContent="flex-start"
									alignItems="center"
								>
									<MDTypography
										component="label"
										variant="button"
										fontWeight="medium"
										color="text"
										textTransform="capitalize"
									>
										Jamaah
									</MDTypography>
									<MDBox ml={1}>
										<MDButton
											variant="gradient"
											color="info"
											size="small"
											iconOnly
											onClick={() => this.userCertificateRef.current.setShow({ show: true })}
										>
											<Icon>add</Icon>
										</MDButton>
									</MDBox>
								</MDBox>
								<ListJamaah
									type="form"
									rows={this.state.dataJamaah}
									ref={this.listJamaahRef}
									onDelete={this.onDeleteJamaah}
								/>
							</Grid>
						</Grid>
						{/* end input content jamaah */}

						<Grid container spacing={3} mb={2}>
							<Grid item xs={12} md={12} lg={12}>
								<MDBox pt={3} display="flex" justifyContent="space-between">
									<MDButton
										variant="gradient"
										color="error"
										component={Link}
										to={{ pathname: "/adm/certificate" }}
									>
										KEMBALI
									</MDButton>
									<MDButton
										type="button"
										variant="gradient"
										color="info"
										disabled={this.state.disabledSubmit}
										onClick={this.handleSubmit}
									>
										SUBMIT
									</MDButton>
								</MDBox>
							</Grid>
						</Grid>
					</MDBox>
				</MDBox>
			</>
		);
	}
}

export default withRouter(FormCertificate);
