/* eslint-disable react/prop-types */
import React from "react";
import { Link, Navigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDAvatar from "components/MDAvatar";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDBadge from "components/MDBadge";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

// Material Dashboard 2 PRO React examples
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import withRouter from "contents/Components/withRouter";
import Loader from "contents/Components/Loader";
import Confirm from "contents/Components/Confirm";
import ModalNotif from "contents/Components/ModalNotif";

import ButtonBack from "contents/Components/ButtonBack";

import Config from "config";
import _ from "lodash";
import moment from "moment";
import useAxios from "libs/useAxios";
import secureStorage from "libs/secureStorage";
import { fnumber } from "libs/currency";

class ProfileOffice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: null,
      merchantId: null,


      // name: "",
      // nomorSk: "",
      // director: "",
      // phone: "",
      // email: "",
      // address: "",
      // dateSk: "",
      // accreditation: "",
      // dateAccreditation: "",
      // institution: "",
      // logo: "",
      // remark: "",
      // status: "",
      // kode: "",
      kode: "",
      prefix: "",
      name: "",
      brand: "",
      notary: "",
      notarialNumber: "",
      companyRegistration: "",
      asita: "",
      iata: "",
      siup: "",
      npwp: "",
      memberHimpuh: "",
      permitHaji: "",
      permitUmroh: "",
      email: "",
      phone: "",
      whatsapp: "",
      address: "",
      isActive: false,
    };

    this.modalNotifRef = React.createRef();
  }

  componentDidMount() {
    const user = secureStorage.getItem("user");

    this.setState(
      {
        userId: user.id,
        merchantId: user.Merchant?.id,
      },
      () => {
        [2, 3].includes(user.roleId) && this.loadData(user.Merchant?.id);
      }
    );
  }

  loadData = (merchantId) => {
    useAxios()
      .post(`${Config.ApiUrl}/manage/merchant/get`, { id: merchantId })
      .then((response) => {
        const data = response.data;
        const province = data && data.Province ? data.Province : null;
        const district = data && data.District ? data.District : null;

        this.setState({
          prefix: data.prefix,
          kode: data.kode,
          name: data.name,
          notary: data && data.notary ? data.notary : "",
          notarialNumber: data && data.notarialNumber ? data.notarialNumber : "",
          npwp: data && data.npwp ? data.npwp : "",
          companyRegistration: data && data.companyRegistration ? data.companyRegistration : "",
          siup: data && data.siup ? data.siup : "",
          asita: data && data.asita ? data.asita : "",
          iata: data && data.iata ? data.iata : "",
          memberHimpuh: data && data.memberHimpuh ? data.memberHimpuh : "",
          permitHaji: data && data.permitHaji ? data.permitHaji : "",
          permitUmroh: data && data.permitUmroh ? data.permitUmroh : "",
          brand: data && data.brand ? data.brand : "",
          email: data && data.email ? data.email : "",
          phone: data && data.phone ? data.phone : "",
          address: data && data.address ? data.address : "",
          whatsapp: data && data.whatsapp ? data.whatsapp : "",
          isActive: data.isActive,
          province,
          district,
        });
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          this.modalNotifRef.current.setShow({
            modalTitle: "Gagal",
            modalMessage: err.response.data,
          });
        } else {
          this.modalNotifRef.current.setShow({
            modalTitle: "Gagal",
            modalMessage: "Koneksi jaringan terputus",
          });
        }
      });
  };

  render() {
    const user = secureStorage.getItem("user");
    return (
      <MDBox my={3} mx="auto" width="100%" height="100vh" pb={3}>
        <Grid container spacing={1} justifyContent="center" alignItems="flex-start" height="100%">
          <Grid item xs={12} sm={12} md={12} lg={12} xl={10} xxl={10}>
            <Card>
              <ModalNotif ref={this.modalNotifRef} />
              <MDBox pt={3} px={5}>
                <MDTypography variant="h3">Profil Perusahaan</MDTypography>
              </MDBox>
              <MDBox px={3} mx={3} width="100%" display="flex" justifyContent="flex-end">
                <Grid item xs={12} md={2} lg={2}>
                  <MDButton
                    color="info"
                    variant="gradient"
                    component={Link}
                    to={"/manage/profile-office/edit"}
                  >
                    Ubah
                  </MDButton>
                </Grid>
              </MDBox>
              <MDBox p={2} mx={1}>
                <Grid container mb={3}>
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4} xxl={4} mx="auto">
                    <MDBox py={1} mb={2} display="flex" justifyContent="center">
                      {this.state.logo && (
                        <MDBox mr={2}>
                          <MDAvatar
                            src={`${Config.ApiAsset}/merchant/${this.state.logo}`}
                            alt="avatar"
                          />
                        </MDBox>
                      )}
                      <MDBox>
                        <MDTypography variant="p" fontWeight="medium" fontSize={16}>
                          <MDBox textAlign="center">{this.state.name ? this.state.name : ""}</MDBox>{" "}
                          <MDBox textAlign="center">NO. REG {this.state.kode ? this.state.kode : ""}</MDBox>
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </Grid>
                </Grid>

                {/* informasi umum */}
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <MDBox mb={2}>
                      <MDTypography variant="p" fontWeight="bold" fontSize={16}>
                        Informasi Umum
                      </MDTypography>
                    </MDBox>
                    {/* Brand */}
                    <MDBox>
                      <MDTypography variant="p" fontSize={15}>
                        Nama Merek : {"  "}
                      </MDTypography>
                      <MDTypography variant="p" fontWeight="bold" fontSize={14}>
                        {this.state.brand ? this.state.brand : ""}
                      </MDTypography>
                    </MDBox>
                    {/* Notary */}
                    <MDBox>
                      <MDTypography variant="p" fontSize={15}>
                        Notaris : {"  "}
                      </MDTypography>
                      <MDTypography variant="p" fontWeight="bold" fontSize={14}>
                        {this.state.notary ? this.state.notary : ""}
                      </MDTypography>
                    </MDBox>
                    {/* Akta Pendirian/ Notarial Number */}
                    <MDBox>
                      <MDTypography variant="p" fontSize={15}>
                        Akta Pendirian : {"  "}
                      </MDTypography>
                      <MDTypography variant="p" fontWeight="bold" fontSize={14}>
                        {this.state.notarialNumber ? this.state.notarialNumber : ""}
                      </MDTypography>
                    </MDBox>
                    {/* NPWP */}
                    <MDBox>
                      <MDTypography variant="p" fontSize={15}>
                        NPWP : {"  "}
                      </MDTypography>
                      <MDTypography variant="p" fontWeight="bold" fontSize={14}>
                        {this.state.npwp ? this.state.npwp : ""}
                      </MDTypography>
                    </MDBox>
                    {/* TDP/ Company Registration */}
                    <MDBox>
                      <MDTypography variant="p" fontSize={15}>
                        TDP/ Company Registration : {"  "}
                      </MDTypography>
                      <MDTypography variant="p" fontWeight="bold" fontSize={14}>
                        {this.state.companyRegistration ? this.state.companyRegistration : ""}
                      </MDTypography>
                    </MDBox>
                    {/* SIUP/ Licence of Business Trade */}
                    <MDBox>
                      <MDTypography variant="p" fontSize={15}>
                        SIUP/ Licence of Business Trade : {"  "}
                      </MDTypography>
                      <MDTypography variant="p" fontWeight="bold" fontSize={14}>
                        {this.state.siup ? this.state.siup : ""}
                      </MDTypography>
                    </MDBox>
                    {/* Izin Haji/ Licence for Hajj */}
                    <MDBox>
                      <MDTypography variant="p" fontSize={15}>
                        Izin Haji/ Licence for Hajj : {"  "}
                      </MDTypography>
                      <MDTypography variant="p" fontWeight="bold" fontSize={14}>
                        {this.state.permitHaji ? this.state.permitHaji : ""}
                      </MDTypography>
                    </MDBox>
                    {/* Izin Umrah/ Licence for Umra */}
                    <MDBox>
                      <MDTypography variant="p" fontSize={15}>
                        Izin Umrah/ Licence for Umra : {"  "}
                      </MDTypography>
                      <MDTypography variant="p" fontWeight="bold" fontSize={14}>
                        {this.state.permitUmroh ? this.state.permitUmroh : ""}
                      </MDTypography>
                    </MDBox>
                    {/* ASITA/ ASITA */}
                    <MDBox>
                      <MDTypography variant="p" fontSize={15}>
                        ASITA/ ASITA : {"  "}
                      </MDTypography>
                      <MDTypography variant="p" fontWeight="bold" fontSize={14}>
                        {this.state.asita ? this.state.asita : ""}
                      </MDTypography>
                    </MDBox>
                    {/* IATA/ lATA */}
                    <MDBox>
                      <MDTypography variant="p" fontSize={15}>
                        IATA/ lATA : {"  "}
                      </MDTypography>
                      <MDTypography variant="p" fontWeight="bold" fontSize={14}>
                        {this.state.iata ? this.state.iata : ""}
                      </MDTypography>
                    </MDBox>
                    {/* Anggota HIMPUH */}
                    <MDBox>
                      <MDTypography variant="p" fontSize={15}>
                        Anggota HIMPUH : {"  "}
                      </MDTypography>
                      <MDTypography variant="p" fontWeight="bold" fontSize={14}>
                        {this.state.memberHimpuh ? this.state.memberHimpuh : ""}
                      </MDTypography>
                    </MDBox>
                  </Grid>

                  {/* informasi alamat */}
                  <Grid item xs={12} sm={12} md={6} lg={6} >
                    <MDBox mb={2}>
                      <MDTypography variant="p" fontWeight="bold" fontSize={16}>
                        Informasi Alamat dan Kontak
                      </MDTypography>
                    </MDBox>
                    {/* Email */}
                    <MDBox>
                      <MDTypography variant="p" fontSize={15}>
                        Email : {"  "}
                      </MDTypography>
                      <MDTypography variant="p" fontWeight="bold" fontSize={14}>
                        {this.state.email ? this.state.email : ""}
                      </MDTypography>
                    </MDBox>
                    {/* Phone */}
                    <MDBox>
                      <MDTypography variant="p" fontSize={15}>
                        Telpon :{" "}
                      </MDTypography>
                      <MDTypography variant="p" fontWeight="bold" fontSize={14}>
                        {this.state.phone ? this.state.phone : ""}
                      </MDTypography>
                    </MDBox>
                    {/* Whatsapp */}
                    <MDBox>
                      <MDTypography variant="p" fontSize={15}>
                        Whatsapp :{" "}
                      </MDTypography>
                      <MDTypography variant="p" fontWeight="bold" fontSize={14}>
                        {this.state.whatsapp ? this.state.whatsapp : ""}
                      </MDTypography>
                    </MDBox>
                    {/* Province */}
                    <MDBox>
                      <MDTypography variant="p" fontSize={15}>
                        Provinsi :{" "}
                      </MDTypography>
                      <MDTypography variant="p" fontWeight="bold" fontSize={14}>
                        {this.state.province ? this.state.province.name : ""}
                      </MDTypography>
                    </MDBox>
                    {/* District */}
                    <MDBox>
                      <MDTypography variant="p" fontSize={15}>
                        Kabupaten :{" "}
                      </MDTypography>
                      <MDTypography variant="p" fontWeight="bold" fontSize={14}>
                        {this.state.district ? this.state.district.name : ""}
                      </MDTypography>
                    </MDBox>
                    {/* Address */}
                    <MDBox>
                      <MDTypography variant="p" fontSize={15}>
                        Alamat :{" "}
                      </MDTypography>
                      <MDBox style={{ wordWrap: "break-word", width: "20em" }}>
                        <MDTypography variant="p" fontWeight="bold" fontSize={14}>
                          {this.state.address ? this.state.address : ""}
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                    {/* Status */}
                    <MDBox>
                      <MDTypography variant="p" fontSize={15}>
                        Status :{" "}
                      </MDTypography>
                      <MDTypography variant="p" fontWeight="light" fontSize={14}>
                        <MDBadge
                          variant="contained"
                          badgeContent={this.state.isActive ? "Aktive" : "Tidak"}
                          size="lg"
                          color={this.state.isActive ? "success" : "error"}
                        />
                      </MDTypography>
                    </MDBox>
                  </Grid>

                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    );
  }
}

export default ProfileOffice;
