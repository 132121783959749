/* eslint-disable react/prop-types */
import React from "react"
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import Icon from "@mui/material/Icon"

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

import Confirm from "contents/Components/Confirm"

// Material Dashboard 2 PRO React examples
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";

class ListAirline extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			rows: props.rows ? props.rows : []
		}
	}

	componentDidMount() {

	}

	setData = (rows) => {
		this.setState({ rows })
	}

	render() {
		return (
			<>
				<TableContainer sx={{ boxShadow: "none", borderRadius: "none" }}>
					<Table>
						<MDBox component="thead">
							<TableRow sx={{ backgroundColor: "#f9fafb" }}>
								<DataTableBodyCell
									width="auto"
									align="left"
								>
									<MDTypography variant="caption" fontWeight="medium" textTransform="uppercase">
										NO
									</MDTypography>
								</DataTableBodyCell>
								<DataTableBodyCell
									width="auto"
									align="left"
								>
									<MDTypography variant="caption" fontWeight="medium" textTransform="uppercase">
										Nama
									</MDTypography>
								</DataTableBodyCell>
								<DataTableBodyCell
									width="auto"
									align="left"
								>
									<MDTypography variant="caption" fontWeight="medium" textTransform="uppercase">
										Nomor Penerbangan
									</MDTypography>
								</DataTableBodyCell>
								<DataTableBodyCell
									width="auto"
									align="left"
								>
									<MDTypography variant="caption" fontWeight="medium" textTransform="uppercase">
										Arah
									</MDTypography>
								</DataTableBodyCell>
							</TableRow>
						</MDBox>
						<TableBody>
							{this.state.rows.map((item, key) => (
								<TableRow key={key}>
									<DataTableBodyCell>
										<MDTypography variant="body">
											{key + 1}
										</MDTypography>
									</DataTableBodyCell>
									<DataTableBodyCell>
										<MDTypography variant="body" >
											{item.name ? item.name : "-"}
										</MDTypography>
									</DataTableBodyCell>
									<DataTableBodyCell align="left">
										<MDTypography variant="body">
											{item.flightNumber ? item.flightNumber : "-"}
										</MDTypography>
									</DataTableBodyCell>
									<DataTableBodyCell align="left">
										<MDTypography variant="body">
											{item.direction ? item.direction : "-"}
										</MDTypography>
									</DataTableBodyCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</>
		)
	}
}

ListAirline.propTypes = {
	rows: PropTypes.arrayOf(PropTypes.object)
}

export default ListAirline