import React from "react"
import ReactPdf from "@react-pdf/renderer"

import PdfReceipt from "helper/PdfReceipt"
// import PdfPaymentList from "helper/PdfPaymentList"
import PdfCertificate from "helper/PdfCertificate"
import PdfPoa from "helper/PdfPoa"
import PdfFinancialTravel from "helper/PdfFinancialTravel"
import PdfFinancialOwner from "helper/PdfFinancialOwner"
import PdfDp from "helper/PdfDp"
import PdfInvoices from "helper/PdfInvoices"
import PdfPaymentList from "helper/PdfPaymentList"
import PdfPaymentReceipt from "helper/PdfPaymentReceipt"
import PdfRegistrasi from "helper/PdfRegistrasi"
import PdfRecapPackage from "helper/pdfRecapPackage"

const components = {
    PdfReceipt,
    PdfCertificate,
    PdfPoa,
    PdfDp,
    PdfInvoices,
    PdfPaymentList,
    PdfPaymentReceipt,
    PdfFinancialTravel,
    PdfFinancialOwner,
    PdfRecapPackage,
    PdfRegistrasi // bukti registrasi jamaah
}

const getUrl = async (data, component) => {
    if (data) {
        const Component = components[component]
        const blob = await ReactPdf.pdf(<Component data={data} />).toBlob()
        const url = URL.createObjectURL(blob)
        return url
    }
}

export { getUrl }