/* eslint-disable no-undef */
module.exports = {
    apiKey: "AIzaSyA_YYTXm89_ZMA4A0ZcryieQqefoKV-gEE",
    authDomain: "mabrur-tours.firebaseapp.com",
    projectId: "mabrur-tours",
    storageBucket: "mabrur-tours.appspot.com",
    messagingSenderId: "535743197916",
    appId: "1:535743197916:web:51caaceaa008bb0744fa19",
    measurementId: "G-L100TEYPPB",
    vapidKey: "BA3ZB4uIqCEIqCR-RecjE0hfC5_jf3HSkcrP8AdyNmg5ha8LYpmyVTaK03u3DKvcT2iMtI0LSi2eXrWM5PX9nQc"
}