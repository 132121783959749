// eslint-disable-next-line no-undef
const CryptoJS = require('crypto-js')
// eslint-disable-next-line no-undef
const SecureStorage = require('secure-web-storage')
const SECRET_KEY = CryptoJS.SHA256(navigator.productSub + navigator.platform + navigator.userAgent).toString()

const secureStorage = new SecureStorage(localStorage, {
	hash: function hash(key) {
		key = CryptoJS.SHA256(key, SECRET_KEY)

		return key.toString()
	},
	encrypt: function encrypt(data) {
		data = CryptoJS.AES.encrypt(data, SECRET_KEY)

		data = data.toString()

		return data;
	},
	decrypt: function decrypt(data) {

		data = CryptoJS.AES.decrypt(data, SECRET_KEY)
		try {
			data = data.toString(CryptoJS.enc.Utf8)
		}
		catch (err) {
			localStorage.clear()
			window.location.reload()
		}
		return data
	}
})

export default secureStorage