import React, { useState } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDDatePicker from "components/MDDatePicker";
import MDTypography from "components/MDTypography";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";

import { formatCurrency } from "libs/currency"
import useAxios from "libs/useAxios";
import Config from "config";
import secureStorage from "libs/secureStorage";
import moment from "moment"

import print from "helper/printer"

class ReportTravel extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			user: null,
			merchant: null,
			product: null,
			startDate: "",
			endDate: "",

			// DANA MASUK
			amountTrx: 0,
			refundCommissionAgent: 0,
			refundAdmin: 0,
			incomeTotal: 0,
			// DANA KELUAR
			admin: 0,
			commissionAgent: 0,
			refundTrx: 0,
			outFundTotal: 0,
			// TOTAL
			netIncome: 0,

			merchants: [],

			isLoading: true,
		}
	}

	componentDidMount() {
		const startDate = moment().startOf("year").format("YYYY-MM-DD")
		const endDate = moment().endOf("year").format("YYYY-MM-DD")
		this.setState({ startDate, endDate })
		this.loadData({ startDate, endDate })
	}

	loadData = (params) => {
		this.setState({ isLoading: true });
		const user = secureStorage.getItem("user");

		const payload = {
			startDate: params && params.startDate ? params.startDate : this.state.startDate,
			endDate: params && params.endDate ? params.endDate : this.state.endDate,
		}

		const path = [2, 3].includes(user.roleId) ? '/finance/report/travel' : '/finance/report/owner'
		useAxios()
			.post(`${Config.ApiUrl}${path}`, payload)
			.then((response) => {
				const data = response.data;

				const incomeTotal = parseInt(data.amountTrx ?? 0) + parseInt(data.refundAdmin ?? 0) + parseInt(data.refundCommissionAgent ?? 0)
				const outFundTotal = parseInt(data.admin ?? 0) + parseInt(data.refundTrx ?? 0) + parseInt(data.commissionAgent ?? 0)

				this.setState({
					isLoading: false,
					// IN
					amountTrx: data.amountTrx ?? 0,
					refundAdmin: data.refundAdmin ?? 0,
					refundCommissionAgent: data.refundCommissionAgent ?? 0,
					incomeTotal,
					// OUT
					admin: data.admin ?? 0,
					refundTrx: data.refundTrx ?? 0,
					commissionAgent: data.commissionAgent ?? 0,
					outFundTotal,
					// Total
					netIncome: incomeTotal - outFundTotal
				});
			})
			.catch((err) => {
				console.log(err);
				this.setState({ isLoading: false });
			});
	}

	handlePrint = () => {
		const payload = {
			startDate: this.state.startDate,
			endDate: this.state.endDate,
			// OUT
			amountTrx: this.state.amountTrx,
			refundAdmin: this.state.refundAdmin,
			refundCommissionAgent: this.state.refundCommissionAgent,
			// IN
			admin: this.state.admin,
			commissionAgent: this.state.commissionAgent,
			refundTrx: this.state.refundTrx,
			outFundTotal: this.state.outFundTotal,
			// TOTAL
			netIncome: this.state.netIncome
		};

		print(payload, "PdfFinancialTravel");
	}

	render() {
		const user = secureStorage.getItem('user')

		return (
			<MDBox my={3} mx="auto" width="100%" height="100vh" pb={3}>
				<Grid container spacing={1} justifyContent="center" alignItems="flex-start" height="100%">
					<Grid item xs={12} sm={12} md={12} lg={12} xl={10} xxl={10}>
						<Card>
							{/* FILTER */}
							<MDBox p={5} width="100%" display="flex" justifyContent="flex-start">
								<Grid container spacing={3} justifyContent="flex-start">
									{/* Start Date */}
									<Grid
										item
										xs={12}
										md={3}
										lg={3}
									>
										<MDDatePicker
											input={{
												id: "startDate",
												placeholder: "Tanggal Awal",
												fullWidth: true,
												sx: {
													".MuiInputBase-input": {
														height: "1em !important",
													},
												},
											}}
											value={this.state.startDate}
											onChange={(value) => {
												const date = moment(value[0]).format("YYYY-MM-DD");
												this.setState({ startDate: date });
											}}
										/>
									</Grid>
									{/* End Date */}
									<Grid
										item
										xs={12}
										md={3}
										lg={3}
									>
										<MDDatePicker
											input={{
												id: "endDate",
												placeholder: "Tanggal Akhir",
												fullWidth: true,
												sx: {
													".MuiInputBase-input": {
														height: "1em !important",
													},
												},
											}}
											value={this.state.endDate}
											onChange={(value) => {
												const date = moment(value[0]).format("YYYY-MM-DD");
												this.setState({ endDate: date });
											}}
										/>
									</Grid>
									{/* Button Search */}
									<Grid item xs={12} md={1} lg={1}>
										<MDButton
											color="info"
											variant="gradient"
											onClick={() => {
												const startDate = this.state.startDate ? this.state.startDate : "";
												const endDate = this.state.endDate ? this.state.endDate : "";
												this.loadData({
													startDate,
													endDate,
													merchantId: this.state.merchant ? this.state.merchant.id : null,
												});
											}}
											iconOnly
										>
											<Icon>search</Icon>
										</MDButton>
									</Grid>
									{/* Button Print */}
									<Grid item xs={12} md={3} lg={3} ml="auto">
										<MDButton
											color="info"
											variant="gradient"
											onClick={() => {
												this.handlePrint();
											}}
										>
											<Icon>print</Icon> Cetak
										</MDButton>
									</Grid>
								</Grid>
							</MDBox>
							{/* CONTENT */}
							<MDBox p={5}>
								<Grid container justifyContent="center">
									<Grid item xs={12} md={12} lg={10} px={3}>
										<TableContainer sx={{ padding: "20px" }}>
											{/* Title */}
											<MDBox p={5}>
												<MDTypography variant="h4" align="center" fontWeight="medium">
													LAPORAN KEUANGAN
												</MDTypography>
												<MDTypography variant="h6" align="center">
													{user.Merchant?.name.toUpperCase()}
												</MDTypography>
												<MDTypography variant="h6" align="center" fontWeight="medium">
													dari{" "}
													{this.state.startDate !== ""
														? this.state.startDate
														: moment().startOf("year").format("YY-MM-DD")}{" "}
													sampai{" "}
													{this.state.endDate !== ""
														? this.state.endDate
														: moment().endOf("year").format("YY-MM-DD")}
												</MDTypography>
											</MDBox>
											{/* Table */}
											<Table>
												<TableBody>
													<TableRow>
														<DataTableBodyCell noBorder>
															<MDTypography variant="body" fontWeight="medium">
																Pendapatan
															</MDTypography>
														</DataTableBodyCell>
													</TableRow>
													{/* Transaction Packet */}
													<TableRow>
														<DataTableBodyCell noBorder>
															<MDTypography variant="body" p={10}>
																Transaksi Paket
															</MDTypography>
														</DataTableBodyCell>

														<DataTableBodyCell noBorder>
															<MDTypography variant="body">
																{formatCurrency(this.state.amountTrx)}
															</MDTypography>
														</DataTableBodyCell>
													</TableRow>
													{/* Refund Agen Commission */}
													<TableRow>
														<DataTableBodyCell noBorder>
															<MDTypography variant="body" p={10}>
																Refund Komisi Agen
															</MDTypography>
														</DataTableBodyCell>

														<DataTableBodyCell noBorder>
															<MDTypography variant="body">
																{formatCurrency(this.state.refundCommissionAgent)}
															</MDTypography>
														</DataTableBodyCell>
													</TableRow>
													{/* Refund Admin */}
													<TableRow>
														<DataTableBodyCell noBorder>
															<MDTypography variant="body" p={10}>
																Refund Biaya Admin
															</MDTypography>
														</DataTableBodyCell>

														<DataTableBodyCell noBorder>
															<MDTypography variant="body">
																{formatCurrency(this.state.refundAdmin)}
															</MDTypography>
														</DataTableBodyCell>
													</TableRow>
													{/* Total incomeTotal */}
													<TableRow>
														<DataTableBodyCell>
															<MDTypography variant="body" fontWeight="medium">
																Total Dana Masuk
															</MDTypography>
														</DataTableBodyCell>
														<DataTableBodyCell>
															<MDTypography variant="body" fontWeight="medium">
																{formatCurrency(this.state.incomeTotal)}
															</MDTypography>
														</DataTableBodyCell>
													</TableRow>

													{/* OutFund */}
													<TableRow>
														<DataTableBodyCell noBorder>
															<MDTypography variant="body" fontWeight="medium">
																Pengeluaran
															</MDTypography>
														</DataTableBodyCell>
													</TableRow>
													{/* Agen Commission */}
													<TableRow>
														<DataTableBodyCell noBorder>
															<MDTypography variant="body" p={10}>
																Total Komisi Agen
															</MDTypography>
														</DataTableBodyCell>

														<DataTableBodyCell noBorder>
															<MDTypography variant="body">
																{formatCurrency(this.state.commissionAgent)}
															</MDTypography>
														</DataTableBodyCell>
													</TableRow>
													{/* Refund Admin */}
													<TableRow>
														<DataTableBodyCell noBorder>
															<MDTypography variant="body" p={10}>
																Total Biaya Admin
															</MDTypography>
														</DataTableBodyCell>

														<DataTableBodyCell noBorder>
															<MDTypography variant="body">{formatCurrency(this.state.admin)}</MDTypography>
														</DataTableBodyCell>
													</TableRow>
													{/* Refund Commission Agent */}
													<TableRow>
														<DataTableBodyCell noBorder>
															<MDTypography variant="body" p={10}>
																Refund Transaksi
															</MDTypography>
														</DataTableBodyCell>

														<DataTableBodyCell noBorder>
															<MDTypography variant="body">
																{formatCurrency(this.state.refundTrx)}
															</MDTypography>
														</DataTableBodyCell>
													</TableRow>

													{/* Total incomeTotal */}
													<TableRow>
														<DataTableBodyCell>
															<MDTypography variant="body" fontWeight="medium">
																Total Dana Keluar
															</MDTypography>
														</DataTableBodyCell>
														<DataTableBodyCell>
															<MDTypography variant="body" fontWeight="medium">
																{formatCurrency(this.state.outFundTotal)}
															</MDTypography>
														</DataTableBodyCell>
													</TableRow>
													{/* Total incomeTotal */}
													<TableRow>
														<DataTableBodyCell>
															<MDTypography variant="body" fontWeight="medium">
																Laba/(Rugi) Bersih
															</MDTypography>
														</DataTableBodyCell>
														<DataTableBodyCell>
															<MDTypography variant="body" fontWeight="medium">
																{formatCurrency(this.state.netIncome)}
															</MDTypography>
														</DataTableBodyCell>
													</TableRow>

												</TableBody>
											</Table>
										</TableContainer>
									</Grid>
								</Grid>
							</MDBox>
						</Card>
					</Grid>
				</Grid>
			</MDBox>
		)
	}
}

export default ReportTravel