import React, { useState } from "react";

// react-router-dom components
import { Navigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid"
import AppBar from "@mui/material/AppBar"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"

// @mui icons
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg"
import LOGO from "assets/img/logoACC.png"

import BasicLayout from "./BasicLayout"

import FormMerchant from "contents/Manage/Merchant/components/Form"
import FormAgent from "contents/Manage/Agent/components/Form";

function Register() {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal")
  const [activeTab, setActiveTab] = useState(0)

  const handleSetTabValue = (event, newValue) => setActiveTab(newValue)

  return (
    <BasicLayout image={bgImage}>
      <MDBox my={3} >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} lg={12}>
            <AppBar position="static">
              <Tabs orientation={tabsOrientation} value={activeTab} onChange={handleSetTabValue}>
                <Tab label="Biro" />
                <Tab label="Agen" />
              </Tabs>
            </AppBar>
          </Grid>
        </Grid>
      </MDBox>

      {activeTab === 0 ? <FormMerchant viewId={2} /> : <FormAgent viewId={2} />}
    </BasicLayout>
  )
}

export default Register;
