/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import MDDatePicker from "components/MDDatePicker";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MiniFormCard from "contents/Components/FormCard/MiniFormCard";

import ModalNotif from "contents/Components/ModalNotif";
import withRouter from "contents/Components/withRouter";

import Config from "config";
import moment from "moment";
import _ from "lodash";

import useAxios from "libs/useAxios";
import secureStorage from "libs/secureStorage"
import { fnumber } from "libs/currency"

class RegisterMember extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			// Query Params
			productCode: "",
			merchantCode: "",

			kode: "OTOMATIS",
			merchant: null,
			product: null,
			roomType: null,
			statusPerson: null,
			province: null,
			district: null,
			tourLeader: "",

			// Jamaah
			nik: "",
			nokk: "",
			name: "",
			gender: "",
			paymentType: "",
			placeBirth: "",
			dateBirth: "",
			address: "",
			email: "",
			phone: "",

			// Other
			nopassport: "",
			passportPlace: "",
			passportDate: "",
			effectiveDate: "",
			expiredDate: "",

			merchants: [],
			products: [],
			provinces: [],
			districts: [],
			roomTypes: [],
			statusPersons: [
				{ id: 1, label: "BELUM KAWIN" },
				{ id: 2, label: "KAWIN" },
				{ id: 3, label: "CERAI HIDUP" },
				{ id: 4, label: "CERAI MATI" }
			],
			paymentTypes: [
				{ id: 1, label: "KREDIT" },
				{ id: 2, label: "CASH" },
			],

			provinceOpen: false,
			districtOpen: false,
			merchantOpen: false,
			genderOpen: false,
			paymentTypeOpen: false,
			statusPersonOpen: false,
			productOpen: false,
			roomTypeOpen: false,

			error: [],
			success: [],

			disabledSubmit: false,
			redirect: null,
		}

		this.inputRef = React.createRef()
		this.modalNotifRef = React.createRef()
	}

	componentDidMount() {
		const user = secureStorage.getItem('user')
		const queryParameters = new URLSearchParams(window.location.search)
		const productCode = queryParameters.get('productCode')
		const merchantCode = queryParameters.get('merchantCode')

		this.setState({
			productCode,
			merchantCode
		}, () => {
			this.loadMerchants(merchantCode, productCode)
			this.loadProvince()
		})
	}

	handleChange = (e) => {
		const { id, value } = e.target;
		this.setState({
			[id]: value,
		});
	};

	handleBlur = (e) => {
		const user = this.state.user;
		const { id, value } = e.target;
		// eslint-disable-next-line no-empty
		if (value) {
			this.setState({
				success: { ...this.state.success, [e.target.id]: true },
				error: { ...this.state.error, [e.target.id]: false },
			});
		} else {
			this.setState({
				success: { ...this.state.success, [e.target.id]: false },
				error: { ...this.state.error, [e.target.id]: true },
			})
		}
	}

	loadMerchants = (merchantCode, productCode) => {
		useAxios()
			.post(`${Config.ApiUrl}/manage/merchant/dropdown`)
			.then((response) => {
				const data = response.data

				const merchant = _.find(data, { kode: merchantCode })
				merchant && this.loadProduct({ merchantId: merchant.id, productCode })

				this.setState({
					merchants: data.map((item) => ({ id: item.id, label: item.name })),
					merchant: merchant ? { id: merchant.id, label: `${merchant.kode} - ${merchant.name}` } : null,
					success: { ...this.state.success, merchant: merchant ? true : false },
					error: { ...this.state.error, merchant: merchant ? false : true }
				});
			})
			.catch((err) => {
				console.log(err)
			})
	}

	loadProduct = (params) => {
		const productCode = params.productCode
		const merchantId = params && params.merchantId ? { merchantId: params.merchantId } : {}

		const payload = {
			...merchantId,
			type: "order"
		}

		useAxios()
			.post(`${Config.ApiUrl}/product/dropdown`, payload)
			.then((response) => {
				const data = response.data

				const product = _.find(data, { kode: productCode })
				const tourLeader = product && product.TourLeader ? product.TourLeader.name : ""
				product && this.loadPrice(product.id)

				this.setState({
					tourLeader,
					products: data.map((item) => ({ id: item.id, label: `${item.kode} - ${item.name}`, tourLeader: item.TourLeader })),
					product: product ? { id: product.id, label: `${product.kode} - ${product.name}` } : null,
					roomType: null,
					roomTypes: [],
					success: {
						...this.state.success,
						roomType: false,
						product: product ? true : false,
						tourLeader: tourLeader ? true : false,
					},
					error: {
						...this.state.error,
						roomType: true,
						product: product ? false : true,
						tourLeader: tourLeader ? false : true,
					}
				})
			})
			.catch((err) => {
				console.log(err);
			})
	}

	loadPrice = (productId) => {
		useAxios()
			.post(`${Config.ApiUrl}/product/price/get`, { productId })
			.then((response) => {
				const data = response.data
				const roomTypes = data.map((item) => {
					const RoomType = item.RoomType

					return {
						id: item.id,
						label: `${RoomType.name} - ${fnumber(item.amount)} - ${fnumber(item.downPayment)}`,
					};
				})

				this.setState({
					roomTypes,
					roomType: null,
					success: { ...this.state.success, roomType: false },
					error: { ...this.state.error, roomType: true }
				})
			})
			.catch((err) => {
				console.log(err)
			})
	}

	loadProvince = () => {
		useAxios()
			.post(`${Config.ApiUrl}/master/region/province/dropdown`, { countryId: 1 })
			.then(response => {
				const data = response.data
				const provinces = data.map(item => ({
					id: item.id,
					label: item.name
				}))

				this.setState({
					province: null,
					provinces,
					district: null,
					districts: [],
					success: { ...this.state.success, province: false, district: false },
					error: { ...this.state.error, province: true, district: true }
				})
			})
			.catch(err => {
				console.log(err)
			})
	}

	loadDistrict = (provinceId) => {
		useAxios()
			.post(`${Config.ApiUrl}/master/region/district/dropdown`, { provinceId })
			.then(response => {
				const data = response.data
				const districts = data.map(item => ({ id: item.id, label: item.name }))

				this.setState({
					districts,
					district: null,
					success: { ...this.state.success, district: false },
					error: { ...this.state.district, district: true }
				})
			})
			.catch(err => {
				console.log(err)
			})
	}

	resetForm = () => {
		const merchant = !this.state.merchantCode ? { merchant: null, provinces: [] } : {}
		const product = !this.state.productCode ? { product: null, roomType: null, roomTypes: [], tourLeader: "" } : {}
		this.setState({
			// TOP
			...merchant,
			...product,
			// BOTTOM LEFT
			nik: "",
			nokk: "",
			name: "",
			gender: "",
			paymentType: "",
			placeBirth: "",
			dateBirth: "",
			address: "",
			email: "",
			phone: "",
			province: null,
			district: null,

			districts: [],
			// BOTTOM RIGHT
			nopassport: "",
			passportPlace: "",
			passportDate: "",
			effectiveDate: "",
			expiredDate: "",

			disabledSubmit: false
		})
	}

	validateMsg = () => {
		const success = this.state.success

		if (!success.merchant) return "Biro Travel"
		else if (!success.product) return "Paket"
		else if (!success.roomType) return "Jenis Kamar"
		else if (!success.nik) return "Nomor KTP"
		else if (!success.nokk) return "Nomor KK"
		else if (!success.name) return "Nama"
		else if (!success.gender) return "Jenis Kelamin"
		else if (!success.paymentType) return "Jenis Pembayaran"
		else if (!success.dateBirth) return "Tanggal Lahir"
		else if (!success.placeBirth) return "Tempat Lahir"
		else if (!success.email) return "Email"
		else if (!success.phone) return "Nomor Telpon"
		else return "Alamat"

		// else if (!success.province) return "Provinsi"
		// else if (!success.district) return "Kabupaten/Kota"
		// else if (!success.nopassport) return "Nomor Passpor"
		// else if (!success.passportPlace) return "Tempat Terbit Passpor"
		// else if (!success.passportDate) return "Tanggal Pembuatan Passpor"
		// else if (!success.effectiveDate) return "Tanggal Terbit Passpor"
		// else return "Tanggal Kadaluarsa Passpor"

	}

	handleSubmit = () => {
		const success = this.state.success

		console.log({ success })
		if (
			success.merchant &&
			success.product &&
			success.roomType &&
			success.nik &&
			success.nokk &&
			success.name &&
			success.gender &&
			success.email &&
			success.phone &&
			success.paymentType &&
			success.statusPerson &&
			success.placeBirth &&
			success.dateBirth
			// success.nopassport &&
			// success.passportPlace &&
			// success.passportDate &&
			// success.effectiveDate &&
			// success.expiredDate
		) {
			this.sendData()
		}
		else {
			const message = this.validateMsg()
			this.modalNotifRef.current.setShow({
				modalTitle: "Gagal",
				modalMessage: `Data ${message} masih kosong, Silahkan di cek kembali !`,
			})
		}
	}

	sendData = () => {
		this.setState({ disabledSubmit: true })
		const user = secureStorage.getItem("user")

		const payload = {
			// Product Package
			parentId: user.id,
			merchantId: this.state.merchant ? this.state.merchant?.id : null,
			productId: this.state.product ? this.state.product?.id : null,
			productPriceId: this.state.roomType ? this.state.roomType?.id : null,
			// Data Member
			nik: this.state.nik,
			nokk: this.state.nokk,
			name: this.state.name,
			gender: this.state.gender,
			email: this.state.email,
			phone: this.state.phone,
			paymentType: this.state.paymentType ? this.state.paymentType?.label : "",
			statusPersonId: this.state.statusPerson ? this.state.statusPerson?.id : null,
			placeBirth: this.state.placeBirth,
			dateBirth: this.state.dateBirth,
			provinceId: this.state.province?.id,
			districtId: this.state.district?.id,
			address: this.state.address,
			// Data Passport
			nopassport: this.state.nopassport,
			passportPlace: this.state.passportPlace,
			passportDate: this.state.passportDate,
			effectiveDate: this.state.effectiveDate,
			expiredDate: this.state.expiredDate,
		}

		console.log(payload)
		useAxios()
			.post(`${Config.ApiUrl}/trx/order`, payload)
			.then(response => {
				const data = response.data

				this.modalNotifRef.current.setShow({
					modalTitle: "Sukses",
					modalMessage: data && data.message ? data.message : "Transaksi berhasil di proses",
					onClose: () => {
						this.resetForm()
					},
				});
			})
			.catch((err) => {
				console.log(err)
				this.setState({ disabledSubmit: false })
				if (err.response) {
					this.modalNotifRef.current.setShow({
						modalTitle: "Gagal",
						modalMessage: err.response ? err.response.data : "Server Internal Error",
					});
				}
				// eslint-disable-next-line no-empty
				else {
					this.modalNotifRef.current.setShow({
						modalTitle: "Gagal",
						modalMessage: "Koneksi jaringan terputus",
					});
				}
			})
	}

	render() {
		const user = secureStorage.getItem('user')

		return (
			<MDBox pb={3} my={3}>
				<ModalNotif ref={this.modalNotifRef} />
				<Card>
					<MDBox p={3} lineHeight={1} display="flex" justifyContent="center">
						<MDTypography variant="h5" fontWeight="medium">
							FORM PENDAFTARAN
						</MDTypography>
					</MDBox>

					<MDBox p={3}>
						<MDBox component="form" role="form">
							<Grid container spacing={3}>
								<Grid item xs={12} md={6} lg={6}>
									{/* KODE REGISTER */}
									<MDBox mb={2}>
										<MDInput
											id="kode"
											type="text"
											label="No Register"
											value={this.state.kode}
											fullWidth
											readOnly
											disabled
										/>
									</MDBox>
									{/* Merchant */}
									<MDBox mb={2}>
										<Autocomplete
											disableClearable
											value={this.state.merchant}
											options={this.state.merchants}
											open={this.state.merchantOpen}
											onOpen={() => this.setState({ merchantOpen: true })}
											onClose={() => this.setState({ merchantOpen: false })}
											onFocus={() => this.setState({ merchantOpen: true })}
											onChange={(e, value) => {
												if (value) {
													this.setState({
														merchant: value,
														success: { ...this.state.success, merchant: true },
														error: { ...this.state.error, merchant: false }
													}, () => {
														this.loadProduct({ merchantId: value.id }) // by merchantId
													})
												}
											}}
											sx={{
												".MuiAutocomplete-input": {
													padding: "7.5px 5px 7.5px 8px !important",
												},
												".MuiOutlinedInput-root": {
													padding: "4px !important",
												},
											}}
											isOptionEqualToValue={(option, value) => option.id === value.id}
											renderInput={(params) => (
												<MDInput label="Pilih Biro Travel" {...params} />
											)}
										/>
									</MDBox>
									{/* Product */}
									<MDBox mb={2}>
										<Autocomplete
											disableClearable
											value={this.state.product}
											options={this.state.products}
											open={this.state.productOpen}
											onOpen={() => this.setState({ productOpen: true })}
											onClose={() => this.setState({ productOpen: false })}
											onFocus={() => this.setState({ productOpen: true })}
											onChange={(e, value) => {
												if (value) {
													const tourLeader = value.tourLeader
													this.setState({
														tourLeader: tourLeader?.name,
														product: { id: value.id, label: value.label },
														success: { ...this.state.success, product: true, tourLeader: true },
														error: { ...this.state.error, product: false, tourLeader: false },
													}, () => {
														this.loadPrice(value.id) // productId
													})
												}
											}}
											sx={{
												".MuiAutocomplete-input": {
													padding: "7.5px 5px 7.5px 8px !important",
												},
												".MuiOutlinedInput-root": {
													padding: "4px !important"
												}
											}}
											isOptionEqualToValue={(option, value) => option.id === value.id}
											renderInput={(params) => (
												<MDInput {...params} label="Pilih Paket Umroh / Haji" />
											)}
										/>
									</MDBox>
									{/* RoomType Hotel */}
									<MDBox mb={2}>
										<Autocomplete
											disableClearable
											value={this.state.roomType}
											options={this.state.roomTypes}
											open={this.state.roomTypeOpen}
											onOpen={() => this.setState({ roomTypeOpen: true })}
											onClose={() => this.setState({ roomTypeOpen: false })}
											onFocus={() => this.setState({ roomTypeOpen: true })}
											onChange={(e, value) => {
												if (value) {
													this.setState({
														roomType: value,
														success: { ...this.state.success, roomType: true },
														error: { ...this.state.error, roomType: false }
													})
												}
											}}
											sx={{
												".MuiAutocomplete-input": {
													padding: "7.5px 5px 7.5px 8px !important",
												},
												".MuiOutlinedInput-root": {
													padding: "4px !important"
												}
											}}
											isOptionEqualToValue={(option, value) => option.id === value.id}
											renderInput={(params) => (
												<MDInput {...params} label="Pilih Jenis Kamar" />
											)}
										/>
									</MDBox>
									{/* Tour Leader */}
									<MDBox mb={2}>
										<MDInput
											disabled
											readOnly
											fullWidth
											type="text"
											id="tourLeader"
											label="Tour Leader"
											value={this.state.tourLeader}
											success={this.state.success ? this.state.success.tourLeader : false}
											error={this.state.error ? this.state.error.tourLeader : false}
										/>
									</MDBox>
								</Grid>
							</Grid>

							<Grid container spacing={3} mb={1}>
								<Grid item xs={6} md={6}>
									<MDBox p={1} display="flex" justifyContent="center">
										<MDTypography fontWeight="medium">FORM JAMAAH</MDTypography>
									</MDBox>
								</Grid>
								<Grid item xs={6} md={6}>
									<MDBox p={1} display="flex" justifyContent="center">
										<MDTypography fontWeight="medium">DATA SUSULAN</MDTypography>
									</MDBox>
								</Grid>
							</Grid>

							<Grid container spacing={3}>
								<Grid item xs={12} md={6} lg={6}>
									{/* NIK */}
									<MDBox mb={2}>
										<MDInput
											id="nik"
											type="text"
											label="NO KTP"
											value={this.state.nik}
											onChange={this.handleChange}
											onBlur={this.handleBlur}
											success={this.state.success ? this.state.success.nik : false}
											error={this.state.error ? this.state.error.nik : false}
											fullWidth
										/>
									</MDBox>
									{/* NO KK */}
									<MDBox mb={2}>
										<MDInput
											id="nokk"
											type="text"
											label="NO KK"
											value={this.state.nokk}
											onChange={this.handleChange}
											onBlur={this.handleBlur}
											success={this.state.success ? this.state.success.nokk : false}
											error={this.state.error ? this.state.error.nokk : false}
											fullWidth
										/>
									</MDBox>
									{/* NAME */}
									<MDBox mb={2}>
										<MDInput
											id="name"
											type="text"
											label="Nama"
											value={this.state.name}
											onChange={this.handleChange}
											onBlur={this.handleBlur}
											success={this.state.success ? this.state.success.name : false}
											error={this.state.error ? this.state.error.name : false}
											fullWidth
										/>
									</MDBox>
									{/* GENDER */}
									<MDBox mb={2}>
										<Autocomplete
											disableClearable
											value={this.state.gender}
											open={this.state.genderOpen}
											onOpen={() => this.setState({ genderOpen: true })}
											onClose={() => this.setState({ genderOpen: false })}
											onFocus={() => this.setState({ genderOpen: true })}
											options={["Male", "Female"]}
											onChange={(e, value) => {
												if (value) {
													this.setState({
														gender: value,
														error: { ...this.state.error, gender: false },
														success: { ...this.state.success, gender: true },
													});
												}
											}}
											sx={{
												".MuiAutocomplete-input": {
													padding: "7.5px 5px 7.5px 8px !important",
												},
												".MuiOutlinedInput-root": {
													padding: "2px !important",
												},
											}}
											isOptionEqualToValue={(option, value) => option === value}
											renderInput={(params) => <MDInput label="Jenis Kelamin" {...params} />}
										/>
									</MDBox>
									{/* Payment Type */}
									<MDBox mb={2}>
										<Autocomplete
											disableClearable
											value={this.state.paymentType}
											open={this.state.paymentTypeOpen}
											onOpen={() => this.setState({ paymentTypeOpen: true })}
											onClose={() => this.setState({ paymentTypeOpen: false })}
											onFocus={() => this.setState({ paymentTypeOpen: true })}
											options={this.state.paymentTypes}
											onChange={(e, value) => {
												if (value) {
													this.setState({
														paymentType: value,
														error: { ...this.state.error, paymentType: false },
														success: { ...this.state.success, paymentType: true },
													});
												}
											}}
											sx={{
												".MuiAutocomplete-input": {
													padding: "7.5px 5px 7.5px 8px !important",
												},
												".MuiOutlinedInput-root": {
													padding: "2px !important",
												},
											}}
											isOptionEqualToValue={(option, value) => option === value}
											renderInput={(params) => <MDInput label="Jenis Pembayaran" {...params} />}
										/>
									</MDBox>
									{/* Status Person */}
									<MDBox mb={2}>
										<Autocomplete
											disableClearable
											open={this.state.statusPersonOpen}
											onOpen={() => this.setState({ statusPersonOpen: true })}
											onClose={() => this.setState({ statusPersonOpen: false })}
											onFocus={() => this.setState({ statusPersonOpen: true })}
											options={this.state.statusPersons}
											value={this.state.statusPerson}
											onChange={(e, value) => {
												if (value) {
													this.setState({
														statusPerson: value,
														error: { ...this.state.error, statusPerson: false },
														success: { ...this.state.success, statusPerson: true },
													});
												}
											}}
											sx={{
												".MuiAutocomplete-input": {
													padding: "7.5px 5px 7.5px 8px !important",
												},
												".MuiOutlinedInput-root": {
													padding: "4px !important",
												},
											}}
											isOptionEqualToValue={(option, value) => option.id === value.id}
											renderInput={(params) => <MDInput label="Status" {...params} />}
										/>
									</MDBox>
									{/* Place Birth */}
									<MDBox mb={2}>
										<MDInput
											id="placeBirth"
											type="text"
											label="Tempat Lahir"
											value={this.state.placeBirth}
											onChange={this.handleChange}
											onBlur={this.handleBlur}
											success={this.state.success ? this.state.success.placeBirth : false}
											error={this.state.error ? this.state.error.placeBirth : false}
											fullWidth
										/>
									</MDBox>
									{/* Date Birth */}
									<MDBox mb={2}>
										<MDDatePicker
											input={{
												id: "dateBirth",
												placeholder: "Tanggal Lahir",
												fullWidth: true,
											}}
											value={this.state.dateBirth}
											onChange={(value) => {
												const date = moment(value[0]).format("YYYY-MM-DD");
												this.setState({
													dateBirth: date,
													error: { ...this.state.error, dateBirth: false },
													success: { ...this.state.success, dateBirth: true },
												});
											}}
										/>
									</MDBox>
									{/* Email */}
									<MDBox mb={2}>
										<MDInput
											id="email"
											type="text"
											label="Email"
											value={this.state.email}
											onChange={this.handleChange}
											onBlur={this.handleBlur}
											success={this.state.success ? this.state.success.email : false}
											error={this.state.error ? this.state.error.email : false}
											fullWidth
										/>
									</MDBox>
									{/* Phone */}
									<MDBox mb={2}>
										<MDInput
											id="phone"
											type="text"
											label="Nomor Telpon"
											value={this.state.phone}
											onChange={this.handleChange}
											onBlur={this.handleBlur}
											success={this.state.success ? this.state.success.phone : false}
											error={this.state.error ? this.state.error.phone : false}
											fullWidth
										/>
										<MDTypography color="error" variant="span" fontWeight="bold" fontSize="12px">
											Nomor Telpon yang terhubung dengan WhatsApp
										</MDTypography>
									</MDBox>
									{/* Province */}
									<MDBox mb={2}>
										<Autocomplete
											disableClearable
											value={this.state.province}
											options={this.state.provinces}
											open={this.state.provinceOpen}
											onOpen={() => this.setState({ provinceOpen: true })}
											onClose={() => this.setState({ provinceOpen: false })}
											onFocus={() => this.setState({ provinceOpen: true })}
											onChange={(e, value) => {
												if (value) {
													this.setState({
														province: value,
														success: { ...this.state.success, province: true },
														error: { ...this.state.error, province: false }
													}, () => {
														this.loadDistrict(value.id) // by provinceId
													})
												}
											}}
											sx={{
												".MuiAutocomplete-input": {
													padding: "7.5px 5px 7.5px 8px !important",
												},
												".MuiOutlinedInput-root": {
													padding: "4px !important"
												}
											}}
											isOptionEqualToValue={(option, value) => option.id === value.id}
											renderInput={(params) => (
												<MDInput {...params} label="Pilih Provinsi" />
											)}
										/>
									</MDBox>
									{/* District */}
									<MDBox mb={2}>
										<Autocomplete
											disableClearable
											value={this.state.district}
											options={this.state.districts}
											open={this.state.districtOpen}
											onOpen={() => this.setState({ districtOpen: true })}
											onClose={() => this.setState({ districtOpen: false })}
											onFocus={() => this.setState({ districtOpen: true })}
											onChange={(e, value) => {
												if (value) {
													this.setState({
														district: value,
														success: { ...this.state.success, district: true },
														error: { ...this.state.error, district: false }
													})
												}
											}}
											sx={{
												".MuiAutocomplete-input": {
													padding: "7.5px 5px 7.5px 8px !important",
												},
												".MuiOutlinedInput-root": {
													padding: "4px !important"
												}
											}}
											isOptionEqualToValue={(option, value) => option.id === value.id}
											renderInput={(params) => (
												<MDInput {...params} label="Pilih Kabupaten/Kota" />
											)}
										/>
									</MDBox>
									{/* Address */}
									<MDBox mb={2}>
										<MDInput
											id="address"
											type="text"
											multiline
											rows={3}
											label="Alamat"
											value={this.state.address}
											onChange={this.handleChange}
											onBlur={this.handleBlur}
											success={this.state.success ? this.state.success.address : false}
											error={this.state.error ? this.state.error.address : false}
											fullWidth
										/>
									</MDBox>
								</Grid>
								<Grid item xs={12} md={6} lg={6}>
									{/* No Passport */}
									<MDBox mb={2}>
										<MDInput
											id="nopassport"
											type="text"
											label="No Passport"
											value={this.state.nopassport}
											onChange={this.handleChange}
											onBlur={this.handleBlur}
											success={this.state.success ? this.state.success.nopassport : false}
											error={this.state.error ? this.state.error.nopassport : false}
											fullWidth
										/>
									</MDBox>
									{/* Place Passport */}
									<MDBox mb={2}>
										<MDInput
											id="passportPlace"
											type="text"
											label="Tempat Terbit"
											value={this.state.passportPlace}
											onChange={this.handleChange}
											onBlur={this.handleBlur}
											success={this.state.success ? this.state.success.passportPlace : false}
											error={this.state.error ? this.state.error.passportPlace : false}
											fullWidth
										/>
									</MDBox>
									{/* Passport Date */}
									<MDBox mb={2}>
										<MDDatePicker
											input={{
												id: "passportDate",
												placeholder: "Tanggal Pembuatan Paspor",
												fullWidth: true,
											}}
											value={this.state.passportDate}
											onChange={(value) => {
												const date = moment(value[0]).format("YYYY-MM-DD");
												this.setState({
													passportDate: date,
													success: { ...this.state.success, passportDate: true },
													error: { ...this.state.error, passportDate: false },
												});
											}}
										/>
									</MDBox>
									{/* Effective Date */}
									<MDBox mb={2}>
										<MDDatePicker
											input={{
												id: "effectiveDate",
												placeholder: "Tanggal Terbit Paspor",
												fullWidth: true,
											}}
											value={this.state.effectiveDate}
											onChange={(value) => {
												const date = moment(value[0]).format("YYYY-MM-DD");
												this.setState({
													effectiveDate: date,
													success: { ...this.state.success, effectiveDate: true },
													error: { ...this.state.error, effectiveDate: false },
												});
											}}
										/>
									</MDBox>
									{/* Expired Date */}
									<MDBox mb={2}>
										<MDDatePicker
											input={{
												id: "expiredDate",
												placeholder: "Tanggal Kadaluarsa Paspor",
												fullWidth: true,
											}}
											value={this.state.expiredDate}
											onChange={(value) => {
												const date = moment(value[0]).format("YYYY-MM-DD");
												this.setState({
													expiredDate: date,
													success: { ...this.state.success, expiredDate: true },
													error: { ...this.state.error, expiredDate: false },
												});
											}}
										/>
									</MDBox>
								</Grid>
							</Grid>

							{/* BUTTON ACTION */}
							<Grid container spacing={3}>
								<Grid item xs={12} md={12} lg={12}>
									<MDBox pt={3} display="flex" justifyContent="space-between">
										<MDButton variant="gradient" color="error" onClick={this.resetForm}>
											RESET
										</MDButton>
										<MDButton
											type="button"
											variant="gradient"
											color="info"
											disabled={this.state.disabledSubmit}
											onClick={this.handleSubmit}
										>
											SUBMIT
										</MDButton>
									</MDBox>
								</Grid>
							</Grid>

						</MDBox>
					</MDBox>
				</Card>
			</MDBox>
		)
	}
}

export default withRouter(RegisterMember)