import React from "react";

// react-router-dom components
import { Navigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import MuiLink from "@mui/material/Link";
import Grid from "@mui/material/Grid";
// @mui icons
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import LOGO from "assets/img/logoACC.png";

import BasicLayout from "contents/Login/BasicLayout";

import axios from "axios";
import Config from "config";
import secureStorage from "libs/secureStorage";

import Notif from "contents/Components/Notif";
import DialogForm from "contents/Components/DialogForm";

class Verifycation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resetPass: null,
      verCode: "",
      confirmCode: "",

      redirect: false,
      submitDisabled: false,
      submitRepeatDisabled: false
    };

    this.notifRef = React.createRef();
  }

  componentDidMount() {
    const resetPass = secureStorage.getItem("resetPass");
    if (!resetPass) {
      this.setState({ redirect: "/login" });
    } else {
      this.setState({ resetPass });
    }
  }

  handleChange = (e) => {
    const { id, value } = e.target;
    if (id === "vercode") this.setState({ verCode: value });
  };

  handleTimer = () => {
    let timer1 = setTimeout(() => this.setState({ submitRepeatDisabled: false }), 5 * 1000)
  }

  handleChangeCode = () => {
    this.setState({ submitRepeatDisabled: true })
    this.handleTimer()
    const resetPass = secureStorage.getItem("resetPass");
    const payload = {
      phone: resetPass.phone,
      email: resetPass.email
    }

    axios
      .post(`${Config.ApiUrl}/user/reset/sendotp`, payload)
      .then((response) => {
        const data = response.data;
        secureStorage.setItem("resetPass", data);
        this.notifRef.current.setShow({
          show: true,
          message: "Kode berhasil dikirim ulang",
          color: "success",
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ submitDisabled: false });
        this.notifRef.current.setShow({
          show: true,
          message:
            err.response && err.response.data ? err.response.data : "Terjadi kesalahan pada system",
          color: "warning",
        });
      });
  };

  handleSubmit = () => {
    const resetPass = secureStorage.getItem("resetPass");
    const verCode = this.state.verCode;
    if (verCode === "") {
      this.notifRef.current.setShow({
        show: true,
        message: "Data masih ada yang kosong, Mohon di lengkapi dahulu",
        color: "warning",
      });
    } else {
      this.setState({
        submitDisabled: true,
      });
      const payload = {
        userId: resetPass.id,
        verCode,
      };

      axios
        .post(`${Config.ApiUrl}/user/verification`, payload)
        .then((response) => {
          console.log(response.data)
          this.setState({
            confirmCode: response.data
          })
        })
        .catch((err) => {
          this.setState({
            submitDisabled: false,
          });

          this.notifRef.current.setShow({
            show: true,
            message:
              err.response && err.response.data
                ? err.response.data
                : "Terjadi kesalahan pada system",
            color: "warning",
          });
        });
    }
  };

  render() {
    const user = secureStorage.getItem("resetPass");
    if (this.state.confirmCode) return <Navigate to={`/reset/${this.state.confirmCode}`} />;
    return (
      <BasicLayout image={bgImage}>
        <Notif ref={this.notifRef} />
        <Card>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            mx={2}
            mt={-3}
            p={2}
            mb={1}
            textAlign="center"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Verifikasi OTP
            </MDTypography>
          </MDBox>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" role="form">
              <MDTypography variant="h6" fontWeight="light" fontSize={14} textAlign="center" my={3}>
                Kode OTP telah dirikim via WhatsApp ke <br /> {user && user.phone}
              </MDTypography>
              <MDTypography variant="h6" fontWeight="medium" textAlign="center" my={3}>
                Masukan Kode Verifikasi OTP
              </MDTypography>
              <MDBox mb={2} textAlign="center" justifyContent="center" align="center">
                <MDInput
                  id="vercode"
                  type="text"
                  textalign="center"
                  label="Kode Verifikasi"
                  variant="standard"
                  mx="auto"
                  inputProps={{
                    maxLength: 6,
                    style: { textAlign: "center", fontSize: 25, fontWeight: 500 },
                  }}
                  value={this.state.verCode}
                  onChange={this.handleChange}
                  onKeyDown={this.handleKeyDown}
                />
              </MDBox>
              <MDBox mt={4} mb={1}>
                <MDButton
                  variant="gradient"
                  color="info"
                  disabled={this.state.submitDisabled}
                  onClick={this.handleSubmit}
                  fullWidth
                >
                  Kirim
                </MDButton>
              </MDBox>
              <MDBox mt={3} mb={1} textAlign="center">
                <MDButton
                  variant="text"
                  color="info"
                  disabled={this.state.submitRepeatDisabled}
                  onClick={this.handleChangeCode}
                  fullWidth
                >
                  Kirim Ulang Kode
                </MDButton>
                {/* <MDTypography
                  
                  onClick={this.handleChangeCode}
                  sx={{ cursor: "pointer" }}
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Kirim Ulang Kode
                </MDTypography> */}
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      </BasicLayout>
    );
  }
}

export default Verifycation;
