/* eslint-disable react/prop-types */
import React, { Fragment } from "react"
import { Document, Page, Text, Image, View, StyleSheet, Font } from "@react-pdf/renderer"

import logo from "assets/img/logoApp.png"

import RobotoMonoRegular from "assets/fonts/RobotoMono/RobotoMono-Regular.ttf"
import RobotMonoBold from "assets/fonts/RobotoMono/RobotoMono-Bold.ttf"
import RobotoMonoBoldItalic from "assets/fonts/RobotoMono/RobotoMono-BoldItalic.ttf"
import RobotoMonoItalic from "assets/fonts/RobotoMono/RobotoMono-Italic.ttf"

import InvoiceStyle from "helper/styles/pdfRecapPackage"
import Config from "config";

// Font
Font.register({
	family: 'RobotoMono',
	format: 'truetype',
	fonts: [
		{ src: RobotoMonoRegular },
		{ src: RobotoMonoBoldItalic },
		{ src: RobotoMonoItalic },
		{ src: RobotMonoBold },
	]
});
// Create styles
const styles = StyleSheet.create(InvoiceStyle)

class PdfRecapPackage extends React.Component {

	constructor(props) {
		super(props)
		this.state = {}
	}

	componentDidMount() {
		const data = this.props.data

	}

	render() {
		const data = this.props.data
		const merchant = data.merchant
		const body = data.body

		return (
			<Document>
				<Page size="A4" orientation="landscape" style={styles.page}>
					{/* HAADER */}
					<View style={styles.headerContainer}>
						<Text style={styles.headerTitle}>{data.title}</Text>
						<Text style={styles.headerDate}>
							Dari {data.startDate} sampai {data.endDate}
						</Text>
					</View>
					{/* END */}

					<View style={styles.contentContainer}>
						<View style={{ width: "100%", borderTopWidth: 1 }}></View>
						<View style={styles.containerMiddle}>
							<Text style={styles.titleDetail}>Rincian Transaksi</Text>

							{merchant && (
								<View style={styles.containerLabel}>
									<Text style={{ ...styles.labelCaption, width: "20%" }}>Biro Travel</Text>
									<Text style={{ width: "2%", fontSize: 5, textAlign: "left" }}>:</Text>
									<Text style={{ ...styles.labelCaption, width: "50%" }}>{merchant.label}</Text>
								</View>
							)}
						</View>
					</View>

					{/* Table */}
					<View style={styles.tableContainer}>
						{/* Table Head */}
						<View style={styles.tableHeadContainer}>
							<Text style={{ ...styles.tableHeadCaption, }}>Paket</Text>
							<Text style={{ ...styles.tableHeadCaption, width: "8%", textAlign: "center" }}>Jamaah</Text>
							<Text style={{ ...styles.tableHeadCaption, width: "15%", textAlign: "right" }}>Komisi</Text>
							<Text style={{ ...styles.tableHeadCaption, width: "20%", textAlign: "right" }}>Tagihan</Text>
							<Text style={{ ...styles.tableHeadCaption, width: "20%", textAlign: "right" }}>Sisa Tagihan</Text>
							<Text style={{ ...styles.tableHeadCaption, width: "20%", textAlign: "right" }}>Tagihan Tebayar</Text>
						</View>

						<Fragment>
							{body.map((item, key) => (
								<View key={key} style={styles.tableRow}>
									<Text style={styles.tableRowCaption}>{item.product}</Text>
									<Text style={{ ...styles.tableRowCaption, textAlign: 'center' }}>{item.qty}</Text>
									<Text style={{ ...styles.tableRowCaption, textAlign: 'right' }}>{item.fee}</Text>
									<Text style={{ ...styles.tableRowCaption, textAlign: 'right' }}>{item.amount}</Text>
									<Text style={{ ...styles.tableRowCaption, textAlign: 'right' }}>{item.remaiderAmount}</Text>
									<Text style={{ ...styles.tableRowCaption, textAlign: 'right' }}>{item.paidAmount}</Text>
								</View>
							))}

							{/* Total Qty */}
							<View style={{ ...styles.tableRow, borderBottomWidth: 0, borderLeftWidth: 0, borderRightWidth: 0 }}>
								<Text style={styles.tableRowCaption}></Text>
								<Text style={styles.tableRowCaption}></Text>
								<Text style={styles.tableRowCaption}></Text>
								<Text style={styles.tableRowCaption}></Text>
								<Text style={{
									...styles.tableRowCaption,
									textAlign: 'right',
									borderBottomWidth: 1,
									borderColor: '#391380'
								}}
								>
									TOTAL JAMAAH
								</Text>
								<Text style={{
									...styles.tableRowCaption,
									textAlign: 'right',
									borderBottomWidth: 1,
									borderColor: '#391380',
									fontWeight: 'bold'
								}}
								>
									{data.totalQty}
								</Text>
							</View>
							{/* TOTAL FEE */}
							<View style={{ ...styles.tableRow, borderBottomWidth: 0, borderLeftWidth: 0, borderRightWidth: 0 }}>
								<Text style={styles.tableRowCaption}></Text>
								<Text style={styles.tableRowCaption}></Text>
								<Text style={styles.tableRowCaption}></Text>
								<Text style={styles.tableRowCaption}></Text>
								<Text style={{
									...styles.tableRowCaption,
									textAlign: 'right',
									borderBottomWidth: 1,
									borderColor: '#391380'
								}}
								>
									TOTAL KOMISI
								</Text>
								<Text style={{
									...styles.tableRowCaption,
									textAlign: 'right',
									borderBottomWidth: 1,
									borderColor: '#391380',
									fontWeight: 'bold'
								}}
								>
									{data.totalFee}
								</Text>
							</View>
							{/* TOTAL BILL */}
							<View style={{ ...styles.tableRow, borderBottomWidth: 0, borderLeftWidth: 0, borderRightWidth: 0 }}>
								<Text style={styles.tableRowCaption}></Text>
								<Text style={styles.tableRowCaption}></Text>
								<Text style={styles.tableRowCaption}></Text>
								<Text style={styles.tableRowCaption}></Text>
								<Text style={{
									...styles.tableRowCaption,
									textAlign: 'right',
									borderBottomWidth: 1,
									borderColor: '#391380'
								}}
								>
									TOTAL TAGIHAN
								</Text>
								<Text style={{
									...styles.tableRowCaption,
									textAlign: 'right',
									borderBottomWidth: 1,
									borderColor: '#391380',
									fontWeight: 'bold'
								}}
								>
									{data.totalBill}
								</Text>
							</View>
							{/* TOTAL REMAIDER */}
							<View style={{ ...styles.tableRow, borderBottomWidth: 0, borderLeftWidth: 0, borderRightWidth: 0 }}>
								<Text style={styles.tableRowCaption}></Text>
								<Text style={styles.tableRowCaption}></Text>
								<Text style={styles.tableRowCaption}></Text>
								<Text style={styles.tableRowCaption}></Text>
								<Text style={{
									...styles.tableRowCaption,
									textAlign: 'right',
									borderBottomWidth: 1,
									borderColor: '#391380'
								}}
								>
									TOTAL SISA TAGIHAN
								</Text>
								<Text style={{
									...styles.tableRowCaption,
									textAlign: 'right',
									borderBottomWidth: 1,
									borderColor: '#391380',
									fontWeight: 'bold'
								}}
								>
									{data.totalRemaider}
								</Text>
							</View>
							{/* TOTAL PAID AMOUNT */}
							<View style={{ ...styles.tableRow, borderBottomWidth: 0, borderLeftWidth: 0, borderRightWidth: 0 }}>
								<Text style={styles.tableRowCaption}></Text>
								<Text style={styles.tableRowCaption}></Text>
								<Text style={styles.tableRowCaption}></Text>
								<Text style={styles.tableRowCaption}></Text>
								<Text style={{
									...styles.tableRowCaption,
									textAlign: 'right',
									borderBottomWidth: 1,
									borderColor: '#391380'
								}}
								>
									TOTAL TAGIHAN TERBAYAR
								</Text>
								<Text style={{
									...styles.tableRowCaption,
									textAlign: 'right',
									borderBottomWidth: 1,
									borderColor: '#391380',
									fontWeight: 'bold'
								}}
								>
									{data.totalPaid}
								</Text>
							</View>
						</Fragment>
					</View>
				</Page>
			</Document>
		)
	}
}

export default PdfRecapPackage