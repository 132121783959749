import { initializeApp } from "firebase/app"
import { getMessaging, getToken, onMessage } from "firebase/messaging"

import Config from "config"
import firebaseConfig from "config/firebase"
import secureStorage from "libs/secureStorage"
import useAxios from "libs/useAxios"

const firebaseApp = initializeApp(firebaseConfig)
const messaging = getMessaging(firebaseApp)

export const requestForToken = () => {
    return getToken(messaging, { vapidKey: firebaseConfig.vapidKey })
        .then(currentToken => {
            if (currentToken) {
                const user = secureStorage.getItem('user')

                const payload = {
                    token: currentToken,
                    userId: user.id
                }

                useAxios()
                    .post(`${Config.ApiUrl}/notif/token`, payload)
                    .then((res) => {
                        // console.log("[DEVICE TOKEN]", res)
                    })
                    .catch((err) => console.log("err", err))
            }
            else {
                //  Show permission request UI
                console.log("No registration token available. Request permission to generate one.")
            }
        })
        .catch((err) => {
            console.log('An error occurred while retrieving token. ', err)
            // catch error while creating client token
        })
}

export const onMessageListener = () => {
    return new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload)
        })
    })
}